import {DataStore} from "aws-amplify";
import {Appointment, Animal, AppointmentAnimal,
    AppointmentNote, AnimalNote, ClientNote, Client,
    TrimTask, ShoeTask, ShoePackage} from "../../models";
import {fetchApptShoeTasksByIds, fetchApptTrimTasksByIds} from "../get/fetchRecords";
import {updateClientRec} from "../update/client/updateClient";

// TODO !!! - START HERE, FR FR, BIG CHANGE!!!
//  START USING NEW ISDELETED FIELD, UPDATE PROVIDERS TO USE THE SAME ON DATA QUERIES

// TODO - START HERE AND GET DELETE OF MODEL AND RELATED MODELS WORKING
export async function deleteAppointmentById(apptRec, showLogs) {
    if (showLogs) console.log('deleteAppointmentById(apptRec)', apptRec);
    // console.log('deleteUserPost(apptId)', apptId)
    // TODO - IS DELETE APPT ANIMALS NEEDED?
    // const apptAnimaRecs = await DataStore.query(AppointmentAnimal, c => c.appointmentId.eq(apptRec.id));

    const apptShoeTasks = await DataStore.query(ShoeTask, c => c.appointmentID.eq(apptRec.id));
    console.log('!!! apptShoeTasks', apptShoeTasks);
    for (const shoeTask of apptShoeTasks) {
        console.log('>>> DELETE shoeTask', shoeTask)
        await DataStore.delete(ShoeTask, shoeTask);
    }

    const apptTrimTasks = await DataStore.query(TrimTask, c => c.appointmentID.eq(apptRec.id));
    console.log('!!! apptTrimTasks', apptTrimTasks);
    for (const trimTask of apptTrimTasks) {
        console.log('>>> DELETE trimTask', trimTask)
        await DataStore.delete(TrimTask, trimTask);
    }

    const apptNotes = await DataStore.query(AppointmentNote, an => an.appointmentID.eq(apptRec.id));
    console.log('!!! apptNotes', apptNotes);
    for (const apptNote of apptNotes) {
        console.log('>>> DELETE apptNote', apptNote)
        await DataStore.delete(AppointmentNote, apptNote);
    }

    try  {
        const deleteResObj = await DataStore.delete(Appointment, apptRec.id);
        return({deleteResObj, success: true});
    }
    catch (error) {
        console.error('deleteAppointmentById error', error);
        return({error, success: false});
    }
}

export async function deleteAppointmentByRouterPath(routerPath) {
    const appt = await DataStore.query(Appointment, c => c.routerPath("eq", routerPath));
    if (appt) {
        await DataStore.delete(Appointment, appt[0].id);
    }
}

export async function deleteAnimalById(animalId, showLogs) {
    if (showLogs) console.log('deleteAnimalById(animalId)', animalId);
    try {
        const deleteAnimalRes = await DataStore.delete(Animal, animalId);
        return({deleteAnimalRes, success: true});
    }
    catch (error) {
        console.error('deleteAnimalById error', error);
        return ({error, success: false});
    }
}

export async function deleteAnimalAndRelatedRecsByAnimalId(animalId, showLogs) {
    if (showLogs) console.log('deleteAnimalById(animalId)', animalId);
    let resObj = {deleteRecsSuccess: true};
    const animalRec = await DataStore.query(Animal, animalId);
    let deleteShoeTasksSuccess = true;
    let deleteTrimTasksSuccess = true;
    let deleteShoePackagesSuccess = true;
    let deleteAnimalNotesSuccess = true;
    let deleteAnimalSuccess = true;
    if (animalRec) {
        const animalShoeTasks = await DataStore.query(ShoeTask, c => c.animalID.eq(animalId));
        // let deleteShoeTasksSuccess = true;
        if (animalShoeTasks && animalShoeTasks.length > 0) {
            const deleteShoeTasksResArr = [];
            // let deleteShoeTasksSuccess = true;
            for (const shoeTask of animalShoeTasks) {
                if (showLogs) console.log('>>> DELETE shoeTask', shoeTask);
                const deleteRes = await deleteShoeTaskById(shoeTask.id, showLogs);
                // useConsoleWarn(showLogs, [{label: 'deleteAnimalAndRelatedRecsByAnimalId deleteShoeTaskById deleteRes', deleteRes}]);
                console.warn('deleteAnimalAndRelatedRecsByAnimalId deleteShoeTaskById deleteRes', deleteRes);
                if (!deleteRes.success) {
                    deleteShoeTasksSuccess = false;
                }
                deleteShoeTasksResArr.push(deleteRes);
            }
            resObj = {...resObj, deleteShoeTasksResArr, deleteShoeTasksSuccess};
        }
        const animalTrimTasks = await DataStore.query(TrimTask, c => c.animalID.eq(animalId));
        // let deleteTrimTasksSuccess = true;
        if (animalTrimTasks && animalTrimTasks.length > 0) {
            const deleteTrimTasksResArr = [];
            // let deleteTrimTasksSuccess = true;
            for (const trimTask of animalTrimTasks) {
                if (showLogs) console.log('>>> DELETE trimTask', trimTask);
                const deleteRes = await deleteTrimTaskById(trimTask.id, showLogs);
                // useConsoleWarn(showLogs, [{label: 'deleteAnimalAndRelatedRecsByAnimalId deleteTrimTaskById deleteRes', deleteRes}]);
                console.warn('deleteAnimalAndRelatedRecsByAnimalId deleteTrimTaskById deleteRes', deleteRes);
                if (!deleteRes.success) {
                    deleteTrimTasksSuccess = false;
                }
                deleteTrimTasksResArr.push(deleteRes);
            }
            resObj = {...resObj, deleteTrimTasksResArr, deleteTrimTasksSuccess};
        }
        const animalShoePackages = await DataStore.query(ShoePackage, c => c.animalID.eq(animalId));
        // let deleteShoePackagesSuccess = true;
        if (animalShoePackages && animalShoePackages.length > 0) {
            const deleteShoePackagesResArr = [];
            // let deleteShoePackagesSuccess = true;
            for (const shoePackage of animalShoePackages) {
                if (showLogs) console.log('>>> DELETE shoePackage', shoePackage);
                const deleteRes = await deleteShoePackageById(shoePackage.id, showLogs);
                // useConsoleWarn(showLogs, [{label: 'deleteAnimalAndRelatedRecsByAnimalId deleteShoePackageById deleteRes', deleteRes}]);
                console.warn('deleteAnimalAndRelatedRecsByAnimalId deleteShoePackageById deleteRes', deleteRes);
                if (!deleteRes.success) {
                    deleteShoePackagesSuccess = false;
                }
                deleteShoePackagesResArr.push(deleteRes);
            }
            resObj = {...resObj, deleteShoePackagesResArr, deleteShoePackagesSuccess};
        }
        const animalNotes = await DataStore.query(AnimalNote, c => c.animalID.eq(animalId));
        // let deleteAnimalNotesSuccess = true;
        if (animalNotes && animalNotes.length > 0) {
            const deleteNotesResArr = [];
            // let deleteAnimalNotesSuccess = true;
            for (const note of animalNotes) {
                if (showLogs) console.log('>>> DELETE note', note);
                const deleteRes = await deleteAnimalNoteById(note.id, showLogs);
                // useConsoleWarn(showLogs, [{label: 'deleteAnimalAndRelatedRecsByAnimalId deleteAnimalNoteById deleteRes', deleteRes}])
                console.warn('deleteAnimalAndRelatedRecsByAnimalId deleteAnimalNoteById deleteRes', deleteRes)
                if (!deleteRes.success) {
                    deleteAnimalNotesSuccess = false;
                }
                deleteNotesResArr.push(deleteRes);
            }
            resObj = {...resObj, deleteNotesResArr, deleteAnimalNotesSuccess};
        }
    }
    // let deleteAnimalSuccess = true;
    try {
        const deleteAnimalRes = await DataStore.delete(Animal, animalId);
        // useConsoleWarn(showLogs, [{label: 'deleteAnimalAndRelatedRecsByAnimalId deleteAnimalRes', deleteAnimalRes}])
        console.warn('deleteAnimalAndRelatedRecsByAnimalId deleteAnimalRes', deleteAnimalRes)
        deleteAnimalSuccess = true;
        // return({...resObj, deleteAnimalRes, success: true});
        resObj = {...resObj, deleteAnimalRes};
    }
    catch (error) {
        deleteAnimalSuccess = false;
        console.error('deleteAnimalById error', error);
        // return ({...resObj, deleteAnimalErr: error, success: false});
        resObj = {...resObj, deleteAnimalErr: error};
    }
    resObj = {...resObj, deleteAnimalSuccess};
    // TODO - FIX THIS ASA IT RETURNS FALSE WHEN ALL REC DELETES WERE SUCCESSFUL
    if (!resObj.deleteAnimalSuccess
        || !resObj.deleteShoeTasksSuccess
        || !resObj.deleteTrimTasksSuccess
        || !resObj.deleteShoePackagesSuccess
        || !resObj.deleteAnimalNotesSuccess) {
        // resObj.deleteRecsSuccess = false;
        resObj = {...resObj, deleteRecsSuccess: false, success: false};
    }
    return resObj;
}

export async function deleteAnimalRecRelatedRecs(animalRecToDelete, deleteNotes, deleteTasks, deleteShoePackages, showLogs) {
    if (showLogs) console.log('deleteAnimalRecRelatedRecs(animalRecToDelete)', animalRecToDelete);
    // TODO - START HERE, FR FR, DELETE RELATED MODEL RECS BASED ON PARAMS

    // delete animal notes
    const relatedNotes = await DataStore.query(AnimalNote, c => c.animalID.eq(animalRecToDelete.id));
    if (relatedNotes && relatedNotes.length > 0) {
        for (const note of relatedNotes) {
            if (showLogs) console.log('>>> DELETE note', note);
            // await DataStore.delete(AnimalNote, note);
            const deleteNoteRes = await deleteAnimalNoteById(note.id, showLogs);
            if (showLogs) console.log('deleteNoteRes', deleteNoteRes);
        }
    }
    else {
        if (showLogs) {
            console.warn('No related notes found for animal', animalRecToDelete);
        }
    }

    // delete animal shoe tasks
    // delete animal trim tasks
    // delete animal appointments
    // delete animal from appointments
    //delete animal's shoe packages

    // await deleteAnimalNoteById(animalRecToDelete.id, showLogs);
    // await deleteAppointmentAnimalById(animalRecToDelete.id, showLogs);
}

export async function deleteShoePackageById(shoePackageId, showLogs) {
    if (showLogs) console.log('deleteShoePackageById(shoePackageId)', shoePackageId);
    try {
        const deleteShoePackageRes = await DataStore.delete(ShoePackage, shoePackageId);
        return({deleteShoePackageRes, success: true});
    }
    catch (error) {
        console.error('deleteShoePackageById error', error);
        return ({error, success: false});
    }
}

export async function deleteTrimTaskById(trimTaskId, showLogs) {
    if (showLogs) console.log('deleteTrimTaskById(trimTaskId)', trimTaskId);
    try {
        const deleteTrimTaskRes = await DataStore.delete(TrimTask, trimTaskId);
        return({deleteTrimTaskRes, success: true});
    }
    catch (error) {
        console.error('deleteTrimTaskById error', error);
        return ({error, success: false});
    }
}

export async function deleteShoeTaskById(shoeTaskId, showLogs) {
    if (showLogs) console.log('deleteShoeTaskById(shoeTaskId)', shoeTaskId);
    try {
        const deleteShoeTaskRes = await DataStore.delete(ShoeTask, shoeTaskId);
        return({deleteShoeTaskRes, success: true});
    }
    catch (error) {
        console.error('deleteShoeTaskById error', error);
        return ({error, success: false});
    }
}

export async function deleteApptTrimTasksByIds(apptId, animalId, showLogs) {
    if (showLogs) console.log('deleteApptTrimTaskByIds apptId', apptId);
    if (showLogs) console.log('deleteApptTrimTaskByIds animalId', animalId);
    // const apptTrimTasks = await DataStore.query(TrimTask, c => c.appointmentId.eq(apptId).animalId.eq(animalId));
    const apptTrimTasks = await fetchApptTrimTasksByIds(apptId, animalId, showLogs);
    console.log('apptTrimTasks', apptTrimTasks);
    if (apptTrimTasks) {
        for (const trimTask of apptTrimTasks) {
            await DataStore.delete(TrimTask, trimTask);
        }
    }
}

export async function deleteApptShoeTasksByIds(apptId, animalId, showLogs) {
    if (showLogs) console.log('deleteApptShoeTaskByIds apptId', apptId);
    if (showLogs) console.log('deleteApptShoeTaskByIds animalId', animalId);
    // const apptShoeTasks = await DataStore.query(ShoeTask, c => c.appointmentId.eq(apptId).animalId.eq(animalId));
    const apptShoeTasks = await fetchApptShoeTasksByIds(apptId, animalId, showLogs);
    console.log('apptShoeTasks', apptShoeTasks);
    if (apptShoeTasks) {
        for (const shoeTask of apptShoeTasks) {
            await DataStore.delete(ShoeTask, shoeTask);
        }
    }
}

// export async function deleteAnimalNoteById(animalNoteId, showLogs) {
export async function deleteAnimalNoteById(animalNoteId, showLogs) {
    if (showLogs) console.log('deleteAnimalNoteById(animalNoteId)', animalNoteId);
    try {
        const deleteAnimalNoteRes = await DataStore.delete(AnimalNote, animalNoteId);
        return({deleteAnimalNoteRes, success: true});
    }
    catch (error) {
        console.error('deleteAnimalNoteById error', error);
        return ({error, success: false});
    }
    // return deleteAnimalNoteRes;
}

export async function deleteAppointmentNoteById(apptNote, showLogs) {
    if (showLogs) console.log('deleteAppointmentNoteById(apptNote)', apptNote);
    await DataStore.delete(AppointmentNote, apptNote);
}

export async function deleteAppointmentAnimalById(apptAnimalModels, showLogs) {
    if (showLogs) console.log('deleteAppointmentAnimalById apptAnimalModels', apptAnimalModels);
    await deleteApptShoeTasksByIds(apptAnimalModels.apptRec.id, apptAnimalModels.animalRec.id, showLogs);
    await deleteApptTrimTasksByIds(apptAnimalModels.apptRec.id, apptAnimalModels.animalRec.id, showLogs);
    // TODO - fix this, needs to delete ApptAnimal rec after query for matching rec
    // await DataStore.delete(AppointmentAnimal, apptAnimalModels.apptAnimalRec);
    // const matchingApptAnimalRec = await DataStore.query(AppointmentAnimal, c => c.appointmentID.eq(apptAnimalModels.apptRec.id).animalID.eq(apptAnimalModels.animalRec.id));
    const matchingApptAnimalRec = await DataStore.query(AppointmentAnimal, c => c.appointmentId.eq(apptAnimalModels.apptRec.id).animalId.eq(apptAnimalModels.animalRec.id));
    await DataStore.delete(matchingApptAnimalRec);
}

// export async function deleteAppointmentAnimalRecByIds(apptId, animalId, showLogs) {
// export async function deleteAppointmentAnimalRecByIds(apptId, animalId, showLogs) {
//     console.warn('$$$ deleteAppointmentAnimalRecByIds apptId', apptId);
//     console.warn('$$$ deleteAppointmentAnimalRecByIds animalId', animalId);
//     // const matchingApptAnimalRecs = await DataStore.query(AppointmentAnimal, c => c.appointmentID.eq(apptId).animalID.eq(animalId));
//     // const matchingApptAnimalRec = await DataStore.query(AppointmentAnimal, c => c.appointmentID.eq(apptId).animalID.eq(animalId));
//     const matchingApptAnimalRec = await DataStore.query(AppointmentAnimal, (c) => c.appointmentId.eq(apptId).animalId.eq(animalId));
//     // if (showLogs) console.log('deleteAppointmentAnimalRecByIds matchingApptAnimalRecs', matchingApptAnimalRecs);
//     if (showLogs) console.log('$$$ deleteAppointmentAnimalRecByIds matchingApptAnimalRec', matchingApptAnimalRec);
//     // await DataStore.delete(AppointmentAnimal, apptAnimalModels.apptAnimalRec);
//     // const deleteRes = await DataStore.delete(AppointmentAnimal, matchingApptAnimalRecs[0]);
//     const deleteRes = await DataStore.delete(matchingApptAnimalRec);
//     console.warn('$$$ deleteAppointmentAnimalRecByIds deleteRes', deleteRes);
//     return deleteRes;
// }

export async function deleteAppointmentAnimalRecById(apptAnimalId, showLogs) {
    console.warn('$$$ deleteAppointmentAnimalRecById apptAnimalId', apptAnimalId);
    const matchingApptAnimalRecs = await DataStore.query(AppointmentAnimal, aa => aa.id.eq(apptAnimalId));
    if (showLogs) console.log('$$$ deleteAppointmentAnimalRecById matchingApptAnimalRecs', matchingApptAnimalRecs);
    if (matchingApptAnimalRecs && matchingApptAnimalRecs.length > 0) {
        const deleteRes = await DataStore.delete(matchingApptAnimalRecs[0]);
        console.warn('$$$ deleteAppointmentAnimalRecById deleteRes', deleteRes);
        return deleteRes;
    }
    else {
        console.warn('$$$ deleteAppointmentAnimalRecById no matchingApptAnimalRec found');
    }
}

export async function deleteClientById(ampUser, clientId, deleteRelatedRecs, showLogs) {
    if (showLogs) console.log('deleteClientById clientId', clientId);
    const resObj = {};
    try {
        const valsDiff = {isDeleted: true}
        const updateClientRes = await updateClientRec(ampUser, clientId, valsDiff, showLogs);
        if (showLogs) {
            console.log('deleteClientById updateClientRes', updateClientRes);
        }
        if (updateClientRes) {
            resObj.updateClientRes = updateClientRes;
            // resObj.success = updateClientRes.success;
            // TODO - !!! DELETE RELATED RECS
            // delete client's animals, appointments, notes
            // return {updateClientRes, success: true};
        }
        if (updateClientRes.success && deleteRelatedRecs) {
            // TODO - !!! DELETE RELATED RECS
            // delete related recs, ? client notes are soft deleted by default
            await deleteClientRecRelatedRecs(updateClientRes.updatedClient, showLogs);
            // delete client's animals, appointments, notes
            // return {updateClientRes, success: true};
        }
        // else {
        //     return {updateClientRes, success: false};
        // }
    }
    catch (error) {
        console.error('deleteClientById error', error);
        // return({error, success: false});
        resObj.error = error;
        resObj.success = false;
        return resObj;
    }
    return resObj;
}

export async function deleteClientNoteById(clientNoteId, showLogs) {
    if (showLogs) console.log('deleteClientNoteById clientNoteId', clientNoteId);
    try {
        const deleteClientNoteRes = await DataStore.delete(ClientNote, clientNoteId);
        return {deleteClientNoteRes, success: true};
    }
    catch (error) {
        console.error('deleteClientNoteById error', error);
        return({error, success: false});
    }
}

export async function softDeleteClientNoteById(clientNoteId, showLogs) {
    if (showLogs) console.log('deleteClientNoteById clientNoteId', clientNoteId);
    try {
        const deleteClientNoteRes = await DataStore.delete(ClientNote, clientNoteId);
        return {deleteClientNoteRes, success: true};
    }
    catch (error) {
        console.error('deleteClientNoteById error', error);
        return({error, success: false});
    }
}

// async function deleteClientRecRelatedRecs(clientRecToDelete, showLogs) {
//     if (showLogs) console.log('deleteClientRecRelatedRecs clientRecToDelete', clientRecToDelete);
//     // SOFT DELETE CLIENT'S RELATED RECS, BY SETTING RECORDS' ISDELETED FIELD TO TRUE
//     // fetch all client's client notes ids
//     if (clientRecToDelete) {
//         const clientNotesRecs = await DataStore.query(ClientNote, cn => cn.clientID.eq(clientRecToDelete.id));
//         if (showLogs) {
//             console.log('deleteClientRecRelatedRecs clientNotesRecs', clientNotesRecs);
//         }
//         const clientsNotesRecsIds = clientNotesRecs.map(note => note.id);
//         // for (const noteId of clientsNotesRecsIds) {
//         //     await deleteClientNoteById(noteId, showLogs);
//         // }
//         const softDeleteClientNotesRes = await batchMarkAsDeleted(clientsNotesRecsIds, ClientNote);
//         if (showLogs) {
//             console.log('deleteClientRecRelatedRecs softDeleteClientNotesRes', softDeleteClientNotesRes);
//         }
//     }
//     // delete client's animals, appointments, notes
//     // delete client's animals
//     // delete client's appointments
//     // delete client's notes
//     // delete client's shoe tasks
//     // delete client's trim tasks
//     // delete client's shoe packages
// }

// TODO - START HERE, !!! FR, FR, ADD CONFIRM DELETE DIALOG, WITH ADDED CONFIRM TO DELETE RELATED RECS, THEN DELETE REST OF RELATED RECS IF CONFIRMED
async function deleteClientRecRelatedRecs(clientRecToDelete, showLogs) {
    if (showLogs) console.log('deleteClientRecRelatedRecs clientRecToDelete', clientRecToDelete);

    if (clientRecToDelete) {
        try {
            const clientNotesRecs = await DataStore.query(ClientNote, cn => cn.clientID.eq(clientRecToDelete.id));
            if (showLogs) {
                console.log('deleteClientRecRelatedRecs clientNotesRecs', clientNotesRecs);
            }
            const clientsNotesRecsIds = clientNotesRecs.map(note => note.id);

            const softDeleteClientNotesRes = await batchMarkAsDeleted(clientsNotesRecsIds, ClientNote);
            if (showLogs) {
                console.log('deleteClientRecRelatedRecs softDeleteClientNotesRes', softDeleteClientNotesRes);
            }
        } catch (error) {
            console.error('Error in deleteClientRecRelatedRecs:', error);
            // Handle the error appropriately (e.g., show a user-friendly message)
        }
    }

    // ... rest of the function (delete other related records)
}

// async function batchMarkAsDeleted(recordIds, modelType) {
//     try {
//         const updates = await Promise.all(recordIds.map(async (id) => {
//             const original = await DataStore.query(modelType, id);
//             if (!original) {
//                 console.warn(`Record with id ${id} not found`);
//                 return null;
//             }
//             return modelType.copyOf(original, updated => {
//                 updated.isDeleted = true;
//             });
//         }));
//
//         const validUpdates = updates.filter(update => update !== null);
//         await DataStore.save(validUpdates);
//
//         console.log(`Successfully marked ${validUpdates.length} records as deleted`);
//     } catch (error) {
//         console.error('Error batch marking records as deleted:', error);
//     }
// }

export async function batchMarkAsDeleted(recordIds, ModelType) {
    try {
        const updates = await Promise.all(recordIds.map(async (id) => {
            const original = await DataStore.query(ModelType, id);
            if (!original) {
                console.warn(`Record with id ${id} not found`);
                return null;
            }
            return ModelType.copyOf(original, updated => {
                updated.isDeleted = true;
            });
        }));

        const validUpdates = updates.filter(update => update !== null);

        // Save each update individually
        for (const update of validUpdates) {
            await DataStore.save(update);
        }

        console.log(`Successfully marked ${validUpdates.length} records as deleted`);
        return validUpdates.length;
    } catch (error) {
        console.error('Error batch marking records as deleted:', error);
        throw error; // Re-throw the error for the calling function to handle
    }
}
