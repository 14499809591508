import React, { useState, useEffect } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { SortDirection } from "aws-amplify";
import AmpUserApptsContext from "../../contexts/AmpUserApptsContext";
import { Appointment, Client } from '../../../models';

const AmpUserApptsProvider = ({ children, ampUserId, apptId, showLogs, resetProviderState }) => {
    const [ampUserApptsRecs, setAmpUserApptsRecs] = useState([]);
    const [localShowLogs, setLocalShowLogs] = useState(false);
    const [clientRecsByApptId, setClientRecsByApptId] = useState({});
    const [apptRecsByClientId, setApptRecsByClientId] = useState({});
    const [ampUserApptRec, setAmpUserApptRec] = useState(null);

    // useEffect( reset data, based on resetProviderState
    useEffect(() => {
        if (resetProviderState) {
            setAmpUserApptRec(null);
            setAmpUserApptsRecs([]);
            setClientRecsByApptId({});
            setApptRecsByClientId({});
        }
    }, [resetProviderState]);

    // useEffect( setLocalShowLogs(showLogs);
    useEffect(() => {
        if (showLogs) {
            setLocalShowLogs(showLogs);
        }
    }, [showLogs]);

    // useEffect( console.log( ampUserApptRec, ampUserApptsRecs, clientRecsByApptId, apptRecsByClientId
    useEffect(() => {
        if (localShowLogs) {
            // console.log('AmpUserApptsProvider ampUserApptRec', ampUserApptRec);
            // console.log('AmpUserApptsProvider ampUserApptsRecs', ampUserApptsRecs);
            // console.log('AmpUserApptsProvider clientRecsByApptId', clientRecsByApptId);
            // console.log('AmpUserApptsProvider apptRecsByClientId', apptRecsByClientId);
        }
    }, [localShowLogs, ampUserApptRec, ampUserApptsRecs, clientRecsByApptId, apptRecsByClientId]);

    useEffect(() => {
        if (ampUserId && !resetProviderState) {
            const subscription = DataStore.observeQuery(Appointment,
                (a => a.ampuserID.eq(ampUserId)),
                {
                    sort: s => s.createdAt(SortDirection.ASCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    if (localShowLogs) {
                        // console.log(`AmpUserApptsProvider a [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                        // console.log('AmpUserApptsProvider a [Snapshot] items', items);
                    }
                    if (isSynced) {
                        const clientByApptId = {};
                        const apptsByClientId = {};
                        for (const appt of items) {
                            const clientRec = await DataStore.query(Client, appt.clientID);
                            if (clientRec) {
                                if (Object.hasOwn(clientByApptId, appt.id)) {
                                    clientByApptId[appt.id].push(clientRec);
                                } else {
                                    clientByApptId[appt.id] = [clientRec];
                                }
                                if (Object.hasOwn(apptsByClientId, clientRec.id)) {
                                    apptsByClientId[clientRec.id].push(appt);
                                } else {
                                    apptsByClientId[clientRec.id] = [appt];
                                }
                            }
                        }
                        setAmpUserApptsRecs(items);
                        setClientRecsByApptId(clientByApptId);
                        setApptRecsByClientId(apptsByClientId);
                    }
                });
            // Remember to unsubscribe when the component unmounts
            return () => subscription.unsubscribe();
        }
        else if (apptId && !resetProviderState) {
            const subscription = DataStore.observeQuery(Appointment,
                (a => a.id.eq(apptId)),
                {
                    sort: s => s.createdAt(SortDirection.ASCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    if (localShowLogs) {
                        // console.log(`AmpUserApptsProvider a [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                        // console.log('AmpUserApptsProvider a [Snapshot] items', items);
                    }
                    if (isSynced) {
                        const clientByApptId = {};
                        const apptsByClientId = {};
                        for (const appt of items) {
                            const clientRec = await DataStore.query(Client, appt.clientID);
                            if (clientRec) {
                                if (Object.hasOwn(clientByApptId, appt.id)) {
                                    clientByApptId[appt.id].push(clientRec);
                                } else {
                                    clientByApptId[appt.id] = [clientRec];
                                }
                                if (Object.hasOwn(apptsByClientId, clientRec.id)) {
                                    apptsByClientId[clientRec.id].push(appt);
                                } else {
                                    apptsByClientId[clientRec.id] = [appt];
                                }
                            }
                        }
                        setAmpUserApptsRecs(items);
                        if (items.length > 0) {
                            setAmpUserApptRec(items[0]);
                        }
                        setClientRecsByApptId(clientByApptId);
                        setApptRecsByClientId(apptsByClientId);
                    }
                });
            // Remember to unsubscribe when the component unmounts
            return () => subscription.unsubscribe();
        }
    }, [localShowLogs, ampUserId, apptId, resetProviderState]); // Dependency array now includes regionId

    return (
        <AmpUserApptsContext.Provider value={{ ampUserApptsRecs, clientRecsByApptId, apptRecsByClientId, ampUserApptRec }}>
            {children}
        </AmpUserApptsContext.Provider>
    );
}

export default AmpUserApptsProvider;