import { farrierAlertsDB } from "../../data/providers/idb/alerts/FarrierAlertsDB";

// TODO - UPDATE NAV PROPS TO BE MORE TARGETED,
//  FOR VIEWING FARRIER ALERTS RELATED REC, DATA, ETC.
// export function idbFarrierAlertNavPropsFactory(idbAlertRec, idbAlertStoreName, farrierAlertsDb, showLogs) {
export function idbFarrierAlertNavPropsFactory(idbAlertRec, idbAlertStoreName, showLogs) {
    if (showLogs) {
        console.log('idbFarrierAlertNavTargetFactory: idbAlertRec', idbAlertRec);
        console.log('idbFarrierAlertNavTargetFactory: idbAlertStoreName', idbAlertStoreName);
    }

    if (!idbAlertRec || !idbAlertStoreName) {
        if (showLogs) {
            console.error('idbFarrierAlertNavTargetFactory: idbAlertRec or idbAlertStoreName is null');
        }
        return null;
    }

    const navPropsObj = {
        alertNavTarget: null,
        alertNavStateObj: null,
    };
    // let alertNavTarget = null;
    // let alertNavStateObj = null;

    switch(idbAlertStoreName) {
        case farrierAlertsDB.stores.farrierClientAlertsStoreName:
            navPropsObj.alertNavTarget = `/clients`;
            navPropsObj.alertNavStateObj = {};
            break;
        case farrierAlertsDB.stores.farrierPublicClientAlertsStoreName:
            navPropsObj.alertNavTarget = `/public-clients`;
            navPropsObj.alertNavStateObj = {};
            break;
        case farrierAlertsDB.stores.farrierAnimalAlertsStoreName:
            navPropsObj.alertNavTarget = `/animals`;
            navPropsObj.alertNavStateObj = {};
            break;
        case farrierAlertsDB.stores.farrierPublicAnimalAlertsStoreName:
            navPropsObj.alertNavTarget = `/public-animals`;
            navPropsObj.alertNavStateObj = {};
            break;
        default:
            if (showLogs) {
                console.error('idbFarrierAlertNavTargetFactory: idbAlertStoreName not recognized', idbAlertStoreName);
            }
            break;
    }

    return navPropsObj;
}

