import React, { useState, useEffect } from 'react';
import ApptsAccordion from "./ApptsAccordion";
import { useConsoleLog } from "../hooks/logging/console";

export default function AllClientApptsAccordions({ clientAppts, clientRec, showLogs }) {
    const [allAppts, setAllAppts] = useState([]);
    const [futureAppts, setFutureAppts] = useState([]);
    const [pastAppts, setPastAppts] = useState([]);
    const [dateNow, setDateNow] = useState(new Date());

    useEffect(() => {
        if (clientAppts) {
            setAllAppts(clientAppts);
        }
    }, [clientAppts]);

    // useConsoleLog(showLogs, [
    //     { label: 'AllClientApptsAccordion allAppts', value: allAppts },
    //     { label: 'AllClientApptsAccordion futureAppts', value: futureAppts },
    //     { label: 'AllClientApptsAccordion pastAppts', value: pastAppts },
    //     { label: 'AllClientApptsAccordion dateNow', value: dateNow },
    // ]);

    useEffect(() => {
        if (allAppts && allAppts.length > 0) {
            const now = new Date();
            const future = [];
            const past = [];

            allAppts.forEach(appt => {
                const apptDate = new Date(appt.apptDate);
                if (apptDate >= now) {
                    future.push(appt);
                } else {
                    past.push(appt);
                }
            });

            setFutureAppts(future);
            setPastAppts(past);
        }
    }, [allAppts]);

    return (
        <div style={{
            marginBottom: '1.5vh',
            backgroundColor: 'lightgray',
        }}>
            <ApptsAccordion
                appts={futureAppts}
                clientRec={clientRec}
                headerText={`Future Appointments (${futureAppts.length})`}
                // disabled={futureAppts && futureAppts?.length === 0}
                showLogs={showLogs}
            />
            <ApptsAccordion
                appts={pastAppts}
                clientRec={clientRec}
                headerText={`Past Appointments (${pastAppts.length})`}
                // disabled={pastAppts && pastAppts?.length === 0}
                showLogs={showLogs}
            />
        </div>
    );
}
