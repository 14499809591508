import React, { useState, useEffect } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { SortDirection } from "aws-amplify";
// import ApptsByClientsContext from "../contexts/AppointmentsContext";
import { Appointment } from '../../../models';
import ApptsByClientsContext from "../../contexts/ApptsByClientsContext";

// TODO - START HERE, FR, FR, SET CLIENT IDS ON CLIENTS PAGE,
//  USE FUTURE APPTS BY CLIENT TO PROVIDE CARD HEADERS TO SAME SCREEN

// TODO - UPDATE USEEFFECT TO SET FUTURE APPTS BY CLIENT ID TO USE APPTTIMESTAMP INSTEAD OF APPT'S DATETIME

// TODO - ? ADD USEEFFECT TO POPULATE LAST APPT BY CLIENT ID?,
//  PROVIDE THAT OBJ?

const ApptsByClientsDataProvider = ({ children, ampUserId, resetProviderState, ...props }) => {
    const [clientIdsArr, setClientIdsArr] = useState([]);
    const [clientsAppointmentsRecs, setClientsAppointmentsRecs] = useState([]);
    const [appointmentsByClientId, setAppointmentsByClientId] = useState({});
    const [futureAppointmentsByClientId, setFutureAppointmentsByClientId] = useState({});
    // const [lastAppointmentByClientId, setLastAppointmentByClientId] = useState({});
    const [nextAppointmentByClientId, setNextAppointmentByClientId] = useState({});
    const [showLogs, setShowLogs] = useState(false);

    const resetContextState = () => {
        setClientIdsArr([]);
        setClientsAppointmentsRecs([]);
        setAppointmentsByClientId({});
        setFutureAppointmentsByClientId({});
        // setLastAppointmentByClientId({});
        setNextAppointmentByClientId({});
    }

    useEffect(() => {
        if (resetProviderState) {
            resetContextState();
        }
    }, [resetProviderState]);

    // useEffect( setShowLogs( props.showLogs
    useEffect(() => {
        if (props?.showLogs) {
            setShowLogs(props.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [props.showLogs]);

    useEffect(() => {
        if (showLogs) {
            console.log('ApptsByClientsDataProvider clientIdsArr', clientIdsArr);
        }
    }, [clientIdsArr, showLogs]);

    // useEffect( console.log(clientsAppointmentsRecs, appointmentsByClientId
    useEffect(() => {
        if (showLogs) {
            console.log('AppointmentsDataProvider clientsAppointmentsRecs', clientsAppointmentsRecs);
            console.log('AppointmentsDataProvider appointmentsByClientId', appointmentsByClientId);
        }
    }, [clientsAppointmentsRecs, appointmentsByClientId, showLogs]);

    // useEffect( console.log( futureAppointmentsByClientId
    useEffect(() => {
        if (showLogs) {
            console.log('AppointmentsDataProvider futureAppointmentsByClientId', futureAppointmentsByClientId);
        }
    }, [futureAppointmentsByClientId, showLogs]);

    useEffect(() => {
        if (!resetProviderState && ampUserId && clientIdsArr && clientIdsArr.length > 0) {
            const subscription = DataStore.observeQuery(Appointment,
                (a) => a.and(a => [
                    a.ampuserID.eq(ampUserId),
                    a.isDeleted.ne(true),
                    a.or(a => clientIdsArr.map(clientId => a.clientID.eq(clientId)))
                ]),
                {
                    sort: s => s.createdAt(SortDirection.DESCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    console.log(`AppointmentsDataProvider [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                    console.log('AppointmentsDataProvider [Snapshot] items', items);
                    if (isSynced) {
                        setClientsAppointmentsRecs(items);
                        if (items && items.length > 0) {
                            const appointmentsByClient = {};
                            for await (const item of items) {
                                if (appointmentsByClient[item.clientID]) {
                                    appointmentsByClient[item.clientID].push(item);
                                } else {
                                    appointmentsByClient[item.clientID] = [item];
                                }
                            }
                            setAppointmentsByClientId(appointmentsByClient);
                        }
                    }
                });

            return () => subscription.unsubscribe();
        }
    }, [ampUserId, clientIdsArr, showLogs, resetProviderState]);

    // TODO - UPDATE TO USE APPTTIMESTAMP INSTEAD OF APPT'S DATETIME
    useEffect(() => {
        if (!resetProviderState && appointmentsByClientId && Object.keys(appointmentsByClientId).length > 0) {
            const nowUTCDt = new Date(new Date().toUTCString());
            const futureApptsByClient = Object.fromEntries(
                Object.entries(appointmentsByClientId).map(([clientId, appts]) => [
                    clientId,
                    appts.filter(appt => new Date(appt.dateTime) > nowUTCDt)
                ])
            );
            setFutureAppointmentsByClientId(futureApptsByClient);
        }
    }, [appointmentsByClientId, resetProviderState]);

    // TODO - UPDATE TO USE APPTTIMESTAMP INSTEAD OF APPT'S DATETIME
    // useEffect(() => {
    //     if (futureAppointmentsByClientId && Object.keys(futureAppointmentsByClientId).length > 0) {
    //         const nextApptByClient = Object.fromEntries(
    //             Object.entries(futureAppointmentsByClientId).map(([clientId, appts]) => [
    //                 clientId,
    //                 appts.reduce((a, b) => new Date(a.dateTime) < new Date(b.dateTime) ? a : b)
    //             ])
    //         );
    //         setNextAppointmentByClientId(nextApptByClient);
    //     }
    // }, [futureAppointmentsByClientId]);
    useEffect(() => {
        if (!resetProviderState && futureAppointmentsByClientId && Object.keys(futureAppointmentsByClientId).length > 0) {
            const nextApptByClient = Object.fromEntries(
                Object.entries(futureAppointmentsByClientId).map(([clientId, appts]) => [
                    clientId,
                    appts.length > 0
                        ? appts.reduce((a, b) => new Date(a.dateTime) < new Date(b.dateTime) ? a : b)
                        : null // or any default value you prefer for an empty appointments list
                ])
            );
            setNextAppointmentByClientId(nextApptByClient);
        }
    }, [futureAppointmentsByClientId, resetProviderState]);

    return (
        <ApptsByClientsContext.Provider value={{
            clientsAppointmentsRecs,
            appointmentsByClientId,
            futureAppointmentsByClientId,
            // lastAppointmentByClientId,
            nextAppointmentByClientId,
            setClientIdsArr,
        }}>
            {children}
        </ApptsByClientsContext.Provider>
    );
}

export default ApptsByClientsDataProvider;
