import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

// TODO - UPDATED STYLING VIA SX OBJ TO MATCH BASE STYLED DELETE BUTTON

export default function BaseNavigateButton({ label, navTarget, navState, ...props }) {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(navTarget, {
            state: navState,
        });
    };

    return (
        <Button
            variant={props?.variant || "contained"}
            size={props?.size || "small"}
            onClick={handleClick}
            // sx={{
            //     textTransform: 'none',
            //     fontSize: ['5vw', '3vw', '2vw', '1.5vw'],
            //     margin: '1rem',
            //     backgroundColor: '#5F9EA0',
            //     // ...props.style
            // }}
            sx={{
                textTransform: 'none',
                fontSize: props?.fontSize || ['1rem', '1.25rem', '1rem', '1rem'],
                backgroundColor: '#5F9EA0',
                // color: 'white',
                padding: '0.25rem 1.5rem 0.25rem 1.5rem',
                margin: '0.25rem 1rem 0.5rem 1rem',
                ...props.style,
                ...props.customsx,
            }}
            {...props}
        >
            {label}
        </Button>
    );
}

