import React, {useState, useEffect} from 'react';
import {useLocation, useParams} from "react-router-dom";
import DevStateProvider from "../../../data/providers/DevStateProvider";
import {RequireAuth} from '../../auth/RequireAuth';
import AnimalNotesProvider from "../../../data/providers/notes/AnimalNotesProvider";
import AnimalShoePackagesProvider from "../../../data/providers/animals/AnimalShoePackagesProvider";
import AnimalShoeTasksProvider from "../../../data/providers/animals/AnimalShoeTasksProvider";
import AnimalTrimTasksProvider from "../../../data/providers/animals/AnimalTrimTasksProvider";
import AnimalClientProvider from "../../../data/providers/animals/AnimalClientProvider";
import AnimalInfoScreen from "../../../screens/animals/AnimalInfoScreen";
import {RequireSub} from "../../auth/RequireSub";

export default function WrappedAnimalInfo({routerPaths, ampUser, showLogs, resetProvidersStates}) {
    const [animalRec, setAnimalRec] = useState(null);

    const {animalRouterPath} = useParams();
    const location = useLocation();

    const stateAnimalRec = location.state?.animalRec;

    // useEffect( console.log('location', location);
    useEffect(() => {
        console.log('location', location);
    }, [location]);

    // useEffect( setAnimalRec(stateAnimalRec);
    useEffect(() => {
        if (stateAnimalRec) {
            setAnimalRec(stateAnimalRec);
        }
    }, [location]);

    return (
        <DevStateProvider>
            <RequireAuth>
                {animalRec && ampUser ? (
                    <RequireSub ampUser={ampUser}>
                        <AnimalClientProvider clientId={animalRec.clientID} showLogs={showLogs} resetProviderState={resetProvidersStates}>
                            <AnimalTrimTasksProvider animalId={animalRec.id} showLogs={showLogs} resetProviderState={resetProvidersStates}>
                                <AnimalShoeTasksProvider animalId={animalRec.id} showLogs={showLogs} resetProviderState={resetProvidersStates}>
                                    <AnimalShoePackagesProvider animalId={animalRec.id} showLogs={showLogs} resetProviderState={resetProvidersStates}>
                                        <AnimalNotesProvider animalId={animalRec.id} showLogs={showLogs} resetProviderState={resetProvidersStates}>
                                            <AnimalInfoScreen routerPaths={routerPaths} animalRec={animalRec}
                                                              showLogs={showLogs}/>
                                        </AnimalNotesProvider>
                                    </AnimalShoePackagesProvider>
                                </AnimalShoeTasksProvider>
                            </AnimalTrimTasksProvider>
                        </AnimalClientProvider>
                    </RequireSub>
                ) : (
                    <h2>WrappedAnimalInfo No Animal Rec</h2>
                )}
            </RequireAuth>
        </DevStateProvider>
    )
}
