import React, {useState, useEffect} from 'react';
import DevStateProvider from "../../../../data/providers/DevStateProvider";
import {RequireAuth} from "../../../auth/RequireAuth";
import CreateUserSubScreen from "../../../../screens/people/subsPayments/CreateUserSubScreen";
import {useLocation} from "react-router-dom";

// import AmpUserDataProvider from "../../data/providers/AmpUserDataProvider";
// import AmpUserSubscriberProvider from "../../data/providers/AmpUserSubscriberProvider";

export default function WrappedCreateUserSub({ routerPaths, ampUser, ampUserSubscriber, showLogs }) {
    const [ampUserRec, setAmpUserRec] = useState(null);
    const [ampUserSubscriberRec, setAmpUserSubscriberRec] = useState(null);
    const [locationState, setLocationState] = useState(null);

    let location = useLocation();

    // useEffect( setAmpUserRec(ampUser);
    useEffect(() => {
        if (ampUser) {
            setAmpUserRec(ampUser);
        }
    }, [ampUser]);

    // useEffect( setAmpUserSubscriberRec(ampUserSubscriber);
    useEffect(() => {
        if (ampUserSubscriber) {
            setAmpUserSubscriberRec(ampUserSubscriber);
        }
    }, [ampUserSubscriber]);

    // useEffect( setLocationState(location.state);
    useEffect(() => {
        if (location?.state) {
            setLocationState(location.state);
        }
    }, [location.state]);

    // useEffect( console.log('WrappedCreateUserSub locationState', locationState);
    useEffect(() => {
        if (showLogs) {
            console.log('WrappedCreateUserSub locationState', locationState);
        }
    }, [locationState, showLogs]);

    // useEffect( console.log('ampUserRec', ampUserRec); console.log('ampUserSubscriberRec', ampUserSubscriberRec);
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('ampUserRec', ampUserRec);
    //         console.log('ampUserSubscriberRec', ampUserSubscriberRec);
    //     }
    // }, [ampUserRec, ampUserSubscriberRec, showLogs]);

    return (
        <RequireAuth>
            <DevStateProvider>
                {ampUserRec ? (
                    // <AmpUserDataProvider ampUserIdProp={ampUserRec.id} showLogs={showLogs}>
                        <CreateUserSubScreen
                            routerPaths={routerPaths}
                            showLogs={showLogs}
                            ampUser={ampUserRec}
                            ampUserSubscriber={ampUserSubscriberRec}
                            locationState={locationState}
                        />
                    // </AmpUserDataProvider>
                ) : (
                    <h3>Loading User Info...</h3>
                )}
            </DevStateProvider>
        </RequireAuth>
    )
}


