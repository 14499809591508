import React, { createContext, useContext, useEffect, useState } from 'react';
import { openDB } from 'idb';

const SharedPubClientIDBContext = createContext(null);

export function SharedPubClientIDBProvider({ children }) {
    const [db, setDb] = useState(null);

    useEffect(() => {
        // Open the IndexedDB using idb's openDB method
        async function initDB() {
            try {
                const dbInstance = await openDB('SharedPublicClientDB', 1, {
                    upgrade(db) {
                        if (!db.objectStoreNames.contains('sharedPubClientUpdates')) {
                            db.createObjectStore('sharedPubClientUpdates', { keyPath: 'timestamp' });
                        }
                    }
                });
                setDb(dbInstance);
            } catch (error) {
                console.error('Failed to open IndexedDB:', error);
            }
        }

        initDB();
    }, []);

    const addUpdate = async (data) => {
        if (!db) return;

        try {
            const tx = db.transaction('sharedPubClientUpdates', 'readwrite');
            const store = tx.objectStore('sharedPubClientUpdates');
            await store.add({
                timestamp: Date.now(),
                data: data
            });
            await tx.done;
        } catch (error) {
            console.error('Error adding update:', error);
        }
    };

    const getLatestUpdate = async () => {
        if (!db) return null;

        try {
            const tx = db.transaction('sharedPubClientUpdates', 'readonly');
            const store = tx.objectStore('sharedPubClientUpdates');
            let cursor = await store.openCursor(null, 'prev');
            if (cursor) {
                return {
                    timestamp: cursor.value.timestamp,
                    data: cursor.value.data
                };
            }
            return null;
        } catch (error) {
            console.error('Error fetching latest update:', error);
            return null;
        }
    };

    const getLatestUpdateBySharedRouterPath = async (sharedRouterPathParam) => {
        if (!db) return null;

        try {
            const tx = db.transaction('sharedPubClientUpdates', 'readonly');
            const store = tx.objectStore('sharedPubClientUpdates');
            let cursor = await store.openCursor();
            let latestEntry = null;

            while (cursor) {
                const { data, timestamp } = cursor.value;

                // Check if the sharedRouterPath matches the parameter
                if (data.sharedRouterPath === sharedRouterPathParam) {
                    // If this is the first match or has a greater timestamp, update the latestEntry
                    if (!latestEntry || timestamp > latestEntry.timestamp) {
                        latestEntry = {
                            timestamp: timestamp,
                            data: data
                        };
                    }
                }

                // Move to the next cursor entry
                cursor = await cursor.continue();
            }

            return latestEntry;
        } catch (error) {
            console.error('Error fetching latest update:', error);
            return null;
        }
    };


    return (
        <SharedPubClientIDBContext.Provider value={{ addUpdate, getLatestUpdate, getLatestUpdateBySharedRouterPath }}>
            {children}
        </SharedPubClientIDBContext.Provider>
    );
}

export function useSharedPubClientIDB() {
    return useContext(SharedPubClientIDBContext);
}
