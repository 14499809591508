import React, {useState} from 'react';
import { Tooltip, IconButton, ClickAwayListener } from '@mui/material';
import { HelpCircle } from 'lucide-react';

const TooltipWrapper = ({ children, tooltipText }) => {
    // console.warn('TooltipWrapper is deprecated, use Tooltip from @mui/material instead');
    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const handleTooltipToggle = () => {
        setOpen(!open);
    };

    // TODO - START HERE, FR, FR,
    //  MAKE NEW BRANCH,
    //  UPDATE STYLING TO BE CLOSER TO FORM FIELD

    return (
        // <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
            {children}
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
            <Tooltip
                PopperProps={{
                    disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                tabIndex={-1}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={tooltipText}
            >
                <IconButton
                    onClick={handleTooltipToggle}
                    size="small"
                    // style={{ marginLeft: '8px' }}
                    style={{
                        position: 'absolute',
                        right: '8px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        zIndex: 1,
                    }}
                >
                    <HelpCircle size={20} />
                </IconButton>
            </Tooltip>
                </div>
            </ClickAwayListener>
        </div>
    );
};

export default TooltipWrapper;
