import React from 'react';
import BaseNavigateButton from "../../components/links/BaseNavigateButton";

export default function Error404Screen() {
    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h1>404</h1>
            <h2>Page Not Found</h2>
            <p>The page you're looking for doesn't exist or has been moved.</p>
            {/*<Link to="/">Go to Home</Link>*/}
            <BaseNavigateButton
                label={"Home"}
                navTarget={"/"}
            />
        </div>
    );
};
