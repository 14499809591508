import React, {useState, useEffect} from 'react';
import AmpUserSubscriberContext from "../../../contexts/AmpUserSubscriberContext";

// import React, {createContext, useContext, useState, useEffect} from 'react';
// import { DataStore } from '@aws-amplify/datastore';
// import { AmpUserSubscriber } from '../../models';
// import {SortDirection} from "aws-amplify";

// TODO - !!! START HERE, FR FR, FINISH THIS,
//  HANDLE AN AMPUSER ID GETTING PASSED IN THEN FETCH REC

// TODO - MAYBE CANCEL ^^ AND JUST USE THIS TO SET AND FETCH FROM CONTEXT,
//  OBSERVEQUERY NOW IN APP.JS, TOGGLE PARTS OF THIS IN IF NEEDED

const AmpUserSubscriberProvider = ({ children, showLogs, ...props }) => {
    const [ampUserSubscriberRec, setAmpUserSubscriberRec] = useState(null);
    const [ampUserSubscriberId, setAmpUserSubscriberId] = useState(null);
    // const [ampUserRec, setAmpUserRec] = useState(null);
    // const [ampUserId, setAmpUserId] = useState(null);

    // useEffect( console.log( ampUserSubscriberRec, ampUserSubscriberId
    useEffect(() => {
        if (showLogs) {
            console.log('ampUserSubscriberRec', ampUserSubscriberRec);
            console.log('ampUserSubscriberId', ampUserSubscriberId);
        }
    }, [showLogs, ampUserSubscriberRec, ampUserSubscriberId]);

    // useEffect( console.log( ampUserRec, ampUserId
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('ampUserRec', ampUserRec);
    //         console.log('ampUserId', ampUserId);
    //     }
    // }, [showLogs, ampUserRec, ampUserId]);

    // useEffect( console.log( props
    useEffect(() => {
        if (showLogs) {
            if (props) {
                console.log('props', props);
            }
        }
    }, [showLogs, props]);

    // useEffect( setAmpUserSubscriberId(props.ampUserSubscriberIdProp);
    // useEffect(() => {
    //     if (props?.ampUserSubscriberIdProp) {
    //         if (props.ampUserSubscriberIdProp !== ampUserSubscriberId) {
    //             setAmpUserSubscriberId(props.ampUserSubscriberIdProp);
    //         }
    //     }
    // }, [props.ampUserSubscriberIdProp]);

    // useEffect( setAmpUserSubscriberRec(props.ampUserSubscriberProp); setAmpUserSubscriberId(props.ampUserSubscriberIdProp);
    useEffect(() => {
        if (props?.ampUserSubscriberProp) {
            if (props.ampUserSubscriberProp !== ampUserSubscriberRec) {
                setAmpUserSubscriberRec(props.ampUserSubscriberProp);
                setAmpUserSubscriberId(props.ampUserSubscriberProp.id);
            }
        }
    }, [props.ampUserSubscriberProp]);

    // useEffect( setAmpUserId(props.ampUserIdProp); setAmpUserId(ampUserRec.id);
    // useEffect(() => {
    //     if (props?.ampUserIdProp) {
    //         if (props.ampUserIdProp !== ampUserId) {
    //             setAmpUserId(props.ampUserIdProp);
    //         }
    //     }
    //     else if (ampUserRec) {
    //         if (ampUserRec.id !== ampUserId) {
    //             setAmpUserId(ampUserRec.id);
    //         }
    //     }
    // }, [ampUserRec, props.ampUserIdProp]);

    // useEffect( setAmpUserSubscriberId(ampUserSubscriberRec.id); setAmpUserId(ampUserSubscriberRec.ampuserID);
    // useEffect(() => {
    //     if (ampUserSubscriberRec) {
    //         if (ampUserSubscriberRec.id !== ampUserSubscriberId) {
    //             console.error('AmpUserSubscriberProvider ampUserSubscriberRec.id !== ampUserSubscriberId', ampUserSubscriberRec.id, ampUserSubscriberId);
    //             setAmpUserSubscriberId(ampUserSubscriberRec.id);
    //         }
    //         if (ampUserSubscriberRec.ampuserID !== ampUserId) {
    //             console.error('AmpUserSubscriberProvider ampUserSubscriberRec.ampuserID !== ampUserId', ampUserSubscriberRec.ampuserID, ampUserId);
    //             setAmpUserId(ampUserSubscriberRec.ampuserID);
    //         }
    //     }
    // }, [ampUserSubscriberRec, showLogs]);

    // useEffect(() => {
    //     if (ampUserSubscriberId) {
    //         let fetchRecord = false;
    //         if (ampUserSubscriberRec) {
    //             if (ampUserSubscriberRec.id !== ampUserSubscriberId) {
    //                 console.error('AmpUserSubscriberProvider ampUserSubscriberRec.id !== ampUserSubscriberId', ampUserSubscriberRec.id, ampUserSubscriberId);
    //                 // id mismatch, fetch the record
    //                 fetchRecord = true;
    //             }
    //         }
    //         else {
    //             fetchRecord = true;
    //         }
    //         if (fetchRecord) {
    //             const subscription = DataStore.observeQuery(AmpUserSubscriber,
    //                 aus => aus.id.eq(ampUserSubscriberId),
    //                 {
    //                     sort: s => s.createdAt(SortDirection.ASCENDING)
    //                 }
    //             ).subscribe(msg => {
    //                 const { items, isSynced } = msg;
    //                 if (showLogs) {
    //                     console.log(`AmpUserSubscriberProvider a [msg] item count: ${items.length}, isSynced: ${isSynced}`);
    //                     console.log('AmpUserSubscriberProvider a [Snapshot] items', items);
    //                 }
    //                 if (isSynced) {
    //                     if (items && items.length > 0) {
    //                         console.log('AmpUserSubscriberProvider a [Snapshot] items', items);
    //                         const ampUserSubscriber = items[0];
    //                         console.log('AmpUserSubscriberProvider ampUserSubscriber items[0]', ampUserSubscriber);
    //                         setAmpUserSubscriberRec(ampUserSubscriber);
    //                         // setAmpUserSubscriberId(ampUserSubscriber.id);
    //                         // setAmpUserId(ampUserSubscriber.ampuserID);
    //                     }
    //                 }
    //             });
    //             return () => {
    //                 subscription.unsubscribe();
    //             };
    //         }
    //     }
    // }, [ampUserSubscriberId, showLogs]);

    // useEffect(() => {
    //     if (ampUserId) {
    //         let fetchRecord = false;
    //         if (ampUserSubscriberRec) {
    //             if (ampUserSubscriberRec.ampuserID !== ampUserId) {
    //                 console.error('AmpUserSubscriberProvider ampUserSubscriberRec.ampuserID !== ampUserId', ampUserSubscriberRec.ampuserID, ampUserId);
    //                 // id mismatch, fetch the record
    //                 fetchRecord = true;
    //             }
    //         }
    //         else {
    //             fetchRecord = true;
    //         }
    //         if (fetchRecord) {
    //             const subscription = DataStore.observeQuery(AmpUserSubscriber,
    //                 aus => aus.ampuserID.eq(ampUserId),
    //                 {
    //                     sort: s => s.createdAt(SortDirection.ASCENDING)
    //                 }
    //             ).subscribe(msg => {
    //                 const { items, isSynced } = msg;
    //                 if (showLogs) {
    //                     console.log(`AmpUserSubscriberProvider b [msg] item count: ${items.length}, isSynced: ${isSynced}`);
    //                     console.log('AmpUserSubscriberProvider b [Snapshot] items', items);
    //                 }
    //                 if (isSynced) {
    //                     if (items && items.length > 0) {
    //                         console.log('AmpUserSubscriberProvider b [Snapshot] items', items);
    //                         const ampUserSubscriber = items[0];
    //                         console.log('AmpUserSubscriberProvider ampUserSubscriber items[0]', ampUserSubscriber);
    //                         setAmpUserSubscriberRec(ampUserSubscriber);
    //                     }
    //                 }
    //             });
    //             return () => {
    //                 subscription.unsubscribe();
    //             };
    //         }
    //         else {
    //             console.error('AmpUserSubscriberProvider ampUserId but no fetchRecord');
    //         }
    //     }
    // // }, [ampUserSubscriberRec, ampUserId, showLogs]);
    // }, [ampUserId, showLogs]);

    return (
        // <AmpUserSubscriberContext.Provider value={{ ampUserSubscriberRec, setAmpUserSubscriberRec, setAmpUserId, setAmpUserRec }}>
        <AmpUserSubscriberContext.Provider value={{ ampUserSubscriberRec, setAmpUserSubscriberRec }}>
            {children}
        </AmpUserSubscriberContext.Provider>
    );
}

export default AmpUserSubscriberProvider;
