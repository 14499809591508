import {DataStore} from "aws-amplify";

import {Appointment, Animal, AppointmentAnimal,
    AppointmentNote, AnimalNote, ClientNote, Client,
    TrimTask, ShoeTask, ShoePackage, PublicClient} from "../../models";

import { fetchAnimalNotesByAnimalId,
    fetchTrimTasksByAnimalId,
    fetchShoeTasksByAnimalId,
    fetchShoePackagesByAnimalId,
    fetchClientById,
    fetchAnimalRecsByClientId,
    fetchClientNotesByClientId,
    fetchApptRecsByClientId } from "../get/fetchRecords";

// import {batchMarkAsDeleted} from "./deleteRecords";

export async function softDeleteAnimalById(animalId, showLogs = false) {
    if (showLogs) console.log('### softDeleteAnimalById animalId', animalId);
    const resObj = {};
    try {
        const updateAnimalRes = await batchMarkRecsAsDeleted([animalId], Animal, showLogs);
        if (showLogs) console.log('### softDeleteAnimalById updateAnimalRes', updateAnimalRes);
        resObj.updateAnimalRes = updateAnimalRes;
        resObj.success = updateAnimalRes.success;
        return resObj;
    }
    catch (error) {
        console.error('Error soft deleting animal:', error);
        resObj.error = error;
        resObj.success = false;
        return resObj;
    }
}

export async function softDeleteAnimalRelatedRecsByRecType(animalId, relatedRecTypesArr, showLogs = false) {
    // TODO - DECIDE ON RETURNING IF ERROR FOR EACH TYPE OR RETURNING ONE ERROR FOR ALL
    if (showLogs) console.log('### softDeleteAnimalRelatedRecsByRecType animalId', animalId);
    if (showLogs) console.log('### softDeleteAnimalRelatedRecsByRecType relatedRecTypesArr', relatedRecTypesArr);

    const resObj = {};
    // TODO - FIGURE OUT HOW TO REMOVE ONLY THIS ANIMAL FROM APPTS,
    //  OR SOFT DELETE APPTS IF THIS ANIMAL IS THE ONLY ANIMAL IN APPT.,
    //  VERIFY APPTS. BALANCE STAYS SAME AFTER ANIMAL DELETE
    if (relatedRecTypesArr.includes('appointments')) {
        if (showLogs) console.log("### softDeleteAnimalRelatedRecsByRecType delete animal's appts - animalId", animalId);
        // try {
        //     const animalAppointments = await DataStore.query(AppointmentAnimal, (aa) => aa.animalID('eq', animalId));
        //     if (showLogs) console.log('### softDeleteAnimalRelatedRecsByRecType animalAppointments', animalAppointments);
        //     if (animalAppointments.length > 0) {
        //         const appointmentIds = animalAppointments.map((aa) => aa.appointmentID);
        //         const updateAppointmentRes = await batchMarkRecsAsDeleted(appointmentIds, Appointment, showLogs);
        //         if (showLogs) console.log('### softDeleteAnimalRelatedRecsByRecType updateAppointmentRes', updateAppointmentRes);
        //         resObj.updateAppointmentRes = updateAppointmentRes;
        //     }
        // }
        // catch (error) {
        //     console.error('Error soft deleting animal appointments:', error);
        //     resObj.error = error;
        //     resObj.success = false;
        //     return resObj;
        // }
    }
    if (relatedRecTypesArr.includes('animalNotes')) {
        if (showLogs) console.log("### softDeleteAnimalRelatedRecsByRecType delete animal's notes - animalId", animalId);
        try {
            // const animalNotes = await DataStore.query(AnimalNote, (an) => an.animalID('eq', animalId));
            // const animalNotes = await DataStore.query(AnimalNote, an => an.animalID.eq(animalId));
            const animalNotes = await fetchAnimalNotesByAnimalId(animalId);
            if (showLogs) console.log('### softDeleteAnimalRelatedRecsByRecType animalNotes', animalNotes);
            if (animalNotes.length > 0) {
                const animalNoteIds = animalNotes.map((an) => an.id);
                const updateAnimalNoteRes = await batchMarkRecsAsDeleted(animalNoteIds, AnimalNote, showLogs);
                if (showLogs) console.log('### softDeleteAnimalRelatedRecsByRecType updateAnimalNoteRes', updateAnimalNoteRes);
                resObj.updateAnimalNoteRes = updateAnimalNoteRes;
            }
        }
        catch (error) {
            console.error('Error soft deleting animal notes:', error);
            resObj.error = error;
            resObj.success = false;
            return resObj;
        }
    }
    if (relatedRecTypesArr.includes('shoePackages')) {
        if (showLogs) console.log("### softDeleteAnimalRelatedRecsByRecType delete animal's shoe packages - animalId", animalId);
        try {
            // const shoePackages = await DataStore.query(ShoePackage, (sp) => sp.animalID.eq(animalId));
            const shoePackages = await fetchShoePackagesByAnimalId(animalId);
            if (showLogs) console.log('### softDeleteAnimalRelatedRecsByRecType shoePackages', shoePackages);
            if (shoePackages.length > 0) {
                const shoePackageIds = shoePackages.map((sp) => sp.id);
                const updateShoePackageRes = await batchMarkRecsAsDeleted(shoePackageIds, ShoePackage, showLogs);
                if (showLogs) console.log('### softDeleteAnimalRelatedRecsByRecType updateShoePackageRes', updateShoePackageRes);
                resObj.updateShoePackageRes = updateShoePackageRes;
            }
        }
        catch (error) {
            console.error('Error soft deleting animal shoe packages:', error);
            resObj.error = error;
            resObj.success = false;
            return resObj;
        }
    }
    if (relatedRecTypesArr.includes('trims')) {
        if (showLogs) console.log("### softDeleteAnimalRelatedRecsByRecType delete animal's trim tasks - animalId", animalId);
        try {
            // const trimTasks = await DataStore.query(TrimTask, (tt) => tt.animalID.eq(animalId));
            const trimTasks = await fetchTrimTasksByAnimalId(animalId);
            if (showLogs) console.log('### softDeleteAnimalRelatedRecsByRecType trimTasks', trimTasks);
            if (trimTasks.length > 0) {
                const trimTaskIds = trimTasks.map((tt) => tt.id);
                const updateTrimTaskRes = await batchMarkRecsAsDeleted(trimTaskIds, TrimTask, showLogs);
                if (showLogs) console.log('### softDeleteAnimalRelatedRecsByRecType updateTrimTaskRes', updateTrimTaskRes);
                resObj.updateTrimTaskRes = updateTrimTaskRes;
            }
        }
        catch (error) {
            console.error('Error soft deleting animal trim tasks:', error);
            resObj.error = error;
            resObj.success = false;
            return resObj;
        }
    }
    if (relatedRecTypesArr.includes('shoeings')) {
        if (showLogs) console.log("### softDeleteAnimalRelatedRecsByRecType delete animal's shoe tasks - animalId", animalId);
        try {
            // const shoeTasks = await DataStore.query(ShoeTask, (st) => st.animalID.eq(animalId));
            const shoeTasks = await fetchShoeTasksByAnimalId(animalId);
            if (showLogs) console.log('### softDeleteAnimalRelatedRecsByRecType shoeTasks', shoeTasks);
            if (shoeTasks.length > 0) {
                const shoeTaskIds = shoeTasks.map((st) => st.id);
                const updateShoeTaskRes = await batchMarkRecsAsDeleted(shoeTaskIds, ShoeTask, showLogs);
                if (showLogs) console.log('### softDeleteAnimalRelatedRecsByRecType updateShoeTaskRes', updateShoeTaskRes);
                resObj.updateShoeTaskRes = updateShoeTaskRes;
            }
        }
        catch (error) {
            console.error('Error soft deleting animal shoe tasks:', error);
            resObj.error = error;
            resObj.success = false;
            return resObj;
        }
    }
    return resObj;
}

export async function softDeleteClientById(clientId, showLogs = false) {
    if (showLogs) console.log('### softDeleteClientById clientId', clientId);
    const resObj = {};
    try {
        const updateClientRes = await batchMarkRecsAsDeleted([clientId], Client, showLogs);
        resObj.updateClientRes = updateClientRes;

        if (showLogs) console.log('### softDeleteClientById updateClientRes', updateClientRes);

        if (updateClientRes.success) {
            // check if client has publicClient record
            const publicClientRecs = await DataStore.query(PublicClient, (pc) => pc.clientID.eq(clientId));
            if (showLogs) console.log('### softDeleteClientById publicClientRecs', publicClientRecs);
            if (publicClientRecs && publicClientRecs?.length > 0) {
                const publicClientIds = publicClientRecs.map((pc) => pc.id);
                const updatePublicClientRes = await batchMarkRecsAsDeleted(publicClientIds, PublicClient, showLogs);
                if (showLogs) console.log('### softDeleteClientById updatePublicClientRes', updatePublicClientRes);
                resObj.updatePublicClientRes = updatePublicClientRes;
            }
        }

        resObj.success = updateClientRes.success;
        return resObj;
    }
    catch (error) {
        console.error('Error soft deleting client:', error);
        resObj.error = error;
        resObj.success = false;
        return resObj;
    }
}

export async function softDeleteClientRelatedRecsByRecType(clientId, relatedRecTypesArr, showLogs = false) {
    // TODO - DECIDE ON RETURNING IF ERROR FOR EACH TYPE OR RETURNING ONE ERROR FOR ALL
    if (showLogs) console.log('### softDeleteClientRelatedRecsByRecType clientId', clientId);
    if (showLogs) console.log('### softDeleteClientRelatedRecsByRecType relatedRecTypesArr', relatedRecTypesArr);

    const resObj = {};
    if (relatedRecTypesArr.includes('clientNotes')) {
        if (showLogs) console.log("### softDeleteClientRelatedRecsByRecType delete client's notes - clientId", clientId);
        try {
            const clientNotes = await fetchClientNotesByClientId(clientId);
            if (showLogs) console.log('### softDeleteClientRelatedRecsByRecType clientNotes', clientNotes);
            if (clientNotes.length > 0) {
                const clientNoteIds = clientNotes.map((cn) => cn.id);
                const updateClientNotesRes = await batchMarkRecsAsDeleted(clientNoteIds, ClientNote, showLogs);
                if (showLogs) console.log('### softDeleteClientRelatedRecsByRecType updateClientNotesRes', updateClientNotesRes);
                resObj.updateClientNotesRes = updateClientNotesRes;
            }
        }
        catch (error) {
            console.error('Error soft deleting client notes:', error);
            resObj.error = error;
            resObj.success = false;
            return resObj;
        }
    }
    if (relatedRecTypesArr.includes('animals')) {
        if (showLogs) console.log("### softDeleteClientRelatedRecsByRecType delete client's animals - clientId", clientId);
        try {
            const animals = await fetchAnimalRecsByClientId(clientId);
            if (showLogs) console.log('### softDeleteClientRelatedRecsByRecType animals', animals);
            if (animals.length > 0) {
                const animalIds = animals.map((a) => a.id);
                const updateAnimalsRes = await batchMarkRecsAsDeleted(animalIds, Animal, showLogs);
                if (showLogs) console.log('### softDeleteClientRelatedRecsByRecType updateAnimalsRes', updateAnimalsRes);
                resObj.updateAnimalsRes = updateAnimalsRes;
            }
        }
        catch (error) {
            console.error('Error soft deleting animals:', error);
            resObj.error = error;
            resObj.success = false;
            return resObj;
        }
    }
    if (relatedRecTypesArr.includes('appointments')) {
        if (showLogs) console.log("### softDeleteClientRelatedRecsByRecType delete client's appts - clientId", clientId);
        try {
            const clientAppointments = await fetchApptRecsByClientId(clientId);
            if (showLogs) console.log('### softDeleteClientRelatedRecsByRecType clientAppointments', clientAppointments);
            if (clientAppointments.length > 0) {
                const appointmentIds = clientAppointments.map((a) => a.id);
                const updateAppointmentsRes = await batchMarkRecsAsDeleted(appointmentIds, Appointment, showLogs);
                if (showLogs) console.log('### softDeleteClientRelatedRecsByRecType updateAppointmentsRes', updateAppointmentsRes);
                resObj.updateAppointmentsRes = updateAppointmentsRes;
            }
        }
        catch (error) {
            console.error('Error soft deleting client appointments:', error);
            resObj.error = error;
            resObj.success = false;
            return resObj;
        }
    }
    // TODO - IF NOT RETURNING ERROR FOR EACH TYPE, RETURN ONE ERROR FOR ALL
    // TODO - IF NOT RETURNING ERROR FOR EACH TYPE, RETURN SUCCESS FOR ALL
    resObj.success = true;
    return resObj;
}

export async function batchMarkRecsAsDeleted(recordIds, ModelType, showLogs) {
    const resObj = {};
    try {
        const updates = await Promise.all(recordIds.map(async (id) => {
            const original = await DataStore.query(ModelType, id);
            if (!original) {
                console.warn(`Record with id ${id} not found`);
                resObj[id] = {
                                modelType: ModelType,
                                recId: id,
                                msg: 'Record not found',
                                success: false
                            };
                return null;
            }
            return ModelType.copyOf(original, updated => {
                updated.isDeleted = true;
            });
        }));

        const validUpdates = updates.filter(update => update !== null);

        // Save each update individually
        for (const update of validUpdates) {
            const updatedRec = await DataStore.save(update);
            if (showLogs) console.log('Successfully marked record as deleted:', updatedRec);
            resObj[updatedRec.id] = {
                                modelType: ModelType,
                                recId: updatedRec.id,
                                updatedRec: updatedRec,
                                msg: 'Record marked as deleted',
                                success: true
                            };
        }

        console.log(`Successfully marked ${validUpdates.length} records as deleted`);
        resObj.validUpdatesLength = validUpdates.length;
        resObj.success = validUpdates.length === recordIds.length;
        // return validUpdates.length;
        return resObj;
    } catch (error) {
        console.error('Error batch marking records as deleted:', error);
        resObj.error = error;
        resObj.success = false;
        return resObj;
        // throw error; // Re-throw the error for the calling function to handle
    }
}
