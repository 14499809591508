import React, { useState, useEffect, useContext } from 'react';
import AmpUserClientsContext from "../../../data/contexts/AmpUserClientsContext";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import { createClientNote } from "../../../data/create/person/ClientNote";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { filterClientByName } from "../../../data/filter/filterRecs";
import StyledTextField from "../../text/StyledTextField";
import BaseStyledButton from "../../buttons/BaseStyledButton";
import { clientNoteFormObj } from "../../../utils/formObjects";
import { useConsoleLog } from "../../hooks/logging/console";
import CreateNewRecSuccessDialog from "../../modals/CreateNewRecSuccessDialog";
import BaseNavigateButton from "../../links/BaseNavigateButton";

// import {generateUniqueKey} from "../../utils/helpers";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

// TODO - IMPORT, USE PROGRESS, SUCCESS, (CREATE THIS) - ERROR DIALOGS

export default function CreateClientNoteForm(props) {
    const [ampUser, setAmpUser] = useState(null);
    const [showLogs, setShowLogs] = useState(false);
    const [clientRec, setClientRec] = useState(null);
    const [clientNoteVal, setClientNoteVal] = useState('');
    const [newClientNoteRec, setNewClientNoteRec] = useState(null);
    const [newClientNoteRecResObj, setNewClientNoteRecResObj] = useState(null);
    const [allClientRecs, setAllClientRecs] = useState([]);
    const [selectedClientRec, setSelectedClientRec] = useState(null);
    const [selectedClientName, setSelectedClientName] = useState('');
    const [formValues, setFormValues] = useState(clientNoteFormObj);
    const [displaySuccessDialog, setDisplaySuccessDialog] = useState(false);

    // const [clientNoteRec, setClientNoteRec] = useState(null);

    const {ampUserClientsRecs, setAmpUserClientsRecs} = useContext(AmpUserClientsContext);

    // useEffect( setShowLogs(
    useEffect(() => {
        if (props.showLogs) {
            setShowLogs(props.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [props.showLogs]);

    // useEffect( setAmpUser(props.ampUser);
    useEffect(() => {
        if (props.ampUser) {
            setAmpUser(props.ampUser);
        }
    }, [props.ampUser]);

    // useEffect( setClientRec(props.clientRec);
    useEffect(() => {
        if (props?.clientRec) {
            setClientRec(props.clientRec);
        }
    }, [props.clientRec]);

    useEffect(() => {
        if (clientRec) {
            if (!selectedClientRec || selectedClientName === '') {
                // setSelectedClientRec(clientRec[0]);
                setSelectedClientRec(clientRec);
                // setSelectedClientName(clientRec[0].name);
                setSelectedClientName(clientRec?.name || '');
            }
        }
    }, [clientRec, selectedClientRec, selectedClientName]);

    // useEffect( setAllClientRecs(ampUserClientsRecs);
    useEffect(() => {
        if (ampUserClientsRecs) {
            setAllClientRecs(ampUserClientsRecs);
        }
    }, [ampUserClientsRecs]);

    // useEffect( console.log('ampUserClientsRecs', ampUserClientsRecs);
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('ampUserClientsRecs', ampUserClientsRecs);
    //     }
    // }, [ampUserClientsRecs, showLogs]);

    useConsoleLog(showLogs, [{label: 'CreateClientNoteForm ampUserClientsRecs', value: ampUserClientsRecs}]);

    // useEffect( logs all the things
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('ampUser', ampUser);
    //         console.log('selectedClientRec', selectedClientRec);
    //         console.log('selectedClientName', selectedClientName);
    //         console.log('newClientNoteRec', newClientNoteRec);
    //         console.log('newClientNoteRecResObj', newClientNoteRecResObj);
    //         // console.log('selectedClientRec', selectedClientRec);
    //         console.log('formValues', formValues);
    //     }
    // }, [ampUser, selectedClientRec, newClientNoteRec, showLogs, formValues, selectedClientName, newClientNoteRecResObj]);

    useConsoleLog(showLogs, [{label: 'CreateClientNoteForm selectedClientRec', value: selectedClientRec}]);
    useConsoleLog(showLogs, [{label: 'CreateClientNoteForm selectedClientName', value: selectedClientName}]);
    useConsoleLog(showLogs, [{label: 'CreateClientNoteForm newClientNoteRec', value: newClientNoteRec}]);
    useConsoleLog(showLogs, [{label: 'CreateClientNoteForm newClientNoteRecResObj', value: newClientNoteRecResObj}]);
    useConsoleLog(showLogs, [{label: 'CreateClientNoteForm formValues', value: formValues}]);

    // useEffect( setSelectedClientRec(clientRec[0]);
    useEffect(() => {
        if (selectedClientName) {
            const filteredClientRec = filterClientByName(allClientRecs, selectedClientName);
            // setSelectedClientRec(clientRec[0]);
            setSelectedClientRec(filteredClientRec);
            // setFormValues({...formValues, clientId: clientRec[0].id})
            setFormValues({...formValues, clientId: filteredClientRec?.id})
        }
    }, [selectedClientName, allClientRecs]);

    async function onCreateClientNoteRec() {
        // TODO - USE PROGRESS, SUCCESS DIALOGS, USE SUCCESS DIALOG TO NAVIGATE TO CLIENT INFO SCREEN,
        //  OR EDIT THIS NOTE SCREEN
        if (selectedClientRec && formValues?.note.length > 0) {
            const createClientNoteRecRes = await createClientNote(selectedClientRec[0].id, formValues.note, showLogs);
            setNewClientNoteRecResObj(createClientNoteRecRes);
            if (createClientNoteRecRes.success) {
                setNewClientNoteRec(createClientNoteRecRes.newClientNote);
                setDisplaySuccessDialog(true);
            }
            else {
                // TODO - ADD ERROR DIALOG
                console.error('createClientNoteRecRes.error', createClientNoteRecRes.error);
                setNewClientNoteRec(null);
            }
        }
        else {
            // TODO - ADD ERROR DIALOG
            // console.error('No client selected');
            if (!selectedClientRec) {
                console.error('No client selected');
                alert('No client selected');
            }
            else if (formValues?.note.length === 0) {
                console.error('No note entered');
                alert('No note entered');
            }
            else {
                console.error('Error creating client note');
                alert('Error creating client note');
            }
        }
    }

    const handleClientNoteTextFieldChange = (event) => {
        setClientNoteVal(event.target.value);
        setFormValues({...formValues, note: event.target.value})
    };

    const nextScreenNavButtonFactory =  () => {
        return (
            <BaseNavigateButton
                label="Client Info"
                // to={`/client/${selectedClientRec[0].routerPath}`}
                navState={{clientRec: selectedClientRec[0]}}
                navTarget={`/client-info/${selectedClientRec[0]?.routerPath}`}
            />
        )
    }

    return (
        <div>
            <Divider>
                <Chip label="Client"/>
            </Divider>
            <div>
                <FormControl sx={{m: 1, width: 300}}>
                    <InputLabel id={`create-client-note-client-input-label`}>Client</InputLabel>
                    <Select
                        labelId={`create-client-note-client-label-id`}
                        id={`create-client-note-client-select`}
                        value={selectedClientName || ''}
                        onChange={(event) => {
                            setSelectedClientName(event.target.value);
                            setFormValues({...formValues, clientName: event.target.value})
                        }}
                        MenuProps={MenuProps}
                    >
                        {allClientRecs.map((eachClientRec, index) => (
                            <MenuItem
                                key={`create-client-note-clientRec-${index}`}
                                value={eachClientRec.name}
                            >
                                {eachClientRec.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <Divider>
                <Chip label="Client Note"/>
            </Divider>
            <div>
                <StyledTextField
                    id="create-client-note-note"
                    label="Note"
                    value={clientNoteVal}
                    onChange={handleClientNoteTextFieldChange}
                />
            </div>
            <div>
            <BaseStyledButton
                label="Create Client Note"
                onClick={onCreateClientNoteRec}
            />
            </div>
            <div>
                {newClientNoteRecResObj?.success && displaySuccessDialog ? (
                    <CreateNewRecSuccessDialog
                        open={displaySuccessDialog}
                        handleClose={() => setDisplaySuccessDialog(false)}
                        recType={'Client Note'}
                        newRecAttrsObj={{newNote: newClientNoteRec.note}}
                        showLogs={showLogs}
                        dialogTitleProps={'Client Note Created'}
                        nextScreenNavButton={nextScreenNavButtonFactory()}
                    />
                ) : null}
            </div>
        </div>
    )
}
