import React from "react";
import BaseNavigateButton from "../links/BaseNavigateButton";

export const homeNavButtonFactory = () => (
    <BaseNavigateButton
        label={"Home"}
        navTarget={'/home'}
    />
);

export const animalsNavButtonFactory = () => (
    <BaseNavigateButton
        label={"Animals"}
        navTarget={'/animals'}
    />
);

export const apptsNavButtonFactory = () => (
    <BaseNavigateButton
        label={"Appointments"}
        navTarget={'/appointments'}
    />
);

export const clientsNavButtonFactory = () => (
    <BaseNavigateButton
        label={"Clients"}
        navTarget={'/clients'}
    />
);

export const createClientNavButtonFactory = () => (
    <BaseNavigateButton
        label={"Create Client"}
        navTarget={'/create-client'}
    />
);

export const animalInfoNavButtonFactory = (animalRec) => (
    <BaseNavigateButton
        label={"Animal Info"}
        navTarget={`/animal-info/${animalRec?.routerPath}`}
        navState={{animalRec}}
    />
);

export const clientInfoNavButtonFactory = (clientRec) => (
    <BaseNavigateButton
        label={"Client Info"}
        navTarget={`/client-info/${clientRec?.routerPath}`}
        navState={{clientRec}}
    />
);

export const clientApptsNavButtonFactory = (clientRec) => (
    <BaseNavigateButton
        label={"Client's Appts."}
        navTarget={`/client-appts/${clientRec?.routerPath}`}
        navState={{clientRec: clientRec}}
    />
);

export const clientAnimalsNavButtonFactory = (clientRec) => (
    <BaseNavigateButton
        label={"Client's Animals"}
        navTarget={`/client-animals/${clientRec?.routerPath}`}
        navState={{clientRec: clientRec}}
    />
);
