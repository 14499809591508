import React, {useState, useEffect} from 'react';
import StyledListCard from "./StyledListCard";
import StyledCardContentTypography from "../text/StyledCardContentTypography";
import { useConsoleLog } from "../hooks/logging/console";

export default function AmpUserClientCard(props) {
    const [showLogs, setShowLogs] = useState(false);
    const [clientRec, setClientRec] = useState(null);

    // useEffect( setShowLogs( props.showLogs
    useEffect(() => {
        if (props.showLogs) {
            setShowLogs(props.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [props.showLogs]);

    // useEffect( setClientRec(props.clientRec);
    useEffect(() => {
        if (props.clientRec) {
            setClientRec(props.clientRec);
        }
    }, [props.clientRec]);

    // useEffect( console.log(clientRec
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('AmpUserClientCard clientRec', clientRec);
    //     }
    // }, [clientRec, showLogs]);

    useConsoleLog(showLogs, [{label: 'AmpUserClientCard clientRec', value: clientRec}]);

    // TODO - ADD REST OF CLIENT INFO

    return (
        <StyledListCard>
        {clientRec ? (
            <>
                <StyledCardContentTypography
                    text={`Name: ${clientRec?.name || '-'}`}
                />
                <StyledCardContentTypography
                    text={`Phone: ${clientRec?.phone || '-'}`}
                />
                <StyledCardContentTypography
                    text={`Email: ${clientRec?.email || '-'}`}
                />
                <StyledCardContentTypography
                    text={`Balance: $${clientRec?.balance || '-'}`}
                />
                <StyledCardContentTypography
                    text={`Status: ${clientRec?.clientStatus || '-'}`}
                />
                <StyledCardContentTypography
                    text={`Address: ${clientRec?.streetAddress || '-'}`}
                />
                <StyledCardContentTypography
                    text={`${clientRec?.city || '-'}, ${clientRec?.state || '-'} ${clientRec?.zipCode || '-'}`}
                />
                <StyledCardContentTypography
                    text={`Gate Code: ${clientRec?.gateCode || '-'}`}
                />
            </>
        ) : (
            <StyledCardContentTypography
                text={`No Client Rec`}
            />
        )}
        </StyledListCard>
    );
}
