import React, {useEffect, useState} from 'react';
import { Modal, Box, TextField, Button, Typography } from '@mui/material';
import { sumTaskAmount, sumTaskDuration } from "../../../utils/numbers";
import { deepClone } from "../../../utils/helpers";


// TODO - HANDLE ORIG VS EDITED IN PARENT COMPONENT
export default function UpdateShoeTaskModal({ open,
                                 onClose,
                                 formData,
                                 handleSaveAndUpdateNext,
                                 handleSaveAndUpdatePrev,
                                 showLogs,
                                 formIndex,
                                 editedShoeTaskFormVals,
                                 setUpdatedShoeTaskFormUniqueId,
                                 setShoeTaskModalOpen,
                                 setEditedShoeTaskFormVals,
                                 currentShoeTaskAnimalIndex,
                                 editedSelectedShoeAnimalRecs,
                                 setCurrentShoeTaskAnimalIndex }) {
    const [editedTotalAmount, setEditedTotalAmount] = useState(0);
    const [editedTotalDuration, setEditedTotalDuration] = useState(0);
    const [localShowLogs, setLocalShowLogs] = useState(false);
    const [origFormData, setOrigFormData] = useState(() => deepClone(formData));
    const [editedFormData, setEditedFormData] = useState(() => deepClone(formData));

    // useEffect( setLocalShowLogs(showLogs);
    useEffect(() => {
        setLocalShowLogs(showLogs);
    }, [showLogs]);

    // useEffect( setEditedTotalAmount(editedAmount);
    useEffect(() => {
        const editedAmount = sumTaskAmount(editedFormData.amount, editedFormData.addedCost);
        setEditedTotalAmount(editedAmount);
    }, [editedFormData.addedCost, editedFormData.amount]);

    // useEffect( setEditedFormData totalAmount: editedTotalAmount
    useEffect(() => {
        if (editedFormData.totalAmount !== editedTotalAmount) {
            setEditedFormData(prevData => ({
                ...prevData,
                totalAmount: editedTotalAmount
            }));
        }
    }, [editedTotalAmount]);

    // useEffect( setEditedTotalDuration(editedDuration);
    useEffect(() => {
        const editedDuration = sumTaskDuration(editedFormData.duration, editedFormData.addedTime);
        setEditedTotalDuration(editedDuration);
    }, [editedFormData.addedTime, editedFormData.duration]);

    // useEffect( setEditedFormData( totalDuration: editedTotalDuration
    useEffect(() => {
        if (editedFormData.totalDuration !== editedTotalDuration) {
            setEditedFormData(prevData => ({
                ...prevData,
                totalDuration: editedTotalDuration
            }));
        }
    }, [editedTotalDuration]);

    // useEffect( setOrigFormData(deepClone(formData)); setEditedFormData(deepClone(formData));
    useEffect(() => {
        // Set initial state with a deep clone to prevent reference issues.
        setOrigFormData(deepClone(formData));
        setEditedFormData(deepClone(formData));
    }, [formData]);

    // const updateFormData = (formKey, formVal) => {
    //     setEditedFormData(prevData => ({
    //         ...prevData,
    //         [formKey]: formVal
    //     }));
    // };

    const updateFormData = (formKey, formVal) => {
        if (formVal === "") {
            formVal = 0;
        }
        setEditedFormData(prevData => ({
            ...prevData,
            [formKey]: formVal
        }));
    };

    const handleChange = (field, value) => {
        // If the input is empty, set it to a string for free text input fields,
        // or zero for number fields where a default zero value is acceptable.
        const newValue = value === "" ? "" : value;
        updateFormData(field, newValue);
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
                <Typography variant="h6">Edit Task for {origFormData.name}</Typography>
                <form>
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Duration (mins)"
                        name="duration"
                        type="number"
                        value={editedFormData.duration || ""} // If duration is 0, it will show as an empty field allowing user to type freely
                        placeholder="Enter duration in minutes"
                        onChange={e => handleChange('duration', e.target.value === "" ? "" : Number(e.target.value))}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Amount"
                        name="amount"
                        type="number"
                        value={editedFormData.amount || ""} // If amount is 0, it will show as an empty field allowing user to type freely
                        placeholder="Enter amount in dollars"
                        onChange={e => handleChange('amount', e.target.value === "" ? "" : Number(e.target.value))}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Added Cost"
                        name="addedCost"
                        type="number"
                        value={editedFormData.addedCost || ""}
                        placeholder="Enter added cost in dollars"
                        onChange={e => handleChange('addedCost', e.target.value === "" ? "" : Number(e.target.value))}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Added Time (mins)"
                        name="addedTime"
                        type="number"
                        value={editedFormData.addedTime || ""}
                        placeholder="Enter added time in minutes"
                        onChange={e => handleChange('addedTime', e.target.value === "" ? "" : Number(e.target.value))}
                    />
                    <Typography>Total Amount ($): {editedTotalAmount}</Typography>
                    <Typography>Total Time (mins.): {editedTotalDuration}</Typography>
                    <div>
                        {/*<Button onClick={() => handleSaveAndUpdatePrev(formIndex, editedFormData)}>Prev</Button>*/}
                        <Button onClick={() => handleSaveAndUpdatePrev(formIndex,
                            editedFormData,
                            editedShoeTaskFormVals,
                            setEditedShoeTaskFormVals,
                            setUpdatedShoeTaskFormUniqueId,
                            currentShoeTaskAnimalIndex,
                            editedSelectedShoeAnimalRecs,
                            setCurrentShoeTaskAnimalIndex,
                            showLogs)}>Prev</Button>
                        {/*<Button onClick={() => handleSaveAndUpdateNext(formIndex, editedFormData)}>Next</Button>*/}
                        <Button onClick={() => handleSaveAndUpdateNext(formIndex,
                            editedFormData,
                            editedShoeTaskFormVals,
                            setEditedShoeTaskFormVals,
                            setUpdatedShoeTaskFormUniqueId,
                            currentShoeTaskAnimalIndex,
                            editedSelectedShoeAnimalRecs,
                            setCurrentShoeTaskAnimalIndex,
                            showLogs)}>Next</Button>
                        {/*<Button onClick={() => onClose(formIndex, editedFormData)}>Close</Button>*/}
                        <Button onClick={() => onClose(formIndex,
                            editedFormData,
                            editedShoeTaskFormVals,
                            setEditedShoeTaskFormVals,
                            setUpdatedShoeTaskFormUniqueId,
                            setShoeTaskModalOpen,
                            showLogs)}>Close</Button>
                    </div>
                </form>
            </Box>
        </Modal>
    );
}

// export default UpdateShoeTaskModal;
