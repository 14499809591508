import React, {useState, useEffect} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function DeleteTrimTaskConfirmationDialog({
                                                             open,
                                                             handleClose,
                                                             handleConfirm,
                                                             modelRecToDelete,
                                                             showLogs,
                                                             animalLastTask
                                                         }) {
    const [modelRec, setModelRec] = useState(null);
    const [deleteTaskApptAnimal, setDeleteTaskApptAnimal] = useState(false);

    // useEffect( setModelRec(modelRecToDelete);
    useEffect(() => {
        if (modelRecToDelete) {
            setModelRec(modelRecToDelete);
        }
    }, [modelRecToDelete]);

    // useEffect( console.log( modelRec, animalLastTask
    useEffect(() => {
        if (showLogs) {
            console.log('modelRec', modelRec);
            console.log('animalLastTask', animalLastTask);
        }
    }, [showLogs, modelRec, animalLastTask]);

    const handleCheckboxChange = (event) => { // Step 4
        setDeleteTaskApptAnimal(event.target.checked);
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{"Confirm Delete Action"}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this record?
                </DialogContentText>
                {animalLastTask && ( // Step 5: Conditionally render the Checkbox
                    <FormControlLabel
                        control={<Checkbox checked={deleteTaskApptAnimal} onChange={handleCheckboxChange}/>}
                        label="Remove the associated animal for this appointment?"
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={() => {
                    handleConfirm(deleteTaskApptAnimal)
                }} autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}

// export default DeleteTrimTaskConfirmationDialog;
