import {DataStore} from "aws-amplify";
import { Client,
    PublicClient,
    PublicClientStatus,
    SharedPublicClientStatus,
    SharedPublicClientUpdateStatus } from "../../../models";
import { generateSaltedRouterPath } from "../../../utils/breadcrumbsRoutes";
import { getNowAsAWSDateTime } from "../../../utils/datesTimes";
import { ampUserEditPublicClientAttrs, publicSharedEditPublicClientAttrs } from "../../../utils/formObjects";
import { v4 as uuidv4 } from 'uuid';


export async function updateClientRec(ampUserRec, clientId, clientFormValsDiff, showLogs) {
    console.log('updateClientRec(clientId, clientFormValsDiff)', clientId, clientFormValsDiff);
    try {
        const original = await DataStore.query(Client, clientId);
        // Check if the client exists
        if (!original) {
            console.error("Client not found");
            return {success: false, error: "Client not found"};
        }
        if (!original.routerPath) {
            // const clientRouterPath = modelRouterPathAttrFactory(`${ampUserRec.name}-${clientId}`);
            const routerPathUUID = uuidv4();
            const clientRouterPath = generateSaltedRouterPath(`${ampUserRec.id}-${clientId}-${routerPathUUID}`);
            clientFormValsDiff.routerPath = clientRouterPath;
        }
        clientFormValsDiff.lastEditedAt = getNowAsAWSDateTime();
        clientFormValsDiff.isEdited = true;
        
        const updatedClient = await DataStore.save(
            Client.copyOf(original, updated => {
                Object.keys(clientFormValsDiff).forEach(key => {
                    // Update the corresponding field in the Client model
                    updated[key] = clientFormValsDiff[key];
                });
            })
        );
        return {updatedClient, success: true};
    } catch (error) {
        console.error('updateClientRec error', error);
        return {success: false, error: error};
    }
}

// Allows update for a known user, not for a public shared form
export async function ampUserUpdatePublicClientRec(publicClientId, publicClientFormValsDiff, ampUserRec, showLogs) {
    if (showLogs) {
        console.log('ampUserUpdatePublicClientRec publicClientId', publicClientId);
        console.log('ampUserUpdatePublicClientRec publicClientFormValsDiff', publicClientFormValsDiff);
    }
    if (!publicClientId) {
        console.error("Public Client ID is missing");
        return {success: false, error: "Public Client ID is missing"};
    }
    if (!ampUserRec) {
        console.error("AmpUser Record is missing");
        return {success: false, error: "AmpUser Record is missing"};
    }
    try {
        const original = await DataStore.query(PublicClient, publicClientId);

        // Check if the public client exists
        if (!original) {
            console.error("Public Client not found");
            return {success: false, error: "Public Client not found"};
        }

        const formValsDiffAttrsObj = {};
        Object.keys(publicClientFormValsDiff).forEach(key => {
            if (ampUserEditPublicClientAttrs.includes(key)) {
                formValsDiffAttrsObj[key] = publicClientFormValsDiff[key];
            }
        });

        if (!original.routerPath) {
            const routerPathUUID = uuidv4();
            const publicClientRouterPath = generateSaltedRouterPath(`${ampUserRec.id}-${publicClientId}-${routerPathUUID}`);
            // publicClientFormValsDiff.routerPath = publicClientRouterPath;
            formValsDiffAttrsObj.routerPath = publicClientRouterPath;
        }

        if (!original.sharedRouterPath) {
            const sharedRouterPathUUID = uuidv4();
            const publicClientSharedRouterPath = generateSaltedRouterPath(`${publicClientId}-${ampUserRec.id}-${sharedRouterPathUUID}`);
            // publicClientFormValsDiff.sharedRouterPath = publicClientSharedRouterPath;
            formValsDiffAttrsObj.sharedRouterPath = publicClientSharedRouterPath;
        }

        if (!publicClientFormValsDiff.publicUpdatingEnabled) {
            // Record owner did not re-enable editing,
            // Ensure editing is disabled until the record owner enables it again
            // formValsDiffAttrsObj.publicClientStatus = PublicClientStatus.EDITING_DISABLED;
            formValsDiffAttrsObj.publicUpdatingEnabled = false;
        }

        if (!publicClientFormValsDiff.sharedPublicClientStatus) {
            // Record owner did not re-enable editing,
            // Ensure editing is disabled until the record owner enables it again
            formValsDiffAttrsObj.sharedPublicClientStatus = SharedPublicClientStatus.DISABLED;
        }

        if (showLogs) {
            console.log('ampUserUpdatePublicClientRec original', original);
            console.log('ampUserUpdatePublicClientRec publicClientFormValsDiff', publicClientFormValsDiff);
            console.log('ampUserUpdatePublicClientRec formValsDiffAttrsObj', formValsDiffAttrsObj);
        }

        const updatedPublicClient = await DataStore.save(
            PublicClient.copyOf(original, updated => {
                // Object.keys(publicClientFormValsDiff).forEach(key => {
                //     // Update the corresponding field in the PublicClient model
                //     updated[key] = publicClientFormValsDiff[key];
                // });
                Object.keys(formValsDiffAttrsObj).forEach(key => {
                    // Update the corresponding field in the PublicClient model
                    updated[key] = formValsDiffAttrsObj[key];
                });
            })
        );

        if (showLogs) {
            console.log('ampUserUpdatePublicClientRec updatedPublicClient', updatedPublicClient);
        }

        return {updatedPublicClient, success: true};
    } catch (error) {
        console.error('ampUserUpdatePublicClientRec error', error);
        return {success: false, error: error};
    }
}

// Allows single update by an unknown / public user, for a public shared form
export async function publicUserUpdatePublicClientRec(publicClientId, publicClientFormValsDiff, showLogs) {
// export async function publicUserUpdatePublicClientRec(publicClientId, publicClientFormValsDiff, ampUserRec, showLogs) {
    if (showLogs) {
        console.log('publicUserUpdatePublicClientRec publicClientId', publicClientId);
        console.log('publicUserUpdatePublicClientRec publicClientFormValsDiff', publicClientFormValsDiff);
    }
    if (!publicClientId) {
        console.error("Public Client ID is missing");
        return {success: false, error: "Public Client ID is missing"};
    }

    // if (!ampUserRec) {
    //     console.error("AmpUser Record is missing");
    //     return {success: false, error: "AmpUser Record is missing"};
    // }

    try {
        const original = await DataStore.query(PublicClient, publicClientId);

        // Check if the public client exists
        if (!original) {
            console.error("Public Client not found");
            return {success: false, error: "Public Client not found"};
        }

        const formValsDiffAttrsObj = {};
        Object.keys(publicClientFormValsDiff).forEach(key => {
            if (publicSharedEditPublicClientAttrs.includes(key)) {
                formValsDiffAttrsObj[key] = publicClientFormValsDiff[key];
            }
        });

        if (!original.routerPath) {
            const routerPathUUID = uuidv4();
            const publicClientRouterPath = generateSaltedRouterPath(`${publicClientId}-${routerPathUUID}`);
            // publicClientFormValsDiff.routerPath = publicClientRouterPath;
            formValsDiffAttrsObj.routerPath = publicClientRouterPath;
        }

        if (!original.sharedRouterPath) {
            const sharedRouterPathUUID = uuidv4();
            const publicClientSharedRouterPath = generateSaltedRouterPath(`${sharedRouterPathUUID}-${publicClientId}`);
            // publicClientFormValsDiff.sharedRouterPath = publicClientSharedRouterPath;
            formValsDiffAttrsObj.sharedRouterPath = publicClientSharedRouterPath;
        }

        // Disable editing until the record owner enables it again
        // formValsDiffAttrsObj.publicClientStatus = PublicClientStatus.EDITING_DISABLED;
        formValsDiffAttrsObj.publicUpdatingEnabled = false;
        formValsDiffAttrsObj.sharedPublicClientStatus = SharedPublicClientStatus.DISABLED;
        formValsDiffAttrsObj.sharedPublicClientUpdateStatus = SharedPublicClientUpdateStatus.CLIENT_UPDATED;

        if (showLogs) {
            console.log('publicUserUpdatePublicClientRec original', original);
            console.log('publicUserUpdatePublicClientRec publicClientFormValsDiff', publicClientFormValsDiff);
            console.log('publicUserUpdatePublicClientRec formValsDiffAttrsObj', formValsDiffAttrsObj);
        }

        const updatedPublicClient = await DataStore.save(
            PublicClient.copyOf(original, updated => {
                // Object.keys(publicClientFormValsDiff).forEach(key => {
                //     // Update the corresponding field in the PublicClient model
                //     updated[key] = publicClientFormValsDiff[key];
                // });
                Object.keys(formValsDiffAttrsObj).forEach(key => {
                    // Update the corresponding field in the PublicClient model
                    updated[key] = formValsDiffAttrsObj[key];
                });
            })
        );

        if (showLogs) {
            console.log('publicUserUpdatePublicClientRec updatedPublicClient', updatedPublicClient);
        }

        return {updatedPublicClient, success: true};
    } catch (error) {
        console.error('publicUserUpdatePublicClientRec error', error);
        return {success: false, error: error};
    }
}
