import { openDB } from 'idb';

const dbPromise = openDB('AmpUserAnimalDB', 2, {
    upgrade(db) {
        if (!db.objectStoreNames.contains('ampUserAnimalStore')) {
            db.createObjectStore('ampUserAnimalStore');
        }
    },
});

export async function saveAmpUserAnimalRec(animalRec) {
    const db = await dbPromise;
    await db.put('ampUserAnimalStore', animalRec, 'currentAnimal');
}

export async function loadAmpUserAnimalRec() {
    const db = await dbPromise;
    return await db.get('ampUserAnimalStore', 'currentAnimal');
}

export async function clearAmpUserAnimalRec() {
    const db = await dbPromise;
    await db.delete('ampUserAnimalStore', 'currentAnimal');
}
