import React, {useState, useEffect, useContext} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {fetchClientByRouterPath} from "../data/get/fetchRecords";
import CreateClientNoteForm from "../components/forms/create/CreateClientNoteForm";
import DevStateContext from "../data/contexts/DevStateContext";

export default function CreateClientNoteScreen(props) {
    const [ampUser, setAmpUser] = useState(null);
    const [showLogs, setShowLogs] = useState(false);
    const [clientRec, setClientRec] = useState(null);
    // const [clientId, setClientId] = useState(null);
    // const [clientName, setClientName] = useState(null);  // [clientRec.name]
    const [clientRouterPath, setClientRouterPath] = useState(null);

    const { devStateObj } = useContext(DevStateContext);

    // useEffect(() => {
    //     if (props.showLogs) {
    //         setShowLogs(props.showLogs);
    //     } else {
    //         setShowLogs(false);
    //     }
    // }, [props.showLogs]);

    // useEffect( setShowLogs(
    useEffect(() => {
        if (devStateObj?.showLogs){
            setShowLogs(devStateObj.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [devStateObj]);

    useEffect(() => {
        if (props.ampUser) {
            setAmpUser(props.ampUser);
        }
    }, [props.ampUser]);

    useEffect(() => {
        if (showLogs) {
            console.log('ampUser', ampUser);
        }
    }, [ampUser, showLogs]);

    useEffect(() => {
        if (props.clientRouterPath) {
            setClientRouterPath(props.clientRouterPath);
        }
    }, [props.clientRouterPath]);

    useEffect(() => {
        if (props.clientRec) {
            setClientRec(props.clientRec);
        }
    }, [props.clientRec]);

    // useEffect(() => {
    //     let isMounted = true;
    //
    //     const fetchData = async () => {
    //         if (clientRouterPath === null) {
    //             // Wait for 500ms
    //             await new Promise(resolve => setTimeout(resolve, 500));
    //
    //             // Re-check if clientRouterPath is still null
    //             if (clientRouterPath !== null && isMounted) {
    //                 try {
    //                     const clientData = await fetchClientByRouterPath();
    //                     if (isMounted && clientData) {
    //                         setClientRec(clientData);
    //                         // setClientId(clientData.id);
    //                     }
    //                 } catch (error) {
    //                     // Handle error, for example, setting an error state
    //                     console.error("Error fetching client data:", error);
    //                 }
    //             }
    //         }
    //     };
    //
    //     fetchData();
    //
    //     // Cleanup function to set isMounted false when component unmounts
    //     return () => {
    //         isMounted = false;
    //     };
    // }, [clientRouterPath]); // Dependency array


    return (
        <div>
            <h2>Create Client Note</h2>
            <CreateClientNoteForm
                showLogs={showLogs}
                ampUser={ampUser}
                clientRec={clientRec}
            />
        </div>
    )
}
