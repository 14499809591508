import React, { useState, useEffect, useContext } from 'react';
import CreateClientForm from "../../components/forms/create/CreateClientForm";
import DevStateContext from "../../data/contexts/DevStateContext";
import {breadcrumbRoutesClientsScreen} from "../../utils/breadcrumbsRoutes";
import RouterBreadcrumbs from "../../components/router/RouterBreadcrumbs";
import Box from "@mui/material/Box";

export default function CreateClientScreen(props) {
    const [breadcrumbRoutes, setBreadcrumbRoutes] = useState([]); // [{path: '', name: ''}, ...

    // setBreadcrumbRoutes
    useEffect(() => {
        setBreadcrumbRoutes(breadcrumbRoutesClientsScreen() || [])
        // console.error('breadcrumbRoutes', breadcrumbRoutes)
    }, [props]);

    const { devState } = useContext(DevStateContext);

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '0 auto',
                }}
            >
            <RouterBreadcrumbs breadcrumbsArr={breadcrumbRoutes}/>
            <CreateClientForm
                showLogs={props.showLogs}
                ampUser={props.ampUser}
            />
            </Box>
        </div>
    );
}
