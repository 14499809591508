import React, { useState, useEffect } from 'react';
import DevStateContext from "../contexts/DevStateContext";

// TODO - START HERE, TRY TO FIND OTHER PLACES TO USE THIS PROVIDER INSTEAD
const DevStateProvider = ({ children }) => {
    const [devStateObj, setDevStateObj] = useState({});
    const [devStateNow, setDevStateNow] = useState("dev");
    const [staticUrlsObj, setStaticUrlsObj] = useState({});
    const [devStateBaseUrl, setDevStateBaseUrl] = useState("");

    const devAppBaseUrl = "http://localhost:3000";
    const prodAppBaseUrl = "https://www.solefrog.com";
    const staticSiteUrl = 'https://www.farrierware.com';

    useEffect(() => {
        // console.log(`devStateNow: ${devStateNow}`);
        // console.log(`devState[devStateNow]: ${devState[devStateNow]}`);
        setDevStateObj(devState[devStateNow]);
    }, [devStateNow]);

    useEffect(() => {
        if (devStateNow === "dev") {
            setDevStateBaseUrl(devAppBaseUrl);
        }
        if (devStateNow === "prod") {
            setDevStateBaseUrl(prodAppBaseUrl);
        }
    }, []);

    useEffect(() => {
        if (devStateNow === "dev") {
            setDevStateBaseUrl(devAppBaseUrl);
        }
        if (devStateNow === "prod") {
            setDevStateBaseUrl(prodAppBaseUrl);
        }
    }, [devStateNow]);

    useEffect(() => {
        if (devStateBaseUrl) {
            setStaticUrlsObj({
                staticSiteUrl: {url: staticSiteUrl,
                                isLive: true },
                blogUrl: {url: `${staticSiteUrl}/blog`,
                            isLive: true },
                termsUrl: {url: `${devStateBaseUrl}/terms`,
                            isLive: false },
                contactUrl: {url: `${devStateBaseUrl}/contact`,
                            isLive: false },
                aboutUrl: {url: `${devStateBaseUrl}/about`,
                            isLive: false },
                worksUrl: {url: `${devStateBaseUrl}/works`,
                            isLive: false },
            });
        }
    }, [devStateBaseUrl]);

    useState(() => {
        if (devStateNow === "dev") {
            console.log('DevStateProvider devStateObj', devStateObj);
            console.log('DevStateProvider staticUrlsObj', staticUrlsObj);
        }
    }, [devStateNow, devStateObj, staticUrlsObj]);

    // useEffect(() => {
    //     console.log('DevStateProvider devStateObj', devStateObj);
    // }, [devStateObj]);

    const blogUrl = `${staticSiteUrl}/blog`;
    const termsUrl = `${staticSiteUrl}/terms`;
    const contactUrl = `${staticSiteUrl}/contact`;
    const aboutUrl = `${staticSiteUrl}/about`;
    const worksUrl = `${staticSiteUrl}/works`;
    const staticUrls = {
        staticSiteUrl,
        blogUrl,
        termsUrl,
        contactUrl,
        aboutUrl,
        worksUrl
    }

    // TODO - START HERE, FR, FR, CHECK NEW RECS FROM LIVE PAYMENT, GO FROM THERE

    // TODO - !!!!!!!!!!!!!!,
    //  SET LOGS TO FALSE FOR "REAL" PROD
    const devState = {
        dev: {
            stateNow: "dev",
            showLogs: true,
            stripeSuccessURL: 'http://localhost:3000/subscription-success',
            stripeCancelURL: 'http://localhost:3000/subscription-cancel',
            staticSiteUrl: 'http://localhost:3000',
            ...staticUrlsObj
        },
        prod: {
            stateNow: "prod",
            // showLogs: false,
            showLogs: true,
            stripeSuccessURL: 'https://www.solefrog.com/subscription-success',
            // stripeSuccessURL: 'https://www.fishingigs.com/',
            stripeCancelURL: 'https://www.solefrog.com/subscription-cancel',
            // stripeCancelURL: 'https://www.fishingigs.com/',
            ...staticUrlsObj
        },
    };

    return (
        <DevStateContext.Provider value={{ devStateObj, staticUrlsObj }}>
            {children}
        </DevStateContext.Provider>
    );
};

export default DevStateProvider;
