import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import AmpUserClientsContext from "../../data/contexts/AmpUserClientsContext";
import DevStateContext from "../../data/contexts/DevStateContext";
import StyledListCard from "../../components/cards/StyledListCard";
import Box from "@mui/material/Box";
import BaseNavigateButton from "../../components/links/BaseNavigateButton";
import StyledListBox from "../../components/boxes/StyledListBox";
import { useResponsiveViewport } from "../../data/providers/ResponsiveViewportProvider";
import { breadcrumbRoutesClientsScreen } from "../../utils/breadcrumbsRoutes";
import RouterBreadcrumbs from "../../components/router/RouterBreadcrumbs";
import { useConsoleLog } from "../../components/hooks/logging/console";
import StyledCardContentTypography from "../../components/text/StyledCardContentTypography";
import NotesAccordion from "../../components/accordions/NotesAccordion";
import AmpUserClientNotesContext from "../../data/contexts/AmpUserClientNotesContext";
import ApptsByClientsContext from "../../data/contexts/ApptsByClientsContext";
import BaseStyledDeleteButton from "../../components/buttons/BaseStyledDeleteButton";
import ConfirmDeleteClientDialog from "../../components/modals/delete/ConfirmDeleteClientDialog";


export default function AmpUserClientsScreen(props) {
    const [ampUser, setAmpUser] = useState(null);
    const [clientRecs, setClientRecs] = useState([]);
    const [clientIds, setClientIds] = useState([]);
    const [showLogs, setShowLogs] = useState(false);
    const [breadcrumbRoutes, setBreadcrumbRoutes] = useState([]); // [{path: '', name: ''}, ...

    const [clientNotes, setClientNotes] = useState([]);
    const [clientNotesByClientId, setClientNotesByClientId] = useState({});

    const [appointmentsByClientId, setAppointmentsByClientId] = useState({});
    const [futureAppointmentsByClientId, setFutureAppointmentsByClientId] = useState({});
    const [nextAppointmentByClientId, setNextAppointmentByClientId] = useState({});

    const [clientRecToDelete, setClientRecToDelete] = useState(null);
    const [displayDeleteClientDialog, setDisplayDeleteClientDialog] = useState(false);
    const [deleteClientResObj, setDeleteClientResObj] = useState({});
    const [deleteClientRelatedRecsObj, setDeleteClientRelatedRecsObj] = useState(true);

    const { isSmallScreen, isLargeScreen } = useResponsiveViewport();

    const { devStateObj } = useContext(DevStateContext);
    const { ampUserClientsRecs } = useContext(AmpUserClientsContext);
    const { ampUserClientNotesRecs,
            setClientIdsArr: setClientIdsArrNotesProvider,
            clientNoteRecsByClientId } = useContext(AmpUserClientNotesContext);
    const { appointmentsByClientId: appointmentsByClientIdProvider,
            futureAppointmentsByClientId: futureAppointmentsByClientIdProvider,
            setClientIdsArr: setClientsIdsArrApptsProvider,
            nextAppointmentByClientId: nextAppointmentByClientIdProvider } = useContext(ApptsByClientsContext);

    let navigate = useNavigate();

    useConsoleLog(showLogs, [{label: 'AmpUserClientsScreen clientRecToDelete', value: clientRecToDelete}]);
    useConsoleLog(showLogs, [{label: 'AmpUserClientsScreen deleteClientResObj', value: deleteClientResObj}]);
    useConsoleLog(showLogs, [{label: 'AmpUserClientsScreen deleteClientRelatedRecsObj', value: deleteClientRelatedRecsObj}]);
    useConsoleLog(showLogs, [{label: 'AmpUserClientsScreen clientRecs', value: clientRecs}]);
    useConsoleLog(showLogs, [{label: 'AmpUserClientsScreen clientIds', value: clientIds}]);
    useConsoleLog(showLogs, [{label: 'AmpUserClientsScreen clientNotes', value: clientNotes}]);
    useConsoleLog(showLogs, [{label: 'AmpUserClientsScreen clientNotesByClientId', value: clientNotesByClientId}]);
    useConsoleLog(showLogs, [{label: 'AmpUserClientsScreen appointmentsByClientId', value: appointmentsByClientId}]);
    useConsoleLog(showLogs, [{label: 'AmpUserClientsScreen futureAppointmentsByClientId', value: futureAppointmentsByClientId}]);
    useConsoleLog(showLogs, [{label: 'AmpUserClientsScreen nextAppointmentByClientId', value: nextAppointmentByClientId}]);

    // setBreadcrumbRoutes
    useEffect(() => {
        setBreadcrumbRoutes(breadcrumbRoutesClientsScreen() || [])
        // console.error('breadcrumbRoutes', breadcrumbRoutes)
    }, [props]);

    // useEffect( setAmpUser(props.ampUser)
    useEffect(() => {
        if (props?.ampUser) {
            setAmpUser(props.ampUser);
        }
    }, [props]);

    // useEffect( setShowLogs(devStateObj.showLogs);
    useEffect(() => {
        if (devStateObj?.showLogs){
            setShowLogs(devStateObj.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [devStateObj]);

    // useEffect( setClientRecs(ampUserClientsRecs);
    useEffect(() => {
        if (ampUserClientsRecs) {
            setClientRecs(ampUserClientsRecs);
        }
    }, [ampUserClientsRecs]);

    // useEffect( setClientIds(clientRecIdsArr);
    useEffect(() => {
        if (clientRecs) {
            const clientRecIdsArr = clientRecs.map((clientRec) => {
                return clientRec.id;
            });
            setClientIds(clientRecIdsArr);
        }
    }, [clientRecs]);

    // useEffect( trigger notes provider with clientIds
    useEffect(() => {
        if (clientIds) {
            if (setClientIdsArrNotesProvider) {
                setClientIdsArrNotesProvider(clientIds);
            }
        }
    // }, [clientIds]);
    }, [clientIds]);

    // useEffect( setClientNotes(ampUserClientNotesRecs); setClientNotesByClientId(clientNoteRecsByClientId);
    useEffect(() => {
        if (ampUserClientNotesRecs) {
            setClientNotes(ampUserClientNotesRecs);
        }
        if (clientNoteRecsByClientId) {
            setClientNotesByClientId(clientNoteRecsByClientId);
        }
    }, [ampUserClientNotesRecs, clientNoteRecsByClientId]);

    // useEffect( trigger appts by client provider with clientIds
    useEffect(() => {
        if (clientIds && clientIds.length > 0) {
            if (setClientsIdsArrApptsProvider) {
                setClientsIdsArrApptsProvider(clientIds);
            }
        }
        // }, [clientIds]);
    }, [clientIds]);

    // useEffect( setAppointmentsByClientId(appointmentsByClientIdProvider);
    useEffect(() => {
        if (appointmentsByClientIdProvider) {
            setAppointmentsByClientId(appointmentsByClientIdProvider);
        }
    }, [appointmentsByClientIdProvider]);

    // useEffect( setFutureAppointmentsByClientId(futureAppointmentsByClientIdProvider);
    useEffect(() => {
        if (futureAppointmentsByClientIdProvider) {
            setFutureAppointmentsByClientId(futureAppointmentsByClientIdProvider);
        }
    }, [futureAppointmentsByClientIdProvider]);

    // useEffect( setNextAppointmentByClientId(nextAppointmentByClientIdProvider);
    useEffect(() => {
        if (nextAppointmentByClientIdProvider) {
            setNextAppointmentByClientId(nextAppointmentByClientIdProvider);
        }
    }, [nextAppointmentByClientIdProvider]);

    // TODO - ADD ANIMALS COUNT
    // TODO - ADD NEXT, PREV APPT DATES

    function displayClientRecs() {
        if (clientRecs) {
            return clientRecs.map((clientRec, index) => {
                let clientRecNotes = clientNotesByClientId?.[clientRec.id] || [];
                let clientNextAppt = nextAppointmentByClientId?.[clientRec.id] || null;

                return (
                    <Box
                        key={`amp-user-clients-${index}-${clientRec.id}`}
                        sx={{
                            marginBottom: '1.5vh',
                            backgroundColor: 'lightgray',
                            width: '100%', // Ensure the item takes the full width of the container
                            display: 'flex',
                            justifyContent: 'center', // Center the content
                        }}
                    >
                        <StyledListCard
                            key={`ampUserClientRec-${index}`}
                            sx={{
                                width: '100%',
                                maxWidth: {
                                    xs: 360,  // max-width for xs breakpoint and up
                                    sm: 480,  // max-width for sm breakpoint and up
                                    md: 600,  // max-width for md breakpoint and up
                                    lg: 800,  // max-width for lg breakpoint and up
                                    xl: 1000, // max-width for xl breakpoint and up
                                },
                            }}
                        >
                            {clientNextAppt && (
                                <StyledCardContentTypography
                                    text={`Next Appt.: ${clientNextAppt?.apptDate || '-'}`}
                                    backgroundColor={'lightblue'}
                                    onClick={() => {
                                        navigate(`/appt-info/${clientNextAppt.routerPath}`, {
                                            state: { apptRec: clientNextAppt, clientRec: clientRec },
                                        });
                                    }}
                                />
                            )}
                            <StyledCardContentTypography text={`Name: ${clientRec?.name || '-'}`} />
                            <StyledCardContentTypography text={`Status: ${clientRec?.clientStatus || '-'}`} />
                            <StyledCardContentTypography text={`Phone: ${clientRec?.phone || '-'}`} />
                            <StyledCardContentTypography text={`Balance: $${clientRec?.balance || '-'}`} />
                            <StyledCardContentTypography text={`Gate Code: ${clientRec.gateCode || '-'}`} />

                            {clientRecNotes.length > 0 && (
                                <NotesAccordion
                                    notes={clientRecNotes}
                                    headerText={`Client Notes: (${clientRecNotes.length})`}
                                    showLogs={showLogs}
                                    clientRecProp={clientRec}
                                    noteType="client"
                                />
                            )}

                            <BaseNavigateButton
                                navTarget={`/client-info/${clientRec.routerPath}`}
                                label={`${clientRecNotes.length > 0 ? 'Client Info & Notes' : 'Client Info'}`}
                                navState={{ clientRec: clientRec }}
                            />
                            <BaseNavigateButton
                                navTarget={`/edit-client/${clientRec.routerPath}`}
                                label={"Edit Client"}
                                navState={{ clientRec }}
                            />
                            <BaseStyledDeleteButton
                                label={"Delete Client"}
                                onClick={() => {
                                    setClientRecToDelete(clientRec);
                                    setDisplayDeleteClientDialog(true);
                                }}
                            />
                        </StyledListCard>
                    </Box>
                );
            });
        } else {
            return (
                <StyledListCard>
                    <StyledCardContentTypography text={'Loading Clients...'} />
                </StyledListCard>
            );
        }
    }

    function onCloseDeleteClientDialog() {
        setDisplayDeleteClientDialog(false);
        setClientRecToDelete(null);
    }

    function onDeleteClientDone(deleteClientRes) {
        if (deleteClientRes) {
            setDeleteClientResObj(deleteClientRes);
        }
    }

    function onDeleteRelatedRecsDone(deleteRelatedRecsRes) {
        if (deleteRelatedRecsRes) {
            setDeleteClientRelatedRecsObj(deleteRelatedRecsRes);
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center', // Center children horizontally
                justifyContent: 'center', // Center children vertically if needed
                margin: '0 auto',
            }}
        >
            <h2>Clients</h2>
            <RouterBreadcrumbs breadcrumbsArr={breadcrumbRoutes}/>
            <StyledListBox
                width={isLargeScreen ? '90%' : '100%'}
                style={{
                    color: 'white',
                    backgroundColor: 'lightgray',
                    borderTop: '1px solid black',
                    borderBottom: '1px solid black',
                    marginTop: '0.5vh',
                    maxWidth: '100vw', // Ensure it doesn’t exceed viewport width
                    height: '60vh', // Adjust height based on viewport height
                    overflowY: 'auto',
                    overflowX: 'hidden',
                }}
            >
            {displayClientRecs()}
            </StyledListBox>
            <div>
                <BaseNavigateButton
                    label={"Create Client"}
                    navTarget={'/create-client'}
                />
            </div>
            <div>
                {displayDeleteClientDialog ? (
                    <ConfirmDeleteClientDialog
                        openProp={displayDeleteClientDialog}
                        ampUser={ampUser}
                        handleCloseProp={onCloseDeleteClientDialog}
                        handleDeleteClientDoneProp={onDeleteClientDone}
                        handleDeleteRelatedRecsDoneProp={onDeleteRelatedRecsDone}
                        modelRecToDelete={clientRecToDelete}
                        showLogs={showLogs}
                    />
                ) : null}
            </div>
        </Box>
    );
}
