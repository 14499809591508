import React, { useState, useEffect, useContext } from 'react';
import TextField from "@mui/material/TextField";
import StyledTextField from "../../text/StyledTextField";
import FormControl from "@mui/material/FormControl";
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import { generateUniqueKey } from "../../../utils/helpers";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import BaseNavigateButton from "../../links/BaseNavigateButton";
import { ClientStatus } from "../../../models";
import DevStateContext from "../../../data/contexts/DevStateContext";
import { createClientFormObj, clientNoteFormObj } from "../../../utils/formObjects";
import { useConsoleLog } from "../../hooks/logging/console";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

// TODO - REPLACE WITH createClientFormObj,
//  ADD DEFAULT CLIENT STATUS ON CREATE ACTION
const initialState = {
    name: '',
    email: '',
    phone: '',
    balance: 0,
    clientStatus: ClientStatus.ACTIVE,
    ampuserID: '',
    clientNote: '',
}

export default function CreateClientForm(props) {
    const [showLogs, setShowLogs] = useState(false);
    const [ampUser, setAmpUser] = useState(null);
    // const [balance, setBalance] = useState(initialState.balance);
    const [balance, setBalance] = useState('');
    const [ampuserID, setAmpUserID] = useState(initialState.ampuserID);
    const [clientStatusEnum, setClientStatusEnum] = useState({});

    const [formValues, setFormValues] = useState(initialState);
    const [origClientFormVals, setOrigClientFormVals] = useState(createClientFormObj);
    const [updatedClientFormVals, setUpdatedClientFormVals] = useState(createClientFormObj);
    const [origClientNoteFormVals, setOrigClientNoteFormVals] = useState(clientNoteFormObj);
    const [updatedClientNoteFormVals, setUpdatedClientNoteFormVals] = useState(clientNoteFormObj);

    const { devStateObj } = useContext(DevStateContext);

    // useEffect( setShowLogs(
    useEffect(() => {
        if (devStateObj?.showLogs){
            setShowLogs(devStateObj.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [devStateObj]);

    // useEffect( setShowLogs(props.showLogs
    // useEffect(() => {
    //     if (props?.showLogs) {
    //         setShowLogs(props.showLogs);
    //     }
    //     else {
    //         setShowLogs(false);
    //     }
    // }, [props.showLogs]);

    // useEffect( setAmpUser(props.ampUser); setAmpUserID(props.ampUser.id);
    useEffect(() => {
        if (props?.ampUser) {
            setAmpUser(props.ampUser);
            setAmpUserID(props.ampUser.id);
        }
    // }, [props.ampUser, showLogs]);
    }, [props.ampUser]);

    // useEffect( setClientStatusEnum(ClientStatus);
    useEffect(() => {
        if (ClientStatus) {
            setClientStatusEnum(ClientStatus);
        }
    }, [ClientStatus]);

    useConsoleLog(showLogs, [{label: 'CreateClientForm ClientStatus', value: ClientStatus}]);

    const handleClientFormValUpdate = (valKey, valValue) => {
        setUpdatedClientFormVals({...updatedClientFormVals, [valKey]: valValue});
    }

    useConsoleLog(showLogs, [{label: 'CreateClientForm formValues', value: formValues}]);
    useConsoleLog(showLogs, [{label: 'CreateClientForm updatedClientFormVals', value: updatedClientFormVals}]);

    const handleClientNoteFormValUpdate = (valKey, valValue) => {
        setUpdatedClientNoteFormVals({...updatedClientNoteFormVals, [valKey]: valValue});
    }

    useConsoleLog(showLogs, [{label: 'CreateClientForm updatedClientNoteFormVals', value: updatedClientNoteFormVals}]);

    const handleBalanceTextFieldChangeFormatted = (event) => {
        let input = event.target.value;

        // Remove all non-numeric characters
        input = input.replace(/[^0-9]/g, '');

        // Convert to integer
        const intValue = parseInt(input, 10);

        // Format as US dollars (without decimal)
        const formattedInput = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0
        }).format(intValue);

        setBalance(formattedInput);
        // setFormValues({...formValues, balance: intValue});
        setUpdatedClientFormVals({...updatedClientFormVals, balance: intValue});
    };

    return (
        <div>
            <h3>Create Client Form</h3>
            <Divider>
                <Chip label="Client Info"/>
            </Divider>
            <div>
                <StyledTextField
                    id="name"
                    label="Name"
                    value={updatedClientFormVals.name}
                    onChange={(event) => handleClientFormValUpdate('name', event.target.value)}
                />
            </div>
            <div>
                <StyledTextField
                    id="email"
                    label="Email"
                    value={updatedClientFormVals.email}
                    onChange={(event) => handleClientFormValUpdate('email', event.target.value)}
                />
            </div>
            <div>
                <StyledTextField
                    id="phone"
                    label="Phone"
                    value={updatedClientFormVals.phone}
                    onChange={(event) => handleClientFormValUpdate('phone', event.target.value)}
                />
            </div>
            <div>
                <StyledTextField
                    id="balance"
                    label="Balance ($)"
                    // type="number"
                    value={balance}
                    onChange={handleBalanceTextFieldChangeFormatted}
                />
            </div>
            <Divider>
                <Chip label="Client Status"/>
            </Divider>
            <div>
                <FormControl sx={{m: 1, width: 300}}>
                    <InputLabel id={`create-client-client-status-input-label`}>Client Status</InputLabel>
                    <Select
                        labelId={`create-client-client-status-label-id`}
                        id={`create-client-client-status-select`}
                        // value={clientStatus}
                        value={updatedClientFormVals.clientStatus}
                        onChange={(event) => {
                            // setClientStatus(event.target.value);
                            // setFormValues({...formValues, clientStatus: event.target.value})
                            handleClientFormValUpdate('clientStatus', event.target.value);
                        }}
                        MenuProps={MenuProps}
                    >
                        {Object.entries(clientStatusEnum).map(([key, val]) => (
                            <MenuItem
                                key={generateUniqueKey(key)}  // Use the key for generating a unique key
                                value={val}  // Use the key as the value for the MenuItem
                            >
                                {val}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div>
                <StyledTextField
                    id="street-address"
                    label="Street Address"
                    value={updatedClientFormVals.streetAddress}
                    onChange={(event) => handleClientFormValUpdate('streetAddress', event.target.value)}
                />
            </div>
            <div>
                <StyledTextField
                    id="city"
                    label="City"
                    value={updatedClientFormVals.city}
                    onChange={(event) => handleClientFormValUpdate('city', event.target.value)}
                />
            </div>
            <div>
                <StyledTextField
                    id="state"
                    label="State"
                    value={updatedClientFormVals.state}
                    onChange={(event) => handleClientFormValUpdate('state', event.target.value)}
                />
            </div>
            <div>
                <StyledTextField
                    id="zip-code"
                    label="Zip Code"
                    value={updatedClientFormVals.zipCode}
                    onChange={(event) => handleClientFormValUpdate('zipCode', event.target.value)}
                />
            </div>
            <div>
                <StyledTextField
                    id="gate-code"
                    label="Gate Code"
                    value={updatedClientFormVals.gateCode}
                    onChange={(event) => handleClientFormValUpdate('gateCode', event.target.value)}
                />
            </div>
            <div>
                <StyledTextField
                    id="alt-contact-name"
                    label="Alt. Contact Name"
                    value={updatedClientFormVals.alternateContactName}
                    onChange={(event) => handleClientFormValUpdate('alternateContactName', event.target.value)}
                />
            </div>
            <div>
                <StyledTextField
                    id="alt-contact-phone"
                    label="Alt. Contact Phone"
                    value={updatedClientFormVals.alternateContactPhone}
                    onChange={(event) => handleClientFormValUpdate('alternateContactPhone', event.target.value)}
                />
            </div>
            <Divider>
                <Chip label="Client Note"/>
            </Divider>
            <div>
                <FormControl
                    row="true"
                    component="fieldset"
                    variant="standard"
                    sx={{
                        minWidth: 300
                    }}
                >
                    <TextField
                        id="create-client-client-note-text-field"
                        // label="Describe your fishing experience"
                        multiline
                        maxRows={16}
                        // value={value}
                        // value={clientNote}
                        value={updatedClientNoteFormVals.note}
                        // onChange={handleClientNoteTextFieldChange}
                        onChange={(event) => handleClientNoteFormValUpdate('note', event.target.value)}
                        variant="outlined"
                    />
                </FormControl>
            </div>
            <div>
                <BaseNavigateButton
                    navTarget={`/create-client-preview`}
                    label="Create Client"
                    navState={{formValues, updatedClientFormVals, updatedClientNoteFormVals}}
                />
            </div>
        </div>
    );
}
