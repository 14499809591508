
export const handleTrimTaskOpen = (editedSelectedTrimAnimalRecs, setTrimTaskModalOpen, setUpdatedTrimTaskFormUniqueId) => {
    if (editedSelectedTrimAnimalRecs.length > 0) {
        setTrimTaskModalOpen(true);
        setUpdatedTrimTaskFormUniqueId(null);
    } else {
        alert("No animal trim tasks to update!");
    }
};

export const handleShoeTaskOpen = (editedSelectedShoeAnimalRecs, setShoeTaskModalOpen, setUpdatedShoeTaskFormUniqueId) => {
    if (editedSelectedShoeAnimalRecs.length > 0) {
        setShoeTaskModalOpen(true);
        setUpdatedShoeTaskFormUniqueId(null);
    } else {
        alert("No animal shoe tasks to update!");
    }
};

export const handleTrimTaskClose = (formIndex, editedFormData, editedTrimTaskFormVals, setEditedTrimTaskFormVals, setUpdatedTrimTaskFormUniqueId, setTrimTaskModalOpen, showLogs) => {
    // TODO - VERIFY IF FURTHER ACTION NEEDED ON MODAL CLOSE
    const updatedTaskElement = editedTrimTaskFormVals[formIndex];
    if (showLogs) {
        console.warn('### handleTrimTaskClose editedFormData', editedFormData);
        console.warn('### handleTrimTaskClose updatedTaskElement', updatedTaskElement);
    }
    if (updatedTaskElement) {
        const taskFormUniqueId = updatedTaskElement.formUniqueId;
        const taskCopy = {...updatedTaskElement, ...editedFormData};
        // replace the element in editedTrimTaskFormVals, using setEditedTrimTaskFormVals, and keep it at the same index
        const newTrimTaskFormVals = editedTrimTaskFormVals.map((item, index) =>
            index === formIndex ? taskCopy : item
        );
        setEditedTrimTaskFormVals(newTrimTaskFormVals);
        // TODO - CHECK FOR DIFFERENT VALS IN THE FORM, CALL BELOW FUNCTION IF ANY CHANGES
        // addUpdateUniqueIdToTaskFormsDiffObj('trim', taskFormUniqueId);
        if (taskFormUniqueId) {
            setUpdatedTrimTaskFormUniqueId(taskFormUniqueId);
        }
    }
    setTrimTaskModalOpen(false);
}

export const handleShoeTaskClose = (formIndex, editedFormData, editedShoeTaskFormVals, setEditedShoeTaskFormVals, setUpdatedShoeTaskFormUniqueId, setShoeTaskModalOpen, showLogs) => {
    // TODO - VERIFY IF FURTHER ACTION NEEDED ON MODAL CLOSE
    const updatedTaskElement = editedShoeTaskFormVals[formIndex];
    if (showLogs) {
        console.warn('### handleShoeTaskClose editedFormData', editedFormData);
        console.warn('### handleShoeTaskClose updatedTaskElement', updatedTaskElement);
    }
    if (updatedTaskElement) {
        const taskFormUniqueId = updatedTaskElement.formUniqueId;
        const taskCopy = {...updatedTaskElement, ...editedFormData};
        // replace the element in editedShoeTaskFormVals, using setEditedShoeTaskFormVals, and keep it at the same index
        const newShoeTaskFormVals = editedShoeTaskFormVals.map((item, index) =>
            index === formIndex ? taskCopy : item
        );
        setEditedShoeTaskFormVals(newShoeTaskFormVals);
        // TODO - CHECK FOR DIFFERENT VALS IN THE FORM, CALL BELOW FUNCTION IF ANY CHANGES
        if (taskFormUniqueId) {
            setUpdatedShoeTaskFormUniqueId(taskFormUniqueId);
        }
    }
    setShoeTaskModalOpen(false);
}

export const handleTrimSaveAndUpdateNext = (formIndex, editedFormData, editedTrimTaskFormVals, setEditedTrimTaskFormVals, setUpdatedTrimTaskFormUniqueId, currentTrimTaskAnimalIndex, editedSelectedTrimAnimalRecs, setCurrentTrimTaskAnimalIndex, showLogs) => {
    // TODO - VERIFY IF FURTHER ACTION NEEDED ON MODAL CLOSE
    // console.log('handleTrimSaveAndUpdateNext');
    const updatedTaskElement = editedTrimTaskFormVals[formIndex];
    if (showLogs) {
        console.warn('### handleTrimSaveAndUpdateNext editedFormData', editedFormData);
        console.warn('### handleTrimSaveAndUpdateNext updatedTaskElement', updatedTaskElement);
    }
    if (updatedTaskElement) {
        const taskFormUniqueId = updatedTaskElement.formUniqueId;
        const taskCopy = {...updatedTaskElement, ...editedFormData};
        if (showLogs) {
            console.warn('### handleTrimSaveAndUpdatePrev taskCopy', taskCopy);
        }
        // replace the element in editedTrimTaskFormVals, using setEditedTrimTaskFormVals, and keep it at the same index
        const newTrimTaskFormVals = editedTrimTaskFormVals.map((item, index) =>
            index === formIndex ? taskCopy : item
        );
        setEditedTrimTaskFormVals(newTrimTaskFormVals);
        // TODO - CHECK FOR DIFFERENT VALS IN THE FORM, CALL BELOW FUNCTION IF ANY CHANGES
        // addUpdateUniqueIdToTaskFormsDiffObj('trim', taskFormUniqueId);
        if (taskFormUniqueId) {
            // TODO - VERIFY TASK WAS UPDATED BASED ON FORM ATTRS,
            //  CHECK MAY NOT NEED TO HAPPEN HERE THOUGH
            setUpdatedTrimTaskFormUniqueId(taskFormUniqueId);
        }
    }

    const nextIndex = currentTrimTaskAnimalIndex + 1;
    if (nextIndex < editedSelectedTrimAnimalRecs.length) {
        setCurrentTrimTaskAnimalIndex(currentTrimTaskAnimalIndex + 1);
    } else {
        setCurrentTrimTaskAnimalIndex(0);
    }
};

export const handleShoeSaveAndUpdateNext = (formIndex, editedFormData, editedShoeTaskFormVals, setEditedShoeTaskFormVals, setUpdatedShoeTaskFormUniqueId, currentShoeTaskAnimalIndex, editedSelectedShoeAnimalRecs, setCurrentShoeTaskAnimalIndex, showLogs) => {
    // console.log('handleShoeSaveAndUpdateNext');
    const updatedTaskElement = editedShoeTaskFormVals[formIndex];
    if (showLogs) {
        console.warn('### handleShoeSaveAndUpdateNext editedFormData', editedFormData);
        console.warn('### handleShoeSaveAndUpdateNext updatedTaskElement', updatedTaskElement);
    }
    if (updatedTaskElement) {
        const taskFormUniqueId = updatedTaskElement.formUniqueId;
        const taskCopy = {...updatedTaskElement, ...editedFormData};
        if (showLogs) {
            console.warn('### handleShoeSaveAndUpdateNext taskCopy', taskCopy);
        }
        // replace the element in editedShoeTaskFormVals, using setEditedShoeTaskFormVals, and keep it at the same index
        const newShoeTaskFormVals = editedShoeTaskFormVals.map((item, index) =>
            index === formIndex ? taskCopy : item
        );
        setEditedShoeTaskFormVals(newShoeTaskFormVals);
        // TODO - CHECK FOR DIFFERENT VALS IN THE FORM, CALL BELOW FUNCTION IF ANY CHANGES
        if (taskFormUniqueId) {
            setUpdatedShoeTaskFormUniqueId(taskFormUniqueId);
        }
    }

    const nextIndex = currentShoeTaskAnimalIndex + 1;
    if (nextIndex < editedSelectedShoeAnimalRecs.length) {
        setCurrentShoeTaskAnimalIndex(currentShoeTaskAnimalIndex + 1);
    } else {
        setCurrentShoeTaskAnimalIndex(0);
    }
};

export const handleTrimSaveAndUpdatePrev = (formIndex, editedFormData, editedTrimTaskFormVals, setEditedTrimTaskFormVals, setUpdatedTrimTaskFormUniqueId, currentTrimTaskAnimalIndex, editedSelectedTrimAnimalRecs, setCurrentTrimTaskAnimalIndex, showLogs) => {
    // TODO - VERIFY IF FURTHER ACTION NEEDED ON MODAL CLOSE
    const updatedTaskElement = editedTrimTaskFormVals[formIndex];
    if (showLogs) {
        console.warn('### handleTrimSaveAndUpdatePrev editedFormData', editedFormData);
        console.warn('### handleTrimSaveAndUpdatePrev updatedTaskElement', updatedTaskElement);
    }
    if (updatedTaskElement) {
        const taskFormUniqueId = updatedTaskElement.formUniqueId;
        const taskCopy = {...updatedTaskElement, ...editedFormData};
        if (showLogs) {
            console.warn('### handleTrimSaveAndUpdatePrev taskCopy', taskCopy);
        }
        // replace the element in editedTrimTaskFormVals, using setEditedTrimTaskFormVals, and keep it at the same index
        const newTrimTaskFormVals = editedTrimTaskFormVals.map((item, index) =>
            index === formIndex ? taskCopy : item
        );
        setEditedTrimTaskFormVals(newTrimTaskFormVals);
        // TODO - CHECK FOR DIFFERENT VALS IN THE FORM, CALL BELOW FUNCTION IF ANY CHANGES
        // addUpdateUniqueIdToTaskFormsDiffObj('trim', taskFormUniqueId);
        if (taskFormUniqueId) {
            // TODO - VERIFY TASK WAS UPDATED BASED ON FORM ATTRS,
            //  CHECK MAY NOT NEED TO HAPPEN HERE THOUGH
            setUpdatedTrimTaskFormUniqueId(taskFormUniqueId);
        }
    }

    const nextIndex = currentTrimTaskAnimalIndex - 1;
    if (nextIndex > -1) {
        setCurrentTrimTaskAnimalIndex(currentTrimTaskAnimalIndex - 1);
    } else {
        const maxIndex = editedSelectedTrimAnimalRecs.length - 1;
        setCurrentTrimTaskAnimalIndex(maxIndex);
    }
};

export const handleShoeSaveAndUpdatePrev = (formIndex, editedFormData, editedShoeTaskFormVals, setEditedShoeTaskFormVals, setUpdatedShoeTaskFormUniqueId, currentShoeTaskAnimalIndex, editedSelectedShoeAnimalRecs, setCurrentShoeTaskAnimalIndex, showLogs) => {
    const updatedTaskElement = editedShoeTaskFormVals[formIndex];
    if (showLogs) {
        console.warn('### handleShoeSaveAndUpdatePrev editedFormData', editedFormData);
        console.warn('### handleShoeSaveAndUpdatePrev updatedTaskElement', updatedTaskElement);
    }
    if (updatedTaskElement) {
        const taskFormUniqueId = updatedTaskElement.formUniqueId;
        const taskCopy = {...updatedTaskElement, ...editedFormData};
        if (showLogs) {
            console.warn('### handleShoeSaveAndUpdatePrev taskCopy', taskCopy);
        }
        // replace the element in editedShoeTaskFormVals, using setEditedShoeTaskFormVals, and keep it at the same index
        const newShoeTaskFormVals = editedShoeTaskFormVals.map((item, index) =>
            index === formIndex ? taskCopy : item
        );
        setEditedShoeTaskFormVals(newShoeTaskFormVals);
        // TODO - CHECK FOR DIFFERENT VALS IN THE FORM, CALL BELOW FUNCTION IF ANY CHANGES
        if (taskFormUniqueId) {
            // TODO - VERIFY TASK WAS UPDATED BASED ON FORM ATTRS,
            //  CHECK MAY NOT NEED TO HAPPEN HERE THOUGH
            setUpdatedShoeTaskFormUniqueId(taskFormUniqueId);
        }
    }

    const nextIndex = currentShoeTaskAnimalIndex - 1;
    if (nextIndex > -1) {
        setCurrentShoeTaskAnimalIndex(currentShoeTaskAnimalIndex - 1);
    } else {
        const maxIndex = editedSelectedShoeAnimalRecs.length - 1;
        setCurrentShoeTaskAnimalIndex(maxIndex);
    }
};


