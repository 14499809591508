import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { SubPaymentStatus } from "../models";
// Extend dayjs with the UTC and timezone plugins
dayjs.extend(utc);
dayjs.extend(timezone);


export function convertAWSDateTimeToLocalDateTime(dateTimeStr) {
    if (dateTimeStr.endsWith('Z')) {
        dateTimeStr = dateTimeStr.slice(0, -1);  // Remove the last character 'Z'
    }
    const returnStr = dayjs(dateTimeStr);
    console.warn('returnStr', returnStr);
    return returnStr;
}

export function convertAWSDateTimeToLocalDateTimeExtended(dateTimeStr) {
    // Parse the AWS DateTime string as UTC
    const parsedDateTime = dayjs.utc(dateTimeStr);
    // Convert the UTC date to local time
    const localDateTime = parsedDateTime.local().format('YYYY-MM-DD HH:mm:ss');
    console.warn('localDateTime', localDateTime);
    return localDateTime;
}

export function convertAWSDateTimeToLocalDateExtended(dateTimeStr) {
    // Parse the AWS DateTime string as UTC
    const parsedDateTime = dayjs.utc(dateTimeStr);
    // Convert the UTC date to local time
    const localDate = parsedDateTime.local().format('YYYY-MM-DD');
    // console.warn('localDate', localDate);
    return localDate;
}

export function convertAWSDateTimeToLocalTimeExtended(dateTimeStr) {
    // Parse the AWS DateTime string as UTC
    const parsedDateTime = dayjs.utc(dateTimeStr);
    // Convert the UTC date to local time
    const localTime = parsedDateTime.local().format('HH:mm:ss');
    // console.warn('localTime', localTime);
    return localTime;
}

export function returnDateTimeNowISO() {
    return new Date().toISOString();
}

export function getNowAsAWSDateTime() {
    const now = new Date();
    return now.toISOString(); // Returns the date and time in ISO 8601 format
}

export function convertTimestampToDate(timestamp) {
    const date = new Date(timestamp);
    return date.toLocaleDateString();
}

export function convertTimestampToDateWithOffset(timestamp, offset) {
    const offsetMs = msSubMap[offset];
    // console.log('offsetMs', offsetMs)
    const date = new Date(timestamp + offsetMs);
    return date.toLocaleDateString();
}

export function dateTimeFromDateTime(dateVal, timeVal) {
    const date = new Date(dateVal);
    const time = new Date(timeVal);
    const dateTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes(), time.getSeconds(), time.getMilliseconds());
    return dateTime;
}

export function dateFromAWSDateTime(dateTimeVal) {
    const date = new Date(dateTimeVal);
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
}

// TODO - UPDATE TO USE ACTUAL VALS FROM STRIPE
export const msSubMap = {
    0: 6.048e+8, // week, 7 days
    2000: 2.6298e+9, // month, 31 days
    3300: 7.889399e+9, // quarter, 93 days
    10000: 3.15576e+10, // year, 365 days
    month: 2.6298e+9, // month, 31 days
    week: 6.048e+8, // week, 7 days
}

export function monthsToMilliseconds(months) {
    const averageDaysPerMonth = 31;
    const hoursPerDay = 24;
    const minutesPerHour = 60;
    const secondsPerMinute = 60;
    const millisecondsPerSecond = 1000;

    const msResult = months * averageDaysPerMonth * hoursPerDay * minutesPerHour * secondsPerMinute * millisecondsPerSecond;
    return Math.floor(msResult);
}

export function userSubIsExpired(userSubEndTS) {
    const nowTs = Date.now();
    return userSubEndTS > nowTs;
}

// TODO - LARGELY GPT GENERATED, VERIFY RETURN VALUES
export function subPaymentViewIsExpired(subPaymentViewEndTS, subPaymentStatus) {
    const nowTs = Date.now();
    if (subPaymentStatus === SubPaymentStatus.PENDING) {
        const minTimeOffset = msSubMap.week;
        const minTime = nowTs - minTimeOffset;
        if (subPaymentViewEndTS < minTime) {
            return true;
        }
        return false;
    }
    else if (subPaymentStatus === SubPaymentStatus.ACTIVE) {
        const minActiveSubOffset = msSubMap.month;
        const minActiveSubTime = nowTs - minActiveSubOffset;
        if (subPaymentViewEndTS < minActiveSubTime) {
            return true;
        }
        return false;
    }
}

// TODO - USE FOR COMPUTING ANIMAL AGE,
//  BASED ON BIRTHDAY DATE - RETURN AGE BASED ON LOCALE DATE,
//  CONVERTED FROM ANIMAL BIRTHDAY WHICH IS IN UTC
export function animalAgeInYearsSinceUTCDate(animalBirthdayUTCDateStr) {
    const animalBirthdayDate = new Date(animalBirthdayUTCDateStr);
    const now = new Date();
    const diff = now - animalBirthdayDate;
    const ageDate = new Date(diff);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}
