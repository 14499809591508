import React, { useState, useEffect, useContext } from 'react';
import StyledTextField from "../../text/StyledTextField";
import DevStateContext from "../../../data/contexts/DevStateContext";
import { createPublicClientFormObj } from "../../../utils/formObjects";
import {convertToE164, formatPhoneNumber, validatePhoneNumber} from "../../../utils/validation";
import BaseStyledButton from "../../buttons/BaseStyledButton";


export default function CreatePublicClientForm(props) {
    const [showLogs, setShowLogs] = useState(false);
    const [ampUser, setAmpUser] = useState(null);
    const [origClientFormVals, setOrigClientFormVals] = useState(createPublicClientFormObj);
    const [editedClientFormVals, setEditedClientFormVals] = useState(createPublicClientFormObj);

    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneError, setPhoneError] = useState('');

    const [pauseOnFormSubmit, setPauseOnFormSubmit] = useState(false);
    
    const { devStateObj } = useContext(DevStateContext);

    // useEffect( setShowLogs(
    useEffect(() => {
        if (devStateObj?.showLogs){
            setShowLogs(devStateObj.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [devStateObj]);

    // useEffect( setAmpUser(props.ampUser)
    useEffect(() => {
        if (props?.ampUser) {
            setAmpUser(props.ampUser);
        }
    }, [props.ampUser]);

    const handlePhoneChange = (e) => {
        if (!pauseOnFormSubmit) {
            const formattedPhoneNumber = formatPhoneNumber(e.target.value);
            setPhoneNumber(formattedPhoneNumber);
        }
    };

    const handlePhoneBlur = () => {
        if (!pauseOnFormSubmit) {
            if (!validatePhoneNumber(phoneNumber)) {
                setPhoneError('Please enter a valid US phone number: (123) 456-7890');
            }
            else {
                setPhoneError('');
                if (phoneNumber) {
                    // handleAmpUserFormChange('phone', phoneNumber);
                    const phoneE164Formatted = convertToE164(phoneNumber);
                    setEditedClientFormVals({
                        ...editedClientFormVals,
                        phone: phoneNumber,
                        phoneE164: phoneE164Formatted,
                    });
                }
            }
        }
    };

    function handlePubClientCreate() {
        handlePhoneBlur();
        // setDisplayConfirmUpdateDialog(true);
        // setPauseOnFormSubmit(true);
        props.handleFormDoneProp(editedClientFormVals);
    }

    function handleFormChange(key, value) {
        if (!pauseOnFormSubmit) {
            setEditedClientFormVals({
                ...editedClientFormVals,
                [key]: value,
            });
        }
    }


    return (
        <div>
            {/*<h3>Create Public Client</h3>*/}
            {/*<div>*/}
            {/*    <StyledCardContentTypography*/}
            {/*        text="Create Public Client"*/}
            {/*        // fontSize={['5vw', '3vw', '2.5vw', '2vw']}*/}
            {/*        sx={{*/}
            {/*            fontSize: {*/}
            {/*                xs: '1.5rem', // Similar to small screens*/}
            {/*                sm: '2rem',   // Adjust for tablets and small desktops*/}
            {/*                md: '2.5rem', // Standard desktops*/}
            {/*                lg: '3rem',   // Larger desktops*/}
            {/*                xl: '3.5rem'  // Ultra-wide or larger screens*/}
            {/*            }*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</div>*/}
            {/*<Divider*/}
            {/*    sx={{*/}
            {/*        // fontSize: {*/}
            {/*        //     xs: '0.5rem', // Similar to small screens*/}
            {/*        //     sm: '1rem',   // Adjust for tablets and small desktops*/}
            {/*        //     md: '1.5rem', // Standard desktops*/}
            {/*        //     lg: '2rem',   // Larger desktops*/}
            {/*        //     xl: '2.5rem'  // Ultra-wide or larger screens*/}
            {/*        //     }*/}
            {/*        marginTop: '0.2rem',*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <Chip*/}
            {/*        label="Client Info"*/}
            {/*        sx={{*/}
            {/*            fontSize: {*/}
            {/*                xs: '1rem', // Similar to small screens*/}
            {/*                sm: '1rem',   // Adjust for tablets and small desktops*/}
            {/*                md: '1.5rem', // Standard desktops*/}
            {/*                lg: '2rem',   // Larger desktops*/}
            {/*                xl: '2.5rem'  // Ultra-wide or larger screens*/}
            {/*            }*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</Divider>*/}
            <div>
                <StyledTextField
                    id="name"
                    label="Name"
                    value={editedClientFormVals?.name || ''}
                    onChange={(event) => handleFormChange('name', event.target.value)}
                    fontSizes={{
                        xs: '1.5rem', // For extra small screens
                        sm: '2rem',   // For small screens
                        md: '2.5rem', // For medium screens
                        lg: '3rem',   // For large screens
                        xl: '3rem'  // For extra large screens
                    }}
                />
            </div>
            <div>
                <StyledTextField
                    id="phone"
                    label="Phone"
                    error={!!phoneError}
                    helperText={phoneError || 'Format: (123) 456-7890'}
                    value={phoneNumber}
                    onChange={handlePhoneChange}
                    onBlur={handlePhoneBlur}
                    fontSizes={{
                        xs: '1.5rem', // For extra small screens
                        sm: '2rem',   // For small screens
                        md: '2.5rem', // For medium screens
                        lg: '3rem',   // For large screens
                        xl: '3rem'  // For extra large screens
                    }}
                    // onChange={(event) => handleFormChange('phone', event.target.value)}
                />
            </div>
            <BaseStyledButton
                // label="Create Public Client"
                label="Create Client"
                onClick={handlePubClientCreate}
                sx={{
                    fontSize: {
                        xs: '1rem', // Similar to small screens
                        sm: '2rem',   // Adjust for tablets and small desktops
                        md: '2rem', // Standard desktops
                        lg: '1.5rem',   // Larger desktops
                        xl: '2.5rem'  // Ultra-wide or larger screens
                    }
                }}
            />
        </div>
    );
}
