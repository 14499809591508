import React from "react";
import MenuItem from '@mui/material/MenuItem';


export default function StyledMenuItem({ children, fontSizesProp, ...props }) {
    const defaultFontSizes = {
        xs: '1.25rem',     // mobile
        sm: '1.75rem',  // tablet
        md: '2rem',   // laptop
        lg: '2.5rem',  // desktop
        xl: '3rem'      // large screens
    };

    const menuItemStyles = {
        '& .MuiLink-root, & a': {
            fontSize: fontSizesProp || defaultFontSizes,
            textDecoration: 'none',
            color: 'inherit',
            '&:visited': {
                color: 'inherit',  // Keep the same color after visiting
            },
            '&:active': {
                color: 'inherit',  // Keep the same color while clicking
            },
            '&:hover': {
                color: 'inherit',  // Keep the same color on hover
            }
        },
        // padding: {
        //     xs: '0.5rem',
        //     sm: '0.75rem',
        //     md: '1rem'
        // },
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
        },
        fontSize: fontSizesProp || defaultFontSizes
    };

    return (
        <MenuItem
            sx={menuItemStyles}
            {...props}
        >
            {children}
        </MenuItem>
    );
}

