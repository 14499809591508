import React, { useState, useEffect, useContext } from 'react';
import { DataStore, SortDirection } from "aws-amplify";
import { PublicClient, PublicAnimal } from '../../../models';

const AmpUserPublicClientsContext = React.createContext();

export function AmpUserPublicClientsProvider({ children, ampUserIdProp, showLogs, resetProviderState }) {
    const [ampUserId, setAmpUserId] = useState(ampUserIdProp);
    const [localShowLogs, setLocalShowLogs] = useState(false);

    const [ampUserPublicClients, setAmpUserPublicClients] = useState([]);
    const [ampUserPublicClientIds, setAmpUserPublicClientIds] = useState([]);
    const [ampUserPublicAnimals, setAmpUserPublicAnimals] = useState([]);
    const [publicAnimalsByPublicClientId, setPublicAnimalsByPublicClientId] = useState({});

    const resetContext = () => {
        setAmpUserId(null);
        setAmpUserPublicClients([]);
        setAmpUserPublicAnimals([]);
    }

    // useEffect( reset state values when resetProviderState is true
    useEffect(() => {
        if (resetProviderState) {
            resetContext();
        }
    }, [resetProviderState]);

    // useEffect( set localShowLogs when showLogs changes
    useEffect(() => {
        if (showLogs) {
            setLocalShowLogs(true);
        } else {
            setLocalShowLogs(false);
        }
    }, [showLogs]);

    // useEffect( setAmpUserId(ampUserIdProp);
    useEffect(() => {
        if (ampUserIdProp) {
            setAmpUserId(ampUserIdProp);
        }
    }, [ampUserIdProp]);

    // useEffect( console.log( ampUserId, ampUserPublicClients, ampUserPublicClientIds, ampUserPublicAnimals, publicAnimalsByPublicClientId
    useEffect(() => {
        if (localShowLogs) {
            console.log('AmpUserPublicClientsProvider ampUserId', ampUserId);
            console.log('AmpUserPublicClientsProvider ampUserPublicClients', ampUserPublicClients);
            console.log('AmpUserPublicClientsProvider ampUserPublicClientIds', ampUserPublicClientIds);
            console.log('AmpUserPublicClientsProvider ampUserPublicAnimals', ampUserPublicAnimals);
            console.log('AmpUserPublicClientsProvider publicAnimalsByPublicClientId', publicAnimalsByPublicClientId);
        }
    }, [ampUserId, ampUserPublicClients, ampUserPublicAnimals, ampUserPublicClientIds, publicAnimalsByPublicClientId, localShowLogs]);

    // TODO - START HERE, FR, FR - 
    //  FIND WHY NEW PUB ANIMALS NOT COMING THROUGH
    useEffect(() => {
        if (!resetProviderState && ampUserId) {
            const subscription = DataStore.observeQuery(PublicClient,
                (pc) => pc.and(pc => [
                    pc.ampuserID.eq(ampUserId),
                    pc.isDeleted.ne(true)  // Filter out records where isDeleted is true
                ]),
                {
                    sort: s => s.createdAt(SortDirection.DESCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    if (isSynced) {
                        setAmpUserPublicClients(items);
                        if (localShowLogs) {
                            console.log(`AmpUserPublicClientsProvider pc [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                            console.log('AmpUserPublicClientsProvider pc [Snapshot] items', items);
                        }

                        // if (items && items?.length > 0) {
                        //     setAmpUserPublicClientIds(items.map(pc => pc.id));
                        // }
                        if (items && items?.length > 0) {
                            setAmpUserPublicClientIds(items.map(pc => pc.id));

                            const animalsByClientId = {};
                            const pubAnimals = [];
                            for await (const item of items) {
                                const clientAnimals = await item.PublicAnimals.toArray();
                                if (localShowLogs) {
                                    console.log('AmpUserPublicClientsProvider pc clientAnimals', clientAnimals);
                                }
                                if (clientAnimals && clientAnimals?.length > 0) {
                                    pubAnimals.push(...clientAnimals);
                                }
                                // if (clientAnimals && clientAnimals?.length > 0) {
                                if (clientAnimals) {
                                    animalsByClientId[item.id] = clientAnimals;
                                }
                                else {
                                    animalsByClientId[item.id] = [];
                                }
                            }
                            setAmpUserPublicAnimals(pubAnimals);
                            setPublicAnimalsByPublicClientId(animalsByClientId);
                        }
                    }
                });
            return () => subscription.unsubscribe();
        }
    }, [ampUserId, localShowLogs, resetProviderState]);

    // useEffect(() => {
    //     // if (!resetProviderState && ampUserId) {
    //     if (!resetProviderState && ampUserId && ampUserPublicClientIds) {
    //         if (ampUserPublicClientIds?.length === 0) {
    //             setAmpUserPublicAnimals([]);
    //             setPublicAnimalsByPublicClientId({});
    //         }
    //     }
    // }, [ampUserId, ampUserPublicClientIds, localShowLogs, resetProviderState]);

    // useEffect(() => {
    //     if (!resetProviderState) {
    //         const subscription = DataStore.observe(PublicClient).subscribe(msg => {
    //             const { element, opType } = msg;
    //             if (opType === 'delete') {
    //                 setPublicClients(prev => prev.filter(c => c.id !== element.id));
    //             } else {
    //                 setPublicClients(prev => {
    //                     const idx = prev.findIndex(c => c.id === element.id);
    //                     if (idx === -1) {
    //                         return [...prev, element];
    //                     } else {
    //                         return prev.map(c => c.id === element.id ? element : c);
    //                     }
    //                 });
    //             }
    //         });
    //         return () => subscription.unsubscribe();
    //     }
    // }, [resetProviderState]);

    // useEffect(() => {
    //     if (!resetProviderState) {
    //         const subscription = DataStore.observe(PublicAnimal).subscribe(msg => {
    //             const { element, opType } = msg;
    //             if (opType === 'delete') {
    //                 setPublicAnimals(prev => prev.filter(a => a.id !== element.id));
    //             } else {
    //                 setPublicAnimals(prev => {
    //                     const idx = prev.findIndex(a => a.id === element.id);
    //                     if (idx === -1) {
    //                         return [...prev, element];
    //                     } else {
    //                         return prev.map(a => a.id === element.id ? element : a);
    //                     }
    //                 });
    //             }
    //         });
    //
    //         return () => subscription.unsubscribe();
    //     }
    // }, [resetProviderState]);

    return (
        <AmpUserPublicClientsContext.Provider value={{ ampUserPublicClients, ampUserPublicAnimals, publicAnimalsByPublicClientId }}>
            {children}
        </AmpUserPublicClientsContext.Provider>
    );
}

export const useAmpUserPublicClientsProvider = () => {
    return useContext(AmpUserPublicClientsContext);
}