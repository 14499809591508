import React, {useState, useEffect} from "react";
import DevStateProvider from "../../../data/providers/DevStateProvider";
import {RequireAuth} from "../../auth/RequireAuth";
import NewAmpUserIntakeScreen from "../../../screens/people/NewAmpUserIntakeScreen";

export default function WrappedAmpUserIntake({routerPaths, ampUser, showLogs, ...props}) {
    const [ampUserRec, setAmpUserRec] = useState(null);

    // useEffect( setAmpUserRec(ampUser);
    useEffect(() => {
        if (ampUser) {
            setAmpUserRec(ampUser);
        }
    }, [ampUser]);

    return (
        <RequireAuth>
            <DevStateProvider>
                {ampUserRec ? (
                    <NewAmpUserIntakeScreen
                        routerPaths={routerPaths}
                        showLogs={showLogs}
                        ampUser={ampUserRec}
                    />
                ) : (
                    <h3>Loading User Info...</h3>
                )}
            </DevStateProvider>
        </RequireAuth>
    )
}
