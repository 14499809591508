import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button} from '@mui/material';
import DialogContentText from "@mui/material/DialogContentText";
import {convertAWSDateTimeToLocalDateTimeExtended} from "../../../utils/datesTimes";


export default function DeleteClientNoteModal({
                                                  open,
                                                  clientNoteRecToDelete,
                                                  handleClose,
                                                  handleConfirm,
                                                  handleCancel,
                                                  setClientNoteToDelete,
                                                  clientRec,
                                                  setDeleteClientNoteRes,
                                                  setDisplayDeleteClientNoteModal,
                                                  showLogs
                                              }) {
    const [localShowLogs, setLocalShowLogs] = useState(false);
    // const [clientNoteToDelete, setClientNoteToDelete] = useState(null);

    // useEffect( setLocalShowLogs(showLogs);
    useEffect(() => {
        if (showLogs) {
            setLocalShowLogs(showLogs);
        } else {
            setLocalShowLogs(false);
        }
    }, [showLogs]);

    // useEffect( setClientNoteToDelete(clientNoteRecToDelete);
    // useEffect(() => {
    // TODO - REPLACED WITH PROPS
    //     if (clientNoteRecToDelete) {
    //         setClientNoteToDelete(clientNoteRecToDelete);
    //     }
    // }, [clientNoteRecToDelete]);

    // useEffect( console.log( clientNoteToDelete
    // useEffect(() => {
    //     if (localShowLogs) {
    //         console.log('DeleteClientNoteModal clientNoteToDelete', clientNoteToDelete);
    //     }
    // }, [localShowLogs, clientNoteToDelete]);

    return (
        <Dialog
            open={open}
            onClose={() => handleClose(setDisplayDeleteClientNoteModal, setClientNoteToDelete, showLogs)}
        >
            <DialogTitle>{"Confirm Delete Client Note"}</DialogTitle>
            <DialogContent>
                {clientNoteRecToDelete ? (
                    <div>
                        <DialogContentText>
                            Are you sure you want to delete this client note?
                        </DialogContentText>
                        <DialogContentText>
                            Client: {clientRec?.name}
                        </DialogContentText>
                        <DialogContentText>
                            Created
                            At: {clientNoteRecToDelete?.createdAt ? convertAWSDateTimeToLocalDateTimeExtended(clientNoteRecToDelete.createdAt) : ''}
                        </DialogContentText>
                        <DialogContentText>
                            Note: {clientNoteRecToDelete?.note}
                        </DialogContentText>
                    </div>
                ) : (
                    <DialogContentText>
                        No record found
                    </DialogContentText>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => handleCancel(clientNoteRecToDelete, setDisplayDeleteClientNoteModal, setClientNoteToDelete, showLogs)}
                    color="primary"
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => handleConfirm(clientNoteRecToDelete, setDeleteClientNoteRes, setDisplayDeleteClientNoteModal, setClientNoteToDelete, showLogs)}
                    color="warning"
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}

// export default DeleteClientNoteModal;
