import React, { useState, useEffect } from 'react';
import BaseNavigateButton from "../links/BaseNavigateButton";
import { idbFarrierAlertNavPropsFactory } from "../../utils/idbAlerts/alertNavHelpers";
import BaseStyledButton from "./BaseStyledButton";
import { useNavigate } from "react-router-dom";

// export default function UnAckedAlertViewButton(props) {
// export default function UnAckedAlertViewButton({ alertIDBStoreNameProp, alertIDBRecProp, showLogsProp, ...props }) {
export default function FarrierAlertViewButton({ alertIDBStoreNameProp, alertIDBRecProp, showLogsProp, ...props }) {
    const [navTarget, setNavTarget] = useState(null);
    const [showLogs, setShowLogs] = useState(false);
    const [navStateObj, setNavStateObj] = useState(null);
    const [alertIDBRec, setAlertIDBRec] = useState(null);
    const [alertIDBStoreName, setAlertIDBStoreName] = useState(null);
    const [isDisabled, setIsDisabled] = useState(true);

    const navigate = useNavigate();

    // useEffect( set showLogs from props.showLogs
    // useEffect(() => {
    //     if (props?.showLogs) {
    //         setShowLogs(props.showLogs);
    //     } else {
    //         setShowLogs(false);
    //     }
    // }, [props.showLogs]);

    // useEffect( set showLogs from showLogsProp
    useEffect(() => {
        if (showLogsProp) {
            setShowLogs(showLogsProp);
        } else {
            setShowLogs(false);
        }
    }, [showLogsProp]);

    // useEffect( setAlertIDBRec(props.alertIDBRec);
    // useEffect(() => {
    //     if (props?.alertIDBRec) {
    //         setAlertIDBRec(props.alertIDBRec);
    //     }
    // }, [props.alertIDBRec]);

    // useEffect( setAlertIDBRec(alertIDBRecProp);
    useEffect(() => {
        if (alertIDBRecProp) {
            setAlertIDBRec(alertIDBRecProp);
        }
    }, [alertIDBRecProp]);

    // useEffect( setAlertIDBStoreName(props.alertIDBStoreName);
    // useEffect(() => {
    //     if (props?.alertIDBStoreName) {
    //         setAlertIDBStoreName(props.alertIDBStoreName);
    //     }
    // }, [props.alertIDBStoreName]);

    // useEffect( setAlertIDBStoreName(alertIDBStoreNameProp);
    useEffect(() => {
        if (alertIDBStoreNameProp) {
            setAlertIDBStoreName(alertIDBStoreNameProp);
        }
    }, [alertIDBStoreNameProp]);

    // useEffect( set navTarget and navStateObj from idbFarrierAlertNavPropsFactory(
    useEffect(() => {
        if (alertIDBRec && alertIDBStoreName) {
            const navProps = idbFarrierAlertNavPropsFactory(alertIDBRec, alertIDBStoreName, showLogs);
            setNavTarget(navProps.alertNavTarget);
            setNavStateObj(navProps.alertNavStateObj);
        }
    }, [alertIDBRec, alertIDBStoreName]);

    // useEffect( set isDisabled from alertIDBRec, alertIDBStoreName, navTarget, navStateObj
    useEffect(() => {
        let navButtonDisabled = false;
        if (!alertIDBRec) {
            if (showLogs) {
                console.error('FarrierAlertViewButton: alertIDBRec is null');
            }
            // setIsDisabled(true);
            navButtonDisabled = true;
        }
        else if (!alertIDBStoreName) {
            if (showLogs) {
                console.error('FarrierAlertViewButton: alertIDBStoreName is null');
            }
            // setIsDisabled(true);
            navButtonDisabled = true;
        }
        else if (!navTarget) {
            if (showLogs) {
                console.error('FarrierAlertViewButton: navTarget is null');
            }
            // setIsDisabled(true);
            navButtonDisabled = true;
        }
        else if (!navStateObj) {
            if (showLogs) {
                console.error('FarrierAlertViewButton: navStateObj is null');
            }
            // setIsDisabled(true);
            navButtonDisabled = true;
        }
        else {
            if (showLogs) {
                console.log('FarrierAlertViewButton: navTarget', navTarget);
                console.log('FarrierAlertViewButton: navStateObj', navStateObj);
            }
            // setIsDisabled(false);
            navButtonDisabled = false;
        }
        setIsDisabled(navButtonDisabled);
    }, [navTarget, navStateObj, alertIDBRec, alertIDBStoreName, showLogs]);


    return (
        <BaseStyledButton
            label={props?.label || 'View Alert'}
            onClick={() => {
                if (navTarget) {
                    navigate(navTarget, {
                        state: navStateObj,
                    });
                }
            }}
            // navTarget={navTarget || '/'}
            // navState={navStateObj || {}}
            disabled={isDisabled}
            {...props}
        />
    );

    // return (
    //     <BaseNavigateButton
    //         label={props?.label || 'View Alert'}
    //         navTarget={navTarget || '/'}
    //         navState={navStateObj || {}}
    //         disabled={isDisabled}
    //         {...props}
    //     />
    // );
}

