import { createIdbAlertId } from '../../../../utils/helpers.js';

export class FarrierAlert {
    constructor({
        // alertId = crypto.randomUUID(),
        alertId = createIdbAlertId(),
        alertMsg,
        ackHash,
        alertData,
        isAcknowledged = false,
        acknowledgedAt,
        isPinned = false,
        pinnedAt,
        unPinnedAt,
        ampUserId,
        alertType,
        createdAt = new Date().toISOString()
                }) {
        this.alertId = alertId;
        this.alertMsg = alertMsg;
        this.isAcknowledged = isAcknowledged;
        this.isPinned = isPinned;
        this.ampUserId = ampUserId;
        this.createdAt = createdAt;
        this.acknowledgedAt = acknowledgedAt;
        this.pinnedAt = pinnedAt;
        this.alertType = alertType;
        this.alertData = alertData;
        this.updatedAt = createdAt;
        this.ackHash = ackHash;
        this.unPinnedAt = unPinnedAt;
    }

    acknowledge() {
        this.isAcknowledged = true;
        this.acknowledgedAt = new Date().toISOString();
        this.updatedAt = new Date().toISOString();
    }

    pin() {
        this.isPinned = true;
        this.pinnedAt = new Date().toISOString();
        this.updatedAt = new Date().toISOString();
    }

    unPin() {
        this.isPinned = false;
        this.unPinnedAt = new Date().toISOString();
        this.updatedAt = new Date().toISOString();
    }

    toJSON() {
        return {
            alertId: this.alertId,
            alertMsg: this.alertMsg,
            isAcknowledged: this.isAcknowledged,
            ampUserId: this.ampUserId,
            createdAt: this.createdAt,
            acknowledgedAt: this.acknowledgedAt,
            isPinned: this.isPinned,
            pinnedAt: this.pinnedAt,
            alertType: this.alertType,
            alertData: this.alertData,
            updatedAt: this.updatedAt,
            ackHash: this.ackHash,
            unPinnedAt: this.unPinnedAt,
        };
    }

    static fromJSON(json) {
        // return new FarrierAlert(json);
        return new this(json);
    }
}

export class FarrierClientAlert extends FarrierAlert {
    constructor({ clientId, ...baseProps }) {
        super(baseProps);
        this.clientId = clientId;
    }

    toJSON() {
        return {
            ...super.toJSON(),
            clientId: this.clientId,
        };
    }

    static fromJSON(json) {
        const { clientId, ...baseProps } = json;
        return new this({ clientId, ...baseProps });
    }
}

export class FarrierPublicClientAlert extends FarrierAlert {
    constructor({ publicClientId, ...baseProps }) {
        super(baseProps);
        this.publicClientId = publicClientId;
    }

    toJSON() {
        return {
            ...super.toJSON(),
            publicClientId: this.publicClientId,
        };
    }

    static fromJSON(json) {
        const { publicClientId, ...baseProps } = json;
        return new this({ publicClientId, ...baseProps });
    }
}

// Subclass for alerts related to a specific animal
export class FarrierAnimalAlert extends FarrierAlert {
    constructor({ animalId, ...baseProps }) {
        super(baseProps);
        this.animalId = animalId;
    }

    toJSON() {
        return {
            ...super.toJSON(),
            animalId: this.animalId,
        };
    }

    static fromJSON(json) {
        const { animalId, ...baseProps } = json;
        return new this({ animalId, ...baseProps });
    }
}

export class FarrierPublicAnimalAlert extends FarrierAlert {
    constructor({ publicAnimalId, ...baseProps }) {
        super(baseProps);
        this.publicAnimalId = publicAnimalId;
    }

    toJSON() {
        return {
            ...super.toJSON(),
            publicAnimalId: this.publicAnimalId,
        };
    }

    static fromJSON(json) {
        const { publicAnimalId, ...baseProps } = json;
        return new this({ publicAnimalId, ...baseProps });
    }
}
