import React, { useState, useEffect } from "react";
import { AnimalStatus, AnimalType } from "../../../models";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import StyledTextField from "../../text/StyledTextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import BaseStyledButton from "../../buttons/BaseStyledButton";
import { generateUniqueKey } from "../../../utils/helpers";
import { createAnimalFormObj } from "../../../utils/formObjects";
import { useConsoleLog } from "../../hooks/logging/console";
import { convertToFullIntegerHandleNaN } from "../../../utils/numbers";
import StyledCardContentTypography from "../../text/StyledCardContentTypography";

// import {createAnimalRecWithNoteShoePkgRecs, createBaseAnimalRec} from "../../data/create/animal/createAnimalRecs";
// import {handleCurrencyTextFieldChangeFormattedUSD} from "../../utils/text";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

// TODO - USE THIS TO REPLACE PREV - CreateAnimalBaseForm

export default function CreateAnimalSubsetForm(props) {
    const [showLogs, setShowLogs] = useState(false);
    const [ampUser, setAmpUser] = useState(null);

    // This is for creating an animal, with no specific client data passed down to here
    // const [selectedAmpUserClient, setSelectedAmpUserClient] = useState(null);

    // This form is for a single, preset client
    const [selectedClient, setSelectedClient] = useState(null);

    // This is the name from the form's select client dropdown
    const [selectedClientName, setSelectedClientName] = useState('');

    const [animalTypeEnums, setAnimalTypeEnums] = useState([]);
    const [selectedAnimalType, setSelectedAnimalType] = useState('');
    const [animalStatusEnums, setAnimalStatusEnums] = useState([]);
    const [selectedAnimalStatus, setSelectedAnimalStatus] = useState('');

    // const [newAnimalRec, setNewAnimalRec] = useState(null);

    // TODO - ? ON NAV BACK, SET ORIG FORM VALS TOO?
    const [origAnimalFormValues, setOrigAnimalFormValues] = useState(createAnimalFormObj);
    const [updatedAnimalFormValues, setUpdatedAnimalFormValues] = useState(createAnimalFormObj);

    // useEffect( setAmpUser(props.ampUser);
    useEffect(() => {
        if (props?.ampUser) {
            setAmpUser(props.ampUser);
        }
    }, [props.ampUser]);

    // useEffect( setShowLogs(props.showLogs);
    useEffect(() => {
        if (props?.showLogs) {
            setShowLogs(props.showLogs);
        }
    }, [props.showLogs]);

    // useEffect( setSelectedClient(props.selectedClientProp);
    useEffect(() => {
        if (props?.selectedClientProp) {
            setSelectedClient(props.selectedClientProp);
            setOrigAnimalFormValues({...origAnimalFormValues, clientId: props.selectedClientProp.id});
            setUpdatedAnimalFormValues({...updatedAnimalFormValues, clientId: props.selectedClientProp.id});
        }
    }, [props.selectedClientProp]);

    // useEffect( set clientId in animal forms, based on selectedClient(Prop) existing
    useEffect(() => {
        if (selectedClient) {
            // setSelectedClient(props.selectedClientProp);
            setOrigAnimalFormValues({...origAnimalFormValues, clientId: selectedClient.id});
            setUpdatedAnimalFormValues({...updatedAnimalFormValues, clientId: selectedClient.id});
        }
        if (selectedClient && selectedClientName === '') {
            setSelectedClientName(selectedClient.name);
        }
    }, [selectedClient]);

    // useEffect( setAnimalStatusEnums(AnimalStatus);
    useEffect(() => {
        if (AnimalStatus) {
            setAnimalStatusEnums(AnimalStatus);
        }
    }, [AnimalStatus]);

    // useEffect( setAnimalTypeEnums(AnimalType);
    useEffect(() => {
        if (AnimalType) {
            setAnimalTypeEnums(AnimalType);
        }
    }, [AnimalType]);

    useEffect(() => {
        if (props?.existingAnimalFormData) {
            // const existingFormVals = {}
            // populate updatedAnimalFormValues using props.existingAnimalFormData
            // Object.entries(props.existingAnimalFormData).forEach()
            setUpdatedAnimalFormValues(props.existingAnimalFormData);
            if (props.existingAnimalFormData?.animalStatus) {
                setSelectedAnimalStatus(props.existingAnimalFormData.animalStatus)
            }
            if (props.existingAnimalFormData?.animalType) {
                setSelectedAnimalType(props.existingAnimalFormData.animalType)
            }
        }
    }, [props.existingAnimalFormData])

    useConsoleLog(showLogs, [{label: 'CreateAnimalForm ampUser', value: ampUser}]);

    useConsoleLog(showLogs, [{label: 'CreateAnimalForm selectedClient', value: selectedClient}]);

    useConsoleLog(showLogs, [{label: 'CreateAnimalForm selectedClientName', value: selectedClientName}]);

    useConsoleLog(showLogs, [{label: 'CreateAnimalForm selectedAnimalType', value: selectedAnimalType}]);

    useConsoleLog(showLogs, [{label: 'CreateAnimalForm animalStatusEnums', value: animalStatusEnums}]);

    useConsoleLog(showLogs, [{label: 'CreateAnimalForm animalTypeEnums', value: animalTypeEnums}]);

    useConsoleLog(showLogs, [{label: 'CreateAnimalForm updatedAnimalFormValues', value: updatedAnimalFormValues}]);

    const handleUpdatedAnimalFormChange = (eventKey, eventValue) => {
        // let eventFormVal;
        if (showLogs) {
            console.log('eventKey', eventKey);
            console.log('eventValue', eventValue);
        }
        if (['age'].includes(eventKey)) {
            const eventFormValInt = convertToFullIntegerHandleNaN(eventValue);
            setUpdatedAnimalFormValues({...updatedAnimalFormValues, [eventKey]: eventFormValInt});
        }
        else {
            setUpdatedAnimalFormValues({...updatedAnimalFormValues, [eventKey]: eventValue});
        }
    }

    // TODO - PREVENT DUPE RECORDS, BASED ON CRITERIA FROM ANIMAL CRUD UPDATES GOOGLE DOC
    function onConfirmCreateAnimalRec() {
        props.handleConfirmCreateProp(updatedAnimalFormValues);

        // TODO - ADD PROGRESS DIALOG, SUCCESS DIALOG, ERROR DIALOG
        // Also contains res objs for create new animal note, new shoe package
        // const createAnimalResObj = await createBaseAnimalRec(updatedAnimalFormValues, selectedClient.id, ampUser.id, showLogs);
        // if (createAnimalResObj?.success) {
        //     setNewAnimalRec(createAnimalResObj.newAnimalRec);
        // }
    }

    // TODO - INFORM USER OF REQUIRED FIELDS TO CONFIRM CREATE
    return (
        <div>
            <StyledCardContentTypography
                text={`Client: ${selectedClient?.name || '-'}`}
            />
            <Divider>
                <Chip label="Animal Info"/>
            </Divider>
            <div>
                <FormControl sx={{m: 1, width: '90%'}}>
                    <InputLabel id={`create-animal-animal-type-input-label`}>Animal Type</InputLabel>
                    <Select
                        labelId={`create-animal-animal-type-label-id`}
                        id={`create-animal-animal-type-select`}
                        value={selectedAnimalType}
                        onChange={(event) => {
                            setSelectedAnimalType(event.target.value);
                            // setFormValues({...formValues, animalType: event.target.value})
                            // setUpdatedAnimalFormValues({...updatedAnimalFormValues, animalType: event.target.value});
                            handleUpdatedAnimalFormChange('animalType', event.target.value);
                        }}
                        MenuProps={MenuProps}
                    >
                        {Object.entries(animalTypeEnums).map(([key, val]) => (
                            <MenuItem
                                key={generateUniqueKey(key)}  // Use the key for generating a unique key
                                value={val}  // Use the key as the value for the MenuItem
                                sx={{width: '90%'}}
                            >
                                {val}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div>
                <FormControl sx={{m: 1, width: '90%'}}>
                    <InputLabel id={`create-animal-animal-status-input-label`}>Animal Status</InputLabel>
                    <Select
                        labelId={`create-animal-animal-status-label-id`}
                        id={`create-animal-animal-status-select`}
                        value={selectedAnimalStatus}
                        onChange={(event) => {
                            setSelectedAnimalStatus(event.target.value);
                            // setUpdatedAnimalFormValues({...updatedAnimalFormValues, animalStatus: event.target.value});
                            handleUpdatedAnimalFormChange('animalStatus', event.target.value);
                        }}
                        MenuProps={MenuProps}
                    >
                        {Object.entries(animalStatusEnums).map(([key, val]) => (
                            <MenuItem
                                key={generateUniqueKey(key)}  // Use the key for generating a unique key
                                value={val}  // Use the key as the value for the MenuItem
                                sx={{width: '90%'}}
                            >
                                {val}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div>
                <StyledTextField
                    id="name"
                    label="Name"
                    value={updatedAnimalFormValues.name}
                    onChange={(event) => handleUpdatedAnimalFormChange('name', event.target.value)}
                />
            </div>
            <div>
                <StyledTextField
                    id="age"
                    label="Age (Years)"
                    value={updatedAnimalFormValues.age}
                    onChange={(event) => handleUpdatedAnimalFormChange('age', event.target.value)}
                />
            </div>
            <Divider>
                <Chip label={`${selectedAnimalType} Info`}/>
            </Divider>
            <div>
                <StyledTextField
                    id="animal-breed"
                    label="Breed"
                    value={updatedAnimalFormValues.breed}
                    // onChange={(event) => handleHorseTextFieldChange('breed', event)}
                    onChange={(event) => handleUpdatedAnimalFormChange('breed', event.target.value)}
                />
            </div>
            <div>
                <StyledTextField
                    id="animal-color"
                    label="Color"
                    value={updatedAnimalFormValues.color}
                    // onChange={(event) => handleHorseTextFieldChange('color', event)}
                    onChange={(event) => handleUpdatedAnimalFormChange('color', event.target.value)}
                />
            </div>
            <div>
                <StyledTextField
                    id="animal-discipline"
                    label="Discipline"
                    value={updatedAnimalFormValues.discipline}
                    // onChange={(event) => handleHorseTextFieldChange('discipline', event)}
                    onChange={(event) => handleUpdatedAnimalFormChange('discipline', event.target.value)}
                />
            </div>
            <BaseStyledButton
                label="Create Animal"
                disabled={!selectedClient || !updatedAnimalFormValues.name || !updatedAnimalFormValues.animalType}
                // onClick={onCreateAnimalRec}
                onClick={onConfirmCreateAnimalRec}
            />
        </div>
    )
}
