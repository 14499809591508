import React, { createContext, useContext, useState, useEffect } from 'react';
import { openDB } from 'idb';
import { useLocation, useParams } from 'react-router-dom';

// Create the context
const TempSharedPubClientFormContext = createContext();

// const dbPromise = openDB('TempSharedPubClientFormDataDB', 1, {
//     upgrade(db) {
//         if (!db.objectStoreNames.contains('tempSharedPubClientFormData')) {
//             db.createObjectStore('tempSharedPubClientFormData');
//         }
//     },
// });

const dbPromise = openDB('TempSharedPubClientFormDataDB', 2, { // Increment version to 2
    upgrade(db) {
        if (!db.objectStoreNames.contains('tempSharedPubClientFormData')) {
            db.createObjectStore('tempSharedPubClientFormData', { keyPath: 'sharedRouterPath' });
        } else {
            // If the object store already exists, delete and recreate it with the new key path
            db.deleteObjectStore('tempSharedPubClientFormData');
            db.createObjectStore('tempSharedPubClientFormData', { keyPath: 'sharedRouterPath' });
        }
    },
});


// async function saveFormData(sharedRouterPath, data) {
//     const db = await dbPromise;
//     await db.put('tempSharedPubClientFormData', data, sharedRouterPath);
// }

async function saveFormData(sharedRouterPath, data) {
    const db = await dbPromise;
    const dataWithKey = { ...data, sharedRouterPath }; // Include sharedRouterPath in the data object
    await db.put('tempSharedPubClientFormData', dataWithKey);
}


async function loadFormData(sharedRouterPath) {
    const db = await dbPromise;
    return await db.get('tempSharedPubClientFormData', sharedRouterPath) || {};
}

async function clearFormData(sharedRouterPath) {
    const db = await dbPromise;
    await db.delete('tempSharedPubClientFormData', sharedRouterPath);
}

// TODO - GET FORM VALS TO SAVE AND FETCH ON NAV AWAY

export function TempSharedPubClientFormDataProvider({ children }) {
    const [formValues, setFormValues] = useState({});

    const { publicClientSharedRouterPath: sharedRouterPath } = useParams();

    const location = useLocation();

    useEffect(() => {
        if (!sharedRouterPath) return;
        // Load form data when the component mounts
        loadFormData(sharedRouterPath).then(data => setFormValues(data));
    }, [sharedRouterPath]);

    useEffect(() => {
        const handleBeforeUnload = () => {
            // Save form data before page unload (refresh or close)
            saveFormData(sharedRouterPath, formValues);
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [formValues, sharedRouterPath]);

    // useEffect(() => {
    //     const resetOnUnmount = () => {
    //         // When navigating away from the base route, reset form values
    //         if (!location.pathname.startsWith('/shared-public-client')) {
    //             setFormValues({});
    //         }
    //     };
    //
    //     return () => {
    //         resetOnUnmount();
    //     };
    // }, [location, sharedRouterPath]);

    const updateFormValues = (name, value) => {
        setFormValues(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const onBlurUpdateSaveFormValues = async (name, value) => {
        const formValsCopy = { ...formValues };
        formValsCopy[name] = value;
        setFormValues(prev => ({
            ...prev,
            [name]: value,
        }));
        console.log('sharedRouterPath', sharedRouterPath);
        console.log('formValsCopy', formValsCopy);
        await saveFormData(sharedRouterPath, formValsCopy);
    };

    const onBlurRemoveFieldFromIDB = async (name) => {
        const formValsCopy = { ...formValues };
        // Remove the field from the local copy
        delete formValsCopy[name];

        // Update the state to remove the field
        setFormValues(formValsCopy);

        // Save the updated form without the removed field to IndexedDB
        await saveFormData(sharedRouterPath, formValsCopy);
    };

    // const resetFormValues = () => {
    //     setFormValues({});
    //     clearFormData(sharedRouterPath);
    // };

    const resetFormValues = async () => {
        setFormValues({});
        await clearFormData(sharedRouterPath);
    };

    // const updateIDBFormValues = () => {
    //     // Save the current form values to IndexedDB
    //     saveFormData(sharedRouterPath, formValues);
    // };

    const updateIDBFormValues = async () => {
        // Save the current form values to IndexedDB
        await saveFormData(sharedRouterPath, formValues);
    };

    const updateMultipleFormValues = (updatedValues) => {
        setFormValues(prev => ({
            ...prev,
            ...updatedValues,
        }));
    };

    const updateSaveMultipleFormValues = async (updatedValues) => {
        const formValsCopy = { ...formValues, ...updatedValues };
        setFormValues(prev => ({
            ...prev,
            ...updatedValues,
        }));
        await saveFormData(sharedRouterPath, formValsCopy);
    };

    return (
        <TempSharedPubClientFormContext.Provider value={{
            formValues,
            updateFormValues,
            resetFormValues,
            updateIDBFormValues,
            updateMultipleFormValues,
            onBlurUpdateSaveFormValues,
            onBlurRemoveFieldFromIDB,
            updateSaveMultipleFormValues
        }}>
            {children}
        </TempSharedPubClientFormContext.Provider>
    );
}

// Custom hook to use the context
// export function useTempSharedPubClientFormDataProvider() {
export function useTempSharedPubClientFormData() {
    return useContext(TempSharedPubClientFormContext);
}
