import React from 'react';
import Typography from '@mui/material/Typography';

const StyledCardContentTypography = ({ text, fullWidth = false, ...props }) => {
    return (
        <Typography
            gutterBottom
            sx={{
                // Add default styles specific to the Typography component
                // For example, setting font size, weight, color, etc.
                // fontSize: '1rem', // Example font size
                fontSize: props?.fontSize || ['6vw', '5vw', '4vw', '3vw'],
                fontWeight: 'normal', // Example font weight
                // color: 'text.primary', // Example color
                // marginBottom: 1, // Example margin at the bottom
                margin: props.margin || 0,
                // Add more default styles if necessary
                backgroundColor: props?.backgroundColor || 'transparent',
                // Ensure text wraps to multiple lines if necessary
                // whiteSpace: 'normal',
                whiteSpace: 'pre-line',
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
                textAlign: props?.style?.textAlign || 'center',
                // Conditionally apply width: 100% if fullWidth is true
                width: fullWidth ? '100%' : 'auto', // Default to auto width unless fullWidth is true
                marginLeft: props?.style?.marginLeft || 'auto',
                // Overwrite with props.sx to allow for custom styles
                ...props.sx,
            }}
            {...props} // Spread the rest of the props to the Typography component
        >
            {text}
        </Typography>
    );
};

export default StyledCardContentTypography;
