import React, {useState, useEffect} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import BaseNavigateButton from "../links/BaseNavigateButton";
import BaseStyledButton from "../buttons/BaseStyledButton";
import {useNavigate} from "react-router-dom";

export default function PromptAmpUserIntake({openProp,
                                                ampUserProp,
                                                handleCloseProp,
                                                handleConfirmNavProp,
                                                showLogs}) {

    const navigate = useNavigate();

    return (
        <Dialog open={openProp}
                onClose={handleCloseProp}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
        >
            <DialogTitle>Add Your User Info</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    To get started, please add your user information.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <BaseStyledButton
                    onClick={() => {
                        handleConfirmNavProp();
                        navigate('/user-intake', {state: {ampUser: ampUserProp}});
                    }}
                    color="primary"
                    label={"Add User Info"}
                />
                <BaseStyledButton
                    onClick={handleCloseProp}
                    color="primary"
                    label={"Later"}
                />
            </DialogActions>
        </Dialog>
    );
}
