import React, {useState, useEffect} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CreateAnimalSubsetForm from "../../forms/create/CreateAnimaSubsetForm";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CircularProgress from "@mui/material/CircularProgress";
import {createAnimalSubsetAttrs} from "../../../utils/formObjects";
import {createBaseAnimalRec} from "../../../data/create/animal/createAnimalRecs";

// import DialogContentText from '@mui/material/DialogContentText';
// import BaseStyledLinkButton from "../../links/BaseStyledLinkButton";
// import IconButton from '@mui/material/IconButton';
// import Stack from '@mui/material/Stack';
// import {ArrowBack} from "@mui/icons-material";
// import {camelCaseToTitleCase} from "../../../utils/text";
// import Divider from "@mui/material/Divider";
// import CreateAnimalBaseForm from "../../forms/CreateAnimalBaseForm";


// TODO - USE TO REPLACE PREV - CreateAnimalDialogBase

export default function CreateAnimalDialog({ openProp,
                                               ampUser,
                                               handleCloseProp,
                                               clientRec,
                                               showLogs,
                                               ...props }) {

    const [open, setOpen] = useState(false);
    const [pauseOnFormSubmit, setPauseOnFormSubmit] = useState(false);
    const [createAnimalResObj, setCreateAnimalResObj] = useState(null);
    const [confirmStep, setConfirmStep] = useState(1);
    const [isCreating, setIsCreating] = useState(false);
    const [createErrorMsg, setCreateErrorMsg] = useState(null);
    const [createSuccessMsg, setCreateSuccessMsg] = useState(null);
    const [newAnimalFormDataObj, setNewAnimalFormDataObj] = useState(null);

    // useEffect( if (openProp) { setOpen(true);
    useEffect(() => {
        if (openProp) {
            setOpen(true);
        }
    }, [openProp]);

    const handleClose = () => {
        if (showLogs) console.log('### CreateAnimalDialogBase handleClose');
        // setOpen(false);
        if (handleCloseProp) {
            if (!pauseOnFormSubmit) {
                // TODO - verify these should all be reset on close
                setNewAnimalFormDataObj(null);
                setConfirmStep(1);
                setCreateAnimalResObj(null);
                setIsCreating(false);
                setCreateErrorMsg(null);
                setCreateSuccessMsg(null);
                setPauseOnFormSubmit(false);
                handleCloseProp();
            }
            // props.handleCloseProp();
        }
    }

    const handleConfirmCreate = async () => {
        if (showLogs) console.log('### CreateAnimalDialogBase handleConfirmCreate');
        // TODO - Add create animal logic here
        // Create rec done in create form, add any other actions here
        // setOpen(false);
        setPauseOnFormSubmit(true);
        const createAnimalRes = await createBaseAnimalRec(newAnimalFormDataObj, clientRec.id, ampUser.id, showLogs);
        setCreateAnimalResObj(createAnimalRes);
        // if (createAnimalResObj?.success) {
        //     setNewAnimalRec(createAnimalResObj.newAnimalRec);
        // }
        return createAnimalRes;
    }

    // const handleCreateRecDone = (createRecResObj) => {
    //     if (showLogs) console.log('### CreateAnimalDialogBase handleCreateRecDone');
    //     if (showLogs) console.log('### CreateAnimalDialogBase handleCreateRecDone createRecResObj', createRecResObj);
    //     // TODO - Add create animal logic here
    //     // Create rec done in create form, add any other actions here
    //     // setOpen(false);
    //     setCreateAnimalResObj(createRecResObj);
    //     setPauseOnFormSubmit(false);
    //     // TODO - ADD ANY OUTCOMES TEXT HERE
    //     // if (props?.handleCloseProp) {
    //     //     props.handleCloseProp();
    //     // }
    // }

    // const handleCancelCreate = () => {
    //     if (showLogs) console.log('### CreateAnimalDialogBase handleCancelCreate');
    //     // setOpen(false);
    //     if (props?.handleCloseProp) {
    //         if (!pauseOnFormSubmit) {
    //             props.handleCloseProp();
    //         }
    //         // props.handleCloseProp();
    //     }
    // }

    const handleInitialConfirm = (newAnimalFormData) => {
        setNewAnimalFormDataObj(newAnimalFormData);
        setConfirmStep(2);
    };

    const handleFinalConfirm = async () => {
        let createError = false;
        let createResObj = null;
        try {
            setIsCreating(true);
            setCreateErrorMsg(null);
            setCreateSuccessMsg(null);
            // onConfirm(typesToDelete);
            createResObj = await handleConfirmCreate();
            setConfirmStep(3);
            // onClose();
            setPauseOnFormSubmit(false);
        }
        catch (error) {
            setCreateErrorMsg(error);
            createError = true;
        }
        finally {
            setIsCreating(false);
            // handleClose();
            setPauseOnFormSubmit(false);
            if (!createError) {
                if (createResObj) {
                    if (createResObj.success) {
                        setCreateSuccessMsg('New animal record successfully created!')
                    }
                    else {
                        if (createResObj.error) {
                            setCreateErrorMsg(createResObj.error);
                        }
                        else {
                            setCreateErrorMsg('Error creating new animal record')
                        }
                    }
                }
            }
        }
        // handleClose();
    };

    const handleBack = () => {
        setConfirmStep(1);
    };

    const renderStep1 = () => (
        <>
            <DialogTitle>Create New Animal</DialogTitle>
            <DialogContent>
                <CreateAnimalSubsetForm
                    ampUser={ampUser}
                    existingAnimalFormData={newAnimalFormDataObj}
                    selectedClientProp={clientRec}
                    handleConfirmCreateProp={handleInitialConfirm}
                    showLogs={showLogs}
                    />
            </DialogContent>
            <DialogActions>
                {/*<Button onClick={onClose}>Cancel</Button>*/}
                <Button onClick={handleClose}>Cancel</Button>
                {/*<Button onClick={handleInitialConfirm} color="primary">*/}
                {/*    Confirm*/}
                {/*</Button>*/}
            </DialogActions>
        </>
    );

    const renderStep2 = () => {
        if (newAnimalFormDataObj) {
            // const animalFields = Object.entries(newAnimalFormDataObj).map();
            return (
                <>
                    <DialogTitle>Confirm Animal Data</DialogTitle>
                    <DialogContent>
                        <p>You are about to create an animal with these values:</p>
                        <List>
                            {Object.entries(newAnimalFormDataObj).map(([key, value], idx) => {
                                if (createAnimalSubsetAttrs.includes(key)) {
                                    return (
                                        <ListItem key={`${key}-${idx}`}>
                                            <ListItemText primary={`${key.charAt(0).toUpperCase() + key.slice(1)}: ${value}`}/>
                                        </ListItem>
                                    )
                                }
                            })}
                        </List>
                        <p>Click Confirm to create this record</p>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} disabled={isCreating}>Cancel</Button>
                        <Button onClick={handleBack} color="secondary" disabled={isCreating}>
                            Back
                        </Button>
                        <Button onClick={async () => await handleFinalConfirm()} color="primary" disabled={isCreating}>
                            {isCreating ? <CircularProgress size={24} /> : 'Confirm Create'}
                        </Button>
                    </DialogActions>
                </>
            );
        }
        // else {
        //     return (
        //         <>
        //
        //         </>
        //     )
        // }
    };

    const renderStep3 = () => (
        <>
            <DialogTitle>Create Confirmation</DialogTitle>
            <DialogContent>
                {createErrorMsg ? (
                    <p>Error: {createErrorMsg}</p>
                ) : (
                    <p>{createSuccessMsg || 'The new animal record has been successfully created!'}</p>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </>
    );

    return (
        <Dialog open={open} onClose={handleClose}>
            {confirmStep === 1 ? renderStep1() :
                confirmStep === 2 ? renderStep2() :
                        renderStep3()}
        </Dialog>
    );
}
