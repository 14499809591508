import React, { useState, useEffect } from "react";
import { AnimalStatus, AnimalType } from "../../../models";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import StyledCardContentTypography from "../../text/StyledCardContentTypography";
import StyledTextField from "../../text/StyledTextField";
import BaseStyledButton from "../../buttons/BaseStyledButton";
import { generateUniqueKey } from "../../../utils/helpers";
import { convertToFullIntegerHandleNaN } from "../../../utils/numbers";
import { editAnimalFormObj,
    editShoePackageValuesFormObj,
    animalFormsTooltipTextsObj } from "../../../utils/formObjects";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import AccordionDetails from "@mui/material/AccordionDetails";
import StyledListCard from "../../cards/StyledListCard";
import DeleteAnimalNoteConfirmationDialog from "../../modals/delete/DeleteAnimalNoteModal";
import { deleteAnimalNoteById } from "../../../data/delete/deleteRecords";
import BaseStyledDeleteButton from "../../buttons/BaseStyledDeleteButton";
import { useConsoleLog } from "../../hooks/logging/console";
import TooltipWrapper from "../../text/TooltipWrapper";
import CreateAnimalNoteDialog from "../../modals/create/CreateAnimalNoteDialog";
import { useEditAnimalFormProvider } from "../../../data/providers/idb/EditAnimalFormProvider";
import ConfirmUpdateAnimalDialog from "../../modals/update/ConfirmUpdateAnimalDialog";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

// TODO - ADD UPDATE / CREATE SHOE PACKAGE DIALOG,
//  ADD UPDATE ANIMAL DIALOG,
//  USE INDEXEDDB FOR STORING CHANGES ON REFRESH

export default function EditAnimalForm(props) {
    const [ampUser, setAmpUser] = useState(null);
    const [showLogs, setShowLogs] = useState(false);
    const [animalRec, setAnimalRec] = useState(null);
    const [clientRec, setClientRec] = useState(null);
    const [animalTypesEnums, setAnimalTypesEnums] = useState([]);
    const [animalStatusEnums, setAnimalStatusEnums] = useState([]);
    const [updateAnimalRecRes, setUpdateAnimalRecRes] = useState({});

    const [showDeleteAnimalNoteModal, setShowDeleteAnimalNoteModal] = useState(false);
    const [animalNoteToDelete, setAnimalNoteToDelete] = useState(null);

    const [animalNotesRecs, setAnimalNotesRecs] = useState([]);
    const [origAnimalFormVals, setOrigAnimalFormVals] = useState(editAnimalFormObj);
    const [editedAnimalFormVals, setEditedAnimalFormVals] = useState(editAnimalFormObj);

    const [origShoePackageFormVals, setOrigShoePackageFormVals] = useState(editShoePackageValuesFormObj);
    const [editedShoePackageFormVals, setEditedShoePackageFormVals] = useState(editShoePackageValuesFormObj);
    const [shoePackageDiffObj, setShoePackageDiffObj] = useState({});
    const [shoePackageFormValsChanged, setShoePackageFormValsChanged] = useState(false);
    const [updatedShoePackageRes, setUpdatedShoePackageRes] = useState({});

    const [origSelectedAnimalStatus, setOrigSelectedAnimalStatus] = useState('');
    const [editedSelectedAnimalStatus, setEditedSelectedAnimalStatus] = useState('');
    const [origSelectedAnimalType, setOrigSelectedAnimalType] = useState('');
    const [editedSelectedAnimalType, setEditedSelectedAnimalType] = useState('');
    const [showUpdatingIndicator, setShowUpdatingIndicator] = useState(false);

    const [animalFormValsChanged, setAnimalFormValsChanged] = useState(false);
    const [animalFormValsDiff, setAnimalFormValsDiff] = useState({});
    const [displayConfirmUpdateAnimalDialog, setDisplayConfirmUpdateAnimalDialog] = useState(false);

    const [pauseForFormSubmit, setPauseForFormSubmit] = useState(false);
    const [displayCreateAnimalNoteDialog, setDisplayCreateAnimalNoteDialog] = useState(false);
    const [createAnimalNoteRecRes, setCreateAnimalNoteRecRes] = useState(null);

    const { editAnimalFormValues,
        updateEditAnimalFormValues,
        resetFormValues,
        updateIDBFormValues,
        updateMultipleFormValues } = useEditAnimalFormProvider();

    // useEffect( update local form values from provider
    useEffect(() => {
        if (editAnimalFormValues) {
            if (Object.keys(editAnimalFormValues).length > 0) {
                // setEditedAnimalFormVals(editAnimalFormValues);
                const hasDifferentValues = Object.keys(editAnimalFormValues).some(
                    key => editAnimalFormValues[key] !== editedAnimalFormVals[key]
                );
                if (hasDifferentValues) {
                    setEditedAnimalFormVals(editAnimalFormValues);
                }
            }
        }
    }, [editAnimalFormValues]);

    const handleBlur = (name, value) => {
        if  (editedAnimalFormVals[name] !== value) {
            updateIDBFormValues();
        }
    };

    // useEffect( after update animal rec, update provider form values from local
    useEffect(() => {
        if (updateAnimalRecRes) {
            if (updateAnimalRecRes?.success && updateAnimalRecRes.updatedAnimalRec) {
                updateMultipleFormValues(updateAnimalRecRes.updatedAnimalRec);
            }
        }
    }, [updateAnimalRecRes]);

    useConsoleLog(showLogs,[{label: "EditAnimalForm props", value: props}]);
    useConsoleLog(showLogs,[{label: "EditAnimalForm origAnimalFormVals", value: origAnimalFormVals}]);
    useConsoleLog(showLogs,[{label: "EditAnimalForm editedAnimalFormVals", value: editedAnimalFormVals}]);
    useConsoleLog(showLogs,[{label: "EditAnimalForm updateAnimalRecRes", value: updateAnimalRecRes}]);
    useConsoleLog(showLogs,[{label: "EditAnimalForm animalFormValsChanged", value: animalFormValsChanged}]);
    useConsoleLog(showLogs,[{label: "EditAnimalForm animalFormValsDiff", value: animalFormValsDiff}]);
    // useConsoleLog(showLogs,[{label: "EditAnimalForm origAnimalFormVals", value: origAnimalFormVals}]);
    useConsoleLog(showLogs,[{label: "EditAnimalForm origSelectedAnimalStatus", value: origSelectedAnimalStatus}]);
    useConsoleLog(showLogs,[{label: "EditAnimalForm editedSelectedAnimalStatus", value: editedSelectedAnimalStatus}]);
    useConsoleLog(showLogs,[{label: "EditAnimalForm origSelectedAnimalType", value: origSelectedAnimalType}]);
    useConsoleLog(showLogs,[{label: "EditAnimalForm editedSelectedAnimalType", value: editedSelectedAnimalType}]);
    useConsoleLog(showLogs,[{label: "EditAnimalForm createAnimalNoteRecRes", value: createAnimalNoteRecRes}]);
    useConsoleLog(showLogs,[{label: "EditAnimalForm origShoePackageFormVals", value: origShoePackageFormVals}]);
    useConsoleLog(showLogs,[{label: "EditAnimalForm editedShoePackageFormVals", value: editedShoePackageFormVals}]);
    useConsoleLog(showLogs,[{label: "EditAnimalForm shoePackageDiffObj", value: shoePackageDiffObj}]);
    useConsoleLog(showLogs,[{label: "EditAnimalForm shoePackageFormValsChanged", value: shoePackageFormValsChanged}]);


    // useEffect( setAmpUser(props.ampUser);
    useEffect(() => {
        if (props?.ampUser) {
            setAmpUser(props.ampUser);
        }
    }, [props.ampUser]);

    // useEffect( setShowLogs(props.showLogs);
    useEffect(() => {
        if (props?.showLogs) {
            setShowLogs(props.showLogs);
        }
        else {
            setShowLogs(false);
        }
    }, [props.showLogs]);

    // useEffect( setAnimalTypesEnums(AnimalType);
    useEffect(() => {
        if (AnimalType) {
            setAnimalTypesEnums(AnimalType);
        }
    }, [AnimalType]);

    // useEffect( setAnimalStatusEnums(AnimalStatus);
    useEffect(() => {
        if (AnimalStatus) {
            setAnimalStatusEnums(AnimalStatus);
        }
    }, [AnimalStatus]);

    // useEffect( setClientRec(props.clientRec);
    useEffect(() => {
        if (props?.clientRec) {
            setClientRec(props.clientRec);
        }
    }, [props.clientRec]);

    // useEffect( setAnimalRec(props.animalRec);
    useEffect(() => {
        if (props?.animalRec) {
            setAnimalRec(props.animalRec);
        }
    }, [props.animalRec]);

    // useEffect( setOrigAnimalFormVals(animalObj); setEditedAnimalFormVals(animalObj);
    useEffect(() => {
        if (!pauseForFormSubmit) {
            if (animalRec) {
                const animalObj = {};
                Object.keys(animalRec).forEach(key => {
                    // console.log('key', key);
                    // console.log('animalRec[key]', animalRec[key]);
                    if (key !== 'id') {
                        animalObj[key] = animalRec[key];
                        updateEditAnimalFormValues(key, animalRec[key]);
                    }
                    if (key === 'animalStatus') {
                        setOrigSelectedAnimalStatus(animalRec[key]);
                        setEditedSelectedAnimalStatus(animalRec[key]);
                    }
                    if (key === 'animalType') {
                        setOrigSelectedAnimalType(animalRec[key]);
                        setEditedSelectedAnimalType(animalRec[key]);
                    }
                });
                setOrigAnimalFormVals(animalObj);
                setEditedAnimalFormVals(animalObj);
                setAnimalFormValsChanged(false);
                setAnimalFormValsDiff({});
                updateIDBFormValues();
            }
        }
    }, [animalRec, pauseForFormSubmit]);

    // useEffect( setAnimalNotesRecs(props.animalNotes);
    useEffect(() => {
        if (props?.animalNotes) {
            setAnimalNotesRecs(props.animalNotes);
        }
    }, [props.animalNotes]);

    // useEffect( orig, edited shoe package form vals (props.shoePackageRec);
    useEffect(() => {
        if (props?.shoePackageRec) {
            setOrigShoePackageFormVals({
                frontLeft: props.shoePackageRec.frontLeft,
                frontRight: props.shoePackageRec.frontRight,
                rearLeft: props.shoePackageRec.rearLeft,
                rearRight: props.shoePackageRec.rearRight,
            });
            setEditedShoePackageFormVals({
                frontLeft: props.shoePackageRec.frontLeft,
                frontRight: props.shoePackageRec.frontRight,
                rearLeft: props.shoePackageRec.rearLeft,
                rearRight: props.shoePackageRec.rearRight,
            });
        }
    }, [props.shoePackageRec]);

    const handleAnimalStatusChange = (event) => {
        const {target: {value}} = event;
        setEditedSelectedAnimalStatus(value);
        setEditedAnimalFormVals({
            ...editedAnimalFormVals,
            animalStatus: value
        });
        updateEditAnimalFormValues('animalStatus', value);
        if (origSelectedAnimalStatus !== value) {
            setAnimalFormValsChanged(true);
            const updatedDiff = {...animalFormValsDiff};
            setAnimalFormValsDiff({...updatedDiff, animalStatus: value});
        }
        else {
            const updatedDiff = {...animalFormValsDiff};
            delete updatedDiff['animalStatus'];
            setAnimalFormValsDiff(updatedDiff);
        }
        updateIDBFormValues();
    }

    const handleAnimalTypeChange = (event) => {
        const {target: {value}} = event;
        setEditedSelectedAnimalType(value);
        setEditedAnimalFormVals({
            ...editedAnimalFormVals,
            animalType: value
        });
        updateEditAnimalFormValues('animalType', value);
        if (origSelectedAnimalType !== value) {
            setAnimalFormValsChanged(true);
            const updatedDiff = {...animalFormValsDiff};
            setAnimalFormValsDiff({...updatedDiff, animalType: value});
        }
        else {
            const updatedDiff = {...animalFormValsDiff};
            delete updatedDiff['animalType'];
            setAnimalFormValsDiff(updatedDiff);
        }
        updateIDBFormValues();
    }

    const handleAnimalTextFieldChange = (key, event) => {
        let typedValue = event.target.value;
        // console.log('handleAnimalTextFieldChange key, value, isInteger', key, typedValue, isInteger(typedValue));

        if (typedValue === null) {
            console.error('handleAnimalTextFieldChange event.target.value is null for key', key);
            return;
        }
        const intFields = ['age',
                                'temperament',
                                'addedShoeTaskAmount',
                                'addedTrimTaskAmount',
                                'addedShoeTaskDuration',
                                'addedTrimTaskDuration',
                                'shoeTaskCyclePeriodWeeks',
                                'trimTaskCyclePeriodWeeks'];
        // if (key in intFields) {
        // if (intFields.indexOf(key) > -1) {
        if (intFields.includes(key)) {
            // console.log('handleAnimalTextFieldChange isInteger', isInteger(typedValue));

            // if (isInteger(typedValue)) {
            //     try {
            //         typedValue = parseInt(event.target.value, 10);
            //     } catch (e) {
            //         // TODO - ADD USER FEEDBACK ON TYPE ERROR
            //         // console.error('handleAnimalTextFieldChange', e);
            //         return;
            //     }
            // }

            // const amountInt = convertToFullIntegerHandleNaN(typedValue);
            typedValue = convertToFullIntegerHandleNaN(typedValue);
            if (showLogs) {
                console.log('handleAnimalTextFieldChange - convertToFullIntegerHandleNaN: key, typedValue', key, typedValue);
            }
            // typedValue = amountInt;
            setEditedAnimalFormVals({...editedAnimalFormVals, [key]: typedValue});
            updateEditAnimalFormValues(key, typedValue);
        }
        else {
            setEditedAnimalFormVals({...editedAnimalFormVals, [key]: typedValue});
            updateEditAnimalFormValues(key, typedValue);
        }

        if (origAnimalFormVals[key] !== typedValue) {
            setAnimalFormValsChanged(true);
            setAnimalFormValsDiff({...animalFormValsDiff, [key]: typedValue});
        }
        else {
            // TODO - OPTIONAL UPDATE - CHECK ALL FIELDS AND RESET VALS CHANGED TO FALSE
            console.log('updated animal form val matches orig value, REMOVING FROM DIFF OBJ', key);
            const newDiffObj = {...animalFormValsDiff};
            delete newDiffObj[key];
            setAnimalFormValsDiff(newDiffObj);
        }
        // setEditedAnimalFormVals({...editedAnimalFormVals, [key]: typedValue});
    };

    // useEffect( setAnimalFormValsChanged( based on animalFormValsDiff
    useEffect(() => {
        if (animalFormValsDiff && Object.keys(animalFormValsDiff).length > 0) {
            // console.log('animalFormValsDiff', animalFormValsDiff);
            setAnimalFormValsChanged(true);
        }
        if (animalFormValsDiff && Object.keys(animalFormValsDiff).length === 0) {
            // console.log('animalFormValsDiff', animalFormValsDiff);
            setAnimalFormValsChanged(false);
        }
    }, [animalFormValsDiff]);

    async function onUpdateAnimalRec() {
        setDisplayConfirmUpdateAnimalDialog(true);
    }

    const handleShoePkgTextFieldChange = (key, event) => {
        setEditedShoePackageFormVals({...editedShoePackageFormVals, [key]: event.target.value});
        // check for changes vs orig vals
        if (origShoePackageFormVals[key] !== event.target.value) {
            setShoePackageFormValsChanged(true);
            setShoePackageDiffObj({...shoePackageDiffObj, [key]: event.target.value});
        }
        else {
            const updatedDiff = {...shoePackageDiffObj};
            delete updatedDiff[key];
            setShoePackageDiffObj(updatedDiff);
        }
    };

    // useEffect( setShoePackageFormValsChanged( based on shoePackageDiffObj
    useEffect(() => {
        if (shoePackageDiffObj && Object.keys(shoePackageDiffObj).length > 0) {
            setShoePackageFormValsChanged(true);
        }
        else {
            setShoePackageFormValsChanged(false);
        }
    }, [shoePackageDiffObj]);

    const handleDeleteAnimalNoteConfirm = async (modelId) => {
        await deleteAnimalNoteById(modelId, showLogs);
        if (showLogs) {
            console.log('The Animal Note record has been deleted.', modelId);
        }
        setShowDeleteAnimalNoteModal(false);
    };

    const handleDeleteAnimalNoteModalOpen = () => {
        setShowDeleteAnimalNoteModal(true);
    };

    const handleDeleteAnimalNoteModalClose = () => {
        setShowDeleteAnimalNoteModal(false);
    };

    function displayAnimalNotesAccordion() {
        // TODO -ADD BUTTON TO ADD ANIMAL NOTE
        // TODO - ADD BUTTON TO DELETE ANIMAL NOTE
        if (animalNotesRecs) {
            return (
                <div>
                    <Accordion
                        disabled={animalNotesRecs.length === 0}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Animal Notes ({animalNotesRecs.length})</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                {animalNotesRecs.map((animalNote, index) => {
                                    return (
                                        <StyledListCard
                                            key={`animalNote-${index}`}
                                        >
                                            <Typography>
                                                Note: {animalNote.note}
                                            </Typography>
                                            <BaseStyledDeleteButton
                                                label="Delete Animal Note"
                                                onClick={() => {
                                                    setAnimalNoteToDelete(animalNote);
                                                    handleDeleteAnimalNoteModalOpen();
                                            }}>
                                                Delete Animal Note
                                            </BaseStyledDeleteButton>
                                        </StyledListCard>
                                    )
                                })}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            )
        } else {
            return (
                <StyledListCard>
                    <Typography>
                        No Animal Notes Found
                    </Typography>
                </StyledListCard>
            )}}

    const onCreateAnimalNoteDialogClose = () => {
        setDisplayCreateAnimalNoteDialog(false);
    }

    const onCreateAnimalNoteDone = (createNoteRes) => {
        if (createNoteRes) {
            setCreateAnimalNoteRecRes(createNoteRes);
        }
        setPauseForFormSubmit(false);
        setShowUpdatingIndicator(false);
    }

    const onUpdateAnimalDialogClose = () => {
        setDisplayConfirmUpdateAnimalDialog(false);
        setPauseForFormSubmit(false);
        setShowUpdatingIndicator(false);
    }

    const onUpdateAnimalDone = (updateAnimalRes) => {
        if (updateAnimalRes) {
            setUpdateAnimalRecRes(updateAnimalRes);
        }
        // setPauseForFormSubmit(false);
        // setShowUpdatingIndicator(false);
    }

    const onUpdateShoePackageDone = (updateShoePackageRes) => {
        if (updateShoePackageRes) {
            setUpdatedShoePackageRes(updateShoePackageRes);
        }
    }

    return (
        <div>
            <Divider>
                <Chip label="Animal Info"/>
            </Divider>
            <div>
                <StyledCardContentTypography>
                    Client: {clientRec?.name || 'No Client Name'}
                </StyledCardContentTypography>
            </div>
            <div>
                <StyledTextField
                    id="name"
                    label="Name"
                    name="name"
                    onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                    value={editedAnimalFormVals?.name || ''}
                    onChange={(event) => handleAnimalTextFieldChange('name', event)}
                />
            </div>
            <div>
                <FormControl sx={{m: 1, width: 300}}>
                    <InputLabel id={`edit-animal-animal-status-input-label`}>Animal Status</InputLabel>
                    <Select
                        labelId={`edit-animal-animal-status-label-id`}
                        id={`edit-animal-animal-status-select`}
                        name={"animalStatus"}
                        value={editedSelectedAnimalStatus || ''}
                        onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                        onChange={(e) => handleAnimalStatusChange(e)}
                        MenuProps={MenuProps}
                    >
                        {Object.entries(animalStatusEnums).map(([key, val]) => (
                            <MenuItem
                                key={generateUniqueKey(key)}  // Use the key for generating a unique key
                                value={val}  // Use the key as the value for the MenuItem
                            >
                                {val}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div>
                <FormControl sx={{m: 1, width: 300}}>
                    <InputLabel id={`edit-animal-animal-type-input-label`}>Animal Type</InputLabel>
                    <Select
                        labelId={`edit-animal-animal-type-label-id`}
                        id={`edit-animal-animal-type-select`}
                        name={`animalType`}
                        value={editedSelectedAnimalType || ''}
                        onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                        onChange={(e) => handleAnimalTypeChange(e)}
                        MenuProps={MenuProps}
                    >
                        {Object.entries(animalTypesEnums).map(([key, val]) => (
                            <MenuItem
                                key={generateUniqueKey(key)}  // Use the key for generating a unique key
                                value={val}  // Use the key as the value for the MenuItem
                            >
                                {val}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div>
                <StyledTextField
                    id="age"
                    label="Age (years)"
                    name="age"
                    onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                    type="number"
                    value={editedAnimalFormVals?.age || ''}
                    onChange={(event) => handleAnimalTextFieldChange('age', event)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">years</InputAdornment>,
                    }}
                />
            </div>
            <div>
                <TooltipWrapper tooltipText={animalFormsTooltipTextsObj.temperament}>
                    <StyledTextField
                        id="temperament"
                        label="Temperament"
                        name="temperament"
                        onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                        type="number"
                        value={editedAnimalFormVals?.temperament || ''}
                        onChange={(event) => handleAnimalTextFieldChange('temperament', event)}
                    />
                </TooltipWrapper>
            </div>
            <div>
                <StyledTextField
                    id="stallNumber"
                    label="Stall Number"
                    name="stallNumber"
                    onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                    value={editedAnimalFormVals?.stallNumber || ''}
                    onChange={(event) => handleAnimalTextFieldChange('stallNumber', event)}
                />
            </div>
            <div>
                <StyledTextField
                    id="breed"
                    label="Breed"
                    name="breed"
                    onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                    value={editedAnimalFormVals?.breed || ''}
                    onChange={(event) => handleAnimalTextFieldChange('breed', event)}
                />
            </div>
            <div>
                <StyledTextField
                    id="color"
                    label="Color"
                    name="color"
                    onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                    value={editedAnimalFormVals?.color || ''}
                    onChange={(event) => handleAnimalTextFieldChange('color', event)}
                />
            </div>
            <div>
                <StyledTextField
                    id="discipline"
                    label="Discipline"
                    name="discipline"
                    onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                    value={editedAnimalFormVals?.discipline || ''}
                    onChange={(event) => handleAnimalTextFieldChange('discipline', event)}
                />
            </div>
            <Divider>
                <Chip label="Shoe Package Info"/>
            </Divider>
            <div>
                <TooltipWrapper tooltipText={animalFormsTooltipTextsObj.shoePackageFrontLeft}>
                <StyledTextField
                    id="shoe-package-front-left"
                    label="Shoe Front Left"
                    value={editedShoePackageFormVals?.frontLeft || ''}
                    onChange={(event) => handleShoePkgTextFieldChange('frontLeft', event)}
                />
                </TooltipWrapper>
            </div>
            <div>
                <TooltipWrapper tooltipText={animalFormsTooltipTextsObj.shoePackageFrontRight}>
                <StyledTextField
                    id="shoe-package-front-right"
                    label="Shoe Front Right"
                    value={editedShoePackageFormVals?.frontRight || ''}
                    onChange={(event) => handleShoePkgTextFieldChange('frontRight', event)}
                />
                </TooltipWrapper>
            </div>
            <div>
                <TooltipWrapper tooltipText={animalFormsTooltipTextsObj.shoePackageRearLeft}>
                <StyledTextField
                    id="shoe-package-rear-left"
                    label="Shoe Rear Left"
                    value={editedShoePackageFormVals?.rearLeft || ''}
                    onChange={(event) => handleShoePkgTextFieldChange('rearLeft', event)}
                />
                </TooltipWrapper>
            </div>
            <div>
                <TooltipWrapper tooltipText={animalFormsTooltipTextsObj.shoePackageRearRight}>
                <StyledTextField
                    id="shoe-package-rear-right"
                    label="Shoe Rear Right"
                    value={editedShoePackageFormVals?.rearRight || ''}
                    onChange={(event) => handleShoePkgTextFieldChange('rearRight', event)}
                />
                </TooltipWrapper>
            </div>
            <Divider>
                <Chip label="Shoeings, Trims Info"/>
            </Divider>
            <div>
                <TooltipWrapper tooltipText={animalFormsTooltipTextsObj.addedShoeTaskAmount}>
                    <StyledTextField
                        id="added-shoe-task-amount"
                        label="Added Shoe Task Amt ($)"
                        name="addedShoeTaskAmount"
                        onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                        type="number"
                        value={editedAnimalFormVals?.addedShoeTaskAmount || ''}
                        onChange={(event) => handleAnimalTextFieldChange('addedShoeTaskAmount', event)}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                    />
                </TooltipWrapper>
            </div>
            <div>
                <TooltipWrapper tooltipText={animalFormsTooltipTextsObj.addedShoeTaskDuration}>
                    <StyledTextField
                        id="added-shoe-task-duration"
                        label="Added Shoe Task Duration (mins.)"
                        name="addedShoeTaskDuration"
                        onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                        type="number"
                        value={editedAnimalFormVals?.addedShoeTaskDuration || ''}
                        onChange={(event) => handleAnimalTextFieldChange('addedShoeTaskDuration', event)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">mins.</InputAdornment>,
                        }}
                    />
                </TooltipWrapper>
            </div>
            <div>
                <TooltipWrapper tooltipText={animalFormsTooltipTextsObj.addedTrimTaskAmount}>
                    <StyledTextField
                        id="added-trim-task-amount"
                        label="Added Trim Task Amt ($)"
                        name="addedTrimTaskAmount"
                        onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                        type="number"
                        value={editedAnimalFormVals?.addedTrimTaskAmount || ''}
                        onChange={(event) => handleAnimalTextFieldChange('addedTrimTaskAmount', event)}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                    />
                </TooltipWrapper>
            </div>
            <div>
                <TooltipWrapper tooltipText={animalFormsTooltipTextsObj.addedTrimTaskDuration}>
                    <StyledTextField
                        id="added-trim-task-duration"
                        label="Added Trim Task Duration (mins.)"
                        name="addedTrimTaskDuration"
                        onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                        type="number"
                        value={editedAnimalFormVals?.addedTrimTaskDuration || ''}
                        onChange={(event) => handleAnimalTextFieldChange('addedTrimTaskDuration', event)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">mins.</InputAdornment>,
                        }}
                    />
                </TooltipWrapper>
            </div>
            <div>
                <TooltipWrapper tooltipText={animalFormsTooltipTextsObj?.shoeTaskCyclePeriodWeeks || '-'}>
                    <StyledTextField
                        id="shoe-task-cycle-period-weeks"
                        label="Shoeing Cycle Period (weeks)"
                        value={editedAnimalFormVals?.shoeTaskCyclePeriodWeeks || ''}
                        onChange={(event) => handleAnimalTextFieldChange('shoeTaskCyclePeriodWeeks', event.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">weeks</InputAdornment>,
                        }}
                    />
                </TooltipWrapper>
            </div>
            <div>
                <TooltipWrapper tooltipText={animalFormsTooltipTextsObj?.trimTaskCyclePeriodWeeks || '-'}>
                    <StyledTextField
                        id="trim-task-cycle-period-weeks"
                        label="Trim Cycle Period (weeks)"
                        value={editedAnimalFormVals?.trimTaskCyclePeriodWeeks || ''}
                        onChange={(event) => handleAnimalTextFieldChange('trimTaskCyclePeriodWeeks', event.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">weeks</InputAdornment>,
                        }}
                    />
                </TooltipWrapper>
            </div>
            <div>
                {displayAnimalNotesAccordion()}
            </div>
            <div>
                <BaseStyledButton
                    label={"Add Animal Note"}
                    onClick={() => {
                        setDisplayCreateAnimalNoteDialog(true);
                    }}
                />
            </div>
            <BaseStyledButton
                label="Update Animal"
                onClick={onUpdateAnimalRec}
                disabled={!animalFormValsChanged && !shoePackageFormValsChanged}
            />
            <div>
                {displayConfirmUpdateAnimalDialog ? (
                    <ConfirmUpdateAnimalDialog
                        openProp={displayConfirmUpdateAnimalDialog}
                        ampUser={ampUser}
                        handleCloseProp={onUpdateAnimalDialogClose}
                        animalRec={animalRec}
                        animalFormValsDiff={animalFormValsDiff}
                        shoePackageFormValsDiff={shoePackageDiffObj}
                        editedShoePackageFormVals={editedShoePackageFormVals}
                        handleUpdateAnimalDoneProp={onUpdateAnimalDone}
                        handleUpdateShoePackageDoneProp={onUpdateShoePackageDone}
                        showLogs={showLogs}
                        />
                ) : null}
            </div>
            <div>
                {animalNoteToDelete ? (
                    <DeleteAnimalNoteConfirmationDialog
                        open={showDeleteAnimalNoteModal}
                        handleClose={handleDeleteAnimalNoteModalClose}
                        handleConfirm={handleDeleteAnimalNoteConfirm}
                        modelRecToDelete={animalNoteToDelete}
                        showLogs={showLogs}
                        animalRec={animalRec}
                    />
                ) : null}
            </div>
            <div>
                {displayCreateAnimalNoteDialog ? (
                    <CreateAnimalNoteDialog
                        openProp={displayCreateAnimalNoteDialog}
                        handleCloseProp={onCreateAnimalNoteDialogClose}
                        animalRec={animalRec}
                        ampUser={ampUser}
                        showLogs={showLogs}
                        handleCreateDoneProp={onCreateAnimalNoteDone}
                    />
                ) : null}
            </div>
        </div>
    );
}