import React, {useState, useEffect} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
    Typography,
    Grid,
    Stack
} from '@mui/material';
import { updateAnimalRecByIdWithDiffObj } from "../../../data/update/animal/updateAnimal";
import { updateAnimalDialogFinalStepSet } from "../../buttons/sets/animalButtonSets";
import { createShoePackageRec } from "../../../data/create/animal/createAnimalRecs";
import BaseStyledButton from "../../buttons/BaseStyledButton";

// TODO - START HERE, FR, FR, HANDLE UPDATED SHOE PACKAGE AS WELL, SIMILAR TO CREATE NEW ANIMAL DIALOG FULL

export default function ConfirmUpdateAnimalDialog({
                                                      openProp,
                                                      ampUser,
                                                      handleCloseProp,
                                                      animalRec,
                                                      animalFormValsDiff,
                                                      shoePackageFormValsDiff,
                                                      editedShoePackageFormVals,
                                                      handleUpdateAnimalDoneProp,
                                                      handleUpdateShoePackageDoneProp,
                                                      showLogs,
                                                      ...props
                                                  }) {
    const [open, setOpen] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [pauseOnFormSubmit, setPauseOnFormSubmit] = useState(false);

    const [updatedAnimalFields, setUpdatedAnimalFields] = useState(null);
    const [updatedShoePackageFields, setUpdatedShoePackageFields] = useState(null);
    const [editedShoePackageVals, setEditedShoePackageVals] = useState(null);

    const [isUpdatingAnimal, setIsUpdatingAnimal] = useState(false);
    const [updateAnimalResObj, setUpdateAnimalResObj] = useState(null);
    const [updateAnimalErrorMsg, setUpdateAnimalErrorMsg] = useState(null);
    const [updateAnimalSuccessMsg, setUpdateAnimalSuccessMsg] = useState(null);

    const [isCreatingShoePackage, setIsCreatingShoePackage] = useState(false);
    const [createShoePackageResObj, setCreateShoePackageResObj] = useState(null);
    const [createShoePackageErrorMsg, setCreateShoePackageErrorMsg] = useState(null);
    const [createShoePackageSuccessMsg, setCreateShoePackageSuccessMsg] = useState(null);

    const [finalErrorMsg, setFinalErrorMsg] = useState(null);
    const [finalSuccessMsg, setFinalSuccessMsg] = useState(null);
    const [finalStepButtonsObj, setFinalStepButtonsObj] = useState(null);
    const [finalStepDialogTitleText, setFinalStepDialogTitleText] = useState(null);

    // useEffect( if (openProp) { setOpen(true);
    useEffect(() => {
        if (openProp) {
            setOpen(true);
        }
    }, [openProp]);

    // useEffect( if (animalRec) setFinalStepButtonsObj(updateAnimalDialogFinalStepSet(animalRec));
    useEffect(() => {
        if (animalRec) {
            setFinalStepButtonsObj(updateAnimalDialogFinalStepSet(animalRec));
        }
    }, [animalRec]);

    useEffect(() => {
        if (showLogs) {
            console.log('### ConfirmUpdateAnimalDialog finalStepButtonsObj', finalStepButtonsObj);
        }
    }, [showLogs, finalStepButtonsObj]);

    // useEffect( set updated fields obj, set active step
    useEffect(() => {
        if (animalFormValsDiff && Object.keys(animalFormValsDiff).length > 0) {
            setUpdatedAnimalFields(animalFormValsDiff);
            setActiveStep(1);
        }
    }, [animalFormValsDiff]);

    // useEffect( set updated shoe package fields obj
    useEffect(() => {
        if (shoePackageFormValsDiff && Object.keys(shoePackageFormValsDiff).length > 0) {
            setUpdatedShoePackageFields(shoePackageFormValsDiff);
            if (editedShoePackageFormVals && Object.keys(editedShoePackageFormVals).length > 0) {
                setEditedShoePackageVals(editedShoePackageFormVals);
            }
        }
    }, [shoePackageFormValsDiff, editedShoePackageFormVals]);

    // useEffect( if updatedShoePackageFields and editedShoePackageVals, but no updatedAnimalFields, set active step to 3, else set active step to 1
    useEffect(() => {
        if (updatedAnimalFields && activeStep <= 1) {
            setActiveStep(1);
        }
        else if (updatedShoePackageFields && editedShoePackageVals && activeStep < 2) {
            if (Object.keys(updatedShoePackageFields).length > 0 && Object.keys(editedShoePackageVals).length > 0) {
                setActiveStep(3);
            }
        }
    }, [updatedAnimalFields, updatedShoePackageFields, editedShoePackageVals, activeStep]);

    // useEffect( if (updateAnimalResObj) handleUpdateAnimalDoneProp(updateAnimalResObj);
    useEffect(() => {
        if (updateAnimalResObj) {
            handleUpdateAnimalDoneProp(updateAnimalResObj);
        }
    }, [updateAnimalResObj]);

    // useEffect( if (createShoePackageResObj) handleUpdateShoePackageDoneProp(createShoePackageResObj);
    useEffect(() => {
        if (createShoePackageResObj) {
            handleUpdateShoePackageDoneProp(createShoePackageResObj);
        }
    }, [createShoePackageResObj]);

    function resetState() {
        setUpdatedAnimalFields(null);
        setUpdatedShoePackageFields(null);
        setEditedShoePackageVals(null);
        setIsUpdatingAnimal(false);
        // setUpdateAnimalResObj(null);
        setUpdateAnimalErrorMsg(null);
        setUpdateAnimalSuccessMsg(null);
        setIsCreatingShoePackage(false);
        // setCreateShoePackageResObj(null);
        setCreateShoePackageErrorMsg(null);
        setCreateShoePackageSuccessMsg(null);
        setFinalErrorMsg(null);
        setFinalSuccessMsg(null);
        setFinalStepButtonsObj(null);
        setFinalStepDialogTitleText(null);
    }

    const handleClose = () => {
        if (showLogs) console.log('### UpdateAnimalDialog handleClose');
        if (handleCloseProp) {
            if (!isUpdatingAnimal && !isCreatingShoePackage) {
                resetState();
                handleCloseProp();
            }
            else {
                alert('Please wait for the update to complete before closing the dialog.');
            }
        }
    };

    const handleConfirmUpdateAnimal = async () => {
        if (showLogs) console.log('### ConfirmUpdateAnimalDialog handleConfirmUpdateAnimal');
        setPauseOnFormSubmit(true);
        setIsUpdatingAnimal(true);
        try {
            const updateAnimalRes = await updateAnimalRecByIdWithDiffObj(animalRec, updatedAnimalFields, showLogs);
            if (updateAnimalRes) {
                setUpdateAnimalResObj(updateAnimalRes);
            }
            if (updateAnimalRes && updateAnimalRes?.success) {
                setUpdateAnimalSuccessMsg('Animal Successfully Updated');
                setFinalSuccessMsg('Updates Succeeded');
                setIsUpdatingAnimal(false);
                setActiveStep(2);
            } else {
                setUpdateAnimalErrorMsg(updateAnimalRes?.error || 'Error Updating Animal');
                setFinalErrorMsg('Error Updating Animal');
                setIsUpdatingAnimal(false);
                setActiveStep(5);
            }
        }
        catch (error) {
            if (showLogs) {
                console.error('handleConfirmUpdateAnimal error', error);
            }
            setUpdateAnimalErrorMsg(error);
            setFinalErrorMsg('Error Updating Animal');
            setIsUpdatingAnimal(false);
            setActiveStep(5);
        }
        // setIsUpdatingAnimal(false);
        setPauseOnFormSubmit(false);
    };

    const handleConfirmCreateShoePackage = async () => {
        if (showLogs) console.log('### ConfirmUpdateAnimalDialog handleConfirmCreateShoePackage');
        setPauseOnFormSubmit(true);
        setIsCreatingShoePackage(true);
        try {
            const editedShoePkgValsCopy = {...editedShoePackageVals};
            const allShoePkgFields = {...editedShoePkgValsCopy, ...updatedShoePackageFields};
            if (showLogs) console.log('handleConfirmCreateShoePackage allShoePkgFields', allShoePkgFields);
            // const createShoePackageRes = await createShoePackageRec(updatedShoePackageFields, showLogs);
            const createShoePackageRes = await createShoePackageRec(allShoePkgFields, animalRec.id, ampUser.id, showLogs);
            if (createShoePackageRes) {
                setCreateShoePackageResObj(createShoePackageRes);
            }
            if (createShoePackageRes && createShoePackageRes?.success) {
                setCreateShoePackageSuccessMsg('Shoe Package Successfully Created');
                setFinalSuccessMsg('Updates Succeeded');
                setIsCreatingShoePackage(false);
                setActiveStep(4);
            } else {
                setCreateShoePackageErrorMsg(createShoePackageRes?.error || 'Error Creating Shoe Package');
                setFinalErrorMsg('Error Creating Shoe Package');
                setIsCreatingShoePackage(false);
                setActiveStep(5);
            }
        }
        catch (error) {
            if (showLogs) {
                console.error('handleConfirmCreateShoePackage error', error);
            }
            setCreateShoePackageErrorMsg(error);
            setFinalErrorMsg('Error Creating Shoe Package');
            setIsCreatingShoePackage(false);
            setActiveStep(5);
        }
        // setIsCreatingShoePackage(false);
        setPauseOnFormSubmit(false);
    }

    const handleUpdateAnimalResultNextStep = () => {
        if (showLogs) console.log('### ConfirmUpdateAnimalDialog handleUpdateAnimalResultNextStep');
        try {
            if (updatedShoePackageFields && editedShoePackageVals) {
                if (Object.keys(updatedShoePackageFields).length > 0 && Object.keys(editedShoePackageVals).length > 0) {
                    setActiveStep(3);
                }
                else {
                    setActiveStep(5);
                }
            }
            else {
                setActiveStep(5);
            }
        }
        catch (error) {
            if (showLogs) {
                console.error('handleUpdateAnimalResultNextStep error', error);
            }
            // setFinalErrorMsg(error);
            setActiveStep(5);
        }
    }

    const handleNextStep = () => {
        setActiveStep(activeStep + 1);
    };

    const handleBackStep = () => {
        setActiveStep(activeStep - 1);
    };

    // const handleFinalStep = () => {
    //     if (activeStep === 1) {
    //         handleUpdateAnimal();
    //     } else {
    //         handleUpdateDoneProp

    const renderViewAnimalAttrsDiffStep1 = () => (
        <>
            <DialogTitle>Confirm Animal Update</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please review the changes to the animal record.
                </DialogContentText>
                <div style={{ margin: '20px 0' }}>
                    {Object.entries(updatedAnimalFields).map(([fieldName, fieldValue]) => (
                        <Typography key={fieldName} variant="body1" style={{ marginBottom: '10px' }}>
                            <strong>{fieldName}</strong>: {fieldValue}
                        </Typography>
                    ))}
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    // onClick={handleCancel}
                    onClick={handleClose}
                    disabled={isUpdatingAnimal}
                >
                    Cancel
                </Button>
                <Button onClick={async () => await handleConfirmUpdateAnimal()} variant="contained" color="primary" disabled={isUpdatingAnimal}>
                    {isUpdatingAnimal ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                            <CircularProgress size={24} />
                            <DialogContentText style={{ marginLeft: '20px' }}>
                                Updating animal record...
                            </DialogContentText>
                        </div>
                    ) : 'Confirm Changes'}
                </Button>
                <Button
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                    disabled={isUpdatingAnimal}
                >
                    Close
                </Button>
            </DialogActions>
        </>
    )

    const renderUpdateAnimalResultStep2 = () => (
        <>
            <DialogTitle>Update Animal Result</DialogTitle>
            <DialogContent>
                {updateAnimalResObj && updateAnimalErrorMsg ? (
                    <div>
                        <DialogContentText>
                            Error Updating Animal
                        </DialogContentText>
                        <DialogContentText>
                            {updateAnimalErrorMsg}
                        </DialogContentText>
                    </div>
                ) : null}
                {updateAnimalResObj && updateAnimalResObj?.success && updateAnimalSuccessMsg ? (
                    <div>
                        <DialogContentText>
                            Animal Updated
                        </DialogContentText>
                        <DialogContentText>
                            {updateAnimalSuccessMsg}
                        </DialogContentText>
                    </div>
                ) : null}
            </DialogContent>
            <DialogActions>
                <BaseStyledButton
                    // disabled={isUpdatingAnimal || isCreatingShoePackage || pauseOnFormSubmit}
                    disabled={isUpdatingAnimal || isCreatingShoePackage}
                    label={'Next'}
                    onClick={handleUpdateAnimalResultNextStep}
                />
            </DialogActions>
        </>
    );

    const renderConfirmCreateShoePackageStep3 = () => (
        <>
            <DialogTitle>Confirm Create Shoe Package</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please review the changes to the shoe package record.
                </DialogContentText>
                <div style={{ margin: '20px 0' }}>
                    {Object.entries(updatedShoePackageFields).map(([fieldName, fieldValue]) => (
                        <Typography key={fieldName} variant="body1" style={{ marginBottom: '10px' }}>
                            <strong>{fieldName}</strong>: {fieldValue}
                        </Typography>
                    ))}
                </div>
            </DialogContent>
            <DialogActions>
                <BaseStyledButton
                    label={'Cancel'}
                    onClick={handleClose}
                    disabled={isCreatingShoePackage || isUpdatingAnimal}
                />
                <BaseStyledButton
                    color={'primary'}
                    disabled={isCreatingShoePackage || isUpdatingAnimal}
                    label={isCreatingShoePackage ? <CircularProgress size={24} /> : 'Confirm'}
                    onClick={async () => await handleConfirmCreateShoePackage()}
                    />
            </DialogActions>
        </>
    );

    const renderCreateShoePackageResultStep4 = () => (
        <>
            <DialogTitle>Create Shoe Package Result</DialogTitle>
            <DialogContent>
                {createShoePackageResObj && createShoePackageErrorMsg ? (
                    <div>
                        <DialogContentText>
                            Error Creating Shoe Package
                        </DialogContentText>
                        <DialogContentText>
                            {createShoePackageErrorMsg}
                        </DialogContentText>
                    </div>
                ) : null}
                {createShoePackageResObj && createShoePackageResObj?.success && createShoePackageSuccessMsg ? (
                    <div>
                        <DialogContentText>
                            Shoe Package Created
                        </DialogContentText>
                        <DialogContentText>
                            {createShoePackageSuccessMsg}
                        </DialogContentText>
                    </div>
                ) : null}
            </DialogContent>
            <DialogActions>
                <BaseStyledButton
                    label={'Next'}
                    disabled={isCreatingShoePackage || isUpdatingAnimal}
                    // onClick={handleUpdateAnimalResultNextStep}
                    onClick={() => setActiveStep(5)}
                />
            </DialogActions>
        </>
    );

    // const renderFinalStep5 = () => (
    //     <>
    //         <DialogTitle>{finalStepDialogTitleText}</DialogTitle>
    //         <DialogContent>
    //             {updateAnimalResObj && updateAnimalErrorMsg ? (
    //                 <div>
    //                     <DialogContentText>
    //                         Error Updating Animal
    //                     </DialogContentText>
    //                     <DialogContentText>
    //                         {updateAnimalErrorMsg}
    //                     </DialogContentText>
    //                 </div>
    //             ) : null}
    //             {updateAnimalResObj && updateAnimalResObj.success ? (
    //                 <div>
    //                     <DialogContentText>
    //                         Animal Updated
    //                     </DialogContentText>
    //                 </div>
    //             ) : null}
    //         </DialogContent>
    //         <DialogActions>
    //             <Stack spacing={2} width={"100%"}>
    //                 <Grid container spacing={2}>
    //                     <Grid item xs={12} sm={6} md={4}>
    //                         {finalStepButtonsObj.home}
    //                     </Grid>
    //                     <Grid item xs={12} sm={6} md={4}>
    //                         {finalStepButtonsObj.animals}
    //                     </Grid>
    //                     <Grid item xs={12} sm={6} md={4}>
    //                         {finalStepButtonsObj.animalInfo}
    //                     </Grid>
    //                     <Grid item xs={12} sm={6} md={4}>
    //                         {finalStepButtonsObj.appts}
    //                     </Grid>
    //                     <Grid item xs={12} sm={6} md={4}>
    //                         {finalStepButtonsObj.clients}
    //                     </Grid>
    //                 </Grid>
    //             </Stack>
    //         </DialogActions>
    //     </>
    // );

    const renderFinalStep5 = () => (
        <>
            <DialogTitle>Updates Complete</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {finalSuccessMsg || finalErrorMsg}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Stack spacing={2} width={"100%"}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                            {finalStepButtonsObj.home}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {finalStepButtonsObj.animals}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {finalStepButtonsObj.animalInfo}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {finalStepButtonsObj.appts}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {finalStepButtonsObj.clients}
                        </Grid>
                    </Grid>
                </Stack>
            </DialogActions>
        </>
    );

    const renderLoadingStep = () => (
        <>
            <DialogTitle>Loading...</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Loading Animal Update Data...
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                    disabled={isUpdatingAnimal}
                >
                    Close
                </Button>
                <Button
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                    disabled={isUpdatingAnimal}
                >
                    Cancel
                </Button>
            </DialogActions>
        </>
    );

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="confirm-update-animal-dialog-title"
            aria-describedby="confirm-update-animal-dialog-description"
        >
            {activeStep === 0 ? renderLoadingStep() :
                activeStep === 1 ? renderViewAnimalAttrsDiffStep1() :
                    activeStep === 2 ? renderUpdateAnimalResultStep2() :
                        activeStep === 3 ? renderConfirmCreateShoePackageStep3() :
                            activeStep === 4 ? renderCreateShoePackageResultStep4() :
                                activeStep === 5 ? renderFinalStep5() :
                    renderLoadingStep()}
        </Dialog>
    );
}
