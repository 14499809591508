import React, { useState, useEffect, useContext } from "react";
import DevStateContext from "../../data/contexts/DevStateContext";
import AnimalShoePackagesContext from "../../data/contexts/AnimalShoePackagesContext";
import AnimalNotesContext from "../../data/contexts/AnimalNotesContext";
import EditAnimalForm from "../../components/forms/edit/EditAnimalForm";
import {useLocation} from "react-router-dom";
import {useResponsiveViewport} from "../../data/providers/ResponsiveViewportProvider";
import {breadcrumbRoutesAnimalsScreen} from "../../utils/breadcrumbsRoutes";
import RouterBreadcrumbs from "../../components/router/RouterBreadcrumbs";

export default function EditAnimalScreen(props) {
    const [ampUser, setAmpUser] = useState(null);
    const [showLogs, setShowLogs] = useState(false);
    const [animalRec, setAnimalRec] = useState(null);
    const [clientRec, setClientRec] = useState(null);
    const [shoePackageRecs, setShoePackageRecs] = useState(null);
    const [currentShoePackageRec, setCurrentShoePackageRec] = useState(null);
    const [currentAnimalNoteRecs, setCurrentAnimalNoteRecs] = useState([]);
    const [breadcrumbRoutes, setBreadcrumbRoutes] = useState([]); // [{path: '', name: ''}, ...

    const { isSmallScreen, isLargeScreen } = useResponsiveViewport();

    const { devStateObj } = useContext(DevStateContext);
    const {animalShoePackagesRecs} = useContext(AnimalShoePackagesContext);
    const {animalNotesRecs} = useContext(AnimalNotesContext);

    const location = useLocation();

    // setBreadcrumbRoutes
    useEffect(() => {
        setBreadcrumbRoutes(breadcrumbRoutesAnimalsScreen() || [])
        // console.error('breadcrumbRoutes', breadcrumbRoutes)
    }, [props]);

    // useEffect( setShowLogs(
    useEffect(() => {
        if (devStateObj?.showLogs){
            setShowLogs(devStateObj.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [devStateObj]);

    // useEffect( setAmpUser(props.ampUser);
    useEffect(() => {
        if (props?.ampUser) {
            setAmpUser(props.ampUser);
        }
    }, [props]);

    // useEffect( setAnimalRec(props.animalRec);
    useEffect(() => {
        if (props.animalRec) {
            setAnimalRec(props.animalRec);
        }
    }, [props.animalRec]);

    // useEffect( setClientRec(props.clientRec);
    useEffect(() => {
        if (props.clientRec) {
            setClientRec(props.clientRec);
        }
    }, [props.clientRec]);

    // useEffect( setShoePackageRecs(animalShoePackagesRecs);
    useEffect(() => {
        if (animalShoePackagesRecs) {
            setShoePackageRecs(animalShoePackagesRecs);
            if (animalShoePackagesRecs.length > 0) {
                setCurrentShoePackageRec(animalShoePackagesRecs[0]);
            }
        }
    }, [animalShoePackagesRecs]);

    // useEffect( setCurrentAnimalNoteRecs(animalNotesRecs);
    useEffect(() => {
        if (animalNotesRecs) {
            setCurrentAnimalNoteRecs(animalNotesRecs);
        }
    }, [animalNotesRecs]);

    // useEffect( setAnimalRec(location.state.animalRec);
    // useEffect(() => {
    //     if (location.state?.animalRec) {
    //         setAnimalRec(location.state.animalRec);
    //     }
    // }, [location]);

    // useEffect( setClientRec(location.state.clientRec);
    // useEffect(() => {
    //     if (location.state?.clientRec) {
    //         setClientRec(location.state.clientRec);
    //     }
    // }, [location]);

    // useEffect( setShoePackageRec(location.state.shoePackageRec);
    // useEffect(() => {
    //     if (location.state?.shoePackageRec) {
    //         setShoePackageRec(location.state.shoePackageRec);
    //     }
    // }, [location]);

    // useEffect( console.log( animalRec, clientRec, shoePackageRecs, currentShoePackageRec
    useEffect(() => {
        if (showLogs) {
            console.log('EditAnimalScreen animalRec', animalRec);
            console.log('EditAnimalScreen clientRec', clientRec);
            console.log('EditAnimalScreen shoePackageRecs', shoePackageRecs);
            console.log('EditAnimalScreen currentShoePackageRec', currentShoePackageRec);
        }
    }, [showLogs, animalRec, clientRec, shoePackageRecs, currentShoePackageRec]);

    return (
        <div style={{
            backgroundColor: 'lightgray',
            minHeight: '15vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 'calc(10px + 2vmin)',
            // color: 'white',
            color: 'black',
            marginBottom: '0.5vh'
        }}>
            <h2>Edit Animal</h2>
            <RouterBreadcrumbs breadcrumbsArr={breadcrumbRoutes} />
            <EditAnimalForm
                ampUser={ampUser}
                showLogs={showLogs}
                animalRec={animalRec}
                clientRec={clientRec}
                shoePackageRec={currentShoePackageRec}
                animalNotes={currentAnimalNoteRecs}
            />
        </div>
    );
}