// CreateWaitlistUserForm.js
import React, { useState, useEffect, useContext } from 'react';
import StyledTextField from "../../text/StyledTextField";
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import DevStateContext from "../../../data/contexts/DevStateContext";
import BaseStyledButton from "../../buttons/BaseStyledButton";
import { createWaitlistUserRec } from "../../../data/create/person/waitlistUser";


export default function CreateWaitlistUserForm(props) {
    const [origEmail, setOrigEmail] = useState('');
    const [editedEmail, setEditedEmail] = useState('');
    const [createWaitlistUserRecObj, setCreateWaitlistUserRecObj] = useState(null);
    const [showLogs, setShowLogs] = useState(false);

    // const { devStateObj } = useContext(DevStateContext);

    // useEffect(() => {
    //     if (devStateObj?.showLogs){
    //         setShowLogs(devStateObj.showLogs);
    //     } else {
    //         setShowLogs(false);
    //     }
    // }, [devStateObj]);

    // useEffect( setShowLogs(props.showLogs);
    useEffect(() => {
        if (props?.showLogs){
            setShowLogs(props.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [props.showLogs]);

    // TODO - START HERE, FR, FR, GET ORIG EMAIL TO POPULATE AND USE IT
    async function handleFormSubmit() {
        if (props.setAddingWaitlistUserProp) {
            props.setAddingWaitlistUserProp(true);
        }
        const newWaitlistUserRec = await createWaitlistUserRec({
            email: editedEmail
        }, showLogs);
        if (showLogs) {
            console.log('CreateWaitlistUserForm handleFormSubmit newWaitlistUserRec', newWaitlistUserRec);
        }
        if (newWaitlistUserRec) {
            setCreateWaitlistUserRecObj(newWaitlistUserRec);
            if (newWaitlistUserRec?.success) {
                alert('Your email has been added to the waitlist!');
            }
            if (props.setCreateWaitlistUserRecObjProp) {
                props.setCreateWaitlistUserRecObjProp(newWaitlistUserRec);
            }
        }
        if (props.setAddingWaitlistUserProp) {
            props.setAddingWaitlistUserProp(false);
        }
    }

    // useEffect( setEditedEmail(origEmail);
    useEffect(() => {
        if (origEmail) {
            setEditedEmail(origEmail);
        }
    }, [origEmail]);

    // useEffect( setOrigEmail(props.origEmail);
    useEffect(() => {
        if (props?.origEmail) {
            setOrigEmail(props.origEmail);
        }
    }, [props.origEmail]);

    // useEffect( console.log( origEmail, editedEmail, createWaitlistUserRecObj
    useEffect(() => {
        if (showLogs) {
            console.log('CreateWaitlistUserForm origEmail', origEmail);
            console.log('CreateWaitlistUserForm editedEmail', editedEmail);
            console.log('CreateWaitlistUserForm createWaitlistUserRecObj', createWaitlistUserRecObj);
        }
    }, [showLogs, origEmail, editedEmail, createWaitlistUserRecObj]);

    function handleInputChange(event) {
        const { name, value } = event.target;
        setEditedEmail(value);
    }

    return (
        <div>
            <Divider />
            <StyledTextField
                id="email"
                name="email"
                label="Email"
                value={editedEmail}
                // onChange={props.handleInputChange}
                onChange={handleInputChange}
                fullWidth
                required
            />
            <Divider />
            <BaseStyledButton
                onClick={handleFormSubmit}
                label="Submit"
            />
        </div>
    );
}
