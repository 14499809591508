import React, {useState, useEffect, useContext} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { CircularProgress } from '@mui/material';
import { AmpUser, UserStatus, UserSubStatus } from "../../../models";
// import {fetchAmpUser, updateAmpUserUserStatus} from "../consts";
import {Amplify} from "aws-amplify";
import BaseStyledLinkButton from "../../../components/links/BaseStyledLinkButton";
import VerticalButtonStack from "../../../components/buttons/VerticalButtonStack";
import {DataStore} from "@aws-amplify/datastore";
import aws_exports from '../../../aws-exports'
import {useConsoleError, useConsoleLog, useConsoleWarn} from "../../../components/hooks/logging/console";
import UserSubsContext from "../../../data/contexts/UserSubsContext";
import SubPaymentViewContext from "../../../data/contexts/SubPaymentViewContext";
import AmpUserContext from "../../../data/contexts/AmpUserContext";
import AmpUserSubscriberContext from "../../../data/contexts/AmpUserSubscriberContext";
import { userSubIsExpired, subPaymentViewIsExpired } from "../../../utils/datesTimes";
import UpdatingIndicator from "../../../components/progress/UpdatingIndicator";
import { createTempUserSubOnPaymentSuccess } from "../../../data/create/paymentsSubs/userSub";
import {breadcrumbRoutesUserAttributesScreen} from "../../../utils/breadcrumbsRoutes";
import RouterBreadcrumbs from "../../../components/router/RouterBreadcrumbs";
import { updateAmpUserUserStatus, updateAmpUserSubscriberById, updateAmpUserUserAttrs } from "../../../data/update/person/updateAmpUser";
import {fetchAmpUserSubscriberById, fetchAmpUserSubscriberByAmpUserId, fetchAmpUser} from "../../../data/get/fetchRecords";

// TODO - STEP THROUGH THESE MESSAGES AS LASTSYNCTIMESTAMP INCREMENTS, AS APPROPRIATE
import { newSubProgressMessagesObj } from "../../../utils/text";

// import BaseLinkButton from "../components/links/BaseLinkButton";

Amplify.configure(aws_exports);

// TODO - UPDATE RECS AS NEEDED TO TRACK NUMBER OF PENDING / TEMP SUBS FROM THIS PAGE,
//  PREVENT MALICIOUS REUSE OF TEMP SUB STATUS

// TODO - !!! START HERE, FR, FR - CREATE TEMP USERSUB WITH PENDING STATUS IF CAN'T CONNECT OR FIND NEW USERSUB, SUBPAYMENTVIEW RECS ON SUCCESS

export default function SubscribeSuccessScreen(props) {
    const [ampUser, setAmpUser] = useState(null);
    const [ampUserHasSynced, setAmpUserHasSynced] = useState(false);
    const [ampUserSubscriber, setAmpUserSubscriber] = useState(null);
    const [ampUserSubscriberHasSynced, setAmpUserSubscriberHasSynced] = useState(false);

    const [updatedAmpUser, setUpdatedAmpUser] = useState(null);
    const [updatedAmpUserSubscriber, setUpdatedAmpUserSubscriber] = useState(null);
    const [ampUserIsSubscriber, setAmpUserIsSubscriber] = useState(false);
    const [userSubs, setUserSubs] = useState(null);
    const [currentUserSub, setCurrentUserSub] = useState(null);
    const [subPaymentViews, setSubPaymentViews] = useState(null);
    const [latestSubPaymentView, setLatestSubPaymentView] = useState(null);
    const [showLogs, setShowLogs] = useState(false);
    const [initSyncTimestamp, setInitSyncTimestamp] = useState(Date.now());
    const [lastSyncTimestamp, setLastSyncTimestamp] = useState(Date.now());
    const [lastSyncTimestampUpdatesCount, setLastSyncTimestampUpdatesCount] = useState(0);
    const [lastSyncTimestampDiffMs, setLastSyncTimestampDiffMs] = useState(0);
    const [continueUpdatingSyncTimestamp, setContinueUpdatingSyncTimestamp] = useState(true);
    const [updateRecsWithTempSubVals, setUpdateRecsWithTempSubVals] = useState(false);
    const [isCreatingTempRecs, setIsCreatingTempRecs] = useState(false);

    const [isLoadingAmpUser, setIsLoadingAmpUser] = useState(true);
    const [isLoadingCurrentUserSub, setIsLoadingCurrentUserSub] = useState(true);
    const [isLoadingCurrentSubPaymentView, setIsLoadingCurrentSubPaymentView] = useState(true);
    const [sessionIdParam, setSessionIdParam] = useState(null);
    const [ampUserSubIsExpired, setAmpUserSubIsExpired] = useState(true);
    const [breadcrumbRoutes, setBreadcrumbRoutes] = useState([]); // [{path: '', name: ''}, ...
    const [displayProgressIndicator, setDisplayProgressIndicator] = useState(false);
    const [currentProgressIndicatorText, setCurrentProgressIndicatorText] = useState('');

    const externalReferrer = document.referrer;
    const queryParams = new URLSearchParams(window.location.search);
    const sessionID = queryParams.get('session_id');

    const {userSubRecs, latestUserSubRec } = useContext(UserSubsContext);
    const {subPaymentViewRecs, latestSubPaymentViewRec } = useContext(SubPaymentViewContext);
    const {ampUserSubscriberRec, setAmpUserSubscriberRec } = useContext(AmpUserSubscriberContext);
    const {ampUserRec, setAmpUserRec } = useContext(AmpUserContext);

    const maxMsDiff = 30000; // 30 seconds

    // setBreadcrumbRoutes
    useEffect(() => {
        setBreadcrumbRoutes(breadcrumbRoutesUserAttributesScreen() || [])
        // console.error('breadcrumbRoutes', breadcrumbRoutes)
    }, [props]);

    useEffect(() => {
        setDisplayProgressIndicator(true);
        setCurrentProgressIndicatorText(newSubProgressMessagesObj.base[0]);
    }, []);

    // useEffect(() => {
    //     if (newSubProgressMessagesObj && !currentProgressIndicatorText) {
    //         setCurrentProgressIndicatorText(newSubProgressMessagesObj.base[0]);
    //     }
    // }, [newSubProgressMessagesObj, currentProgressIndicatorText]);

    // TODO - FIRST CHECK USER STATUS ON AMP USER REC,
    //  THEN CHECK SUB END ON AMP USER SUBSCRIBER,
    //  THEN CHECK MOST RECENT USER SUB REC,
    //  THEN CHECK SUB END VALUES ON LATEST SUB PAYMENT VIEW REC

    // useEffect(() => {
    //
    // }, [currentUserSub, latestSubPaymentView, ampUserSubscriber, ampUser]);

    // TODO - FIGURE OUT HOW TO CLEAR DATA STORE AND NOT THROW, may need to add some ampuser model attrs

    // useEffect( DataStore.clear();
    // useEffect(() => {
    //     DataStore.clear();
    // }, []);

    // useEffect( setShowLogs(
    useEffect(() => {
        if (props?.showLogs){
            setShowLogs(props.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [props]);

    // useEffect( setSessionIdParam(sessionID);
    useEffect(() => {
        if (sessionID) {
            setSessionIdParam(sessionID);
        }
    }, [sessionID]);

    // useEffect( setAmpUser(, setAmpUserIsSubscriber(, setIsLoadingAmpUser(
    useEffect(() => {
        if (props?.ampUser) {
            setAmpUser(props.ampUser);
            setAmpUserIsSubscriber(props.ampUser.userStatus === UserStatus.SUBSCRIBER);
            setIsLoadingAmpUser(false);
        }
        else if (ampUserRec) {
            setAmpUser(ampUserRec);
            setAmpUserIsSubscriber(ampUserRec.userStatus === UserStatus.SUBSCRIBER);
            setIsLoadingAmpUser(false);
        }
    }, [props.ampUser, ampUserRec]);

    // useEffect( setAmpUserSubscriber(props.ampUserSubscriber, ampUserSubscriberRec
    useEffect(() => {
        if (props?.ampUserSubscriber) {
            setAmpUserSubscriber(props.ampUserSubscriber);
        }
        else if (ampUserSubscriberRec) {
            setAmpUserSubscriber(ampUserSubscriberRec);
        }
    }, [props.ampUserSubscriber, ampUserSubscriberRec]);

    // useEffect( setUserSubs(userSubRecs);
    useEffect(() => {
        if (userSubRecs) {
            setUserSubs(userSubRecs);
        }
    }, [userSubRecs]);

    // useEffect( setSubPaymentViews(subPaymentViewRecs);
    useEffect(() => {
        if (subPaymentViewRecs) {
            setSubPaymentViews(subPaymentViewRecs);
        }
    }, [subPaymentViewRecs]);

    // useEffect( setCurrentUserSub(props.currentUserSub);
    // useEffect(() => {
    //     if (props?.currentUserSub) {
    //         setCurrentUserSub(props.currentUserSub);
    //     }
    // }, [props]);

    // useEffect( setCurrentUserSub(latestUserSubRec);
    useEffect(() => {
        if (latestUserSubRec) {
            const latestUserSubIsExpired = userSubIsExpired(latestUserSubRec.end);
            if (!latestUserSubIsExpired) {
                setCurrentUserSub(latestUserSubRec);
            }
        }
    }, [latestUserSubRec]);

    // TODO - CHECK LATEST USER SUB FOR USER STATUS, IF SUBSCRIBER, ALL GOOD
    // useEffect( setLatestSubPaymentView(props.latestSubPaymentView);
    // useEffect(() => {
    //     if (props?.latestSubPaymentView) {
    //         setLatestSubPaymentView(props.latestSubPaymentView);
    //     }
    // }, [props]);

    // useEffect( setLatestSubPaymentView(latestSubPaymentViewRec);
    useEffect(() => {
        if (latestSubPaymentViewRec) {
            setLatestSubPaymentView(latestSubPaymentViewRec);
        }
    }, [latestSubPaymentViewRec]);

    // useEffect( setLastSyncTimestamp(currentTimestamp);
    useEffect(() => {
        // Set the state to the current timestamp when the component mounts
        const currentTimestamp = Date.now();
        setLastSyncTimestamp(currentTimestamp);
    }, []); // The empty dependency array ensures this runs only once, when the component mounts

    // useEffect( setAmpUserIsSubscriber(, setAmpUserSubIsExpired(, based on ampUser, currentUserSub, ampUserSubscriber, latestSubPaymentView attrs.
    useEffect(() => {
        if (!ampUserIsSubscriber) {
            let isSubscriber = false;
            if (ampUser) {
                isSubscriber = ampUser.userStatus === UserStatus.SUBSCRIBER || ampUser.userStatus === UserStatus.PENDING;
            }
            else if (!isSubscriber && currentUserSub) {
                isSubscriber = currentUserSub.userStatus === UserStatus.SUBSCRIBER || currentUserSub.userStatus === UserStatus.PENDING;
                if (!isSubscriber) {
                    isSubscriber = currentUserSub.userSubStatus === UserSubStatus.LIVE || currentUserSub.userSubStatus === UserSubStatus.PENDING;
                }
            }
            else if (!isSubscriber && ampUserSubscriber) {
                isSubscriber = ampUserSubscriber.subIsActive;
                if (!isSubscriber) {
                    isSubscriber = userSubIsExpired(ampUserSubscriber.currentSubEndTimestamp);
                }
            }
            else if (!isSubscriber && latestSubPaymentView) {
                // TODO - VERIFY RETURN VAL IS ACCURATE FOR USE HERE
                isSubscriber = !subPaymentViewIsExpired(latestSubPaymentView.createdAtDateTime, latestSubPaymentView.subPaymentStatus);
            }
            setAmpUserIsSubscriber(isSubscriber);
            setAmpUserSubIsExpired(!isSubscriber);
        }
    }, [ampUserIsSubscriber, ampUser, currentUserSub, ampUserSubscriber, latestSubPaymentView]);

    // useEffect( setDisplayProgressIndicator(, based on ampUserIsSubscriber
    useEffect(() => {
        if (displayProgressIndicator) {
            if (ampUserIsSubscriber) {
                setDisplayProgressIndicator(false);
                setContinueUpdatingSyncTimestamp(false);
            }
        }
    }, [displayProgressIndicator, ampUserIsSubscriber]);

    useConsoleLog(showLogs, [{label: 'SubscribeSuccessScreen sessionID', value: sessionID}]);

    useConsoleLog(showLogs, [{label: 'SubscribeSuccessScreen document.referrer', value: document.referrer}]);

    useConsoleLog(showLogs, [{label: 'SubscribeSuccessScreen externalReferrer', value: externalReferrer}]);

    useConsoleLog(showLogs, [{label: 'SubscribeSuccessScreen ampUser', value: ampUser}]);

    useConsoleLog(showLogs, [{label: 'SubscribeSuccessScreen ampUserSubscriber', value: ampUserSubscriber}]);

    useConsoleLog(showLogs, [{label: 'SubscribeSuccessScreen lastSyncTimestamp', value: lastSyncTimestamp}]);

    useConsoleLog(showLogs, [{label: 'SubscribeSuccessScreen ampUserIsSubscriber', value: ampUserIsSubscriber}]);

    useConsoleLog(showLogs, [{label: 'SubscribeSuccessScreen ampUserSubIsExpired', value: ampUserSubIsExpired}]);

    useConsoleLog(showLogs, [{label: 'SubscribeSuccessScreen userSubs', value: userSubs}]);

    useConsoleLog(showLogs, [{label: 'SubscribeSuccessScreen currentUserSub', value: currentUserSub}]);

    useConsoleLog(showLogs, [{label: 'SubscribeSuccessScreen subPaymentViews', value: subPaymentViews}]);

    useConsoleLog(showLogs, [{label: 'SubscribeSuccessScreen latestSubPaymentView', value: latestSubPaymentView}]);

    // useEffect( setLastSyncTimestamp((prevTimestamp) setLastSyncTimestampUpdatesCount(currentLastSyncTimestampsUpdateCount + 1);
    useEffect(() => {
        // Only start the interval if currentUserSub is null
        let interval;
        if (continueUpdatingSyncTimestamp) {
            if (currentUserSub === null) {
                interval = setInterval(() => {
                    setLastSyncTimestamp((prevTimestamp) => {
                        // If prevTimestamp is null, initialize it with the current time
                        const baseTimestamp = prevTimestamp ?? Date.now();
                        return baseTimestamp + 60000; // Adds 7 seconds
                    });
                    const currentLastSyncTimestampsUpdateCount = lastSyncTimestampUpdatesCount;
                    setLastSyncTimestampUpdatesCount(currentLastSyncTimestampsUpdateCount + 1);
                }, 7000); // Update every 7 seconds
            }
        }

        return () => {
            // Clear the interval when the component unmounts or when currentUserSub changes
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [currentUserSub, continueUpdatingSyncTimestamp]); // Dependency array includes currentUserSub

    // useEffect(() => {
    //     async function fetchUpdatedRecs() {
    //         const resObj = {};
    //         if (ampUser) {
    //             const fetchedAmpUser = await fetchAmpUser(ampUser.id);
    //             if (fetchedAmpUser) {
    //                 setUpdatedAmpUser(fetchedAmpUser);
    //             }
    //             resObj.fetchedAmpUser = fetchedAmpUser;
    //         }
    //         if (ampUserSubscriber) {
    //             const fetchedAmpUserSubscriber = await fetchAmpUserSubscriberById(ampUserSubscriber.id, showLogs);
    //             if (fetchedAmpUserSubscriber) {
    //                 setUpdatedAmpUserSubscriber(fetchedAmpUserSubscriber);
    //             }
    //             resObj.fetchedAmpUserSubscriber = fetchedAmpUserSubscriber;
    //         }
    //         return resObj;
    //     }
    //     // TODO - START HERE, DELETE CURRENT LIVE PAYMENT RECS FOR THIS USER, TRIGGER PROD WEBHOOK AGAIN, THEN NAVIGATE DIRECTLY TO THIS PAGE AND VERIFY THIS TRIGGERS REC SYNC AS NEEDED,THEN POSSIBLY SKIP TEMP RECS IF POSSIBLE
    //     if (lastSyncTimestamp && !ampUserIsSubscriber) {
    //         fetchUpdatedRecs().then((res) => {
    //             if (res) {
    //                 // setAmpUserHasSynced(true);
    //                 // setAmpUserSubscriberHasSynced(true);
    //                 if (res?.fetchedAmpUser) {
    //                     setUpdatedAmpUser(res.fetchedAmpUser);
    //                 }
    //                 if (res?.fetchedAmpUserSubscriber) {
    //                     setUpdatedAmpUserSubscriber(res.fetchedAmpUserSubscriber);
    //                 }
    //             }
    //         }).catch((error) => {
    //             console.error('Error fetching updated recs:', error);
    //         });
    //     }
    // // }, [lastSyncTimestampUpdatesCount, ampUserIsSubscriber]);
    // }, [lastSyncTimestamp, ampUserIsSubscriber]);

    useConsoleLog(showLogs, [{label: 'SubscribeSuccessScreen updatedAmpUser', value: updatedAmpUser}]);
    useConsoleLog(showLogs, [{label: 'SubscribeSuccessScreen updatedAmpUserSubscriber', value: updatedAmpUserSubscriber}]);

    // useEffect(() => {
    //     async function updateAmpUserSyncTSAttr() {
    //         const currentTimestamp = Date.now();
    //         const attrsDiffObj = {
    //             lastSubSuccessPageSyncTimestamp: currentTimestamp,
    //         }
    //         const syncedAmpUserRecRes = await updateAmpUserUserAttrs(ampUserRec, attrsDiffObj, showLogs);
    //         if (showLogs) {
    //             console.log('syncedAmpUserRecRes', syncedAmpUserRecRes);
    //         }
    //         return syncedAmpUserRecRes;
    //     }
    //
    //     if (ampUser && lastSyncTimestamp) {
    //         updateAmpUserSyncTSAttr().then((res) => {
    //             if (res) {
    //                 // setAmpUserHasSynced(true);
    //                 if (res?.success) {
    //                     if (res?.updatedUser) {
    //                         setUpdatedAmpUser(res.updatedUser);
    //                     }
    //                 }
    //             }
    //         }).catch((error) => {
    //             console.error('Error updating ampUser sync timestamp:', error);
    //         });
    //     }
    // }, [lastSyncTimestamp]);
    //
    // useEffect(() => {
    //     async function updateAmpUserSubscriberSyncTSAttr() {
    //         const currentTimestamp = Date.now();
    //         const attrsDiffObj = {
    //             lastSubSuccessPageSyncTimestamp: currentTimestamp,
    //         }
    //         const syncedAmpUserSubscriberRecRes = await updateAmpUserSubscriberById(ampUserSubscriber.id, attrsDiffObj, showLogs);
    //         if (showLogs) {
    //             console.log('syncedAmpUserSubscriberRecRes', syncedAmpUserSubscriberRecRes);
    //         }
    //         return syncedAmpUserSubscriberRecRes;
    //     }
    //
    //     if (ampUserSubscriber && lastSyncTimestamp) {
    //         updateAmpUserSubscriberSyncTSAttr().then((res) => {
    //             if (res) {
    //                 // setAmpUserHasSynced(true);
    //                 if (res?.success) {
    //                     if (res?.updatedRec) {
    //                         setUpdatedAmpUserSubscriber(res.updatedRec);
    //                     }
    //                 }
    //             }
    //         }).catch((error) => {
    //             console.error('Error updating ampUserSubscriber sync timestamp:', error);
    //         });
    //     }
    // }, [lastSyncTimestamp]);

    useEffect(() => {
        async function updateSyncTSAttrs() {
            const resObj = {};
            const currentTimestamp = Date.now();
            const attrsDiffObj = {
                lastSubSuccessPageSyncTimestamp: currentTimestamp,
            }
            const syncedAmpUserRecRes = await updateAmpUserUserAttrs(ampUserRec, attrsDiffObj, showLogs);
            if (showLogs) {
                console.log('syncedAmpUserRecRes', syncedAmpUserRecRes);
            }
            // return syncedAmpUserRecRes;
            resObj.syncedAmpUserRecRes = syncedAmpUserRecRes;

            const syncedAmpUserSubscriberRecRes = await updateAmpUserSubscriberById(ampUserSubscriber.id, attrsDiffObj, showLogs);
            if (showLogs) {
                console.log('syncedAmpUserSubscriberRecRes', syncedAmpUserSubscriberRecRes);
            }
            resObj.syncedAmpUserSubscriberRecRes = syncedAmpUserSubscriberRecRes;
            return resObj;
        }

        if (ampUser && ampUserSubscriber && lastSyncTimestamp) {
            updateSyncTSAttrs().then((res) => {
                console.warn('updateSyncTSAttrs res', res);
                if (res) {
                    // setAmpUserHasSynced(true);
                    if (res?.syncedAmpUserRecRes?.success) {
                        if (res?.syncedAmpUserRecRes?.updatedUser) {
                            setUpdatedAmpUser(res.syncedAmpUserRecRes.updatedUser);
                        }
                    }
                    if (res?.syncedAmpUserSubscriberRecRes?.success) {
                        if (res?.syncedAmpUserSubscriberRecRes?.updatedRec) {
                            setUpdatedAmpUserSubscriber(res.syncedAmpUserSubscriberRecRes.updatedRec);
                        }
                    }
                }
            }).catch((error) => {
                console.error('Error updating ampUser sync timestamp:', error);
            });
        }
    }, [lastSyncTimestamp]);

    // useEffect(() => {
    //     async function updateAmpUserSubscriberSyncTSAttr() {
    //         const currentTimestamp = Date.now();
    //         const attrsDiffObj = {
    //             lastSubSuccessPageSyncTimestamp: currentTimestamp,
    //         }
    //         const syncedAmpUserSubscriberRecRes = await updateAmpUserSubscriberById(ampUserSubscriber.id, attrsDiffObj, showLogs);
    //         if (showLogs) {
    //             console.log('syncedAmpUserSubscriberRecRes', syncedAmpUserSubscriberRecRes);
    //         }
    //         return syncedAmpUserSubscriberRecRes;
    //     }
    //
    //     if (ampUserSubscriber && lastSyncTimestamp) {
    //         updateAmpUserSubscriberSyncTSAttr().then((res) => {
    //             if (res) {
    //                 // setAmpUserHasSynced(true);
    //                 if (res?.success) {
    //                     if (res?.updatedRec) {
    //                         setUpdatedAmpUserSubscriber(res.updatedRec);
    //                     }
    //                 }
    //             }
    //         }).catch((error) => {
    //             console.error('Error updating ampUserSubscriber sync timestamp:', error);
    //         });
    //     }
    // }, [lastSyncTimestamp]);


    // TODO - UPDATE PROGRESS MODAL TEXT BASED ON INCREMENTING COUNTS VAL, IF HIT MAX MS DIFF, CREATE TEMP REC UPDATES
    // useEffect( setLastSyncTimestampDDiffMs(lastSyncTimestampDiff);
    useEffect(() => {
        if (lastSyncTimestampUpdatesCount > 0) {
            const currentTimestamp = Date.now();
            const lastSyncTimestampDiff = currentTimestamp - lastSyncTimestamp;
            setLastSyncTimestampDiffMs(lastSyncTimestampDiff);
        }
    }, [lastSyncTimestamp, lastSyncTimestampUpdatesCount]);

    // useEffect( setContinueUpdatingSyncTimestamp(, setUpdateRecsWithTempSubVals(, if lastSyncTimestampDiffMs > maxMsDiff
    useEffect(() => {
        if (lastSyncTimestampDiffMs > maxMsDiff) {
            setContinueUpdatingSyncTimestamp(false);
            setUpdateRecsWithTempSubVals(true);
            setIsCreatingTempRecs(true);
        }
    }, [lastSyncTimestampDiffMs]);

    // useEffect( createTempUserSubOnPaymentSuccess(
    useEffect(() => {
        if (!updateRecsWithTempSubVals || isCreatingTempRecs) {
            return; // Exit early if the condition is not met
        }
        // TODO - BREAK UP ABOVE CONDITIONAL AND TOGGLE THIS BACK IN?
        // if (isCreatingTempRecs) {
        //     return; // Exit early if the condition is not met
        // }
        // setIsCreatingTempRecs(true);

        const createTempUpdates = async () => {
            try {
                const updateAmpUserTempSubRes = await updateAmpUserUserStatus(ampUser, UserStatus.PENDING, showLogs);
                if (showLogs) {
                    console.log('updateAmpUserTempSubRes', updateAmpUserTempSubRes);
                }
                if (updateAmpUserTempSubRes?.success) {
                    const createTempUserSubRes = await createTempUserSubOnPaymentSuccess(ampUser.id, ampUserSubscriber.id, showLogs);
                    if (showLogs) {
                        console.log('createTempUserSubRes', createTempUserSubRes);
                    }
                    if (createTempUserSubRes?.success) {
                        const updateAUSAttrs = {
                            subIsActive: true,
                            ampUserLastPaymentHash: sessionID,
                            lastUserSubId: createTempUserSubRes.newRec.id,
                            currentSubEndTimestamp: createTempUserSubRes.newRec.end,
                            currentSubEndDateTime: new Date(createTempUserSubRes.newRec.end).toISOString(),
                        }
                        const updateAmpUserSubscriberTempSubRes = await updateAmpUserSubscriberById(ampUserSubscriber.id, updateAUSAttrs, showLogs);
                        if (showLogs) {
                            console.log('updateAmpUserSubscriberTempSubRes', updateAmpUserSubscriberTempSubRes);
                        }
                        if (updateAmpUserSubscriberTempSubRes?.success) {
                            // TODO - UPDATE PROGRESS DIALOG WITH SUCCESS TEXT
                            setContinueUpdatingSyncTimestamp(false);
                            setUpdateRecsWithTempSubVals(false);
                            // TODO - SET THIS HERE OR LET THE NEW RECS HANDLE IT VIA USEEFFECTS?
                            setAmpUserIsSubscriber(true);
                        }
                        // TODO - UPDATE PROGRESS DIALOG WITH SUCCESS TEXT
                        setContinueUpdatingSyncTimestamp(false);
                        setUpdateRecsWithTempSubVals(false);
                    }
                }
                // TODO - UPDATE PROGRESS DIALOG WITH SUCCESS TEXT
                setContinueUpdatingSyncTimestamp(false);
                setUpdateRecsWithTempSubVals(false);
                setIsCreatingTempRecs(false);
                // Handle the response here if needed
            } catch (error) {
                // TODO - SHOW ERROR TEXT IN PROGRESS DIALOG, TRIGGER ERROR NOTI TO SUPPORT EMAIL
                // Handle any errors here
                console.error("Error creating temp user sub:", error);
                setContinueUpdatingSyncTimestamp(false);
                setUpdateRecsWithTempSubVals(false);
                setIsCreatingTempRecs(false);
                // TODO - HANDLE ERROR AND REDUCE USER PAIN, NOTIFY ADMIN AND OFFER ?
            }
        };

        // TODO - SET A TIMEOUT, THEN CLOSE THE PROGRESS DIALOG
        createTempUpdates();
    }, [updateRecsWithTempSubVals]);

    // TODO - UPDATE TO USE BOX, OTHER STYLED COMPONENTS
    return (
        <div className={'Post-success-body'}>
            <RouterBreadcrumbs breadcrumbsArr={breadcrumbRoutes}/>

            <Card
                sx={{
                    maxWidth: 480,
                    minWidth: 320,
                    marginBottom: '1rem'
            }}
            >
                <CardContent>
                    <Typography
                    sx={{
                        fontSize: ['5vw', '4vw', '3vw', '2vw']
                    }}
                    >
                        Subscription Success!
                    </Typography>
                </CardContent>
                <Divider />
            </Card>
            {/*{isLoadingAmpUser ? (*/}
            {/*    <div>*/}
            {/*        <Typography>Loading...</Typography>*/}
            {/*    <CircularProgress />*/}
            {/*    </div>*/}
            {!ampUserIsSubscriber ? (
                    <div
                        style={{
                            marginBottom: '1rem'
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: ['5vw', '4vw', '3vw', '2vw'],
                                color: 'black'
                            }}
                        >
                            Updating your user profile...
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: ['4vw', '3vw', '2vw', '1.5vw'],
                                color: 'black'
                            }}
                        >
                            (this may take a few seconds)
                        </Typography>
                        {/*<CircularProgress />*/}
                    </div>
                ) : null}
            {!latestSubPaymentView || !currentUserSub ? (
                    <div
                        style={{
                            marginBottom: '1rem'
                        }}
                    >
                        <Divider
                            color={'black'}
                        />
                        <Typography
                            sx={{
                                fontSize: ['5vw', '4vw', '3vw', '2vw'],
                                color: 'black'
                            }}
                        >
                            Creating your new subscription...
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: ['4vw', '3vw', '2vw', '1.5vw'],
                                color: 'black'
                            }}
                        >
                            (this may take a few minutes)
                        </Typography>
                        {/*<CircularProgress />*/}
                    </div>
                ) : null}
            {/*{ampUserIsSubscriber && latestSubPaymentView && currentUserSub ? (*/}
            {ampUserIsSubscriber ? (
                <VerticalButtonStack>
                    {/*<BaseStyledLinkButton*/}
                    {/*    buttonText="Create Post"*/}
                    {/*    linkTarget="/create"*/}
                    {/*/>*/}
                    <BaseStyledLinkButton
                        buttonText="Home"
                        linkTarget="/"
                    />
                    {/*<BaseStyledLinkButton*/}
                    {/*    buttonText="Fisheries"*/}
                    {/*    linkTarget="/fisheries"*/}
                    {/*/>*/}
                    {/*<BaseStyledLinkButton*/}
                    {/*    buttonText="Regions"*/}
                    {/*    linkTarget="/regions"*/}
                    {/*/>*/}
                    {/*<BaseStyledLinkButton*/}
                    {/*    buttonText="Gear Types"*/}
                    {/*    linkTarget="/gear-types"*/}
                    {/*/>*/}
                </VerticalButtonStack>
            ) : <CircularProgress />}
            {displayProgressIndicator ? (
                <UpdatingIndicator
                    message={currentProgressIndicatorText}
                />
            ) : null}
        </div>
    );
}
