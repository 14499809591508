import {DataStore} from "aws-amplify";
import {Appointment, AppointmentNote, ShoeTask, TrimTask} from "../../../models";
import {getNowAsAWSDateTime} from "../../../utils/datesTimes";


// export async function updateAppointment(apptId, updateApptFormValues, showLogs) {
export async function updateAppointment(apptId, apptFormValsDiff, showLogs) {
    console.log('updateAppointment(apptId, updateApptFormValues)', apptId);
    console.log('updateAppointment(apptId, apptFormValsDiff)', apptFormValsDiff);
    try {
        const original = await DataStore.query(Appointment, apptId);
        // Check if the appointment exists
        if (!original) {
            console.error("Appointment not found");
            // return;
            return {success: false, error: "Appointment not found"};
        }
        // TODO - ADD EDITED AT VALS

        const updatedAppt = await DataStore.save(
            Appointment.copyOf(original, updated => {
                Object.keys(apptFormValsDiff).forEach(key => {
                    // Update the corresponding field in the Appointment model
                    updated[key] = apptFormValsDiff[key];
                });
            })
        );
        // create AppointmentHorse
        // create AppointmentNote
        return {updatedAppt, success: true};
    } catch (error) {
        console.error('updateAppointment error', error);
        return {success: false, error: 'Error updating the appointment'};
    }
}

// export async function updateAppointmentNote(apptNoteId, note, showLogs) {
//     if (showLogs) {
//         console.log('updateAppointmentNote', apptNoteId, note);
//     }
//     try {
//         const original = await DataStore.query(AppointmentNote, apptNoteId);
//         // Check if the appointment note exists
//         if (!original) {
//             console.error("AppointmentNote not found");
//             return {success: false, error: "Appointment Note not found"};
//         }
//
//         const updatedApptNote = await DataStore.save(
//             AppointmentNote.copyOf(original, updated => {
//                 updated.note = note;
//                 updated.isEdited = true;
//                 updated.lastEditedAt = getNowAsAWSDateTime();
//             })
//         );
//         return {updatedApptNote, success: true};
//     } catch (error) {
//         console.error('updateAppointmentNote error', error);
//         return {success: false, error};
//     }
// }

export async function updateAppointmentShoeTask(apptShoeTaskId, animalShoeTaskFormVal, showLogs) {
    if (showLogs) {
        console.log('updateAppointmentShoeTask', apptShoeTaskId, animalShoeTaskFormVal);
    }
    // TODO - ADD EDITED AT VALS
    const original = await DataStore.query(ShoeTask, apptShoeTaskId);
    // Check if the shoe task exists
    if (!original) {
        console.error("ShoeTask not found");
        return;
    }

    const updatedShoeTask = await DataStore.save(
        ShoeTask.copyOf(original, updated => {
            updated.duration = animalShoeTaskFormVal.duration;
            updated.amount = animalShoeTaskFormVal.amount;
            updated.addedTime = animalShoeTaskFormVal.addedTime;
            updated.addedCost = animalShoeTaskFormVal.addedCost;
            // updated.taskNote = animalShoeTaskFormVal.taskNote;
        })
    );
    return updatedShoeTask;
}

export async function updateAppointmentTrimTask(apptTrimTaskId, animalTrimTaskFormVal, showLogs) {
    if (showLogs) {
        console.log('updateAppointmentTrimTask', apptTrimTaskId, animalTrimTaskFormVal);
    }
    const original = await DataStore.query(TrimTask, apptTrimTaskId);
    // Check if the trim task exists
    if (!original) {
        console.error("TrimTask not found apptTrimTaskId", apptTrimTaskId);
        console.error("TrimTask not found animalTrimTaskFormVal", animalTrimTaskFormVal);
        return;
    }
    // TODO - ADD EDITED AT VALS

    const updatedTrimTask = await DataStore.save(
        TrimTask.copyOf(original, updated => {
            updated.duration = animalTrimTaskFormVal.duration;
            updated.amount = animalTrimTaskFormVal.amount;
            updated.addedTime = animalTrimTaskFormVal.addedTime;
            updated.addedCost = animalTrimTaskFormVal.addedCost;
            // updated.taskNote = animalTrimTaskFormVal.taskNote;
        })
    );
    console.warn('updatedTrimTask', updatedTrimTask);
    return updatedTrimTask;
}

export async function updateApptTasks(apptId, tasksForAnimals, showLogs) {
    // if (showLogs) {
    //     console.log('updateApptTasks', apptId, tasksForAnimals);
    // }
    const updatedShoeTaskRecs = [];
    const updatedTrimTaskRecs = [];
    for (const task of tasksForAnimals) {
        if (task.taskType === 'shoe') {
            const updatedShoeTaskRec = await updateAppointmentShoeTask(task.id, task, showLogs);
            updatedShoeTaskRecs.push(updatedShoeTaskRec);
        } else if (task.taskType === 'trim') {
            const updatedTrimTaskRec = await updateAppointmentTrimTask(task.id, task, showLogs);
            updatedTrimTaskRecs.push(updatedTrimTaskRec);
        }
    }
    return {updatedShoeTaskRecs, updatedTrimTaskRecs};
}
