import React, {useState, useEffect, useContext} from 'react';
import AmpUserClientNotesContext from "../../data/contexts/AmpUserClientNotesContext";
import AmpUserClientCard from "../../components/cards/AmpUserClientCard";
import StyledListCard from "../../components/cards/StyledListCard";
import Typography from "@mui/material/Typography";
import DevStateContext from "../../data/contexts/DevStateContext";
import BaseNavigateButton from "../../components/links/BaseNavigateButton";
import {useNavigate} from "react-router-dom";
import {useResponsiveViewport} from "../../data/providers/ResponsiveViewportProvider";
import {breadcrumbRoutesClientsScreen} from "../../utils/breadcrumbsRoutes";
import Box from "@mui/material/Box";
import RouterBreadcrumbs from "../../components/router/RouterBreadcrumbs";
import StyledListBox from "../../components/boxes/StyledListBox";
import StyledCardContentTypography from "../../components/text/StyledCardContentTypography";
import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import DeleteClientNoteModal from "../../components/modals/delete/DeleteClientNoteModal";
import BaseStyledDeleteButton from "../../components/buttons/BaseStyledDeleteButton";
import ClientApptsContext from "../../data/contexts/ClientApptsContext";
import ClientAnimalsContext from "../../data/contexts/ClientAnimalsContext";
import DeleteAnimalConfirmationDialog from "../../components/modals/delete/DeleteAnimalModal";
import DeleteApptModal from "../../components/modals/delete/DeleteApptModal";
import { handleDeleteApptConfirm,
    handleDeleteApptModalClose,
    handleDeleteApptCancel,
    handleDeleteClientNoteConfirm,
    handleDeleteClientNoteModalClose,
    handleDeleteClientNoteCancel,
    handleDeleteAnimalConfirm,
    handleDeleteAnimalModalClose,
    handleDeleteAnimalCancel } from "../../components/modals/helpers";


export default function ClientInfoScreen(props) {
    const [clientRec, setClientRec] = useState(null);
    const [clientNotesRecs, setClientNotesRecs] = useState([]);
    const [showLogs, setShowLogs] = useState(false);
    const [breadcrumbRoutes, setBreadcrumbRoutes] = useState([]); // [{path: '', name: ''}, ...
    const [clientApptRecs, setClientApptRecs] = useState([]);
    const [clientAnimalRecs, setClientAnimalRecs] = useState([]);

    const [displayDeleteClientNoteModal, setDisplayDeleteClientNoteModal] = useState(false);
    const [clientNoteToDelete, setClientNoteToDelete] = useState(null);
    const [deleteClientNoteRes, setDeleteClientNoteRes] = useState(null)

    const [displayDeleteClientAnimalModal, setDisplayDeleteClientAnimalModal] = useState(false);
    const [clientAnimalToDelete, setClientAnimalToDelete] = useState(null);
    const [deleteClientAnimalRes, setDeleteClientAnimalRes] = useState(null)

    const [displayDeleteClientApptModal, setDisplayDeleteClientApptModal] = useState(false);
    const [clientApptToDelete, setClientApptToDelete] = useState(null);
    const [deleteClientApptRes, setDeleteClientApptRes] = useState(null)

    const { isSmallScreen, isLargeScreen } = useResponsiveViewport();

    const {clientApptsRecs} = useContext(ClientApptsContext);
    const {ampUserClientAnimalsRecs} = useContext(ClientAnimalsContext);
    const {ampUserClientNotesRecs} = useContext(AmpUserClientNotesContext);
    const {devStateObj} = useContext(DevStateContext);

    const navigate = useNavigate();

    // setBreadcrumbRoutes
    useEffect(() => {
        setBreadcrumbRoutes(breadcrumbRoutesClientsScreen() || [])
        // console.error('breadcrumbRoutes', breadcrumbRoutes)
    }, [props]);

    // useEffect( setShowLogs(devStateObj.showLogs);
    useEffect(() => {
        if (devStateObj?.showLogs) {
            setShowLogs(devStateObj.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [devStateObj]);

    // useEffect( setClientRec(props.clientRec);
    useEffect(() => {
            if (props.clientRec) {
                setClientRec(props.clientRec);
            }
        }, [props.clientRec]);

    // useEffect( setClientApptRecs(clientApptsRecs);
    useEffect(() => {
        if (clientApptsRecs) {
            setClientApptRecs(clientApptsRecs);
        }
    }, [clientApptsRecs]);

    // useEffect( setClientAnimalRecs(ampUserClientAnimalsRecs);
    useEffect(() => {
        if (ampUserClientAnimalsRecs) {
            setClientAnimalRecs(ampUserClientAnimalsRecs);
        }
    }, [ampUserClientAnimalsRecs]);

    // useEffect( setClientNotesRecs(ampUserClientNotesRecs);
    useEffect(() => {
        if (ampUserClientNotesRecs) {
            setClientNotesRecs(ampUserClientNotesRecs);
        }
    }, [ampUserClientNotesRecs]);

    // TODO - !!! START HERE, FR FR - ADD BUTTONS TO DELETE CLIENT,
    //  EDIT CLIENT - NAV TO NEW EDIT CLIENT SCREEN,
    //  BUILD EDIT CLIENT SCREEN

    // function displayClientNoteRecs() {
    //     // if (clientNotesRecs) {
    //     if (clientNotesRecs && clientNotesRecs.length > 0) {
    //         return clientNotesRecs.map((clientNoteRec, index) => {
    //             return (
    //                 <StyledListCard
    //                     key={`ampUserClientNotesRec-${index}`}
    //                 >
    //                     <StyledCardContentTypography
    //                         text={`Note: ${clientNoteRec.note}`}
    //                     />
    //                     <BaseNavigateButton
    //                         label={"Update Client Note"}
    //                         navTarget={`/edit-client-note/${clientNoteRec.routerPath}`}
    //                         navState={{clientNoteRec}}
    //                         />
    //                 </StyledListCard>
    //             )
    //         })
    //     }
    //     else {
    //         return (
    //             <StyledListCard>
    //                 <StyledCardContentTypography
    //                     text={`No Client Notes`}
    //                 />
    //             </StyledListCard>
    //         )
    //     }
    // }

    // TODO - CHECK IF ANY THING ELSE TO PASS IN NAV STATE TO UPDATE
    function displayClientAnimalsAccordion() {
        // if (clientAnimalRecs) {
        if (clientAnimalRecs) {
            return (
                <div>
                    <Accordion
                        disabled={clientAnimalRecs.length === 0}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <StyledCardContentTypography
                                text={`Client Animals (${clientAnimalRecs.length})`}
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                {clientAnimalRecs.map((clientAnimalRec, index) => {
                                    return (
                                        <StyledListCard
                                            key={`ampUserClientAnimalsRec-${index}`}
                                        >
                                            <StyledCardContentTypography
                                                text={`Name: ${clientAnimalRec.name}`}
                                            />
                                            <BaseNavigateButton
                                                label={"Update Client Animal"}
                                                navTarget={`/edit-animal/${clientAnimalRec.routerPath}`}
                                                navState={{animalRec: clientAnimalRec, clientRec: clientRec}}
                                            />
                                            <BaseStyledDeleteButton
                                                label="Delete Client Animal"
                                                onClick={() => {
                                                    setClientAnimalToDelete(clientAnimalRec);
                                                    setDisplayDeleteClientAnimalModal(true);
                                                }}
                                            />
                                        </StyledListCard>
                                    )
                                })}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            )
        }
        else {
            return (
                <StyledListCard>
                    <StyledCardContentTypography
                        text={`No Client Animals`}
                    />
                </StyledListCard>
            )
        }
    }

    function displayClientApptsAccordion() {
        if (clientApptRecs) {
            return (
                <div>
                    <Accordion
                        disabled={clientApptRecs.length === 0}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <StyledCardContentTypography
                                text={`Client Appts. (${clientApptRecs.length})`}
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                {clientApptRecs.map((clientApptRec, index) => {
                                    return (
                                        <StyledListCard
                                            key={`ampUserClientApptsRec-${index}`}
                                        >
                                            <StyledCardContentTypography
                                                text={`Appt Date: ${clientApptRec.apptDate}`}
                                            />
                                            <BaseNavigateButton
                                                label={"Update Client Appt."}
                                                navTarget={`/edit-appt/${clientApptRec.routerPath}`}
                                                navState={{clientRec, apptRec: clientApptRec}}
                                            />
                                            <BaseStyledDeleteButton
                                                label="Delete Client Appt."
                                                onClick={() => {
                                                    setClientApptToDelete(clientApptRec);
                                                    setDisplayDeleteClientApptModal(true);
                                                }}
                                            />
                                        </StyledListCard>
                                    )
                                })}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            )
        }
        else {
            return (
                <StyledListCard>
                    <StyledCardContentTypography
                        text={`No Client Appts.`}
                    />
                </StyledListCard>
            )
        }
    }

    function displayClientNotesAccordion() {
        if (clientNotesRecs) {
            return (
                <div>
                    <Accordion
                        disabled={clientNotesRecs.length === 0}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <StyledCardContentTypography
                                text={`Client Notes (${clientNotesRecs.length})`}
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                {clientNotesRecs.map((clientNoteRec, index) => {
                                    return (
                                        <StyledListCard
                                            key={`ampUserClientNotesRec-${index}`}
                                        >
                                            <StyledCardContentTypography
                                                text={`Note: ${clientNoteRec.note}`}
                                            />
                                            <BaseStyledDeleteButton
                                                label="Delete Client Note"
                                                onClick={() => {
                                                    setClientNoteToDelete(clientNoteRec);
                                                    // handleDeleteApptModalOpen();
                                                    setDisplayDeleteClientNoteModal(true);
                                                }}
                                            />
                                        </StyledListCard>
                                    )
                                })}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            )
        }
        else {
            return (
                <StyledListCard>
                    <StyledCardContentTypography
                        text={`No Client Notes`}
                    />
                </StyledListCard>
            )
        }
    }


    // TODO - !!! START HERE, FR FR - ADD DELETE ACTIONS TO MODALS,
    //  MOVE ACTIONS TO MODALS HELPERS DIR
    // TODO - MOVE ACCORDIONS TO OWN COMPONENTS, REUSE IN EDIT CLIENT FORM

    // const handleDeleteAnimalModalClose = () => {
    //     setDisplayDeleteClientAnimalModal(false);
    // };

    // const handleDeleteAnimalConfirm = async (modelId) => {
    //     if (showLogs) {
    //         console.log('The Animal record has been deleted.', modelId);
    //     }
    //     // TODO - CALL DELETE ANIMAL FUNCTION
    //     setDisplayDeleteClientAnimalModal(false);
    // };

    // const handleDeleteApptConfirm = async () => {
    //     if (showLogs) {
    //         console.log('The Appt record has been deleted.', clientApptToDelete);
    //     }
    //     await deleteAppointmentById(clientApptToDelete, showLogs);
    //     handleDeleteApptModalClose();
    //     // setDisplayDeleteClientApptModal(false);
    // };

    // const handleDeleteApptModalClose = () => {
    //     setDisplayDeleteClientApptModal(false);
    //     setClientApptToDelete(null);
    // };

    // useEffect( console.log('props', props); console.log('clientRec', clientRec); console.log('clientNotesRecs', clientNotesRecs);
    useEffect(() => {
        if (showLogs) {
            console.log('props', props);
            console.log('clientRec', clientRec);  // Log the postRec object
            console.log('clientNotesRecs', clientNotesRecs);  // Log the postRec object
        }
    }, [showLogs, clientRec, props, clientNotesRecs]);

    // TODO - ADD DELETE ANIMAL BUTTON TO CLIENT ANIMALS SCREEN

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto',
            }}
        >
            <h2>Client Info</h2>
            <RouterBreadcrumbs breadcrumbsArr={breadcrumbRoutes}/>
            <StyledListBox
                width={isLargeScreen ? '90%' : '100%'}
                style={{
                    color: 'white',
                    backgroundColor: 'lightgray',
                    borderTop: '1px solid black',
                    borderBottom: '1px solid black',
                    marginTop: '0.5vh',
                }}
            >
            {clientRec ? (
                <AmpUserClientCard
                    clientRec={clientRec}
                    showLogs={showLogs}
                />
            ) : (
                <Typography>
                    No Client Rec
                </Typography>
            )}
            {/*{displayClientNoteRecs()}*/}
                {displayClientNotesAccordion()}
                {displayClientAnimalsAccordion()}
                {displayClientApptsAccordion()}
            {clientRec ? (
                <div>
                    <BaseNavigateButton
                        label={"Update Client"}
                        navTarget={`/edit-client/${clientRec?.routerPath}`}
                        navState={{clientRec}}
                    />
                    <BaseNavigateButton
                        label={"Add Client Note"}
                        navTarget={`/create-client-note/${clientRec?.routerPath}`}
                        navState={{clientRec}}
                    />
                    <BaseNavigateButton
                        label={"Client Animals"}
                        navTarget={`/client-animals`}
                        navState={{clientRec}}
                    />
                    <BaseNavigateButton
                        label={"Client Appts."}
                        navTarget={`/client-appts/${clientRec?.routerPath}`}
                        navState={{clientRec}}
                    />
                </div>
            ) : null }
            </StyledListBox>
            {clientRec ? (
                <div>
                    {clientNoteToDelete ? (
                        <div>
                            <DeleteClientNoteModal
                                open={displayDeleteClientNoteModal}
                                handleClose={handleDeleteClientNoteModalClose}
                                handleConfirm={handleDeleteClientNoteConfirm}
                                handleCancel={handleDeleteClientNoteCancel}
                                showLogs={showLogs}
                                clientNoteRecToDelete={clientNoteToDelete}
                                setDisplayDeleteClientNoteModal={setDisplayDeleteClientNoteModal}
                                setClientNoteToDelete={setClientNoteToDelete}
                                setDeleteClientNoteRes={setDeleteClientNoteRes}
                                clientRec={clientRec}
                            />
                        </div>
                    ) : null}
                    {clientAnimalToDelete ? (
                        <div>
                            <DeleteAnimalConfirmationDialog
                                open={displayDeleteClientAnimalModal}
                                handleClose={handleDeleteAnimalModalClose}
                                handleConfirm={handleDeleteAnimalConfirm}
                                handleCancel={handleDeleteAnimalCancel}
                                showLogs={showLogs}
                                modelRecToDelete={clientAnimalToDelete}
                                setDisplayDeleteClientAnimalModal={setDisplayDeleteClientAnimalModal}
                                setClientAnimalToDelete={setClientAnimalToDelete}
                                setDeleteClientAnimalRes={setDeleteClientAnimalRes}
                                clientRec={clientRec}
                            />
                        </div>
                    ) : null}
                    {clientApptToDelete ? (
                        <div>
                            <DeleteApptModal
                                open={displayDeleteClientApptModal}
                                handleClose={handleDeleteApptModalClose}
                                handleConfirm={handleDeleteApptConfirm}
                                handleCancel={handleDeleteApptCancel}
                                showLogs={showLogs}
                                modelRecToDelete={clientApptToDelete}
                                setDisplayDeleteClientApptModal={setDisplayDeleteClientApptModal}
                                setClientApptToDelete={setClientApptToDelete}
                                setDeleteClientApptRes={setDeleteClientApptRes}
                                clientRec={clientRec}
                            />
                        </div>
                    ) : null}
                </div>
            ) : null}
        </Box>
    )
}
