import { DataStore } from "aws-amplify";
import { AmpUserSubscriber } from "../../../models";

// TOD0 - MOVE THIS TO AFTER CREATE NEW AMP USER, REDUCE TRIGGERS, ETC TO CREATE THIS
export async function createNewAmpUserSubscriberRec(ampUserRec, showLogs) {
    return await DataStore.save(
        new AmpUserSubscriber({
            ampuserID: ampUserRec.id,
            subIsActive: false,
            isEdited: false
        })
    );
}
