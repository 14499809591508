import React, { useState, useEffect } from "react";
import { AnimalStatus, AnimalType } from "../../../models";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import StyledTextField from "../../text/StyledTextField";
import StyledNoteTextField from "../../text/StyledNoteTextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { filterClientByName } from "../../../data/filter/filterRecs";
import BaseStyledButton from "../../buttons/BaseStyledButton";
import { generateUniqueKey } from "../../../utils/helpers";
import {
    createAnimalFormObj,
    createShoePackageValuesFormObj,
    animalNoteFormObj, animalFormsTooltipTextsObj
} from "../../../utils/formObjects";
import { useConsoleLog } from "../../hooks/logging/console";
import { handleCurrencyTextFieldChangeFormattedUSD } from "../../../utils/text";
import { convertToFullIntegerHandleNaN } from "../../../utils/numbers";
import TooltipWrapper from "../../text/TooltipWrapper";
import ConfirmCreateAnimalFullDialog from "../../modals/create/ConfirmCreateAnimalFullDialog";
import InputAdornment from "@mui/material/InputAdornment";

// import { useNavigate } from "react-router-dom";
// import {createAnimalRecWithNoteShoePkgRecs} from "../../data/create/animal/createAnimalRecs";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function CreateAnimalForm(props) {
    const [showLogs, setShowLogs] = useState(false);
    const [ampUser, setAmpUser] = useState(null);
    const [ampUserClientRecs, setAmpUserClientRecs] = useState([]);

    // This is for creating an animal, with no specific client data passed down to here
    const [selectedAmpUserClient, setSelectedAmpUserClient] = useState(null);

    // This is for creating an animal for a specific client, based on location state from another screen
    const [selectedClient, setSelectedClient] = useState(null);

    // This is the name from the form's select client dropdown
    const [selectedClientName, setSelectedClientName] = useState('');

    const [animalTypeEnums, setAnimalTypeEnums] = useState([]);
    const [selectedAnimalType, setSelectedAnimalType] = useState('');
    const [animalStatusEnums, setAnimalStatusEnums] = useState([]);
    const [selectedAnimalStatus, setSelectedAnimalStatus] = useState('');

    const [origAnimalFormValues, setOrigAnimalFormValues] = useState(createAnimalFormObj);
    const [updatedAnimalFormValues, setUpdatedAnimalFormValues] = useState(createAnimalFormObj);
    const [animalFormValsDiffObj, setAnimalFormValsDiffObj] = useState({});

    const [addedShoeTaskAmountFormatted, setAddedShoeTaskAmountFormatted] = useState('');
    const [addedTrimTaskAmountFormatted, setAddedTrimTaskAmountFormatted] = useState('');

    const [updatedAnimalNoteFormValues, setUpdatedAnimalNoteFormValues] = useState(animalNoteFormObj);

    const [updatedShoePackageValues, setUpdatedShoePackageValues] = useState(createShoePackageValuesFormObj);

    const [displayConfirmCreateAnimalFullDialog, setDisplayConfirmCreateAnimalFullDialog] = useState(false);
    const [createAnimalResObj, setCreateAnimalResObj] = useState(null);
    const [createNoteResObj, setCreateNoteResObj] = useState(null);
    const [createShoePkgResObj, setCreateShoePkgResObj] = useState(null);

    // const navigate = useNavigate();

    // useEffect( setAmpUser(props.ampUser);
    useEffect(() => {
        if (props?.ampUser) {
            setAmpUser(props.ampUser);
        }
    }, [props.ampUser]);

    // useEffect( setShowLogs(props.showLogs);
    useEffect(() => {
        if (props?.showLogs) {
            setShowLogs(props.showLogs);
        }
    }, [props.showLogs]);

    // useEffect( setAmpUserClientRecs(props.ampUserClients);
    useEffect(() => {
        if (props?.ampUserClients) {
            setAmpUserClientRecs(props.ampUserClients);
        }
    }, [props.ampUserClients]);

    // useEffect( setSelectedClient(props.selectedClientProp);
    useEffect(() => {
        if (props?.selectedClientProp) {
            setSelectedClient(props.selectedClientProp);
            setOrigAnimalFormValues({...origAnimalFormValues, clientId: props.selectedClientProp.id});
            setUpdatedAnimalFormValues({...updatedAnimalFormValues, clientId: props.selectedClientProp.id});
        }
    }, [props.selectedClientProp]);

    // useEffect( set clientId in animal forms, based on selectedClient(Prop) existing
    useEffect(() => {
        if (selectedClient) {
            // setSelectedClient(props.selectedClientProp);
            setOrigAnimalFormValues({...origAnimalFormValues, clientId: selectedClient.id});
            setUpdatedAnimalFormValues({...updatedAnimalFormValues, clientId: selectedClient.id});
        }
        if (selectedClient && selectedClientName === '') {
            setSelectedClientName(selectedClient.name);
        }
    }, [selectedClient]);

    useConsoleLog(showLogs, [{label: 'CreateAnimalForm ampUser', value: ampUser}]);
    useConsoleLog(showLogs, [{label: 'CreateAnimalForm ampUserClientRecs', value: ampUserClientRecs}]);
    useConsoleLog(showLogs, [{label: 'CreateAnimalForm selectedClient', value: selectedClient}]);
    useConsoleLog(showLogs, [{label: 'CreateAnimalForm selectedAmpUserClient', value: selectedAmpUserClient}]);
    useConsoleLog(showLogs, [{label: 'CreateAnimalForm selectedClientName', value: selectedClientName}]);
    useConsoleLog(showLogs, [{label: 'CreateAnimalForm selectedAnimalType', value: selectedAnimalType}]);
    useConsoleLog(showLogs, [{label: 'CreateAnimalForm animalStatusEnums', value: animalStatusEnums}]);
    useConsoleLog(showLogs, [{label: 'CreateAnimalForm animalTypeEnums', value: animalTypeEnums}]);
    useConsoleLog(showLogs, [{label: 'CreateAnimalForm updatedAnimalFormValues', value: updatedAnimalFormValues}]);
    // useConsoleLog(showLogs, [{
    //     label: 'CreateAnimalForm addedShoeTaskAmountFormatted',
    //     value: addedShoeTaskAmountFormatted
    // }]);
    // useConsoleLog(showLogs, [{
    //     label: 'CreateAnimalForm addedTrimTaskAmountFormatted',
    //     value: addedTrimTaskAmountFormatted
    // }]);
    useConsoleLog(showLogs, [{label: 'CreateAnimalForm updatedShoePackageValues', value: updatedShoePackageValues}]);
    useConsoleLog(showLogs, [{label: 'CreateAnimalForm displayConfirmCreateAnimalFullDialog', value: displayConfirmCreateAnimalFullDialog}]);
    useConsoleLog(showLogs, [{label: 'CreateAnimalForm createAnimalResObj', value: createAnimalResObj}]);
    useConsoleLog(showLogs, [{label: 'CreateAnimalForm createNoteResObj', value: createNoteResObj}]);
    useConsoleLog(showLogs, [{label: 'CreateAnimalForm createShoePkgResObj', value: createShoePkgResObj}]);
    useConsoleLog(showLogs, [{label: 'CreateAnimalForm animalFormValsDiffObj', value: animalFormValsDiffObj}]);

    // useEffect( setAnimalStatusEnums(AnimalStatus);
    useEffect(() => {
        if (AnimalStatus) {
            setAnimalStatusEnums(AnimalStatus);
        }
    }, [AnimalStatus]);

    // useEffect( setAnimalTypeEnums(AnimalType);
    useEffect(() => {
        if (AnimalType) {
            setAnimalTypeEnums(AnimalType);
        }
    }, [AnimalType]);

    useEffect(() => {
        if (origAnimalFormValues && updatedAnimalFormValues) {
            const diffObj = {};
            for (const [key, value] of Object.entries(updatedAnimalFormValues)) {
                if (origAnimalFormValues[key] !== value) {
                    diffObj[key] = value;
                }
            }
            setAnimalFormValsDiffObj(diffObj);
        }
    }, [origAnimalFormValues, updatedAnimalFormValues]);

    // TODO - ADD HANDLING FOR INT TYPE FIELDS
    const handleUpdatedAnimalFormChange = (eventKey, eventValue) => {
        // let eventFormVal;
        if (showLogs) {
            console.log('eventKey', eventKey);
            console.log('eventValue', eventValue);
        }
        // if (eventKey in ['addedShoeTaskAmount', 'addedTrimTaskAmount']) {
        if (['addedShoeTaskAmount', 'addedTrimTaskAmount'].includes(eventKey)) {
            const eventFormValObj = handleCurrencyTextFieldChangeFormattedUSD(eventValue);
            if (showLogs) {
                console.warn('eventFormValObj', eventFormValObj);
            }
            if (eventFormValObj.intVal) {
                // convert to int as dollars
                const intValFormVal = eventFormValObj.intVal * 100;
                setUpdatedAnimalFormValues({...updatedAnimalFormValues, [eventKey]: intValFormVal});
            }
            if (eventKey === 'addedShoeTaskAmount') {
                setAddedShoeTaskAmountFormatted(eventFormValObj.displayVal);
            }
            if (eventKey === 'addedTrimTaskAmount') {
                setAddedTrimTaskAmountFormatted(eventFormValObj.displayVal);
            }
        } else if (['age', 'addedShoeTaskDuration', 'addedTrimTaskDuration', 'shoeTaskCyclePeriodWeeks', 'trimTaskCyclePeriodWeeks'].includes(eventKey)) {
            const eventFormValInt = convertToFullIntegerHandleNaN(eventValue);
            setUpdatedAnimalFormValues({...updatedAnimalFormValues, [eventKey]: eventFormValInt});
        } else {
            setUpdatedAnimalFormValues({...updatedAnimalFormValues, [eventKey]: eventValue});
        }
    }

    // handleTemperamentTextFieldChange setName(event.target.value); setFormValues({...formValues, name: event.target.value})
    const handleTemperamentTextFieldChange = (event) => {
        let valInt;
        if (event.target.value === '') {
            valInt = 0;
        } else {
            valInt = parseInt(event.target.value, 10);
            if (isNaN(valInt)) {
                valInt = 0;
            }
        }
        // setFormValues({...formValues, temperament: valInt});
        setUpdatedAnimalFormValues({...updatedAnimalFormValues, temperament: valInt});
    };

    // handleUpdatedNoteTextFieldChange( setUpdatedAnimalNoteFormValues({...updatedAnimalNoteFormValues, [eventKey]: eventVal});
    const handleUpdatedNoteTextFieldChange = (eventKey, eventVal) => {
        setUpdatedAnimalNoteFormValues({...updatedAnimalNoteFormValues, [eventKey]: eventVal});
    };

    const handleUpdatedShoePkgTextFieldChange = (eventKey, eventVal) => {
        // const valInt =parseInt(event.target.value, 10);
        setUpdatedShoePackageValues({...updatedShoePackageValues, [eventKey]: eventVal});
    };

    // useEffect( setSelectedAmpUserClient(clientRec[0]);
    useEffect(() => {
        if (selectedClient && selectedClientName && selectedClient.name === selectedClientName) {
            // const clientRec = filterClientByName(ampUserClientRecs, selectedClientName);
            setSelectedAmpUserClient(selectedClient);
            // setFormValues({...formValues, clientId: clientRec[0].id})
            setUpdatedAnimalFormValues({...updatedAnimalFormValues, clientId: selectedClient.id})
        } else if (selectedClientName) {
            const clientRec = filterClientByName(ampUserClientRecs, selectedClientName);
            setSelectedAmpUserClient(clientRec[0]);
            // setFormValues({...formValues, clientId: clientRec[0].id})
            setUpdatedAnimalFormValues({...updatedAnimalFormValues, clientId: clientRec[0].id})
        }
        // }, [selectedClientName, ampUserClientRecs]);
    }, [selectedClient, selectedClientName, ampUserClientRecs]);

    function onCloseCreateAnimalFullDialog() {
        setDisplayConfirmCreateAnimalFullDialog(false);
    }

    function onCreateAnimalDone(createResObj) {
        setCreateAnimalResObj(createResObj);
    }

    function onCreateNoteDone(createResObj) {
        setCreateNoteResObj(createResObj);
    }

    function onCreateShoePkgDone(createResObj) {
        setCreateShoePkgResObj(createResObj);
    }

    // TODO - PREVENT DUPE RECORDS, BASED ON CRITERIA FROM ANIMAL CRUD UPDATES GOOGLE DOC

    // TODO - resolve input error in form, handle other animal types, records save as expected
    // TODO - add date time input for birthday, update age if birthday is populated
    return (
        <div>
            <Divider>
                <Chip label="Animal Info"/>
            </Divider>
            <div>
                <FormControl sx={{m: 1, width: 300}}>
                    <InputLabel id={`create-animal-client-input-label`}>Client</InputLabel>
                    <Select
                        labelId={`create-animal-client-label-id`}
                        id={`create-animal-client-select`}
                        value={selectedClientName}
                        onChange={(event) => {
                            setSelectedClientName(event.target.value);
                            handleUpdatedAnimalFormChange('clientName', event.target.value);
                        }}
                        MenuProps={MenuProps}
                    >
                        {ampUserClientRecs.map((clientRec, index) => (
                            <MenuItem
                                key={`create-animal-clientRec-${index}`}
                                value={clientRec.name}
                            >
                                {clientRec.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div>
                <FormControl sx={{m: 1, width: 300}}>
                    <InputLabel id={`create-animal-animal-type-input-label`}>Animal Type</InputLabel>
                    <Select
                        labelId={`create-animal-animal-type-label-id`}
                        id={`create-animal-animal-type-select`}
                        value={selectedAnimalType}
                        onChange={(event) => {
                            setSelectedAnimalType(event.target.value);
                            handleUpdatedAnimalFormChange('animalType', event.target.value);
                        }}
                        MenuProps={MenuProps}
                    >
                        {Object.entries(animalTypeEnums).map(([key, val]) => (
                            <MenuItem
                                key={generateUniqueKey(key)}  // Use the key for generating a unique key
                                value={val}  // Use the key as the value for the MenuItem
                            >
                                {val}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div>
                <FormControl sx={{m: 1, width: 300}}>
                    <InputLabel id={`create-animal-animal-status-input-label`}>Animal Status</InputLabel>
                    <Select
                        labelId={`create-animal-animal-status-label-id`}
                        id={`create-animal-animal-status-select`}
                        value={selectedAnimalStatus}
                        onChange={(event) => {
                            setSelectedAnimalStatus(event.target.value);
                            handleUpdatedAnimalFormChange('animalStatus', event.target.value);
                        }}
                        MenuProps={MenuProps}
                    >
                        {Object.entries(animalStatusEnums).map(([key, val]) => (
                            <MenuItem
                                key={generateUniqueKey(key)}  // Use the key for generating a unique key
                                value={val}  // Use the key as the value for the MenuItem
                            >
                                {val}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div>
                <StyledTextField
                    id="name"
                    label="Name"
                    value={updatedAnimalFormValues.name}
                    onChange={(event) => handleUpdatedAnimalFormChange('name', event.target.value)}
                />
            </div>
            <div>
                <StyledTextField
                    id="age"
                    label="Age (Years)"
                    value={updatedAnimalFormValues.age}
                    onChange={(event) => handleUpdatedAnimalFormChange('age', event.target.value)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">years</InputAdornment>,
                    }}
                />
            </div>
            <div>
                <TooltipWrapper tooltipText={animalFormsTooltipTextsObj?.temperament || '-'}>
                    <StyledTextField
                        id="temperament"
                        label="Temperament"
                        value={updatedAnimalFormValues.temperament}
                        onChange={handleTemperamentTextFieldChange}
                    />
                </TooltipWrapper>
            </div>
            <div>
                <StyledTextField
                    id="stall-number"
                    label="Stall Number"
                    value={updatedAnimalFormValues.stallNumber}
                    onChange={(event) => handleUpdatedAnimalFormChange('stallNumber', event.target.value)}
                />
            </div>
            <div>
                <StyledNoteTextField
                    id="note"
                    label="Note"
                    value={updatedAnimalNoteFormValues.note}
                    onChange={(event) => handleUpdatedNoteTextFieldChange('note', event.target.value)}
                />
            </div>
            <Divider>
                <Chip label="Shoe Package Info"/>
            </Divider>
            <div>
                <TooltipWrapper tooltipText={animalFormsTooltipTextsObj.shoePackageFrontLeft}>
                <StyledTextField
                    id="shoe-package-front-left"
                    label="Shoe Front Left"
                    value={updatedShoePackageValues.frontLeft}
                    onChange={(event) => handleUpdatedShoePkgTextFieldChange('frontLeft', event.target.value)}
                />
                </TooltipWrapper>
            </div>
            <div>
                <TooltipWrapper tooltipText={animalFormsTooltipTextsObj.shoePackageFrontRight}>
                <StyledTextField
                    id="shoe-package-front-right"
                    label="Shoe Front Right"
                    value={updatedShoePackageValues.frontRight}
                    onChange={(event) => handleUpdatedShoePkgTextFieldChange('frontRight', event.target.value)}
                />
                </TooltipWrapper>
            </div>
            <div>
                <TooltipWrapper tooltipText={animalFormsTooltipTextsObj.shoePackageRearLeft}>
                <StyledTextField
                    id="shoe-package-rear-left"
                    label="Shoe Rear Left"
                    value={updatedShoePackageValues.rearLeft}
                    onChange={(event) => handleUpdatedShoePkgTextFieldChange('rearLeft', event.target.value)}
                />
                </TooltipWrapper>
            </div>
            <div>
                <TooltipWrapper tooltipText={animalFormsTooltipTextsObj.shoePackageRearRight}>
                <StyledTextField
                    id="shoe-package-rear-right"
                    label="Shoe Rear Right"
                    value={updatedShoePackageValues.rearRight}
                    onChange={(event) => handleUpdatedShoePkgTextFieldChange('rearRight', event.target.value)}
                />
                </TooltipWrapper>
            </div>
            <Divider>
                <Chip label={`${selectedAnimalType} Info`}/>
            </Divider>
            <div>
                <StyledTextField
                    id="animal-breed"
                    label="Breed"
                    value={updatedAnimalFormValues.breed}
                    onChange={(event) => handleUpdatedAnimalFormChange('breed', event.target.value)}
                />
            </div>
            <div>
                <StyledTextField
                    id="animal-color"
                    label="Color"
                    value={updatedAnimalFormValues.color}
                    onChange={(event) => handleUpdatedAnimalFormChange('color', event.target.value)}
                />
            </div>
            <div>
                <StyledTextField
                    id="animal-discipline"
                    label="Discipline"
                    value={updatedAnimalFormValues.discipline}
                    onChange={(event) => handleUpdatedAnimalFormChange('discipline', event.target.value)}
                />
            </div>
            <div>
                <TooltipWrapper tooltipText={animalFormsTooltipTextsObj?.addedShoeTaskAmount || '-'}>
                    <StyledTextField
                        id="added-shoe-task-amount"
                        label="Added Shoeing Amount ($)"
                        value={addedShoeTaskAmountFormatted}
                        onChange={(event) => handleUpdatedAnimalFormChange('addedShoeTaskAmount', event.target.value)}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                    />
                </TooltipWrapper>
            </div>
            <div>
                <TooltipWrapper tooltipText={animalFormsTooltipTextsObj?.addedShoeTaskDuration || '-'}>
                    <StyledTextField
                        id="added-shoe-task-duration"
                        label="Added Shoeing Duration (mins.)"
                        value={updatedAnimalFormValues.addedShoeTaskDuration}
                        onChange={(event) => handleUpdatedAnimalFormChange('addedShoeTaskDuration', event.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">mins.</InputAdornment>,
                        }}
                    />
                </TooltipWrapper>
            </div>
            <div>
                <TooltipWrapper tooltipText={animalFormsTooltipTextsObj?.addedTrimTaskAmount || '-'}>
                    <StyledTextField
                        id="added-trim-task-amount"
                        label="Added Trim Amount ($)"
                        value={addedTrimTaskAmountFormatted}
                        onChange={(event) => handleUpdatedAnimalFormChange('addedTrimTaskAmount', event.target.value)}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                    />
                </TooltipWrapper>
            </div>
            <div>
                <TooltipWrapper tooltipText={animalFormsTooltipTextsObj?.addedTrimTaskDuration || '-'}>
                    <StyledTextField
                        id="added-trim-task-duration"
                        label="Added Trim Duration (mins.)"
                        value={updatedAnimalFormValues.addedTrimTaskDuration}
                        onChange={(event) => handleUpdatedAnimalFormChange('addedTrimTaskDuration', event.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">mins.</InputAdornment>,
                        }}
                    />
                </TooltipWrapper>
            </div>
            <div>
                <TooltipWrapper tooltipText={animalFormsTooltipTextsObj?.shoeTaskCyclePeriodWeeks || '-'}>
                    <StyledTextField
                        id="shoe-task-cycle-period-weeks"
                        label="Shoeing Cycle Period (weeks)"
                        value={updatedAnimalFormValues.shoeTaskCyclePeriodWeeks}
                        onChange={(event) => handleUpdatedAnimalFormChange('shoeTaskCyclePeriodWeeks', event.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">weeks</InputAdornment>,
                        }}
                    />
                </TooltipWrapper>
            </div>
            <div>
                <TooltipWrapper tooltipText={animalFormsTooltipTextsObj?.trimTaskCyclePeriodWeeks || '-'}>
                    <StyledTextField
                        id="trim-task-cycle-period-weeks"
                        label="Trim Cycle Period (weeks)"
                        value={updatedAnimalFormValues.trimTaskCyclePeriodWeeks}
                        onChange={(event) => handleUpdatedAnimalFormChange('trimTaskCyclePeriodWeeks', event.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">weeks</InputAdornment>,
                        }}
                    />
                </TooltipWrapper>
            </div>
            <div>
                <BaseStyledButton
                    label="Create Animal"
                    disabled={!selectedAmpUserClient}
                    onClick={() => setDisplayConfirmCreateAnimalFullDialog(true)}
                />
            </div>
            <div>
                {displayConfirmCreateAnimalFullDialog ? (
                    <ConfirmCreateAnimalFullDialog
                        openProp={displayConfirmCreateAnimalFullDialog}
                        ampUser={ampUser}
                        handleCloseProp={onCloseCreateAnimalFullDialog}
                        handleCreateAnimalDoneProp={onCreateAnimalDone}
                        handleCreateNoteDoneProp={onCreateNoteDone}
                        handleCreateShoePackageDoneProp={onCreateShoePkgDone}
                        animalFormVals={updatedAnimalFormValues}
                        animalFormValsDiffObj={animalFormValsDiffObj}
                        animalNoteFormVals={updatedAnimalNoteFormValues}
                        shoePackageFormVals={updatedShoePackageValues}
                        showLogs={showLogs}
                    />
                ) : null}
            </div>
        </div>
    )
}
