import React, { useState, useEffect } from "react";
import ClientAnimalsContext from "../../contexts/ClientAnimalsContext";
import {DataStore, SortDirection} from "aws-amplify";
import {Animal, ShoePackage} from "../../../models";
import {fetchShoePackagesByAnimalId} from "../../get/fetchRecords";

const ClientAnimalsDataProvider = ({ children, clientId, showLogs, resetProviderState }) => {
    const [ampUserClientAnimalsRecs, setAmpUserClientAnimalsRecs] = useState([]);
    const [ampUserClientAnimalsShowLogs, setAmpUserClientAnimalsShowLogs] = useState(false);
    const [shoePackageRecsByAnimalId, setShoePackageRecsByAnimalId] = useState({});
    const [animalRecIds, setAnimalRecIds] = useState([]);

    const resetContext = () => {
        setAmpUserClientAnimalsRecs([]);
        setShoePackageRecsByAnimalId({});
        setAnimalRecIds([]);
        // console.warn('!!! ClientAnimalsDataProvider resetContext() - RESET');
    }

    // useEffect( reset state values when resetProviderState is true
    useEffect(() => {
        if (resetProviderState) {
            resetContext();
        }
    }, [resetProviderState]);

    // useEffect( set ampUserClientAnimalsShowLogs(showLogs);
    useEffect(() => {
        if (showLogs) {
            setAmpUserClientAnimalsShowLogs(showLogs);
        }
        else {
            setAmpUserClientAnimalsShowLogs(false);
        }
    }, [showLogs]);

    // useEffect( console.log( ampUserClientAnimalsRecs, animalRecIds, shoePackageRecsByAnimalId
    // useEffect(() => {
    //     if (ampUserClientAnimalsShowLogs) {
    //         console.log('ClientAnimalsProvider ampUserClientAnimalsRecs', ampUserClientAnimalsRecs);
    //         console.log('ClientAnimalsProvider animalRecIds', animalRecIds);
    //         console.log('ClientAnimalsProvider shoePackageRecsByAnimalId', shoePackageRecsByAnimalId);
    //     }
    // }, [ampUserClientAnimalsRecs, ampUserClientAnimalsShowLogs, animalRecIds, shoePackageRecsByAnimalId]);

    // useEffect( set animalRecIds from ampUserClientAnimalsRecs
    useEffect(() => {
        if (ampUserClientAnimalsRecs) {
            const animalRecIds = ampUserClientAnimalsRecs.map((animalRec) => {
                return animalRec.id;
            });
            setAnimalRecIds(animalRecIds);
        }
    }, [ampUserClientAnimalsRecs]);


    useEffect(() => {
        if (!resetProviderState && clientId) {
            const subscription = DataStore.observeQuery(Animal,
                // (a) => a.clientID.eq(clientId),
                (a) => a.and(a => [
                    a.clientID.eq(clientId),
                    a.isDeleted.ne(true)  // Filter out records where isDeleted is true
                ]),
                {
                    sort: s => s.createdAt(SortDirection.DESCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    // console.log(`ClientAnimalsDataProvider a [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                    // console.log('ClientAnimalsDataProvider a [Snapshot] items', items);
                    if (isSynced) {
                        setAmpUserClientAnimalsRecs(items);
                    }
                });
            // Remember to unsubscribe when the component unmounts
            return () => subscription.unsubscribe();
        }
    }, [clientId, ampUserClientAnimalsShowLogs, resetProviderState]); // Dependency array now includes clientId

    useEffect(() => {
        const fetchAllShoePackages = async () => {
            if (!resetProviderState && animalRecIds && animalRecIds.length > 0) {
                const fetchPromises = animalRecIds.map(async (animalId) => {
                    const shoePackageRecs = await fetchShoePackagesByAnimalId(animalId);
                    return { animalId, shoePackageRecs };
                });

                const results = await Promise.all(fetchPromises);
                const recsById = results.reduce((acc, { animalId, shoePackageRecs }) => {
                    // Assuming shoePackageRecs is an array of records
                    // Sort shoePackageRecs by createdAt in descending order to get the most recent record first
                    if (shoePackageRecs && shoePackageRecs.length > 0) {
                        shoePackageRecs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                        // Assign only the most recent shoePackageRec for the animalId
                        acc[animalId] = shoePackageRecs[0];
                    }
                    return acc;
                }, {});

                setShoePackageRecsByAnimalId(recsById);
            }
        };
        fetchAllShoePackages();
    }, [animalRecIds, resetProviderState]);

    return (
        <ClientAnimalsContext.Provider value={{ ampUserClientAnimalsRecs, shoePackageRecsByAnimalId, setAmpUserClientAnimalsShowLogs }}>
            {children}
        </ClientAnimalsContext.Provider>
    );
}

export default ClientAnimalsDataProvider;
