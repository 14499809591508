import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
    Typography,
} from '@mui/material';
import { updateClientRec } from "../../../data/update/client/updateClient";
import Grid from "@mui/material/Grid";
import { updateClientDialogFinalStepSet } from "../../buttons/sets/clientButtonSets";
import { Stack } from '@mui/material';
import BaseNavigateButton from "../../links/BaseNavigateButton";

export default function UpdateClientDialog({ openProp,
                                            ampUser,
                                            handleCloseProp,
                                            clientRec,
                                            clientFormValsDiff,
                                            handleUpdateDoneProp,
                                            showLogs,
                                            ...props }) {
    const [open, setOpen] = useState(false);
    const [updatedFields, setUpdatedFields] = useState(null);

    const [isUpdatingClient, setIsUpdatingClient] = useState(false);
    const [updateClientResObj, setUpdateClientResObj] = useState(null);
    const [updateClientError, setUpdateClientError] = useState(null);

    const [activeStep, setActiveStep] = useState(0);
    const [finalStepButtonsObj, setFinalStepButtonsObj] = useState(null);
    const [finalStepDialogTitleText, setFinalStepDialogTitleText] = useState(null);

    // useEffect( if (openProp) { setOpen(true);
    useEffect(() => {
        if (openProp) {
            setOpen(true);
        }
    }, [openProp]);

    useEffect(() => {
        if (clientFormValsDiff && Object.keys(clientFormValsDiff).length > 0) {
            setUpdatedFields(clientFormValsDiff);
            setActiveStep(1);
        }
        else {
            setActiveStep(2);
        }
    }, [clientFormValsDiff]);

    useEffect(() => {
        if (clientRec) {
            setFinalStepButtonsObj(updateClientDialogFinalStepSet(clientRec));
        }
    }, [clientRec]);

    useEffect(() => {
        if (updateClientResObj) {
            if (!updateClientResObj?.success && updateClientResObj?.error) {
                setUpdateClientError(updateClientResObj.error);
            }
        }
    }, [updateClientResObj]);

    const handleClose = () => {
        if (showLogs) console.log('### UpdateClientDialog handleClose');
        if (handleCloseProp) {
            if (!isUpdatingClient) {
                if (handleUpdateDoneProp) {
                    handleUpdateDoneProp(updateClientResObj);
                }
                handleCloseProp();
            }
        }
    }

    const handleCancel = () => {
        setActiveStep(0);
        handleClose();
    };

    const handleConfirmUpdateClient = async () => {
        setIsUpdatingClient(true);
        const updateStartTime = Date.now();

        try {
            const [updateClientResponse] = await Promise.all([
                updateClientRec(ampUser, clientRec.id, updatedFields, showLogs), // Capture the response object here
                new Promise(resolve => setTimeout(resolve, 2000))
            ]);

            // Use the update response to set your state
            setUpdateClientResObj(updateClientResponse);

        } catch (error) {
            console.error('Update failed:', error);
            setUpdateClientError(error);
            // Handle error (e.g., show error message to user)
        } finally {
            const updateDuration = Date.now() - updateStartTime;
            if (updateDuration < 1000) {
                await new Promise(resolve => setTimeout(resolve, 2000 - updateDuration));
            }
            setIsUpdatingClient(false);
            setActiveStep(2);
        }
    };

    // Step 1, only if client fields to update
    const renderUpdateClientStep = () => (
        <>
            <DialogTitle>Update Client</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please review the updated client information:
                </DialogContentText>
                <div style={{ margin: '20px 0' }}>
                    {Object.entries(updatedFields).map(([fieldName, fieldValue]) => (
                        <Typography key={fieldName} variant="body1" style={{ marginBottom: '10px' }}>
                            <strong>{fieldName}:</strong> {fieldValue}
                        </Typography>
                    ))}
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleCancel}
                    disabled={isUpdatingClient}
                >
                    Cancel
                </Button>
                <Button onClick={async () => await handleConfirmUpdateClient()} variant="contained" color="primary" disabled={isUpdatingClient}>
                    {isUpdatingClient ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                                <CircularProgress size={24} />
                                <DialogContentText style={{ marginLeft: '20px' }}>
                                    Updating client record...
                                </DialogContentText>
                            </div>
                    ) : 'Confirm Changes'}
                </Button>
                <Button
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                    disabled={isUpdatingClient}
                >
                    Close
                </Button>
            </DialogActions>
        </>
    )

    // Last step to display outcomes, display nav buttons or close
    const renderFinalStep = () => (
        <>
            <DialogTitle>{finalStepDialogTitleText || 'Update Done'}</DialogTitle>
            <DialogContent>
                {updateClientResObj && updateClientError ? (
                    <div>
                        <DialogContentText>
                            Error updating the client
                        </DialogContentText>
                        <p>{updateClientError}</p>
                    </div>
                ) : null}
                {updateClientResObj && updateClientResObj?.success ? (
                    <DialogContentText>
                        Client updated
                    </DialogContentText>
                ) : null }
            </DialogContent>
            <DialogActions>
                <Stack spacing={2} width="100%">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                            {finalStepButtonsObj.clientInfo}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {finalStepButtonsObj.clientAnimals}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {finalStepButtonsObj.clientAppts}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {finalStepButtonsObj.home}
                        </Grid>
                    </Grid>
                    {/*<Button*/}
                    {/*    onClick={handleClose}*/}
                    {/*    variant="contained"*/}
                    {/*    color="primary"*/}
                    {/*    fullWidth*/}
                    {/*>*/}
                    {/*    Close*/}
                    {/*</Button>*/}
                    <BaseNavigateButton
                        label={"Done"}
                        fullWidth
                        navTarget={"/clients"}
                    />
                </Stack>
            </DialogActions>
        </>
    )

    const renderLoadingStep = () => (
        <>
            <DialogTitle>Loading...</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Loading Client Update Data
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                    disabled={isUpdatingClient}
                >
                    Close
                </Button>
                <Button
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                    disabled={isUpdatingClient}
                >
                    Cancel
                </Button>
            </DialogActions>
        </>
    );

    return (
        <Dialog open={open} onClose={handleClose}>
            {activeStep === 0 ? renderLoadingStep() :
                activeStep === 1 ? renderUpdateClientStep() :
                        renderFinalStep()}
        </Dialog>
    );
}
