import React, {useState, useEffect} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import {ArrowBack} from "@mui/icons-material";
import {camelCaseToTitleCase} from "../../utils/text";
import Divider from "@mui/material/Divider";

export default function CreateNewRecSuccessDialog({ open,
                                                    handleClose,
                                                    recType,
                                                    newRecAttrsObj,
                                                    showLogs,
                                                    ...props }) {
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogContentText, setDialogContentText] = useState('');
    const [newRecType, setNewRecType] = useState('');
    const [newRecAttrsToDisplayObj, setNewRecAttrsToDisplayObj] = useState({});
    const [nextScreenNavButtonComponent, setNextScreenNavButtonComponent] = useState(null);

    // useEffect( setNewRecType(camelCaseToTitleCase(recType));
    useEffect(() => {
        if (recType) {
            setNewRecType(camelCaseToTitleCase(recType));
        }
    }, [recType]);

    // useEffect( setNewRecAttrsToDisplayObj(newRecAttrsObj);
    useEffect(() => {
        if (newRecAttrsObj) {
            setNewRecAttrsToDisplayObj(newRecAttrsObj);
        }
    }, [newRecAttrsObj]);

    // useEffect( console.log( newRecType, newRecAttrsToDisplayObj
    useEffect(() => {
        if (showLogs) {
            console.log('CreateNewRecSuccessDialog newRecType', newRecType);
            console.log('CreateNewRecSuccessDialog newRecAttrsToDisplayObj', newRecAttrsToDisplayObj);
        }
    }, [showLogs, newRecType, newRecAttrsToDisplayObj]);

    // useEffect( setDialogTitle(props.dialogTitleProp);
    useEffect(() => {
        if (props?.dialogTitleProp) {
            setDialogTitle(props.dialogTitleProp);
        }
        else if (newRecType) {
            setDialogTitle(`${newRecType} Created`);
        }
        else{
            setDialogTitle('New Record Created');
        }
    }, [props.dialogTitleProp, newRecType]);

    // useEffect( setDialogContentText(props.dialogContentTextProp);
    useEffect(() => {
        if (props?.dialogContentTextProp) {
            setDialogContentText(props.dialogContentTextProp);
        }
        else if (newRecType) {
            setDialogContentText(`${newRecType} was created successfully!`);
        }
        else{
            setDialogContentText('New record was created successfully!');
        }
    }, [props.dialogContentTextProp, newRecType]);

    // useEffect( setNextScreenNavButtonComponent(props.nextScreenNavButton);
    useEffect(() => {
        if (props?.nextScreenNavButton) {
            setNextScreenNavButtonComponent(props.nextScreenNavButton);
        }
    }, [props.nextScreenNavButton]);

    // useEffect( console.log( dialogTitle, dialogContentText
    useEffect(() => {
        if (showLogs) {
            console.log('CreateNewRecSuccessDialog dialogTitle', dialogTitle);
            console.log('CreateNewRecSuccessDialog dialogContentText', dialogContentText);
        }
    }, [showLogs, dialogTitle, dialogContentText]);

    // useEffect( console.log( nextScreenNavButtonComponent
    useEffect(() => {
        if (showLogs) {
            console.log('CreateNewRecSuccessDialog nextScreenNavButtonComponent', nextScreenNavButtonComponent);
        }
    }, [showLogs, nextScreenNavButtonComponent]);


    function displayNewRecAttrs() {
        // if (updateRecDiffsObj) {
        if (newRecAttrsToDisplayObj && Object.keys(newRecAttrsToDisplayObj).length > 0) {
            return (
                <Stack spacing={2}>
                    {/*{recDiffsTitle ? <h3>{recDiffsTitle}</h3> : <h3>Fields to Update</h3>}*/}
                    {Object.entries(newRecAttrsToDisplayObj).map(([key, value], idx) => {
                        return (
                            <DialogContentText key={`${idx}-${key}`}>
                                {`${camelCaseToTitleCase(key)}: ${value}`}
                            </DialogContentText>
                        )
                    })}
                </Stack>
            );
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {dialogTitle}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {dialogContentText}
                </DialogContentText>
                <Divider />
                {displayNewRecAttrs()}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus>
                    Close
                </Button>
                {nextScreenNavButtonComponent ? nextScreenNavButtonComponent : null}
            </DialogActions>
        </Dialog>
    );
}
