
export const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
};

export const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

export const validatePhoneNumber = (phone) => {
    if (!phone) return true;

    const re = /^\(\d{3}\) \d{3}-\d{4}$/;
    return re.test(phone);
};

export const convertToE164 = (phoneNumber) => {
    // Remove all non-numeric characters
    const cleaned = phoneNumber.replace(/\D/g, '');

    // Check if the number has 10 digits (U.S. phone number)
    if (cleaned.length === 10) {
        // Format as E.164 by adding the U.S. country code
        return `+1${cleaned}`;
    }

    // Return the original number if it doesn't match the expected format
    return phoneNumber;
};

