import React, { useState, useEffect } from 'react';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import StyledCardContentTypography from "../text/StyledCardContentTypography";
import StyledListCard from "../cards/StyledListCard";
import StyledAccordion from "./StyledAccordion";
import Divider from "@mui/material/Divider";
import { toTitleCase } from "../../utils/text";
import { convertTimestampToDate } from "../../utils/datesTimes";
import {toDollarString} from "../../utils/text";
import BaseStyledButton from "../buttons/BaseStyledButton";

// import { useNavigate } from "react-router-dom";

export default function SubsPaymentsDataAccordion({ userSubs, subPaymentViews, headerText, showLogs, ...props }) {
    const [userSubRecs, setUserSubRecs] = useState([]);
    const [subPaymentViewRecs, setSubPaymentViewRecs] = useState([]);
    const [accordionHeaderText, setAccordionHeaderText] = useState('');
    const [matchedRecs, setMatchedRecs] = useState([]);
    const [ampUserSubscriber, setAmpUserSubscriber] = useState(null);
    const [accExpanded, setAccExpanded] = useState(false);

    // let navigate = useNavigate();

    // useEffect( setUserSubRecs(userSubs);
    useEffect(() => {
        if (userSubs) {
            setUserSubRecs(userSubs);
        }
    }, [userSubs]);

    // useEffect( setSubPaymentViewRecs(subPaymentViews);
    useEffect(() => {
        if (subPaymentViews) {
            setSubPaymentViewRecs(subPaymentViews);
        }
    }, [subPaymentViews]);

    // useEffect( setAmpUserSubscriber(props.ampUserSubscriber);
    useEffect(() => {
        if (props?.ampUserSubscriber) {
            setAmpUserSubscriber(props.ampUserSubscriber);
        }
    }, [props.ampUserSubscriber]);

    // useEffect(() => {
    //     if (userSubRecs && subPaymentViewRecs) {
    //         const matchedRecs = userSubRecs.filter((userSubRec) => {
    //             return subPaymentViewRecs.some((subPaymentViewRec) => {
    //                 return userSubRec.id === subPaymentViewRec.id;
    //             });
    //         });
    //         setMatchedRecs(matchedRecs);
    //     }
    // }, [userSubRecs, subPaymentViewRecs]);

    // TODO - ADD COUPONS ATTRS IF ANY WERE USED

    // useEffect( setMatchedRecs( based on available sub or payment view recs
    useEffect(() => {
        if (userSubRecs && subPaymentViewRecs) {
            const matchedRecs = userSubRecs.reduce((acc, userSubRec) => {
                subPaymentViewRecs.forEach((subPaymentViewRec) => {
                    if (userSubRec.id === subPaymentViewRec.usersubID) {
                        acc.push({
                            userSubId: userSubRec.id,
                            subPaymentViewId: subPaymentViewRec.id,
                            amount: subPaymentViewRec.amount,
                            paymentSuccess: subPaymentViewRec.paymentSuccess,
                            currency: subPaymentViewRec.currency,
                            receiptURL: subPaymentViewRec.receiptURL,
                            subBegin: userSubRec.begin,
                            subEnd: userSubRec.end,
                            subscriptionType: userSubRec.subscriptionType
                        });
                    }
                });
                return acc;
            }, []);
            setMatchedRecs(matchedRecs);
        }
        else if (userSubRecs && !subPaymentViewRecs) {
            const nonMatchedUserSubAttrsObjs = userSubRecs.map((userSubRec) => {
                return {
                    userSubId: userSubRec.id,
                    subPaymentViewId: '',
                    amount: '',
                    paymentSuccess: '',
                    currency: '',
                    receiptURL: '',
                    subBegin: userSubRec.begin,
                    subEnd: userSubRec.end,
                    subscriptionType: userSubRec.subscriptionType
                };
            });
            setMatchedRecs(nonMatchedUserSubAttrsObjs);
        }
        else if (!userSubRecs && subPaymentViewRecs) {
            // TODO - ? USE SUB END VAL FROM AMPUSERSUBSCRIBER HERE ?
            const nonMatchedSubPaymentViewRecsAttrsObjs = subPaymentViewRecs.map((subPaymentViewRec) => {
                return {
                    userSubId: '',
                    subPaymentViewId: subPaymentViewRec.id,
                    amount: subPaymentViewRec.amount,
                    paymentSuccess: subPaymentViewRec.paymentSuccess,
                    currency: subPaymentViewRec.currency,
                    receiptURL: subPaymentViewRec.receiptURL,
                    subBegin: '',
                    subEnd: '',
                    subscriptionType: ''
                };
            });
            setMatchedRecs(nonMatchedSubPaymentViewRecsAttrsObjs);
        }
    }, [userSubRecs, subPaymentViewRecs]);

    // useEffect( setAccordionHeaderText(headerText);
    useEffect(() => {
        if (headerText) {
            setAccordionHeaderText(headerText);
        }
    }, [headerText]);

    return (
        <StyledAccordion
            expanded={accExpanded}
            onChange={() => {
                setAccExpanded(!accExpanded);
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <StyledCardContentTypography
                    text={accordionHeaderText || 'User Subscriptions:'}
                />
            </AccordionSummary>
            <AccordionDetails>
                <div>
                    {matchedRecs.map((matchedRec, index) => (
                        <div key={`${accordionHeaderText}-${matchedRec.userSubId}-${index}`}>
                            <StyledListCard
                                // onClick={() => {
                                //     navigate(`/user-sub-info/${userSubRec.routerPath}`, {state: {userSubRec}});
                                // }}
                            >
                                {matchedRec?.subscriptionType ? (
                                    <StyledCardContentTypography text={`Type: ${matchedRec?.subscriptionType ? toTitleCase(matchedRec.subscriptionType) : '-'}`} />
                                ) : null}
                                {matchedRec?.amount ? (
                                    <StyledCardContentTypography text={`Amount: ${matchedRec?.amount ? toDollarString(matchedRec.amount) : '-'}`} />
                                ) : null}
                                {matchedRec?.currency ? (
                                    <StyledCardContentTypography text={`Currency: ${matchedRec?.currency ? matchedRec.currency.toUpperCase() : '-'}`} />
                                ) : null}
                                {matchedRec?.paymentSuccess ? (
                                    <StyledCardContentTypography text={`Payment Success: ${matchedRec?.paymentSuccess ? toTitleCase(matchedRec.paymentSuccess.toString()) : '-'}`} />
                                ) : null}
                                {matchedRec?.subBegin ? (
                                    <StyledCardContentTypography text={`Begin: ${matchedRec?.subBegin ? convertTimestampToDate(matchedRec.subBegin) : '-'}`} />
                                ) : null}
                                {matchedRec?.subEnd ? (
                                    <StyledCardContentTypography text={`End: ${matchedRec?.subEnd ? convertTimestampToDate(matchedRec.subEnd) : '-'}`} />
                                ) : null}
                                {matchedRec?.receiptURL ? (
                                    <BaseStyledButton
                                        size={'medium'}
                                        style={{
                                            marginBottom: '10px',
                                            marginTop: '10px',
                                        }}
                                        label={'View Receipt'}
                                        onClick={() => window.open(matchedRec.receiptURL, '_blank')}
                                    />
                                ) : null}
                            </StyledListCard>
                            <Divider />
                        </div>
                    ))}
                </div>
            </AccordionDetails>
        </StyledAccordion>
    )
}
