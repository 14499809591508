import React, {useState, useEffect} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from "@mui/material/Divider";
import {dateFromAWSDateTime} from "../../../utils/datesTimes";

export default function DeleteApptNoteConfirmationDialog({
                                                             open,
                                                             handleClose,
                                                             handleConfirm,
                                                             modelRecToDelete,
                                                             showLogs,
                                                             apptRec
                                                         }) {
    const [modelRec, setModelRec] = useState(null);
    const [relatedApptRec, setRelatedApptRec] = useState(null);

    // useEffect( setModelRec(modelRecToDelete); setRelatedApptRec(apptRec);
    useEffect(() => {
        if (modelRecToDelete) {
            setModelRec(modelRecToDelete);
        }
        if (apptRec) {
            setRelatedApptRec(apptRec);
        }
    }, [modelRecToDelete, apptRec]);

    // useEffect( console.log( relatedApptRec, modelRec
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('DeleteAnimalNoteConfirmationDialog modelRec', modelRec);
    //         console.log('DeleteAnimalNoteConfirmationDialog relatedApptRec', relatedApptRec);
    //     }
    // }, [showLogs, relatedApptRec, modelRec]);

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{"Confirm Delete Action"}</DialogTitle>
            <DialogContent>
                {modelRec && relatedApptRec ? (
                    <div>
                        {/*<Typography>*/}
                        {/*    Animal Name: {relatedAnimalRec.name}*/}
                        {/*</Typography>*/}
                        <Typography>
                            Created At: {dateFromAWSDateTime(modelRec.createdAt)}
                        </Typography>
                        <Typography>
                            Note: {modelRec.note}
                        </Typography>
                        <Divider/>
                        <div>
                            <DialogContentText>
                                Are you sure you want to delete this appointment note?
                            </DialogContentText>
                        </div>
                    </div>
                ) : (
                    <Typography>
                        No record found
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                {/*{modelRec && relatedApptRec ? (*/}
                {/*    <Button onClick={() => {*/}
                {/*        handleConfirm(modelRec)*/}
                {/*    }} autoFocus>*/}
                {/*        Confirm*/}
                {/*    </Button>*/}
                {/*) : null }*/}
                <Button onClick={handleConfirm} autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}

// export default DeleteApptNoteConfirmationDialog;
