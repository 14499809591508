import React, {useState, useCallback, useEffect} from 'react';
import SubPaymentViewContext from "../../../contexts/SubPaymentViewContext";
import { DataStore } from '@aws-amplify/datastore';
import { SubPaymentView } from '../../../../models';
import {SortDirection} from "aws-amplify";

const SubPaymentViewProvider = ({ children, showLogs, ...props }) => {
    const [subPaymentViewRecs, setSubPaymentViewRecs] = useState(null);
    const [latestSubPaymentViewRec, setLatestSubPaymentViewRec] = useState(null);
    const [ampUserSubscriberRec, setAmpUserSubscriberRec] = useState(null);
    const [userSubIdsArr, setUserSubIdsArr] = useState([]);
    const [userSubRecId, setUserSubRecId] = useState(null);

    // useEffect( setAmpUserSubscriberRec(props.ampUserSubscriberProp);
    useEffect(() => {
        if (props?.ampUserSubscriberProp) {
            setAmpUserSubscriberRec(props.ampUserSubscriberProp);
        }
    }, [props.ampUserSubscriberProp]);

    // useEffect( setUserSubIdsArr(props.userSubIdsArrProp);
    // useEffect(() => {
    //     if (props?.userSubIdsArrProp) {
    //         setUserSubIdsArr(props.userSubIdsArrProp);
    //     }
    // }, [props.userSubIdsArrProp]);

    // useEffect( console.log( ampUserSubscriberRec
    useEffect(() => {
        if (showLogs) {
            console.log('ampUserSubscriberRec', ampUserSubscriberRec);
        }
    }, [ampUserSubscriberRec, showLogs]);

    // useEffect( console.log( subPaymentViewRecs, latestSubPaymentViewRec
    useEffect(() => {
        if (showLogs) {
            console.log('subPaymentViewRecs', subPaymentViewRecs);
            console.log('latestSubPaymentViewRec', latestSubPaymentViewRec);
        }
    }, [subPaymentViewRecs, latestSubPaymentViewRec, showLogs]);

    // useEffect( console.log( userSubIdsArr, userSubRecId
    useEffect(() => {
        if (showLogs) {
            console.log('userSubIdsArr', userSubIdsArr);
            console.log('userSubRecId', userSubRecId);
        }
    }, [userSubIdsArr, userSubRecId, showLogs]);

    useEffect(() => {
        if (ampUserSubscriberRec) {
            const subscription = DataStore.observeQuery(SubPaymentView,
                (a => a.ampusersubscriberID.eq(ampUserSubscriberRec.id)),
                {
                    sort: s => s.createdAt(SortDirection.DESCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    if (showLogs) {
                        console.log(`SubPaymentViewProvider a [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                        console.log('SubPaymentViewProvider a [Snapshot] items', items);
                    }
                    if (isSynced) {
                        setSubPaymentViewRecs(items);
                        if (items.length > 0) {
                            setLatestSubPaymentViewRec(items[0]);
                        }
                    }
                });
            return () => subscription.unsubscribe();
        }
        else if (userSubIdsArr && userSubIdsArr.length > 0) {
            const subscription = DataStore.observeQuery(SubPaymentView,
                (a) => a.or((a) =>
                    userSubIdsArr.map(usId => a.usersubID.eq(usId))
                ),
                    {
                    sort: s => s.createdAt(SortDirection.DESCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    if (showLogs) {
                        console.log(`SubPaymentViewProvider b [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                        console.log('SubPaymentViewProvider b [Snapshot] items', items);
                    }
                    if (isSynced) {
                        setSubPaymentViewRecs(items);
                        if (items.length > 0) {
                            setLatestSubPaymentViewRec(items[0]);
                        }
                    }
                });
            return () => subscription.unsubscribe();
        }
        else if (userSubRecId) {
            const subscription = DataStore.observeQuery(SubPaymentView,
                (a => a.usersubID.eq(userSubRecId)),
                {
                    sort: s => s.createdAt(SortDirection.DESCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    if (showLogs) {
                        console.log(`SubPaymentViewProvider c [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                        console.log('SubPaymentViewProvider c [Snapshot] items', items);
                    }
                    if (isSynced) {
                        setSubPaymentViewRecs(items);
                        if (items.length > 0) {
                            setLatestSubPaymentViewRec(items[0]);
                        }
                    }
                });
            return () => subscription.unsubscribe();
        }
    }, [ampUserSubscriberRec, userSubRecId, userSubIdsArr, showLogs]);

    return (
        <SubPaymentViewContext.Provider value={{
            subPaymentViewRecs,
            latestSubPaymentViewRec,
            setAmpUserSubscriberRec,
            setUserSubIdsArr,
            setUserSubRecId,
            showLogs
        }}>
            {children}
        </SubPaymentViewContext.Provider>
    );
}

export default SubPaymentViewProvider;
