import { useEffect, useMemo } from 'react';

// export const useConsoleError = (showLogs, objToLog, label = 'Log') => {
//     useEffect(() => {
//         if (showLogs) {
//             console.error(label, objToLog);
//         }
//     }, [showLogs, objToLog, label]);
// };

// export const useConsoleError = (showLogs, logs) => {
//     useEffect(() => {
//         if (showLogs) {
//             logs.forEach(({ label, value }) => {
//                 if (label) {
//                     console.error(label, value);
//                 } else {
//                     console.error(value);
//                 }
//             });
//         }
//     // }, [showLogs, ...logs.map(log => log.value)]);
//     }, [showLogs, JSON.stringify(logs)]);
// };

export const useConsoleError = (showLogs, logs) => {
    const dependencies = useMemo(() => logs.map(log => log.value), [logs]);

    useEffect(() => {
        if (showLogs) {
            logs.forEach(({ label, value }) => {
                if (label) {
                    console.error(label, value);
                } else {
                    console.error(value);
                }
            });
        }
    }, [showLogs, ...dependencies]);
};

// export const useConsoleLog = (showLogs, objToLog, label = 'Log') => {
//     useEffect(() => {
//         if (showLogs) {
//             console.log(label, objToLog);
//         }
//     }, [showLogs, objToLog, label]);
// };

// export const useConsoleLog = (showLogs, logs) => {
//     useEffect(() => {
//         if (showLogs) {
//             logs.forEach(({ label, value }) => {
//                 if (label) {
//                     console.log(label, value);
//                 } else {
//                     console.log(value);
//                 }
//             });
//         }
//     // }, [showLogs, ...logs.map(log => log.value)]);
//     }, [showLogs, JSON.stringify(logs)]);
// };

export const useConsoleLog = (showLogs, logs) => {
    const dependencies = useMemo(() => logs.map(log => log.value), [logs]);

    useEffect(() => {
        if (showLogs) {
            logs.forEach(({ label, value }) => {
                if (label) {
                    console.log(label, value);
                } else {
                    console.log(value);
                }
            });
        }
    }, [showLogs, ...dependencies]);
};

// export const useConsoleWarn = (showLogs, objToLog, label = 'Log') => {
//     useEffect(() => {
//         if (showLogs) {
//             console.warn(label, objToLog);
//         }
//     }, [showLogs, objToLog, label]);
// };

// export const useConsoleWarn = (showLogs, logs) => {
//     useEffect(() => {
//         if (showLogs) {
//             logs.forEach(({ label, value }) => {
//                 if (label) {
//                     console.warn(label, value);
//                 } else {
//                     console.warn(value);
//                 }
//             });
//         }
//     // }, [showLogs, ...logs.map(log => log.value)]);
//     }, [showLogs, JSON.stringify(logs)]);
// };

export const useConsoleWarn = (showLogs, logs) => {
    const dependencies = useMemo(() => logs.map(log => log.value), [logs]);

    useEffect(() => {
        if (showLogs) {
            logs.forEach(({ label, value }) => {
                if (label) {
                    console.warn(label, value);
                } else {
                    console.warn(value);
                }
            });
        }
    }, [showLogs, ...dependencies]);
};
