import React, {useEffect, useState} from 'react';
import {RequireAuth} from '../../auth/RequireAuth';
import DevStateProvider from "../../../data/providers/DevStateProvider";
import {RequireSub} from "../../auth/RequireSub";
import AmpUserPublicClientsScreen from "../../../screens/AmpUserPublicClientsScreen";
import AmpUserClientsDataProvider from "../../../data/providers/people/AmpUserClientsDataProvider";
import {AmpUserPublicClientsProvider} from "../../../data/providers/people/AmpUserPublicClientsProvider";


export default function WrappedAmpUserPublicClients({routerPaths, ampUser, showLogs, resetProvidersStates}) {
    const [ampUserRec, setAmpUserRec] = useState(null);
    const [localShowLogs, setLocalShowLogs] = useState(false);

    // useEffect( setAmpUserRec(ampUser);
    useEffect(() => {
        if (ampUserRec === null && ampUser !== null) {
            setAmpUserRec(ampUser);
        }
    }, [ampUser]);

    // useEffect( setLocalShowLogs(showLogs);
    useEffect(() => {
        if (showLogs !== null) {
            setLocalShowLogs(showLogs);
        }
    }, [showLogs]);

    // useEffect( console.log( ampUserRec
    useEffect(() => {
        if (localShowLogs) {
            console.log('WrappedAmpUserPublicClients ampUserRec', ampUserRec);
        }
    }, [ampUserRec, localShowLogs]);

    return (
        <DevStateProvider>
            <RequireAuth>
                {ampUser ? (
                    <RequireSub ampUser={ampUser}>
                        <AmpUserClientsDataProvider ampUserId={ampUser.id} showLogs={showLogs}
                                                    resetProviderState={resetProvidersStates}>
                            <AmpUserPublicClientsProvider ampUserIdProp={ampUser.id} showLogs={showLogs}
                                                          resetProviderState={resetProvidersStates}>
                                <AmpUserPublicClientsScreen
                                    routerPaths={routerPaths}
                                    ampUser={ampUser}
                                />
                            </AmpUserPublicClientsProvider>
                        </AmpUserClientsDataProvider>
                    </RequireSub>
                ) : (
                    <div><h2>Loading Public Client Data...</h2></div>
                )}
            </RequireAuth>
        </DevStateProvider>
    );
}
