import React, {useState, useEffect} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
    Typography, Stack, Grid
} from '@mui/material';
import StyledNoteTextField from "../../text/StyledNoteTextField";
import {animalNoteFormObj} from "../../../utils/formObjects";
import {createAnimalNoteRec} from "../../../data/create/animal/createAnimalRecs";
import {animalNoteDialogFinalStepSet} from "../../buttons/sets/animalButtonSets";

// import {updateClientDialogFinalStepSet} from "../../buttons/sets/clientButtonSets";
// import {createClientNote} from "../../../data/create/person/ClientNote";

export default function UpdateAnimalNoteDialog({
                                                   openProp,
                                                   ampUser,
                                                   handleCloseProp,
                                                   animalRec,
                                                   handleCreateDoneProp,
                                                   showLogs,
                                                   ...props
                                               }) {
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(animalNoteFormObj);
    const [animalNote, setAnimalNote] = useState(null);
    const [editedAnimalNoteFormVals, setEditedAnimalNoteFormVals] = useState(animalNoteFormObj);
    const [animalNoteFormValChanged, setAnimalNoteFormValChanged] = useState(false);

    const [isCreatingNote, setIsCreatingNote] = useState(false);
    const [createAnimalNoteResObj, setCreateAnimalNoteResObj] = useState(null);
    const [createNoteError, setCreateNoteError] = useState(null);

    const [activeStep, setActiveStep] = useState(0);
    const [finalStepButtonsObj, setFinalStepButtonsObj] = useState(null);
    const [finalStepDialogTitleText, setFinalStepDialogTitleText] = useState(null);

    // useEffect( if (openProp) { setOpen(true);
    useEffect(() => {
        if (openProp) {
            setOpen(true);
        }
    }, [openProp]);

    // useEffect( if (clientRec) { setFinalStepButtonsObj(updateClientDialogFinalStepSet(clientRec)); setActiveStep(1);
    useEffect(() => {
        if (animalRec) {
            setFinalStepButtonsObj(animalNoteDialogFinalStepSet(animalRec));
            setActiveStep(1);
        }
    }, [animalRec]);

    // useEffect( setCreateNoteError(createClientNoteResObj.error);
    useEffect(() => {
        if (!createAnimalNoteResObj?.success && createAnimalNoteResObj?.error) {
            setCreateNoteError(createAnimalNoteResObj.error);
        }
    }, [createAnimalNoteResObj]);

    // useEffect( if (editedClientNoteFormVals && editedClientNoteFormVals?.note) { if (editedClientNoteFormVals.note.length > 0) { setClientNoteFormValChanged(true); } else { setClientNoteFormValChanged(false); }
    useEffect(() => {
        if (editedAnimalNoteFormVals && editedAnimalNoteFormVals?.note) {
            if (editedAnimalNoteFormVals.note.length > 0) {
                setAnimalNoteFormValChanged(true);
            } else {
                setAnimalNoteFormValChanged(false);
            }
        }
    }, [editedAnimalNoteFormVals]);

    const handleAnimalNoteTextFieldChange = (event) => {
        setFormValues({...formValues, note: event.target.value})
        setEditedAnimalNoteFormVals({...editedAnimalNoteFormVals, note: event.target.value})
        setAnimalNote(event.target.value);
    };

    const handleClose = () => {
        if (showLogs) console.log('### CreateAnimalNoteDialog handleClose');
        if (handleCloseProp) {
            if (!isCreatingNote) {
                if (handleCreateDoneProp) {
                    handleCreateDoneProp(createAnimalNoteResObj);
                }
                handleCloseProp();
            }
        }
    }

    const handleCancel = () => {
        setActiveStep(0);
        handleClose();
    };

    const handleConfirmCreateAnimalNote = async () => {
        setIsCreatingNote(true);
        const createStartTime = Date.now();

        try {
            const [createNoteResponse] = await Promise.all([
                createAnimalNoteRec(animalNote, animalRec.id, ampUser.id, showLogs),
                new Promise(resolve => setTimeout(resolve, 1000))
            ]);

            setCreateAnimalNoteResObj(createNoteResponse);

        } catch (error) {
            console.error('Note creation failed:', error);
            setCreateNoteError(error);
            // Handle error (e.g., show error message to user)
        } finally {
            const createDuration = Date.now() - createStartTime;
            if (createDuration < 1000) {
                await new Promise(resolve => setTimeout(resolve, 1000 - createDuration));
            }
            setIsCreatingNote(false);
            setActiveStep((prevStep) => prevStep + 1);
        }
    };

    const renderCreateAnimalNoteStep = () => (
        <>
            <DialogTitle>Create Animal Note</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Write animal note
                </DialogContentText>
                <div>
                    <StyledNoteTextField
                        id={`create-animal-note-note-field-id`}
                        label="Note"
                        maxRows={16}
                        value={editedAnimalNoteFormVals?.note || ''}
                        onChange={handleAnimalNoteTextFieldChange}
                        sx={{width: '100%'}}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleCancel}
                    disabled={isCreatingNote}
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                    disabled={isCreatingNote}
                >
                    Close
                </Button>
                <Button
                    onClick={() => setActiveStep(2)}
                    variant="contained"
                    color="primary"
                    disabled={!animalNoteFormValChanged || isCreatingNote}
                >
                    Create
                </Button>
            </DialogActions>
        </>
    )

    // Step 2 if client fields to update, otherwise step 1
    const renderConfirmAnimalNoteStep = () => (
        <>
            <DialogTitle>Confirm Animal Note</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please review the following note to be added:
                </DialogContentText>
                <Typography variant="body1" style={{margin: '20px 0', padding: '10px', border: '1px solid #ccc'}}>
                    {animalNote}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleCancel}
                    disabled={isCreatingNote}
                >
                    Cancel
                </Button>
                <Button onClick={async () => await handleConfirmCreateAnimalNote()} variant="contained" color="primary"
                        disabled={isCreatingNote}>
                    {isCreatingNote ? (
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px'}}>
                            <CircularProgress size={24}/>
                            <DialogContentText style={{marginLeft: '20px'}}>
                                Creating Animal note...
                            </DialogContentText>
                        </div>
                    ) : 'Confirm Add Note'}
                </Button>
                <Button
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                    disabled={isCreatingNote}
                >
                    Close
                </Button>
            </DialogActions>
        </>
    )

    // Last step to display outcomes, display nav buttons or close
    const renderFinalStep = () => (
        <>
            <DialogTitle>{finalStepDialogTitleText || 'Create Done'}</DialogTitle>
            <DialogContent>
                {createAnimalNoteResObj && createNoteError ? (
                    <div>
                        <DialogContentText>
                            Error creating animal note
                        </DialogContentText>
                        <p>{createNoteError}</p>
                    </div>
                ) : null}
                {createAnimalNoteResObj && createAnimalNoteResObj?.success ? (
                    <DialogContentText>
                        Animal note created
                    </DialogContentText>
                ) : null}
            </DialogContent>
            <DialogActions>
                <Stack spacing={2} width="100%">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                            {finalStepButtonsObj.animalInfo}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {finalStepButtonsObj.animals}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {finalStepButtonsObj.clients}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {finalStepButtonsObj.appts}
                        </Grid>
                        {/*<Grid item xs={12} sm={6} md={4}>*/}
                        {/*    {finalStepButtonsObj.animalAppts}*/}
                        {/*</Grid>*/}
                        <Grid item xs={12} sm={6} md={4}>
                            {finalStepButtonsObj.home}
                        </Grid>
                    </Grid>
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        color="primary"
                        fullWidth
                    >
                        Close
                    </Button>
                </Stack>
            </DialogActions>
        </>
    )

    const renderLoadingStep = () => (
        <>
            <DialogTitle>Loading...</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Loading Animal Note Form
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                    disabled={isCreatingNote}
                >
                    Close
                </Button>
                <Button
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                    disabled={isCreatingNote}
                >
                    Cancel
                </Button>
            </DialogActions>
        </>
    );

    return (
        <Dialog open={open} onClose={handleClose}>
            {activeStep === 0 ? renderLoadingStep() :
                activeStep === 1 ? renderCreateAnimalNoteStep() :
                    activeStep === 2 ? renderConfirmAnimalNoteStep() :
                        renderFinalStep()}
        </Dialog>
    );
}
