
export const handleCurrencyTextFieldChangeFormattedUSD = (eventVal) => {
    const resObj = {};
    let input = eventVal;

    // Remove all non-numeric characters
    input = input.replace(/[^0-9]/g, '');

    // Convert to integer
    const intValue = parseInt(input, 10);

    // Format as US dollars (without decimal)
    const formattedInput = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0
    }).format(intValue);

    // setBalance(formattedInput);
    resObj.displayVal = formattedInput;

    // setFormValues({...formValues, balance: intValue});
    // setUpdatedClientFormVals({...updatedClientFormVals, balance: intValue});
    resObj.intVal = intValue;

    return resObj;
};

export function toDollarString(amount) {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(amount / 100);
}

export function toTitleCase(str) {
    return str.toLowerCase().split(' ').map(function(word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ');
}

export function camelCaseToTitleCase(camelCaseStr) {
    // Check if the string is all lowercase
    if (camelCaseStr === camelCaseStr.toLowerCase()) {
        return camelCaseStr.charAt(0).toUpperCase() + camelCaseStr.slice(1);
    }
    // Use a regular expression to insert a space before each uppercase letter
    let result = camelCaseStr.replace(/([A-Z])/g, ' $1');
    // Capitalize the first letter of the resulting string
    result = result.charAt(0).toUpperCase() + result.slice(1);
    return result;
}

export function textFieldValToInteger(val) {
    if (val === '') {
        return '';
    }
    return parseInt(val, 10);
}

export function textFieldValToIntegerHandleNaN(val) {
    // if (val === '') {
    //     return 0; // Return 0 when the input is an empty string
    // }
    // const parsedInt = parseInt(val, 10);
    // return isNaN(parsedInt) ? 0 : parsedInt; // Return 0 if the parsed value is NaN
    if (val === '') {
        return ''; // Return empty string when the input is empty
    }
    const parsedInt = parseInt(val, 10);
    return isNaN(parsedInt) ? '' : parsedInt; // Return empty string if the parsed value is NaN
}

const newSubProgressMessagesArrBase = [
    'Updating your user profile...',
    'Creating your new subscription...',
];

const newSubProgressMessagesArrSuccess = [
    'All done!',
];

const newSubProgressMessagesArrTemp = [
    'Just a few more moments...',
];

const newSubProgressMessagesArrError = [
    'Uh oh! Something went wrong...',
    "We're working on it, email us at support@solefrog.pm",
];

export const newSubProgressMessagesObj = {
    base: newSubProgressMessagesArrBase,
    success: newSubProgressMessagesArrSuccess,
    temp: newSubProgressMessagesArrTemp,
    error: newSubProgressMessagesArrError,
    defaultMsgPeriodMs: 1000,
};

const newApptProgressMessagesArrBase = [
    'Creating your new appointment...',
    'Updating related records...',
];

const newApptProgressMessagesArrSuccess = [
    'All done!',
];

const newApptProgressMessagesArrTemp = [
    'Just a few more moments...',
];

const newApptProgressMessagesArrError = [
    'Uh oh! Something went wrong...',
    "We're working on it, email us at support@solefrog.pm",
];

export const CreateNewApptProgressMessagesObj = {
    title: 'Creating New Appointment',
    base: newApptProgressMessagesArrBase,
    success: newApptProgressMessagesArrSuccess,
    temp: newApptProgressMessagesArrTemp,
    error: newApptProgressMessagesArrError,
    defaultMsgPeriodMs: 1000,
};

export const confirmUpdateDialogTitlesObj = {
    note: {
        default: 'Confirm Update Note',
        client: 'Confirm Update Client Note',
        animal: 'Confirm Update Animal Note',
        appointment: 'Confirm Update Appointment Note',
        shoeTask: 'Confirm Update Shoeing Note',
        trimTask: 'Confirm Update Trim Note',
        ownerNote: 'Confirm Update Owner Note',
    }
}

