import React, { useState, useEffect } from 'react';
import FormControl from "@mui/material/FormControl";
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { AppointmentStatus } from "../../../models";
import { filterClientByName, filterAnimalRecsByAnimalNames } from "../../../data/filter/filterRecs";
import { textFieldValToInteger } from "../../../utils/text";
import { convertToFullInteger, sumTaskAmount, sumTaskDuration } from "../../../utils/numbers";
import { generateUniqueKey } from "../../../utils/helpers";
import StyledTextField from "../../text/StyledTextField";
import BaseStyledButton from "../../buttons/BaseStyledButton";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import CreateShoeTaskFormModal from "../../modals/create/CreateShoeTaskFormModal";
import CreateTrimTaskFormModal from "../../modals/create/CreateTrimTaskFormModal";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import StyledNoteTextField from "../../text/StyledNoteTextField";
import { trimTaskFormObj, shoeTaskFormObj, createApptFormObj } from "../../../utils/formObjects";

// import {useResponsiveViewport} from "../../data/providers/ResponsiveViewportProvider";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
// import StyledListBox from "../boxes/StyledListBox";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function CreateApptScreen(props) {
    const [showLogs, setShowLogs] = useState(false);
    const [routerPaths, setRouterPaths] = useState({});
    const [ampUser, setAmpUser] = useState(null);
    // const [clientID, setClientID] = useState(null);
    // const [apptType, setApptType] = useState(null);
    // const [apptNotes, setApptNotes] = useState(null);
    // const [lastApptNotes, setLastApptNotes] = useState(null);
    // const [lastApptPicLinks, setLastApptPicLinks] = useState([]);
    // const [lastApptVideoLinks, setLastApptVideoLinks] = useState([]);
    // const [thisApptPicLinks, setThisApptPicLinks] = useState([]);
    // const [thisApptVideoLinks, setThisApptVideoLinks] = useState([]);
    // const [horseIds, setHorseIds] = useState([]);
    const [apptNoteIds, setApptNoteIds] = useState([]);
    // const { isSmallScreen, isLargeScreen } = useResponsiveViewport();
    const [apptStatusEnums, setApptStatusEnums] = useState([]);
    const [ampUserClientRecs, setAmpUserClientRecs] = useState([]);
    const [defaultChecked, setDefaultChecked] = useState(false);
    const [selectedAmpUserClient, setSelectedAmpUserClient] = useState(null);
    const [preSelectedAmpUserClient, setPreSelectedAmpUserClient] = useState(null);
    const [selectedClientName, setSelectedClientName] = useState('');

    const [selectedApptStatus, setSelectedApptStatus] = useState('');
    const [personName, setPersonName] = useState([]);
    // const [apptDate, setApptDate] = useState(null);
    const [apptDateTime, setApptDateTime] = useState(null);
    // const [apptTime, setApptTime] = useState(null);

    const [clientAnimalRecs, setClientAnimalRecs] = useState([]);
    const [animalRecsByClientId, setAnimalRecsByClientId] = useState(null);

    const [selectedShoeAnimalRecs, setSelectedShoeAnimalRecs] = useState([]);
    const [selectedTrimAnimalRecs, setSelectedTrimAnimalRecs] = useState([]);
    const [selectedShoeAnimalNames, setSelectedShoeAnimalNames] = useState([]);
    const [selectedTrimAnimalNames, setSelectedTrimAnimalNames] = useState([]);

    const [shoeTaskModalOpen, setShoeTaskModalOpen] = useState(false);
    const [trimTaskModalOpen, setTrimTaskModalOpen] = useState(false);
    const [currentShoeTaskAnimalIndex, setCurrentShoeTaskAnimalIndex] = useState(0);
    const [currentTrimTaskAnimalIndex, setCurrentTrimTaskAnimalIndex] = useState(0);
    const [shoeTaskFormVals, setShoeTaskFormVals] = useState([]);
    const [trimTaskFormVals, setTrimTaskFormVals] = useState([]);

    const [shoeAnimalTaskRecs, setShoeAnimalTaskRecs] = useState([]);
    const [trimAnimalTaskRecs, setTrimAnimalTaskRecs] = useState([]);

    // should be all tasks, with a taskName for each element
    const [tasksForAnimals, setTasksForAnimals] = useState([]);
    const [totalApptAmount, setTotalApptAmount] = useState(0);
    const [totalApptDuration, setTotalApptDuration] = useState(0);

    const [newApptRec, setNewApptRec] = useState(null);
    const [newApptAnimalRecs, setNewApptAnimalRecs] = useState([]);
    // const [newApptNoteRec, setNewApptNoteRec] = useState(null);
    // const [newShoeTaskRecs, setNewShoeTaskRecs] = useState([]);
    // const [newTrimTaskRecs, setNewTrimTaskRecs] = useState([]);
    const [apptTimeVals, setApptTimeVals] = useState({});

    const [apptFormValues, setApptFormValues] = useState(createApptFormObj);
    const [apptNoteFormValues, setApptNoteFormValues] = useState({
        apptNote: '',
    });

    let navigate = useNavigate();

    // const handleShoeTaskOpen = () => setShoeTaskModalOpen(true);
    const handleShoeTaskOpen = () => {
        if (selectedShoeAnimalRecs.length > 0) {
            setShoeTaskModalOpen(true);
        } else {
            alert("No animal shoe tasks to update!");
        }
    };

    const handleTrimTaskOpen = () => {
        if (selectedTrimAnimalRecs.length > 0) {
            setTrimTaskModalOpen(true);
        } else {
            alert("No animal trim tasks to update!");
        }
    };

    const handleShoeTaskClose = () => setShoeTaskModalOpen(false);
    const handleTrimTaskClose = () => setTrimTaskModalOpen(false);

    const theme = useTheme();

    const handleShoeTaskUpdate = (fieldName, fieldVal) => {
        // Create a new array with updates applied only to the targeted item
        const updatedShoeTaskFormVals = shoeTaskFormVals.map((taskForm, index) => {
            if (index === currentShoeTaskAnimalIndex) {
                // Update only the target object, creating a new object for immutability
                return {...taskForm, [fieldName]: fieldVal};
            }
            return taskForm; // Return untouched objects as-is
        });

        // Update the state with the new array, ensuring only one update per call
        setShoeTaskFormVals(updatedShoeTaskFormVals);
    };

    const handleTrimTaskUpdate = (fieldName, fieldVal) => {
        // Create a new array with updates applied only to the targeted item
        const updatedTrimTaskFormVals = trimTaskFormVals.map((taskForm, index) => {
            if (index === currentTrimTaskAnimalIndex) {
                // Update only the target object, creating a new object for immutability
                return {...taskForm, [fieldName]: fieldVal};
            }
            return taskForm; // Return untouched objects as-is
        });

        // Update the state with the new array, ensuring only one update per call
        setTrimTaskFormVals(updatedTrimTaskFormVals);
    };

    // useEffect( setTasksForAnimals(combinedTaskRecs);;
    useEffect(() => {
        const combinedTaskRecs = shoeTaskFormVals.concat(trimTaskFormVals);
        setTasksForAnimals(combinedTaskRecs);
    }, [shoeTaskFormVals, trimTaskFormVals]);

    // useEffect( setApptFormValues({...apptFormValues, animalIds: uniqueAnimalIds});
    useEffect(() => {
        const tasksAnimalIds = tasksForAnimals.map((taskObj, idx) => {
            return taskObj.animalID;
        });
        const uniqueAnimalIds = [...new Set(tasksAnimalIds)];
        setApptFormValues({...apptFormValues, animalIds: uniqueAnimalIds});
    }, [tasksForAnimals]);

    // useEffect( setTotalApptAmount(sumAmount);
    useEffect(() => {
        const sumAmount = tasksForAnimals.reduce((accumulator, currentObj) => {
            return accumulator + currentObj.totalAmount;
        }, 0);
        setTotalApptAmount(sumAmount);
    }, [tasksForAnimals]);

    // useEffect( setTotalApptDuration(sumDuration);
    useEffect(() => {
        const sumDuration = tasksForAnimals.reduce((accumulator, currentObj) => {
            return accumulator + currentObj.totalDuration;
        }, 0);
        setTotalApptDuration(sumDuration);
    }, [tasksForAnimals]);

    // useEffect( console.log( totalApptAmount, totalApptDuration
    useEffect(() => {
        // console.log('BLOCK FOUR');
        if (showLogs) {
            console.log('totalApptAmount', totalApptAmount);
            console.log('totalApptDuration', totalApptDuration);
        }
        // console.log('BLOCK FIVE');
    }, [showLogs, totalApptAmount, totalApptDuration]);

    // useEffect( setApptFormValues({...apptFormValues, amount: totalApptAmount, duration: totalApptDuration
    useEffect(() => {
        setApptFormValues({...apptFormValues,
            amount: totalApptAmount,
            apptAmount: totalApptAmount,
            duration: totalApptDuration,
            apptDuration: totalApptDuration
        });
        }, [totalApptAmount, totalApptDuration]);

    const handleShoeSaveAndUpdateNext = () => {
        // console.log('handleShoeSaveAndUpdateNext');
        const nextIndex = currentShoeTaskAnimalIndex + 1;
        if (nextIndex < selectedShoeAnimalRecs.length) {
            setCurrentShoeTaskAnimalIndex(currentShoeTaskAnimalIndex + 1);
        } else {
            setCurrentShoeTaskAnimalIndex(0);
        }
    };

    const handleTrimSaveAndUpdateNext = () => {
        // console.log('handleTrimSaveAndUpdateNext');
        const nextIndex = currentTrimTaskAnimalIndex + 1;
        if (nextIndex < selectedTrimAnimalRecs.length) {
            setCurrentTrimTaskAnimalIndex(currentTrimTaskAnimalIndex + 1);
        } else {
            setCurrentTrimTaskAnimalIndex(0);
        }
    };

    const handleShoeSaveAndUpdatePrev = () => {
        const nextIndex = currentShoeTaskAnimalIndex - 1;
        if (nextIndex > -1) {
            setCurrentShoeTaskAnimalIndex(currentShoeTaskAnimalIndex - 1);
        } else {
            const maxIndex = selectedShoeAnimalRecs.length - 1;
            setCurrentShoeTaskAnimalIndex(maxIndex);
        }
    };

    const handleTrimSaveAndUpdatePrev = () => {
        const nextIndex = currentTrimTaskAnimalIndex - 1;
        if (nextIndex > -1) {
            setCurrentTrimTaskAnimalIndex(currentTrimTaskAnimalIndex - 1);
        } else {
            const maxIndex = selectedTrimAnimalRecs.length - 1;
            setCurrentTrimTaskAnimalIndex(maxIndex);
        }
    };

    // useEffect( setRouterPaths(props.routerPaths);
    useEffect(() => {
        if (props?.routerPaths) {
            setRouterPaths(props.routerPaths);
        }
    }, [props.routerPaths]);

    // useEffect( setAnimalRecsByClientId(props.animalRecsByClientId);
    useEffect(() => {
        if (props?.animalRecsByClientId) {
            setAnimalRecsByClientId(props.animalRecsByClientId);
        }
    }, [props.animalRecsByClientId]);

    // useEffect( setAmpUserClientRecs(props.ampUserClients);
    useEffect(() => {
        if (props?.ampUserClients) {
            setAmpUserClientRecs(props.ampUserClients);
        }
    }, [props.ampUserClients]);

    // useEffect( setShowLogs( props.showLogs
    useEffect(() => {
        if (props?.showLogs){
            setShowLogs(props.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [props]);

    // useEffect( console.log(ampUser
    useEffect(() => {
        if (showLogs) {
            console.log('ampUser', ampUser);
        }
    }, [showLogs, ampUser]);

    // useEffect( console.log(apptFormValues, apptNoteFormValues
    useEffect(() => {
        if (showLogs) {
            console.log('apptFormValues', apptFormValues);
            console.log('apptNoteFormValues', apptNoteFormValues);
        }
    }, [showLogs, apptFormValues, apptNoteFormValues]);

    // useEffect( console.log(apptDate, apptTime, apptDateTime
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('apptDate', apptDate);
    //         console.log('apptTime', apptTime);
    //         console.log('apptDateTime', apptDateTime);
    //     }
    // }, [showLogs, apptDate, apptTime, apptDateTime]);

    // useEffect( console.log( animalRecsByClientId, ampUserClientRecs
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('animalRecsByClientId', animalRecsByClientId);
    //         console.log('@@@ ampUserClientRecs', ampUserClientRecs)
    //     }
    // }, [showLogs, animalRecsByClientId, ampUserClientRecs]);

    // useEffect( console.log( selectedAmpUserClient, selectedClientName, clientAnimalRecs, selectedAnimalRecs
    // useEffect(() => {
    //     if (showLogs) {
    //         // console.log('selectedAmpUserClient', selectedAmpUserClient);
    //         // console.log('selectedClientName', selectedClientName);
    //         console.log('@@@ clientAnimalRecs', clientAnimalRecs);
    //         // console.log("selectedAnimalRecs", selectedAnimalRecs);
    //     }
    // // }, [showLogs, selectedAmpUserClient, selectedClientName, clientAnimalRecs, selectedAnimalRecs]);
    // }, [showLogs, clientAnimalRecs]);

    // useEffect( console.log( selectedAnimalNames, selectedShoeAnimalNames, shoeTaskFormVals, tasksForAnimals
    useEffect(() => {
        if (showLogs) {
            // console.log('selectedAnimalNames', selectedAnimalNames);
            console.log('selectedShoeAnimalNames', selectedShoeAnimalNames);
            console.log('selectedTrimAnimalNames', selectedTrimAnimalNames);
            console.log('shoeTaskFormVals', shoeTaskFormVals);
            console.log('trimTaskFormVals', trimTaskFormVals);
            console.log('tasksForAnimals', tasksForAnimals);
        }
    }, [showLogs, selectedShoeAnimalNames, selectedTrimAnimalNames, shoeTaskFormVals, trimTaskFormVals, tasksForAnimals]);

    // useEffect setAmpUser(props.ampUser)
    useEffect(() => {
        if (props?.ampUser) {
            setAmpUser(props.ampUser);
        }
        // console.log('CreatePostScreen(props) props.ampUser', props.ampUser);
    }, [props]);

    useEffect(() => {
        if (props?.selectedClientRec) {
            setPreSelectedAmpUserClient(props.selectedClientRec);
            if (ampUserClientRecs.length > 0) {
                const isValidClientName = ampUserClientRecs.some(clientRec => clientRec.name === props.selectedClientRec.name);
                // console.log('$$$ isValidClientName', isValidClientName);
                if (isValidClientName) {
                    setSelectedAmpUserClient(props.selectedClientRec);
                    setSelectedClientName(props.selectedClientRec.name);
                } else {
                    setSelectedAmpUserClient(null);
                    setSelectedClientName('');
                }
            }
        }
    }, [props.selectedClientRec, ampUserClientRecs]);

    // useEffect( console.log('!!! CreateApptScreen selectedAmpUserClient', selectedAmpUserClient);
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('!!! CreateApptScreen selectedAmpUserClient', selectedAmpUserClient);
    //     }
    // }, [showLogs, selectedAmpUserClient]);

    // useEffect( setAnimalStatusEnums(AnimalStatus);
    useEffect(() => {
        if (AppointmentStatus) {
            setApptStatusEnums(AppointmentStatus);
        }
    }, [AppointmentStatus]);

    // setDefaultChecked(bangChecked);
    const handleDefaultCheckboxChange = () => {
        const bangChecked = !defaultChecked;
        setDefaultChecked(bangChecked);
    };

    useEffect(() => {
        if (selectedShoeAnimalNames.length > 0) {
            const shoeAnimalRecs = filterAnimalRecsByAnimalNames(clientAnimalRecs, selectedShoeAnimalNames);
            setSelectedShoeAnimalRecs(shoeAnimalRecs);
            const shoeAnimalRecsTasks = shoeAnimalRecs.map((animalRec, idx) => {
                return {
                    ...animalRec,
                    taskType: 'shoe',
                };
            });
            setShoeAnimalTaskRecs(shoeAnimalRecsTasks);
        } else {
            setShoeAnimalTaskRecs([]);
        }
    }, [selectedShoeAnimalNames, ampUser]);

    useEffect(() => {
        if (selectedTrimAnimalNames.length > 0) {
            const trimAnimalRecs = filterAnimalRecsByAnimalNames(clientAnimalRecs, selectedTrimAnimalNames);
            setSelectedTrimAnimalRecs(trimAnimalRecs);
            const trimAnimalRecsTasks = trimAnimalRecs.map((animalRec, idx) => {
                return {
                    ...animalRec,
                    taskType: 'trim',
                };
            });
            setTrimAnimalTaskRecs(trimAnimalRecsTasks);
        } else {
            setTrimAnimalTaskRecs([]);
        }
    }, [selectedTrimAnimalNames]);

    // useEffect( console.log( shoeAnimalTaskRecs, trimAnimalTaskRecs
    useEffect(() => {
        if (showLogs) {
            console.log('shoeAnimalTaskRecs', shoeAnimalTaskRecs);
            console.log('trimAnimalTaskRecs', trimAnimalTaskRecs);
        }
    }, [showLogs, shoeAnimalTaskRecs, trimAnimalTaskRecs]);

    // TODO - USE AMOUNT, DURATION VALUES FROM AMPUSER AND ANIMAL RECS FOR INITIAL TASK FORM VALS
    // TODO - START HERE, MOVE SUMMED VALUES TO AFTER THEY ARE PART OF NEW FORM VAL, SET STATE THERE
    // useEffect( setShoeTaskFormVals(shoeTaskEmptyFormVals);
    useEffect(() => {
        if (shoeAnimalTaskRecs) {
            // let shoeTasksAmount = 0;
            // let shoeTasksDuration = 0;
            const shoeTaskEmptyFormVals = shoeAnimalTaskRecs.map((animalRec, idx) => {
                const defaultTaskAmt = ampUser.defaultShoeTaskAmount || shoeTaskFormObj.amount;
                const defaultTaskDuration = ampUser.defaultShoeTaskDuration || shoeTaskFormObj.duration;
                const addedTaskAmt = animalRec.addedShoeTaskAmount || shoeTaskFormObj.addedCost;
                const addedTaskDuration = animalRec.addedShoeTaskDuration || shoeTaskFormObj.addedTime;
                const thisTaskAmt = sumTaskAmount(defaultTaskAmt, addedTaskAmt);
                console.log('thisTaskAmt', thisTaskAmt);
                // shoeTasksAmount += thisTaskAmt;
                const thisTaskDur = sumTaskDuration(defaultTaskDuration, addedTaskDuration);
                console.log('thisTaskDur', thisTaskDur);
                // shoeTasksDuration += thisTaskDur;
                return {...shoeTaskFormObj,
                    animalID: animalRec.id,
                    name: animalRec.name,
                    taskName: 'shoe',
                    duration: defaultTaskDuration,
                    amount: defaultTaskAmt,
                    addedCost: addedTaskAmt,
                    addedTime: addedTaskDuration,
                    totalAmount: thisTaskAmt,
                    totalDuration: thisTaskDur,
                };
            })
            // console.log('!!! CHANGED shoeTaskEmptyFormVals', shoeTaskEmptyFormVals);
            setShoeTaskFormVals(shoeTaskEmptyFormVals);
            // setTotalApptAmount(shoeTasksAmount);
            // setTotalApptDuration(shoeTasksDuration);
        }
    }, [shoeAnimalTaskRecs]);

    // useEffect( setTrimTaskFormVals(trimTaskEmptyFormVals);
    useEffect(() => {
        if (trimAnimalTaskRecs) {
            // let trimTasksAmount = 0;
            // let trimTasksDuration = 0;
            const trimTaskEmptyFormVals = trimAnimalTaskRecs.map((animalRec, idx) => {
                const defaultTaskAmt = ampUser.defaultTrimTaskAmount || trimTaskFormObj.amount;
                const defaultTaskDuration = ampUser.defaultTrimTaskDuration || trimTaskFormObj.duration;
                const addedTaskAmt = animalRec.addedTrimTaskAmount || trimTaskFormObj.addedCost;
                const addedTaskDuration = animalRec.addedTrimTaskDuration || trimTaskFormObj.addedTime;
                const thisTaskAmt = sumTaskAmount(defaultTaskAmt, addedTaskAmt);
                console.log('thisTaskAmt', thisTaskAmt);
                // trimTasksAmount += thisTaskAmt;
                const thisTaskDur = sumTaskDuration(defaultTaskDuration, addedTaskDuration);
                console.log('thisTaskDur', thisTaskDur);
                // trimTasksDuration += thisTaskDur;
                return {...trimTaskFormObj,
                    animalID: animalRec.id,
                    name: animalRec.name,
                    taskName: 'trim',
                    duration: defaultTaskDuration,
                    amount: defaultTaskAmt,
                    addedCost: addedTaskAmt,
                    addedTime: addedTaskDuration,
                    totalAmount: thisTaskAmt,
                    totalDuration: thisTaskDur,
                };
            })
            // console.log('!!! CHANGED trimTaskEmptyFormVals', trimTaskEmptyFormVals);
            setTrimTaskFormVals(trimTaskEmptyFormVals);
        }
    }, [trimAnimalTaskRecs]);

    // setApptFormValues({...apptFormValues, [updatedFormField]: updatedFieldValue});
    function updateApptFormValues(updatedFormField, updatedFieldValue) {
        // if (updatedFormField === 'amount') {
        //     updatedFieldValue = convertToFullInteger(updatedFieldValue);
        // }
        setApptFormValues({...apptFormValues, [updatedFormField]: updatedFieldValue});
    }

    // useEffect( if (selectedAmpUserClient) setApptFormValues(
    useEffect(() => {
        // console.log('<><><> selectedAmpUserClient', selectedAmpUserClient);
        if (selectedAmpUserClient) {
            // updateDefaultFormValuesFromSelectedClient();
            setApptFormValues(prevValues => ({
                ...prevValues,
                clientId: selectedAmpUserClient.id,
                city: selectedAmpUserClient.city || '',
                streetAddress: selectedAmpUserClient.streetAddress || '',
                state: selectedAmpUserClient.state || '',
                zipCode: selectedAmpUserClient.zipCode || '',
                gateCode: selectedAmpUserClient.gateCode || '',
            }));
        }
        // console.log('<><><> UPDATED FORM VALS', apptFormValues);
    }, [selectedAmpUserClient]);

    // useEffect( setSelectedAmpUserClient(clientRec[0]);
    useEffect(() => {
        let clientRec;
        if (selectedClientName && ampUserClientRecs) {
            // console.log('BLOCK ONE');
            // let clientRec;
            // const isValidClientName = ampUserClientRecs.some(clientRec => clientRec.name === props.selectedClientRec.name);
            if (selectedAmpUserClient && selectedAmpUserClient.name === selectedClientName) {
                // console.log('BLOCK TWO');
                clientRec = selectedAmpUserClient;
            } else {
                console.log('BLOCK THREE');
                const filteredClientRec = filterClientByName(ampUserClientRecs, selectedClientName);
                clientRec = filteredClientRec[0];
            }
            setSelectedAmpUserClient(clientRec);
        }
        // console.log('clientRec', clientRec);
        // console.log('animalRecsByClientId', animalRecsByClientId);
        if (animalRecsByClientId && clientRec) {
            console.log('BLOCK FOUR');
            if (Object.keys(animalRecsByClientId).length > 0) {
                // console.log('BLOCK FIVE');
                const animalRecs = animalRecsByClientId[clientRec.id];
                setClientAnimalRecs(animalRecs);
            }
        }
    }, [selectedClientName, ampUserClientRecs, selectedAmpUserClient, animalRecsByClientId]);

    // useEffect( reset on clientAnimalRecs change
    useEffect(() => {
            setShoeAnimalTaskRecs([]);
            setTrimAnimalTaskRecs([]);
            setSelectedShoeAnimalNames([]);
            setSelectedTrimAnimalNames([]);
            setShoeTaskFormVals([]);
            setTrimTaskFormVals([]);
            setTasksForAnimals([]);
            setTotalApptDuration(0);
            setTotalApptAmount(0);
            setSelectedApptStatus('');
            setCurrentShoeTaskAnimalIndex(0);
            setCurrentTrimTaskAnimalIndex(0);
    }, [clientAnimalRecs]);

    // const handleSelectedAnimalNamesChange = (event)
    const handleSelectedShoeAnimalNamesChange = (event) => {
        // console.log('event', event);
        const { target: { value }, } = event;
        // console.log('value', value);
        // On autofill we get a stringified value.
        const shoeNames = typeof value === 'string' ? value.split(',') : value;
        setSelectedShoeAnimalNames(shoeNames);

        // const animalNamesSet = new Set(selectedAnimalNames);
        // animalNamesSet.add(shoeNames);
        // const animalNamesArr = Array.from(animalNamesSet);
        // setSelectedAnimalNames(animalNamesArr);
    };

    const handleSelectedTrimAnimalNamesChange = (event) => {
        // console.log('event', event);
        const { target: { value }, } = event;
        // console.log('value', value);
        // On autofill we get a stringified value.
        const trimNames = typeof value === 'string' ? value.split(',') : value;
        setSelectedTrimAnimalNames(trimNames);

        // const animalNamesSet = new Set(selectedAnimalNames);
        // animalNamesSet.add(trimNames);
        // const animalNamesArr = Array.from(animalNamesSet);
        // setSelectedAnimalNames(animalNamesArr);
    };

    // handleNoteTextFieldChange setName(event.target.value); setFormValues({...formValues, name: event.target.value})
    const handleNoteTextFieldChange = (event) => {
        setApptNoteFormValues({apptNote: event.target.value});
    };

    function dateTimeFromDateTime(dateTimeVal) {
        const ret = {};
        const dateVal = dateTimeVal.format('YYYY-MM-DD');
        ret['dateVal'] = dateVal;
        const timeVal = dateTimeVal.format('HH:mm:ss');
        ret['timeVal'] = timeVal;
        const formattedDateTime = `${dateVal}T${timeVal}Z`;
        ret['formattedDateTime'] = formattedDateTime;
        return ret;
    }

    function handleApptDateTimeChange(newDateTime) {
        setApptDateTime(newDateTime);
        const dateTimeValsObj = dateTimeFromDateTime(newDateTime);
        setApptTimeVals(dateTimeValsObj);
        const updatedApptFormVals = {
            ...apptFormValues,
            apptDateTime: dateTimeValsObj.formattedDateTime,
            apptDate: dateTimeValsObj.dateVal,
            apptTime: dateTimeValsObj.timeVal,
        };
        setApptFormValues(updatedApptFormVals);
    }

    // useEffect( console.log(apptTimeVals
    // useEffect(() => {
    //     if (apptTimeVals) {
    //         console.log('$$$ apptTimeVals', apptTimeVals);
    //     }
    // }, [apptTimeVals]);

    async function onCreateApptRec() {
        if (apptFormValues.amount !== 0) {
            const amountInt = convertToFullInteger(apptFormValues.amount);
            setApptFormValues({...apptFormValues, amount: amountInt});
        }
        navigate('/create-appt-preview', {state: {
            apptFormValues,
            apptNoteFormValues,
            tasksForAnimals,
        }});
    }

    // useEffect( console.log( newApptRec, newApptAnimalRecs
    // useEffect( console.log( newApptRec, newApptAnimalRecs, newApptNoteRec, newShoeTaskRecs, newTrimTaskRecs
    useEffect(() => {
        if (showLogs) {
            console.log('newApptRec', newApptRec);
            console.log('newApptAnimalRecs', newApptAnimalRecs);
            // console.log('newApptNoteRec', newApptNoteRec);
            // console.log('newShoeTaskRecs', newShoeTaskRecs);
            // console.log('newTrimTaskRecs', newTrimTaskRecs);
        }
    // }, [showLogs, newApptRec, newApptAnimalRecs, newApptNoteRec, newShoeTaskRecs, newTrimTaskRecs]);
    }, [showLogs, newApptRec, newApptAnimalRecs]);

    // TODO - ADD SELECT FOR ANIMAL TRIM TASKS TOO, FOLLOW THROUGH ON UPDATING ANIMAL NAMES AND RECS
    return (
        <div>
            {/*<h2>*/}
            {/*    Create Appt Form*/}
            {/*</h2>*/}
            <Divider>
                <Chip label="Appt. Info"/>
            </Divider>
            <div>
                <FormControl sx={{m: 1, width: 300}}>
                    <InputLabel id={`create-appt-client-input-label`}>Client</InputLabel>
                    <Select
                        labelId={`create-appt-client-label-id`}
                        id={`create-appt-client-select`}
                        value={selectedClientName || ''}
                        onChange={(event) => {
                            setSelectedClientName(event.target.value);
                            setApptFormValues({...apptFormValues, clientName: event.target.value});
                            // setSelectedAmpUserClient(null);
                        }}
                        MenuProps={MenuProps}
                    >
                        {ampUserClientRecs.map((clientRec, index) => (
                            <MenuItem
                                key={`create-animal-clientRec-${index}`}
                                value={clientRec.name}
                            >
                                {clientRec.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div>
                <FormControl sx={{m: 1, width: 300}}>
                    <InputLabel id={`multiple-chip-label-shoe-animal-names`}>Shoe Task Animals</InputLabel>
                    <Select
                        labelId={`multiple-chip-label-shoe-animal-names`}
                        id={`multiple-chip-shoe-animal-names`}
                        multiple
                        disabled={selectedClientName === ''}
                        value={selectedShoeAnimalNames}
                        onChange={handleSelectedShoeAnimalNamesChange}
                        input={<OutlinedInput id={`select-multiple-chip-shoe-animal-names`}
                                              label={`Chip-animal-names`}/>}
                        renderValue={(selected) => (
                            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                {selected.map((value) => (
                                    <Chip key={generateUniqueKey(value)} label={value}/>
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        {clientAnimalRecs.map((eachRec) => (
                            <MenuItem
                                key={generateUniqueKey(eachRec.name)}
                                value={eachRec.name}
                                style={getStyles(eachRec.name, personName, theme)}
                            >
                                {eachRec.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div>
                <BaseStyledButton
                    disabled={selectedShoeAnimalNames.length === 0}
                    onClick={handleShoeTaskOpen}
                    label="Edit Shoe Tasks"
                />
            </div>
            <div>
                <FormControl sx={{m: 1, width: 300}}>
                    <InputLabel id={`multiple-chip-label-trim-animal-names`}>Trim Task Animals</InputLabel>
                    <Select
                        labelId={`multiple-chip-label-trim-animal-names`}
                        id={`multiple-chip-trim-animal-names`}
                        multiple
                        disabled={selectedClientName === ''}
                        value={selectedTrimAnimalNames}
                        onChange={handleSelectedTrimAnimalNamesChange}
                        input={<OutlinedInput id={`select-multiple-chip-trim-animal-names`}
                                              label={`Chip-animal-names`}/>}
                        renderValue={(selected) => (
                            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                {selected.map((value) => (
                                    <Chip key={generateUniqueKey(value)} label={value}/>
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        {clientAnimalRecs.map((eachRec) => (
                            <MenuItem
                                key={generateUniqueKey(eachRec.name)}
                                value={eachRec.name}
                                style={getStyles(eachRec.name, personName, theme)}
                            >
                                {eachRec.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div>
                <BaseStyledButton
                    disabled={selectedTrimAnimalNames.length === 0}
                    onClick={handleTrimTaskOpen}
                    label="Edit Trim Tasks"
                />
            </div>
            <div>
                <FormControl sx={{m: 1, width: 300}}>
                    <InputLabel id={`create-appt-appt-status-input-label`}>Appt. Status</InputLabel>
                    <Select
                        labelId={`create-appt-appt-status-label-id`}
                        id={`create-appt-appt-status-select`}
                        value={selectedApptStatus}
                        onChange={(event) => {
                            setSelectedApptStatus(event.target.value);
                            setApptFormValues({...apptFormValues, appointmentStatus: event.target.value})
                        }}
                        MenuProps={MenuProps}
                    >
                        {Object.entries(apptStatusEnums).map(([key, val]) => (
                            <MenuItem
                                key={generateUniqueKey(key)}  // Use the key for generating a unique key
                                value={val}  // Use the key as the value for the MenuItem
                            >
                                {val}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div>
                <StyledTextField
                    id="street-address"
                    label="Street Address"
                    value={apptFormValues.streetAddress}
                    onChange={(event) => updateApptFormValues('streetAddress', event.target.value)}
                />
            </div>
            <div>
                <StyledTextField
                    id="city"
                    label="City"
                    value={apptFormValues.city}
                    onChange={(event) => updateApptFormValues('city', event.target.value)}
                />
            </div>
            <div>
                <StyledTextField
                    id="state"
                    label="State"
                    value={apptFormValues.state}
                    onChange={(event) => updateApptFormValues('state', event.target.value)}
                />
            </div>
            <div>
                <StyledTextField
                    id="zip-code"
                    label="Zip Code"
                    value={apptFormValues.zipCode}
                    onChange={(event) => updateApptFormValues('zipCode', event.target.value)}
                />
            </div>
            <div>
                <StyledTextField
                    id="amount"
                    label="Amount ($)"
                    type="number"
                    value={totalApptAmount}
                    onChange={(event) => {
                        const amountVal = textFieldValToInteger(event.target.value);
                        setTotalApptAmount(amountVal);
                    }}
                />
            </div>
            <div>
                <StyledTextField
                    id="duration"
                    label="Duration (mins.)"
                    type="number"
                    value={totalApptDuration}
                    onChange={(event) => {
                        const durationVal = textFieldValToInteger(event.target.value);
                        setTotalApptDuration(durationVal);
                    }}
                />
            </div>
            <div>
                <StyledTextField
                    id="gate-code"
                    label="Gate Code"
                    value={apptFormValues.gateCode}
                    onChange={(event) => updateApptFormValues('gateCode', event.target.value)}
                />
            </div>
            <div>
                <StyledNoteTextField
                    id="appt-note"
                    label="Appt. Note"
                    value={apptNoteFormValues.apptNote}
                    onChange={handleNoteTextFieldChange}
                />
            </div>
            <Divider>
                <Chip label="Appt. Date"/>
            </Divider>
            <div>
                <DateTimePicker
                    value={apptDateTime}
                    onChange={(newValue) => handleApptDateTimeChange(newValue)}
                />
            </div>
            {apptFormValues.apptDateTime ? (<div>
                <Typography>
                    {apptFormValues.apptDateTime}
                </Typography>
            </div>) : null}
            {apptFormValues.apptDate ? (<div>
                <Typography>
                    {apptFormValues.apptDate}
                </Typography>
            </div>) : null}
            {apptFormValues.apptTime ? (<div>
                <Typography>
                    {apptFormValues.apptTime}
                </Typography>
            </div>) : null}
            <div>
                <BaseStyledButton
                    label="Create Appointment"
                    onClick={onCreateApptRec}
                />
            </div>
            <div>
                {shoeTaskModalOpen && (
                    <CreateShoeTaskFormModal
                        open={shoeTaskModalOpen}
                        onClose={handleShoeTaskClose}
                        formData={shoeTaskFormVals[currentShoeTaskAnimalIndex]}
                        handleFormChange={handleShoeTaskUpdate}
                        handleSaveAndUpdateNext={handleShoeSaveAndUpdateNext}
                        handleSaveAndUpdatePrev={handleShoeSaveAndUpdatePrev}
                        showLogs={showLogs}
                    />
                )}
            </div>
            <div>
                {trimTaskModalOpen && (
                    <CreateTrimTaskFormModal
                        open={trimTaskModalOpen}
                        onClose={handleTrimTaskClose}
                        formData={trimTaskFormVals[currentTrimTaskAnimalIndex]}
                        handleFormChange={handleTrimTaskUpdate}
                        handleSaveAndUpdateNext={handleTrimSaveAndUpdateNext}
                        handleSaveAndUpdatePrev={handleTrimSaveAndUpdatePrev}
                        showLogs={showLogs}
                    />
                )}
            </div>
        </div>
    );
}
