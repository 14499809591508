import { openDB } from 'idb';
import {
    FarrierAlert,
    FarrierClientAlert,
    FarrierPublicClientAlert,
    FarrierAnimalAlert,
    FarrierPublicAnimalAlert
} from "../../alerts/alertClasses/FarrierAlerts";

class FarrierAlertsDB {
  constructor() {
      this.dbName = 'FarrierAlertsDB';
      this.version = 1;
      this.stores = {
          farrierAnimalAlertsStoreName: 'farrier-animal-alerts',
          farrierPublicAnimalAlertsStoreName: 'farrier-public-animal-alerts',
          farrierClientAlertsStoreName: 'farrier-client-alerts',
          farrierPublicClientAlertsStoreName: 'farrier-public-client-alerts'
      }
      this.db = null;
  }

  async init() {
      try {
          const stores = this.stores;

          this.db = await openDB(this.dbName, this.version, {
              upgrade(db) {
                  // Animal Alerts Store
                  if (!db.objectStoreNames.contains(stores.farrierAnimalAlertsStoreName)) {
                      const animalStore = db.createObjectStore(stores.farrierAnimalAlertsStoreName, { keyPath: 'alertId' });
                      animalStore.createIndex('ampUserId', 'ampUserId', { unique: false });
                      animalStore.createIndex('animalId', 'animalId', { unique: false });
                      animalStore.createIndex('isAcknowledged', 'isAcknowledged', { unique: false });
                      animalStore.createIndex('isPinned', 'isPinned', { unique: false });
                  }
                    // Public Animal Alerts Store
                    if (!db.objectStoreNames.contains(stores.farrierPublicAnimalAlertsStoreName)) {
                        const publicAnimalStore = db.createObjectStore(stores.farrierPublicAnimalAlertsStoreName, { keyPath: 'alertId' });
                        publicAnimalStore.createIndex('ampUserId', 'ampUserId', { unique: false });
                        publicAnimalStore.createIndex('publicAnimalId', 'publicAnimalId', { unique: false });
                        publicAnimalStore.createIndex('isAcknowledged', 'isAcknowledged', { unique: false });
                        publicAnimalStore.createIndex('isPinned', 'isPinned', { unique: false });
                    }
                  // Client Alerts Store
                  if (!db.objectStoreNames.contains(stores.farrierClientAlertsStoreName)) {
                      const clientStore = db.createObjectStore(stores.farrierClientAlertsStoreName, { keyPath: 'alertId' });
                      clientStore.createIndex('ampUserId', 'ampUserId', { unique: false });
                      clientStore.createIndex('clientId', 'clientId', { unique: false });
                      clientStore.createIndex('isAcknowledged', 'isAcknowledged', { unique: false });
                      clientStore.createIndex('isPinned', 'isPinned', { unique: false });
                  }
                  // Public Client Alerts Store
                  if (!db.objectStoreNames.contains(stores.farrierPublicClientAlertsStoreName)) {
                      const publicStore = db.createObjectStore(stores.farrierPublicClientAlertsStoreName, { keyPath: 'alertId' });
                      publicStore.createIndex('ampUserId', 'ampUserId', { unique: false });
                      publicStore.createIndex('publicClientId', 'publicClientId', { unique: false });
                      publicStore.createIndex('isAcknowledged', 'isAcknowledged', { unique: false });
                      publicStore.createIndex('isPinned', 'isPinned', { unique: false });
                  }
              },
          });
          return this.db;
      }
      catch (error) {
          console.error('FarrierAlertsDB Database initialization failed:', error);
          throw new Error(`Failed to initialize FarrierAlertsDB database: ${error.message}`);
      }
  }

    async ensureDB() {
        if (!this.db) {
            await this.init();
        }
    }

    getFarrierAlertClassByStoreName(storeName) {
        switch (storeName) {
            case this.stores.farrierClientAlertsStoreName:
                return FarrierClientAlert;
            case this.stores.farrierPublicClientAlertsStoreName:
                return FarrierPublicClientAlert;
            case this.stores.farrierAnimalAlertsStoreName:
                return FarrierAnimalAlert;
            case this.stores.farrierPublicAnimalAlertsStoreName:
                return FarrierPublicAnimalAlert;
            default:
                return FarrierAlert;
        }
    }

    // Generic add alert method that works with any store
    async addAlertByStoreName(alert, storeName) {
        try {
            await this.ensureDB();
            if (!Object.values(this.stores).includes(storeName)) {
                throw new Error(`Invalid store name: ${storeName}`);
            }
            await this.db.put(storeName, alert.toJSON());
            return alert.alertId;
        } catch (error) {
            console.error(`Error adding alert to ${storeName}:`, error);
            throw new Error(`Failed to add alert to ${storeName}: ${error.message}`);
        }
    }

    // Generic get alert method that works with any store
    async getAlertByIdByStoreName(alertId, storeName) {
        try {
            await this.ensureDB();
            if (!Object.values(this.stores).includes(storeName)) {
                throw new Error(`Invalid store name: ${storeName}`);
            }
            const result = await this.db.get(storeName, alertId);
            const AlertClass = this.getFarrierAlertClassByStoreName(storeName);
            // return result ? FarrierAlert.fromJSON(result) : null;
            return result ? AlertClass.fromJSON(result) : null;
        } catch (error) {
            console.error(`Error getting alert from ${storeName}:`, error);
            throw new Error(`Failed to get alert from ${storeName}: ${error.message}`);
        }
    }

    // Get alerts by user for a specific store
    async getAlertsByAmpUserByStoreName(ampUserId, storeName) {
        try {
            await this.ensureDB();
            if (!Object.values(this.stores).includes(storeName)) {
                throw new Error(`Invalid store name: ${storeName}`);
            }
            const AlertClass = this.getFarrierAlertClassByStoreName(storeName);
            const results = await this.db.getAllFromIndex(storeName, 'ampUserId', ampUserId);
            // return results.map(alert => FarrierAlert.fromJSON(alert));
            return results.map(alert => AlertClass.fromJSON(alert));
        } catch (error) {
            console.error(`Error getting alerts for user from ${storeName}:`, error);
            throw new Error(`Failed to get alerts for user from ${storeName}: ${error.message}`);
        }
    }

    // Get unacknowledged alerts for a specific store
    async getUnAckedAlertsByAmpUserByStoreName(ampUserId, storeName) {
        try {
            await this.ensureDB();
            if (!Object.values(this.stores).includes(storeName)) {
                throw new Error(`Invalid store name: ${storeName}`);
            }
            const AlertClass = this.getFarrierAlertClassByStoreName(storeName);
            const tx = this.db.transaction(storeName, 'readonly');
            const index = tx.store.index('ampUserId');
            const alerts = [];

            let cursor = await index.openCursor(ampUserId);
            while (cursor) {
                if (!cursor.value.isAcknowledged) {
                    // alerts.push(FarrierAlert.fromJSON(cursor.value));
                    alerts.push(AlertClass.fromJSON(cursor.value));
                }
                cursor = await cursor.continue();
            }

            return alerts;
        } catch (error) {
            console.error(`Error getting unacknowledged alerts from ${storeName}:`, error);
            throw new Error(`Failed to get unacknowledged alerts from ${storeName}: ${error.message}`);
        }
    }

    // Get unacknowledged alerts for a specific store
    async getPinnedAlertsByAmpUserByStoreName(ampUserId, storeName) {
        try {
            await this.ensureDB();
            if (!Object.values(this.stores).includes(storeName)) {
                throw new Error(`Invalid store name: ${storeName}`);
            }
            const AlertClass = this.getFarrierAlertClassByStoreName(storeName);
            const tx = this.db.transaction(storeName, 'readonly');
            const index = tx.store.index('ampUserId');
            const alerts = [];

            let cursor = await index.openCursor(ampUserId);
            while (cursor) {
                if (cursor.value.isPinned) {
                    // alerts.push(FarrierAlert.fromJSON(cursor.value));
                    alerts.push(AlertClass.fromJSON(cursor.value));
                }
                cursor = await cursor.continue();
            }

            return alerts;
        } catch (error) {
            console.error(`Error getting pinned alerts from ${storeName}:`, error);
            throw new Error(`Failed to get pinned alerts from ${storeName}: ${error.message}`);
        }
    }

    // Get all unacknowledged alerts across all stores
    async getAllUnAckedAlertsByAmpUser(ampUserId) {
        try {
            await this.ensureDB();
            const allAlerts = [];

            for (const storeName of Object.values(this.stores)) {
                const storeAlerts = await this.getUnAckedAlertsByAmpUserByStoreName(ampUserId, storeName);
                allAlerts.push(...storeAlerts);
            }

            // Sort by createdAt date
            return allAlerts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        } catch (error) {
            console.error(`Error getAllUnAckedAlertsByAmpUser ampUserId: ${ampUserId}, error:`, error);
            throw new Error(`Failed to getAllUnAckedAlertsByAmpUser ampUserId: ${ampUserId}, error: ${error.message}`);
        }
    }

    // Get all unacknowledged alerts across all stores
    async getAllPinnedAlertsByAmpUser(ampUserId) {
        try {
            await this.ensureDB();
            const allAlerts = [];

            for (const storeName of Object.values(this.stores)) {
                const storeAlerts = await this.getPinnedAlerts(ampUserId, storeName);
                allAlerts.push(...storeAlerts);
            }

            // Sort by createdAt date
            return allAlerts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        } catch (error) {
            console.error(`Error getAllPinnedAlertsByAmpUser ampUserId: ${ampUserId}, error:`, error);
            throw new Error(`Failed getAllPinnedAlertsByAmpUser ampUserId: ${ampUserId}, error: ${error.message}`);
        }
    }

    // Acknowledge alert in a specific store
    async acknowledgeAlertByIdByStoreName(alertId, storeName) {
        try {
            await this.ensureDB();
            if (!Object.values(this.stores).includes(storeName)) {
                throw new Error(`Invalid store name: ${storeName}`);
            }

            const tx = this.db.transaction(storeName, 'readwrite');
            const alert = await tx.store.get(alertId);

            if (!alert) {
                throw new Error('Alert not found');
            }
            const AlertClass = this.getFarrierAlertClassByStoreName(storeName);
            // const updatedAlert = FarrierAlert.fromJSON(alert);
            const updatedAlert = AlertClass.fromJSON(alert);
            updatedAlert.acknowledge();
            await tx.store.put(updatedAlert.toJSON());
            await tx.done;

            return updatedAlert;
        } catch (error) {
            console.error(`Error acknowledging alertId: ${alertId}, in store: ${storeName}, error:`, error);
            throw new Error(`Failed to acknowledge alertId: ${alertId}, in store: ${storeName}, error: ${error.message}`);
        }
    }

    // Acknowledge alert in a specific store
    async pinAlertByIdByStoreName(alertId, storeName) {
        try {
            await this.ensureDB();
            if (!Object.values(this.stores).includes(storeName)) {
                throw new Error(`Invalid store name: ${storeName}`);
            }

            const tx = this.db.transaction(storeName, 'readwrite');
            const alert = await tx.store.get(alertId);

            if (!alert) {
                throw new Error('Alert not found');
            }
            const AlertClass = this.getFarrierAlertClassByStoreName(storeName);
            // const updatedAlert = FarrierAlert.fromJSON(alert);
            const updatedAlert = AlertClass.fromJSON(alert);
            updatedAlert.pin();
            await tx.store.put(updatedAlert.toJSON());
            await tx.done;

            return updatedAlert;
        } catch (error) {
            console.error(`Error pinning alertId: ${alertId}, in store: ${storeName}, error:`, error);
            throw new Error(`Failed to pin alertId: ${alertId}, in store: ${storeName}, error: ${error.message}`);
        }
    }

    // Unpin alert in a specific store
    async unPinAlertByIdByStoreName(alertId, storeName) {
        try {
            await this.ensureDB();
            if (!Object.values(this.stores).includes(storeName)) {
                throw new Error(`Invalid store name: ${storeName}`);
            }

            const tx = this.db.transaction(storeName, 'readwrite');
            const alert = await tx.store.get(alertId);

            if (!alert) {
                throw new Error('Alert not found');
            }
            const AlertClass = this.getFarrierAlertClassByStoreName(storeName);
            // const updatedAlert = FarrierAlert.fromJSON(alert);
            const updatedAlert = AlertClass.fromJSON(alert);
            updatedAlert.unPin();
            await tx.store.put(updatedAlert.toJSON());
            await tx.done;

            return updatedAlert;
        } catch (error) {
            console.error(`Error unpinning alertId: ${alertId}, in store: ${storeName}, error:`, error);
            throw new Error(`Failed to unpin alertId: ${alertId}, in store: ${storeName}, error: ${error.message}`);
        }
    }


    // Delete alert from a specific store
    async deleteAlertByIdByStoreName(alertId, storeName) {
        try {
            await this.ensureDB();
            if (!Object.values(this.stores).includes(storeName)) {
                throw new Error(`Invalid store name: ${storeName}`);
            }
            await this.db.delete(storeName, alertId);
            return true;
        } catch (error) {
            console.error(`Error deleteAlertByIdByStoreName alertId: ${alertId}, store: ${storeName}, error:`, error);
            throw new Error(`Failed deleteAlertByIdByStoreName alertId: ${alertId}, store: ${storeName}, error: ${error.message}`);
        }
    }

    // Clear old alerts from a specific store
    async clearOldAlertsByStoreName(daysToKeep = 180, storeName) {
        try {
            await this.ensureDB();
            if (!Object.values(this.stores).includes(storeName)) {
                throw new Error(`Invalid store name: ${storeName}`);
            }

            const cutoffDate = new Date();
            cutoffDate.setDate(cutoffDate.getDate() - daysToKeep);

            const tx = this.db.transaction(storeName, 'readwrite');
            let cursor = await tx.store.openCursor();
            let deletedCount = 0;

            while (cursor) {
                if (new Date(cursor.value.createdAt) < cutoffDate) {
                    await cursor.delete();
                    deletedCount++;
                }
                cursor = await cursor.continue();
            }

            await tx.done;
            return deletedCount;
        } catch (error) {
            console.error(`Error clearing old alerts, days older than: ${daysToKeep}, from store ${storeName}, error:`, error);
            throw new Error(`Failed to clear old alerts, days older than: ${daysToKeep}, from store ${storeName}, error: ${error.message}`);
        }
    }
}

export const farrierAlertsDB = new FarrierAlertsDB();
