import React from 'react';
import CardContent from '@mui/material/CardContent';

const StyledCardContent = ({ children, ...props }) => {
    return (
        <CardContent
            sx={{
                // Add default styles specific to the CardContent component
                // For example, setting padding, margin, etc.
                padding: '0 8px',
                margin: '0 4px',
                '&:last-child': {
                    paddingBottom: 2, // Example bottom padding
                },
                // Add more default styles if necessary

                // Overwrite with props.sx to allow for custom styles
                ...props.sx,
            }}
            {...props} // Spread the rest of the props to the CardContent component
        >
            {children}
        </CardContent>
    );
};

export default StyledCardContent;
