import React, { createContext, useContext, useState, useEffect } from 'react';
import { openDB } from 'idb';
import { useLocation } from 'react-router-dom';

// TODO - START HERE, FR, FR, CREATE NEW BRANCH,
//  USE THIS AND RELATED IDB FILE IN EDIT ANIMAL SCREEN, FORM

// Create the context
const EditAnimalFormContext = createContext();

const dbPromise = openDB('EditFormsDB_v2', 2, {
    upgrade(db) {
        if (!db.objectStoreNames.contains('editAnimalFormData')) {
            db.createObjectStore('editAnimalFormData');
        }
    },
});

async function saveEditAnimalFormData(data) {
    const db = await dbPromise;
    await db.put('editAnimalFormData', data, 'currentEditAnimalForm');
}

async function loadEditAnimalFormData() {
    const db = await dbPromise;
    return await db.get('editAnimalFormData', 'currentEditAnimalForm') || {};
}

async function clearEditAnimalFormData() {
    const db = await dbPromise;
    await db.delete('editAnimalFormData', 'currentEditAnimalForm');
}

// Create the provider component
export function EditAnimalFormProvider({ children }) {
    const [editAnimalFormValues, setEditAnimalFormValues] = useState({});

    const location = useLocation();

    useEffect(() => {
        loadEditAnimalFormData().then(data => setEditAnimalFormValues(data));
    }, []);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            // Persist data before the page unloads (refresh or close)
            saveEditAnimalFormData(editAnimalFormValues);
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [editAnimalFormValues]);

    useEffect(() => {
        const resetOnUnmount = () => {
            // When navigating away from the base route, reset form values
            if (!location.pathname.startsWith('/edit-animal')) {
                setEditAnimalFormValues({});
            }
        };

        return () => {
            resetOnUnmount();
        };
    }, [location]);

    const updateEditAnimalFormValues = (name, value) => {
        setEditAnimalFormValues(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const resetFormValues = () => {
        setEditAnimalFormValues({});
        clearEditAnimalFormData();
    };

    const updateIDBFormValues = () => {
        // Save the current form values to IndexedDB
        saveEditAnimalFormData(editAnimalFormValues);
    };

    const updateMultipleFormValues = (updatedValues) => {
        setEditAnimalFormValues(prev => ({
            ...prev,
            ...updatedValues,
        }));
    };

    return (
        <EditAnimalFormContext.Provider value={{ editAnimalFormValues, updateEditAnimalFormValues, resetFormValues, updateIDBFormValues, updateMultipleFormValues }}>
            {children}
        </EditAnimalFormContext.Provider>
    );
}

// Custom hook to use the context
export function useEditAnimalFormProvider() {
    return useContext(EditAnimalFormContext);
}
