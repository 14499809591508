import React, { createContext, useContext, useState, useEffect } from 'react';
import { openDB } from 'idb';
import { useLocation } from 'react-router-dom';

// Create the context
const EditClientFormContext = createContext();

const dbPromise = openDB('EditFormsDB', 3, {
    upgrade(db) {
        if (!db.objectStoreNames.contains('editClientFormData')) {
            db.createObjectStore('editClientFormData');
        }
    },
});

async function saveEditClientFormData(data) {
    const db = await dbPromise;
    await db.put('editClientFormData', data, 'currentEditClientForm');
}

async function loadEditClientFormData() {
    const db = await dbPromise;
    return await db.get('editClientFormData', 'currentEditClientForm') || {};
}

async function clearEditClientFormData() {
    const db = await dbPromise;
    await db.delete('editClientFormData', 'currentEditClientForm');
}

// Create the provider component
export function EditClientFormProvider({ children }) {
    const [editClientFormValues, setEditClientFormValues] = useState({});

    const location = useLocation();

    useEffect(() => {
        loadEditClientFormData().then(data => setEditClientFormValues(data));
    }, []);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            // Persist data before the page unloads (refresh or close)
            saveEditClientFormData(editClientFormValues);
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [editClientFormValues]);

    useEffect(() => {
        const resetOnUnmount = () => {
            // When navigating away from the base route, reset form values
            if (!location.pathname.startsWith('/edit-client')) {
                setEditClientFormValues({});
            }
        };

        return () => {
            resetOnUnmount();
        };
    }, [location]);

    const updateEditClientFormValues = (name, value) => {
        setEditClientFormValues(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const resetFormValues = () => {
        setEditClientFormValues({});
        clearEditClientFormData();
    };

    const updateIDBFormValues = () => {
        // Save the current form values to IndexedDB
        saveEditClientFormData(editClientFormValues);
    };

    const updateMultipleFormValues = (updatedValues) => {
        setEditClientFormValues(prev => ({
            ...prev,
            ...updatedValues,
        }));
    };

    return (
        <EditClientFormContext.Provider value={{ editClientFormValues, updateEditClientFormValues, resetFormValues, updateIDBFormValues, updateMultipleFormValues }}>
            {children}
        </EditClientFormContext.Provider>
    );
}

// Custom hook to use the context
export function useEditClientFormProvider() {
    return useContext(EditClientFormContext);
}
