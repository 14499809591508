import React from 'react';
import TextField from '@mui/material/TextField';

const StyledTextField = ({ id, label, value, onChange, maxRows, fontSizes, labelFontSizes, helperTextFontSizes, sx, ...props }) => {
    return (
        <TextField
            id={id}
            label={label}
            maxRows={maxRows}
            value={value}
            onChange={onChange}
            variant="outlined"
            // fullWidth
            sx={{
                padding: '0 0.5vw',
                margin: '1vh 0.5vw',
                borderRadius: '4px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                '& .MuiInputBase-input': {
                    fontSize: fontSizes || {
                        xs: '1.5rem', // Default for extra small screens
                        sm: '2rem',   // Default for small screens
                        md: '2.5rem', // Default for medium screens
                        lg: '3rem',   // Default for large screens
                        xl: '3.5rem'  // Default for extra large screens
                    }
                },
                '& .MuiInputLabel-root': {
                    fontSize: labelFontSizes || {
                        xs: '1.25rem', // Default for extra small screens
                        sm: '1.5rem',  // Default for small screens
                        md: '1.75rem', // Default for medium screens
                        lg: '2rem',    // Default for large screens
                        xl: '2.25rem'  // Default for extra large screens
                    }
                },
                '& .MuiFormHelperText-root': {
                    fontSize: helperTextFontSizes || {
                        xs: '1rem',   // Default for extra small screens
                        sm: '1.25rem', // Default for small screens
                        md: '1.5rem',  // Default for medium screens
                        lg: '1.75rem', // Default for large screens
                        xl: '2rem'    // Default for extra large screens
                    }
                },
                ...sx,
            }}
            {...props}
        />
    );
};

export default StyledTextField;
