import React, { useState, useEffect, useContext } from 'react';
import RouterBreadcrumbs from "../../components/router/RouterBreadcrumbs";
import { useResponsiveViewport } from "../../data/providers/ResponsiveViewportProvider";
import EditClientForm from "../../components/forms/edit/EditClientForm";
import { breadcrumbRoutesClientsScreen } from "../../utils/breadcrumbsRoutes";
import ClientApptsContext from "../../data/contexts/ClientApptsContext";
import ClientAnimalsContext from "../../data/contexts/ClientAnimalsContext";
import AmpUserClientNotesContext from "../../data/contexts/AmpUserClientNotesContext";

// TODO - !!! ADD HANDLING FOR CLIENT ADDED APPT AMOUNT, DURATION TO APPT SCREENS, MODEL REC ACTIONS
// TODO - !!! ADD PROVIDER FOR CLIENT PAYMENTS, PASS ALONG, ADD SCREEN TO CLIENT PAYMENTS CRUD ACTIONS

export default function EditClientScreen(props) {
    const [clientRec, setClientRec] = useState(null);
    const [showLogs, setShowLogs] = useState(false);
    const [ampUser, setAmpUser] = useState(null);
    const [breadcrumbRoutes, setBreadcrumbRoutes] = useState([]); // [{path: '', name: ''}, ...
    const [clientApptRecs, setClientApptRecs] = useState([]);
    const [clientAnimalRecs, setClientAnimalRecs] = useState([]);
    const [clientNoteRecs, setClientNoteRecs] = useState([]);
    const [routerPaths, setRouterPaths] = useState({});

    const { isSmallScreen, isLargeScreen } = useResponsiveViewport();

    const {clientApptsRecs} = useContext(ClientApptsContext);
    const {ampUserClientAnimalsRecs} = useContext(ClientAnimalsContext);
    const {ampUserClientNotesRecs} = useContext(AmpUserClientNotesContext);

    // setBreadcrumbRoutes
    useEffect(() => {
        setBreadcrumbRoutes(breadcrumbRoutesClientsScreen() || [])
        // console.error('breadcrumbRoutes', breadcrumbRoutes)
    }, [props]);

    // useEffect setAmpUser(props.ampUser)
    useEffect(() => {
        if (props.ampUser) {
            setAmpUser(props.ampUser);
        }
    }, [props.ampUser]);

    // useEffect( console.log(ampUser
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('ampUser', ampUser);
    //     }
    // }, [showLogs, ampUser]);

    // useEffect( setRouterPaths(props.routerPaths);
    useEffect(() => {
        if (props?.routerPaths) {
            setRouterPaths(props.routerPaths);
        }
    }, [props.routerPaths]);

    // useEffect( setShowLogs( props.showLogs
    useEffect(() => {
        if (props?.showLogs){
            setShowLogs(props.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [props.showLogs]);

    // useEffect( setClientRec(props.clientRec);
    useEffect(() => {
        if (props.clientRec) {
            setClientRec(props.clientRec);
        }
    }, [props.clientRec]);

    // useEffect( setClientApptRecs(clientApptsRecs);
    useEffect(() => {
        if (clientApptsRecs) {
            setClientApptRecs(clientApptsRecs);
        }
    }, [clientApptsRecs]);

    // useEffect( setClientAnimalRecs(ampUserClientAnimalsRecs);
    useEffect(() => {
        if (ampUserClientAnimalsRecs) {
            setClientAnimalRecs(ampUserClientAnimalsRecs);
        }
    }, [ampUserClientAnimalsRecs]);

    // useEffect( setClientNoteRecs(ampUserClientNotesRecs);
    useEffect(() => {
        if (ampUserClientNotesRecs) {
            setClientNoteRecs(ampUserClientNotesRecs);
        }
    }, [ampUserClientNotesRecs]);

    // TODO - REPLACE WITH BOX COMPONENT FOR UNIFORMITY?

    return (
        <div
            style={{
                backgroundColor: 'lightgray',
                minHeight: '15vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 'calc(10px + 2vmin)',
                // color: 'white',
                color: 'black',
                marginBottom: '0.5vh'
            }}
        >
            <h2>Edit Client</h2>
            <RouterBreadcrumbs breadcrumbsArr={breadcrumbRoutes}/>
            <EditClientForm
                ampUser={ampUser}
                clientRec={clientRec}
                showLogs={showLogs}
                clientApptRecs={clientApptRecs}
                clientAnimalRecs={clientAnimalRecs}
                clientNoteRecs={clientNoteRecs}
            />
        </div>
    );
}
