import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { camelCaseToTitleCase, toTitleCase } from "../../../utils/text";
import BaseNavigateButton from "../../links/BaseNavigateButton";
import Grid from "@mui/material/Grid";
import { formFieldsToIgnore, ampUserAttrsDisplayTextObj } from "../../../utils/formObjects";
import {Button} from "@mui/material";
import BaseStyledButton from "../../buttons/BaseStyledButton";
import { updateAmpUserUserAttrs } from "../../../data/update/person/updateAmpUser";
import CircularProgress from "@mui/material/CircularProgress";


export default function ConfirmUpdateAmpUserDialog({openProp, 
                                                       ampUserProp,
                                                       handleCloseProp, 
                                                       handleUpdateDoneProp, 
                                                       recDiffsObj, 
                                                       showLogs, 
                                                       ...props}) {
    const [open, setOpen] = useState(false);
    const [ampUser, setAmpUser] = useState(null);
    const [updatedFields, setUpdatedFields] = useState(null);
    const [updatedDisplayFields, setUpdatedDisplayFields] = useState(null);

    const [isUpdatingAmpUser, setIsUpdatingAmpUser] = useState(false);
    const [updateAmpUserResObj, setUpdateAmpUserResObj] = useState(null);
    const [updateAmpUserErrorMsg, setUpdateAmpUserErrorMsg] = useState(null);
    const [updateAmpUserSuccessMsg, setUpdateAmpUserSuccessMsg] = useState(null);

    const [activeStep, setActiveStep] = useState(0);
    const [finalStepButtonsObj, setFinalStepButtonsObj] = useState(null);
    const [finalStepDialogTitleText, setFinalStepDialogTitleText] = useState(null);

    // useEffect( if (openProp) { setOpen(true);
    useEffect(() => {
        if (openProp) {
            setOpen(true);
        }
    }, [openProp]);

    // useEffect( if (ampUserProp) { setAmpUser(ampUserProp);
    useEffect(() => {
        if (ampUserProp) {
            setAmpUser(ampUserProp);
        }
    }, [ampUserProp]);

    // useEffect( if (recDiffsObj) { setUpdatedFields(recDiffsObj);
    useEffect(() => {
        if (recDiffsObj  && Object.keys(recDiffsObj).length > 0) {
            // setUpdatedFields(recDiffsObj);
            const updatedFieldsObj = {};
            Object.entries(recDiffsObj).forEach(([key, value]) => {
                if (!formFieldsToIgnore.includes(key)) {
                    updatedFieldsObj[key] = value;
                }
            });
            setUpdatedFields(updatedFieldsObj);
            setActiveStep(1);
        }
    }, [recDiffsObj]);

    useEffect(() => {
        if (updatedFields && Object.keys(updatedFields).length > 0) {
            const updatedDisplayFieldsObj = {};
            const displayTextAttrs = Object.keys(ampUserAttrsDisplayTextObj);
            Object.entries(updatedFields).forEach(([key, value]) => {
                if (!formFieldsToIgnore.includes(key)) {
                    const titleCaseKey = camelCaseToTitleCase(key);
                    if (key === 'userStatus') {
                        // updatedDisplayFieldsObj[key] = toTitleCase(value);
                        updatedDisplayFieldsObj[titleCaseKey] = toTitleCase(value);
                    }
                    else if (key === 'ampUserTypes') {
                        const displayKey = 'User Types';
                        const titleCaseAmpUserTypes = value.map(aut => toTitleCase(aut));
                        // updatedDisplayFieldsObj[key] = titleCaseAmpUserTypes.join(', ');
                        updatedDisplayFieldsObj[displayKey] = titleCaseAmpUserTypes.join(', ');
                    }
                    else {
                        // updatedDisplayFieldsObj[key] = value;
                        if (displayTextAttrs.includes(key)) {
                            updatedDisplayFieldsObj[ampUserAttrsDisplayTextObj[key]] = value;
                        }
                        else {
                            updatedDisplayFieldsObj[titleCaseKey] = value;
                        }
                        // updatedDisplayFieldsObj[titleCaseKey] = value;
                    }
                }
            });
            setUpdatedDisplayFields(updatedDisplayFieldsObj);
        }
    }, [updatedFields]);

    // useEffect( handleUpdateDoneProp(updateAmpUserResObj);
    useEffect(() => {
        if (updateAmpUserResObj) {
            handleUpdateDoneProp(updateAmpUserResObj);
        }
    }, [updateAmpUserResObj]);

    // const handleClose = () => {
    function handleClose() {
        if (showLogs) console.log('ConfirmUpdateAmpUserDialog handleClose');
        if (handleCloseProp) {
            if (!isUpdatingAmpUser) {
                // resetState();
                handleCloseProp();
            }
            else {
                alert('Please wait for the update to complete before closing the dialog.');
            }
        }
    }

    const handleConfirmUpdateAmpUser = async () => {
        setIsUpdatingAmpUser(true);
        const updateRes = await updateAmpUserUserAttrs(ampUser, updatedFields, showLogs);
        if (showLogs) console.log('updateRes', updateRes);
        setUpdateAmpUserResObj(updateRes);
        setIsUpdatingAmpUser(false);
        if (updateRes.success) {
            setUpdateAmpUserSuccessMsg('Update Successful');
            // setActiveStep(2);
        }
        else {
            setUpdateAmpUserErrorMsg(updateRes?.error || 'Update Failed');
        }
        setActiveStep(2);
        setIsUpdatingAmpUser(false);
    }

    const renderUpdateStep1 = () => (
        <>
            <DialogTitle>Confirm Update</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <h3>Fields to Update</h3>
                    {updatedDisplayFields ? Object.entries(updatedDisplayFields).map(([key, value], idx) => {
                        return (
                            <DialogContentText key={`${idx}-${key}`}>
                                {`${key}: ${value}`}
                            </DialogContentText>
                        )
                    }) : null}
                </Stack>
            </DialogContent>
            <DialogActions>
                <BaseStyledButton
                    onClick={async () => await handleConfirmUpdateAmpUser()}
                    disabled={isUpdatingAmpUser}
                    label="Update"
                />
                {isUpdatingAmpUser ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                        <CircularProgress size={24} />
                        <DialogContentText style={{ marginLeft: '20px' }}>
                            Updating user record...
                        </DialogContentText>
                    </div>
                ) : null }
                <BaseStyledButton
                    onClick={handleClose}
                    disabled={isUpdatingAmpUser}
                    label="Cancel"
                />
            </DialogActions>
        </>
    );

    const renderUpdateStep2 = () => (
        <>
            <DialogTitle>Update Complete</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {updateAmpUserSuccessMsg || updateAmpUserErrorMsg}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {!isUpdatingAmpUser && updateAmpUserSuccessMsg ? (
                    <BaseNavigateButton
                        navTarget={'/'}
                        label="Done"
                        disabled={isUpdatingAmpUser}
                    />
                ) : null}
                {!isUpdatingAmpUser && updateAmpUserErrorMsg ? (
                    <BaseStyledButton
                        onClick={handleClose}
                        disabled={isUpdatingAmpUser}
                        label="Close"
                    />
                ) : null}
            </DialogActions>
        </>
    );

    const renderLoadingStep = () => (
        <>
            <DialogTitle>Loading...</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Loading Client Update Data
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <BaseStyledButton
                    onClick={handleClose}
                    disabled={isUpdatingAmpUser}
                    label="Cancel"
                />
                <BaseStyledButton
                    onClick={handleClose}
                    disabled={isUpdatingAmpUser}
                    label="Close"
                />
            </DialogActions>
        </>
    );

    return (
        <Dialog open={open} onClose={handleClose}>
            {activeStep === 0 ? renderLoadingStep()
                : activeStep === 1 ? renderUpdateStep1()
                : activeStep === 2 ? renderUpdateStep2()
                : renderLoadingStep()}
        </Dialog>
    );

}
