import React, {useState, useEffect, useContext} from 'react';
import {DataStore, SortDirection} from "aws-amplify";
import {PublicClient, PublicAnimal} from '../../../models';

const SharedPubAnimalsContext = React.createContext();

export function SharedPubAnimalsProvider({children, sharedPubClientIdProp, showLogs, resetProviderState}) {
    const [sharedPubAnimals, setSharedPubAnimals] = useState([]);
    // const [sharedPubClientId, setSharedPubClientId] = useState(sharedPubClientIdProp);
    const [sharedPubClientId, setSharedPubClientId] = useState(null);
    const [localShowLogs, setLocalShowLogs] = useState(false);
    const [pubAnimalIds, setPubAnimalIds] = useState([]);
    const [pubAnimalIdsToQuery, setPubAnimalIdsToQuery] = useState([]);

    const resetContext = () => {
        setSharedPubClientId(null);
        setSharedPubAnimals([]);
        setPubAnimalIds([]);
        setPubAnimalIdsToQuery([]);
    }

    // useEffect( reset state values when resetProviderState is true
    useEffect(() => {
        if (resetProviderState) {
            resetContext();
        }
    }, [resetProviderState]);

    // useEffect( set localShowLogs when showLogs changes
    useEffect(() => {
        if (showLogs) {
            setLocalShowLogs(true);
        } else {
            setLocalShowLogs(false);
        }
    }, [showLogs]);

    // useEffect( setSharedPubClientId(sharedPubClientIdProp);
    useEffect(() => {
        if (sharedPubClientIdProp) {
            setSharedPubClientId(sharedPubClientIdProp);
        }
    }, [sharedPubClientIdProp]);

    // useEffect( console.log( sharedPubClientId, sharedPubAnimals, pubAnimalIds, pubAnimalIdsToQuery
    useEffect(() => {
        if (localShowLogs) {
            console.log('SharedPubAnimalsProvider sharedPubClientId', sharedPubClientId);
            console.log('SharedPubAnimalsProvider sharedPubAnimals', sharedPubAnimals);
            console.log('SharedPubAnimalsProvider pubAnimalIds', pubAnimalIds);
            console.log('SharedPubAnimalsProvider pubAnimalIdsToQuery', pubAnimalIdsToQuery);
        }
    }, [sharedPubClientId, sharedPubAnimals, pubAnimalIds, pubAnimalIdsToQuery, localShowLogs]);

    useEffect(() => {
        if (!resetProviderState && sharedPubClientId) {
            const subscription = DataStore.observeQuery(PublicAnimal,
                (pa) => pa.and(
                    pa => [
                        pa.publicclientID.eq(sharedPubClientId),
                        pa.isDeleted.ne(true),
                    ]),
                {
                    sort: s => s.createdAt(SortDirection.DESCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    if (localShowLogs) {
                        console.log(`SharedPubAnimalsProvider a [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                        console.log('SharedPubAnimalsProvider a [Snapshot] items', items);
                    }
                    if (isSynced) {
                        setSharedPubAnimals(items);
                        if (items && items.length > 0) {
                            const pubAnimalIds = items.map(item => item.id);
                            setPubAnimalIds(pubAnimalIds);
                        }
                    }
                });
            // Remember to unsubscribe when the component unmounts
            return () => subscription.unsubscribe();
        }
    }, [sharedPubClientId, localShowLogs, resetProviderState]);

    useEffect(() => {
        if (!resetProviderState && sharedPubClientId && pubAnimalIdsToQuery && pubAnimalIdsToQuery.length > 0) {
            // observeQuery for PublicAnimal records, with matching publicclientID and id in pubAnimalIdsToQuery and isDeleted is false
            const subscription = DataStore.observeQuery(PublicAnimal,
                // (pa) => pa.and(
                //     pa => [
                //         pa.publicclientID.eq(sharedPubClientId),
                //         // pa.id.in(pubAnimalIdsToQuery),
                //         pubAnimalIdsToQuery.map(id => pa.id.eq(id)),
                //         pa.isDeleted.ne(true),
                //     ]),
                (a) => a.or((a) =>
                    pubAnimalIdsToQuery.map(id => a.id.eq(id))
                ),
                {
                    sort: s => s.createdAt(SortDirection.DESCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    if (localShowLogs) {
                        console.log(`SharedPubAnimalsProvider b [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                        console.log('SharedPubAnimalsProvider b [Snapshot] items', items);
                    }
                    if (isSynced) {
                        setSharedPubAnimals(items);
                        if (items && items.length > 0) {
                            const pubAnimalIds = items.map(item => item.id);
                            setPubAnimalIds(pubAnimalIds);
                        }
                    }
                });
            // Remember to unsubscribe when the component unmounts
            return () => subscription.unsubscribe();
        }
    }, [sharedPubClientId, pubAnimalIdsToQuery, resetProviderState, localShowLogs]);

    // useEffect(() => {
    //     if (!resetProviderState && sharedPubClientId && pubAnimalIdsToQuery && pubAnimalIdsToQuery.length > 0) {
    //         const subscription = DataStore.observeQuery(PublicAnimal,
    //             (pa) => pa.and(pa => [
    //                 pa.publicclientID.eq(sharedPubClientId),
    //                 pa.or(...pubAnimalIdsToQuery.map(id => pa.id.eq(id))),
    //                 pa.isDeleted.ne(true),
    //             ]),
    //             {
    //                 sort: s => s.createdAt(SortDirection.DESCENDING)
    //             })
    //             .subscribe(async msg => {
    //                 const {items, isSynced} = msg;
    //                 if (localShowLogs) {
    //                     console.log(`SharedPubAnimalsProvider b [msg] item count: ${items.length}, isSynced: ${isSynced}`);
    //                     console.log('SharedPubAnimalsProvider b [Snapshot] items', items);
    //                 }
    //                 if (isSynced) {
    //                     setSharedPubAnimals(items);
    //                     if (items && items.length > 0) {
    //                         const pubAnimalIds = items.map(item => item.id);
    //                         setPubAnimalIds(pubAnimalIds);
    //                     }
    //                 }
    //             });
    //
    //         return () => subscription.unsubscribe();
    //     }
    // }, [sharedPubClientId, pubAnimalIdsToQuery, resetProviderState, localShowLogs]);

    // useEffect(() => {
    //     if (!resetProviderState && sharedPubClientId && pubAnimalIdsToQuery && pubAnimalIdsToQuery.length > 0) {
    //         const subscription = DataStore.observeQuery(PublicAnimal,
    //             c => c.publicclientID("eq", sharedPubClientId)
    //                 .id("in", pubAnimalIdsToQuery)
    //                 .isDeleted("ne", true),
    //             {
    //                 sort: s => s.createdAt(SortDirection.DESCENDING)
    //             })
    //             .subscribe(async msg => {
    //                 const {items, isSynced} = msg;
    //                 if (localShowLogs) {
    //                     console.log(`SharedPubAnimalsProvider b [msg] item count: ${items.length}, isSynced: ${isSynced}`);
    //                     console.log('SharedPubAnimalsProvider b [Snapshot] items', items);
    //                 }
    //                 if (isSynced) {
    //                     setSharedPubAnimals(items);
    //                     if (items && items.length > 0) {
    //                         const pubAnimalIds = items.map(item => item.id);
    //                         setPubAnimalIds(pubAnimalIds);
    //                     }
    //                 }
    //             });
    //
    //         return () => subscription.unsubscribe();
    //     }
    // }, [sharedPubClientId, pubAnimalIdsToQuery, resetProviderState, localShowLogs]);

    return (
        <SharedPubAnimalsContext.Provider value={{
            sharedPubAnimals,
            sharedPubClientId,
            pubAnimalIds,
            pubAnimalIdsToQuery,
            setPubAnimalIdsToQuery
        }}
        >
            {children}
        </SharedPubAnimalsContext.Provider>
    );
}

export function useSharedPubAnimals() {
    return useContext(SharedPubAnimalsContext);
}
