import {Animal, AnimalNote, ShoePackage} from "../../../models";
import {DataStore} from "aws-amplify";
import {
    fetchAnimalById,
    fetchAnimalByRouterPath,
    fetchShoePackageById,
    fetchShoePackageByRouterPath
} from "../../get/fetchRecords";
import { getNowAsAWSDateTime, returnDateTimeNowISO } from "../../../utils/datesTimes";


export function shoePackageChanged(shoePackageFormValues, shoePackageRec) {
    if (!shoePackageRec) {
        return true;
    }
    return shoePackageFormValues.frontLeft !== shoePackageRec.frontLeft ||
        shoePackageFormValues.frontRight !== shoePackageRec.frontRight ||
        shoePackageFormValues.rearLeft !== shoePackageRec.rearLeft ||
        shoePackageFormValues.rearRight !== shoePackageRec.rearRight;
}

// export async function updateAnimalRecById(animalId, animalFormValues, showLogs) {
export async function updateAnimalRecById(animalRec, animalFormValues, showLogs) {
    if (showLogs) {
        // console.log('updateAnimalRecById', animalId, animalFormValues);
        console.log('updateAnimalRecById', animalRec, animalFormValues);
    }
    try {
        // const animalArr = await fetchAnimalById(animalId, showLogs);
        // const animalArr = await fetchAnimalById(animalRec, showLogs);
        const fetchAnimalRes = await fetchAnimalById(animalRec, showLogs);
        if (showLogs) {
            // console.log('updateAnimalRecById animalArr', animalArr);
            console.log('updateAnimalRecById fetchAnimalRes', fetchAnimalRes);
        }
        // if (animalArr && animalArr.length > 0) {
        if (fetchAnimalRes && fetchAnimalRes?.success && fetchAnimalRes?.animalRec) {
            // const updateAnimalRecRes = await updateAnimalRec(animalArr[0], animalFormValues, showLogs);
            const updateAnimalRecRes = await updateAnimalRec(fetchAnimalRes.animalRec, animalFormValues, showLogs);
            if (updateAnimalRecRes && updateAnimalRecRes?.success && updateAnimalRecRes?.updatedAnimalRec) {
                return {success: true, updatedAnimalRec: updateAnimalRecRes.updatedAnimalRec};
            }
            else {
                console.error('updateAnimalRecById error', updateAnimalRecRes.error);
                return {success: false, error: updateAnimalRecRes.error};
            }
        } else {
            // console.error('updateAnimalRecById animalId not found', animalRec.id);
            console.error('updateAnimalRecById animal not found - fetchAnimalRes', fetchAnimalRes);
            return {success: false, error: "Animal not found"};
        }
    }
    catch (error) {
        console.error('updateAnimalRecById error', error);
        return {success: false, error};
    }
}

export async function updateAnimalRecByRouterPath(routerPath, animalFormValues, showLogs) {
    const animal = await fetchAnimalByRouterPath(routerPath);
    if (animal) {
        return await updateAnimalRec(animal, animalFormValues, showLogs);
    } else {
        console.error('updateAnimalRecByRouterPath routerPath not found', routerPath);
    }
}

async function updateAnimalRec(animal, animalFormValues, showLogs) {
    if (showLogs) {
        console.log('updateAnimalRec', animal, animalFormValues);
    }
    try {
        const updatedAnimalRec = await DataStore.save(Animal.copyOf(animal, updated => {
            updated.name = animalFormValues.name;
            updated.animalType = animalFormValues.animalType;
            updated.animalStatus = animalFormValues.animalStatus;
            updated.temperament = animalFormValues.temperament;
            updated.stallNumber = animalFormValues.stallNumber;
            updated.isEdited = true;
            updated.lastEditedAt = returnDateTimeNowISO();
            updated.breed = animalFormValues.breed;
            updated.color = animalFormValues.color;
            updated.discipline = animalFormValues.discipline;
            updated.age = animalFormValues.age;
            updated.isMiniature = animalFormValues.isMiniature;
            updated.addedShoeTaskAmount = animalFormValues.addedShoeTaskAmount;
            updated.addedShoeTaskDuration = animalFormValues.addedShoeTaskDuration;
            updated.addedTrimTaskAmount = animalFormValues.addedTrimTaskAmount;
            updated.addedTrimTaskDuration = animalFormValues.addedTrimTaskDuration;
            updated.shoeTaskCyclePeriodWeeks = animalFormValues.shoeTaskCyclePeriodWeeks;
            updated.trimTaskCyclePeriodWeeks = animalFormValues.trimTaskCyclePeriodWeeks;
            updated.birthday = animalFormValues.birthday;
            updated.isDeleted = animalFormValues.isDeleted;
        }));
        if (showLogs) {
            console.log('updateAnimalRec updatedAnimalRec', updatedAnimalRec);
        }
        return({success: true, updatedAnimalRec});
    }
    catch (error) {
        console.error('updateAnimalRec error', error);
        return {success: false, error};
    }
    // return updatedAnimal;
}

export async function updateAnimalRecByIdWithDiffObj(animalRec, animalFormValsDiffObj, showLogs) {
    if (showLogs) {
        console.log('updateAnimalRecByIdWithDiffObj animalRec', animalRec);
        console.log('updateAnimalRecByIdWithDiffObj animalFormValsDiffObj', animalFormValsDiffObj);
    }
    try {
        const fetchAnimalRes = await fetchAnimalById(animalRec, showLogs);
        if (showLogs) {
            console.log('updateAnimalRecByIdWithDiffObj fetchAnimalRes', fetchAnimalRes);
        }
        if (fetchAnimalRes && fetchAnimalRes?.success && fetchAnimalRes?.animalRec) {
            animalFormValsDiffObj.lastEditedAt = returnDateTimeNowISO();
            animalFormValsDiffObj.isEdited = true;
            const updateAnimalRecRes = await updateAnimalRecWithDiffObj(fetchAnimalRes.animalRec, animalFormValsDiffObj, showLogs);

            if (updateAnimalRecRes && updateAnimalRecRes?.success && updateAnimalRecRes?.updatedAnimalRec) {
                return {success: true, updatedAnimalRec: updateAnimalRecRes.updatedAnimalRec};
            }
            else {
                console.error('updateAnimalRecByIdWithDiffObj error', updateAnimalRecRes.error);
                return {success: false, error: updateAnimalRecRes.error};
            }

        } else {
            console.error('updateAnimalRecByIdWithDiffObj animal not found - fetchAnimalRes', fetchAnimalRes);
            return {success: false, error: "Animal not found"};
        }
    }
    catch (error) {
        console.error('updateAnimalRecByIdWithDiffObj error', error);
        return {success: false, error};
    }
}

async function updateAnimalRecWithDiffObj(animalRec, animalFormValsDiffObj, showLogs) {
    if (showLogs) {
        console.log('updateAnimalRecWithDiffObj animalRec', animalRec);
        console.log('updateAnimalRecWithDiffObj animalFormValsDiffObj', animalFormValsDiffObj);
    }
    try {
        const updatedAnimalRec = await DataStore.save(Animal.copyOf(animalRec, updated => {
            Object.keys(animalFormValsDiffObj).forEach(key => {
                // Update the corresponding field in the Animal model
                updated[key] = animalFormValsDiffObj[key];
            });
        }));
        if (showLogs) {
            console.log('updateAnimalRecWithDiffObj updatedAnimalRec', updatedAnimalRec);
        }
        return({success: true, updatedAnimalRec});
    }
    catch (error) {
        console.error('updateAnimalRecWithDiffObj error', error);
        return {success: false, error};
    }
}

export async function updateShoePackageRecById(shoePackageId, shoePackageFormValues, showLogs) {
    const shoePackage = await fetchShoePackageById(shoePackageId);
    if (shoePackage) {
        return await updateShoePackageRec(shoePackage, shoePackageFormValues, showLogs);
    } else {
        console.error('updateShoePackageRecById shoePackageId not found', shoePackageId);
    }
}

export async function updateShoePackageRecByRouterPath(routerPath, shoePackageFormValues, showLogs) {
    const shoePackage = await fetchShoePackageByRouterPath(routerPath);
    if (shoePackage) {
        return await updateShoePackageRec(shoePackage, shoePackageFormValues, showLogs);
    } else {
        console.error('updateShoePackageRecByRouterPath routerPath not found', routerPath);
    }
}

async function updateShoePackageRec(shoePackage, shoePackageFormValues, showLogs) {
    const updatedShoePackage = await DataStore.save(ShoePackage.copyOf(shoePackage, updated => {
        updated.frontLeft = shoePackageFormValues.frontLeft;
        updated.frontRight = shoePackageFormValues.frontRight;
        updated.rearLeft = shoePackageFormValues.rearLeft;
        updated.rearRight = shoePackageFormValues.rearRight;
        updated.isEdited = true;
        updated.lastEditedAt = returnDateTimeNowISO();
    }));
    if (showLogs) {
        console.log('updateShoePackageRec updatedShoePackage', updatedShoePackage);
    }
    return updatedShoePackage;
}

// export async function updateAnimalNoteById(animalNoteId, note, showLogs) {
//     if (showLogs) {
//         console.log('updateAnimalNoteById', animalNoteId, note);
//     }
//     try {
//         const original = await DataStore.query(AnimalNote, animalNoteId);
//         // Check if the animal note exists
//         if (!original) {
//             console.error("AnimalNote not found");
//             return {success: false, error: "Animal Note not found"};
//         }
//
//         const updatedAnimalNote = await DataStore.save(
//             AnimalNote.copyOf(original, updated => {
//                 updated.note = note;
//                 updated.isEdited = true;
//                 updated.lastEditedAt = getNowAsAWSDateTime();
//             })
//         );
//         return {updatedAnimalNote, success: true};
//     } catch (error) {
//         console.error('updateAnimalNote error', error);
//         return {success: false, error};
//     }
// }

