import React, { useState, useEffect } from 'react';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import StyledAccordion from "./StyledAccordion";
import StyledCardContentTypography from "../text/StyledCardContentTypography";
import StyledListCard from "../cards/StyledListCard";
import Divider from "@mui/material/Divider";
import { useApptByTask } from "../../data/providers/appts/ApptByTaskProvider";
import BaseStyledButton from "../buttons/BaseStyledButton";
import { useNavigate } from "react-router-dom";

export default function ShoeTasksAccordion(props) {
    const [showLogs, setShowLogs] = useState(false);
    const [shoeTasks, setShoeTasks] = useState([]);
    const [accordionHeaderText, setAccordionHeaderText] = useState('');
    const [taskAppt, setTaskAppt] = useState([]);
    const [taskApptAnimals, setTaskApptAnimals] = useState([]);
    const [taskApptClient, setTaskApptClient] = useState(null);
    const [fetchingApptInfo, setFetchingApptInfo] = useState(false);
    const [navApptId, setNavApptId] = useState(null);
    const [accExpanded, setAccExpanded] = useState(false);

    const { taskRecApptId, setTaskRecApptId, setTaskApptRec, setTaskApptClientRec, setTaskApptAnimalRecs,
            taskApptRec, taskApptClientRec,
            taskApptAnimalRecs } = useApptByTask();

    const navigate = useNavigate();

    // useEffect( clear task rec appt id on unmount
    useEffect(() => {
        return () => {
            setFetchingApptInfo(false);
            setNavApptId(null);
            if (taskRecApptId) {
                setTaskRecApptId(null);
            }
            if (taskApptRec) {
                setTaskApptRec(null);
            }
            if (taskApptClientRec) {
                setTaskApptClientRec(null);
            }
            if (taskApptAnimalRecs) {
                setTaskApptAnimalRecs([]);
            }
        };
    }, []);

    // useEffect( setShowLogs(props.showLogs);
    useEffect(() => {
        if (props?.showLogs) {
            setShowLogs(props.showLogs);
        }
        else {
            setShowLogs(false);
        }
    }, [props.showLogs]);

    // useEffect( setShoeTasks(props.shoeTasks);
    useEffect(() => {
        if (props?.shoeTasks) {
            setShoeTasks(props.shoeTasks);
        }
    }, [props.shoeTasks]);

    // useEffect( setAccordionHeaderText(props.headerText);
    useEffect(() => {
        if (props?.headerText) {
            setAccordionHeaderText(props.headerText);
        }
    }, [props.headerText]);

    // useEffect( set state vals from task appt data context
    useEffect(() => {
        if (taskApptRec) {
            setTaskAppt(taskApptRec);
        }
        if (taskApptClientRec) {
            setTaskApptClient(taskApptClientRec);
        }
        if (taskApptAnimalRecs) {
            setTaskApptAnimals(taskApptAnimalRecs);
        }
    }, [taskApptRec, taskApptClientRec, taskApptAnimalRecs]);

    // useEffect( console.log('shoeTasks', shoeTasks);
    useEffect(() => {
        if (showLogs) {
            console.log('shoeTasks', shoeTasks);
        }
    }, [shoeTasks, showLogs]);

    // useEffect( console.log( state selected task's appt rec and related recs
    useEffect(() => {
        if (showLogs) {
            console.log('ShoeTasksAccordion taskAppt', taskAppt);
            console.log('ShoeTasksAccordion taskApptAnimals', taskApptAnimals);
            console.log('ShoeTasksAccordion taskApptClient', taskApptClient);
        }
    }, [showLogs, taskAppt, taskApptAnimals, taskApptClient]);

    function navToTaskApptInfoScreen(task) {
        if (task) {
            setFetchingApptInfo(true);
            setTaskRecApptId(task.appointmentID);
            setNavApptId(task.appointmentID);
        }
    }

    useEffect(() => {
        if (fetchingApptInfo && navApptId) {
            if (navApptId === taskRecApptId) {
                if (taskApptRec && taskApptClientRec && taskApptAnimalRecs && taskApptAnimalRecs.length > 0) {
                    navigate(`/appt-info/${taskApptRec?.routerPath}`, {
                        state: {
                            apptRec: taskApptRec,
                            clientRec: taskApptClientRec,
                            animalRecs: taskApptAnimalRecs
                        }
                    });
                }
            }
        }
    }, [fetchingApptInfo, navApptId, taskRecApptId, taskApptRec, taskApptClientRec, taskApptAnimalRecs]);

    return (
        <StyledAccordion
            expanded={accExpanded}
            onChange={() => {
                setAccExpanded(!accExpanded);
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <StyledCardContentTypography
                    text={accordionHeaderText || 'Shoe Tasks:'}
                />
            </AccordionSummary>
            <AccordionDetails>
                <div>
                    {shoeTasks.map((shoeTask, index) => {
                        return (
                            <div key={`${accordionHeaderText}-${shoeTask.id}-${index}`}>
                                <StyledListCard>
                                    <StyledCardContentTypography
                                        text={`Created at: ${shoeTask.createdAt}`}
                                    />
                                    <StyledCardContentTypography
                                        text={`Duration: ${shoeTask.duration}`}
                                    />
                                    <StyledCardContentTypography
                                        text={`Amount: ${shoeTask.amount}`}
                                    />
                                    <StyledCardContentTypography
                                        text={`Added Cost: ${shoeTask.addedCost}`}
                                    />
                                    <StyledCardContentTypography
                                        text={`Added Time: ${shoeTask.addedTime}`}
                                    />
                                    <Divider />
                                    {shoeTask?.taskNote && shoeTask?.taskNote.length > 0 ? (
                                        <StyledCardContentTypography
                                            text={`Notes: ${shoeTask.taskNote}`}
                                        />
                                    ) : null}
                                    <Divider />
                                    <BaseStyledButton
                                        label={'View Appt.'}
                                        onClick={() => navToTaskApptInfoScreen(shoeTask)}
                                    />
                                </StyledListCard>
                            </div>
                        )
                    })}
                </div>
            </AccordionDetails>
        </StyledAccordion>
    );
}