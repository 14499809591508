import React from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    render() {
        if (this.state.hasError) {
            if (this.state.error.message.includes('403')) {
                return <h1>Access Forbidden (403)</h1>;
            }
            return <h1>Something went wrong</h1>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
