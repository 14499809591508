import {
    homeNavButtonFactory,
    animalsNavButtonFactory,
    apptsNavButtonFactory,
    clientsNavButtonFactory,
    animalInfoNavButtonFactory,
} from "../shared";

export const updateAnimalDialogFinalStepSet = (animalRec) => {

        return {
            home: homeNavButtonFactory(),
            animals: animalsNavButtonFactory(),
            animalInfo: animalInfoNavButtonFactory(animalRec),
            appts: apptsNavButtonFactory(),
            clients: clientsNavButtonFactory(),
        }
}

export const animalNoteDialogFinalStepSet = (animalRec) => {

    return {
        home: homeNavButtonFactory(),
        animals: animalsNavButtonFactory(),
        animalInfo: animalInfoNavButtonFactory(animalRec),
        appts: apptsNavButtonFactory(),
        clients: clientsNavButtonFactory(),
    }
}
