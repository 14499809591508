import React, { useState, useEffect } from 'react';
import {IconButton, Badge} from "@mui/material";
import NotificationsIcon from '@mui/icons-material/Notifications';


export default function AlertNotifCardIconButton({ buttonIconProp, displayBadgeProp, badgeValProp, showLogsProp, ...props }) {
    const [IconProp, setIconProp] = useState(() => <NotificationsIcon/>);
    const [displayBadge, setDisplayBadge] = useState(false);
    const [showLogs, setShowLogs] = useState(false);


    // useEffect( setShowLogs(showLogsProp);
    useEffect(() => {
        if (showLogsProp) {
            setShowLogs(showLogsProp);
        } else {
            setShowLogs(false);
        }
    }, [showLogsProp]);

    // useEffect( setIconProp(buttonIconProp);
    useEffect(() => {
        if (buttonIconProp) {
            setIconProp(buttonIconProp);
        }
    }, [buttonIconProp]);

    // useEffect( set displayBadge from displayBadgeProp and badgeValProp
    useEffect(() => {
        let displayButtonBadge = false;
        if (!displayBadgeProp && !badgeValProp) {
            if (showLogs) {
                console.log('AlertNotifCardIconButton: No badge to display, !displayBadgeProp && !badgeValProp');
            }
        }
        else if (displayBadgeProp) {
            if (showLogs) {
                console.log('AlertNotifCardIconButton: display Badge - displayBadgeProp:', displayBadgeProp);
            }
            displayButtonBadge = true;
        }
        else if (badgeValProp !== 0 && badgeValProp !== '' && badgeValProp !== undefined && badgeValProp !== null && badgeValProp !== false) {
            if (showLogs) {
                console.log('AlertNotifCardIconButton: display Badge - badgeValProp:', badgeValProp);
            }
            displayButtonBadge = true;
        }

        setDisplayBadge(displayButtonBadge);
    }, [displayBadgeProp, badgeValProp, showLogs]);


    return (
        <IconButton
            {...props}
            size="large"
            sx={{
                color: props?.notificoncolor || 'white',
                marginLeft: props?.notificonmarginleft || {
                    xs: '0.25rem',  // Assuming base font-size is 16px, this is 4px
                    sm: '0.5rem',   // 8px
                    md: '1rem',     // 16px
                    lg: '1.5rem',   // 24px
                    xl: '2rem'      // 32px
                },
                marginRight: props?.notificonmarginright || {
                    xs: '0rem',  // Assuming base font-size is 16px, this is 4px
                    sm: '0rem',   // 8px
                    md: '0rem',     // 16px
                    lg: '0rem',   // 24px
                    xl: '0rem'      // 32px
                },
                '&:hover': {
                    backgroundColor: props?.notificonhovercolor || '#507D7E',  // A darker shade for hover
                },
                '& .MuiSvgIcon-root': {
                    fontSize: props?.notificonrootfontsize || {
                        xs: '1.5rem', // Small screens
                        sm: '2.5rem',    // Medium screens
                        md: '2.75rem',  // Large screens
                        lg: '3.25rem'     // Extra-large screens
                    }
                }
            }}
        >
            {displayBadge ? (
                <Badge
                    // badgeContent={badgeValProp || ''}
                    badgeContent=""
                    color="error"
                    // sx={{
                    //     '& .MuiBadge-badge': {
                    //         fontSize: props?.badgefontsize || {
                    //             xs: '0.75rem',  // Font size for smallest screens
                    //             sm: '1rem', // Slightly larger for small screens
                    //             md: '1.5rem',    // Default for medium screens
                    //             lg: '1.75rem', // Larger for large screens
                    //             xl: '2rem'   // Largest for extra-large screens
                    //         },
                    //         minWidth: props?.badgeminwidth || {
                    //             xs: '1rem',  // Font size for smallest screens
                    //             sm: '1.25rem', // Slightly larger for small screens
                    //             md: '1.5rem',    // Default for medium screens
                    //             lg: '1.75rem', // Larger for large screens
                    //             xl: '2rem'   // Largest for extra-large screens
                    //         },
                    //         height: props?.badgeheight || {
                    //             xs: '1rem',  // Font size for smallest screens
                    //             sm: '1.25rem', // Slightly larger for small screens
                    //             md: '1.5rem',    // Default for medium screens
                    //             lg: '1.75rem', // Larger for large screens
                    //             xl: '2rem'   // Largest for extra-large screens
                    //         },
                    //         transform: 'scale(1)', // Maintains proportional scaling
                    //     },
                    // }}
                    sx={{
                        '& .MuiBadge-badge': {
                            backgroundColor: 'red',
                            width: '1rem', // Adjust size as needed
                            height: '1rem',
                            borderRadius: '50%',
                            ...(badgeValProp ? { // If badgeValProp exists, show nested white circle
                                border: '2px solid white'
                            } : {})
                        }
                    }}
                >
                    <>
                        {IconProp}
                    </>
                </Badge>
            ) : (
                <>
                    {IconProp}
                </>
            )}
        </IconButton>
    );
}

