import React, {useState, useEffect, useContext} from 'react';
import CreateUserSubForm from "../../../components/forms/create/CreateUserSubForm";
import {useResponsiveViewport} from "../../../data/providers/ResponsiveViewportProvider";
import {userSubDataObj} from "../../../utils/formObjects";
import AmpUserContext from "../../../data/contexts/AmpUserContext";
import {breadcrumbRoutesCreateUserSubScreen} from "../../../utils/breadcrumbsRoutes";
import {useConsoleError, useConsoleLog, useConsoleWarn} from "../../../components/hooks/logging/console";
import RouterBreadcrumbs from "../../../components/router/RouterBreadcrumbs";
import Box from "@mui/material/Box";

export default function CreateUserSubScreen(props) {
    const [ampUser, setAmpUser] = useState(null);
    const [ampUserSubscriber, setAmpUserSubscriber] = useState(null);
    const [showLogs, setShowLogs] = useState(false);
    const [routerPaths, setRouterPaths] = useState(null);
    const [locationState, setLocationState] = useState(null);
    const [breadcrumbRoutes, setBreadcrumbRoutes] = useState([]); // [{path: '', name: ''}, ...
    const [userSubData, setUserSubData] = useState(userSubDataObj);

    const {ampUserRec} = useContext(AmpUserContext);
    const { isSmallScreen, isLargeScreen } = useResponsiveViewport();

    // setBreadcrumbRoutes
    useEffect(() => {
        setBreadcrumbRoutes(breadcrumbRoutesCreateUserSubScreen() || [])
        // console.error('breadcrumbRoutes', breadcrumbRoutes)
    }, [props]);

    // useEffect setShowLogs(props.showLogs);
    useEffect(() => {
        setShowLogs(props.showLogs);
    }, [props.showLogs]);

    // useEffect setLocationState(props.locationState);
    useEffect(() => {
        if (props.locationState) {
            setLocationState(props.locationState);
        }
    }, [props.locationState]);

    // useEffect setAmpUser(props.ampUser);
    useEffect(() => {
        if (props?.ampUser) {
            setAmpUser(props.ampUser);
        }
    }, [props.ampUser]);

    // useEffect setAmpUserSubscriber(props.ampUserSubscriber);
    useEffect(() => {
        if (props?.ampUserSubscriber) {
            setAmpUserSubscriber(props.ampUserSubscriber);
        }
    }, [props.ampUserSubscriber]);

    // useEffect setRouterPaths(props.routerPaths);
    useEffect(() => {
        if (props?.routerPaths) {
            setRouterPaths(props.routerPaths);
        }
    }, [props.routerPaths]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto',
            }}
        >
            <h2>Create User Subscription</h2>
            <RouterBreadcrumbs breadcrumbsArr={breadcrumbRoutes}/>
            <CreateUserSubForm
                ampUser={ampUser}
                ampUserSubscriber={ampUserSubscriber}
                showLogs={showLogs}
                routerPaths={routerPaths}
                locationState={locationState}
            />
        </Box>
    );
}
