import React from "react";
import {
    Link
} from "react-router-dom";
import MenuItem from '@mui/material/MenuItem';
import { ROUTER_PATHS } from "../../utils/breadcrumbsRoutes";
// import { useLocation } from "react-router-dom";

/*
* USE THIS ONE IN THE APP BAR
* * */

// const StyledMenuItem = ({ children, selected, fontSizes }) => {
const StyledMenuItem = ({ children, fontSizes }) => {
    const defaultFontSizes = {
        xs: '1rem',     // mobile
        sm: '1.25rem',  // tablet
        md: '1.5rem',   // laptop
        lg: '1.75rem',  // desktop
        xl: '2rem'      // large screens
    };

    const menuItemStyles = {
        '& .MuiLink-root, & a': {
            fontSize: fontSizes || defaultFontSizes,
            textDecoration: 'none',
            color: 'inherit',
            '&:visited': {
                color: 'inherit',  // Keep the same color after visiting
            },
            '&:active': {
                color: 'inherit',  // Keep the same color while clicking
            },
            '&:hover': {
                color: 'inherit',  // Keep the same color on hover
            }
        },
        padding: {
            xs: '0.5rem',
            sm: '0.75rem',
            md: '1rem'
        },
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
        }
    };

    return (
        <MenuItem
            sx={menuItemStyles}
            // selected={selected}
        >
            {children}
        </MenuItem>
    );
};

// Usage:
export function AppBarRouterMenu() {
    // const location = useLocation();

    // const customFontSizes = {
    //     xs: '0.875rem',
    //     sm: '1rem',
    //     md: '1.25rem',
    //     lg: '1.5rem',
    //     xl: '1.75rem'
    // };

    const customFontSizes = {
        xs: '1.25rem',
        sm: '1.75rem',
        md: '2rem',
        lg: '2.5rem',
        xl: '3rem'
    };

    return (
        <div>
            <nav>
                <ul style={{
                    margin: 0,
                    padding: 0,
                    listStyle: 'none'
                }}>
                    {ROUTER_PATHS.map((option) => (
                        <StyledMenuItem
                            key={`router-menu-path-${option}`}
                            // selected={option === '/'}
                            // selected={location.pathname === option}
                            fontSizes={customFontSizes}
                        >
                            {option === '/' ? (
                                <Link to={option} style={{ color: 'inherit', textDecoration: 'none' }}>home</Link>
                            ) : (
                                <Link to={option} style={{ color: 'inherit', textDecoration: 'none' }}>{option}</Link>
                            )}
                        </StyledMenuItem>
                    ))}
                </ul>
            </nav>
        </div>
    );
}

// export function AppBarRouterMenu() {
//
//     return (
//         <div>
//             <nav>
//                 <ul>
//                     {ROUTER_PATHS.map((option) => (
//                         <MenuItem key={`router-menu-path-${option}`} selected={option === '/'} >
//                             {option === '/' ? <Link to={option}>home</Link> : <Link to={option}>{option}</Link>}
//                         </MenuItem>
//                     ))}
//                 </ul>
//             </nav>
//         </div>
//     );
// }

// export function RouterMenuRouterPathToNames(pathsToNamesObj) {
//     // console.warn('routerMenuRouterPathToNames(pathsToNamesObj)', pathsToNamesObj)
//     const pathsToNamesEntries = Object.entries(pathsToNamesObj);
//     // console.warn('pathsToNamesEntries', pathsToNamesEntries)
//     return (
//         <div>
//             <nav>
//                 <ul>
//                     <li><p>
//                         list item
//                     </p></li>
//                     {}
//                     {/*{pathsToNamesObj.keys().map((pathToNameObj) => {*/}
//                     {/*    // <MenuItem key={`router-menu-path-${option}`} selected={option === 'regions'} onClick={handleClose}>*/}
//                     {/*    return <MenuItem key={`router-menu-path-${pathToNameObj}`} selected={option === 'regions'} >*/}
//                     {/*        <Link to={option}>{option}</Link>*/}
//                     {/*    </MenuItem>*/}
//                     {/*})}*/}
//                 </ul>
//             </nav>
//         </div>
//     );
// }

// function returnAllPaths(pathsObj) {
//     const allPathsArr = [];
//     ROUTER_PATHS.forEach((eachPath) => {
//         if (pathsObj.hasOwnProperty(eachPath)) {
//             pathsObj.forEach((eachNestedPath) => {
//                 allPathsArr.push(`${eachPath}/${eachNestedPath}`)
//             })
//         } else {
//             allPathsArr.push(`${eachPath}`)
//         }
//     })
//
//     return allPathsArr
// }

// export function AppBarAllRouterPathsMenu(recsObj) {
//
//     return (
//         <div>
//             <nav>
//                 <ul>
//                     {returnAllPaths(recsObj).map((eachPath) => (
//                         // <MenuItem key={`router-menu-path-${option}`} selected={option === 'regions'} onClick={handleClose}>
//
//                         <MenuItem key={`router-menu-path-${eachPath}`} selected={eachPath === 'home'} >
//                             <Link to={eachPath}>{eachPath}</Link>
//                         </MenuItem>
//                     ))}
//                 </ul>
//             </nav>
//         </div>
//     );
// }
