import React, {useState, useEffect} from 'react';
import { DataStore, SortDirection } from "aws-amplify";
import {ShoeTask, Animal} from "../../../models";
import ApptShoeTasksContext from "../../contexts/ApptShoeTasksContext";

const ApptShoeTasksProvider = ({ children, apptId, showLogs }) => {
    const [apptShoeTasksRecs, setApptShoeTasksRecs] = useState([]);
    const [localShowLogs, setLocalShowLogs] = useState(false);
    const [shoeTaskRecsByAnimalId, setShoeTaskRecsByAnimalId] = useState({});
    const [animalRecsByShoeTaskId, setAnimalRecsByShoeTaskId] = useState({});

    // useEffect( setLocalShowLogs(showLogs);
    useEffect(() => {
        if (showLogs) {
            setLocalShowLogs(showLogs);
        } else {
            setLocalShowLogs(false);
        }
    }, [showLogs]);

    useEffect(() => {
        if (apptId) {
            const subscription = DataStore.observeQuery(ShoeTask,
                (c) => c.appointmentID.eq(apptId),
                {
                    sort: s => s.createdAt(SortDirection.ASCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    if (localShowLogs) {
                        console.log(`ApptShoeTasksProvider c [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                        console.log('ApptShoeTasksProvider c [Snapshot] items', items);
                    }
                    if (isSynced) {
                        setApptShoeTasksRecs(items);
                        const tasksByAnimal = {};
                        const animalsByTask = {};
                        for (const item of items) {
                            const taskAnimal = await DataStore.query(Animal, item.animalID);
                            if (taskAnimal) {
                                animalsByTask[item.id] = taskAnimal;
                            }
                            if (tasksByAnimal[item.animalID]) {
                                tasksByAnimal[item.animalID].push(item);
                            } else {
                                tasksByAnimal[item.animalID] = [item];
                            }
                        }
                        setShoeTaskRecsByAnimalId(tasksByAnimal);
                        setAnimalRecsByShoeTaskId(animalsByTask);
                    }
                });
            // Remember to unsubscribe when the component unmounts
            return () => subscription.unsubscribe();
        }
    }, [apptId, localShowLogs])

    return (
        <ApptShoeTasksContext.Provider value={{apptShoeTasksRecs, setApptShoeTasksRecs, shoeTaskRecsByAnimalId, animalRecsByShoeTaskId}}>
            {children}
        </ApptShoeTasksContext.Provider>
    );
}

export default ApptShoeTasksProvider;
