import React, {useState, useEffect} from 'react';
import StyledListBox from "../../../boxes/StyledListBox";
import ListItem from "@mui/material/ListItem";
import BaseStyledButton from "../../../buttons/BaseStyledButton";
import FarrierPinnedNotif from "../../../alerts/FarrierPinnedNotif";
import {useConsoleLog} from "../../../hooks/logging/console";


export default function FarrierAllPinnedAlertsList(props) {
    const [ampUserRec, setAmpUserRec] = useState(null);
    const [showLogs, setShowLogs] = useState(false);
    const [allPinnedAlertsArr, setAllPinnedAlertsArr] = useState([]);

    useConsoleLog(showLogs, [{label: 'FarrierAllPinnedAlertsList allPinnedAlertsArr', value: allPinnedAlertsArr}]);

    // useEffect( set showLogs from props.showLogs
    useEffect(() => {
        if (props?.showLogs) {
            setShowLogs(props.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [props.showLogs]);

    // useEffect( set ampUserRec from props.ampUserRec
    useEffect(() => {
        if (props?.ampUserRec) {
            setAmpUserRec(props.ampUserRec);
        }
    }, [props.ampUserRec]);

    // useEffect( set allPinnedAlertsArr from props.farrierPinnedAlertsAll
    useEffect(() => {
        if (props?.farrierPinnedAlertsAll) {
            setAllPinnedAlertsArr(props.farrierPinnedAlertsAll);
        }
    }, [props.farrierPinnedAlertsAll]);

    return (
        <StyledListBox
            backgroundColor={'whitesmoke'}
        >
            {allPinnedAlertsArr.map((alert, index) => {
                return (
                    <ListItem
                        key={alert.alertId}
                    >
                        <FarrierPinnedNotif
                            alertIDBRec={alert}
                            showLogs={props.showLogs}
                            ampUserRec={props.ampUserRec}
                        />
                    </ListItem>
                );
            })}
            <div>
                <BaseStyledButton
                    label={'Close'}
                    onClick={props.handleListCloseProp}
                    fontSize={{
                        xs: '1.5rem',
                        sm: '1.5rem',
                        md: '1.5rem',
                        lg: '2rem',
                        xl: '2.5rem',
                    }}
                />
            </div>
        </StyledListBox>
    );
}
