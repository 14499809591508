
export function sumTaskAmount(clientDefaultTaskAmount, addedTaskAmount) {
    // Convert clientDefaultTaskAmount to an int if it's a string, treating empty strings as 0
    if (typeof clientDefaultTaskAmount === 'string') {
        clientDefaultTaskAmount = clientDefaultTaskAmount.trim() === '' ? 0 : parseInt(clientDefaultTaskAmount, 10);
    }
    // Convert addedTaskAmount to an int if it's a string, treating empty strings as 0
    if (typeof addedTaskAmount === 'string') {
        addedTaskAmount = addedTaskAmount.trim() === '' ? 0 : parseInt(addedTaskAmount, 10);
    }

    // Replace NaN with 0 if parsing failed
    clientDefaultTaskAmount = isNaN(clientDefaultTaskAmount) ? 0 : clientDefaultTaskAmount;
    addedTaskAmount = isNaN(addedTaskAmount) ? 0 : addedTaskAmount;

    // Return the sum of the integer values
    return clientDefaultTaskAmount + addedTaskAmount;
}

export function sumTaskDuration(clientDefaultTaskDuration, addedTaskDuration) {

    return clientDefaultTaskDuration + addedTaskDuration;
}

export function sumApptAmount(apptTasksArr) {
    let totalAmt = 0;
    apptTasksArr.forEach((eachTask) => {
        console.log('eachTask.totalAmount', eachTask.totalAmount);
        totalAmt += eachTask.totalAmount;
    });
    return totalAmt;
}

export function sumApptDuration(apptTasksArr) {
    let totalDur = 0;
    apptTasksArr.forEach((eachTask) => {
        totalDur += eachTask.totalDuration;
    });
    return totalDur;
}

export function convertToFullInteger(str) {
    let val;
    console.log('convertToFullInteger(str)', str);
    if (typeof str !== 'string') {
        val = `${str}`
    } else {
        val = str;
    }
    try {
        // Remove all non-digit characters except the decimal point
        const numericPart = val.replace(/[^\d.]/g, '');

        // Remove the decimal point and convert to an integer
        const fullInteger = parseInt(numericPart.replace('.', ''), 10);
        return fullInteger;

    } catch (e) {
        // TODO - notify user of error
        console.error('convertToFullInteger error', e);
        return 0;
    }
}

export function convertToFullIntegerHandleNaN(str) {

    // let val;
    // console.log('convertToFullInteger(str)', str);
    // if (typeof str !== 'string') {
    //     val = `${str}`;
    // } else {
    //     val = str;
    // }
    // try {
    //     // Remove all non-digit characters except the decimal point
    //     const numericPart = val.replace(/[^\d.]/g, '');
    //
    //     // If numericPart is empty, return 0
    //     if (numericPart === '') {
    //         return 0;
    //     }
    //
    //     // Remove the decimal point and convert to an integer
    //     const fullInteger = parseInt(numericPart.replace('.', ''), 10);
    //     return isNaN(fullInteger) ? 0 : fullInteger; // Return 0 if the parsed value is NaN
    // } catch (e) {
    //     // Notify user of error (optional)
    //     console.error('convertToFullInteger error', e);
    //     return 0;
    // }

    let val;
    console.log('convertToFullInteger(str)', str);
    if (typeof str !== 'string') {
        val = `${str}`;
    } else {
        val = str;
    }
    try {
        // Remove all non-digit characters except the decimal point
        const numericPart = val.replace(/[^\d.]/g, '');

        // If numericPart is empty, return empty string
        if (numericPart === '') {
            return '';
        }

        // Remove the decimal point and convert to an integer
        const fullInteger = parseInt(numericPart.replace('.', ''), 10);
        return isNaN(fullInteger) ? '' : fullInteger; // Return empty string if the parsed value is NaN

    } catch (e) {
        // Notify user of error (optional)
        console.error('convertToFullInteger error', e);
        return '';
    }
}

export const isInteger = (value) => {
    const number = parseInt(value, 10);
    return !isNaN(number) && Number.isInteger(number);
};
