import React, { useState, useEffect } from "react";
import StyledListCard from "./StyledListCard";
import BaseNavigateButton from "../links/BaseNavigateButton";
import StyledCardContentTypography from "../text/StyledCardContentTypography";
import { toTitleCase } from "../../utils/text";
import BaseStyledButton from "../buttons/BaseStyledButton";
import Divider from "@mui/material/Divider";

// import {useConsoleLog, useConsoleWarn, useConsoleError} from "../hooks/logging/console";
// import StyledListItemText from "../text/StyledListItemText";

export default function AnimalInfoCard(props) {
    const [clientRec, setClientRec] = useState(null);
    const [animalRec, setAnimalRec] = useState(null);
    const [showLogs, setShowLogs] = useState(false);
    const [shoePackageRec, setShoePackageRec] = useState(null);

    const [showAllAttrs, setShowAllAttrs] = useState(true);

    const [cardActionButtonStack, setCardActionButtonStack] = useState(null);

    // useEffect( setShowLogs(props.showLogs);
    useEffect(() => {
        if (props?.showLogs) {
            setShowLogs(props.showLogs);
        }
    }, [props.showLogs]);

    // useEffect( setAnimalRec(props.animalRec);
    useEffect(() => {
        if (props?.animalRec) {
            setAnimalRec(props.animalRec);
        }
    }, [props.animalRec]);

    // useEffect( setShoePackageRec(props.shoePackageRec);
    useEffect(() => {
        if (props?.shoePackageRec) {
            setShoePackageRec(props.shoePackageRec);
        }
    }, [props.shoePackageRec]);

    // useEffect( setClientRec(props.clientRec);
    useEffect(() => {
        if (props?.clientRec) {
            setClientRec(props.clientRec);
        }
    }, [props.clientRec]);

    // useEffect( setShowAllAttrs(props.showAllAttrs);
    useEffect(() => {
        if (props?.showAllAttrs) {
            if (props.showAllAttrs === 'false') {
                setShowAllAttrs(false);
            }
            else {
                setShowAllAttrs(true);
            }
        }
    }, [props.showAllAttrs]);

    // useEffect( setCardActionButtonStack(props.cardActionButtonStack);
    useEffect(() => {
        if (props?.cardActionButtonStack) {
            setCardActionButtonStack(props.cardActionButtonStack);
        }
    }, [props.cardActionButtonStack]);

    function handleShowAllAttrs() {
        setShowAllAttrs(!showAllAttrs);
    }

    // useEffect( console.log( clientRec, animalRec, shoePackageRec
    useEffect(() => {
        if (showLogs) {
            console.log('AnimalInfoCard clientRec', clientRec);
            console.log('AnimalInfoCard animalRec', animalRec);
            console.log('AnimalInfoCard shoePackageRec', shoePackageRec);
        }
    }, [clientRec, animalRec, shoePackageRec, showLogs]);

    // TODO - ADD NOTES ACCORDION,
    //  OR NOTES COUNT + BUTTON TO NAVIGATE TO NOTES

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 auto',
        }}>
            <StyledListCard
                margin={['1rem', '1rem', '1rem', '1rem']}
            >
                <StyledCardContentTypography
                    text={`Client: ${clientRec?.name || '-'}`}
                />
                <StyledCardContentTypography
                    text={`Animal: ${animalRec?.name || '-'}`}
                />
                <StyledCardContentTypography
                    text={`Animal Type: ${animalRec?.animalType ?  toTitleCase(animalRec.animalType) : '-'}`}
                />
                <StyledCardContentTypography
                    text={`Breed: ${animalRec?.breed ? toTitleCase(animalRec.breed) : '-'}`}
                />
                <StyledCardContentTypography
                    text={`Age: ${animalRec?.age || '-'}`}
                />
                <StyledCardContentTypography
                    text={`Color: ${animalRec?.color || '-'}`}
                />
                {showAllAttrs ? (
                    <div>
                        <StyledCardContentTypography
                            text={`Discipline: ${animalRec?.discipline || '-'}`}
                        />
                        <StyledCardContentTypography
                            text={`Status: ${animalRec?.animalStatus ? toTitleCase(animalRec.animalStatus) : '-'}`}
                        />
                        <StyledCardContentTypography
                            text={`Is Miniature: ${animalRec?.isMiniature ? toTitleCase(animalRec.isMiniature) : '-'}`}
                        />
                        <StyledCardContentTypography
                            text={`Temperament: ${animalRec?.temperament || '-'}`}
                        />
                        <StyledCardContentTypography
                            text={`Stall Number: ${animalRec?.stallNumber || '-'}`}
                        />
                        <StyledCardContentTypography
                            text={`Birthday: ${animalRec?.birthday || '-'}`}
                        />
                        <StyledCardContentTypography
                            text={`Added Shoe Task Amount: ${animalRec?.addedShoeTaskAmount || '-'}`}
                        />
                        <StyledCardContentTypography
                            text={`Added Trim Task Amount: ${animalRec?.addedTrimTaskAmount || '-'}`}
                        />
                        <StyledCardContentTypography
                            text={`Added Shoe Task Duration: ${animalRec?.addedShoeTaskDuration || '-'}`}
                        />
                        <StyledCardContentTypography
                            text={`Added Trim Task Duration: ${animalRec?.addedTrimTaskDuration || '-'}`}
                        />
                        <StyledCardContentTypography
                            text={`Shoe Task Cycle Period Weeks: ${animalRec?.shoeTaskCyclePeriodWeeks || '-'}`}
                        />
                        <StyledCardContentTypography
                            text={`Trim Task Cycle Period Weeks: ${animalRec?.trimTaskCyclePeriodWeeks || '-'}`}
                        />
                        {shoePackageRec ? (
                            <div>
                                <Divider/>
                                <StyledCardContentTypography
                                    text={`Shoe Package:`}
                                />
                                <StyledCardContentTypography
                                    text={`Front Left: ${shoePackageRec?.frontLeft || '-'}`}
                                />
                                <StyledCardContentTypography
                                    text={`Front Right: ${shoePackageRec?.frontRight || '-'}`}
                                />
                                <StyledCardContentTypography
                                    text={`Rear Left: ${shoePackageRec?.rearLeft || '-'}`}
                                />
                                <StyledCardContentTypography
                                    text={`Rear Right: ${shoePackageRec?.rearRight || '-'}`}
                                />
                                <Divider/>
                            </div>
                        ) : null}
                        {cardActionButtonStack ? cardActionButtonStack : null}
                        {props?.hideEditButton ? null : (
                            <div>
                                {animalRec && clientRec ? (
                                    <BaseNavigateButton
                                        label={"Update Animal"}
                                        navTarget={`/edit-animal/${animalRec.routerPath}`}
                                        navState={{animalRec, clientRec, shoePackageRec, showLogs}}
                                    />
                                ) : null}
                            </div>
                        )}
                    </div>
                ) : null}
                <div>
                    <BaseStyledButton
                        label={showAllAttrs ? "Show Less" : "Show More"}
                        onClick={handleShowAllAttrs}
                    />
                </div>
            </StyledListCard>
        </div>
    )
}
