import React from 'react';
import Accordion from "@mui/material/Accordion";


export default function StyledAccordion(props) {
    return (
        <Accordion
            sx={{
                // Default styles specific to the Accordion component
                fontSize: props?.fontSize || '1rem',
                fontWeight: props?.fontWeight || 'normal',
                color: props?.color || 'text.primary',
                marginTop: props?.marginTop || '1rem',
                // marginBottom: props?.marginBottom || 1,
                margin: props?.margin || 0,
                // Add more default styles if necessary

                // Responsive styles for height, maxHeight, and minHeight
                height: props?.height || 'auto',
                maxHeight: props?.maxHeight || 'none',
                minHeight: props?.minHeight || '0',

                // Overwrite with props.sx to allow for custom styles
                ...props?.sx,
                marginBottom: props?.sx?.marginBottom || '1rem !important',
            }}
            expanded={props.expanded !== undefined ? props.expanded : false}  // Always pass expanded
            onChange={props.onChange || (() => {})}  // Always pass an onChange handler
            disabled={props.disabled}
        >
            {props.children}
        </Accordion>
    );
}
