import React from 'react';
import ListItemText from '@mui/material/ListItemText';

function toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

const StyledListItemText = ({ text, secondaryText, propsFontSize, secondaryFontSize, ...props }) => {
    const titleCasedText = toTitleCase(text);
    let titleCasedSecondaryText = '';
    if (secondaryText) {
        titleCasedSecondaryText = toTitleCase(secondaryText);
    }
    // console.log('StyledListItemText text', text);
    // console.log('StyledListItemText props', props);
    // console.log('StyledListItemText propsFontSize', propsFontSize);

    const defaultFontSizes = {
        primary: {
            xs: '1rem',    // mobile
            sm: '1.25rem', // tablet
            md: '1.5rem',  // small laptop
            lg: '1.75rem', // desktop
            xl: '1.75rem'     // large screens
        },
        secondary: {
            xs: '0.875rem',  // mobile
            sm: '1rem',      // tablet
            md: '1.125rem',  // small laptop
            lg: '1.25rem',   // desktop
            xl: '1.5rem'   // large screens
        }
    };

    const sxStyles = {
        '& .MuiListItemText-primary': {
            fontSize: propsFontSize?.fontSize || defaultFontSizes.primary,
        },
        '& .MuiListItemText-secondary': {
            fontSize: secondaryFontSize?.fontSize || defaultFontSizes.secondary,
        },
        // fontSize: propsFontSize || ['2.5vw', '2vw', '1.5vw', '1vw'],
        padding: props.padding || '1rem',
        color: 'black',
        textTransform: 'none',
        // Add any default styles here
    };

    return (
        <ListItemText
            // sx={{
            //     // fontSize: props.fontSize || ['2.5vw', '2vw', '1.5vw', '1vw'],
            //     fontSize: propsFontSize || ['2.5vw', '2vw', '1.5vw', '1vw'],
            //     padding: props.padding || '1rem',
            //     color: 'black',
            //     textTransform: 'none',
            //     // Add any default styles here
            //     // ...props.style
            // }}
            sx={sxStyles}
            {...props}
            primary={titleCasedText}
            secondary={titleCasedSecondaryText}
        />
    );
};

export default StyledListItemText;
