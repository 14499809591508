import React, {useState, useEffect} from 'react';
import {DataStore, SortDirection} from "aws-amplify";
import {Client} from '../../../models';
import AnimalClientContext from "../../contexts/AnimalClientContext";

const AnimalClientProvider = ({ children, clientId, showLogs, resetProviderState }) => {
    const [animalClientRec, setAnimalClientRec] = useState(null);
    const [animalClientShowLogs, setAnimalClientShowLogs] = useState(false);

    const resetContext = () => {
        setAnimalClientRec(null);
    }

    useEffect(() => {
        if (resetProviderState) {
            resetContext();
        }
    }, [resetProviderState]);

    useEffect(() => {
        if (showLogs) {
            setAnimalClientShowLogs(showLogs);
        } else {
            setAnimalClientShowLogs(false);
        }
    }, [showLogs]);

    useEffect(() => {
        if (animalClientShowLogs) {
            console.log('AnimalClientProvider clientId', clientId);
        }
    }, [clientId, animalClientShowLogs]);

    useEffect(() => {
        if (!resetProviderState && clientId) {
            const subscription = DataStore.observeQuery(Client,
                (c) => c.and(c => [
                    c.id.eq(clientId),
                    c.isDeleted.ne(true)  // Filter out records where isDeleted is true
                ]),
                {
                    sort: s => s.createdAt(SortDirection.DESCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    if (animalClientShowLogs) {
                        console.log(`AnimalClientProvider c [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                        console.log('AnimalClientProvider c [Snapshot] items', items);
                    }
                    if (isSynced) {
                        setAnimalClientRec(items[0]);
                    }
                });
            // Remember to unsubscribe when the component unmounts
            return () => subscription.unsubscribe();
        }
    }, [clientId, animalClientShowLogs, resetProviderState]); // Dependency array now includes regionId

    return (
        <AnimalClientContext.Provider value={{ animalClientRec }}>
            {children}
        </AnimalClientContext.Provider>
    );
}

export default AnimalClientProvider;