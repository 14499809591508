import React, { useState, useEffect } from "react";
import { useConsoleLog } from "../../hooks/logging/console";
import UpdatingIndicator from "../../progress/UpdatingIndicator";
import { AmpUserType } from "../../../models";
import { textFieldValToIntegerHandleNaN, toTitleCase } from "../../../utils/text";
import {
    editAmpUserFormObj,
    numericFormFields,
    ampUserTypesToDisplay,
    formFieldsToIgnore,
    ampUserFormsTooltipTextsObj
} from "../../../utils/formObjects";
import StyledCardContentTypography from "../../text/StyledCardContentTypography";
import StyledTextField from "../../text/StyledTextField";
import BaseStyledButton from "../../buttons/BaseStyledButton";
import TooltipWrapper from "../../text/TooltipWrapper";
import { formatPhoneNumber, validatePhoneNumber, convertToE164} from "../../../utils/validation";
import ConfirmUpdateAmpUserDialog from "../../modals/update/ConfirmUpdateAmpUserDialog";
import Divider from "@mui/material/Divider";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {generateUniqueKey} from "../../../utils/helpers";
import Checkbox from "@mui/material/Checkbox";
import StyledListBox from "../../boxes/StyledListBox";


// TODO - !!! START HERE, FR FR, BEGIN ADDING PAYMENTS, SUBSCRIPTIONS, DISPLAY THAT INFO AND ACTIONS HERE,
//  START USING STYLES AND VIEWPORT PROVIDERS FOR SCREEN SIZES, CLEAN THIS UP,
//  ADD DIALOG FOR UPDATE SUCCESS OR ERROR, ALSO UPDATE AMPUSER LAST LOGIN ON EVERY LOGIN, IN APP.JS

export default function EditAmpUserForm(props) {
    const [ampUser, setAmpUser] = useState(null);
    const [showLogs, setShowLogs] = useState(false);
    const [routerPaths, setRouterPaths] = useState({});

    const [origAmpUserFormVals, setOrigAmpUserFormVals] = useState(editAmpUserFormObj);
    const [editedAmpUserFormVals, setEditedAmpUserFormVals] = useState(editAmpUserFormObj);

    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneError, setPhoneError] = useState('');

    const [origSelectedAmpUserStatus, setOrigSelectedAmpUserStatus] = useState('');
    const [editedSelectedAmpUserStatus, setEditedSelectedAmpUserStatus] = useState('');

    const [selectedAmpUserTypes, setSelectedAmpUserTypes] = useState([]);
    const [isFarrierChecked, setIsFarrierChecked] = useState(false);
    const [ampUserTypesEnums, setAmpUserTypesEnums] = useState({});
    const [ampUserTypesFormVals, setAmpUserTypesFormVals] = useState({});

    const [ampUserFormValsChanged, setAmpUserFormValsChanged] = useState(false);
    const [ampUserFormValsDiff, setAmpUserFormValsDiff] = useState({});

    const [showUpdatingIndicator, setShowUpdatingIndicator] = useState(false);
    const [pauseForFormSubmit, setPauseForFormSubmit] = useState(false);

    const [updateSuccessMsg, setUpdateSuccessMsg] = useState('');
    const [updateErrorMsg, setUpdateErrorMsg] = useState('');

    const [updatedAmpUserResObj, setUpdatedAmpUserResObj] = useState(null);
    const [displayConfirmUpdateDialog, setDisplayConfirmUpdateDialog] = useState(false);
    const [pauseOnAmpUserUpdate, setPauseOnAmpUserUpdate] = useState(false);

    useConsoleLog(showLogs, [{label: "EditAmpUserForm updateSuccessMsg", value: updateSuccessMsg}]);
    useConsoleLog(showLogs, [{label: "EditAmpUserForm updateErrorMsg", value: updateErrorMsg}]);
    useConsoleLog(showLogs, [{label: "EditAmpUserForm pauseForFormSubmit", value: pauseForFormSubmit}]);
    useConsoleLog(showLogs, [{label: "EditAmpUserForm editedAmpUserFormVals", value: editedAmpUserFormVals}]);
    useConsoleLog(showLogs, [{label: "EditAmpUserForm origAmpUserFormVals", value: origAmpUserFormVals}]);
    useConsoleLog(showLogs, [{label: "EditAmpUserForm ampUserFormValsDiff", value: ampUserFormValsDiff}]);
    useConsoleLog(showLogs, [{label: "EditAmpUserForm ampUserFormValsChanged", value: ampUserFormValsChanged}]);

    // useEffect( setShowLogs( props.showLogs
    useEffect(() => {
        if (props?.showLogs){
            setShowLogs(props.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [props.showLogs]);

    // useEffect( setAmpUser(props.ampUser);
    useEffect(() => {
        if (props?.ampUser) {
            // TODO - MAY NEED A CHECK TO SEE IF AMPUSER IS NULL FIRST
            setAmpUser(props.ampUser);
        }
    }, [props.ampUser]);

    // useEffect( setRouterPaths(props.routerPaths);
    useEffect(() => {
        if (props?.routerPaths) {
            setRouterPaths(props.routerPaths);
        }
    }, [props.routerPaths]);

    // useEffect( log props console.log('props', props);
    useEffect(() => {
        if (showLogs) {
            console.log('props', props);
        }
    }, [showLogs, props]);

    // useEffect( populate initial ampUser form vals, status state vals
    useEffect(() => {
        if (!pauseForFormSubmit) {
            if (ampUser) {
                const ampUserObj = {};
                Object.keys(ampUser).forEach(key => {
                    if (key in editAmpUserFormObj) {
                        if (ampUser[key] !== null) {
                            ampUserObj[key] = ampUser[key];
                        }
                    }
                    if (key === 'ampUserTypes') {
                        setSelectedAmpUserTypes(ampUser[key]);
                        // setSelectedAmpUserTypes(ampUser[key].map((type) => toTitleCase(type)));
                    }
                    if (key === 'userStatus') {
                        setOrigSelectedAmpUserStatus(ampUser.userStatus);
                        setEditedSelectedAmpUserStatus(ampUser.userStatus);
                    }
                    if (key === 'phone') {
                        setPhoneNumber(ampUser.phone);
                    }
                });
                // setOrigAmpUserFormVals({...editAmpUserFormObj, ...ampUserObj});
                setOrigAmpUserFormVals({...editAmpUserFormObj, ...ampUserObj});
                setEditedAmpUserFormVals({...editAmpUserFormObj, ...ampUserObj});
            }
        }
    }, [ampUser, pauseForFormSubmit]);

    const handlePhoneChange = (e) => {
        if (!pauseOnAmpUserUpdate) {
            const formattedPhoneNumber = formatPhoneNumber(e.target.value);
            setPhoneNumber(formattedPhoneNumber);
        }
    };

    const handlePhoneBlur = () => {
        if (!pauseOnAmpUserUpdate) {
            if (!validatePhoneNumber(phoneNumber)) {
                setPhoneError('Please enter a valid US phone number: (123) 456-7890');
            }
            else {
                setPhoneError('');
                if (phoneNumber) {
                    // handleAmpUserFormChange('phone', phoneNumber);
                    const phoneE164Formatted = convertToE164(phoneNumber);
                    setEditedAmpUserFormVals({
                        ...editedAmpUserFormVals,
                        phone: phoneNumber,
                        phoneE164: phoneE164Formatted,
                    });
                }
            }
        }
    };

    // function handleAmpUserFormChange(key, value) {
    function updateEditedAmpUserFormVals(key, value) {
        if (!pauseOnAmpUserUpdate) {
            if (numericFormFields.includes(key)) {
                const amountVal = textFieldValToIntegerHandleNaN(value);
                setEditedAmpUserFormVals({
                    ...editedAmpUserFormVals,
                    [key]: amountVal,
                });
            }
            else {
                if (key !== 'email') {
                    setEditedAmpUserFormVals({
                        ...editedAmpUserFormVals,
                        [key]: value,
                    });
                }
            }
        }
    }

    // useEffect( setAmpUserTypesEnums(AmpUserType); setAmpUserTypesFormVals(userTypesObj);
    useEffect(() => {
        // if (AmpUserType) {
        if (!pauseOnAmpUserUpdate && AmpUserType) {
            setAmpUserTypesEnums(AmpUserType);
            // setAmpUserTypesEnums(AmpUserType);
            const userTypesObj = {};
            Object.entries(AmpUserType).forEach(([key, val]) => {
                if (ampUserTypesToDisplay.includes(val)) {
                    userTypesObj[key] = toTitleCase(val);
                }
            });
            setAmpUserTypesFormVals(userTypesObj);
        }
        // }, [AmpUserType]);
    }, [AmpUserType, pauseOnAmpUserUpdate]);

    // Handle checkbox change
    const handleCheckboxChange = (event) => {
        if (!pauseOnAmpUserUpdate) {
            const {name, checked} = event.target;
            if (showLogs) {
                console.log('!!! handleCheckboxChange name', name);
                console.log('!!! handleCheckboxChange checked', checked);
            }
            if (checked) {
                // Add the checked type to the array
                // setSelectedAmpUserTypes((prev) => [...prev, toTitleCase(name)]);
                // setSelectedAmpUserTypes((prev) => [...prev, name]);
                setSelectedAmpUserTypes((prev) => [...prev, name.toUpperCase()]);
            }
            else {
                // Remove the unchecked type from the array
                // setSelectedAmpUserTypes((prev) => prev.filter((type) => type !== name));

                // setSelectedAmpUserTypes((prev) => prev.filter((type) => type !== toTitleCase(name)));
                // setSelectedAmpUserTypes((prev) => prev.filter((type) => type !== name));
                setSelectedAmpUserTypes((prev) => prev.filter((type) => type !== name.toUpperCase()));
            }
        }
    };

    // useEffect( setEditedAmpUserFormVals({...editedAmpUserFormVals, ampUserTypes: ampUserTypesEnumsArr});
    // useEffect( setEditedAmpUserFormVals({...editedAmpUserFormVals, ampUserTypes: selectedAmpUserTypes});
    useEffect(() => {
        if (!pauseOnAmpUserUpdate) {
            const ampUserTypesEnumsArr = selectedAmpUserTypes.map((type) => type.toUpperCase());
            // setEditedAmpUserFormVals({...editedAmpUserFormVals, ampUserTypes: selectedAmpUserTypes});
            setEditedAmpUserFormVals({...editedAmpUserFormVals, ampUserTypes: ampUserTypesEnumsArr});
        }
    }, [selectedAmpUserTypes, pauseOnAmpUserUpdate]);

    // useEffect( if (selectedAmpUserTypes.includes('FARRIER')) setIsFarrierChecked(true);
    useEffect(() => {
        if (!pauseOnAmpUserUpdate) {
            // if (selectedAmpUserTypes.includes('FARRIER')) {
            if (selectedAmpUserTypes.includes('FARRIER') || selectedAmpUserTypes.includes('Farrier')) {
                setIsFarrierChecked(true);
            }
            else {
                setIsFarrierChecked(false);
            }
        }
    }, [selectedAmpUserTypes, pauseOnAmpUserUpdate]);

    // useEffect( setAmpUserFormValsDiffObj(diffObj); setAmpUserFormValsChanged(Object.keys(diffObj).length > 0);
    useEffect(() => {
        if (!pauseOnAmpUserUpdate) {
            if (origAmpUserFormVals && editedAmpUserFormVals) {
                const diffObj = {};
                Object.keys(origAmpUserFormVals).forEach((key) => {
                    if (!formFieldsToIgnore.includes(key)) {
                        if (key === 'ampUserTypes') {
                            if (showLogs) {
                                console.log('!!! useEffect( setAmpUserFormValsDiffObj( origAmpUserFormVals key', key);
                                console.log('!!! useEffect( setAmpUserFormValsDiffObj( origAmpUserFormVals[key]', origAmpUserFormVals[key]);
                                console.log('!!! useEffect( setAmpUserFormValsDiffObj( editedAmpUserFormVals[key]', editedAmpUserFormVals[key]);
                            }
                            // compare the arrays
                            const ampUserTypesOrigDiff = origAmpUserFormVals[key].filter((type) => !editedAmpUserFormVals[key].includes(type));
                            if (showLogs) console.log('ampUserTypesOrigDiff', ampUserTypesOrigDiff);
                            const ampUserTypesEditedDiff = editedAmpUserFormVals[key].filter((type) => !origAmpUserFormVals[key].includes(type));
                            if (showLogs) console.log('ampUserTypesEditedDiff', ampUserTypesEditedDiff);
                            if (ampUserTypesOrigDiff.length > 0 || ampUserTypesEditedDiff.length > 0) {
                                diffObj[key] = editedAmpUserFormVals[key];
                            }
                        }
                        else if (origAmpUserFormVals[key] !== editedAmpUserFormVals[key]) {
                            diffObj[key] = editedAmpUserFormVals[key];
                        }
                    }
                });
                setAmpUserFormValsDiff(diffObj);
                setAmpUserFormValsChanged(Object.keys(diffObj).length > 0);
            }
        }
    }, [origAmpUserFormVals, editedAmpUserFormVals, showLogs, pauseOnAmpUserUpdate]);

    function onUpdateUserDone(updateResObj) {
        if (updateResObj) {
            setUpdatedAmpUserResObj(updateResObj);
            // setUpdatedAmpUserRes(updateResObj);
        }
    }

    function onUpdateUserCancel() {
        setDisplayConfirmUpdateDialog(false);
        setPauseOnAmpUserUpdate(false);
    }

    function handleAmpUserSubmit() {
        handlePhoneBlur();
        setDisplayConfirmUpdateDialog(true);
        setPauseOnAmpUserUpdate(true);
    }

    // TODO - FUTURE CHANGE - ADD SOCIALS HANDLES / ADDRESSES,
    //  ALLOW MULTIPLE EMAILS OR PHONES

    return (
            <StyledListBox
                // sx={{
                //     display: 'flex',
                //     flexDirection: 'column',
                //     alignItems: 'center',
                //     justifyContent: 'center',
                //     margin: '0 auto',
                //     // marginTop: '0.5vh',
                //     width: '100%', // Ensure the container takes up full width
                // }}
                style={{
                    backgroundColor: 'lightgray',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '0 auto',
                    // marginTop: '0.5vh',
                    width: '100%', // Ensure the container takes up full width
                }}
            >
                {ampUser ? (
                    <div>
                        {showUpdatingIndicator ? <UpdatingIndicator message={"Updating User Info..."} /> : (
                            <div>
                                <h3>User Data</h3>
                                <div>
                                    <StyledCardContentTypography
                                        fullWidth
                                        text={'Name'}
                                    />
                                    <StyledTextField
                                        id="name"
                                        label="Name"
                                        value={editedAmpUserFormVals?.name || ''}
                                        onChange={(event) => updateEditedAmpUserFormVals('name', event.target.value)}
                                    />
                                </div>
                                <Divider/>
                                <div>
                                    <StyledCardContentTypography
                                        fullWidth
                                        text={'User Type'}
                                    />
                                    <FormGroup
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',   // Center horizontally
                                            justifyContent: 'center',  // Center vertically
                                            width: '100%',           // Ensure full width inside the parent
                                        }}
                                    >
                                        {Object.entries(ampUserTypesFormVals).map(([key, val]) => {
                                            // console.log('key', key, 'val', val);
                                            return (
                                                <FormControlLabel
                                                    key={generateUniqueKey(key)}
                                                    control={
                                                        <Checkbox
                                                            // name={toTitleCase(val)}
                                                            name={val}
                                                            // checked={selectedAmpUserTypes.includes(val)}
                                                            // checked={selectedAmpUserTypes.includes(toTitleCase(val))}
                                                            checked={selectedAmpUserTypes.includes(val.toUpperCase())}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                    }
                                                    // label={toTitleCase(val)}
                                                    label={val}
                                                />
                                            )
                                        })}
                                    </FormGroup>
                                </div>
                                <Divider/>
                                <div>
                                    <StyledCardContentTypography
                                        fullWidth
                                        text={'Phone'}
                                    />
                                    <StyledTextField
                                        id="phone"
                                        label="Phone"
                                        error={!!phoneError}
                                        // value={editedAmpUserFormVals?.phone || ''}
                                        value={phoneNumber}
                                        onChange={handlePhoneChange}
                                        onBlur={handlePhoneBlur}
                                        helperText={phoneError || 'Format: (123) 456-7890'}
                                        // onChange={(event) => updateEditedAmpUserFormVals('phone', event.target.value)}
                                    />
                                </div>
                                <div>
                                    <StyledCardContentTypography
                                        fullWidth
                                        text={'Email'}
                                    />
                                    <StyledTextField
                                        id="email"
                                        label="Email"
                                        readOnly={true}
                                        value={editedAmpUserFormVals?.email || ''}
                                    />
                                </div>
                                <div>
                                    <StyledCardContentTypography
                                        fullWidth
                                        text={'Street Address'}
                                    />
                                    <StyledTextField
                                        id="street-address"
                                        label="Street Address"
                                        value={editedAmpUserFormVals?.streetAddress || ''}
                                        onChange={(event) => updateEditedAmpUserFormVals('streetAddress', event.target.value)}
                                    />
                                </div>
                                <div>
                                    <StyledCardContentTypography
                                        fullWidth
                                        text={'City'}
                                    />
                                    <StyledTextField
                                        id="city"
                                        label="City"
                                        value={editedAmpUserFormVals?.city || ''}
                                        onChange={(event) => updateEditedAmpUserFormVals('city', event.target.value)}
                                    />
                                </div>
                                <div>
                                    <StyledCardContentTypography
                                        fullWidth
                                        text={'State'}
                                    />
                                    <StyledTextField
                                        id="state"
                                        label="State"
                                        value={editedAmpUserFormVals?.state || ''}
                                        onChange={(event) => updateEditedAmpUserFormVals('state', event.target.value)}
                                    />
                                </div>
                                <div>
                                    <StyledCardContentTypography
                                        fullWidth
                                        text={'Zip Code'}
                                    />
                                    <StyledTextField
                                        id="zip-code"
                                        label="Zip Code"
                                        value={editedAmpUserFormVals?.zipCode || ''}
                                        onChange={(event) => updateEditedAmpUserFormVals('zipCode', event.target.value)}
                                    />
                                </div>
                                {isFarrierChecked ? (
                                    <div>
                                        <TooltipWrapper tooltipText={ampUserFormsTooltipTextsObj.defaultShoeTaskAmount}>
                                            <StyledCardContentTypography
                                                text={'Default Shoeing ($)'}
                                            />
                                        </TooltipWrapper>
                                        <StyledTextField
                                            id="default-shoe-task-amount"
                                            label="Default Shoeing Amoount ($)"
                                            value={editedAmpUserFormVals?.defaultShoeTaskAmount || ''}
                                            onChange={(event) => {
                                                // const amountVal = textFieldValToIntegerHandleNaN(event.target.value);
                                                // updateEditedAmpUserFormVals('defaultShoeTaskAmount', amountVal);
                                                updateEditedAmpUserFormVals('defaultShoeTaskAmount', event.target.value);
                                            }}
                                        />
                                    </div>
                                ) : null}
                                {isFarrierChecked ? (
                                    <div>
                                        <TooltipWrapper tooltipText={ampUserFormsTooltipTextsObj.defaultShoeTaskDuration}>
                                            <StyledCardContentTypography
                                                text={'Default Shoeing (mins.)'}
                                            />
                                        </TooltipWrapper>
                                        <StyledTextField
                                            id="default-shoe-task-duration"
                                            label="Default Shoeing Time (mins.)"
                                            value={editedAmpUserFormVals?.defaultShoeTaskDuration || ''}
                                            onChange={(event) => {
                                                // const amountVal = textFieldValToIntegerHandleNaN(event.target.value);
                                                // updateEditedAmpUserFormVals('defaultShoeTaskDuration', amountVal);
                                                updateEditedAmpUserFormVals('defaultShoeTaskDuration', event.target.value);
                                            }}
                                        />
                                    </div>
                                ) : null}
                                {isFarrierChecked ? (
                                    <div>
                                        <TooltipWrapper tooltipText={ampUserFormsTooltipTextsObj.defaultTrimTaskAmount}>
                                            <StyledCardContentTypography
                                                text={'Default Trims ($)'}
                                            />
                                        </TooltipWrapper>
                                        <StyledTextField
                                            id="default-trim-task-amount"
                                            label="Default Trims Amount ($)"
                                            value={editedAmpUserFormVals?.defaultTrimTaskAmount || ''}
                                            onChange={(event) => {
                                                // const amountVal = textFieldValToIntegerHandleNaN(event.target.value);
                                                // updateEditedAmpUserFormVals('defaultTrimTaskAmount', amountVal);
                                                updateEditedAmpUserFormVals('defaultTrimTaskAmount', event.target.value);
                                            }}
                                        />
                                    </div>
                                ) : null}
                                {isFarrierChecked ? (
                                    <div>
                                        <TooltipWrapper tooltipText={ampUserFormsTooltipTextsObj.defaultTrimTaskDuration}>
                                            <StyledCardContentTypography
                                                fullWidth
                                                text={'Default Trims (mins.)'}
                                            />
                                        </TooltipWrapper>
                                        <StyledTextField
                                            id="default-trim-task-duration"
                                            label="Default Trims Time (mins.)"
                                            value={editedAmpUserFormVals?.defaultTrimTaskDuration || ''}
                                            onChange={(event) => {
                                                // const amountVal = textFieldValToIntegerHandleNaN(event.target.value);
                                                // updateEditedAmpUserFormVals('defaultTrimTaskDuration', amountVal);
                                                updateEditedAmpUserFormVals('defaultTrimTaskDuration', event.target.value);
                                            }}
                                        />
                                    </div>
                                ) : null}
                                <div>
                                    <BaseStyledButton
                                        // onClick={() => setDisplayUpdateAmpUserModal(true)}
                                        onClick={handleAmpUserSubmit}
                                        disabled={!ampUserFormValsChanged}
                                        label={"Update User"}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    <StyledCardContentTypography
                        text={"Loading User Info..."}
                    />
                )}
                {/*{ampUserFormValsChanged ? (*/}
                {/*    <ConfirmUpdateUserDialog*/}
                {/*        open={displayUpdateAmpUserModal}*/}
                {/*        handleClose={onUpdateAmpUserModalClose}*/}
                {/*        handleConfirmUpdate={onConfirmUpdateAmpUser}*/}
                {/*        handleCancelUpdate={onCancelUpdateAmpUser}*/}
                {/*        // handleShowPrevModal={() => setDisplayUpdateModal(false)}*/}
                {/*        recDiffsObj={ampUserFormValsDiff}*/}
                {/*        recDiffsTitle={"Fields to Update"}*/}
                {/*        showLogs={showLogs}*/}
                {/*    />*/}
                {/*) : null}*/}
                {displayConfirmUpdateDialog ? (
                    <ConfirmUpdateAmpUserDialog
                        openProp={displayConfirmUpdateDialog}
                        handleCloseProp={onUpdateUserCancel}
                        handleUpdateDoneProp={onUpdateUserDone}
                        recDiffsObj={ampUserFormValsDiff}
                        showLogs={showLogs}
                        ampUserProp={ampUser}
                    />
                ) : null}
            </StyledListBox>
    );
}
