import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

export default function DeleteApptConfirmationDialog({
                                                         open,
                                                         handleClose,
                                                         handleConfirm,
                                                         handleCancel,
                                                         modelRecToDelete,
                                                         setDisplayDeleteClientApptModal,
                                                         setClientApptToDelete,
                                                         setDeleteClientApptRes,
                                                         clientRec,
                                                         showLogs
                                                     }) {
    // if (showLogs) console.log("DeleteApptConfirmationDialog modelRecToDelete: ", modelRecToDelete);

    return (
        <Dialog
            open={open}
            onClose={() => handleClose(setDisplayDeleteClientApptModal, setClientApptToDelete, showLogs)}
        >
            <DialogTitle>{"Confirm Delete Appt."}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this appointment?
                </DialogContentText>
                <DialogContentText>
                    Client: {clientRec?.name}
                </DialogContentText>
                <DialogContentText>
                    Date: {modelRecToDelete?.apptDate}
                </DialogContentText>
                <DialogContentText>
                    Time: {modelRecToDelete?.apptTime}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => handleCancel(modelRecToDelete, setDisplayDeleteClientApptModal, setClientApptToDelete, showLogs)}
                    color="primary"
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => handleConfirm(modelRecToDelete, setDeleteClientApptRes, setDisplayDeleteClientApptModal, setClientApptToDelete, showLogs)}
                    color="warning"
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}

// export default DeleteApptConfirmationDialog;
