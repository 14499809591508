import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Divider from "@mui/material/Divider";

export default function DeleteApptAnimalConfirmationDialog({
                                                               open,
                                                               handleClose,
                                                               handleConfirm,
                                                               modelRecToDelete,
                                                               showLogs
                                                           }) {
    if (showLogs) console.log("DeleteApptAnimalConfirmationDialog modelRecToDelete: ", modelRecToDelete);

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{"Confirm Delete Action"}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    This will remove this animal from this appointment, but will not delete the animal from the system.
                </DialogContentText>
                <Divider/>
                <DialogContentText>
                    Are you sure you want to remove this animal from this appointment?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleConfirm} autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}

// export default DeleteApptAnimalConfirmationDialog;
