import React, { useState, useEffect, useContext } from 'react';
import StyledTextField from "../../text/StyledTextField";
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import BaseStyledButton from "../../buttons/BaseStyledButton";
import {
    clientFormsTooltipTextsObj,
    editPublicClientFormObj,
    createPubAnimalFormObj,
    publicSharedEditPublicClientAttrs,
    publicSharedPubAnimalAttrs
} from "../../../utils/formObjects";
import {convertToE164, formatPhoneNumber, validatePhoneNumber} from "../../../utils/validation";
import TooltipWrapper from "../../text/TooltipWrapper";
import { SharedPublicClientUpdateStatus, SharedPublicClientStatus, PublicClientStatus } from "../../../models";
import { useConsoleLog } from "../../hooks/logging/console";
import SharedPubAnimalsAccordion from "../../accordions/SharedPubAnimalsAccordion";
import Switch from '@mui/material/Switch';
import FormControlLabel from "@mui/material/FormControlLabel";
import { AnimalType } from "../../../models";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {generateUniqueKey} from "../../../utils/helpers";
import {convertToFullIntegerHandleNaN} from "../../../utils/numbers";
import ConfirmUpdateSharedPublicClientDialog from "../../modals/update/ConfirmUpdateSharedPublicClientDialog";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSharedPubClientIDB } from "../../../data/providers/idb/SharedPubClientIDBProvider";
import { useTempSharedPubClientFormData } from "../../../data/providers/idb/SharedPubClientFormIDBProvider";
import {useLocation} from "react-router-dom";
import {useSharedPubAnimals} from "../../../data/providers/animals/SharedPubAnimalsProvider";
import {useSharedPubAnimalsIDB} from "../../../data/providers/idb/SharedPubAnimalsIDBProvider";
import StyledAccordionList from "../../accordions/StyledAccordionList";
import SharedPubAnimalInfoCard from "../../cards/SharedPubAnimalInfoCard";
import BaseStyledDeleteButton from "../../buttons/BaseStyledDeleteButton";
import Box from "@mui/material/Box";
import dayjs from 'dayjs';
import {FormHelperText} from "@mui/material";
import {camelCaseToTitleCase} from "../../../utils/text";

// import StyledListCard from "../../cards/StyledListCard";
// import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";

const updateApiUrl = process.env.REACT_APP_SHARED_PUBLIC_CLIENT_API;

// const updateApiUrl = process.env.SHARED_PUBLIC_CLIENT_API;
// const updateApiUrl = process.env['SHARED_PUBLIC_CLIENT_API'];
// const updateApiUrlToken = process.env.REACT_APP_SHARED_PUBLIC_CLIENT_API_DEV_TOKEN;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

// Helper function to create a unique key for a PublicAnimal
const createPublicAnimalKey = (animal) => {
    console.log('!!! >>> createPublicAnimalKey animal', animal);
    return `${animal.name}-${animal.breed}-${animal.color}-${animal.age}`;
};

// Helper function to determine if an animal is more recent
const isMoreRecent = (animal1, animal2) => {
    const timestamp1 = animal1.timestamp || animal1._lastChangedAt;
    const timestamp2 = animal2.timestamp || animal2._lastChangedAt;
    return new Date(timestamp1) > new Date(timestamp2);
};

// Main function to merge PublicAnimals data
const mergePublicAnimalsData = (propsAnimals, idbAnimals) => {
    console.log('!!! >>> mergePublicAnimalsData propsAnimals', propsAnimals);
    console.log('!!! >>> mergePublicAnimalsData idbAnimals', idbAnimals);
    const mergedAnimals = new Map();

    // Process animals from props
    propsAnimals.forEach(animal => {
        const key = createPublicAnimalKey(animal);
        mergedAnimals.set(key, { ...animal, source: 'props' });
    });
    console.log('!!! >>> mergePublicAnimalsData mergedAnimals after props', mergedAnimals);

    // Process animals from IndexedDB
    idbAnimals.forEach(animal => {
        const key = createPublicAnimalKey(animal);
        if (mergedAnimals.has(key)) {
            // Animal exists in both sources, keep the most recent
            const existingAnimal = mergedAnimals.get(key);
            if (isMoreRecent(animal, existingAnimal)) {
                mergedAnimals.set(key, { ...animal, source: 'idb' });
            }
        } else {
            // Animal only in IndexedDB
            mergedAnimals.set(key, { ...animal, source: 'idb' });
        }
        console.log('!!! >>> mergePublicAnimalsData mergedAnimals after idb', mergedAnimals);
    });

    return Array.from(mergedAnimals.values());
};


export default function UpdateSharedPublicClientForm(props) {
    const [showLogs, setShowLogs] = useState(false);
    const [publicClientRec, setPublicClientRec] = useState(null);
    // const [recUpdatingEnabled, setRecUpdatingEnabled] = useState(false);
    // TODO - TOGGLE BACK TO LOGIC AFTER DEV WORK
    const [recUpdatingEnabled, setRecUpdatingEnabled] = useState(true);

    const [publicClientPublicAnimalRecs, setPublicClientPublicAnimalRecs] = useState([]);

    // const [origPubClientFormVals, setOrigPubClientFormVals] = useState(createClientFormObj);
    const [origPubClientFormVals, setOrigPubClientFormVals] = useState(editPublicClientFormObj);
    // const [editedPubClientFormVals, setEditedPubClientFormVals] = useState(createClientFormObj);
    const [editedPubClientFormVals, setEditedPubClientFormVals] = useState(editPublicClientFormObj);
    const [isMiniIsToggled, setIsMiniIsToggled] = useState(false);

    const [editedPubAnimalFormVals, setEditedPubAnimalFormVals] = useState(createPubAnimalFormObj);
    const [editedAnimalBirthdayDate, setEditedAnimalBirthdayDate] = useState(null);
    const [editedAnimalBirthdayDateVals, setEditedAnimalBirthdayDateVals] = useState({});
    const [editedPubAnimalsArr, setEditedPubAnimalsArr] = useState([]);
    const [pubAnimalFormValsDiffsObj, setPubAnimalFormValsDiffsObj] = useState({});
    const [pubAnimalsDiffObjsArr, setPubAnimalsDiffObjsArr] = useState([]);
    const [pubAnimalFormValsChanged, setPubAnimalFormValsChanged] = useState(false);
    const [pubAnimalsArrCurrentIndex, setPubAnimalsArrCurrentIndex] = useState(0);

    const [animalTypesEnums, setAnimalTypesEnums] = useState([]);
    const [editedSelectedAnimalType, setEditedSelectedAnimalType] = useState('');

    const [addPubAnimal, setAddPubAnimal] = useState(false);
    const [updateExistingPubAnimal, setUpdateExistingPubAnimal] = useState(false);
    const [resetPubAnimalForm, setResetPubAnimalForm] = useState(false);

    const [pubClientFormValsDiffObj, setPubClientFormValsDiffObj] = useState({});
    const [pubClientFormValsChanged, setPubClientFormValsChanged] = useState(false);

    // const [pubFormValsDiffObj, setPubClientFormValsDiffObj] = useState({});
    // const [pubClientFormValsChanged, setPubClientFormValsChanged] = useState(false);

    const [phoneNumber, setPhoneNumber] = useState('');
    const [alternateContactPhoneNumber, setAlternateContactPhoneNumber] = useState('');
    const [alternateContactPhoneError, setAlternateContactPhoneError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    // const [phoneError, setPhoneError] = useState('');

    const [pauseOnFormSubmit, setPauseOnFormSubmit] = useState(false);
    const [displayConfirmUpdateDialog, setDisplayConfirmUpdateDialog] = useState(false);

    const [updateResObj, setUpdateResObj] = useState(null);
    const [updatedPubClientResObj, setUpdatedPubClientResObj] = useState(null);
    const [updateResAnimals, setUpdateResAnimals] = useState(null);
    const [updateResPubAnimalIds, setUpdateResPubAnimalIds] = useState(null);

    const [disableFormButtons, setDisableFormButtons] = useState(false);
    const [pubClientErrorMessages, setPubClientErrorMessages] = useState({});
    const [pubAnimalErrorMessages, setPubAnimalErrorMessages] = useState({});

    const [idbPubClientVals, setIdbPubClientVals] = useState({});
    const [idbFormId, setIdbFormId] = useState('');
    // const [idbTempFormVals, setIdbTempFormVals] = useState({});
    const [idbPubAnimalsArr, setIdbPubAnimalsArr] = useState([]);
    const [refetchPubAnimalsData, setRefetchPubAnimalsData] = useState(false);

    const { addUpdate: addUpdatedPubClientToIDB,
        getLatestUpdate: getLatestUpdatedPubClientFromIDB,
        getLatestUpdateBySharedRouterPath } = useSharedPubClientIDB();

    // const { saveTempSharedPubClientFormData, getTempSharedPubClientFormData, clearTempSharedPubClientFormData } = useTempSharedPubClientFormData();
    const { formValues,
        updateFormValues,
        resetFormValues: resetPubClientIDBFormVals,
        updateIDBFormValues,
        updateMultipleFormValues,
        onBlurUpdateSaveFormValues,
        onBlurRemoveFieldFromIDB,
        updateSaveMultipleFormValues,
        saveTempSharedPubClientFormData,
        getTempSharedPubClientFormData,
        // clearTempSharedPubClientFormData
    } = useTempSharedPubClientFormData();

    const { sharedPubAnimals: providerSharedPubAnimals,
        sharedPubClientId,
        pubAnimalIds,
        pubAnimalIdsToQuery,
        setPubAnimalIdsToQuery: providerSetPubAnimalIdsToQuery } = useSharedPubAnimals();

    const { storeSharedPubAnimalsData: storeSharedPubAnimalsDataIDB,
        getLatestPubAnimalsData: getLatestPubAnimalsDataIDB } = useSharedPubAnimalsIDB();

    const location = useLocation();


    useConsoleLog(showLogs, [{label: 'UpdateSharedPublicClientForm props', value: props}]);
    useConsoleLog(showLogs, [{label: 'UpdateSharedPublicClientForm location', value: location}]);
    useConsoleLog(showLogs, [{label: 'UpdateSharedPublicClientForm publicClientRec', value: publicClientRec}]);
    useConsoleLog(showLogs, [{label: 'UpdateSharedPublicClientForm recUpdatingEnabled', value: recUpdatingEnabled}]);
    useConsoleLog(showLogs, [{label: 'UpdateSharedPublicClientForm origPubClientFormVals', value: origPubClientFormVals}]);
    useConsoleLog(showLogs, [{label: 'UpdateSharedPublicClientForm editedPubClientFormVals', value: editedPubClientFormVals}]);
    useConsoleLog(showLogs, [{label: 'UpdateSharedPublicClientForm pubClientFormValsDiffObj', value: pubClientFormValsDiffObj}]);
    useConsoleLog(showLogs, [{label: 'UpdateSharedPublicClientForm pubClientFormValsChanged', value: pubClientFormValsChanged}]);
    useConsoleLog(showLogs, [{label: 'UpdateSharedPublicClientForm editedPubAnimalFormVals', value: editedPubAnimalFormVals}]);
    useConsoleLog(showLogs, [{label: 'UpdateSharedPublicClientForm editedPubAnimalsArr', value: editedPubAnimalsArr}]);
    // useConsoleLog(showLogs, [{label: 'UpdateSharedPublicClientForm phoneError', value: phoneError}]);
    // useConsoleLog(showLogs, [{label: 'UpdateSharedPublicClientForm disableFormButtons', value: disableFormButtons}]);
    useConsoleLog(showLogs, [{label: 'UpdateSharedPublicClientForm updateResObj', value: updateResObj}]);
    useConsoleLog(showLogs, [{label: 'UpdateSharedPublicClientForm pubClientErrorMessages', value: pubClientErrorMessages}]);
    useConsoleLog(showLogs, [{label: 'UpdateSharedPublicClientForm pubAnimalErrorMessages', value: pubAnimalErrorMessages}]);
    useConsoleLog(showLogs, [{label: 'UpdateSharedPublicClientForm idbPubClientVals', value: idbPubClientVals}]);
    // useConsoleLog(showLogs, [{label: 'UpdateSharedPublicClientForm idbFormId', value: idbFormId}]);
    // useConsoleLog(showLogs, [{label: 'UpdateSharedPublicClientForm formValues', value: formValues}]);
    useConsoleLog(showLogs, [{label: 'UpdateSharedPublicClientForm updatedPubClientResObj', value: updatedPubClientResObj}]);
    useConsoleLog(showLogs, [{label: 'UpdateSharedPublicClientForm updateResPubAnimalIds', value: updateResPubAnimalIds}]);
    // useConsoleLog(showLogs, [{label: 'UpdateSharedPublicClientForm idbTempFormVals', value: idbTempFormVals}]);
    useConsoleLog(showLogs, [{label: 'UpdateSharedPublicClientForm providerSharedPubAnimals', value: providerSharedPubAnimals}]);
    useConsoleLog(showLogs, [{label: 'UpdateSharedPublicClientForm idbPubAnimalsArr', value: idbPubAnimalsArr}]);
    useConsoleLog(showLogs, [{label: 'UpdateSharedPublicClientForm publicClientPublicAnimalRecs', value: publicClientPublicAnimalRecs}]);

    // useConsoleLog(showLogs, [{label: 'UpdateSharedPublicClientForm idbPubClientVals', value: idbPubClientVals}]);

    // useEffect( setIdbFormId(formId);
    useEffect(() => {
        if (publicClientRec && idbFormId === '') {
            const formId = `publicClient_${publicClientRec.id}`;
            setIdbFormId(formId);
        }
    }, [publicClientRec, idbFormId]);

    // useEffect(() => {
    //     const clearFormDataOnNavigate = () => {
    //         if (!location.pathname.startsWith('/shared-public-client')) {
    //             // clearTempSharedPubClientFormData(idbFormId);
    //             // setEditedPubClientFormVals({}); // Reset form values
    //             // resetFormValues();
    //             updateMultipleFormValues(idbTempFormVals);
    //         }
    //     };
    //     return () => {
    //         clearFormDataOnNavigate();
    //     };
    // }, [location, idbFormId]);

    // useEffect(() => {
    //     const updateFormDataOnNavigate = () => {
    //         if (!location.pathname.startsWith('/shared-public-client')) {
    //             console.error('!!! >>> updateFormDataOnNavigate location.pathname', location.pathname);
    //             // updateMultipleFormValues(idbTempFormVals);
    //             // updateMultipleFormValues(pubClientFormValsDiffObj);
    //             updateSaveMultipleFormValues(pubClientFormValsDiffObj);
    //         }
    //     };
    //     return () => {
    //         updateFormDataOnNavigate();
    //     };
    // }, [location, idbFormId, showLogs]);

    // useEffect(() => {
    //     const fetchFormData = async () => {
    //         const tempFormData = await getTempSharedPubClientFormData(idbFormId);
    //         if (showLogs) console.log('!!! >>> tempFormData', tempFormData);
    //         if (tempFormData && Object.keys(tempFormData).length > 0) {
    //             // setEditedPubClientFormVals(tempFormData);
    //             setIdbTempFormVals(tempFormData);
    //         }
    //     };
    //
    //     fetchFormData();
    // // }, [idbFormId]);
    // }, [idbFormId, showLogs]);

    // useEffect(() => {
    //     const fetchFormData = () => {
    //         if (showLogs) console.log('!!! >>> formValues from context', formValues);
    //         if (formValues && Object.keys(formValues).length > 0) {
    //             setIdbTempFormVals(formValues);
    //         }
    //     };
    //
    //     fetchFormData();
    // }, [formValues, idbFormId, showLogs]);

    // useEffect(() => {
    //     const handleBeforeUnload = () => {
    //         // Only save if the form is not fully submitted (you can use a flag or condition to check)
    //         if (recUpdatingEnabled && !pauseOnFormSubmit) {
    //             // saveTempSharedPubClientFormData(idbFormId, editedPubClientFormVals);
    //             // updateIDBFormValues();
    //             updateSaveMultipleFormValues(pubClientFormValsDiffObj);
    //         }
    //     };
    //
    //     window.addEventListener('beforeunload', handleBeforeUnload);
    //
    //     return () => {
    //         window.removeEventListener('beforeunload', handleBeforeUnload);
    //     };
    // // }, [editedPubClientFormVals, idbFormId]);
    // }, [formValues, idbFormId]);


    // useEffect( setShowLogs(
    useEffect(() => {
        if (props?.showLogs){
            setShowLogs(props.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [props.showLogs]);

    // TODO - HANDLE UPDATED PUBLIC CLIENT RECORD AFTER SUBMITTING FORM

    // useEffect( setPublicClientRec(props.publicClient), setRecUpdatingEnabled(
    useEffect(() => {
        // TODO - HANDLE UPDATED PUBLIC CLIENT RECORD AFTER SUBMITTING FORM
        if (!pauseOnFormSubmit) {
            if (props?.publicClient) {
                setPublicClientRec(props.publicClient);
                if (props.publicClient.publicUpdatingEnabled && props.publicClient.sharedPublicClientUpdateStatus !== SharedPublicClientStatus.DISABLED) {
                    setRecUpdatingEnabled(true);
                }
            }
        }
    }, [props.publicClient, pauseOnFormSubmit]);

    // useEffect( setPublicClientPublicAnimalRecs(props.publicClientPubAnimals);
    useEffect(() => {
        if (!pauseOnFormSubmit) {
            if (props?.publicClientPubAnimals) {
                // if (props.publicClientPubAnimals.length > 0) {
                //     setPublicClientPublicAnimalRecs(props.publicClientPubAnimals);
                // }
                if (showLogs) console.log('!!! >>> setPublicClientPublicAnimalRecs(props.publicClientPubAnimals', props.publicClientPubAnimals);
                setPublicClientPublicAnimalRecs(props.publicClientPubAnimals);
            }
            // else if (sharedPubAnimals && sharedPubAnimals.length > 0) {
            //     setPublicClientPublicAnimalRecs(sharedPubAnimals);
            // }
            // }, [props.publicClientPubAnimals, sharedPubAnimals]);
        }
    }, [props.publicClientPubAnimals, pauseOnFormSubmit, showLogs]);

    // useEffect( setPublicClientPublicAnimalRecs(providerSharedPubAnimals);
    useEffect(() => {
        if (!pauseOnFormSubmit) {
            if (providerSharedPubAnimals && providerSharedPubAnimals.length > 0) {
                if (showLogs) console.log('!!! >>> setPublicClientPublicAnimalRecs(providerSharedPubAnimals', providerSharedPubAnimals);
                setPublicClientPublicAnimalRecs(providerSharedPubAnimals);
            }
        }
    }, [providerSharedPubAnimals, pauseOnFormSubmit, showLogs]);

    // useEffect( providerSetPubAnimalIdsToQuery(updateResPubAnimalIds);
    useEffect(() => {
        if (!pauseOnFormSubmit) {
            if (updateResPubAnimalIds && updateResPubAnimalIds.length > 0) {
                providerSetPubAnimalIdsToQuery(updateResPubAnimalIds);
            }
        }
    }, [updateResPubAnimalIds, pauseOnFormSubmit]);

    // TODO - START HERE, FR, FR, USE THIS, BUT DEDUPE BY IDS

    // useEffect( fetch pubAnimals from IDB
    // useEffect(() => {
    //     const fetchPubAnimalsFromIDB = async () => {
    //         // const pubAnimalsData = await getLatestPubAnimalsDataIDB();
    //         const pubAnimalsData = await getLatestPubAnimalsDataIDB(publicClientRec.id);
    //         if (pubAnimalsData) {
    //             const idbPubAnimalsArrCopy = [...pubAnimalsData.data];
    //             idbPubAnimalsArrCopy.concat(pubAnimalsData.data);
    //             if (showLogs) console.log('!!! >>> idbPubAnimalsArrCopy', idbPubAnimalsArrCopy);
    //             // setEditedPubAnimalsArr(idbPubAnimalsArrCopy);
    //
    //             setIdbPubAnimalsArr(pubAnimalsData.data);
    //
    //         }
    //     };
    //
    //     if (!pauseOnFormSubmit && publicClientRec) {
    //         fetchPubAnimalsFromIDB();
    //     }
    // }, [publicClientRec, pauseOnFormSubmit]);

    // useEffect( fetch pubAnimals from IDB, merge with props, store in IDB, set edited pub animals arr
    useEffect(() => {
        if (!publicClientRec || pauseOnFormSubmit) return;

        async function fetchAndMergePublicAnimals() {
            if (publicClientRec && publicClientRec.id) {
                let refetchData = false;
                const idbData = await getLatestPubAnimalsDataIDB(publicClientRec.id);
                const idbAnimals = idbData ? idbData.data : [];
                console.log('!!! >>> idbAnimals', idbAnimals);
                setIdbPubAnimalsArr(idbAnimals);

                const propsAnimals = props.publicClientPubAnimals || [];
                console.log('!!! >>> propsAnimals', propsAnimals);

                if (propsAnimals.length > 0) {
                    if (idbAnimals.length > 0) {
                        const mergedAnimals = mergePublicAnimalsData(propsAnimals, idbAnimals);
                        setEditedPubAnimalsArr(mergedAnimals);
                        storeSharedPubAnimalsDataIDB(publicClientRec.id, mergedAnimals);
                        // setRefetchPubAnimalsData(true);
                        refetchData = true;
                    } else {
                        setEditedPubAnimalsArr(propsAnimals);
                        storeSharedPubAnimalsDataIDB(publicClientRec.id, propsAnimals);
                    }
                } else {
                    setEditedPubAnimalsArr(idbAnimals);
                }
                setRefetchPubAnimalsData(refetchData);
            }
         // setRefetchPubAnimalsData(false);
         // setRefetchPubAnimalsData(refetchData);
        }

        fetchAndMergePublicAnimals();
    }, [publicClientRec, props.publicClientPubAnimals, pauseOnFormSubmit, refetchPubAnimalsData]);

    // useEffect(() => {
    //     if (!publicClientRec || pauseOnFormSubmit) return
    //
    //     async function fetchAndMergePublicAnimals() {
    //         if (publicClientRec && publicClientRec.id) {
    //             const idbData = await getLatestPubAnimalsDataIDB(publicClientRec.id);
    //             const idbAnimals = idbData ? idbData.data : [];
    //
    //             if (props?.publicClientPubAnimals && props.publicClientPubAnimals.length > 0) {
    //
    //                 if (idbAnimals && idbAnimals.length > 0) {
    //                     const mergedAnimals = mergePublicAnimalsData(props.publicClientPubAnimals, idbAnimals);
    //                     setEditedPubAnimalsArr(mergedAnimals);
    //                 }
    //                 else {
    //                     setEditedPubAnimalsArr(props.publicClientPubAnimals);
    //                 }
    //             }
    //             else {
    //                 setEditedPubAnimalsArr(idbAnimals);
    //             }
    //         }
    //     }
    //
    //     fetchAndMergePublicAnimals();
    // }, [publicClientRec, props.publicClientPubAnimals, pauseOnFormSubmit]);

    // useEffect( setAnimalTypesEnums(AnimalType);
    useEffect(() => {
        if (AnimalType) {
            setAnimalTypesEnums(AnimalType);
        }
    }, [AnimalType]);

    // useEffect( setOrigClientFormVals(clientObj); setEditedClientFormVals(clientObj); setClientFormValsChanged(false); setClientFormValsDiff({});
    useEffect(() => {
        if (!pauseOnFormSubmit) {
            if (publicClientRec) {
                populatePubClientFormVals(publicClientRec);
                // const publicClientObj = {};
                // Object.keys(publicClientRec).forEach(key => {
                //     // console.log('key', key);
                //     // console.log('clientRec[key]', clientRec[key]);
                //     if (key !== 'id') {
                //         publicClientObj[key] = publicClientRec[key];
                //     }
                //     if (key === 'phone') {
                //         setPhoneNumber(publicClientRec[key]);
                //         if (!validatePhoneNumber(publicClientRec[key])) {
                //             setPhoneError('Please enter a valid US phone number: (123) 456-7890');
                //         }
                //         publicClientObj[key] = publicClientRec[key];
                //     }
                //     if (key === 'alternateContactPhone') {
                //         setAlternateContactPhoneNumber(publicClientRec[key]);
                //         if (!validatePhoneNumber(publicClientRec[key])) {
                //             setAlternateContactPhoneError('Please enter a valid US phone number: (123) 456-7890');
                //         }
                //         publicClientObj[key] = publicClientRec[key];
                //     }
                // });
                // setOrigPubClientFormVals(publicClientObj);
                // setEditedPubClientFormVals(publicClientObj);
                // setPubClientFormValsChanged(false);
                // setPubClientFormValsDiffObj({});
            }
        }
    }, [publicClientRec, pauseOnFormSubmit]);

    // useEffect( setOrigClientFormVals(clientObj); setEditedClientFormVals(clientObj); setClientFormValsChanged(false); setClientFormValsDiff({});
    // useEffect(() => {
    //     if (!pauseOnFormSubmit) {
    //         if (publicClientRec) {
    //             const publicClientObj = {};
    //             Object.keys(publicClientRec).forEach(key => {
    //                 // console.log('key', key);
    //                 // console.log('clientRec[key]', clientRec[key]);
    //                 if (key !== 'id') {
    //                     publicClientObj[key] = publicClientRec[key];
    //                 }
    //                 if (key === 'phone') {
    //                     setPhoneNumber(publicClientRec[key]);
    //                     if (!validatePhoneNumber(publicClientRec[key])) {
    //                         setPhoneError('Please enter a valid US phone number: (123) 456-7890');
    //                     }
    //                     publicClientObj[key] = publicClientRec[key];
    //                 }
    //                 if (key === 'alternateContactPhone') {
    //                     setAlternateContactPhoneNumber(publicClientRec[key]);
    //                     if (!validatePhoneNumber(publicClientRec[key])) {
    //                         setAlternateContactPhoneError('Please enter a valid US phone number: (123) 456-7890');
    //                     }
    //                     publicClientObj[key] = publicClientRec[key];
    //                 }
    //             });
    //             setOrigPubClientFormVals(publicClientObj);
    //             setEditedPubClientFormVals(publicClientObj);
    //             setPubClientFormValsChanged(false);
    //             setPubClientFormValsDiffObj({});
    //         }
    //     }
    // }, [publicClientRec, pauseOnFormSubmit]);

    function populatePubClientFormVals(pubClientData) {
        if (pubClientData) {
            const publicClientObj = {};
            Object.keys(pubClientData).forEach(key => {
                // console.log('key', key);
                // console.log('clientRec[key]', clientRec[key]);
                if (key !== 'id') {
                    publicClientObj[key] = pubClientData[key];
                }
                if (key === 'phone') {
                    setPhoneNumber(pubClientData[key]);
                    if (!validatePhoneNumber(pubClientData[key])) {
                        setPhoneError('Please enter a valid US phone number: (123) 456-7890');
                    }
                    publicClientObj[key] = pubClientData[key];
                }
                if (key === 'alternateContactPhone') {
                    setAlternateContactPhoneNumber(pubClientData[key]);
                    if (!validatePhoneNumber(pubClientData[key])) {
                        setAlternateContactPhoneError('Please enter a valid US phone number: (123) 456-7890');
                    }
                    publicClientObj[key] = pubClientData[key];
                }
                if (key === 'publicEditingEnabled') {
                    // TODO - !!!!!!! TOGGLE BACK IN
                    setRecUpdatingEnabled(pubClientData[key]);
                    publicClientObj[key] = pubClientData[key];
                }
            });
            setOrigPubClientFormVals(publicClientObj);
            setEditedPubClientFormVals(publicClientObj);
            setPubClientFormValsChanged(false);
            setPubClientFormValsDiffObj({});
        }
    }

    // useEffect( fetchAndCompareData();
    useEffect(() => {
        const fetchAndCompareData = async () => {
            // const [latestUpdate, tempFormVals] = await Promise.all([
            // const [latestUpdate] = await Promise.all([
            //     getLatestUpdatedPubClientFromIDB(),
            //     // getTempSharedPubClientFormData(idbFormId)
            // ]);

            let latestUpdate = null;
            if (publicClientRec) {
                const [latestUpdateData] = await Promise.all([
                    // getLatestUpdatedPubClientFromIDB(),
                    getLatestUpdateBySharedRouterPath(publicClientRec.sharedRouterPath),
                    // getTempSharedPubClientFormData(idbFormId)
                ]);
                latestUpdate = latestUpdateData;

                if (latestUpdate) {
                    setIdbPubClientVals(latestUpdate.data);

                    const idbTimestamp = latestUpdate.timestamp;
                    // if (showLogs) console.log('!!! >>> latestUpdate.timestamp', latestUpdate.timestamp);

                    if (publicClientRec && publicClientRec?._lastChangedAt) {
                        // const propsTimestamp = new Date(publicClientRec._lastUpdatedAt).getTime();
                        const propsTimestamp = publicClientRec._lastChangedAt;
                        // if (showLogs) console.log('!!! >>> publicClientRec._lastChangedAt', publicClientRec._lastChangedAt);
                        // if (showLogs) console.log('!!! >>> propsTimestamp', propsTimestamp);
                        if (idbTimestamp > propsTimestamp) {
                            populatePubClientFormVals(latestUpdate.data);
                            // if (JSON.stringify(latestUpdate.data) !== JSON.stringify(formValues)) {
                            //     // updateMultipleFormValues(latestUpdate.data);
                            // }
                            // setEditedPubClientVals(latestUpdate.data);
                        } else {
                            populatePubClientFormVals(publicClientRec);
                            // if (JSON.stringify(publicClientRec) !== JSON.stringify(formValues)) {
                            //     // updateMultipleFormValues(publicClientRec);
                            // }
                            // setEditedPubClientVals(publicClientRec);
                        }
                    }
                }
                // else if (Object.keys(formValues).length > 0) {
                //     if (JSON.stringify(formValues) !== JSON.stringify(editedPubClientFormVals)) {
                //         // updateMultipleFormValues(formValues);
                //     }
                // }
                else {
                    populatePubClientFormVals(publicClientRec);
                    // updateMultipleFormValues(publicClientRec);
                    // setEditedPubClientVals(publicClientRec);

                }
            }
            // TODO - START HERE, FR, FR - VERIFY THIS WORKS
            // If there's temporary form data, use it instead
            // if (tempFormVals) {
            //     if (showLogs) console.log('!!! >>> UPDATING WITH IDB tempFormVals', tempFormVals);
            //     // setEditedPubClientVals(tempFormData);
            //     const pubClientVals = {...editedPubClientFormVals, ...tempFormVals};
            //     if (showLogs) console.log(`!!! >>> UPDATING WITH IDB pubClientVals {...editedPubClientFormVals, ...tempFormVals}:`, pubClientVals);
            //     // setEditedPubClientFormVals(pubClientVals);
            //     // compare form data, set diff obj
            //     const diffObj = {};
            //     Object.keys(pubClientVals).forEach(key => {
            //         if (pubClientVals[key] !== origPubClientFormVals[key]) {
            //             if (showLogs) console.log(`!!! >>> UPDATING WITH IDB tempFormVals key: ${key}, value: ${pubClientVals[key]}`);
            //             diffObj[key] = pubClientVals[key];
            //         }
            //     });
            //     setPubClientFormValsDiffObj(diffObj);
            //     setEditedPubClientFormVals(pubClientVals);
            // }
        };

        fetchAndCompareData();
    // }, [publicClientRec, formId, showLogs]);
    // }, [publicClientRec, showLogs, formValues, idbFormId]);
    }, [publicClientRec, showLogs]);

    // useEffect( fetchAndCompareData();
    // useEffect(() => {
    //     const fetchAndCompareData = async () => {
    //         const [latestUpdate, tempFormVals] = await Promise.all([
    //             getLatestUpdatedPubClientFromIDB(),
    //             getTempSharedPubClientFormData(idbFormId)
    //         ]);
    //
    //         if (latestUpdate) {
    //             setIdbPubClientVals(latestUpdate.data);
    //
    //             const idbTimestamp = latestUpdate.timestamp;
    //             if (showLogs) console.log('!!! >>> latestUpdate.timestamp', latestUpdate.timestamp);
    //
    //             if (publicClientRec && publicClientRec?._lastChangedAt) {
    //                 // const propsTimestamp = new Date(publicClientRec._lastUpdatedAt).getTime();
    //                 const propsTimestamp = publicClientRec._lastChangedAt;
    //                 if (showLogs) console.log('!!! >>> publicClientRec._lastChangedAt', publicClientRec._lastChangedAt);
    //                 if (showLogs) console.log('!!! >>> propsTimestamp', propsTimestamp);
    //                 if (idbTimestamp > propsTimestamp) {
    //                     populatePubClientFormVals(latestUpdate.data);
    //                     // setEditedPubClientVals(latestUpdate.data);
    //                 } else {
    //                     populatePubClientFormVals(publicClientRec);
    //                     // setEditedPubClientVals(publicClientRec);
    //                 }
    //             }
    //         } else {
    //             populatePubClientFormVals(publicClientRec);
    //         }
    //     };
    //
    //     fetchAndCompareData();
    // }, [publicClientRec, showLogs]);

    // useEffect( setDisableFormButtons( based on recUpdatingEnabled, pauseOnFormSubmit, displayConfirmUpdateDialog);
    useEffect(() => {
        if (showLogs) console.log(`!!! useEffect( recUpdatingEnabled: ${recUpdatingEnabled}, pauseOnFormSubmit: ${pauseOnFormSubmit}, displayConfirmUpdateDialog: ${displayConfirmUpdateDialog}, disableFormButtons: ${disableFormButtons}`);
        if (!recUpdatingEnabled) {
            setDisableFormButtons(true);
        }
        else {
            if (disableFormButtons) {
                if (!pauseOnFormSubmit && !displayConfirmUpdateDialog) {
                    setDisableFormButtons(false);
                }
            }
            else {
                if (pauseOnFormSubmit && displayConfirmUpdateDialog) {
                    setDisableFormButtons(true);
                }
            }
        }
    }, [recUpdatingEnabled, pauseOnFormSubmit, displayConfirmUpdateDialog, disableFormButtons, showLogs]);

    const handlePhoneChange = (e) => {
        if (!pauseOnFormSubmit) {
            const formattedPhoneNumber = formatPhoneNumber(e.target.value);
            setPhoneNumber(formattedPhoneNumber);
        }
    };

    const handleAlternateContactPhoneChange = (e) => {
        if (!pauseOnFormSubmit) {
            const formattedPhoneNumber = formatPhoneNumber(e.target.value);
            setAlternateContactPhoneNumber(formattedPhoneNumber);
        }
    };

    const handlePhoneBlur = () => {
        if (!pauseOnFormSubmit) {
            if (!phoneNumber) {
                setPhoneError('Please enter a valid US phone number: (123) 456-7890');
            }
            else if (!validatePhoneNumber(phoneNumber)) {
                setPhoneError('Please enter a valid US phone number: (123) 456-7890');
            }
            else {
                setPhoneError('');
                if (phoneNumber) {
                    // handleAmpUserFormChange('phone', phoneNumber);
                    const phoneE164Formatted = convertToE164(phoneNumber);
                    setEditedPubClientFormVals({
                        ...editedPubClientFormVals,
                        phone: phoneNumber,
                        phoneE164: phoneE164Formatted,
                    });
                    if (origPubClientFormVals.phone !== phoneNumber) {
                        // setPubClientFormValsChanged(true);
                        // setPubClientFormValsDiffObj({
                        //     ...pubClientFormValsDiffObj,
                        //     phone: phoneNumber,
                        //     phoneE164: phoneE164Formatted,
                        // });

                        setPubClientFormValsChanged(true);
                        setPubClientFormValsDiffObj(prevState => ({
                            ...prevState,
                            phone: phoneNumber,
                            phoneE164: phoneE164Formatted,
                        }));
                    }
                    else {
                        // const pubClientFormValsDiff = {...pubClientFormValsDiffObj};
                        // delete pubClientFormValsDiff.phone;
                        // delete pubClientFormValsDiff.phoneE164;
                        // setPubClientFormValsDiffObj(pubClientFormValsDiff);

                        setPubClientFormValsDiffObj(prevState => {
                            const { phone, phoneE164, ...rest } = prevState;  // Destructure to remove fields
                            return rest;
                        });
                    }
                }
            }
        }
    };

    const handleAlternateContactPhoneBlur = () => {
        if (!pauseOnFormSubmit) {
            if (!alternateContactPhoneNumber) {
                setAlternateContactPhoneError('Please enter a valid US phone number: (123) 456-7890');
            }
            else if (!validatePhoneNumber(alternateContactPhoneNumber)) {
                setAlternateContactPhoneError('Please enter a valid US phone number: (123) 456-7890');
            }
            else {
                setAlternateContactPhoneError('');
                if (alternateContactPhoneNumber) {
                    const phoneE164Formatted = convertToE164(alternateContactPhoneNumber);
                    setEditedPubClientFormVals({
                        ...editedPubClientFormVals,
                        alternateContactPhone: alternateContactPhoneNumber,
                        alternateContactPhoneE164: phoneE164Formatted,
                    });
                    if (origPubClientFormVals.alternateContactPhone !== alternateContactPhoneNumber) {
                        setPubClientFormValsChanged(true);
                        setPubClientFormValsDiffObj(prevState => ({
                            ...prevState,
                            alternateContactPhone: alternateContactPhoneNumber,
                            alternateContactPhoneE164: phoneE164Formatted,
                        }));
                    }
                    else {
                        setPubClientFormValsDiffObj(prevState => {
                            const { alternateContactPhone, alternateContactPhoneE164, ...rest } = prevState;  // Destructure to remove fields
                            return rest;
                        });
                    }
                }
            }
        }
    };

    const handleIsMiniToggle = (event) => {
        setIsMiniIsToggled(event.target.checked);
    };

    function dateFromDate(inputDateVal) {
        if (showLogs) console.log('!!! >>> dateFromDate inputDateVal', inputDateVal);
        const ret = {};
        const formDateVal = inputDateVal.format('YYYY-MM-DD');
        // const dateVal = inputDateVal.format('YYYY-MM-DD');
        // const dateVal = inputDateVal.format('LL');
        ret['formDateVal'] = formDateVal;
        // const displayDateVal = inputDateVal.format('LL');
        // ret['displayDateVal'] = `${displayDateVal}`;
        return ret;
    }

    function handleAnimalBirthdayDateChange(newBirthdayDate) {
        setEditedAnimalBirthdayDate(newBirthdayDate);
        if (newBirthdayDate === null) {
            // if null, add to pub animal error messages
            setPubAnimalErrorMessages({
                ...pubAnimalErrorMessages,
                birthday: 'Please enter a valid date',
            });

            return;
            // setEditedAnimalBirthdayDateVals({
            //     formDateVal: '',
            // });
            // const updatedPubAnimalFormVals = {
            //     ...editedPubAnimalFormVals,
            //     birthday: '',
            // };
            // setEditedPubAnimalFormVals(updatedPubAnimalFormVals);
        }
        const dateValsObj = dateFromDate(newBirthdayDate);
        setEditedAnimalBirthdayDateVals(dateValsObj);
        const updatedPubAnimalFormVals = {
            ...editedPubAnimalFormVals,
            birthday: dateValsObj.formDateVal,
        };
        setEditedPubAnimalFormVals(updatedPubAnimalFormVals);

        // if valid date, remove from pub animal error messages
        const newErrors = { ...pubAnimalErrorMessages };
        newErrors.birthday = '';
        setPubAnimalErrorMessages(newErrors);
    }

    // useEffect( handlePubAnimalFormChange('isMiniature', isMiniIsToggled);
    useEffect(() => {
        handlePubAnimalFormChange('isMiniature', isMiniIsToggled);
    }, [isMiniIsToggled]);

    const handleAnimalTypeChange = (event) => {
        const {target: {value}} = event;
        setEditedSelectedAnimalType(value);
        setEditedPubAnimalFormVals({
            ...editedPubAnimalFormVals,
            animalType: value
        });
    }

    function handleFormChange(key, value) {
        if (!pauseOnFormSubmit && recUpdatingEnabled) {
            setEditedPubClientFormVals({
                ...editedPubClientFormVals,
                [key]: value,
            });
            // updateFormValues(key, value);

            // if (origPubClientFormVals[key] !== value) {
            //     setPubClientFormValsChanged(true);
            //     setPubClientFormValsDiffObj({
            //         ...pubClientFormValsDiffObj,
            //         [key]: value,
            //     });
            // }
            // else {
            //     const pubClientFormValsDiff = {...pubClientFormValsDiffObj};
            //     delete pubClientFormValsDiff[key];
            //     setPubClientFormValsDiffObj(pubClientFormValsDiff);
            // }

            // Dynamic field validation
            let newErrors = { ...pubClientErrorMessages };

            // Check if the field is empty
            if (!value) {
                // newErrors[key] = `${key} is required`;  // Set error message if empty
                newErrors[key] = `${camelCaseToTitleCase(key)} is required`;  // Set error message if empty
            } else {
                newErrors[key] = '';  // Clear error message if field is filled
            }

            // Special validation for email field
            // if (key === 'email' && value) {
            //     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            //     if (!emailRegex.test(value)) {
            //         newErrors.email = 'Invalid email address';
            //     } else {
            //         newErrors.email = '';  // Clear email error if valid
            //     }
            // }

            setPubClientErrorMessages(newErrors);  // Update error messages state
        }
    }

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validateForm = () => {
        const newErrors = {};

        // Check if all fields are filled
        Object.keys(editedPubClientFormVals).forEach((key) => {
            if (!editedPubClientFormVals[key]) {
                newErrors[key] = `${key} is required`;
            }
        });

        // Validate email field
        if (editedPubClientFormVals.email && !isValidEmail(editedPubClientFormVals.email)) {
            newErrors.email = 'Invalid email address';
        }

        setPubClientErrorMessages(newErrors);

        // Return true if there are no errors
        return Object.keys(newErrors).length === 0;
    };

    // useEffect( setPubClientFormValsChanged( based on pubClientFormValsDiffObj
    useEffect(() => {
        if (pubClientFormValsDiffObj && Object.keys(pubClientFormValsDiffObj).length > 0) {
            setPubClientFormValsChanged(true);
        }
        if (pubClientFormValsDiffObj && Object.keys(pubClientFormValsDiffObj).length === 0) {
            setPubClientFormValsChanged(false);
        }
    }, [pubClientFormValsDiffObj]);

    async function handleFormSubmit() {
        try {
            // first check for any empty required fields
            const newErrors = {};
            Object.keys(editedPubClientFormVals).forEach((key) => {
                // if (!editedPubClientFormVals[key]) {
                if (publicSharedEditPublicClientAttrs.includes(key) && !editedPubClientFormVals[key]) {
                    // newErrors[key] = `${key} is required`;
                    newErrors[key] = `${camelCaseToTitleCase(key)} is required`;
                }
            });
            let pubClientErrorsExist = false;
            // if any required fields errors, set error messages and return
            if (Object.keys(newErrors).length > 0) {
                if (showLogs) console.error('handleFormSubmit newErrors', newErrors);
                setPubClientErrorMessages(newErrors);
                pubClientErrorsExist = true;
                // return;
            }
            handlePhoneBlur();
            handleAlternateContactPhoneBlur();
            if (phoneError || alternateContactPhoneError) {
                pubClientErrorsExist = true;
            }
            if (pubClientErrorsExist) {
                return;
            }
            // Assume pub animals are valid, based on create or update animal form validation

            // const diffs = {};
            // publicSharedEditPublicClientAttrs.forEach((key) => {
            //     // if (editedPubClientFormVals[key] !== origPubClientFormVals[key]) {
            //     if (editedPubClientFormVals[key] !== origPubClientFormVals[key] && !(key in pubClientFormValsDiffObj)) {
            //         diffs[key] = editedPubClientFormVals[key];
            //     }
            // });

            // setPubClientFormValsDiffObj(diffs);
            // Only update formDiffsObj if there are new diffs
            // if (Object.keys(diffs).length > 0) {
            //     setPubClientFormValsDiffObj(prevState => ({
            //         ...prevState,
            //         ...diffs
            //     }));
            // }

            // prevent unintended val updates passed to open confirmation dialog
            if (pauseOnFormSubmit || displayConfirmUpdateDialog) {
                if (showLogs) console.error('handleFormSubmit pauseOnFormSubmit or displayConfirmUpdateDialog is true, returning');
                return;
            }
            else {
                // make sure both pauseOnFormSubmit, displayConfirmUpdateDialog
                // are set true after validating logic blocks are cleared,
                // then reset to false when done
                if (recUpdatingEnabled) {
                // if (publicClientRec) {
                    // only allow update submit if public updating is enabled
                    // if (pubClientFormValsChanged && editedPubAnimalsArr.length > 0) {
                    if (pubClientFormValsChanged) {
                    // if (Object.keys(diffs).length > 0) {
                        // TODO - this may need updating for cases where farrier re-enables editing for a previously updated record and submitted form
                        if (editedPubAnimalsArr.length > 0) {
                            // TODO - this may need updating for cases where farrier re-enables editing for a previously updated record and submitted form
                            if (phoneNumber) {
                                handlePhoneBlur();
                            }
                            else {
                                setPhoneError('Please enter a valid US phone number: (123) 456-7890');
                                alert('Please enter a valid US phone number: (123) 456-7890');
                            }

                            if (alternateContactPhoneNumber) {
                                handleAlternateContactPhoneBlur();
                            }
                            else {
                                setAlternateContactPhoneError('Please enter a valid US phone number: (123) 456-7890');
                                alert('Please enter a valid US phone number: (123) 456-7890');
                            }

                            if (!phoneError && !alternateContactPhoneError) {

                                storeSharedPubAnimalsDataIDB(editedPubAnimalsArr, publicClientRec.id);
                                setPauseOnFormSubmit(true);
                                setDisableFormButtons(true);
                                setDisplayConfirmUpdateDialog(true);
                            }
                            else {
                                if (showLogs) console.error('handleFormSubmit phoneError', phoneError);
                                alert(phoneError);
                            }
                        }
                        else {
                            if (showLogs) console.error('handleFormSubmit editedPubAnimalsArr is empty');
                            alert('Please add all your animals (at least one) before submitting.');
                        }
                    }
                    else {
                        if (showLogs) console.error('handleFormSubmit pubClientFormValsChanged is false');
                        alert('Please update all client info fields before submitting.');
                    }
                }
                else {
                    if (showLogs) console.error('handleFormSubmit recUpdatingEnabled is false');
                    alert('Record updating is disabled. Please contact your farrier to enable updating to submit further changes.');
                }
            }
        }
        catch (error) {
            if (showLogs) console.error('handleFormSubmit error', error);
        }
    }

    function handleAddPubAnimal() {
        // setAddPubAnimal(!addPubAnimal);
        setAddPubAnimal(!addPubAnimal);
        setUpdateExistingPubAnimal(false);
        setPubAnimalErrorMessages({});
    }

    function handleUpdateExistingPubAnimal(idx) {
        setPubAnimalErrorMessages({});

        setAddPubAnimal(false);
        setPubAnimalsArrCurrentIndex(idx);
        const idxPubAnimalFormVals = { ...editedPubAnimalsArr[idx] };
        // setEditedPubAnimalFormVals(idxPubAnimalFormVals);
        setEditedPubAnimalFormVals(editedPubAnimalsArr[idx]);
        setEditedSelectedAnimalType(idxPubAnimalFormVals?.animalType || '');
        if (idxPubAnimalFormVals?.birthday) {
            const initialDate = dayjs(idxPubAnimalFormVals.birthday);
            setEditedAnimalBirthdayDate(initialDate);
        }
        setIsMiniIsToggled(idxPubAnimalFormVals?.isMiniature || false);
        // setEditedAnimalBirthdayDate(moment(idxPubAnimalFormVals?.birthday) || null);
        // setAddPubAnimal(true);
        setUpdateExistingPubAnimal(true);

    }

    function handleRemoveExistingPubAnimal(idx) {
        const updatedPubAnimals = [...editedPubAnimalsArr];
        updatedPubAnimals.splice(idx, 1);
        setEditedPubAnimalsArr(updatedPubAnimals);
        if (pubAnimalsArrCurrentIndex === idx) {
            setPubAnimalsArrCurrentIndex(null);
            setAddPubAnimal(false);
            setUpdateExistingPubAnimal(false);
            setEditedPubAnimalFormVals(createPubAnimalFormObj);
            setIsMiniIsToggled(false);
            setEditedSelectedAnimalType('');
            setEditedAnimalBirthdayDate(null);
            setEditedAnimalBirthdayDateVals({});
        }
    }

    function handlePubAnimalFormChange(key, value) {
        let typedValue = value;
        if (key === 'age') {
            typedValue = convertToFullIntegerHandleNaN(value);
        }
        setEditedPubAnimalFormVals({
            ...editedPubAnimalFormVals,
            [key]: typedValue,
        });

        let newErrors = { ...pubAnimalErrorMessages };

        // Check if the field is empty
        // if (!value) {
        // if (key !== 'isMiniature' && !value) {
        if (publicSharedPubAnimalAttrs.includes(key) && key !== 'isMiniature' && !value) {
            // newErrors[key] = `${key} is required`;  // Set error message if empty
            newErrors[key] = `${camelCaseToTitleCase(key)} is required`;  // Set error message if empty
        } else {
            newErrors[key] = '';  // Clear error message if field is filled
        }

        setPubAnimalErrorMessages(newErrors);  // Update error messages state
    }

    function handleAddPubAnimalDone() {
        // first, check if all required fields are filled
        let hasErrors = false;
        const newErrors = {};
        Object.keys(editedPubAnimalFormVals).forEach((key) => {
            // if (!editedPubAnimalFormVals[key]) {
            // if (key !== 'isMiniature' && !editedPubAnimalFormVals[key]) {
            if (publicSharedPubAnimalAttrs.includes(key) && key !== 'isMiniature' && !editedPubAnimalFormVals[key]) {
                // newErrors[key] = `${key} is required`;
                newErrors[key] = `${camelCaseToTitleCase(key)} is required`;
                hasErrors = true;
            }
        });
        setPubAnimalErrorMessages(newErrors);
        if (hasErrors) {
            return;
        }

        setEditedPubAnimalsArr([
            ...editedPubAnimalsArr,
            editedPubAnimalFormVals,
        ]);
        // setAddPubAnimal(false);
        // setEditedPubAnimalFormVals(createPubAnimalFormObj);
        // setIsMiniIsToggled(false);
        // setEditedSelectedAnimalType('');
        // setEditedAnimalBirthdayDate(null);
        // setEditedAnimalBirthdayDateVals({});

        // setPubAnimalErrorMessages({});
        setResetPubAnimalForm(true);
        // setPubAnimalErrorMessages({});
    }

    function handleUpdateExistingPubAnimalDone() {
        // first, check if all required fields are filled
        let hasErrors = false;
        const newErrors = {};
        Object.keys(editedPubAnimalFormVals).forEach((key) => {
            // if (!editedPubAnimalFormVals[key]) {
            // if (key !== 'isMiniature' && !editedPubAnimalFormVals[key]) {
            if (publicSharedPubAnimalAttrs.includes(key) && key !== 'isMiniature' && !editedPubAnimalFormVals[key]) {
                // newErrors[key] = `${key} is required`;
                newErrors[key] = `${camelCaseToTitleCase(key)} is required`;
                hasErrors = true;
            }
        });
        setPubAnimalErrorMessages(newErrors);
        if (hasErrors) {
            return;
        }

        const updatedPubAnimals = [...editedPubAnimalsArr];
        updatedPubAnimals[pubAnimalsArrCurrentIndex] = editedPubAnimalFormVals;
        setEditedPubAnimalsArr(updatedPubAnimals);
        // setAddPubAnimal(false);
        // setUpdateExistingPubAnimal(false);
        // setEditedPubAnimalFormVals(createPubAnimalFormObj);
        // setIsMiniIsToggled(false);
        // setEditedSelectedAnimalType('');
        // setEditedAnimalBirthdayDate(null);
        // setEditedAnimalBirthdayDateVals({});
        // setPubAnimalsArrCurrentIndex(null);
        setResetPubAnimalForm(true);
    }

    function resetPubAnimalFormVals() {
        // setPubAnimalErrorMessages({});
        setEditedPubAnimalFormVals(createPubAnimalFormObj);
        setIsMiniIsToggled(false);
        setEditedSelectedAnimalType('');
        setEditedAnimalBirthdayDate(null);
        setEditedAnimalBirthdayDateVals({});
        setAddPubAnimal(false);
        setUpdateExistingPubAnimal(false);
        setPubAnimalsArrCurrentIndex(null);
    }

    // useEffect( if (resetPubAnimalForm) resetPubAnimalFormVals();
    useEffect(() => {
        if (resetPubAnimalForm) {
            setPubAnimalErrorMessages({});
            resetPubAnimalFormVals();
            setResetPubAnimalForm(false);
        }
    }, [resetPubAnimalForm]);

    function onConfirmUpdateDialogClose() {
        // setDisableFormButtons(false);
        setDisplayConfirmUpdateDialog(false);
        setPauseOnFormSubmit(false);
    }

    async function onConfirmUpdateDone(updateRes) {
        if (showLogs) console.log('onConfirmUpdateDone updateRes', updateRes);
        if (updateRes) {
            setUpdateResObj(updateRes);
            try {
                if (updateRes?.updatePubClientSuccess) {
                    setUpdatedPubClientResObj(updateRes.updatedPublicClientFields);
                }
                // if (updateRes?.updatePubClientSuccess && updateRes?.updateClientSuccess && updateRes?.addAnimalsSuccess && updateRes?.addPubAnimalsSuccess) {
                //     await clearTempSharedPubClientFormData(idbFormId);
                // }
                if (updateRes?.addPubAnimalsSuccess) {
                    setUpdateResPubAnimalIds(updateRes.addedPubAnimalIds);
                }
            }
            catch (error) {
                if (showLogs) console.error('onConfirmUpdateDone error', error);
            }
        }
    }

    // useEffect( addUpdatedPubClientToIDB( based on updatedPubClientResObj
    useEffect(() => {
        if (!pauseOnFormSubmit) {

            if (updatedPubClientResObj) {
                const editedPubClientFormValsCopy = {...editedPubClientFormVals};
                // const keysToIgnore = ['id', 'updatedAt', '_lastChangedAt', '_version'];
                const keysToIgnore = ['id', 'updatedAt', '_version'];
                // // go through and update editedPubClientFormVals fields, based on entries in updatedPubClientResObj
                Object.entries(updatedPubClientResObj).forEach(([key, value]) => {
                    if (!keysToIgnore.includes(key)) {
                        editedPubClientFormValsCopy[key] = value;
                    }
                });
                editedPubClientFormValsCopy.publicUpdatingEnabled = false;
                editedPubClientFormValsCopy.publicClientStatus = PublicClientStatus.ACTIVE;
                editedPubClientFormValsCopy.sharedPublicClientStatus = SharedPublicClientStatus.DISABLED;
                editedPubClientFormValsCopy.sharedPublicClientUpdateStatus = SharedPublicClientUpdateStatus.CLIENT_UPDATED;

                // setOrigPubClientFormVals(editedPubClientFormValsCopy);
                // setEditedPubClientFormVals(editedPubClientFormValsCopy);
                populatePubClientFormVals(editedPubClientFormValsCopy);
                // addUpdatedPubClientToIDB(updatedPubClientResObj);
                addUpdatedPubClientToIDB(editedPubClientFormValsCopy);
                // addUpdatedPubClientToIDB(updatedPubClientResObj);

                setRecUpdatingEnabled(false);
                // resetFormValues();
                // resetPubClientIDBFormVals();
            }
        }
    }, [updatedPubClientResObj, pauseOnFormSubmit]);

    // function handlePubClientFieldBlur(event) {
    //     const { name, value } = event.target;
    //
    //     // Check if the field value is different from the original form value
    //     // if (editedPubClientFormVals[name] !== value) {
    //     if (origPubClientFormVals[name] !== value) {
    //         // setFormDiffsObj(prevState => ({
    //         //     ...prevState,
    //         //     [name]: value,
    //         // }));
    //         setPubClientFormValsDiffObj(prevState => ({
    //             ...prevState,
    //             [name]: value,
    //         }));
    //     } else {
    //         // If the value matches the original, remove it from the diffs object
    //         // setFormDiffsObj(prevState => {
    //         //     const updatedDiffs = { ...prevState };
    //         //     delete updatedDiffs[name];
    //         //     return updatedDiffs;
    //         // });
    //         setPubClientFormValsDiffObj(prevState => {
    //             const updatedDiffs = { ...prevState };
    //             delete updatedDiffs[name];
    //             return updatedDiffs;
    //         });
    //     }
    // }

    function handlePubClientFieldBlur(event) {
        const { name, value } = event.target;

        // Check if the field value is different from the original form value
        if (origPubClientFormVals[name] !== value) {
            setPubClientFormValsDiffObj(prevState => ({
                ...prevState,
                [name]: value,
            }));
            // Save the diff to IndexedDB using the onBlurUpdateSaveFormValues function
            // onBlurUpdateSaveFormValues(name, value);
        }
        else {
            setPubClientFormValsDiffObj(prevState => {
                const updatedDiffs = { ...prevState };
                delete updatedDiffs[name];
                return updatedDiffs;
            });
            // Remove the field from IndexedDB since it matches the original value
            // onBlurRemoveFieldFromIDB(name);
        }
    }

    function animalsAccordionHeaderTextFormatter(animalData) {
        return `Name: ${animalData?.name || '-'}`;
    }

    function pubAnimalsAccordionDetailsFactory(animalData) {
        return (
            <>
                {/*<div>*/}
                {/*    <span>Animal Type: {animalData?.animalType || '-'}</span>*/}
                {/*</div>*/}
                <SharedPubAnimalInfoCard
                    showLogsProp={showLogs}
                    animalData={animalData}
                    />
                {/*<div>*/}
                {/*    <span>Birthday: {animalData?.birthday || '-'}</span>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <span>Age: {animalData?.age || '-'}</span>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <span>Is Miniature: {animalData?.isMiniature ? 'Yes' : 'No'}</span>*/}
                {/*</div>*/}
            </>
        );
    }

    // const PublicAnimalAccordionDetails = ({ eachAccordionItem, showLogs }) => {
    const PublicAnimalAccordionDetails = ({ accordionDetailsItem, showLogs }) => {
        return (
            <>
                <SharedPubAnimalInfoCard
                    showLogsProp={showLogs}
                    // animalData={eachAccordionItem}
                    animalData={accordionDetailsItem}
                />
            </>
        );
    }


    function pubAnimalsAccordionButtonsFactory(animalData, idx) {
        return (
            <Box display="flex" justifyContent="space-between">
                <BaseStyledButton
                    label={"Update"}
                    // onClick={() => handleUpdateExistingPubAnimal(animalData.index)}
                    onClick={() => handleUpdateExistingPubAnimal(idx)}
                />
                <BaseStyledDeleteButton
                    label={"Remove"}
                    // onClick={() => handleRemoveExistingPubAnimal(animalData.index)}
                    onClick={() => handleRemoveExistingPubAnimal(idx)}
                />
            </Box>
        );
    }

    // TODO - START HERE, FR, FR,
    //  ON UPDATE EXISTING PUB ANIMAL,
    //  HANDLE REPOPULATE OF ANIMAL TYPE AND BIRTHDAY
    return (
        <div>
            <h2>New Client Form</h2>
            {/*{!recUpdatingEnabled ? (*/}
            {/*    <div>*/}
            {/*        <h3>Data submitted, form is read only now</h3>*/}
            {/*    </div>*/}
            {/*) : null}*/}
            <Divider>
                <Chip label="Client Info"/>
            </Divider>
            <div>
                <StyledTextField
                    id="name"
                    label="Name"
                    name={"name"}
                    value={editedPubClientFormVals?.name || ''}
                    // readOnly={!recUpdatingEnabled}
                    readOnly={disableFormButtons}
                    onChange={(event) => handleFormChange('name', event.target.value)}
                    onBlur={handlePubClientFieldBlur}
                    error={!!pubClientErrorMessages.name}
                    helperText={pubClientErrorMessages.name || ''}
                    sx={{
                        borderColor: pubClientErrorMessages.name ? 'red' : '',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: pubClientErrorMessages.name ? 'red' : '',
                            },
                            '&:hover fieldset': {
                                borderColor: pubClientErrorMessages.name ? 'red' : '',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: pubClientErrorMessages.name ? 'red' : '',
                            },
                        },
                    }}
                />
            </div>
            <div>
                <StyledTextField
                    id="email"
                    label="Email"
                    name={"email"}
                    value={editedPubClientFormVals?.email || ''}
                    readOnly={disableFormButtons}
                    onChange={(event) => handleFormChange('email', event.target.value)}
                    onBlur={handlePubClientFieldBlur}
                    error={!!pubClientErrorMessages.email}
                    helperText={pubClientErrorMessages.email || ''}
                    sx={{
                        borderColor: pubClientErrorMessages.email ? 'red' : '',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: pubClientErrorMessages.email ? 'red' : '',
                            },
                            '&:hover fieldset': {
                                borderColor: pubClientErrorMessages.email ? 'red' : '',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: pubClientErrorMessages.email ? 'red' : '',
                            },
                        },
                    }}
                />
            </div>
            <div>
                <StyledTextField
                    id="phone"
                    label="Phone"
                    error={!!phoneError}
                    helperText={phoneError || 'Format: (123) 456-7890'}
                    value={phoneNumber || ''}
                    onChange={handlePhoneChange}
                    onBlur={handlePhoneBlur}
                    // readOnly={!recUpdatingEnabled}
                    readOnly={disableFormButtons}
                    // onChange={(event) => handleFormChange('phone', event.target.value)}
                />
            </div>
            <div>
                <TooltipWrapper tooltipText={clientFormsTooltipTextsObj.streetAddress}>
                    <StyledTextField
                        id="street-address"
                        name="streetAddress"
                        label="Street Address"
                        value={editedPubClientFormVals?.streetAddress || ''}
                        readOnly={!recUpdatingEnabled}
                        // disabled={disableFormButtons}
                        // onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                        // onChange={(event) => updateEditedClientFormVals('streetAddress', event.target.value)}
                        onChange={(event) => handleFormChange(event.target.name, event.target.value)}
                        error={!!pubClientErrorMessages.streetAddress}
                        helperText={pubClientErrorMessages.streetAddress || ''}
                        sx={{
                            borderColor: pubClientErrorMessages.streetAddress ? 'red' : '',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: pubClientErrorMessages.streetAddress ? 'red' : '',
                                },
                                '&:hover fieldset': {
                                    borderColor: pubClientErrorMessages.streetAddress ? 'red' : '',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: pubClientErrorMessages.streetAddress ? 'red' : '',
                                },
                            },
                        }}
                    />
                </TooltipWrapper>
            </div>
            <div>
                <StyledTextField
                    id="city"
                    name="city"
                    label="City"
                    value={editedPubClientFormVals?.city || ''}
                    // readOnly={!recUpdatingEnabled}
                    readOnly={disableFormButtons}
                    onChange={(event) => handleFormChange(event.target.name, event.target.value)}
                    onBlur={handlePubClientFieldBlur}
                    error={!!pubClientErrorMessages.city}
                    helperText={pubClientErrorMessages.city || ''}
                    sx={{
                        borderColor: pubClientErrorMessages.city ? 'red' : '',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: pubClientErrorMessages.city ? 'red' : '',
                            },
                            '&:hover fieldset': {
                                borderColor: pubClientErrorMessages.city ? 'red' : '',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: pubClientErrorMessages.city ? 'red' : '',
                            },
                        },
                    }}

                />
            </div>
            <div>
                <StyledTextField
                    id="state"
                    name="state"
                    label="State"
                    value={editedPubClientFormVals?.state || ''}
                    // readOnly={!recUpdatingEnabled}
                    readOnly={disableFormButtons}
                    onChange={(event) => handleFormChange(event.target.name, event.target.value)}
                    onBlur={handlePubClientFieldBlur}
                    error={!!pubClientErrorMessages.state}
                    helperText={pubClientErrorMessages.state || ''}
                    sx={{
                        borderColor: pubClientErrorMessages.state ? 'red' : '',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: pubClientErrorMessages.state ? 'red' : '',
                            },
                            '&:hover fieldset': {
                                borderColor: pubClientErrorMessages.state ? 'red' : '',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: pubClientErrorMessages.state ? 'red' : '',
                            },
                        },
                    }}
                />
            </div>
            <div>
                <StyledTextField
                    id="zip-code"
                    name="zipCode"
                    label="Zip Code"
                    value={editedPubClientFormVals?.zipCode || ''}
                    // readOnly={!recUpdatingEnabled}
                    readOnly={disableFormButtons}
                    onChange={(event) => handleFormChange(event.target.name, event.target.value)}
                    onBlur={handlePubClientFieldBlur}
                    error={!!pubClientErrorMessages.zipCode}
                    helperText={pubClientErrorMessages.zipCode || ''}
                    sx={{
                        borderColor: pubClientErrorMessages.zipCode ? 'red' : '',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: pubClientErrorMessages.zipCode ? 'red' : '',
                            },
                            '&:hover fieldset': {
                                borderColor: pubClientErrorMessages.zipCode ? 'red' : '',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: pubClientErrorMessages.zipCode ? 'red' : '',
                            },
                        },
                    }}
                />
            </div>
            <div>
                <StyledTextField
                    id="alternateContactName"
                    label="Alternate Contact Name"
                    name={'alternateContactName'}
                    value={editedPubClientFormVals?.alternateContactName || ''}
                    // readOnly={!recUpdatingEnabled}
                    readOnly={disableFormButtons}
                    onChange={(event) => handleFormChange('alternateContactName', event.target.value)}
                    onBlur={handlePubClientFieldBlur}
                    error={!!pubClientErrorMessages.alternateContactName}
                    helperText={pubClientErrorMessages.alternateContactName || ''}
                    sx={{
                        borderColor: pubClientErrorMessages.alternateContactName ? 'red' : '',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: pubClientErrorMessages.alternateContactName ? 'red' : '',
                            },
                            '&:hover fieldset': {
                                borderColor: pubClientErrorMessages.alternateContactName ? 'red' : '',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: pubClientErrorMessages.alternateContactName ? 'red' : '',
                            },
                        },
                    }}
                />
            </div>
            <div>
                <StyledTextField
                    id="alternateContactPhone"
                    label="Alternate Contact Phone"
                    error={!!alternateContactPhoneError}
                    helperText={alternateContactPhoneError || 'Format: (123) 456-7890'}
                    value={alternateContactPhoneNumber || ''}
                    onChange={handleAlternateContactPhoneChange}
                    onBlur={handleAlternateContactPhoneBlur}
                    // readOnly={!recUpdatingEnabled}
                    readOnly={disableFormButtons}
                />
            </div>
            <Divider/>
            {(updateExistingPubAnimal || addPubAnimal) && !disableFormButtons ? (<div>
                    <Divider>
                        <Chip label="Animal Info"/>
                    </Divider>
                    <div>
                        <StyledTextField
                            id="pub-animal-name"
                            name="name"
                            label="Name"
                            value={editedPubAnimalFormVals?.name || ''}
                            // readOnly={!recUpdatingEnabled}
                            readOnly={disableFormButtons}
                            onChange={(event) => handlePubAnimalFormChange(event.target.name, event.target.value)}
                            error={!!pubAnimalErrorMessages.name}
                            helperText={pubAnimalErrorMessages.name || ''}
                            sx={{
                                borderColor: pubAnimalErrorMessages.name ? 'red' : '',
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: pubAnimalErrorMessages.name ? 'red' : '',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: pubAnimalErrorMessages.name ? 'red' : '',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: pubAnimalErrorMessages.name ? 'red' : '',
                                    },
                                },
                            }}
                        />
                    </div>
                    <div>
                        <FormControl sx={{m: 1, width: 300}} error={!!pubAnimalErrorMessages.animalType}>
                            <InputLabel id={`edit-animal-animal-type-input-label`}>Animal Type</InputLabel>
                            <Select
                                labelId={`edit-animal-animal-type-label-id`}
                                id={`edit-animal-animal-type-select`}
                                name={`animalType`}
                                value={editedSelectedAnimalType || ''}
                                // onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                                onChange={(e) => handleAnimalTypeChange(e)}
                                MenuProps={MenuProps}
                                // error={!!pubAnimalErrorMessages.animalType}
                                // helperText={pubAnimalErrorMessages.animalType || ''}
                                sx={{
                                    borderColor: pubAnimalErrorMessages.animalType ? 'red' : '',
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: pubAnimalErrorMessages.animalType ? 'red' : '',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: pubAnimalErrorMessages.animalType ? 'red' : '',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: pubAnimalErrorMessages.animalType ? 'red' : '',
                                        },
                                    },
                                }}
                            >
                                {Object.entries(animalTypesEnums).map(([key, val]) => (
                                    <MenuItem
                                        key={generateUniqueKey(key)}  // Use the key for generating a unique key
                                        value={val}  // Use the key as the value for the MenuItem
                                    >
                                        {val}
                                    </MenuItem>
                                ))}
                            </Select>
                                {pubAnimalErrorMessages.animalType && (
                                    <FormHelperText error>
                                        {pubAnimalErrorMessages.animalType}
                                    </FormHelperText>
                                )}
                        </FormControl>
                    </div>
                    <div>
                        <StyledTextField
                            id="pub-animal-breed"
                            name="breed"
                            label="Breed"
                            value={editedPubAnimalFormVals?.breed || ''}
                            // readOnly={!recUpdatingEnabled}
                            readOnly={disableFormButtons}
                            onChange={(event) => handlePubAnimalFormChange(event.target.name, event.target.value)}
                            error={!!pubAnimalErrorMessages.breed}
                            helperText={pubAnimalErrorMessages.breed || ''}
                            sx={{
                                borderColor: pubAnimalErrorMessages.breed ? 'red' : '',
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: pubAnimalErrorMessages.breed ? 'red' : '',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: pubAnimalErrorMessages.breed ? 'red' : '',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: pubAnimalErrorMessages.breed ? 'red' : '',
                                    },
                                },
                            }}
                        />
                    </div>
                    <div>
                        <StyledTextField
                            id="pub-animal-color"
                            name="color"
                            label="Color"
                            value={editedPubAnimalFormVals?.color || ''}
                            // readOnly={!recUpdatingEnabled}
                            readOnly={disableFormButtons}
                            onChange={(event) => handlePubAnimalFormChange(event.target.name, event.target.value)}
                            error={!!pubAnimalErrorMessages.color}
                            helperText={pubAnimalErrorMessages.color || ''}
                            sx={{
                                borderColor: pubAnimalErrorMessages.color ? 'red' : '',
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: pubAnimalErrorMessages.color ? 'red' : '',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: pubAnimalErrorMessages.color ? 'red' : '',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: pubAnimalErrorMessages.color ? 'red' : '',
                                    },
                                },
                            }}
                        />
                    </div>
                    <div>
                        <StyledTextField
                            id="pub-animal-discipline"
                            name="discipline"
                            label="Discipline"
                            value={editedPubAnimalFormVals?.discipline || ''}
                            // readOnly={!recUpdatingEnabled}
                            readOnly={disableFormButtons}
                            onChange={(event) => handlePubAnimalFormChange(event.target.name, event.target.value)}
                            error={!!pubAnimalErrorMessages.discipline}
                            helperText={pubAnimalErrorMessages.discipline || ''}
                            sx={{
                                borderColor: pubAnimalErrorMessages.discipline ? 'red' : '',
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: pubAnimalErrorMessages.discipline ? 'red' : '',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: pubAnimalErrorMessages.discipline ? 'red' : '',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: pubAnimalErrorMessages.discipline ? 'red' : '',
                                    },
                                },
                            }}
                        />
                    </div>
                    <div>
                        <StyledTextField
                            id="pub-animal-stall-number"
                            name="stallNumber"
                            label="Stall Number"
                            value={editedPubAnimalFormVals?.stallNumber || ''}
                            // readOnly={!recUpdatingEnabled}
                            readOnly={disableFormButtons}
                            onChange={(event) => handlePubAnimalFormChange(event.target.name, event.target.value)}
                            error={!!pubAnimalErrorMessages.stallNumber}
                            helperText={pubAnimalErrorMessages.stallNumber || ''}
                            sx={{
                                borderColor: pubAnimalErrorMessages.stallNumber ? 'red' : '',
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: pubAnimalErrorMessages.stallNumber ? 'red' : '',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: pubAnimalErrorMessages.stallNumber ? 'red' : '',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: pubAnimalErrorMessages.stallNumber ? 'red' : '',
                                    },
                                },
                            }}
                        />
                    </div>
                    <div>
                        <StyledTextField
                            id="pub-animal-age"
                            name="age"
                            label="Age"
                            value={editedPubAnimalFormVals?.age || ''}
                            // readOnly={!recUpdatingEnabled}
                            readOnly={disableFormButtons}
                            onChange={(event) => handlePubAnimalFormChange(event.target.name, event.target.value)}
                            error={!!pubAnimalErrorMessages.age}
                            helperText={pubAnimalErrorMessages.age || ''}
                            sx={{
                                borderColor: pubAnimalErrorMessages.age ? 'red' : '',
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: pubAnimalErrorMessages.age ? 'red' : '',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: pubAnimalErrorMessages.age ? 'red' : '',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: pubAnimalErrorMessages.age ? 'red' : '',
                                    },
                                },
                            }}
                        />
                    </div>
                    <div>
                        <DatePicker
                            label={'Birthday'}
                            value={editedAnimalBirthdayDate || null}
                            onChange={(newValue) => handleAnimalBirthdayDateChange(newValue)}
                            format="ll"
                            slotProps={{
                                textField: {
                                    error: !!pubAnimalErrorMessages.birthday,
                                    helperText: pubAnimalErrorMessages.birthday || '',
                                    sx: {
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: pubAnimalErrorMessages.birthday ? 'red' : '',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: pubAnimalErrorMessages.birthday ? 'red' : '',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: pubAnimalErrorMessages.birthday ? 'red' : '',
                                            },
                                        },
                                    }
                                }
                            }}
                        />
                    </div>
                    <div>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={isMiniIsToggled}
                                    onChange={handleIsMiniToggle}
                                    name="booleanField"
                                    color="primary"
                                    disabled={disableFormButtons}
                                />
                            }
                            label="Is Miniature"
                        />
                    </div>
                    {!updateExistingPubAnimal ? (
                        <div>
                            <BaseStyledButton
                                label="Add Animal"
                                onClick={handleAddPubAnimalDone}
                                disabled={disableFormButtons || updateExistingPubAnimal}
                                fontSize={{
                                    xs: '1.5rem',
                                    sm: '1.5rem',
                                    md: '1.5rem',
                                    lg: '2rem',
                                    xl: '2.5rem',
                                }}
                            />
                        </div>
                    ) : null}
                    {!addPubAnimal ? (
                        <div>
                            <BaseStyledButton
                                label="Update Animal"
                                onClick={handleUpdateExistingPubAnimalDone}
                                disabled={disableFormButtons || addPubAnimal}
                                fontSize={{
                                    xs: '1.5rem',
                                    sm: '1.5rem',
                                    md: '1.5rem',
                                    lg: '2rem',
                                    xl: '2.5rem',
                                }}
                            />
                        </div>
                    ) : null}
                    <div>
                        <BaseStyledButton
                            label="Cancel"
                            // onClick={resetPubAnimalFormVals}
                            onClick={() => setResetPubAnimalForm(true)}
                            disabled={disableFormButtons}
                            fontSize={{
                                xs: '1.5rem',
                                sm: '1.5rem',
                                md: '1.5rem',
                                lg: '2rem',
                                xl: '2.5rem',
                            }}
                        />
                    </div>
                </div>
            ) : null}
            <Divider/>
            <Divider>
                <Chip label="Animals"/>
            </Divider>
            <Divider/>
            <div>
                <StyledAccordionList
                    accordionDataProp={editedPubAnimalsArr}
                    baseHeaderTextFormatFunc={animalsAccordionHeaderTextFormatter}
                    CustomAccordionDetails={PublicAnimalAccordionDetails}
                    renderButtonsRowProp={pubAnimalsAccordionButtonsFactory}
                    showLogsProp={showLogs}
                />
            </div>
            <Divider />
            <div>
                <BaseStyledButton
                    label="Add New Animal"
                    onClick={handleAddPubAnimal}
                    disabled={disableFormButtons || updateExistingPubAnimal}
                    fontSize={{
                        xs: '1.5rem',
                        sm: '1.5rem',
                        md: '1.5rem',
                        lg: '2rem',
                        xl: '2.5rem',
                    }}
                />
            </div>
            <div>
                <BaseStyledButton
                    label="Update Public Client"
                    onClick={handleFormSubmit}
                    disabled={disableFormButtons}
                    fontSize={{
                        xs: '1.5rem',
                        sm: '1.5rem',
                        md: '1.5rem',
                        lg: '2rem',
                        xl: '2.5rem',
                    }}
                />
            </div>
            {displayConfirmUpdateDialog ? (
                <ConfirmUpdateSharedPublicClientDialog
                    openProp={displayConfirmUpdateDialog}
                    handleCloseProp={onConfirmUpdateDialogClose}
                    handleUpdateDoneProp={onConfirmUpdateDone}
                    newAnimalsArrProp={editedPubAnimalsArr}
                    pubClientRecProp={publicClientRec}
                    origEmailProp={editedPubClientFormVals.email}
                    pubClientFormValsDiffProp={pubClientFormValsDiffObj}
                    showLogs={showLogs}
                />
            ) : null}
        </div>
    );
}
