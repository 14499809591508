import React, {useState, useEffect} from 'react';
import StyledListCard from "./StyledListCard";
import StyledCardContentTypography from "../text/StyledCardContentTypography";
import { useConsoleLog } from "../hooks/logging/console";
import {toTitleCase} from "../../utils/text";
import { convertAWSDateTimeToLocalDateExtended } from "../../utils/datesTimes";

// TODO - ADD LINK TO SUBSCRIPTIONS PAGE

export default function AmpUserSubscriberInfoCard(props) {
    const [showLogs, setShowLogs] = useState(false);
    const [ampUserRec, setAmpUserRec] = useState(null);
    const [ampUserSubscriberRec, setAmpUserSubscriberRec] = useState(null);
    const [userSubCouponRecs, setUserSubCouponRecs] = useState(null);

    // useEffect( setShowLogs( props.showLogs
    useEffect(() => {
        if (props.showLogs) {
            setShowLogs(props.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [props.showLogs]);

    // useEffect( setAmpUserRec(props.ampUser);
    useEffect(() => {
        if (props?.ampUser) {
            setAmpUserRec(props.ampUser);
        }
    }, [props.ampUser]);

    // useEffect( setAmpUserSubscriberRec(props.ampUserSubscriber);
    useEffect(() => {
        if (props?.ampUserSubscriber) {
            setAmpUserSubscriberRec(props.ampUserSubscriber);
        }
    }, [props.ampUserSubscriber]);

    useConsoleLog(showLogs, [{label: 'AmpUserSubscriberInfoCard ampUserRec', value: ampUserRec}]);
    useConsoleLog(showLogs, [{label: 'AmpUserSubscriberInfoCard ampUserSubscriberRec', value: ampUserSubscriberRec}]);

    // TODO - TOGGLE BACK IN AFTER COUPONS ARE LIVE, CREATE COUPONS ACCORDION

    // useEffect( setUserSubCouponRecs(props.userSubCoupons);
    // useEffect(() => {
    //     if (props?.userSubCoupons) {
    //         setUserSubCouponRecs(props.userSubCoupons);
    //     }
    // }, [props.userSubCoupons]);

    return (
        <StyledListCard
            margin={['1rem', '1rem', '1rem', '1rem']}
        >
            {ampUserSubscriberRec ? (
                <>
                    <StyledCardContentTypography
                        text={`Current Sub End: ${ampUserSubscriberRec?.currentSubEndDateTime ? convertAWSDateTimeToLocalDateExtended(ampUserSubscriberRec.currentSubEndDateTime) : '-'}`}
                    />
                    <StyledCardContentTypography
                        text={`Sub is Active: ${ampUserSubscriberRec?.subIsActive ? toTitleCase(ampUserSubscriberRec.subIsActive.toString()) : '-'}`}
                    />
                    {userSubCouponRecs && userSubCouponRecs.length > 0 ? (
                        <StyledCardContentTypography
                            text={`Coupon: ${userSubCouponRecs[0]?.couponCode || '-'}`}
                        />
                    ) : null}
                </>
            ) : null}
        </StyledListCard>
    );
}
