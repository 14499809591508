
export function filterClientByName(clientRecs, clientName) {
    return clientRecs.filter((clientRec) => {
        return clientRec.name === clientName;
    });
}

export function filterAnimalByName(animalRecs, animalName) {
    return animalRecs.filter((animalRec) => {
        return animalRec.name === animalName;
    });
}

export function filterAnimalRecsByAnimalNames(clientAnimalRecs, selectedAnimalNames) {
    // console.log('!!! filterAnimalRecsByAnimalNames clientAnimalRecs', clientAnimalRecs);
    // console.log('!!! filterAnimalRecsByAnimalNames selectedAnimalNames', selectedAnimalNames);
    // Use the filter method to iterate over each record in animalRecs
    return clientAnimalRecs.filter(animalRec =>
        // Check if animalNames includes the name of the current animal record
        selectedAnimalNames.includes(animalRec.name)
    );
    // return [];
}

export function filterUnselectedAnimalRecsByAnimalNames(clientAnimalRecs, selectedAnimalNames) {
    // return clientAnimalRecs where the rec.name is not in selectedAnimalNames
    return clientAnimalRecs.filter(animalRec =>
        !selectedAnimalNames.includes(animalRec.name)
    );
}

export function filterAnimalByClientId(animalRecs, clientId) {
    console.log('filterAnimalByClientId animalRecs', animalRecs);
    console.log('filterAnimalByClientId clientId', clientId);
    return animalRecs.filter((animalRec) => {
        return animalRec.clientID === clientId;
    });
}
