import React, {useState, useEffect, useContext} from 'react';
import AnimalNotesContext from "../../data/contexts/AnimalNotesContext";
import AnimalShoeTasksContext from "../../data/contexts/AnimalShoeTasksContext";
import AnimalTrimTasksContext from "../../data/contexts/AnimalTrimTasksContext";
import AnimalShoePackagesContext from "../../data/contexts/AnimalShoePackagesContext";
import AnimalClientContext from "../../data/contexts/AnimalClientContext";
import StyledListCard from "../../components/cards/StyledListCard";
import Typography from "@mui/material/Typography";
import DevStateContext from "../../data/contexts/DevStateContext";
import BaseNavigateButton from "../../components/links/BaseNavigateButton";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {toTitleCase} from "../../utils/text";
import {dateFromAWSDateTime} from "../../utils/datesTimes";
import {breadcrumbRoutesAnimalsScreen} from "../../utils/breadcrumbsRoutes";
import {deleteShoePackageById, deleteTrimTaskById, deleteShoeTaskById, deleteAnimalNoteById} from "../../data/delete/deleteRecords";
import DeleteAnimalConfirmationDialog from "../../components/modals/delete/DeleteAnimalModal";
import DeleteShoePackageConfirmationDialog from "../../components/modals/delete/DeleteShoePackageModal";
import DeleteShoeTaskConfirmationDialog from "../../components/modals/delete/DeleteShoeTaskModal";
import DeleteTrimTaskConfirmationDialog from "../../components/modals/delete/DeleteTrimTaskModal";
import DeleteAnimalNoteConfirmationDialog from "../../components/modals/delete/DeleteAnimalNoteModal";
import BaseStyledDeleteButton from "../../components/buttons/BaseStyledDeleteButton";
import RouterBreadcrumbs from "../../components/router/RouterBreadcrumbs";
import Box from "@mui/material/Box";
import StyledListBox from "../../components/boxes/StyledListBox";
import {useResponsiveViewport} from "../../data/providers/ResponsiveViewportProvider";

// import {useConsoleLog, useConsoleWarn, useConsoleError} from "../components/hooks/logging/console";
// import NotesAccordion from "../components/accordions/NotesAccordion";
// import {useNavigate} from "react-router-dom";
// import {AccordionActions} from "@mui/material";

export default function AnimalInfoScreen(props) {
    const [animalRec, setAnimalRec] = useState(null);
    const [clientRec, setClientRec] = useState(null);
    const [animalNotes, setAnimalNotes] = useState([]);
    const [shoeTaskRecs, setShoeTaskRecs] = useState([]);
    const [trimTaskRecs, setTrimTaskRecs] = useState([]);
    const [shoePackageRecs, setShoePackageRecs] = useState([]);
    const [showLogs, setShowLogs] = useState(false);
    const [breadcrumbRoutes, setBreadcrumbRoutes] = useState([]); // [{path: '', name: ''}, ...

    const [shoePackageToDelete, setShoePackageToDelete] = useState(null);
    const [shoeTaskToDelete, setShoeTaskToDelete] = useState(null);
    const [trimTaskToDelete, setTrimTaskToDelete] = useState(null);
    const [animalNoteToDelete, setAnimalNoteToDelete] = useState(null);

    const [showDeleteAnimalModal, setShowDeleteAnimalModal] = useState(false);
    const [showDeleteAnimalNoteModal, setShowDeleteAnimalNoteModal] = useState(false);
    const [showDeleteShoePackageModal, setShowDeleteShoePackageModal] = useState(false);
    const [showDeleteShoeTaskModal, setShowDeleteShoeTaskModal] = useState(false);
    const [showDeleteTrimTaskModal, setShowDeleteTrimTaskModal] = useState(false);

    const { isSmallScreen, isLargeScreen } = useResponsiveViewport();

    const {animalNotesRecs} = useContext(AnimalNotesContext);
    const {animalShoeTasksRecs} = useContext(AnimalShoeTasksContext);
    const {animalTrimTasksRecs} = useContext(AnimalTrimTasksContext);
    const {animalShoePackagesRecs} = useContext(AnimalShoePackagesContext);
    const {animalClientRec} = useContext(AnimalClientContext);
    const {devStateObj} = useContext(DevStateContext);

    // setBreadcrumbRoutes
    useEffect(() => {
        setBreadcrumbRoutes(breadcrumbRoutesAnimalsScreen() || [])
        // console.error('breadcrumbRoutes', breadcrumbRoutes)
    }, [props]);

    // TODO - MOVE ALL THESE MODAL ACTIONS TO MODAL HELPERS DIR

    const handleDeleteAnimalModalOpen = () => {
        setShowDeleteAnimalModal(true);
    };

    const handleDeleteAnimalNoteModalOpen = () => {
        setShowDeleteAnimalNoteModal(true);
    };

    const handleDeleteShoePackageModalOpen = () => {
        setShowDeleteShoePackageModal(true);
    };

    const handleDeleteShoeTaskModalOpen = () => {
        setShowDeleteShoeTaskModal(true);
    };

    const handleDeleteTrimTaskModalOpen = () => {
        setShowDeleteTrimTaskModal(true);
    };

    const handleDeleteAnimalModalClose = () => {
        setShowDeleteAnimalModal(false);
    };

    const handleDeleteAnimalNoteModalClose = () => {
        setShowDeleteAnimalNoteModal(false);
    };

    const handleDeleteShoePackageModalClose = () => {
        setShowDeleteShoePackageModal(false);
    };

    const handleDeleteShoeTaskModalClose = () => {
        setShowDeleteShoeTaskModal(false);
    };

    const handleDeleteTrimTaskModalClose = () => {
        setShowDeleteTrimTaskModal(false);
    };

    // TODO - THESE DELETES CAN USE JUST THE MODEL REC FROM STATE,
    //  DO NOT USE MODEL IDS IN THE DELETE FUNCTIONS
    const handleDeleteAnimalConfirm = async (modelId) => {
        if (showLogs) {
            console.log('The Animal record has been deleted.', modelId);
        }
        // TODO - CALL DELETE ANIMAL FUNCTION
        setShowDeleteAnimalModal(false);
    };

    const handleDeleteAnimalNoteConfirm = async (modelId) => {
        await deleteAnimalNoteById(modelId, showLogs);
        if (showLogs) {
            console.log('The Animal Note record has been deleted.', modelId);
        }
        setShowDeleteAnimalNoteModal(false);
    };

    const handleDeleteShoePackageConfirm = async (modelId) => {
        await deleteShoePackageById(modelId, showLogs);
        if (showLogs) {
            console.log('The Shoe Package record has been deleted.', modelId);
        }
        setShowDeleteShoePackageModal(false);
    };

    const handleDeleteShoeTaskConfirm = async (modelId) => {
        await deleteShoeTaskById(modelId, showLogs);
        if (showLogs) {
            console.log('The Shoe Task record has been deleted.', modelId);
        }
        setShowDeleteShoeTaskModal(false);
    };

    const handleDeleteTrimTaskConfirm = async (modelId) => {
        await deleteTrimTaskById(modelId, showLogs);
        if (showLogs) {
            console.log('The Trim Task record has been deleted.', modelId);
        }
        setShowDeleteTrimTaskModal(false);
    };

    // useEffect( setShowLogs(devStateObj.showLogs);
    useEffect(() => {
        if (devStateObj?.showLogs) {
            setShowLogs(devStateObj.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [devStateObj]);

    // useEffect( setAnimalRec(props.animalRec);
    useEffect(() => {
        if (props.animalRec) {
            setAnimalRec(props.animalRec);
        }
    }, [props.animalRec]);

    // useEffect( setClientRec(animalClientRec);
    useEffect(() => {
        if (animalClientRec) {
            setClientRec(animalClientRec);
        }
    }, [animalClientRec]);

    // useEffect( setAnimalNotes(animalNotesRecs);
    useEffect(() => {
        if (animalNotesRecs) {
            setAnimalNotes(animalNotesRecs);
        }
    }, [animalNotesRecs]);

    // useEffect( setShoeTaskRecs(animalShoeTasksRecs);
    useEffect(() => {
        if (animalShoeTasksRecs) {
            setShoeTaskRecs(animalShoeTasksRecs);
        }
    }, [animalShoeTasksRecs]);

    // useEffect( setTrimTaskRecs(animalTrimTasksRecs);
    useEffect(() => {
        if (animalTrimTasksRecs) {
            setTrimTaskRecs(animalTrimTasksRecs);
        }
    }, [animalTrimTasksRecs]);

    // useEffect( setShoePackageRecs(animalShoePackagesRecs);
    useEffect(() => {
        if (animalShoePackagesRecs) {
            setShoePackageRecs(animalShoePackagesRecs);
        }
    }, [animalShoePackagesRecs]);

    // useEffect( console.log('AnimalInfoScreen animalRec', animalRec);
    useEffect(() => {
        if (showLogs) {
            console.log('AnimalInfoScreen animalRec', animalRec);
            console.log('AnimalInfoScreen clientRec', clientRec);
        }
    }, [animalRec, clientRec, showLogs]);

    // TODO - UPDATE TO USE ANIMAL INFO CARD

    // TODO - START HERE, GET EDIT ANIMAL FORM WORKING CORRECTLY, ADD ACCORDIONS FOR NOTES, TASKS, SHOE PACKAGE RECS
    function displayAnimalInfo() {
        // TODO - ADD BUTTON TO EDIT ANIMAL
        // TODO - ADD BUTTON TO DELETE ANIMAL
        // TODO - ADD BUTTON TO ADD ANIMAL APPOINTMENT,
        //  THIS WILL NEED A WRAPPED PATH TO create-animal-appt WITH PREPOPULATED FORM VALS
        if (animalRec && clientRec) {
            return (
                <div
                    key={`amp-user-animal-info`}
                    style={{
                        marginBottom: '1.5vh',
                        backgroundColor: 'lightgray',
                    }}
                >
                <StyledListCard>
                    <Typography>
                        Name: {animalRec.name}
                    </Typography>
                    <Typography>
                        Client: {clientRec.name}
                    </Typography>
                    <Typography>
                        Animal Type: {animalRec?.animalType ? toTitleCase(animalRec.animalType) : ""}
                    </Typography>
                    <Typography>
                        Animal Status: {animalRec?.animalStatus ? toTitleCase(animalRec.animalStatus) : ""}
                    </Typography>
                    <Typography>
                        Breed: {animalRec?.breed ? toTitleCase(animalRec.breed) : ""}
                    </Typography>
                    <Typography>
                        Temperament: {animalRec.temperament}
                    </Typography>
                    <Typography>
                        Stall Number: {animalRec.stallNumber}
                    </Typography>
                    <Typography>
                        Color: {animalRec?.color ? toTitleCase(animalRec.color) : ""}
                    </Typography>
                    <Typography>
                        Discipline: {animalRec?.discipline ? toTitleCase(animalRec.discipline) : ""}
                    </Typography>
                    <Typography>
                        Is Miniature: {animalRec.isMiniature ? "Yes" : "No"}
                    </Typography>
                    <Typography>
                        Age: {animalRec.age}
                    </Typography>
                    <Typography>
                        Added Shoe Task Amt.: {animalRec.addedShoeTaskAmount}
                    </Typography>
                    <Typography>
                        Added Shoe Task Time.: {animalRec.addedShoeTaskDuration}
                    </Typography>
                    <Typography>
                        Added Trim Task Amt.: {animalRec.addedTrimTaskAmount}
                    </Typography>
                    <Typography>
                        Added Trim Task Time.: {animalRec.addedTrimTaskDuration}
                    </Typography>
                    <BaseNavigateButton
                        label={"Update Animal"}
                        navTarget={`/edit-animal/${animalRec.routerPath}`}
                        navState={{animalRec, clientRec}}
                    />
                </StyledListCard>
                </div>
            )} else {
            return (
                <StyledListCard>
                    <Typography>
                        No Animal Found
                    </Typography>
                </StyledListCard>
            )
        }
    }

    // TODO - REPLACE WITH NOTES ACCORDION, INCLUDE ACTION BUTTONS STACK PROP

    function displayAnimalNotesAccordion() {
        // TODO -ADD BUTTON TO ADD ANIMAL NOTE
        // TODO - ADD BUTTON TO EDIT ANIMAL NOTE
        // TODO - ADD BUTTON TO DELETE ANIMAL NOTE
        if (animalNotes) {
                return (
                    <div>
                        <Accordion
                            disabled={animalNotes.length === 0}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Animal Notes ({animalNotes.length})</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    {animalNotes.map((animalNote, index) => {
                                        return (
                                            <StyledListCard
                                                key={`animalNote-${index}`}
                                            >
                                                <Typography>
                                                    Note: {animalNote.note}
                                                </Typography>
                                                <BaseStyledDeleteButton
                                                    label={"Delete Animal Note"}
                                                    onClick={() => {
                                                        setAnimalNoteToDelete(animalNote);
                                                        handleDeleteAnimalNoteModalOpen();
                                                    }}
                                                    size={'medium'}
                                                />
                                            </StyledListCard>
                                        )
                                    })}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                )
        } else {
            return (
                <StyledListCard>
                    <Typography>
                        No Animal Notes Found
                    </Typography>
                </StyledListCard>
            )}}

    // TODO - CREATE, USE DEDICATED TASKS ACCORDION

    function displayShoeTasksAccordion() {
        // TODO - ADD BUTTON TO ADD SHOE TASK
        // TODO - ? ADD BUTTON TO EDIT SHOE TASK?
        // TODO - ? ADD BUTTON TO DELETE SHOE TASK?
        if (shoeTaskRecs) {
            return (
                <div>
                    <Accordion
                        disabled={shoeTaskRecs.length === 0}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Shoeings ({shoeTaskRecs.length})</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                {shoeTaskRecs.map((shoeTaskRec, index) => {
                                    return (
                                        <StyledListCard
                                            key={`shoeTask-${index}`}
                                        >
                                            <Typography>
                                                Task: {shoeTaskRec.task}
                                            </Typography>
                                            <BaseNavigateButton
                                                label={"Update Shoeing"}
                                                navTarget={`/edit-shoe-task/${shoeTaskRec.routerPath}`}
                                                navState={{shoeTaskRec}}
                                            />
                                            <BaseStyledDeleteButton
                                                label={"Delete Shoeing"}
                                                onClick={() => {
                                                    setShoeTaskToDelete(shoeTaskRec);
                                                    handleDeleteShoeTaskModalOpen();
                                                }}
                                              size={'medium'}
                                            />
                                        </StyledListCard>
                                    )
                                })}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            )
        } else {
            return (
                <StyledListCard>
                    <Typography>
                        No Shoeings Found
                    </Typography>
                </StyledListCard>
            )}}

    // TODO - CREATE, USE DEDICATED TASKS ACCORDION

    function displayTrimTasksAccordion() {
        // TODO - ADD BUTTON TO ADD TRIM TASK
        // TODO - ? ADD BUTTON TO EDIT TRIM TASK?
        // TODO - ? ADD BUTTON TO DELETE TRIM TASK?
        if (trimTaskRecs) {
            return (
                <div>
                    <Accordion
                        disabled={trimTaskRecs.length === 0}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Trims ({trimTaskRecs.length})</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                {trimTaskRecs.map((trimTaskRec, index) => {
                                    return (
                                        <StyledListCard
                                            key={`trimTask-${index}`}
                                        >
                                            <BaseNavigateButton
                                                label={"Update Trim"}
                                                navTarget={`/edit-trim-task/${trimTaskRec.routerPath}`}
                                                navState={{trimTaskRec}}
                                            />
                                            <BaseStyledDeleteButton
                                                label={"Delete Trim"}
                                                onClick={() => {
                                                    setTrimTaskToDelete(trimTaskRec);
                                                    handleDeleteTrimTaskModalOpen();
                                                }}
                                                size={'medium'}
                                            />
                                        </StyledListCard>
                                    )
                                })}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            )
        } else {
            return (
                <StyledListCard>
                    <Typography>
                        No Trims Found
                    </Typography>
                </StyledListCard>
            )}}

    // TODO - CREATE, USE DEDICATED ACCORDION ?,
    //  !!! - CHECK IF WE ARE USING > 1 SHOE PKG RECS PER ANIMAL,
    //  OR ARE WE UPDATING SAME REC

    function displayShoePackagesAccordion() {
        // TODO - ADD BUTTON TO ADD APPT TO MAKE NEW SHOE PACKAGE
        // TODO - ? ADD BUTTON TO EDIT SHOE PACKAGE?
        // TODO - ? ADD BUTTON TO DELETE SHOE PACKAGE?
        if (shoePackageRecs) {
            return (
                <div>
                    <Accordion
                        disabled={shoePackageRecs.length === 0}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Shoe Packages ({shoePackageRecs.length})</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                {shoePackageRecs.map((shoePackageRec, index) => {
                                    return (
                                        <StyledListCard
                                            key={`shoePackage-${index}`}
                                        >
                                            <Typography>
                                                Package: {dateFromAWSDateTime(shoePackageRec.createdAt)}
                                            </Typography>
                                            <BaseNavigateButton
                                                label={"Update Shoe Package"}
                                                navTarget={`/edit-shoe-package/${shoePackageRec.routerPath}`}
                                                navState={{shoePackageRec}}
                                            />
                                            <BaseStyledDeleteButton
                                                label={"Delete Shoe Package"}
                                                onClick={() => {
                                                    setShoePackageToDelete(shoePackageRec);
                                                    handleDeleteShoePackageModalOpen();
                                                }}
                                                size={'medium'}
                                            />
                                        </StyledListCard>
                                    )
                                })}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            )
        } else {
            return (
                <StyledListCard>
                    <Typography>
                        No Shoe Packages Found
                    </Typography>
                </StyledListCard>
            )}}


    // TODO - MAKE SURE DELETE MODALS WORK AS EXPECTED
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto',
            }}
        >
            <h2>Animal Info</h2>
            <RouterBreadcrumbs breadcrumbsArr={breadcrumbRoutes} />
            {animalRec ? (
                <StyledListBox
                    width={isLargeScreen ? '90%' : '100%'}
                    style={{
                        color: 'white',
                        backgroundColor: 'lightgray',
                        borderTop: '1px solid black',
                        borderBottom: '1px solid black',
                        marginTop: '0.5vh',
                    }}
                >
                <div>
                    {displayAnimalInfo()}
                    {displayAnimalNotesAccordion()}
                    {displayShoeTasksAccordion()}
                    {displayTrimTasksAccordion()}
                    {displayShoePackagesAccordion()}
                    <div>
                        <BaseNavigateButton
                            label={"Update Animal"}
                            navTarget={`/edit-animal/${animalRec.routerPath}`}
                            navState={{animalRec, clientRec}}
                        />
                        <BaseNavigateButton
                            label={"Create Appointment"}
                            navTarget={`/create-appt`}
                            navState={{animalRec, clientRec}}
                        />
                    </div>
                    <div>
                        <DeleteAnimalConfirmationDialog
                            open={showDeleteAnimalModal}
                            handleClose={handleDeleteAnimalModalClose}
                            handleConfirm={handleDeleteAnimalConfirm}
                            showLogs={showLogs}
                            modelRecToDelete={animalRec}
                        />
                        {animalNoteToDelete ? (
                            <DeleteAnimalNoteConfirmationDialog
                                open={showDeleteAnimalNoteModal}
                                handleClose={handleDeleteAnimalNoteModalClose}
                                handleConfirm={handleDeleteAnimalNoteConfirm}
                                modelRecToDelete={animalNoteToDelete}
                                showLogs={showLogs}
                                animalRec={animalRec}
                            />
                        ) : null}
                        {shoePackageToDelete ? (
                            <DeleteShoePackageConfirmationDialog
                                open={showDeleteShoePackageModal}
                                handleClose={handleDeleteShoePackageModalClose}
                                handleConfirm={handleDeleteShoePackageConfirm}
                                modelRecToDelete={shoePackageToDelete}
                                showLogs={showLogs}
                                animalRec={animalRec}
                            />
                        ) : null}
                        {shoeTaskToDelete ? (
                            <DeleteShoeTaskConfirmationDialog
                                open={showDeleteShoeTaskModal}
                                handleClose={handleDeleteShoeTaskModalClose}
                                handleConfirm={handleDeleteShoeTaskConfirm}
                                modelRecToDelete={shoeTaskToDelete}
                                showLogs={showLogs}
                                animalRec={animalRec}
                            />
                        ) : null}
                        {trimTaskToDelete ? (
                            <DeleteTrimTaskConfirmationDialog
                                open={showDeleteTrimTaskModal}
                                handleClose={handleDeleteTrimTaskModalClose}
                                handleConfirm={handleDeleteTrimTaskConfirm}
                                modelRecToDelete={trimTaskToDelete}
                                showLogs={showLogs}
                                animalRec={animalRec}
                            />
                        ) : null}
                    </div>
                </div>
                </StyledListBox>
            ) : (
                <h2>Loading Animal Rec...</h2>
            )}
        </Box>
    )
}
