import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
    Typography,
} from '@mui/material';
import { createClientNote } from "../../../data/create/person/ClientNote";
import Grid from "@mui/material/Grid";
import { updateClientDialogFinalStepSet } from "../../buttons/sets/clientButtonSets";
import { Stack } from '@mui/material';
import { clientNoteFormObj } from "../../../utils/formObjects";
import StyledNoteTextField from "../../text/StyledNoteTextField";

export default function CreateClientNoteDialog({ openProp,
                                               ampUser,
                                               handleCloseProp,
                                               clientRec,
                                               handleCreateDoneProp,
                                               showLogs,
                                               ...props }) {
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(clientNoteFormObj);
    const [clientNote, setClientNote] = useState(null);
    const [editedClientNoteFormVals, setEditedClientNoteFormVals] = useState(clientNoteFormObj);
    const [clientNoteFormValChanged, setClientNoteFormValChanged] = useState(false);

    const [isCreatingNote, setIsCreatingNote] = useState(false);
    const [createClientNoteResObj, setCreateClientNoteResObj] = useState(null);
    const [createNoteError, setCreateNoteError] = useState(null);

    const [activeStep, setActiveStep] = useState(0);
    const [finalStepButtonsObj, setFinalStepButtonsObj] = useState(null);
    const [finalStepDialogTitleText, setFinalStepDialogTitleText] = useState(null);

    // useEffect( if (openProp) { setOpen(true);
    useEffect(() => {
        if (openProp) {
            setOpen(true);
        }
    }, [openProp]);

    // useEffect( if (clientRec) { setFinalStepButtonsObj(updateClientDialogFinalStepSet(clientRec)); setActiveStep(1);
    useEffect(() => {
        if (clientRec) {
            setFinalStepButtonsObj(updateClientDialogFinalStepSet(clientRec));
            setActiveStep(1);
        }
    }, [clientRec]);

    // useEffect( setCreateNoteError(createClientNoteResObj.error);
    useEffect(() => {
        if (!createClientNoteResObj?.success && createClientNoteResObj?.error) {
            setCreateNoteError(createClientNoteResObj.error);
        }
    }, [createClientNoteResObj]);

    // useEffect( if (editedClientNoteFormVals && editedClientNoteFormVals?.note) { if (editedClientNoteFormVals.note.length > 0) { setClientNoteFormValChanged(true); } else { setClientNoteFormValChanged(false); }
    useEffect(() => {
        if (editedClientNoteFormVals && editedClientNoteFormVals?.note) {
            if (editedClientNoteFormVals.note.length > 0) {
                setClientNoteFormValChanged(true);
            }
            else {
                setClientNoteFormValChanged(false);
            }
        }
    }, [editedClientNoteFormVals]);

    const handleClientNoteTextFieldChange = (event) => {
        // setClientNoteVal(event.target.value);
        setFormValues({...formValues, note: event.target.value})
        setEditedClientNoteFormVals({...editedClientNoteFormVals, note: event.target.value})
        setClientNote(event.target.value);
    };

    const handleClose = () => {
        if (showLogs) console.log('### CreateClientNoteDialog handleClose');
        if (handleCloseProp) {
            if (!isCreatingNote) {
                if (handleCreateDoneProp) {
                    handleCreateDoneProp(createClientNoteResObj);
                }
                handleCloseProp();
            }
        }
    }

    const handleCancel = () => {
        setActiveStep(0);
        handleClose();
    };

    const handleConfirmCreateClientNote = async () => {
        setIsCreatingNote(true);
        const createStartTime = Date.now();

        try {
            const [createNoteResponse] = await Promise.all([
                createClientNote(clientRec.id, clientNote, showLogs),
                new Promise(resolve => setTimeout(resolve, 1000))
            ]);

            setCreateClientNoteResObj(createNoteResponse);

        } catch (error) {
            console.error('Note creation failed:', error);
            setCreateNoteError(error);
            // Handle error (e.g., show error message to user)
        } finally {
            const createDuration = Date.now() - createStartTime;
            if (createDuration < 1000) {
                await new Promise(resolve => setTimeout(resolve, 1000 - createDuration));
            }
            setIsCreatingNote(false);
            setActiveStep((prevStep) => prevStep + 1);
        }
    };

    const renderCreateClientNoteStep = () => (
        <>
            <DialogTitle>Create Client Note</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Write client note
                </DialogContentText>
                <div>
                    <StyledNoteTextField
                        id={`create-client-note-note-field-id`}
                        label="Note"
                        maxRows={16}
                        value={editedClientNoteFormVals?.note || ''}
                        onChange={handleClientNoteTextFieldChange}
                        sx={{width: '100%'}}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleCancel}
                    disabled={isCreatingNote}
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                    disabled={isCreatingNote}
                >
                    Close
                </Button>
                <Button
                    onClick={() => setActiveStep(2)}
                    variant="contained"
                    color="primary"
                    disabled={!clientNoteFormValChanged || isCreatingNote}
                >
                    Create
                </Button>
            </DialogActions>
        </>
    )

    // Step 2 if client fields to update, otherwise step 1
    const renderConfirmClientNoteStep = () => (
        <>
            <DialogTitle>Confirm Client Note</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please review the following note to be added:
                </DialogContentText>
                <Typography variant="body1" style={{margin: '20px 0', padding: '10px', border: '1px solid #ccc'}}>
                    {clientNote}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleCancel}
                    disabled={isCreatingNote}
                >
                    Cancel
                </Button>
                <Button onClick={async () => await handleConfirmCreateClientNote()} variant="contained" color="primary" disabled={isCreatingNote}>
                    {isCreatingNote ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                            <CircularProgress size={24} />
                            <DialogContentText style={{ marginLeft: '20px' }}>
                                Creating client note...
                            </DialogContentText>
                        </div>
                    ) : 'Confirm Add Note'}
                </Button>
                <Button
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                    disabled={isCreatingNote}
                >
                    Close
                </Button>
            </DialogActions>
        </>
    )

    // Last step to display outcomes, display nav buttons or close
    const renderFinalStep = () => (
        <>
            <DialogTitle>{finalStepDialogTitleText || 'Create Done'}</DialogTitle>
            <DialogContent>
                {createClientNoteResObj && createNoteError ? (
                    <div>
                        <DialogContentText>
                            Error creating client note
                        </DialogContentText>
                        <p>{createNoteError}</p>
                    </div>
                ) : null}
                {createClientNoteResObj && createClientNoteResObj?.success ? (
                    <DialogContentText>
                        Client note created
                    </DialogContentText>
                ) : null }
            </DialogContent>
            <DialogActions>
                <Stack spacing={2} width="100%">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                            {finalStepButtonsObj.clientInfo}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {finalStepButtonsObj.clientAnimals}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {finalStepButtonsObj.clientAppts}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {finalStepButtonsObj.home}
                        </Grid>
                    </Grid>
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        color="primary"
                        fullWidth
                    >
                        Close
                    </Button>
                </Stack>
            </DialogActions>
        </>
    )

    const renderLoadingStep = () => (
        <>
            <DialogTitle>Loading...</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Loading Client Note Form
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                    disabled={isCreatingNote}
                >
                    Close
                </Button>
                <Button
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                    disabled={isCreatingNote}
                >
                    Cancel
                </Button>
            </DialogActions>
        </>
    );

    return (
        <Dialog open={open} onClose={handleClose}>
            {activeStep === 0 ? renderLoadingStep() :
                    activeStep === 1 ? renderCreateClientNoteStep() :
                    activeStep === 2 ? renderConfirmClientNoteStep() :
                        renderFinalStep()}
        </Dialog>
    );
}
