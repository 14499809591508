import React, { useState, useEffect, useContext } from 'react';
import { FarrierClientAlert,
    FarrierAnimalAlert,
    FarrierPublicClientAlert,
    FarrierPublicAnimalAlert } from "./alertClasses/FarrierAlerts";
import { farrierAlertsDB } from "../idb/alerts/FarrierAlertsDB";

const FarrierAlertsIDBContext = React.createContext();

// TODO - START HERE, FR, FR - ADD FETCH FOR ALL ALERTS, ADD PROVIDER TO FETCH DATA TO THEN PASS HERE TO CREATE ALERTS
export function FarrierAlertsIDBProvider({ children, ampUserIdProp, showLogsProp, resetProviderState, ...props }) {
    const [ampUserId, setAmpUserId] = useState(ampUserIdProp);
    const [publicClientAlertsAll, setPublicClientAlertsAll] = useState([]);
    const [publicClientAlertsUnAcked, setPublicClientAlertsUnAcked] = useState([]);
    const [clientAlertsAll, setClientAlertsAll] = useState([]);
    const [clientAlertsUnAcked, setClientAlertsUnAcked] = useState([]);
    const [animalAlertsUnAcked, setAnimalAlertsUnAcked] = useState([]);
    const [animalAlertsAll, setAnimalAlertsAll] = useState([]);
    const [publicAnimalAlertsUnAcked, setPublicAnimalAlertsUnAcked] = useState([]);
    const [publicAnimalAlertsAll, setPublicAnimalAlertsAll] = useState([]);
    const [publicClientAlertsPinned, setPublicClientAlertsPinned] = useState([]);
    const [clientAlertsPinned, setClientAlertsPinned] = useState([]);
    const [animalAlertsPinned, setAnimalAlertsPinned] = useState([]);
    const [publicAnimalAlertsPinned, setPublicAnimalAlertsPinned] = useState([]);
    const [localShowLogs, setLocalShowLogs] = useState(false);
    const [error, setError] = useState(null);
    const [isUpdatingUnAckedAlerts, setIsUpdatingUnAckedAlerts] = useState(false);
    const [isUpdatingPinnedAlerts, setIsUpdatingPinnedAlerts] = useState(false);

    function resetContext() {
        setAmpUserId(null);
        setPublicClientAlertsUnAcked([]);
        setClientAlertsUnAcked([]);
        setAnimalAlertsUnAcked([]);
        setPublicAnimalAlertsUnAcked([]);
        // setFarrierPublicClientIds([]);
        setAnimalAlertsPinned([]);
        setPublicAnimalAlertsPinned([]);
        setPublicClientAlertsPinned([]);
        setClientAlertsPinned([]);
        setError(null);
        setIsUpdatingUnAckedAlerts(false);
        setIsUpdatingPinnedAlerts(false);
    }

    // useEffect( resetContext();
    useEffect(() => {
        if (resetProviderState) {
            resetContext();
        }
    }, [resetProviderState]);

    // useEffect( setAmpUserId(ampUserIdProp);
    useEffect(() => {
        if (ampUserIdProp) {
            setAmpUserId(ampUserIdProp);
        }
    }, [ampUserIdProp]);

    // useEffect( set localShowLogs when showLogs changes
    useEffect(() => {
        if (showLogsProp) {
            setLocalShowLogs(true);
        }
        else {
            setLocalShowLogs(false);
        }
    }, [showLogsProp]);

    const loadAllUnAckedAlerts = async () => {
        try {
            if (!ampUserId) return;
            if (resetProviderState) return;

            const [unAckedPublicClientAlerts, unAckedClientAlerts, unAckedAnimalAlerts, unAckedPublicAnimalAlerts] = await Promise.all([
                farrierAlertsDB.getUnAckedAlertsByAmpUserByStoreName(ampUserId, farrierAlertsDB.stores.farrierPublicClientAlertsStoreName),
                farrierAlertsDB.getUnAckedAlertsByAmpUserByStoreName(ampUserId, farrierAlertsDB.stores.farrierClientAlertsStoreName),
                farrierAlertsDB.getUnAckedAlertsByAmpUserByStoreName(ampUserId, farrierAlertsDB.stores.farrierAnimalAlertsStoreName),
                farrierAlertsDB.getUnAckedAlertsByAmpUserByStoreName(ampUserId, farrierAlertsDB.stores.farrierPublicAnimalAlertsStoreName)
            ]);

            setPublicClientAlertsUnAcked(unAckedPublicClientAlerts);
            setClientAlertsUnAcked(unAckedClientAlerts);
            setAnimalAlertsUnAcked(unAckedAnimalAlerts);
            setPublicAnimalAlertsUnAcked(unAckedPublicAnimalAlerts);
            setError(null);
        } catch (error) {
            console.error('Error loading alerts:', error);
            setError('Failed to load alerts');
        }
    };

    const loadAllAlertsByStoreName = async (storeName) => {
        try {
            if (!ampUserId) return;
            if (resetProviderState) return;

            const allAlerts = await farrierAlertsDB.getAlertsByAmpUserByStoreName(ampUserId, storeName);
            setPublicClientAlertsAll(allAlerts);

            return allAlerts;
        } catch (error) {
            // console.error('Error loading alerts:', error);
            console.error(`Failed to load alerts for store: ${storeName}, error:`, error);
            // setError('Failed to load alerts', error);
            setError(`Failed to load alerts for store: ${storeName}, error: ${error}`);
        }
    }

    const loadAllAlertsForAllStores = async () => {
        try {
            if (!ampUserId) return;
            if (resetProviderState) return;

            const [publicClientAlerts, clientAlerts, animalAlerts, publicAnimalAlerts] = await Promise.all([
                farrierAlertsDB.getAlertsByAmpUserByStoreName(ampUserId, farrierAlertsDB.stores.farrierPublicClientAlertsStoreName),
                farrierAlertsDB.getAlertsByAmpUserByStoreName(ampUserId, farrierAlertsDB.stores.farrierClientAlertsStoreName),
                farrierAlertsDB.getAlertsByAmpUserByStoreName(ampUserId, farrierAlertsDB.stores.farrierAnimalAlertsStoreName),
                farrierAlertsDB.getAlertsByAmpUserByStoreName(ampUserId, farrierAlertsDB.stores.farrierPublicAnimalAlertsStoreName)
            ]);

            setPublicClientAlertsAll(publicClientAlerts);
            setClientAlertsAll(clientAlerts);
            setAnimalAlertsAll(animalAlerts);
            setPublicAnimalAlertsAll(publicAnimalAlerts);
            setError(null);
        } catch (error) {
            console.error('Error loading alerts:', error);
            setError('Failed to load alerts');
        }
    }

    const loadUnAckedAlertsByStoreName = async (storeName) => {
        try {
            if (!ampUserId) return;
            if (resetProviderState) return;

            const unAckedAlerts = await farrierAlertsDB.getUnAckedAlertsByAmpUserByStoreName(ampUserId, storeName);

            return unAckedAlerts;
        } catch (error) {
            // console.error('Error loading alerts:', error);
            console.error(`Failed to load unAcked alerts for store: ${storeName}, error:`, error);
            // setError('Failed to load alerts', error);
            setError(`Failed to load unAcked alerts for store: ${storeName}, error: ${error}`);
        }
    }

    const loadPinnedAlertsByStoreName = async (storeName) => {
        try {
            if (!ampUserId) return;
            if (resetProviderState) return;

            const pinnedAlerts = await farrierAlertsDB.getPinnedAlertsByAmpUserByStoreName(ampUserId, storeName);

            return pinnedAlerts;
        } catch (error) {
            // console.error('Error loading alerts:', error);
            console.error(`Failed to load pinned alerts for store: ${storeName}, error:`, error);
            // setError('Failed to load alerts', error);
            setError(`Failed to load pinned alerts for store: ${storeName}, error: ${error}`);
        }
    }

    const loadAllPinnedAlerts = async () => {
        try {
            if (!ampUserId) return;
            if (resetProviderState) return;

            const [pinnedPublicClientAlerts, pinnedClientAlerts, pinnedAnimalAlerts, pinnedPublicAnimalAlerts] = await Promise.all([
                farrierAlertsDB.getPinnedAlertsByAmpUserByStoreName(ampUserId, farrierAlertsDB.stores.farrierPublicClientAlertsStoreName),
                farrierAlertsDB.getPinnedAlertsByAmpUserByStoreName(ampUserId, farrierAlertsDB.stores.farrierClientAlertsStoreName),
                farrierAlertsDB.getPinnedAlertsByAmpUserByStoreName(ampUserId, farrierAlertsDB.stores.farrierAnimalAlertsStoreName),
                farrierAlertsDB.getPinnedAlertsByAmpUserByStoreName(ampUserId, farrierAlertsDB.stores.farrierPublicAnimalAlertsStoreName)
            ]);

            setPublicClientAlertsPinned(pinnedPublicClientAlerts);
            setClientAlertsPinned(pinnedClientAlerts);
            setAnimalAlertsPinned(pinnedAnimalAlerts);
            setPublicAnimalAlertsPinned(pinnedPublicAnimalAlerts);
            setError(null);
        } catch (error) {
            console.error('Error loading alerts:', error);
            setError('Failed to load alerts');
        }
    };

    // useEffect( loadAllUnAckedAlerts();
    useEffect(() => {
        // if (ampUserId && !resetProviderState) {
        if (ampUserId && !resetProviderState && farrierAlertsDB && farrierAlertsDB.stores && Object.keys(farrierAlertsDB.stores).length > 0) {
            loadAllUnAckedAlerts();
        }
    }, [ampUserId, resetProviderState, farrierAlertsDB]);

    // useEffect( loadAllPinnedAlerts();
    useEffect(() => {
        // if (ampUserId && !resetProviderState) {
        if (ampUserId && !resetProviderState && farrierAlertsDB && farrierAlertsDB.stores && Object.keys(farrierAlertsDB.stores).length > 0) {
            loadAllPinnedAlerts();
        }
    }, [ampUserId, resetProviderState, farrierAlertsDB]);

    // useEffect( loadAllAlertsByStoreName(farrierAlertsDB.stores.farrierPublicClientAlertsStoreName);
    useEffect(() => {
        // if (ampUserId && !resetProviderState) {
        if (ampUserId && !resetProviderState && farrierAlertsDB && farrierAlertsDB.stores && Object.keys(farrierAlertsDB.stores).length > 0) {
            loadAllAlertsByStoreName(farrierAlertsDB.stores.farrierPublicClientAlertsStoreName);
        }
    }, [ampUserId, resetProviderState, farrierAlertsDB]);

    const createPublicClientAlert = async (alertData) => {
        try {
            const newAlert = new FarrierPublicClientAlert({
                ...alertData,
                ampUserId
            });
            await farrierAlertsDB.addAlertByStoreName(newAlert, farrierAlertsDB.stores.farrierPublicClientAlertsStoreName);
            // await loadAllUnAckedAlerts();
            const storeUnAckedAlerts = await loadUnAckedAlertsByStoreName(farrierAlertsDB.stores.farrierPublicClientAlertsStoreName);
            setPublicClientAlertsUnAcked(storeUnAckedAlerts);

            return newAlert;
        } catch (error) {
            console.error('Error creating public client alert:', error);
            setError('Failed to create public client alert');
            throw error;
        }
    };

    const createClientAlert = async (alertData) => {
        try {
            const newAlert = new FarrierClientAlert({
                ...alertData,
                ampUserId
            });
            await farrierAlertsDB.addAlertByStoreName(newAlert, farrierAlertsDB.stores.farrierClientAlertsStoreName);
            // await loadAllUnAckedAlerts();
            const storeUnAckedAlerts = await loadUnAckedAlertsByStoreName(farrierAlertsDB.stores.farrierClientAlertsStoreName);
            setClientAlertsUnAcked(storeUnAckedAlerts);

            return newAlert;
        } catch (error) {
            console.error('Error creating client alert:', error);
            setError('Failed to create client alert');
            throw error;
        }
    };

    const createAnimalAlert = async (alertData) => {
        try {
            const newAlert = new FarrierAnimalAlert({
                ...alertData,
                ampUserId
            });
            await farrierAlertsDB.addAlertByStoreName(newAlert, farrierAlertsDB.stores.farrierAnimalAlertsStoreName);
            // await loadAllUnAckedAlerts();
            const storeUnAckedAlerts = await loadUnAckedAlertsByStoreName(farrierAlertsDB.stores.farrierAnimalAlertsStoreName);
            setAnimalAlertsUnAcked(storeUnAckedAlerts);

            return newAlert;
        } catch (error) {
            console.error('Error creating animal alert:', error);
            setError('Failed to create animal alert');
            throw error;
        }
    };

    const createPublicAnimalAlert = async (alertData) => {
        try {
            const newAlert = new FarrierPublicAnimalAlert({
                ...alertData,
                ampUserId
            });
            await farrierAlertsDB.addAlertByStoreName(newAlert, farrierAlertsDB.stores.farrierPublicAnimalAlertsStoreName);
            // await loadAllUnAckedAlerts();
            const storeUnAckedAlerts = await loadUnAckedAlertsByStoreName(farrierAlertsDB.stores.farrierPublicAnimalAlertsStoreName);
            setPublicAnimalAlertsUnAcked(storeUnAckedAlerts);

            return newAlert;
        } catch (error) {
            console.error('Error creating public animal alert:', error);
            setError('Failed to create public animal alert');
            throw error;
        }
    };

    const acknowledgeAlert = async (alertId, storeName) => {
        // setIsUpdatingUnAckedAlerts(true);
        console.warn('acknowledgeAlert alertId:', alertId);
        console.warn('acknowledgeAlert storeName:', storeName);

        try {
            // console.warn('acknowledgeAlert alertId:', alertId);
            // console.warn('acknowledgeAlert storeName:', storeName);
            // setIsUpdatingUnAckedAlerts(true);
            await farrierAlertsDB.acknowledgeAlertByIdByStoreName(alertId, storeName);
            // await loadAllUnAckedAlerts();
            const storeUnAckedAlerts = await loadUnAckedAlertsByStoreName(storeName);
            console.warn('acknowledgeAlert storeUnAckedAlerts:', storeUnAckedAlerts);
            switch (storeName) {
                case farrierAlertsDB.stores.farrierPublicClientAlertsStoreName:
                    setPublicClientAlertsUnAcked(storeUnAckedAlerts);
                    break;
                case farrierAlertsDB.stores.farrierClientAlertsStoreName:
                    setClientAlertsUnAcked(storeUnAckedAlerts);
                    break;
                case farrierAlertsDB.stores.farrierAnimalAlertsStoreName:
                    setAnimalAlertsUnAcked(storeUnAckedAlerts);
                    break;
                case farrierAlertsDB.stores.farrierPublicAnimalAlertsStoreName:
                    setPublicAnimalAlertsUnAcked(storeUnAckedAlerts);
                    break;
                default:
                    break;
            }
            // setIsUpdatingUnAckedAlerts(false);
        }
        catch (error) {
            console.error(`Error acknowledging alertId: ${alertId}, error:`, error);
            setError(`Failed to acknowledge alertId: ${alertId}, error: ${error}`);
            throw error;
        }
        // setIsUpdatingUnAckedAlerts(false);
    };

    const pinAlert = async (alertId, storeName) => {
        console.warn('pinAlert alertId:', alertId);
        console.warn('pinAlert storeName:', storeName);

        try {
            await farrierAlertsDB.pinAlertByIdByStoreName(alertId, storeName);
            await acknowledgeAlert(alertId, storeName);
            // await loadAllPinnedAlerts();
            const storePinnedAlerts = await loadPinnedAlertsByStoreName(storeName);
            console.warn('pinAlert storePinnedAlerts:', storePinnedAlerts);
            switch (storeName) {
                case farrierAlertsDB.stores.farrierPublicClientAlertsStoreName:
                    setPublicClientAlertsPinned(storePinnedAlerts);
                    break;
                case farrierAlertsDB.stores.farrierClientAlertsStoreName:
                    setClientAlertsPinned(storePinnedAlerts);
                    break;
                case farrierAlertsDB.stores.farrierAnimalAlertsStoreName:
                    setAnimalAlertsPinned(storePinnedAlerts);
                    break;
                case farrierAlertsDB.stores.farrierPublicAnimalAlertsStoreName:
                    setPublicAnimalAlertsPinned(storePinnedAlerts);
                    break;
                default:
                    break;
            }
            // await acknowledgeAlert(alertId, storeName);
        } catch (error) {
            // console.error('Error pinning alert:', error);
            console.error(`Failed to pin alertId: ${alertId}, error:`, error);
            // setError('Failed to pin alert');
            setError(`Failed to pin alertId: ${alertId}, error: ${error}`);
            throw error;
        }
    };

    const unPinAlert = async (alertId, storeName) => {
        console.warn('unPinAlert alertId:', alertId);
        console.warn('unPinAlert storeName:', storeName);

        try {
            await farrierAlertsDB.unPinAlertByIdByStoreName(alertId, storeName);
            // await acknowledgeAlert(alertId, storeName);
            // await loadAllPinnedAlerts();
            const storePinnedAlerts = await loadPinnedAlertsByStoreName(storeName);
            console.warn('unPinAlert storePinnedAlerts:', storePinnedAlerts);
            switch (storeName) {
                case farrierAlertsDB.stores.farrierPublicClientAlertsStoreName:
                    setPublicClientAlertsPinned(storePinnedAlerts);
                    break;
                case farrierAlertsDB.stores.farrierClientAlertsStoreName:
                    setClientAlertsPinned(storePinnedAlerts);
                    break;
                case farrierAlertsDB.stores.farrierAnimalAlertsStoreName:
                    setAnimalAlertsPinned(storePinnedAlerts);
                    break;
                case farrierAlertsDB.stores.farrierPublicAnimalAlertsStoreName:
                    setPublicAnimalAlertsPinned(storePinnedAlerts);
                    break;
                default:
                    break;
            }
            // await acknowledgeAlert(alertId, storeName);
        } catch (error) {
            // console.error('Error pinning alert:', error);
            console.error(`Failed to unpin alertId: ${alertId}, error:`, error);
            // setError('Failed to pin alert');
            setError(`Failed to unpin alertId: ${alertId}, error: ${error}`);
            throw error;
        }
    };


    return (
        <FarrierAlertsIDBContext.Provider value={{
            clientAlertsUnAcked,
            publicClientAlertsUnAcked,
            animalAlertsUnAcked,
            publicAnimalAlertsUnAcked,
            clientAlertsPinned,
            publicClientAlertsPinned,
            animalAlertsPinned,
            publicAnimalAlertsPinned,
            error,
            createPublicClientAlert,
            createClientAlert,
            createAnimalAlert,
            createPublicAnimalAlert,
            acknowledgeAlert,
            pinAlert,
            unPinAlert,
            refreshUnAckedAlerts: loadAllUnAckedAlerts,
            refreshPinnedAlerts: loadAllPinnedAlerts,
            refreshAllAlertsByStoreName: loadAllAlertsByStoreName,
            refreshAllAlertsForAllStores: loadAllAlertsForAllStores,
            isUpdatingPinnedAlerts,
            isUpdatingUnAckedAlerts,
            setIsUpdatingUnAckedAlerts,
            setIsUpdatingPinnedAlerts,
            publicClientAlertsAll,
            clientAlertsAll,
            animalAlertsAll,
            publicAnimalAlertsAll,
        }}>
            {children}
        </FarrierAlertsIDBContext.Provider>
    );
}

export const useFarrierAlertsIDBProvider = () => {
    // return useContext(FarrierAlertsContext);
    const context = useContext(FarrierAlertsIDBContext);
    if (!context) {
        throw new Error('useFarrierAlertsIDBProvider must be used within a FarrierAlertsIDBProvider');
    }
    return context;
}
