import { openDB } from 'idb';

const dbPromise = openDB('AmpUserClientDB', 1, {
    upgrade(db) {
        if (!db.objectStoreNames.contains('ampUserClientStore')) {
            db.createObjectStore('ampUserClientStore');
        }
    },
});

export async function saveAmpUserClientRec(clientRec) {
    const db = await dbPromise;
    await db.put('ampUserClientStore', clientRec, 'currentClient');
}

export async function loadAmpUserClientRec() {
    const db = await dbPromise;
    return await db.get('ampUserClientStore', 'currentClient');
}

export async function clearAmpUserClientRec() {
    const db = await dbPromise;
    await db.delete('ampUserClientStore', 'currentClient');
}
