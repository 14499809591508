import React, { useState, useEffect, useContext } from 'react';
import StyledTextField from "../../text/StyledTextField";
import Divider from '@mui/material/Divider';
import { useConsoleLog } from "../../hooks/logging/console";
import DevStateContext from "../../../data/contexts/DevStateContext";
import { AmpUserType } from "../../../models";
import { editAmpUserFormObj,
    ampUserFormsTooltipTextsObj,
    numericFormFields,
    ampUserTypesToDisplay,
    formFieldsToIgnore } from "../../../utils/formObjects";
import { generateUniqueKey } from "../../../utils/helpers";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TooltipWrapper from "../../text/TooltipWrapper";
import {textFieldValToIntegerHandleNaN, toTitleCase} from "../../../utils/text";
import BaseStyledButton from "../../buttons/BaseStyledButton";
import { formatPhoneNumber, validatePhoneNumber, convertToE164 } from "../../../utils/validation";
import StyledCardContentTypography from "../../text/StyledCardContentTypography";
import ConfirmUpdateAmpUserDialog from "../../modals/update/ConfirmUpdateAmpUserDialog";


export default function NewAmpUserIntakeForm(props) {
    const [ampUser, setAmpUser] = useState(null);
    const [showLogs, setShowLogs] = useState(false);

    const [origAmpUserFormVals, setOrigAmpUserFormVals] = useState(null);
    const [editedAmpUserFormVals, setEditedAmpUserFormVals] = useState(null);
    const [updatedAmpUserResObj, setUpdatedAmpUserResObj] = useState(null);

    const [ampUserFormValsDiffObj, setAmpUserFormValsDiffObj] = useState(null);
    const [ampUserFormValsChanged, setAmpUserFormValsChanged] = useState(false);

    const [isFarrierChecked, setIsFarrierChecked] = useState(false);
    const [ampUserTypesEnums, setAmpUserTypesEnums] = useState({});
    const [ampUserTypesFormVals, setAmpUserTypesFormVals] = useState({});

    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneError, setPhoneError] = useState('');

    const [selectedAmpUserTypes, setSelectedAmpUserTypes] = useState([]);
    const [displayConfirmUpdateDialog, setDisplayConfirmUpdateDialog] = useState(false);
    const [pauseOnAmpUserUpdate, setPauseOnAmpUserUpdate] = useState(false);

    const {devStateObj} = useContext(DevStateContext);

    useConsoleLog(showLogs, [{label: 'NewAmpUserIntakeForm origAmpUserFormVals', value: origAmpUserFormVals}]);
    useConsoleLog(showLogs, [{label: 'NewAmpUserIntakeForm editedAmpUserFormVals', value: editedAmpUserFormVals}]);
    useConsoleLog(showLogs, [{label: 'NewAmpUserIntakeForm ampUserFormValsDiffObj', value: ampUserFormValsDiffObj}]);
    useConsoleLog(showLogs, [{label: 'NewAmpUserIntakeForm ampUserFormValsChanged', value: ampUserFormValsChanged}]);
    useConsoleLog(showLogs, [{label: 'NewAmpUserIntakeForm updatedAmpUserResObj', value: updatedAmpUserResObj}]);
    useConsoleLog(showLogs, [{label: 'NewAmpUserIntakeForm AmpUserType', value: AmpUserType}]);
    useConsoleLog(showLogs, [{label: 'NewAmpUserIntakeForm ampUserTypesEnums', value: ampUserTypesEnums}]);
    useConsoleLog(showLogs, [{label: 'NewAmpUserIntakeForm ampUserTypesFormVals', value: ampUserTypesFormVals}]);
    useConsoleLog(showLogs, [{label: 'NewAmpUserIntakeForm selectedAmpUserTypes', value: selectedAmpUserTypes}]);
    useConsoleLog(showLogs, [{label: 'NewAmpUserIntakeForm pauseOnAmpUserUpdate', value: pauseOnAmpUserUpdate}]);

    // useEffect( clean up state values on unmount
    useEffect(() => {
        return () => {
            setDisplayConfirmUpdateDialog(false);
            setAmpUser(null);
            setShowLogs(false);
            setOrigAmpUserFormVals(null);
            setEditedAmpUserFormVals(null);
            setUpdatedAmpUserResObj(null);
            setAmpUserFormValsDiffObj(null);
            setAmpUserFormValsChanged(false);
            setIsFarrierChecked(false);
            setAmpUserTypesEnums({});
            setAmpUserTypesFormVals({});
            setPhoneNumber('');
            setPhoneError('');
            setSelectedAmpUserTypes([]);
            setPauseOnAmpUserUpdate(false);
        }
    }, []);

    // useEffect( setShowLogs(
    useEffect(() => {
        if (!pauseOnAmpUserUpdate) {
            if (devStateObj?.showLogs) {
                setShowLogs(devStateObj.showLogs);
            } else {
                setShowLogs(false);
            }
        }
    }, [devStateObj, pauseOnAmpUserUpdate]);

    // useEffect( setAmpUser(props.ampUser)
    useEffect(() => {
        if (!pauseOnAmpUserUpdate) {
            if (props?.ampUser) {
                setAmpUser(props.ampUser);
            }
        }
    }, [props.ampUser, pauseOnAmpUserUpdate]);

    // useEffect( setOrigAmpUserFormVals(userVals); setEditedAmpUserFormVals(userVals);
    useEffect(() => {
        // if (ampUser) {
        // if (!displayConfirmUpdateDialog && ampUser) {
        if (!pauseOnAmpUserUpdate && ampUser) {
            const userVals = {}
            Object.keys(editAmpUserFormObj).forEach((key) => {
                userVals[key] = ampUser[key];
                if (key === 'ampUserTypes') {
                    setSelectedAmpUserTypes(ampUser[key]);
                    // setSelectedAmpUserTypes(ampUser[key].map((type) => toTitleCase(type)));
                }
                if (key === 'phone') {
                    setPhoneNumber(ampUser[key]);
                }
            });
            setOrigAmpUserFormVals(userVals);
            setEditedAmpUserFormVals(userVals);
        }
    // }, [ampUser]);
    // }, [ampUser, displayConfirmUpdateDialog]);
    }, [ampUser, pauseOnAmpUserUpdate]);

    // useEffect( setAmpUserTypesEnums(AmpUserType); setAmpUserTypesFormVals(userTypesObj);
    useEffect(() => {
        // if (AmpUserType) {
        if (!pauseOnAmpUserUpdate && AmpUserType) {
            setAmpUserTypesEnums(AmpUserType);
            // setAmpUserTypesEnums(AmpUserType);
            const userTypesObj = {};
            Object.entries(AmpUserType).forEach(([key, val]) => {
               if (ampUserTypesToDisplay.includes(val)) {
                     userTypesObj[key] = toTitleCase(val);
               }
            });
            setAmpUserTypesFormVals(userTypesObj);
        }
    // }, [AmpUserType]);
    }, [AmpUserType, pauseOnAmpUserUpdate]);

    function handleAmpUserFormChange(key, value) {
        if (!pauseOnAmpUserUpdate) {
            if (numericFormFields.includes(key)) {
                const amountVal = textFieldValToIntegerHandleNaN(value);
                setEditedAmpUserFormVals({
                    ...editedAmpUserFormVals,
                    [key]: amountVal,
                });
            }
            else {
                if (key !== 'email') {
                    setEditedAmpUserFormVals({
                        ...editedAmpUserFormVals,
                        [key]: value,
                    });
                }
            }
        }
    }

    // Handle checkbox change
    const handleCheckboxChange = (event) => {
        if (!pauseOnAmpUserUpdate) {
            const {name, checked} = event.target;
            if (showLogs) {
                console.log('!!! handleCheckboxChange name', name);
                console.log('!!! handleCheckboxChange checked', checked);
            }
            if (checked) {
                // Add the checked type to the array
                // setSelectedAmpUserTypes((prev) => [...prev, toTitleCase(name)]);
                // setSelectedAmpUserTypes((prev) => [...prev, name]);
                setSelectedAmpUserTypes((prev) => [...prev, name.toUpperCase()]);
            }
            else {
                // Remove the unchecked type from the array
                // setSelectedAmpUserTypes((prev) => prev.filter((type) => type !== name));

                // setSelectedAmpUserTypes((prev) => prev.filter((type) => type !== toTitleCase(name)));
                // setSelectedAmpUserTypes((prev) => prev.filter((type) => type !== name));
                setSelectedAmpUserTypes((prev) => prev.filter((type) => type !== name.toUpperCase()));
            }
        }
    };

    // useEffect( setEditedAmpUserFormVals({...editedAmpUserFormVals, ampUserTypes: ampUserTypesEnumsArr});
    // useEffect( setEditedAmpUserFormVals({...editedAmpUserFormVals, ampUserTypes: selectedAmpUserTypes});
    useEffect(() => {
        if (!pauseOnAmpUserUpdate) {
            const ampUserTypesEnumsArr = selectedAmpUserTypes.map((type) => type.toUpperCase());
            // setEditedAmpUserFormVals({...editedAmpUserFormVals, ampUserTypes: selectedAmpUserTypes});
            setEditedAmpUserFormVals({...editedAmpUserFormVals, ampUserTypes: ampUserTypesEnumsArr});
        }
    }, [selectedAmpUserTypes, pauseOnAmpUserUpdate]);

    // useEffect( if (selectedAmpUserTypes.includes('FARRIER')) setIsFarrierChecked(true);
    useEffect(() => {
        if (!pauseOnAmpUserUpdate) {
            // if (selectedAmpUserTypes.includes('FARRIER')) {
            if (selectedAmpUserTypes.includes('FARRIER') || selectedAmpUserTypes.includes('Farrier')) {
                setIsFarrierChecked(true);
            }
            else {
                setIsFarrierChecked(false);
            }
        }
    }, [selectedAmpUserTypes, pauseOnAmpUserUpdate]);

    // useEffect( setAmpUserFormValsDiffObj(diffObj); setAmpUserFormValsChanged(Object.keys(diffObj).length > 0);
    useEffect(() => {
        if (!pauseOnAmpUserUpdate) {
            if (origAmpUserFormVals && editedAmpUserFormVals) {
                const diffObj = {};
                Object.keys(origAmpUserFormVals).forEach((key) => {
                    if (!formFieldsToIgnore.includes(key)) {
                        if (key === 'ampUserTypes') {
                            if (showLogs) {
                                console.log('!!! useEffect( setAmpUserFormValsDiffObj( origAmpUserFormVals key', key);
                                console.log('!!! useEffect( setAmpUserFormValsDiffObj( origAmpUserFormVals[key]', origAmpUserFormVals[key]);
                                console.log('!!! useEffect( setAmpUserFormValsDiffObj( editedAmpUserFormVals[key]', editedAmpUserFormVals[key]);
                            }
                            // compare the arrays
                            const ampUserTypesOrigDiff = origAmpUserFormVals[key].filter((type) => !editedAmpUserFormVals[key].includes(type));
                            if (showLogs) console.log('ampUserTypesOrigDiff', ampUserTypesOrigDiff);
                            const ampUserTypesEditedDiff = editedAmpUserFormVals[key].filter((type) => !origAmpUserFormVals[key].includes(type));
                            if (showLogs) console.log('ampUserTypesEditedDiff', ampUserTypesEditedDiff);
                            if (ampUserTypesOrigDiff.length > 0 || ampUserTypesEditedDiff.length > 0) {
                                diffObj[key] = editedAmpUserFormVals[key];
                            }
                        }
                        else if (origAmpUserFormVals[key] !== editedAmpUserFormVals[key]) {
                            diffObj[key] = editedAmpUserFormVals[key];
                        }
                    }
                });
                setAmpUserFormValsDiffObj(diffObj);
                setAmpUserFormValsChanged(Object.keys(diffObj).length > 0);
            }
        }
    }, [origAmpUserFormVals, editedAmpUserFormVals, showLogs, pauseOnAmpUserUpdate]);

    const handlePhoneChange = (e) => {
        if (!pauseOnAmpUserUpdate) {
            const formattedPhoneNumber = formatPhoneNumber(e.target.value);
            setPhoneNumber(formattedPhoneNumber);
        }
    };

    const handlePhoneBlur = () => {
        if (!pauseOnAmpUserUpdate) {
            if (!validatePhoneNumber(phoneNumber)) {
                setPhoneError('Please enter a valid US phone number: (123) 456-7890');
            }
            else {
                setPhoneError('');
                if (phoneNumber) {
                    // handleAmpUserFormChange('phone', phoneNumber);
                    const phoneE164Formatted = convertToE164(phoneNumber);
                    setEditedAmpUserFormVals({
                        ...editedAmpUserFormVals,
                        phone: phoneNumber,
                        phoneE164: phoneE164Formatted,
                    });
                }
            }
        }
    };

    function onUpdateUserDone(updateResObj) {
        if (updateResObj) {
            setUpdatedAmpUserResObj(updateResObj);
        }
    }

    function onUpdateUserCancel() {
        setDisplayConfirmUpdateDialog(false);
        setPauseOnAmpUserUpdate(false);
    }

    // async function handleAmpUserSubmit() {
    //     handlePhoneBlur();
    //     setDisplayConfirmUpdateDialog(true);
    //     setPauseOnAmpUserUpdate(true);
    // }

    function handleAmpUserSubmit() {
        handlePhoneBlur();
        setDisplayConfirmUpdateDialog(true);
        setPauseOnAmpUserUpdate(true);
    }

    return (
        <div
            style={{
                backgroundColor: 'lightgray',
                // backgroundColor: 'red',
                minHeight: '15vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 'calc(10px + 2vmin)',
                // color: 'white',
                color: 'black',
                // marginBottom: '5vh'
                marginTop: '0.5vh',
                // border: '1px solid black',
                borderTop: '1px solid black',
                borderBottom: '1px solid black',
                paddingTop: '1.5vh',
                paddingBottom: '1.5vh',
                width: '100%', // Ensure the container takes up full width
                maxWidth: '100%',
                minWidth: '100%',
                // flexDirection: 'column',  // Stack elements vertically
                // alignItems: 'stretch',    // Ensure children take full width
                // marginLeft: '1vw',
                // marginRight: '1vw',
            }}
        >
            <Divider/>
            <div>
                <StyledCardContentTypography
                    fullWidth
                    text={'Name'}
                />
                <StyledTextField
                    id={'name'}
                    label={'Name'}
                    value={editedAmpUserFormVals?.name || ''}
                    onChange={(e) => handleAmpUserFormChange('name', e.target.value)}
                />
            </div>
            <Divider/>
            <div>
                <StyledCardContentTypography
                    fullWidth
                    text={'User Type'}
                />
                <FormGroup>
                    {Object.entries(ampUserTypesFormVals).map(([key, val]) => {
                            // console.log('key', key, 'val', val);
                            return (
                                <FormControlLabel
                                    key={generateUniqueKey(key)}
                                    control={
                                        <Checkbox
                                            // name={toTitleCase(val)}
                                            name={val}
                                            // checked={selectedAmpUserTypes.includes(val)}
                                            // checked={selectedAmpUserTypes.includes(toTitleCase(val))}
                                            checked={selectedAmpUserTypes.includes(val.toUpperCase())}
                                            onChange={handleCheckboxChange}
                                        />
                                    }
                                    // label={toTitleCase(val)}
                                    label={val}
                                />
                            )
                    })}
                </FormGroup>
            </div>
            <Divider/>
            <div>
                <StyledCardContentTypography
                    fullWidth
                    text={'Phone'}
                />
                <StyledTextField
                    id={'phone'}
                    label={'Phone'}
                    error={!!phoneError}
                    // value={editedAmpUserFormVals?.phone || ''}
                    value={phoneNumber}
                    // onChange={(e) => handleAmpUserFormChange('phone', e.target.value)}
                    onChange={handlePhoneChange}
                    onBlur={handlePhoneBlur}
                    helperText={phoneError || 'Format: (123) 456-7890'}
                />
            </div>
            <div>
                <StyledCardContentTypography
                    fullWidth
                    text={'Email'}
                />
                <StyledTextField
                    readOnly
                    id={'email'}
                    label={'Email'}
                    value={editedAmpUserFormVals?.email || ''}
                />
            </div>
            <div>
                <StyledCardContentTypography
                    fullWidth
                    text={'Street Address'}
                />
                <StyledTextField
                    id={'street-address'}
                    label={'Street Address'}
                    value={editedAmpUserFormVals?.streetAddress || ''}
                    onChange={(e) => handleAmpUserFormChange('streetAddress', e.target.value)}
                />
            </div>
            <div>
                <StyledCardContentTypography
                    fullWidth
                    text={'City'}
                />
                <StyledTextField
                    id={'city'}
                    label={'City'}
                    value={editedAmpUserFormVals?.city || ''}
                    onChange={(e) => handleAmpUserFormChange('city', e.target.value)}
                />
            </div>
            <div>
                <StyledCardContentTypography
                    fullWidth
                    text={'State'}
                />
                <StyledTextField
                    id={'state'}
                    label={'State'}
                    value={editedAmpUserFormVals?.state || ''}
                    onChange={(e) => handleAmpUserFormChange('state', e.target.value)}
                />
            </div>
            <div>
                <StyledCardContentTypography
                    fullWidth
                    text={'Zip Code'}
                />
                <StyledTextField
                    id={'zip-code'}
                    label={'Zip Code'}
                    value={editedAmpUserFormVals?.zipCode || ''}
                    onChange={(e) => handleAmpUserFormChange('zipCode', e.target.value)}
                />
            </div>
            {isFarrierChecked ? (
                <div>
                    <TooltipWrapper tooltipText={ampUserFormsTooltipTextsObj.defaultShoeTaskAmount}>
                    <StyledCardContentTypography
                        text={'Default Shoeing ($)'}
                    />
                    </TooltipWrapper>
                    <StyledTextField
                        id="default-shoe-task-amount"
                        label="Default Shoeing Amoount ($)"
                        value={editedAmpUserFormVals?.defaultShoeTaskAmount || ''}
                        onChange={(event) => handleAmpUserFormChange('defaultShoeTaskAmount', event.target.value)}
                        // InputProps={{
                        //     startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        // }}
                    />
                </div>
            ) : null}
            {isFarrierChecked ? (
                <div>
                    <TooltipWrapper tooltipText={ampUserFormsTooltipTextsObj.defaultShoeTaskDuration}>
                    <StyledCardContentTypography
                        text={'Default Shoeing (mins.)'}
                    />
                    </TooltipWrapper>
                    <StyledTextField
                        id="default-shoe-task-duration"
                        label="Default Shoeing Time (mins.)"
                        value={editedAmpUserFormVals?.defaultShoeTaskDuration || ''}
                        // value={returnEndAdornedFormValue('defaultShoeTaskDuration', editedAmpUserFormVals?.defaultShoeTaskDuration || '')}
                        onChange={(event) => handleAmpUserFormChange('defaultShoeTaskDuration', event.target.value)}
                        // onChange={(event) => handleUpdateEditedAmpUserFormValsWithEndAdornedField('defaultShoeTaskDuration', event.target.value)}
                        // helperText={'minutes'}
                    />
                </div>
            ) : null }
            {isFarrierChecked ? (
                <div>
                    <TooltipWrapper tooltipText={ampUserFormsTooltipTextsObj.defaultTrimTaskAmount}>
                    <StyledCardContentTypography
                        text={'Default Trims ($)'}
                    />
                    </TooltipWrapper>
                    <StyledTextField
                        id="default-trim-task-amount"
                        label="Default Trims Amount ($)"
                        value={editedAmpUserFormVals?.defaultTrimTaskAmount || ''}
                        onChange={(event) => handleAmpUserFormChange('defaultTrimTaskAmount', event.target.value)}
                        // InputProps={{
                        //     startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        // }}
                    />
                </div>
            ) : null }
            {isFarrierChecked ? (
                <div>
                    <TooltipWrapper tooltipText={ampUserFormsTooltipTextsObj.defaultTrimTaskDuration}>
                    <StyledCardContentTypography
                        fullWidth
                        text={'Default Trims (mins.)'}
                    />
                    </TooltipWrapper>
                        <StyledTextField
                            id="default-trim-task-duration"
                            label="Default Trims Time (mins.)"
                            value={editedAmpUserFormVals?.defaultTrimTaskDuration || ''}
                            onChange={(event) => handleAmpUserFormChange('defaultTrimTaskDuration', event.target.value)}
                        />
                </div>
            ) : null }
            <div>
                <BaseStyledButton
                    label={'Update User'}
                    disabled={!ampUserFormValsChanged}
                    onClick={handleAmpUserSubmit}
                />
            </div>
            {displayConfirmUpdateDialog ? (
                <ConfirmUpdateAmpUserDialog
                    openProp={displayConfirmUpdateDialog}
                    handleCloseProp={onUpdateUserCancel}
                    handleUpdateDoneProp={onUpdateUserDone}
                    recDiffsObj={ampUserFormValsDiffObj}
                    showLogs={showLogs}
                    ampUserProp={ampUser}
                />
            ) : null}
        </div>
    );
}
