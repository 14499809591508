import React, { useState, useEffect } from 'react';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import StyledCardContentTypography from "../text/StyledCardContentTypography";
import StyledListCard from "../cards/StyledListCard";
import { useNavigate } from "react-router-dom";
import StyledAccordion from "./StyledAccordion";
import {fetchClientById, fetchAnimalById} from "../../data/get/fetchRecords";
import Divider from "@mui/material/Divider";

export default function NotesAccordion({ notes, headerText, noteType, apptRecProp, showLogs, ...props }) {
    const [noteRecs, setNoteRecs] = useState([]);
    const [accordionHeaderText, setAccordionHeaderText] = useState('');
    const [clientId, setClientId] = useState(null);
    const [clientRec, setClientRec] = useState(null);
    const [animalId, setAnimalId] = useState(null);
    const [animalRec, setAnimalRec] = useState(null);
    const [apptRec, setApptRec] = useState(null);
    const [accExpanded, setAccExpanded] = useState(false);

    let navigate = useNavigate();

    // useEffect( setNoteRecs(notes);
    useEffect(() => {
        if (notes) {
            setNoteRecs(notes);
        }
    }, [notes]);

    // useEffect( console.log( 'NotesAccordion noteRecs:', noteRecs);
    useEffect(() => {
        if (showLogs) {
            console.log('NotesAccordion noteRecs:', noteRecs);
        }
    }, [showLogs, noteRecs]);

    // useEffect( setAccordionHeaderText(headerText);
    useEffect(() => {
        if (headerText) {
            setAccordionHeaderText(headerText);
        }
    }, [headerText]);

    // useEffect( setClientId(props.clientId);
    useEffect(() => {
        if (props?.clientId) {
            if (clientId !== props.clientId) {
                setClientId(props.clientId);
            }
        }
    }, [props]);

    // useEffect( setClientRec(props.clientRecProp);
    useEffect(() => {
        if (props?.clientRecProp) {
            if (clientRec !== props.clientRecProp) {
                setClientRec(props.clientRecProp);
            }
        }
    }, [props]);

    // useEffect( setClientRec(fetchClientRecRes.clientModelRec);
    useEffect(() => {
        if (clientId) {
            async function fetchClient() {
                const fetchClientRecRes = await fetchClientById(clientId);
                if (fetchClientRecRes.success) {
                    setClientRec(fetchClientRecRes.clientModelRec);
                }
                else {
                    console.error('fetchClientById error:', fetchClientRecRes.error);
                }
            }
            fetchClient();
        }
    }, [clientId]);

    // useEffect( setAnimalId(props.animalId);
    useEffect(() => {
        if (props?.animalId) {
            if (animalId !== props.animalId) {
                setAnimalId(props.animalId);
            }
        }
    }, [props]);

    // useEffect( setAnimalRec(fetchAnimalRecRes.animalRec);
    useEffect(() => {
        if (animalId) {
            async function fetchAnimal() {
                const fetchAnimalRecRes = await fetchAnimalById(animalId, showLogs);
                if (fetchAnimalRecRes.success) {
                    setAnimalRec(fetchAnimalRecRes.animalRec);
                }
                else {
                    console.error('fetchAnimalById error:', fetchAnimalRecRes.error);
                }
            }
            fetchAnimal();
        }
    }, [animalId]);

    // useEffect( setApptRec(apptRecProp);
    useEffect(() => {
        if (apptRecProp) {
            if (apptRec !== apptRecProp) {
                setApptRec(apptRecProp);
            }
        }
    }, [apptRecProp]);

    // function navigateToNoteDetail(noteRec) {
    //     switch (noteType) {
    //         case 'appt':
    //             // navigate(`/appointment-note/${noteRec.routerPath}`);
    //             navigate(`/edit-appt-note/${noteRec?.routerPath}`, {state: {apptNoteRec: noteRec, apptRec, clientRec}});
    //             break;
    //         case 'animal':
    //             navigate(`/edit-animal-note/${noteRec.routerPath}`, {state: {animalNote: noteRec, animalRec, clientRec}});
    //             break;
    //         case 'client':
    //             navigate(`/edit-client-note/${noteRec.routerPath}`, {state: {clientNoteRec: noteRec, clientRec}});
    //             break;
    //         default:
    //             break;
    //     }
    // }

    return (
        <StyledAccordion
            expanded={accExpanded}
            onChange={() => {
                setAccExpanded(!accExpanded);
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <StyledCardContentTypography
                    text={accordionHeaderText || 'Notes:'}
                />
            </AccordionSummary>
            <AccordionDetails>
                <div>
                    {noteRecs.map((noteRec, index) => (
                        <div key={`${accordionHeaderText}-${noteRec.id}`}>
                            <StyledListCard
                                // onClick={() => {
                                //     console.log('onClick noteRec:', noteRec);
                                //     navigateToNoteDetail(noteRec)
                                // }}
                                >
                                <StyledCardContentTypography text={noteRec.note} />
                            </StyledListCard>
                            <Divider />
                        </div>
                    ))}
                </div>
            </AccordionDetails>
        </StyledAccordion>
    );
}
