import React, { useState, useEffect } from "react";
import DevStateProvider from "../../../data/providers/DevStateProvider";
import {RequireAuth} from "../../auth/RequireAuth";
import AmpUserDataProvider from "../../../data/providers/people/AmpUserDataProvider";
import AmpUserInfoScreen from "../../../screens/people/AmpUserInfoScreen";
import UserSubsProvider from "../../../data/providers/people/subsPayments/UserSubProvider";
import SubPaymentViewProvider from "../../../data/providers/people/subsPayments/SubPaymentViewProvider";

export default function WrappedAmpUserInfo({ routerPaths, ampUser, showLogs, ...props }) {
    const [ampUserRec, setAmpUserRec] = useState(null);
    const [ampUserSubscriberRec, setAmpUserSubscriberRec] = useState(null);

    // useEffect( setAmpUserRec(ampUser);
    useEffect(() => {
        if (ampUser) {
            setAmpUserRec(ampUser);
        }
    }, [ampUser]);

    // useEffect( setAmpUserSubscriberRec(props.ampUserSubscriberProp);
    useEffect(() => {
        if (props?.ampUserSubscriberProp) {
            setAmpUserSubscriberRec(props.ampUserSubscriberProp);
        }
    }, [props.ampUserSubscriberProp]);

    // useEffect( console.log( ampUserRec, ampUserSubscriberRec
    useEffect(() => {
        if (showLogs) {
            console.log('ampUserRec', ampUserRec);
            console.log('ampUserSubscriberRec', ampUserSubscriberRec);
        }
    }, [ampUserRec, ampUserSubscriberRec, showLogs]);

    return (
        <RequireAuth>
            <DevStateProvider>
                {ampUserRec ? (
                    <UserSubsProvider ampUserProp={ampUserRec} showLogs={showLogs}>
                        <SubPaymentViewProvider ampUserSubscriberProp={ampUserSubscriberRec} showLogs={showLogs}>
                            <AmpUserDataProvider ampUserIdProp={ampUserRec.id} showLogs={showLogs}>
                                <AmpUserInfoScreen
                                    routerPaths={routerPaths}
                                    showLogs={showLogs}
                                    ampUser={ampUserRec}
                                    ampUserSubscriber={ampUserSubscriberRec}
                                />
                            </AmpUserDataProvider>
                        </SubPaymentViewProvider>
                    </UserSubsProvider>
                ) : (
                    <h3>No User Info...</h3>
                )}
            </DevStateProvider>
        </RequireAuth>
    )
}
