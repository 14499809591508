import React from 'react';
import Stack from '@mui/material/Stack';

const VerticalButtonStack = ({ children, spacing = 2, ...props }) => {
    return (
        <Stack
            spacing={spacing}
            {...props} // Allows for additional props and styles to be passed in
        >
            {children}
        </Stack>
    );
};

export default VerticalButtonStack;
