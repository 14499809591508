import React, { useMemo } from "react";
import HomeCardsList from "../components/lists/HomeCardsList";
import WrappedWaitlistUserCreateForm from "../components/forms/create/WrappedWaitlistUserCreateForm"; // Modify the import path to point to the wrapper component
import {deployState} from "../utils/consts";
import StyledCardContentTypography from "../components/text/StyledCardContentTypography";

function HomeScreen() {
    const memoizedHomeCardsList = useMemo(() => <HomeCardsList />, []);

    return(
        <div className={"home-screen-cards"}>
            {deployState === 'signup' ? <WrappedWaitlistUserCreateForm onSuccess={() => {
                alert('Added to the SoleFrog wait list successfully!');
            }
            }/> : null}
            <div>
                <StyledCardContentTypography
                    text={"Home"}
                    sx={{
                        fontSize: {
                            xs: '1.5rem', // Similar to small screens
                            sm: '2rem',   // Adjust for tablets and small desktops
                            md: '2.5rem', // Standard desktops
                            lg: '3rem',   // Larger desktops
                            xl: '3.5rem'  // Ultra-wide or larger screens
                        },
                        // marginTop: '1rem',
                        marginTop: {
                            xs: '0.5rem', // Similar to small screens
                            sm: '0.75rem',   // Adjust for tablets and small desktops
                            md: '1rem', // Standard desktops
                            lg: '1.25rem',   // Larger desktops
                            xl: '1.5rem'  // Ultra-wide or larger screens
                        },
                    }}
                    />
            </div>
            <div>
                {memoizedHomeCardsList}
            </div>
        </div>
    );
}

export default React.memo(HomeScreen);
