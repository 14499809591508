import React, {useState, useEffect} from 'react';
import StyledListBox from "../../../boxes/StyledListBox";
import ListItem from "@mui/material/ListItem";
import BaseStyledButton from "../../../buttons/BaseStyledButton";
import FarrierUnAckedAlert from "../../../alerts/FarrierUnAckedAlert";
import {useConsoleLog} from "../../../hooks/logging/console";


export default function FarrierAllUnAckedAlertsList(props) {
    const [ampUserRec, setAmpUserRec] = useState(null);
    const [showLogs, setShowLogs] = useState(false);
    const [allUnAckedAlertsArr, setAllUnAckedAlertsArr] = useState([]);

    useConsoleLog(showLogs, [{label: 'FarrierAllUnAckedAlertsList allUnAckedAlertsArr', value: allUnAckedAlertsArr}]);

    // useEffect( set showLogs from props.showLogs
    useEffect(() => {
        if (props?.showLogs) {
            setShowLogs(props.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [props.showLogs]);

    // useEffect( set ampUserRec from props.ampUserRec
    useEffect(() => {
        if (props?.ampUserRec) {
            setAmpUserRec(props.ampUserRec);
        }
    }, [props.ampUserRec]);

    // useEffect( set allUnAckedAlertsArr from props.farrierUnAckedAlertsAll
    useEffect(() => {
        if (props?.farrierUnAckedAlertsAll) {
            setAllUnAckedAlertsArr(props.farrierUnAckedAlertsAll);
        }
    }, [props.farrierUnAckedAlertsAll]);

    return (
        <StyledListBox
            backgroundColor={'whitesmoke'}
        >
            {allUnAckedAlertsArr.map((alert, index) => {
                return (
                    <ListItem
                        key={alert.alertId}
                    >
                        <FarrierUnAckedAlert
                            alertIDBRecProp={alert}
                            showLogsProp={showLogs}
                            ampUserRecProp={ampUserRec}
                            updateAckedAlertHashesProp={props.updateAckedAlertHashesProp}
                        />
                    </ListItem>
                );
            })}
            <div>
                <BaseStyledButton
                    label={'Close'}
                    onClick={props.handleListCloseProp}
                />
            </div>
        </StyledListBox>
    );
}
