import React, {useState, useEffect, useContext} from 'react';
import { useParams, useLocation } from "react-router-dom";
import { RequireAuth } from "../../auth/RequireAuth";
import DevStateProvider from "../../../data/providers/DevStateProvider";
import EditAnimalScreen from "../../../screens/animals/EditAnimalScreen";
import AnimalShoePackagesProvider from "../../../data/providers/animals/AnimalShoePackagesProvider";
import AnimalNotesProvider from "../../../data/providers/notes/AnimalNotesProvider";
import { RequireSub } from "../../auth/RequireSub";
import { fetchAnimalByRouterPath, fetchClientById } from "../../../data/get/fetchRecords";
import { EditAnimalFormProvider } from "../../../data/providers/idb/EditAnimalFormProvider";
import { saveAmpUserAnimalRec, loadAmpUserAnimalRec, clearAmpUserAnimalRec } from "../../../data/indexedDb/ampUserAnimal";


export default function WrappedEditAnimal({routerPaths, ampUser, showLogs}) {
    // const { showLogs, ampUser, ampUserClients } = props;
    // const [devStateObj, setDevStateObj] = useState(null);
    // const { animalRouterPath } = useParams();
    const [animalRec, setAnimalRec] = useState(null);
    const [clientRec, setClientRec] = useState(null);
    // const [showLogs, setShowLogs] = useState(false);
    // const [shoePackageRec, setShoePackageRec] = useState(null)

    const location = useLocation();
    // const routerParams = useParams();
    const {animalRouterPath} = useParams();

    const stateAnimalRec = location.state?.animalRec;
    const stateClientRec = location.state?.clientRec;
    // const stateShoePackageRec = location.state?.shoePackageRec;

    // useEffect( setAnimalRec(stateAnimalRec) saveAmpUserAnimalRec(stateAnimalRec);
    useEffect(() => {
        if (stateAnimalRec) {
            setAnimalRec(stateAnimalRec);
            saveAmpUserAnimalRec(stateAnimalRec); // Save the animalRec to IndexedDB
        }
    }, [location]);

    // useEffect( setClientRec(stateClientRec);
    useEffect(() => {
        if (stateClientRec) {
            setClientRec(stateClientRec);
        }
    }, [location]);

    // useEffect( await loadAmpUserAnimalRec() =>  setAnimalRec(storedAnimalRec);
    useEffect(() => {
        const loadFromIndexedDB = async () => {
            const storedAnimalRec = await loadAmpUserAnimalRec();
            if (storedAnimalRec) {
                setAnimalRec(storedAnimalRec);
            }
        };

        loadFromIndexedDB();
    }, []);

    // useEffect( saveAmpUserAnimalRec(animalRec);
    useEffect(() => {
        if (animalRec) {
            saveAmpUserAnimalRec(animalRec); // Update the animalRec in IndexedDB whenever it changes
        }
    }, [animalRec]);

    useEffect(() => {
        // Reset or remove animalRec from IndexedDB when navigating away from "/edit-animal/animalRouterPath?"
        const resetAnimalRecIfNavigatingAway = () => {
            const currentPath = location.pathname;
            // const isEditAnimalPath = /^\/edit-animal(\/[^/]+)?$/.test(currentPath); // Match "/edit-animal" or "/edit-animal/animalRouterPath"
            const isEditAnimalPath = currentPath.startsWith('/edit-animal'); // Match "/edit-animal" or "/edit-animal/animalRouterPath"

            if (!isEditAnimalPath) {
                clearAmpUserAnimalRec(); // Clear animalRec from IndexedDB
                setAnimalRec(null); // Reset animalRec in state
            }
        };

        resetAnimalRecIfNavigatingAway();
    }, [location]);

    useEffect(() => {
        async function fetchAnimalAndClientRecs() {
            const resObj = {};
            if (animalRouterPath) {
                const animalRecRes = await fetchAnimalByRouterPath(animalRouterPath);
                if (animalRecRes && animalRecRes.length > 0) {
                    // setAnimalRec(animalRecRes[0]);
                    resObj.animalRecRes = animalRecRes[0];
                    const clientRecRes = await fetchClientById(animalRecRes[0].clientID);
                    if (clientRecRes.success && clientRecRes.clientModelRec && clientRecRes.clientModelRec.length > 0) {
                        // setClientRec(clientRecRes.clientModelRec[0]);
                        resObj.clientRecRes = clientRecRes.clientModelRec[0];
                    }
                }
            }
            return resObj;
        }

        if (!stateAnimalRec && !stateClientRec && animalRouterPath) {
            // TODO - ADD LOGIC TO GET ANIMAL AND CLIENT RECS
            // console.log('WrappedEditAnimal useEffect animalRouterPath', animalRouterPath);
            fetchAnimalAndClientRecs().then((resObj) => {
                if (showLogs) {
                    console.log('WrappedEditAnimal useEffect fetchAnimalAndClientRecs resObj', resObj);
                }
                if (resObj.animalRecRes) {
                    setAnimalRec(resObj.animalRecRes);
                }
                if (resObj.clientRecRes) {
                    setClientRec(resObj.clientRecRes);
                }
            }).catch((error) => {
                console.error('WrappedEditAnimal useEffect fetchAnimalAndClientRecs error', error);
            });
        }
    }, [stateAnimalRec, stateClientRec, animalRouterPath]);

    // useEffect(() => {
    //     if (stateShoePackageRec) {
    //         setShoePackageRec(stateShoePackageRec);
    //     }
    // }, [location]);

    // useEffect( console.log(  animalRec, clientRec
    useEffect(() => {
        if (showLogs) {
            console.log('WrappedEditAnimal animalRec', animalRec);
            console.log('WrappedEditAnimal clientRec', clientRec);
            // console.log('WrappedEditAnimal shoePackageRec', shoePackageRec);
        }
    }, [showLogs, animalRec, clientRec]);

    return (
        <RequireAuth>
            <DevStateProvider>
                {ampUser && animalRec && clientRec ? (
                    <RequireSub ampUser={ampUser}>
                        <EditAnimalFormProvider>
                            <AnimalNotesProvider animalId={animalRec.id} showLogs={showLogs}>
                                <AnimalShoePackagesProvider animalId={animalRec.id} showLogs={showLogs}>
                                    <EditAnimalScreen
                                        ampUser={ampUser}
                                        routerPaths={routerPaths}
                                        animalRec={animalRec}
                                        clientRec={clientRec}
                                    />
                                </AnimalShoePackagesProvider>
                            </AnimalNotesProvider>
                        </EditAnimalFormProvider>
                    </RequireSub>
                ) : (<div>loading...</div>)}
            </DevStateProvider>
        </RequireAuth>
    );
}
