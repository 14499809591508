import React, { useState, useEffect, useContext } from 'react';
import { Button, Menu, AppBar,
    Box, Toolbar, IconButton,
    Typography, Modal, Popover } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MoreIcon from '@mui/icons-material/MoreVert';
import { AppBarRouterMenu } from "./AppBarRouterMenu";
import {defaultName, deployState, devStateNow} from "../../utils/consts";
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { useLocation, useNavigate, } from 'react-router-dom';
import { Amplify } from "aws-amplify";
import aws_exports from '../../aws-exports';
import DevStateContext from "../../data/contexts/DevStateContext";
import AmpUserContext from "../../data/contexts/AmpUserContext";
import StyledHeaderButton from "../buttons/StyledHeaderButton";
import StyledMenuItem from "../text/StyledMenuItem";
import { useFarrierClientAlertsProvider } from "../../data/providers/alerts/FarrierClientAlertsProvider";
import { useFarrierAlertsIDBProvider } from "../../data/providers/alerts/FarrierAlertsIDBProvider";
import StyledHeaderAlertsButton from "../buttons/StyledHeaderAlertsButton";
import StyledHeaderPinnedButton from "../buttons/StyledHeaderPinnedButton";
import { useConsoleLog } from "../hooks/logging/console";
import FarrierAllUnAckedAlertsList from "../lists/alerts/unAcked/FarrierAllUnAckedAlertsList";
import {alertAckHashFactory} from "../../utils/helpers";
import FarrierAllPinnedAlertsList from "../lists/alerts/pinned/FarrierAllPinnedAlertsList";
import FarrierAllAlertsNotifsList from "../lists/alerts/all/FarrierAllAlertsNotifsList";

// import MenuItem from '@mui/material/MenuItem';


Amplify.configure(aws_exports);

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

const appRoutes = [
    'appointments',
    'clients',
    'animals',
    // 'ports',
    // 'gear-types',
    // 'create',
    '/'
]

function ScrollTop(props) {
    const {children, window} = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event) => {
        const anchor = ((event.target).querySelector('#back-to-top-anchor'));
        if (anchor) {
            anchor.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    };
}

export default function PrimarySearchAppBar(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [menuItems, setMenuItems] = useState([])
    const [anchorMenuEl, setAnchorMenuEl] = React.useState(null);
    const [allAlertsPopoverAnchorEl, setAllAlertsPopoverAnchorEl] = React.useState(null);
    const [unAckedAlertsPopoverAnchorEl, setUnAckedAlertsPopoverAnchorEl] = React.useState(null);
    const [pinnedPopoverAnchorEl, setPinnedPopoverAnchorEl] = React.useState(null);

    const [userDisplayName, setUserDisplayName] = useState('');
    const [ampUser, setAmpUser] = useState(null);
    const [userAuthenticated, setUserAuthenticated] = useState(false);
    const [showLogs, setShowLogs] = useState(false);
    const [blogUrl, setBlogUrl] = useState('');

    const [clientUpdatedSharedPublicClients, setClientUpdatedSharedPublicClients] = useState([]);
    const [allAlerts, setAllAlerts] = useState([]);
    const [allUnAckedAlerts, setAllUnAckedAlerts] = useState([]);
    const [unAckedAlertsPubClient, setUnAckedAlertsPubClient] = useState([]);
    const [allPinnedAlerts, setAllPinnedAlerts] = useState([]);
    const [pinnedAlertsPubClient, setPinnedAlertsPubClient] = useState([]);
    const [isCreatingAlerts, setIsCreatingAlerts] = useState(false);
    const [ackedAlertHashes, setAckedAlertHashes] = useState([]);
    const [newUnAckedAlertHashes, setNewUnAckedAlertHashes] = useState([]);
    const [unAckedAlertHashesUpdateDone, setUnAckedAlertHashesUpdateDone] = useState(false);

    const [displayUnAckedAlerts, setDisplayUnAckedAlerts] = useState(false);

    // const [displayNoti, setDisplayNoti] = useState(false);
    // const [networkConnected, setNetworkConnected] = useState( true);
    // const [networkModalText, setNetworkModalText] = useState('');

    const { ampUserRec, setAmpUserRec: setAmpUserRecContext } = useContext(AmpUserContext);
    const { devStateObj, staticUrlsObj } = useContext(DevStateContext);

    const { clientUpdatedSharedPublicClientRecs } = useFarrierClientAlertsProvider();
    const { publicClientAlertsUnAcked,
        publicClientAlertsPinned,
        createPublicClientAlert,
        isUpdatingPinnedAlerts,
        isUpdatingUnAckedAlerts,
        publicClientAlertsAll,
        refreshAllAlertsForAllStores } = useFarrierAlertsIDBProvider();

    const location = useLocation();
    let navigate = useNavigate();

    useConsoleLog(showLogs, [{label: 'PrimarySearchAppBar allUnAckedAlerts', value: allUnAckedAlerts}]);
    useConsoleLog(showLogs, [{label: 'PrimarySearchAppBar allAlerts', value: allAlerts}]);
    useConsoleLog(showLogs, [{label: 'PrimarySearchAppBar publicClientAlertsPinned', value: publicClientAlertsPinned}]);
    useConsoleLog(showLogs, [{label: 'PrimarySearchAppBar allPinnedAlerts', value: allPinnedAlerts}]);
    useConsoleLog(showLogs, [{label: 'PrimarySearchAppBar unAckedAlertsPubClient', value: unAckedAlertsPubClient}]);
    useConsoleLog(showLogs, [{label: 'PrimarySearchAppBar pinnedAlertsPubClient', value: pinnedAlertsPubClient}]);
    useConsoleLog(showLogs, [{label: 'PrimarySearchAppBar isCreatingAlerts', value: isCreatingAlerts}]);
    useConsoleLog(showLogs, [{label: 'PrimarySearchAppBar clientUpdatedSharedPublicClients', value: clientUpdatedSharedPublicClients}]);
    useConsoleLog(showLogs, [{label: 'PrimarySearchAppBar displayUnAckedAlerts', value: displayUnAckedAlerts}]);
    useConsoleLog(showLogs, [{label: 'PrimarySearchAppBar isUpdatingUnAckedAlerts', value: isUpdatingUnAckedAlerts}]);
    useConsoleLog(showLogs, [{label: 'PrimarySearchAppBar ackedAlertHashes', value: ackedAlertHashes}]);

    // useEffect( setCognitoUser(props.cognitoUser);
    // useEffect(() => {
    //     if (props.cognitoUser) {
    //         setCognitoUser(props.cognitoUser);
    //     }
    // }, [props]);

    // useEffect( setShowLogs(
    useEffect(() => {
        if (devStateObj) {
            // setBlogUrl(devStateObj.blogUrl);
            setShowLogs(devStateObj.showLogs);
        }
    }, [devStateObj]);

    // useEffect( setBlogUrl(
    useEffect(() => {
        if (staticUrlsObj && staticUrlsObj?.blogUrl) {
            if (staticUrlsObj.blogUrl?.isLive && staticUrlsObj.blogUrl?.url) setBlogUrl(staticUrlsObj.blogUrl.url);
            // setBlogUrl(devStateObj.blogUrl);
            // setShowLogs(devStateObj.showLogs);
        }
    }, [staticUrlsObj]);

    // useEffect( setAmpUserRecContext(ampUser);
    useEffect(() => {
        if (ampUser && setAmpUserRecContext) {
            setAmpUserRecContext(ampUser);
        }
    }, [ampUser, setAmpUserRecContext]);

    // useEffect( setClientUpdatedSharedPublicClients(clientUpdatedSharedPublicClientRecs)
    useEffect(() => {
        if (clientUpdatedSharedPublicClientRecs) {
            setClientUpdatedSharedPublicClients(clientUpdatedSharedPublicClientRecs);
        }
    }, [clientUpdatedSharedPublicClientRecs]);

    // useEffect( setUnAckedAlertsPubClient(publicClientAlertsUnAcked);
    useEffect(() => {
        if (!isCreatingAlerts && !isUpdatingUnAckedAlerts) {
            // setUnAckedAlertHashesUpdateDone(false);

            if (publicClientAlertsUnAcked) {
                // if (publicClientAlertsUnAcked.length === 0) {
                //     if (showLogs) {
                //         console.warn('>>> USEEFFECT RETURNING setUnAckedAlertsPubClient publicClientAlertsUnAcked:', publicClientAlertsUnAcked);
                //     }
                //     return;
                // }
                if (publicClientAlertsUnAcked.length > 0) {
                    // if (showLogs) {
                    //     console.warn('>>> USEEFFECT RETURNING setUnAckedAlertsPubClient publicClientAlertsUnAcked:', publicClientAlertsUnAcked);
                    // }
                    // return;
                    setUnAckedAlertHashesUpdateDone(false);
                }
                // setUnAckedAlertsPubClient(publicClientAlertsUnAcked);
                const newUnAckedAlertHashesCopy = [...newUnAckedAlertHashes];
                const newUnAckedAlertHashesCopySet = new Set(newUnAckedAlertHashesCopy);
                let updateStateHashes = false;
                publicClientAlertsUnAcked.forEach((alert) => {
                    const alertAckHash = alert.ackHash;
                    if (showLogs) {
                        console.warn('>>> USEEFFECT RETURNING setUnAckedAlertsPubClient alert:', alert);
                        console.warn('>>> USEEFFECT RETURNING setUnAckedAlertsPubClient alertAckHash:', alertAckHash);
                        console.warn('>>> USEEFFECT RETURNING setUnAckedAlertsPubClient ackedAlertHashes:', ackedAlertHashes);
                        console.warn('>>> USEEFFECT RETURNING setUnAckedAlertsPubClient newUnAckedAlertHashesCopy:', newUnAckedAlertHashesCopy);
                        console.warn('>>> USEEFFECT RETURNING setUnAckedAlertsPubClient newUnAckedAlertHashesCopySet:', newUnAckedAlertHashesCopySet);
                    }
                    if (ackedAlertHashes.includes(alertAckHash)) {
                        if (showLogs) {
                            console.warn('>>> USEEFFECT RETURNING setUnAckedAlertsPubClient alertAckHash:', alertAckHash);
                            console.warn('>>> USEEFFECT RETURNING setUnAckedAlertsPubClient alertAckHash already in ackedAlertHashes:', ackedAlertHashes);
                        }
                        return;
                    }
                    if (newUnAckedAlertHashesCopy.includes(alertAckHash)) {
                        if (showLogs) {
                            console.warn('>>> USEEFFECT RETURNING setUnAckedAlertsPubClient alertAckHash:', alertAckHash);
                            console.warn('>>> USEEFFECT RETURNING setUnAckedAlertsPubClient alertAckHash already in newUnAckedAlertHashesCopy:', newUnAckedAlertHashesCopy);
                        }
                        return;
                    }
                    if (newUnAckedAlertHashesCopySet.has(alertAckHash)) {
                        if (showLogs) {
                            console.warn('>>> USEEFFECT RETURNING setUnAckedAlertsPubClient alertAckHash:', alertAckHash);
                            console.warn('>>> USEEFFECT RETURNING setUnAckedAlertsPubClient alertAckHash already in newUnAckedAlertHashesCopySet:', newUnAckedAlertHashesCopySet);
                        }
                        return;
                    }
                    if (!ackedAlertHashes.includes(alertAckHash) && !newUnAckedAlertHashesCopySet.has(alertAckHash) && !newUnAckedAlertHashesCopy.includes(alertAckHash)) {
                        newUnAckedAlertHashesCopy.push(alertAckHash);
                        newUnAckedAlertHashesCopySet.add(alertAckHash);
                        updateStateHashes = true;
                    }
                });
                if (showLogs) {
                    console.warn('>>> USEEFFECT RETURNING setUnAckedAlertsPubClient updateStateHashes:', updateStateHashes);
                }
                if (updateStateHashes) {
                    setNewUnAckedAlertHashes(newUnAckedAlertHashesCopy);
                }
                setUnAckedAlertsPubClient(publicClientAlertsUnAcked);
            }
            setUnAckedAlertHashesUpdateDone(true);
        }
    // }, [publicClientAlertsUnAcked, isUpdatingUnAckedAlerts, isCreatingAlerts]);
    }, [publicClientAlertsUnAcked, isUpdatingUnAckedAlerts, isCreatingAlerts, ackedAlertHashes, newUnAckedAlertHashes, showLogs]);

    // useEffect( setPinnedAlertsPubClient(publicClientAlertsPinned);
    useEffect(() => {
        if (!isCreatingAlerts && !isUpdatingPinnedAlerts) {
            if (publicClientAlertsPinned) {
                setPinnedAlertsPubClient(publicClientAlertsPinned);
            }
        }
    }, [publicClientAlertsPinned, isUpdatingPinnedAlerts]);

    // useEffect( setAllAlerts(publicClientAlertsAll);
    // useEffect(() => {
    //     if (publicClientAlertsAll) {
    //         setAllAlerts(publicClientAlertsAll);
    //     }
    // }, [publicClientAlertsAll]);

    // useEffect( setAllAlerts(publicClientAlertsAll);
    useEffect(() => {
        if (!isCreatingAlerts && !isUpdatingUnAckedAlerts) {
            setAllAlerts(publicClientAlertsAll);
            // refreshAllAlertsForAllStores();
        }
    }, [publicClientAlertsAll, isUpdatingUnAckedAlerts, isCreatingAlerts]);


    // useEffect( refreshAllAlertsForAllStores();
    useEffect(() => {
        if (!isCreatingAlerts && !isUpdatingUnAckedAlerts) {
            // setAllAlerts(publicClientAlertsAll);
            refreshAllAlertsForAllStores();
        }
    }, [isUpdatingUnAckedAlerts, isCreatingAlerts]);

    // useEffect( refreshAllAlertsForAllStores();
    useEffect(() => {
        if (!isCreatingAlerts && !isUpdatingPinnedAlerts) {
            // setAllAlerts(publicClientAlertsAll);
            refreshAllAlertsForAllStores();
        }
    }, [isUpdatingPinnedAlerts, isCreatingAlerts]);



    // TODO - UPDATE THESE TWO,
    //  SETTING THE ALL ALERTS TYPES ARRS,
    //  TO ALSO INCLUDE OTHER IDB ALERTS TYPES / CLASSES

    // useEffect( setAllUnAckedAlerts(unAckedAlertsPubClient);
    useEffect(() => {
        if (unAckedAlertsPubClient) {
            setAllUnAckedAlerts(unAckedAlertsPubClient);
        }
    }, [unAckedAlertsPubClient]);

    // useEffect( setAllPinnedAlerts(pinnedAlertsPubClient);
    useEffect(() => {
        if (pinnedAlertsPubClient) {
            setAllPinnedAlerts(pinnedAlertsPubClient);
        }
    }, [pinnedAlertsPubClient]);


    function updateAckedAlertHashes(ackHash) {
        setAckedAlertHashes([...ackedAlertHashes, ackHash]);
    }

    // useEffect( creates new unAcked alerts
    // useEffect(() => {
    //     async function createNewIDBAlertRecs() {
    //         // for each clientUpdatedSharedPublicClientRecs, check if there are any unAcked alerts, with a matching publicClientId, and if not create a new alert
    //         if (!ampUser) {
    //             if (showLogs) {
    //                 console.warn('>>> CREATE ALERTS USEEFFECT - returning because of !ampUser:', ampUser);
    //             }
    //             return;
    //         }
    //
    //         if (isUpdatingUnAckedAlerts) {
    //             if (showLogs) {
    //                 console.warn('>>> CREATE ALERTS USEEFFECT - returning because of isUpdatingUnAckedAlerts:', isUpdatingUnAckedAlerts);
    //             }
    //             return;
    //         }
    //
    //         // if (isCreatingAlerts) {
    //         //     if (showLogs) {
    //         //         console.warn('>>> CREATE ALERTS USEEFFECT - returning because of isCreatingAlerts:', isCreatingAlerts);
    //         //     }
    //         //     return;
    //         // }
    //
    //         setIsCreatingAlerts(true);
    //
    //         // const newUnAckedAlertHashesCopy = [...newUnAckedAlertHashes];
    //         // const newUnAckedAlertHashesCopySet = new Set(newUnAckedAlertHashesCopy);
    //
    //         // if (clientUpdatedSharedPublicClients && publicClientAlertsUnAcked) {
    //         if (clientUpdatedSharedPublicClients && clientUpdatedSharedPublicClients?.length > 0 && publicClientAlertsUnAcked) {
    //             // setIsCreatingAlerts(true);
    //             let updateStateHashes = false;
    //
    //             // if (clientUpdatedSharedPublicClients && unAckedAlertsPubClient && isCreatingAlerts) {
    //             // convert to async for creating alerts
    //
    //             // const newUnAckedAlertHashesCopy = [...newUnAckedAlertHashes];
    //             const newUnAckedAlertHashesCopy = [...newUnAckedAlertHashes];
    //             const newUnAckedAlertHashesCopySet = new Set(newUnAckedAlertHashesCopy);
    //
    //             if (showLogs) {
    //                 console.warn('>>> CREATE ALERTS USEEFFECT clientUpdatedSharedPublicClients:', clientUpdatedSharedPublicClients);
    //                 console.warn('>>> CREATE ALERTS USEEFFECT publicClientAlertsUnAcked:', publicClientAlertsUnAcked);
    //                 console.warn('>>> CREATE ALERTS USEEFFECT newUnAckedAlertHashesCopy:', newUnAckedAlertHashesCopy);
    //             }
    //
    //             for (let i = 0; i < clientUpdatedSharedPublicClients.length; i++) {
    //                 const pubClientRec = clientUpdatedSharedPublicClients[i];
    //                 const clientPubClientId = pubClientRec.id;
    //                 const alertAckHash = alertAckHashFactory(ampUser.id, pubClientRec.id, 'clientUpdatedSharedPublicClient');
    //                 if (showLogs) {
    //                     console.warn('>>> CREATE ALERTS USEEFFECT clientUpdatedSharedPublicClient pubClientRec:', pubClientRec);
    //                     console.warn('>>> CREATE ALERTS USEEFFECT clientUpdatedSharedPublicClient alertAckHash:', alertAckHash);
    //                 }
    //                 if (ackedAlertHashes.includes(alertAckHash)) {
    //                     if (showLogs) {
    //                         console.warn('>>> CREATE ALERTS USEEFFECT alertAckHash already in ackedAlertHashes:', ackedAlertHashes);
    //                     }
    //                     continue;
    //                 }
    //                 if (newUnAckedAlertHashesCopy.includes(alertAckHash)) {
    //                     if (showLogs) {
    //                         console.warn('>>> CREATE ALERTS USEEFFECT alertAckHash already in newUnAckedAlertHashesCopy:', newUnAckedAlertHashesCopy);
    //                     }
    //                     continue;
    //                 }
    //                 if (newUnAckedAlertHashesCopySet.has(alertAckHash)) {
    //                     if (showLogs) {
    //                         console.warn('>>> CREATE ALERTS USEEFFECT alertAckHash already in newUnAckedAlertHashesCopySet:', newUnAckedAlertHashesCopySet);
    //                     }
    //                     continue;
    //                 }
    //                 // const clientPubClientAlerts = publicClientAlertsUnAcked.filter((alert) => alert.publicClientId === clientPubClientId);
    //                 const clientPubClientAlerts = publicClientAlertsUnAcked.filter((alert) => alert.ackHash === alertAckHash);
    //                 if (showLogs) {
    //                     console.warn('>>> CREATE ALERTS USEEFFECT clientUpdatedSharedPublicClient clientPubClientAlerts:', clientPubClientAlerts);
    //                 }
    //                 if (clientPubClientAlerts.length === 0) {
    //                     // const alertAckHash = alertAckHashFactory(ampUser.id, pubClientRec.id, 'clientUpdatedSharedPublicClient');
    //                     // if (!ackedAlertHashes.includes(alertAckHash)) {
    //                     // if (!ackedAlertHashes.includes(alertAckHash) && !newUnAckedAlertHashes.includes(alertAckHash)) {
    //
    //                     // if (!ackedAlertHashes.includes(alertAckHash) && !newUnAckedAlertHashesCopy.includes(alertAckHash)) {
    //                     // if (!ackedAlertHashes.includes(alertAckHash) && !newUnAckedAlertHashesCopySet.has(alertAckHash)) {
    //                     if (!ackedAlertHashes.includes(alertAckHash) && !newUnAckedAlertHashesCopySet.has(alertAckHash) && !newUnAckedAlertHashesCopy.includes(alertAckHash)) {
    //                         if (showLogs) {
    //                             console.warn('>>> CREATE ALERTS USEEFFECT creating new alert for index:', i);
    //                             console.warn('>>> CREATE ALERTS USEEFFECT creating new alert for pubClientRec:', pubClientRec);
    //                             console.warn('>>> CREATE ALERTS USEEFFECT creating new alert with ackHash:', alertAckHash);
    //                             console.warn('>>> CREATE ALERTS USEEFFECT new ackHash not in ackedAlertHashes:', ackedAlertHashes);
    //                             console.warn('>>> CREATE ALERTS USEEFFECT new ackHash not in newUnAckedAlertHashesCopySet:', newUnAckedAlertHashesCopySet);
    //                         }
    //                         newUnAckedAlertHashesCopy.push(alertAckHash);
    //                         newUnAckedAlertHashesCopySet.add(alertAckHash);
    //                         const newPubClientAlert = await createPublicClientAlert({
    //                             publicClientId: clientPubClientId,
    //                             alertType: 'clientUpdatedSharedPublicClient',
    //                             alertData: pubClientRec,
    //                             alertMsg: 'Client has updated their shared public client record',
    //                             ackHash: alertAckHash
    //                         });
    //                         updateStateHashes = true;
    //                         if (showLogs) {
    //                             console.warn('>>> CREATE ALERTS USEEFFECT created new alert for index:', i);
    //                             console.warn('>>> CREATE ALERTS USEEFFECT created new alert for pubClientRec:', pubClientRec);
    //                             console.warn('>>> CREATE ALERTS USEEFFECT created new alert newPubClientAlert:', newPubClientAlert);
    //                             console.warn('>>> CREATE ALERTS USEEFFECT newUnAckedAlertHashesCopy:', newUnAckedAlertHashesCopy);
    //                             console.warn('>>> CREATE ALERTS USEEFFECT newUnAckedAlertHashesCopySet:', newUnAckedAlertHashesCopySet);
    //                         }
    //                         // setNewUnAckedAlertHashes([...newUnAckedAlertHashes, alertAckHash]);
    //                         // newUnAckedAlertHashesCopy.push(alertAckHash);
    //                     }
    //                 }
    //             }
    //             // setNewUnAckedAlertHashes([...newUnAckedAlertHashes, alertAckHash]);
    //
    //             if (showLogs) {
    //                 console.warn('>>> CREATE ALERTS USEEFFECT - AFTER LOOP');
    //                 console.warn('>>> CREATE ALERTS USEEFFECT updateStateHashes:', updateStateHashes);
    //             }
    //             if (updateStateHashes) {
    //                 if (showLogs) {
    //                     console.warn('>>> CREATE ALERTS USEEFFECT newUnAckedAlertHashesCopy:', newUnAckedAlertHashesCopy);
    //                     console.warn('>>> CREATE ALERTS USEEFFECT calling setNewUnAckedAlertHashes(newUnAckedAlertHashesCopy):', newUnAckedAlertHashesCopy);
    //                     console.warn('>>> CREATE ALERTS USEEFFECT updateStateHashes:', updateStateHashes);
    //                 }
    //                 setNewUnAckedAlertHashes(newUnAckedAlertHashesCopy);
    //             }
    //             // setNewUnAckedAlertHashes(newUnAckedAlertHashesCopy);
    //
    //             // const setToArr = Array.from(newUnAckedAlertHashesCopySet);
    //             // if (showLogs) {
    //             //     console.warn('>>> CREATE ALERTS USEEFFECT newUnAckedAlertHashesCopySet:', newUnAckedAlertHashesCopySet);
    //             //     console.warn('>>> CREATE ALERTS USEEFFECT setToArr:', setToArr);
    //             // }
    //             // setNewUnAckedAlertHashes(setToArr);
    //
    //             // newUnAckedAlertHashesCopy.push(alertAckHash);
    //
    //             // clientUpdatedSharedPublicClients.forEach((pubClientRec)  => {
    //             //     const clientPubClientId = pubClientRec.id;
    //             //     const clientPubClientAlerts = publicClientAlertsUnAcked.filter((alert) => alert.publicClientId === clientPubClientId);
    //             //     if (clientPubClientAlerts.length === 0) {
    //             //         const alertAckHash = alertAckHashFactory(ampUser.id, pubClientRec.id, 'clientUpdatedSharedPublicClient');
    //             //         if (!ackedAlertHashes.includes(alertAckHash)) {
    //             //             console.warn('>>> CREATE ALERTS USEEFFECT creating new alert for pubClientRec:', pubClientRec);
    //             //             await createPublicClientAlert({
    //             //                 publicClientId: clientPubClientId,
    //             //                 alertType: 'clientUpdatedSharedPublicClient',
    //             //                 alertData: pubClientRec,
    //             //                 alertMsg: 'Client has updated their shared public client record',
    //             //                 ackHash: alertAckHash
    //             //             });
    //             //         }
    //             //     }
    //             // });
    //             // setIsCreatingAlerts(false);
    //         }
    //         setIsCreatingAlerts(false);
    //         if (showLogs) {
    //             console.warn('>>> CREATE ALERTS USEEFFECT - END, isCreatingAlerts set back to false');
    //             console.warn('>>> CREATE ALERTS USEEFFECT - END');
    //         }
    //     }
    //
    //     // // for each clientUpdatedSharedPublicClientRecs, check if there are any unAcked alerts, with a matching publicClientId, and if not create a new alert
    //     // if (isUpdatingUnAckedAlerts) return;
    //     //
    //     // setIsCreatingAlerts(true);
    //     // if (clientUpdatedSharedPublicClients && publicClientAlertsUnAcked) {
    //     //     console.warn('>>> CREATE ALERTS USEEFFECT clientUpdatedSharedPublicClients:', clientUpdatedSharedPublicClients);
    //     //     console.warn('>>> CREATE ALERTS USEEFFECT publicClientAlertsUnAcked:', publicClientAlertsUnAcked);
    //     //     // if (clientUpdatedSharedPublicClients && unAckedAlertsPubClient && isCreatingAlerts) {
    //     //     clientUpdatedSharedPublicClients.forEach((pubClientRec) => {
    //     //         const clientPubClientId = pubClientRec.id;
    //     //         const clientPubClientAlerts = publicClientAlertsUnAcked.filter((alert) => alert.publicClientId === clientPubClientId);
    //     //         if (clientPubClientAlerts.length === 0) {
    //     //             const alertAckHash = alertAckHashFactory(ampUser.id, pubClientRec.id, 'clientUpdatedSharedPublicClient');
    //     //             if (!ackedAlertHashes.includes(alertAckHash)) {
    //     //                 console.warn('>>> CREATE ALERTS USEEFFECT creating new alert for pubClientRec:', pubClientRec);
    //     //                 createPublicClientAlert({
    //     //                     publicClientId: clientPubClientId,
    //     //                     alertType: 'clientUpdatedSharedPublicClient',
    //     //                     alertData: pubClientRec,
    //     //                     alertMsg: 'Client has updated their shared public client record',
    //     //                     ackHash: alertAckHash
    //     //                 });
    //     //             }
    //     //         }
    //     //     });
    //     //     // setIsCreatingAlerts(false);
    //     // }
    //     // setIsCreatingAlerts(false);
    //
    //     createNewIDBAlertRecs();
    //
    //     // }, [clientUpdatedSharedPublicClients, unAckedAlertsPubClient, isCreatingAlerts]);
    //     // }, [clientUpdatedSharedPublicClients, unAckedAlertsPubClient]);
    //     // }, [clientUpdatedSharedPublicClients, unAckedAlertsPubClient, isUpdatingUnAckedAlerts]);
    //     // }, [clientUpdatedSharedPublicClients, publicClientAlertsUnAcked, isUpdatingUnAckedAlerts]);
    // // }, [clientUpdatedSharedPublicClients, publicClientAlertsUnAcked, isUpdatingUnAckedAlerts, ackedAlertHashes, showLogs, newUnAckedAlertHashes]);
    // // }, [clientUpdatedSharedPublicClients, publicClientAlertsUnAcked, isUpdatingUnAckedAlerts, ackedAlertHashes, showLogs]);
    // // }, [ampUser, clientUpdatedSharedPublicClients, publicClientAlertsUnAcked, isUpdatingUnAckedAlerts, ackedAlertHashes, showLogs]);
    // }, [ampUser, clientUpdatedSharedPublicClients, publicClientAlertsUnAcked, isUpdatingUnAckedAlerts, ackedAlertHashes, showLogs, newUnAckedAlertHashes]);

    // useEffect( creates new unAcked alerts
    useEffect(() => {
        async function createNewIDBAlertRecs() {
            // for each clientUpdatedSharedPublicClientRecs, check if there are any unAcked alerts, with a matching publicClientId, and if not create a new alert
            if (!ampUser) {
                if (showLogs) {
                    console.warn('>>> CREATE ALERTS USEEFFECT - returning because of !ampUser:', ampUser);
                }
                return;
            }

            if (isUpdatingUnAckedAlerts) {
                if (showLogs) {
                    console.warn('>>> CREATE ALERTS USEEFFECT - returning because of isUpdatingUnAckedAlerts:', isUpdatingUnAckedAlerts);
                }
                return;
            }

            if (!unAckedAlertHashesUpdateDone) {
                if (showLogs) {
                    console.warn('>>> CREATE ALERTS USEEFFECT - returning because of !unAckedAlertHashesUpdateDone:', unAckedAlertHashesUpdateDone);
                }
                return;
            }

            // if (isCreatingAlerts) {
            //     if (showLogs) {
            //         console.warn('>>> CREATE ALERTS USEEFFECT - returning because of isCreatingAlerts:', isCreatingAlerts);
            //     }
            //     return;
            // }

            setIsCreatingAlerts(true);

            // const newUnAckedAlertHashesCopy = [...newUnAckedAlertHashes];
            // const newUnAckedAlertHashesCopySet = new Set(newUnAckedAlertHashesCopy);

            // if (clientUpdatedSharedPublicClients && publicClientAlertsUnAcked) {
            // if (clientUpdatedSharedPublicClients && clientUpdatedSharedPublicClients?.length > 0 && publicClientAlertsUnAcked) {
            if (clientUpdatedSharedPublicClients && clientUpdatedSharedPublicClients?.length > 0 && unAckedAlertsPubClient) {
                // setIsCreatingAlerts(true);
                let updateStateHashes = false;

                // if (clientUpdatedSharedPublicClients && unAckedAlertsPubClient && isCreatingAlerts) {
                // convert to async for creating alerts

                // const newUnAckedAlertHashesCopy = [...newUnAckedAlertHashes];
                const newUnAckedAlertHashesCopy = [...newUnAckedAlertHashes];
                const newUnAckedAlertHashesCopySet = new Set(newUnAckedAlertHashesCopy);

                if (showLogs) {
                    console.warn('>>> CREATE ALERTS USEEFFECT clientUpdatedSharedPublicClients:', clientUpdatedSharedPublicClients);
                    console.warn('>>> CREATE ALERTS USEEFFECT unAckedAlertsPubClient:', unAckedAlertsPubClient);
                    console.warn('>>> CREATE ALERTS USEEFFECT newUnAckedAlertHashesCopy:', newUnAckedAlertHashesCopy);
                }

                for (let i = 0; i < clientUpdatedSharedPublicClients.length; i++) {
                    const pubClientRec = clientUpdatedSharedPublicClients[i];
                    const clientPubClientId = pubClientRec.id;
                    const alertAckHash = alertAckHashFactory(ampUser.id, pubClientRec.id, 'clientUpdatedSharedPublicClient');

                    if (showLogs) {
                        console.warn('>>> CREATE ALERTS USEEFFECT clientUpdatedSharedPublicClient pubClientRec:', pubClientRec);
                        console.warn('>>> CREATE ALERTS USEEFFECT clientUpdatedSharedPublicClient alertAckHash:', alertAckHash);
                    }

                    if (ackedAlertHashes.includes(alertAckHash)) {
                        if (showLogs) {
                            console.warn('>>> CREATE ALERTS USEEFFECT alertAckHash already in ackedAlertHashes:', ackedAlertHashes);
                        }
                        continue;
                    }
                    if (newUnAckedAlertHashesCopy.includes(alertAckHash)) {
                        if (showLogs) {
                            console.warn('>>> CREATE ALERTS USEEFFECT alertAckHash already in newUnAckedAlertHashesCopy:', newUnAckedAlertHashesCopy);
                        }
                        continue;
                    }
                    if (newUnAckedAlertHashesCopySet.has(alertAckHash)) {
                        if (showLogs) {
                            console.warn('>>> CREATE ALERTS USEEFFECT alertAckHash already in newUnAckedAlertHashesCopySet:', newUnAckedAlertHashesCopySet);
                        }
                        continue;
                    }

                    // const clientPubClientAlerts = publicClientAlertsUnAcked.filter((alert) => alert.publicClientId === clientPubClientId);
                    const clientPubClientAlerts = unAckedAlertsPubClient.filter((alert) => alert.ackHash === alertAckHash);
                    if (showLogs) {
                        console.warn('>>> CREATE ALERTS USEEFFECT clientUpdatedSharedPublicClient clientPubClientAlerts:', clientPubClientAlerts);
                    }

                    if (clientPubClientAlerts.length === 0) {
                        // const alertAckHash = alertAckHashFactory(ampUser.id, pubClientRec.id, 'clientUpdatedSharedPublicClient');
                        // if (!ackedAlertHashes.includes(alertAckHash)) {
                        // if (!ackedAlertHashes.includes(alertAckHash) && !newUnAckedAlertHashes.includes(alertAckHash)) {

                        // if (!ackedAlertHashes.includes(alertAckHash) && !newUnAckedAlertHashesCopy.includes(alertAckHash)) {
                        // if (!ackedAlertHashes.includes(alertAckHash) && !newUnAckedAlertHashesCopySet.has(alertAckHash)) {
                        if (!ackedAlertHashes.includes(alertAckHash) && !newUnAckedAlertHashesCopySet.has(alertAckHash) && !newUnAckedAlertHashesCopy.includes(alertAckHash)) {
                            if (showLogs) {
                                console.warn('>>> CREATE ALERTS USEEFFECT creating new alert for index:', i);
                                console.warn('>>> CREATE ALERTS USEEFFECT creating new alert for pubClientRec:', pubClientRec);
                                console.warn('>>> CREATE ALERTS USEEFFECT creating new alert with ackHash:', alertAckHash);
                                console.warn('>>> CREATE ALERTS USEEFFECT new ackHash not in ackedAlertHashes:', ackedAlertHashes);
                                console.warn('>>> CREATE ALERTS USEEFFECT new ackHash not in newUnAckedAlertHashesCopySet:', newUnAckedAlertHashesCopySet);
                            }

                            newUnAckedAlertHashesCopy.push(alertAckHash);
                            newUnAckedAlertHashesCopySet.add(alertAckHash);
                            const newPubClientAlert = await createPublicClientAlert({
                                publicClientId: clientPubClientId,
                                alertType: 'clientUpdatedSharedPublicClient',
                                alertData: pubClientRec,
                                // alertMsg: 'Client has updated their shared public client record',
                                alertMsg: `Client ${pubClientRec.name} has updated their shared public client record`,
                                ackHash: alertAckHash
                            });
                            updateStateHashes = true;

                            if (showLogs) {
                                console.warn('>>> CREATE ALERTS USEEFFECT created new alert for index:', i);
                                console.warn('>>> CREATE ALERTS USEEFFECT created new alert for pubClientRec:', pubClientRec);
                                console.warn('>>> CREATE ALERTS USEEFFECT created new alert newPubClientAlert:', newPubClientAlert);
                                console.warn('>>> CREATE ALERTS USEEFFECT newUnAckedAlertHashesCopy:', newUnAckedAlertHashesCopy);
                                console.warn('>>> CREATE ALERTS USEEFFECT newUnAckedAlertHashesCopySet:', newUnAckedAlertHashesCopySet);
                            }

                            // setNewUnAckedAlertHashes([...newUnAckedAlertHashes, alertAckHash]);
                            // newUnAckedAlertHashesCopy.push(alertAckHash);
                        }
                    }
                }
                // setNewUnAckedAlertHashes([...newUnAckedAlertHashes, alertAckHash]);

                if (showLogs) {
                    console.warn('>>> CREATE ALERTS USEEFFECT - AFTER LOOP');
                    console.warn('>>> CREATE ALERTS USEEFFECT updateStateHashes:', updateStateHashes);
                }

                if (updateStateHashes) {
                    if (showLogs) {
                        console.warn('>>> CREATE ALERTS USEEFFECT newUnAckedAlertHashesCopy:', newUnAckedAlertHashesCopy);
                        console.warn('>>> CREATE ALERTS USEEFFECT calling setNewUnAckedAlertHashes(newUnAckedAlertHashesCopy):', newUnAckedAlertHashesCopy);
                        console.warn('>>> CREATE ALERTS USEEFFECT updateStateHashes:', updateStateHashes);
                    }
                    setNewUnAckedAlertHashes(newUnAckedAlertHashesCopy);
                }

                // setNewUnAckedAlertHashes(newUnAckedAlertHashesCopy);

                // const setToArr = Array.from(newUnAckedAlertHashesCopySet);
                // if (showLogs) {
                //     console.warn('>>> CREATE ALERTS USEEFFECT newUnAckedAlertHashesCopySet:', newUnAckedAlertHashesCopySet);
                //     console.warn('>>> CREATE ALERTS USEEFFECT setToArr:', setToArr);
                // }
                // setNewUnAckedAlertHashes(setToArr);

                // newUnAckedAlertHashesCopy.push(alertAckHash);

                // clientUpdatedSharedPublicClients.forEach((pubClientRec)  => {
                //     const clientPubClientId = pubClientRec.id;
                //     const clientPubClientAlerts = publicClientAlertsUnAcked.filter((alert) => alert.publicClientId === clientPubClientId);
                //     if (clientPubClientAlerts.length === 0) {
                //         const alertAckHash = alertAckHashFactory(ampUser.id, pubClientRec.id, 'clientUpdatedSharedPublicClient');
                //         if (!ackedAlertHashes.includes(alertAckHash)) {
                //             console.warn('>>> CREATE ALERTS USEEFFECT creating new alert for pubClientRec:', pubClientRec);
                //             await createPublicClientAlert({
                //                 publicClientId: clientPubClientId,
                //                 alertType: 'clientUpdatedSharedPublicClient',
                //                 alertData: pubClientRec,
                //                 alertMsg: 'Client has updated their shared public client record',
                //                 ackHash: alertAckHash
                //             });
                //         }
                //     }
                // });
                // setIsCreatingAlerts(false);
            }
            setIsCreatingAlerts(false);
            if (showLogs) {
                console.warn('>>> CREATE ALERTS USEEFFECT - END, isCreatingAlerts set back to false');
                console.warn('>>> CREATE ALERTS USEEFFECT - END');
            }
        }

        // // for each clientUpdatedSharedPublicClientRecs, check if there are any unAcked alerts, with a matching publicClientId, and if not create a new alert
        // if (isUpdatingUnAckedAlerts) return;
        //
        // setIsCreatingAlerts(true);
        // if (clientUpdatedSharedPublicClients && publicClientAlertsUnAcked) {
        //     console.warn('>>> CREATE ALERTS USEEFFECT clientUpdatedSharedPublicClients:', clientUpdatedSharedPublicClients);
        //     console.warn('>>> CREATE ALERTS USEEFFECT publicClientAlertsUnAcked:', publicClientAlertsUnAcked);
        //     // if (clientUpdatedSharedPublicClients && unAckedAlertsPubClient && isCreatingAlerts) {
        //     clientUpdatedSharedPublicClients.forEach((pubClientRec) => {
        //         const clientPubClientId = pubClientRec.id;
        //         const clientPubClientAlerts = publicClientAlertsUnAcked.filter((alert) => alert.publicClientId === clientPubClientId);
        //         if (clientPubClientAlerts.length === 0) {
        //             const alertAckHash = alertAckHashFactory(ampUser.id, pubClientRec.id, 'clientUpdatedSharedPublicClient');
        //             if (!ackedAlertHashes.includes(alertAckHash)) {
        //                 console.warn('>>> CREATE ALERTS USEEFFECT creating new alert for pubClientRec:', pubClientRec);
        //                 createPublicClientAlert({
        //                     publicClientId: clientPubClientId,
        //                     alertType: 'clientUpdatedSharedPublicClient',
        //                     alertData: pubClientRec,
        //                     alertMsg: 'Client has updated their shared public client record',
        //                     ackHash: alertAckHash
        //                 });
        //             }
        //         }
        //     });
        //     // setIsCreatingAlerts(false);
        // }
        // setIsCreatingAlerts(false);

        createNewIDBAlertRecs();

        // }, [clientUpdatedSharedPublicClients, unAckedAlertsPubClient, isCreatingAlerts]);
        // }, [clientUpdatedSharedPublicClients, unAckedAlertsPubClient]);
        // }, [clientUpdatedSharedPublicClients, unAckedAlertsPubClient, isUpdatingUnAckedAlerts]);
        // }, [clientUpdatedSharedPublicClients, publicClientAlertsUnAcked, isUpdatingUnAckedAlerts]);
        // }, [clientUpdatedSharedPublicClients, publicClientAlertsUnAcked, isUpdatingUnAckedAlerts, ackedAlertHashes, showLogs, newUnAckedAlertHashes]);
        // }, [clientUpdatedSharedPublicClients, publicClientAlertsUnAcked, isUpdatingUnAckedAlerts, ackedAlertHashes, showLogs]);
        // }, [ampUser, clientUpdatedSharedPublicClients, publicClientAlertsUnAcked, isUpdatingUnAckedAlerts, ackedAlertHashes, showLogs]);
    // }, [ampUser, clientUpdatedSharedPublicClients, publicClientAlertsUnAcked, isUpdatingUnAckedAlerts, ackedAlertHashes, showLogs, newUnAckedAlertHashes]);
    // }, [ampUser, clientUpdatedSharedPublicClients, unAckedAlertsPubClient, isUpdatingUnAckedAlerts, ackedAlertHashes, showLogs, newUnAckedAlertHashes]);
    }, [ampUser, clientUpdatedSharedPublicClients, unAckedAlertsPubClient, isUpdatingUnAckedAlerts, ackedAlertHashes, showLogs, newUnAckedAlertHashes, unAckedAlertHashesUpdateDone]);

    // useEffect( setUserAuthenticated(
    useEffect(() => {
        if (props?.authStatus) {
            if (props.authStatus === 'authenticated') {
                setUserAuthenticated(true);
            } else {
                setUserAuthenticated(false);
            }
        }
    }, [props]);

    // window.addEventListener("offline", (event) => {
    //     setNetworkConnected(false);
    //     setNetworkModalText('network connection is offline');
    //     setDisplayNoti(true);
    //     // displayErrorNoti('lost network connection');
    //     // window.location.reload();
    // });

    // window.addEventListener("online", (event) => {
    //     setNetworkConnected(true);
    //     setNetworkModalText('network connection is back online');
    //     setDisplayNoti(true);
    //     // displayNetworkReconnectedNoti();
    //     // window.location.reload();
    // });

    // todo - should probably group these into a separate component

    // const displayNetworkReconnectedNoti = () => {
    //     setNotiMessage('Connected to network')
    //     setNotiSeverity('info')
    //     setDisplayNoti(true);
    // }

    // const displayRecordAddedNoti = () => {
    //     setNotiMessage('Record Added!')
    //     setNotiSeverity('success')
    //     setDisplayNoti(true);
    // }

    // const displayUploadSuccessNoti = () => {
    //     setNotiMessage('Uploaded File Successfully!')
    //     setNotiSeverity('success')
    //     setDisplayNoti(true);
    // }

    // const displayErrorNoti = (errMsg) => {
    //     setNotiMessage(errMsg)
    //     setNotiSeverity('warning')
    //     setDisplayNoti(true);
    // }

    // const handleNotiClose = (event, reason) => {
    //     if (reason === 'clickaway') {
    //         return;
    //     }
    //     setDisplayNoti(false);
    // }

    // useEffect( setAmpUser( setUserDisplayName(
    useEffect(() => {
        if (props?.ampUser) {
            setAmpUser(props.ampUser);
            if (props.ampUser?.name !== defaultName) {
                setUserDisplayName(props.ampUser.name)
            } else if (props.ampUser?.email) {
                setUserDisplayName(props.ampUser.email.split('@')[0])
            }
        } else {
            setUserDisplayName('');
        }
    }, [props.ampUser]);

    // useEffect( setMenuItems(appRoutes)
    useEffect(() => {
        setMenuItems(appRoutes);
    }, []);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const open = Boolean(anchorMenuEl);
    const isUnAckedAlertsPopoverOpen = Boolean(unAckedAlertsPopoverAnchorEl);
    const isAllAlertsPopoverOpen = Boolean(allAlertsPopoverAnchorEl);
    const unAckedAlertsPopoverId = isUnAckedAlertsPopoverOpen ? 'notification-popover' : undefined;
    const allAlertsPopoverId = isAllAlertsPopoverOpen ? 'all-alerts-popover' : undefined;
    const isPinnedPopoverOpen = Boolean(pinnedPopoverAnchorEl);
    const pinnedPopoverId = isPinnedPopoverOpen ? 'pinned-popover' : undefined;


    // Handle opening the UnAckedAlertsPopover
    const handleUnAckedAlertsPopoverClick = (event) => {
        setPinnedPopoverAnchorEl(null);
        if (allUnAckedAlerts && allUnAckedAlerts.length > 0) {
            setUnAckedAlertsPopoverAnchorEl(event.currentTarget);
        }
    };

    // Handle opening the AllAlertsPopover
    const handleAllAlertsPopoverClick = (event) => {
        setPinnedPopoverAnchorEl(null);
        if (allAlerts && allAlerts.length > 0) {
            setAllAlertsPopoverAnchorEl(event.currentTarget);
        }
    };

    // Handle opening the PinnedPopover
    const handlePinnedPopoverClick = (event) => {
        // setUnAckedAlertsPopoverAnchorEl(null);
        setAllAlertsPopoverAnchorEl(null);
        if (allPinnedAlerts && allPinnedAlerts.length > 0) {
            setPinnedPopoverAnchorEl(event.currentTarget);
        }
    };

    // Handle closing the UnAckedAlertsPopover
    const handleUnAckedAlertsPopoverClose = () => {
        setUnAckedAlertsPopoverAnchorEl(null);
    };

    // Handle closing the AllAlertsPopover
    const handleAllAlertsPopoverClose = () => {
        setAllAlertsPopoverAnchorEl(null);
    };

    // Handle closing the PinnedPopover
    const handlePinnedPopoverClose = () => {
        setPinnedPopoverAnchorEl(null);
    };

    // useEffect( setUnAckedAlertsPopoverAnchorEl(null);
    useEffect(() => {
        if (allUnAckedAlerts && allUnAckedAlerts.length === 0) {
            setUnAckedAlertsPopoverAnchorEl(null);
        }
    }, [allUnAckedAlerts]);

    // useEffect( setAllAlertsPopoverAnchorEl(null);
    useEffect(() => {
        if (allAlerts && allAlerts.length === 0) {
            setAllAlertsPopoverAnchorEl(null);
        }
    }, [allAlerts]);

    // useEffect( setPinnedPopoverAnchorEl(null);
    useEffect(() => {
        if (allPinnedAlerts && allPinnedAlerts.length === 0) {
            setPinnedPopoverAnchorEl(null);
        }
    }, [allPinnedAlerts]);

    const handleRouterMenuClick = (event) => {
        setAnchorMenuEl(event.currentTarget);
    };

    const handleRouterMenuClose = () => {
        setAnchorMenuEl(null);
    };

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    function returnSignOut() {
        // DataStore.clear();
        return (
            props.signOut
        )
    }

    // TODO - TRY TO MOVE THIS TO MODALS DIR
    function SignOutModal() {
        const [open, setOpen] = React.useState(false);
        const handleModalOpen = () => {
            setOpen(true);
        };
        const handleModalClose = () => {
            setOpen(false);
        };

        return (
            <React.Fragment>
                {/*<Button*/}
                {/*    variant="contained"*/}
                {/*    // color="secondary"*/}
                {/*    sx={{*/}
                {/*        backgroundColor: '#5F9EA0',*/}
                {/*        color: 'white',*/}
                {/*        // marginLeft: '8px',  // Slight left margin*/}
                {/*        marginLeft: {*/}
                {/*            xs: '0.25rem',  // Assuming base font-size is 16px, this is 4px*/}
                {/*            sm: '0.5rem',   // 8px*/}
                {/*            md: '1rem',     // 16px*/}
                {/*            lg: '1.5rem',   // 24px*/}
                {/*            xl: '2rem'      // 32px*/}
                {/*        },*/}
                {/*        textTransform: 'none',  // Reset the default text transformation*/}
                {/*        '&:hover': {*/}
                {/*            backgroundColor: '#507D7E',  // A darker shade for hover*/}
                {/*        }*/}
                {/*    }}*/}
                {/*    onClick={handleModalOpen}>Sign Out*/}
                {/*</Button>*/}
                <StyledHeaderButton
                    onClick={handleModalOpen}
                    noWrap={true}
                >
                    Sign Out
                </StyledHeaderButton>
                <Modal
                    hideBackdrop
                    open={open}
                    onClose={handleModalClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Box sx={{ ...modalStyle, width: 300 }}>
                        <h2 id="child-modal-title">Sign Out</h2>
                        <p id="child-modal-description">
                            Click below to sign out, see you soon!
                        </p>
                        <Button
                            onClick={props.signOut}
                            // variant="contained"
                            // color="secondary"
                        >Sign Out</Button>
                        <Button onClick={handleModalClose}>Cancel</Button>
                    </Box>
                </Modal>
            </React.Fragment>
        );
    }

    // TODO - TRY TO MOVE THIS TO MODALS DIR

    // function NetworkConnModal() {
    //     const [open, setOpen] = useState(false);
    //     // const handleOpen = () => setOpen(displayNoti);
    //
    //     useEffect(() => {
    //         setOpen(displayNoti);
    //     }, [displayNoti]);
    //
    //     const handleClose = () => {
    //         setOpen(false);
    //         setDisplayNoti(false);
    //     }
    //
    //     return (
    //         <div>
    //             {/*<Button onClick={handleOpen}>Open modal</Button>*/}
    //             <Modal
    //                 open={open}
    //                 onClose={handleClose}
    //                 aria-labelledby="modal-modal-title"
    //                 aria-describedby="modal-modal-description"
    //             >
    //                 <Box sx={modalStyle}>
    //                     <Typography id="modal-modal-title" variant="h6" component="h2">
    //                         Network Connection Changed
    //                     </Typography>
    //                     <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    //                         {/*{`network is connected: ${networkConnected}`}*/}
    //                         {networkModalText}
    //                     </Typography>
    //                 </Box>
    //             </Modal>
    //         </div>
    //     );
    // }

    // MUI component doc: https://mui.com/material-ui/react-app-bar/#app-bar-with-a-primary-search-field

    // THESE 2 MENUS ARE JUST USED IN THE RETURN FUNCTION
    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            // FULL SCREEN MENU ON THE RIGHT
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <StyledMenuItem
                onClick={() => navigate('user-info', {
                    state: {
                        userSub: props.userSub,
                        ampUser: ampUser,
                        subPayment: props.subPayment
                    }
                })}
            >
                My Account
            </StyledMenuItem>
            {/*{userAuthenticated ? <MenuItem onClick={returnSignOut()}>Sign Out</MenuItem> : null}*/}
            {userAuthenticated ? <StyledMenuItem onClick={returnSignOut()}>Sign Out</StyledMenuItem> : null}
        </Menu>
    );

    // TODO - START HERE, GET ENTIRE USER PROFILE MENU TO DISPLAY, NOT JUST THE SIGN OUT BUTTON
    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            // SMALL SCREEN MENU ON THE RIGHT
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <StyledMenuItem
                // onClick={handleUnAckedAlertsPopoverClick}
                onClick={handleAllAlertsPopoverClick}
            >
                <StyledHeaderAlertsButton
                    badgeAlertsCount={allUnAckedAlerts?.length || 0}
                    // badgeAlertsCount={allAlerts?.length || 0}
                    showLogs={showLogs}
                    // aria-label="show new alert notifications"
                    aria-label="show all alert notifications"
                    notificoncolor={'grey'}
                    notificonmarginleft={{
                        xs: '0rem',  // Assuming base font-size is 16px, this is 4px
                        sm: '0rem',   // 8px
                        md: '0rem',     // 16px
                        lg: '0rem',   // 24px
                        xl: '0rem'      // 32px
                    }}
                    notificonmarginright={{
                        xs: '0.25rem',  // Assuming base font-size is 16px, this is 4px
                        sm: '0.5rem',   // 8px
                        md: '1rem',     // 16px
                        lg: '1.5rem',   // 24px
                        xl: '2rem'      // 32px
                    }}
                    notificonrootfontsize={{
                        xs: '2.5rem', // Small screens
                        sm: '3rem',    // Medium screens
                        md: '3.25rem',  // Large screens
                        lg: '3.5rem'     // Extra-large screens
                    }}
                    badgefontsize={{
                        xs: '1rem',  // Font size for smallest screens
                        sm: '1.25rem', // Slightly larger for small screens
                        md: '1.5rem',    // Default for medium screens
                        lg: '2rem', // Larger for large screens
                        xl: '2.25rem'   // Largest for extra-large screens
                    }}
                    badgeminwidth={{
                        xs: '24px',   // Ensures the badge has a minimum width for smaller screens
                        sm: '28px',
                        md: '32px',
                        lg: '36px',
                        xl: '40px'
                    }}
                    badgeheight={{
                        xs: '24px',   // Ensures the badge has a minimum width for smaller screens
                        sm: '28px',
                        md: '32px',
                        lg: '36px',
                        xl: '40px'
                    }}
                    // onClick={handleUnAckedAlertsPopoverClick}
                    onClick={handleAllAlertsPopoverClick}
                />
                <p>Notifications</p>
            </StyledMenuItem>
            <StyledMenuItem
                onClick={handlePinnedPopoverClick}
            >
                <StyledHeaderPinnedButton
                    badgePinnedCount={allPinnedAlerts?.length || 0}
                    showLogs={showLogs}
                    aria-label="show pinned alerts"
                    pinnediconcolor={'grey'}
                    pinnediconmarginleft={{
                        xs: '0rem',  // Assuming base font-size is 16px, this is 4px
                        sm: '0rem',   // 8px
                        md: '0rem',     // 16px
                        lg: '0rem',   // 24px
                        xl: '0rem'      // 32px
                    }}
                    pinnediconmarginright={{
                        xs: '0.25rem',  // Assuming base font-size is 16px, this is 4px
                        sm: '0.5rem',   // 8px
                        md: '1rem',     // 16px
                        lg: '1.5rem',   // 24px
                        xl: '2rem'      // 32px
                    }}
                    pinnediconrootfontsize={{
                        xs: '2.5rem', // Small screens
                        sm: '3rem',    // Medium screens
                        md: '3.25rem',  // Large screens
                        lg: '3.5rem'     // Extra-large screens
                    }}
                    pinnedbadgefontsize={{
                        xs: '1rem',  // Font size for smallest screens
                        sm: '1.25rem', // Slightly larger for small screens
                        md: '1.5rem',    // Default for medium screens
                        lg: '2rem', // Larger for large screens
                        xl: '2.25rem'   // Largest for extra-large screens
                    }}
                    pinnedbadgeminwidth={{
                        xs: '24px',   // Ensures the badge has a minimum width for smaller screens
                        sm: '28px',
                        md: '32px',
                        lg: '36px',
                        xl: '40px'
                    }}
                    pinnedbadgeheight={{
                        xs: '24px',   // Ensures the badge has a minimum width for smaller screens
                        sm: '28px',
                        md: '32px',
                        lg: '36px',
                        xl: '40px'
                    }}
                    onClick={handlePinnedPopoverClick}
                />
                <p>Pinned</p>
            </StyledMenuItem>
            <StyledMenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                    sx={{
                        '& .MuiSvgIcon-root': {
                            // fontSize: {
                            //     xs: '1.5rem', // Small screens
                            //     sm: '2rem',    // Medium screens
                            //     md: '2.5rem',  // Large screens
                            //     lg: '3rem'     // Extra-large screens
                            // }
                            fontSize: {
                                xs: '2.5rem', // Small screens
                                sm: '3rem',    // Medium screens
                                md: '3.25rem',  // Large screens
                                lg: '3.5rem'     // Extra-large screens
                            }
                        },
                        '&:hover': {
                            backgroundColor: '#507D7E',  // A darker shade for hover
                        },
                        marginRight: {
                            xs: '0.25rem',  // Assuming base font-size is 16px, this is 4px
                            sm: '0.5rem',   // 8px
                            md: '1rem',     // 16px
                            lg: '1.5rem',   // 24px
                            xl: '2rem'      // 32px
                        }
                    }}
                >
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </StyledMenuItem>
            {/*OTHER OPTION WAS NOT ADDED, RENDERS AS EXPECTED*/}
        </Menu>
    );

    // TODO - START HERE, MAKE PINNED ALERTS LIST, GET WORKING
    return (
        <Box
            sx={{
                flexGrow: 1
            }}
        >
            <AppBar
                position="sticky"
                sx={{
                    backgroundColor: '#486B6C',
                    color: 'white',
                    minHeight: {
                        xs: '4rem',    // 64px equivalent
                        sm: '5rem',  // 72px equivalent
                        md: '6rem',    // 80px equivalent
                        lg: '7rem',  // 88px equivalent
                        xl: '7.5rem'     // 96px equivalent
                    },
                    // Add these properties to center content vertically
                    display: 'flex',
                    justifyContent: 'center',
                    // alignItems: 'center',
                    alignItems: 'space-between',
                }}
            >
                <Toolbar
                    sx={{
                        // width: '100%',
                        minHeight: {
                            xs: '4rem',
                            sm: '5rem',
                            md: '6rem',
                            lg: '7rem',
                            xl: '7.5rem'
                        },
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center', // Vertical centering
                        // padding: {
                        //     xs: '0 1rem',
                        //     sm: '0 1.5rem',
                        //     md: '0 2rem'
                        // }
                    }}
                >
                    {/*BASE APP BAR, TOGGLES ON RIGHT HAND MENU DOTS*/}
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{
                            mr: 2,
                            '& .MuiSvgIcon-root': {
                                fontSize: {
                                    xs: '1.5rem', // Small screens
                                    sm: '2rem',    // Medium screens
                                    md: '2.5rem',  // Large screens
                                    lg: '3rem'     // Extra-large screens
                                }
                            }
                        }}
                        id="router-menu-button"
                        aria-controls={open ? 'router-menu-button' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleRouterMenuClick}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="router-menu"
                        anchorEl={anchorMenuEl}
                        open={open}
                        onClose={handleRouterMenuClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        {anchorMenuEl ? <AppBarRouterMenu /> : null}
                    </Menu>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            fontSize: {
                                xs: '1.5rem', // Similar to small screens
                                sm: '2rem',   // Adjust for tablets and small desktops
                                md: '2rem', // Standard desktops
                                lg: '2.5rem',   // Larger desktops
                                xl: '3rem'  // Ultra-wide or larger screens
                            }
                    }}
                    >
                        {userDisplayName ? `Hello ${userDisplayName}` : "FNG"}
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} >
                        <StyledHeaderButton
                            onClick={() => navigate('/')}
                            noWrap={true}
                        >
                            Sole Frog
                        </StyledHeaderButton>
                    </Box>
                    {/*PRETTY SURE BELOW BOX IS ONE OF THE BADGED ICONS*/}
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        {ampUser ? (
                            <StyledHeaderPinnedButton
                                badgePinnedCount={allPinnedAlerts?.length || 0}
                                showLogs={showLogs}
                                aria-label="show pinned notifications"
                                pinnediconhovercolor={'transparent'}
                                onClick={handlePinnedPopoverClick}
                            />
                        ) : null}
                        {ampUser ? (
                            <StyledHeaderAlertsButton
                                badgeAlertsCount={allUnAckedAlerts?.length || 0}
                                // badgeAlertsCount={allAlerts?.length || 0}
                                showLogs={showLogs}
                                // aria-label="show new alert notifications"
                                aria-label="show all alert notifications"
                                notificonhovercolor={'transparent'}
                                // onClick={handleUnAckedAlertsPopoverClick}
                                onClick={handleAllAlertsPopoverClick}
                            />
                        ) : null}
                        {blogUrl ? <StyledHeaderButton
                            onClick={() => window.open(blogUrl, '_blank')}
                        >Blog
                        </StyledHeaderButton> : null}
                        {deployState !== 'signup' ? userAuthenticated ? <SignOutModal /> : <StyledHeaderButton
                            onClick={() => navigate('/login')}>Sign In
                        </StyledHeaderButton> : null}
                        {ampUser ? <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                            fontSize="inherit"
                            sx={{
                                '& .MuiSvgIcon-root': {
                                    fontSize: {
                                        xs: '1.5rem', // Small screens
                                        sm: '2rem',    // Medium screens
                                        md: '2.5rem',  // Large screens
                                        lg: '3rem'     // Extra-large screens
                                    }
                                }
                            }}
                        >
                            <AccountCircle />
                        </IconButton> : null}
                    </Box>
                    {/*PRETTY SURE BELOW BOX IS ONE OF THE BADGED ICONS*/}
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        {blogUrl ? <StyledHeaderButton
                            onClick={() => window.open(blogUrl, '_blank')}
                        >Blog
                        </StyledHeaderButton> : null}
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                            sx={{
                                '& .MuiSvgIcon-root': {
                                    fontSize: {
                                        xs: '1.5rem', // Small screens
                                        sm: '2rem',    // Medium screens
                                        md: '2.5rem',  // Large screens
                                        lg: '3rem'     // Extra-large screens
                                    }
                                }
                            }}
                        >
                            <MoreIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {/*TODO - FIGURE OUT HOW THIS IS TOGGLING THE RIGHT HAND MENU*/}
            {renderMobileMenu}
            {renderMenu}

            <Popover
                id={unAckedAlertsPopoverId}
                open={isUnAckedAlertsPopoverOpen}
                anchorEl={unAckedAlertsPopoverAnchorEl}
                onClose={handleUnAckedAlertsPopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <FarrierAllUnAckedAlertsList
                    farrierUnAckedAlertsAll={allUnAckedAlerts}
                    ampUserRec={ampUserRec}
                    showLogs={showLogs}
                    handleListCloseProp={handleUnAckedAlertsPopoverClose}
                    updateAckedAlertHashesProp={updateAckedAlertHashes}
                />
            </Popover>

            <Popover
                id={allAlertsPopoverId}
                open={isAllAlertsPopoverOpen}
                anchorEl={allAlertsPopoverAnchorEl}
                onClose={handleAllAlertsPopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <FarrierAllAlertsNotifsList
                    farrierAlertsAll={allAlerts}
                    ampUserRec={ampUserRec}
                    showLogs={showLogs}
                    handleListCloseProp={handleAllAlertsPopoverClose}
                    updateAckedAlertHashesProp={updateAckedAlertHashes}
                />
            </Popover>

            <Popover
                id={pinnedPopoverId}
                open={isPinnedPopoverOpen}
                anchorEl={pinnedPopoverAnchorEl}
                onClose={handlePinnedPopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <FarrierAllPinnedAlertsList
                    farrierPinnedAlertsAll={allPinnedAlerts}
                    ampUserRec={ampUserRec}
                    showLogs={showLogs}
                    handleListCloseProp={handlePinnedPopoverClose}
                />
            </Popover>
        </Box>
    );
}
