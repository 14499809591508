import {WaitlistUser, WaitlistUserStatus} from "../../../models";
import { DataStore } from "aws-amplify";
import { v4 as uuidv4 } from 'uuid';
import { getNowAsAWSDateTime } from "../../../utils/datesTimes";
import CryptoJS from 'crypto-js';

function createUniqueToken(email) {
    const timestamp = Date.now().toString();
    const randomNumber = Math.floor(Math.random() * (999999 - 100000) + 100000).toString(); // Generating a random 6-digit number
    const dataToHash = email + timestamp + randomNumber;

    const hash = CryptoJS.SHA256(dataToHash).toString(CryptoJS.enc.Hex);
    return hash;
}

// TODO - !!! START HERE, FR, FR, ADD RELATIONSHIP TO PUBLIC CLIENT

export async function createWaitlistUserRec(waitlistUserFormValues, showLogs) {
    const resObj = {};
    if (showLogs) {
        console.log('createWaitlistUserRec waitlistUserFormValues', waitlistUserFormValues);
    }
    if (!waitlistUserFormValues?.email) {
        resObj.success = false;
        resObj.error = 'Email is required';
        return resObj;
    }
    const waitlistUserStartedAt = getNowAsAWSDateTime();
    const newUniqueToken = createUniqueToken(waitlistUserFormValues.email);
    try {
        const newWaitlistUserRec = await DataStore.save(new WaitlistUser(
            {
                email: waitlistUserFormValues.email,
                waitlistUserStatus: WaitlistUserStatus.SUBSCRIBED,
                uniqueToken: newUniqueToken,
            }
        ));
        if (showLogs) {
            console.log('createWaitlistUser newWaitlistUserRec', newWaitlistUserRec);
        }
        resObj.success = true;
        resObj.newWaitlistUserRec = newWaitlistUserRec;
        return resObj;
    }
    catch (error) {
        console.error('createWaitlistUserRec error', error);
        resObj.error = error;
        resObj.success = false;
        return resObj;
    }
}

