import React from 'react';
import {RequireAuth} from '../../auth/RequireAuth';
import DevStateProvider from "../../../data/providers/DevStateProvider";
import CreateClientScreen from "../../../screens/people/CreateClientScreen";
import {RequireSub} from "../../auth/RequireSub";

export default function WrappedCreateClient({ routerPaths, ampUser, showLogs }) {
    return (
        <DevStateProvider>
                <RequireAuth>
                    {ampUser ? (
                        <RequireSub ampUser={ampUser}>
                            <CreateClientScreen
                                routerPaths={routerPaths}
                                ampUser={ampUser}
                                showLogs={showLogs}
                            />
                        </RequireSub>
                    ) : (
                        <div><h2>Loading Data...</h2></div>
                    )}
                </RequireAuth>
        </DevStateProvider>
    );
}
