import React, { useState, useEffect } from 'react';
import StyledListCard from "../cards/StyledListCard";
import StyledCardContentTypography from "../text/StyledCardContentTypography";
import BaseStyledButton from "../buttons/BaseStyledButton";
import CardContent from "@mui/material/CardContent";
import { CardActions } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFarrierAlertsIDBProvider } from "../../data/providers/alerts/FarrierAlertsIDBProvider";
import { farrierAlertsDB } from "../../data/providers/idb/alerts/FarrierAlertsDB";
import { SharedPublicClientUpdateStatus } from "../../models";
import { ampUserUpdatePublicClientRec } from "../../data/update/client/updateClient";
import { useConsoleLog } from "../hooks/logging/console";
import FarrierAlertViewButton from "../buttons/FarrierAlertViewButton";


// export default function FarrierAlert(props) {
export default function FarrierUnAckedAlert({ alertIDBRecProp, ampUserRecProp, showLogsProp, updateAckedAlertHashesProp, ...props }) {
    const [ampUserRec, setAmpUserRec] = useState(null);
    const [alertIDBRec, setAlertIDBRec] = useState(null);
    const [alertStoreName, setAlertStoreName] = useState(null);
    const [updateRecDiffObj, setUpdateRecDiffObj] = useState(null);
    const [alertUpdateModelRecId, setAlertUpdateModelRecId] = useState(null);
    const [ackOnClickHandler, setAckOnClickHandler] = useState(null);
    const [pinOnClickHandler, setPinOnClickHandler] = useState(null);
    const [showLogs, setShowLogs] = useState(false);

    // TODO - start here, add unpin actions
    const { acknowledgeAlert, pinAlert, setIsUpdatingUnAckedAlerts, unPinAlert } = useFarrierAlertsIDBProvider();

    const navigate = useNavigate();

    useConsoleLog(showLogs, [{label: 'FarrierAlert alertIDBRec', value: alertIDBRec}]);
    // useConsoleLog(showLogs, [{label: 'FarrierAlert alertStoreName', value: alertStoreName}]);
    // useConsoleLog(showLogs, [{label: 'FarrierAlert updateRecDiffObj', value: updateRecDiffObj}]);
    // useConsoleLog(showLogs, [{label: 'FarrierAlert alertUpdateModelRecId', value: alertUpdateModelRecId}]);
    // useConsoleLog(showLogs, [{label: 'FarrierAlert ampUserRec', value: ampUserRec}]);
    // useConsoleLog(showLogs, [{label: 'FarrierAlert ackOnClickHandler', value: ackOnClickHandler}]);

    // useEffect( set showLogs from showLogsProp
    useEffect(() => {
        if (showLogsProp) {
            setShowLogs(showLogsProp);
        } else {
            setShowLogs(false);
        }
    }, [showLogsProp]);

    // useEffect( set alertIDBRec from alertIDBRecProp
    useEffect(() => {
        if (alertIDBRecProp) {
            setAlertIDBRec(alertIDBRecProp);
        }
        // }, [props.alertIDBRec]);
    }, [alertIDBRecProp]);

    // useEffect( set ampUserRec from ampUserRecProp
    useEffect(() => {
        if (ampUserRecProp) {
            setAmpUserRec(ampUserRecProp);
        }
    }, [ampUserRecProp]);

    // useEffect( setStateValsByAlertType(alertIDBRec.alertType);
    useEffect(() => {
        if (alertIDBRec?.alertType) {
            // setAlertStoreName(getStoreNameByAlertType(props.alertType));
            setStateValsByAlertType(alertIDBRec.alertType);
        }
    }, [alertIDBRec]);

    async function handleAckSharedPublicClientUpdate() {
        if (ampUserRec) {
            setIsUpdatingUnAckedAlerts(true);
            const recDiffObj = {sharedPublicClientUpdateStatus: SharedPublicClientUpdateStatus.CLIENT_UPDATE_ACKNOWLEDGED};
            const pubClientId = alertIDBRec.publicClientId;
            const storeName = farrierAlertsDB.stores.farrierPublicClientAlertsStoreName;
            if (showLogs) {
                console.log('handleAckSharedPublicClientUpdate alertIDBRec', alertIDBRec);
                console.log('handleAckSharedPublicClientUpdate storeName', storeName);
                console.log('handleAckSharedPublicClientUpdate recDiffObj', recDiffObj);
                console.log('handleAckSharedPublicClientUpdate pubClientId', pubClientId);
                console.log('handleAckSharedPublicClientUpdate ampUserRec', ampUserRec);
            }
            // await ampUserUpdatePublicClientRec(alertUpdateModelRecId, updateRecDiffObj, ampUserRec, showLogs);
            await ampUserUpdatePublicClientRec(pubClientId, recDiffObj, ampUserRec, showLogs);
            // await acknowledgeAlert(props.alertId, alertStoreName);
            // await acknowledgeAlert(alertIDBRec.alertId, alertStoreName);

            await acknowledgeAlert(alertIDBRec.alertId, storeName);
            // props.handleListCloseProp(alertIDBRec.ackHash);
            // props.updateAckedAlertHashesProp(alertIDBRec.ackHash);
            updateAckedAlertHashesProp(alertIDBRec.ackHash);
            setIsUpdatingUnAckedAlerts(false);
        }
    }

    async function handlePinSharedPublicClientUpdate() {
        if (ampUserRec) {
            setIsUpdatingUnAckedAlerts(true);
            const recDiffObj = {sharedPublicClientUpdateStatus: SharedPublicClientUpdateStatus.CLIENT_UPDATE_ACKNOWLEDGED};
            const pubClientId = alertIDBRec.publicClientId;
            const storeName = farrierAlertsDB.stores.farrierPublicClientAlertsStoreName;
            if (showLogs) {
                console.log('handleAckSharedPublicClientUpdate alertIDBRec', alertIDBRec);
                console.log('handleAckSharedPublicClientUpdate storeName', storeName);
                console.log('handleAckSharedPublicClientUpdate recDiffObj', recDiffObj);
                console.log('handleAckSharedPublicClientUpdate pubClientId', pubClientId);
                console.log('handleAckSharedPublicClientUpdate ampUserRec', ampUserRec);
            }
            // await ampUserUpdatePublicClientRec(alertUpdateModelRecId, updateRecDiffObj, ampUserRec, showLogs);
            await ampUserUpdatePublicClientRec(pubClientId, recDiffObj, ampUserRec, showLogs);
            // await acknowledgeAlert(props.alertId, alertStoreName);
            // await acknowledgeAlert(alertIDBRec.alertId, alertStoreName);
            await pinAlert(alertIDBRec.alertId, storeName);
            await acknowledgeAlert(alertIDBRec.alertId, storeName);
            // props.handleListCloseProp(alertIDBRec.ackHash);
            // props.updateAckedAlertHashesProp(alertIDBRec.ackHash);
            updateAckedAlertHashesProp(alertIDBRec.ackHash);
            setIsUpdatingUnAckedAlerts(false);
        }
    }

    // function getStoreNameByAlertType(alertType) {
    //     switch (alertType) {
    //         case "clientUpdatedSharedPublicClient":
    //             setUpdateRecDiffObj({sharedPublicClientUpdateStatus: SharedPublicClientUpdateStatus.CLIENT_UPDATE_ACKNOWLEDGED});
    //             setAlertUpdateModelRecId(alertIDBRec.publicClientId);
    //             setAlertStoreName(farrierAlertsDB.stores.farrierPublicClientAlertsStoreName);
    //             setAckOnClickHandler(() => handleAckSharedPublicClientUpdate);
    //             return farrierAlertsDB.stores.farrierPublicClientAlertsStoreName;
    //         default:
    //             // return farrierAlertsDB.stores.farrierClientAlertsStoreName;
    //             return;
    //     }
    // }

    function setStateValsByAlertType(alertType) {
        switch (alertType) {
            case "clientUpdatedSharedPublicClient":
                setUpdateRecDiffObj({sharedPublicClientUpdateStatus: SharedPublicClientUpdateStatus.CLIENT_UPDATE_ACKNOWLEDGED});
                setAlertUpdateModelRecId(alertIDBRec.publicClientId);
                setAlertStoreName(farrierAlertsDB.stores.farrierPublicClientAlertsStoreName);
                setAckOnClickHandler(() => handleAckSharedPublicClientUpdate);
                // return farrierAlertsDB.stores.farrierPublicClientAlertsStoreName;
                break;
            default:
                // return farrierAlertsDB.stores.farrierClientAlertsStoreName;
                return;
        }
    }

    // useEffect(() => {
    //     if (props?.alertType) {
    //         const farrierAlertsDBStoreName = getStoreNameByAlertType(props.alertType);
    //         // setAlertStoreName(getStoreNameByAlertType(props.alertType));
    //         if (farrierAlertsDBStoreName) {
    //             setAlertStoreName(farrierAlertsDBStoreName);
    //         }
    //     }
    // }, [props.alertType]);

    // useEffect(() => {
    //     if (alertIDBRec?.alertType) {
    //         const farrierAlertsDBStoreName = getStoreNameByAlertType(alertIDBRec.alertType);
    //         // setAlertStoreName(getStoreNameByAlertType(props.alertType));
    //         if (farrierAlertsDBStoreName) {
    //             setAlertStoreName(farrierAlertsDBStoreName);
    //         }
    //     }
    // }, [alertIDBRec]);


    return (
        <div>
            <StyledListCard>
                <CardContent>
                    <StyledCardContentTypography
                        text={`FarrierAlert id: ${alertIDBRec?.alertId || 'none'}`}
                    />
                </CardContent>
                <CardActions>
                    <FarrierAlertViewButton
                        label={'View'}
                        alertIDBRecProp={alertIDBRec}
                        alertIDBStoreNameProp={alertStoreName}
                        showLogsProp={showLogs}
                    />
                    <BaseStyledButton
                        label={'Acknowledge'}
                        onClick={async () => {
                            if (ackOnClickHandler) {
                                await ackOnClickHandler();
                            } else {
                                alert('Acknowledge button clicked, no ack handler set for this alert');
                            }
                        }}
                    />
                    <BaseStyledButton
                        label={'Pin'}
                        onClick={async () => {
                            if (alertIDBRec) {
                                await handlePinSharedPublicClientUpdate();
                            }
                            else {
                                alert('Pin button clicked, no pin handler set for this alert');
                            }
                        }}
                    />
                </CardActions>
            </StyledListCard>
        </div>
    );
}

