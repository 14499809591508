import CryptoJS from "crypto-js";
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';
import {getNowAsAWSDateTime} from "./datesTimes";


export function alertAckHashFactory(ampUserId, modelRecId, alertType) {
    // return `${ampUserId}-${alertId}`;
    const paramsString = `${ampUserId}-${modelRecId}-${alertType}`;
    const hash = CryptoJS.SHA256(paramsString).toString(CryptoJS.enc.Hex);
    return hash;
}

export function generateUniqueKey(baseString) {
    // Generate a random number or string
    const randomValue = Math.random().toString(36).substring(2, 15);

    // Concatenate baseString with the random value
    const uniqueKey = `${baseString}-${randomValue}`;

    return uniqueKey;
}

// Utility for deep cloning objects
export function deepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
}

export async function generateHash(inputString) {
    const encoder = new TextEncoder();
    const randomValue = Math.random().toString(36).substring(2, 15);
    const data = encoder.encode(`${inputString}-${randomValue}`);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // Convert buffer to byte array
    // const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    // return hashHex.slice(0, 32); // Truncate to 32 characters
}

export function createRecTempId() {
    const nowStringISO = getNowAsAWSDateTime();
    const uniqueKey = generateUniqueKey(nowStringISO);
    const newRecNamespace = uuidv4();
    const newIdName = `${uniqueKey}-${nowStringISO}`;
    const newTempRecId = uuidv5(newIdName, newRecNamespace);
    return newTempRecId;
}

export function createIdbAlertId() {
    return createRecTempId();
}

export function filterModelByChecked(elem) {
    if (elem.checked) {
        return true;
    }
}

export function subPaymentModelIsExpired(model) {
    const nowTs = Date.now();
    const modelCreatedAtDate = model.processorEventCreated;
    return modelCreatedAtDate > nowTs;
}

export function modelIdNameFactory(models) {
    const idNamesObj = {}
    models.forEach((eachRec) => {
        idNamesObj[eachRec.id] = eachRec.name
    });
    return idNamesObj
}

/**
 *
 * @param {{}} obj1
 * @param {{}} obj2
 */
export function objectDeepEquals(obj1, obj2) {
    if (JSON.stringify(obj1) !== JSON.stringify(obj2)) {
        return false;
    }
    if (JSON.stringify(obj1.keys()) !== JSON.stringify(obj2.keys())) {
        return false;
    }
    if (JSON.stringify(obj1.values()) !== JSON.stringify(obj2.values())) {
        return false;
    }
    Object.entries(obj1).forEach((eachEntryArr) => {
        const eachEntryKey = eachEntryArr[0];
        const eachEntryVal = eachEntryArr[1];
        const obj2Val = obj2[eachEntryKey];
        if (JSON.stringify(eachEntryVal) !== JSON.stringify(obj2Val)) {
            return false;
        }
    });
    return true;
}

export function disableSelectIfItemChecked(selectName, objsArray) {
    let disableSelect = false;
    objsArray.forEach((eachObj) => {
        if (eachObj.name === selectName && eachObj.checked) {
            disableSelect = true;
        }
    });
    return disableSelect;
}

export function createUniqueToken(email) {
    const timestamp = Date.now().toString();
    const randomNumber = Math.floor(Math.random() * (999999 - 100000) + 100000).toString(); // Generating a random 6-digit number
    const dataToHash = email + timestamp + randomNumber;

    const hash = CryptoJS.SHA256(dataToHash).toString(CryptoJS.enc.Hex);
    return hash;
}

// TODO - !!! - TOGGLE BACK IN WHEN USED
// export async function updateAmpUserLastPaymentHash(ampUser, lastPaymentHash) {
//     console.warn('updateAmpUserLastPaymentHash(ampUser, userStatus)', ampUser, lastPaymentHash)
//     await DataStore.save(
//         AmpUser.copyOf(ampUser, updated => {
//             updated.lastPaymentHash = lastPaymentHash;
//         })
//     );
//     // window.location.reload();
// }
