import React, {useState, useEffect} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';
import { Button, CardActionArea, CardActions } from '@mui/material';
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import {userSubDataObj} from "../../utils/formObjects";
import {useConsoleError, useConsoleLog, useConsoleWarn} from "../hooks/logging/console";

import StyledListItemText from "../text/StyledListItemText";
import ListItemText from '@mui/material/ListItemText';


export default function SubscriptionPreviewCard(props) {
    const [ampUser, setAmpUser] = useState({});
    const [subLength, setSubLength] = useState('');
    const [subLengthData, setSubLengthData] = useState({});
    const [showLogs, setShowLogs] = useState(false);
    const [userSubData, setUserSubData] = useState(userSubDataObj);


    let navigate = useNavigate();

    // useEffect( setShowLogs(props.showLogs);
    useEffect(() => {
        if (props?.showLogs) {
            setShowLogs(props.showLogs);
        }
        else {
            setShowLogs(false);
        }
    }, [props.showLogs]);

    // useEffect( setAmpUser(props.ampUser[0]);
    useEffect(() => {
        if (props?.ampUser) {
            setAmpUser(props.ampUser);
        }
    },[props.ampUser]);

    // useEffect( setSubLength(props.subLength);
    useEffect(() => {
        if (props?.subLength) {
            setSubLength(props.subLength);
            setSubLengthData(userSubData[props.subLength]);
        }
    },[props.subLength]);

    // useEffect( console.log('subLength', subLength);
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('SubscriptionPreviewCard(props) subLength', subLength);
    //         console.log('SubscriptionPreviewCard(props) subLengthData', subLengthData);
    //     }
    // }, [subLength, showLogs]);

    useConsoleLog(showLogs, [{label: 'SubscriptionPreviewCard subLength', value: subLength}]);

    useConsoleLog(showLogs, [{label: 'SubscriptionPreviewCard subLengthData', value: subLengthData}]);

    return (
        <Box
            sx={{
                '& > :not(style)': { m: 1 }
            }}
        >
            <Card
                sx={{
                    maxWidth: 600
                }}
            >
                <CardActionArea>
                    <CardContent>
                        <List>
                            <ListSubheader
                                inset={false}
                                sx={{
                                    fontSize: ['6vw', '4.5vw', '4vw', '3vw'],
                                    color: 'black'
                                }}
                            >
                                Your Info:
                            </ListSubheader>
                            <Typography
                                sx={{
                                    fontSize: ['5vw', '4vw', '3vw', '2.5vw'],
                                    // padding: '0 0.5vw'
                                }}
                            >
                                {ampUser?.email || 'missing email'}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: ['4vw', '3vw', '2.5vw', '2vw'],
                                    // padding: '0 0.5vw'
                                }}
                            >
                                (You can add your phone number later)
                            </Typography>
                            <Divider />
                        </List>
                        <List>
                            <ListSubheader
                                inset={false}
                                sx={{
                                    fontSize: ['4.5vw', '3vw', '2vw', '2.5vw'],
                                    color: 'black'
                                }}
                            >
                                Selected Subscription Length:
                            </ListSubheader>
                            {subLength ? <Typography
                                    sx={{
                                        fontSize: ['5vw', '3.5vw', '2.5vw', '2.5vw'],
                                        padding: '0 0.5vw'
                                    }}
                                >
                                    {`${subLengthData.subText} - $${subLengthData.subPrice}`}
                                </Typography>
                                : null}
                        </List>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Box>
    );
}
