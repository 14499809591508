
export const defaultName = 'name'
export const defaultPhone = '+12061235555'

export const devState = {
    dev: {
        stateNow: "dev",
        stripeSuccessURL: 'http://localhost:3000/subscription-success',
        stripeCancelURL: 'http://localhost:3000/subscription-cancel',
        staticSiteUrl: 'http://localhost:3000',
    },
    prod: {
        stateNow: "prod",
        stripeSuccessURL: 'https://www.solefrog.com/subscription-success',
        // stripeSuccessURL: 'https://www.solefrog.com/',
        stripeCancelURL: 'https://www.solefrog.com/subscription-cancel',
        // stripeCancelURL: 'https://www.solefrog.com/',
        staticSiteUrl: 'https://www.solefrog.com',
    },
};

export const devStateNow = devState['dev'];
export const deployState = 'app'; // app || signup

// export const subTypeEnum = {
//     3000: SubscriptionType.QUARTER,
//     10000: SubscriptionType.YEAR
// }

// export function sumTaskAmount(clientDefaultTaskAmount, addedTaskAmount) {
//     return clientDefaultTaskAmount + addedTaskAmount;
// }

// TODO - ADD CREATE, UPDATE FOR OWNER, HORSE, CLIENT, PICS, VIDEO, NOTES AND RELATED MODELS,
//  ADD PROVIDERS
