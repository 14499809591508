import React, { useState, useEffect } from 'react';
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import StyledTextField from "../../text/StyledTextField";
import BaseStyledButton from "../../buttons/BaseStyledButton";
import { AppointmentStatus } from "../../../models";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { filterAnimalRecsByAnimalNames } from "../../../data/filter/filterRecs";
import { textFieldValToInteger } from "../../../utils/text";
import { convertToFullInteger,
    sumTaskAmount,
    sumTaskDuration } from "../../../utils/numbers";
import { generateUniqueKey } from "../../../utils/helpers";
import { getNowAsAWSDateTime } from "../../../utils/datesTimes";
import { shoeTaskFormObj,
    trimTaskFormObj,
    editApptFormObj,
    apptNoteFormObj } from "../../../utils/formObjects";
import DeleteApptModal from "../../modals/delete/DeleteApptModal";
import DeleteApptNoteConfirmationDialog from "../../modals/delete/DeleteApptNoteModal";
import { deleteAppointmentNoteById,
    deleteShoeTaskById,
    deleteTrimTaskById,
    deleteAppointmentAnimalRecById
} from "../../../data/delete/deleteRecords";
import {
    updateAppointment,
    updateAppointmentTrimTask,
    updateAppointmentShoeTask,
    updateApptTasks
} from "../../../data/update/appt/updateAppt";
import StyledNoteTextField from "../../text/StyledNoteTextField";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Typography from "@mui/material/Typography";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import { convertAWSDateTimeToLocalDateTime } from "../../../utils/datesTimes";
import UpdateTrimTaskModal from "../../modals/update/UpdateTrimTaskModal";
import UpdateShoeTaskModal from "../../modals/update/UpdateShoeTaskModal";
import {
    createAppointmentTrimTask,
    createAppointmentShoeTask,
    createAppointmentAnimal
} from "../../../data/create/appt/createAppt";
import {
    addUpdatedTrimTaskFormToTaskFormsDiffObj,
    addUpdatedShoeTaskFormToTaskFormsDiffObj,
    addCreateShoeTaskFormToTaskFormsDiffObj,
    addCreateTrimTaskFormToTaskFormsDiffObj,
    addDeleteTrimTaskUniqueIdToTaskFormsDiffObj,
    addDeleteShoeTaskUniqueIdToTaskFormsDiffObj,
    handleUpdateNewTrimTaskForm,
    handleUpdateNewShoeTaskForm } from "../helpers/tasks";
import { handleTrimTaskOpen,
    handleTrimTaskClose,
    handleTrimSaveAndUpdateNext,
    handleTrimSaveAndUpdatePrev,
    handleShoeTaskOpen,
    handleShoeTaskClose,
    handleShoeSaveAndUpdateNext,
    handleShoeSaveAndUpdatePrev } from "../../modals/helpers/editAppt";
import UpdatingIndicator from "../../progress/UpdatingIndicator";
import { handleDeleteApptModalClose,
    handleDeleteApptCancel,
    handleDeleteApptConfirm } from "../../modals/helpers";

// import StyledCardContentTypography from "../text/StyledCardContentTypography";
// import UpdateApptConfirmationDialog from "../modals/UpdateApptModal";
// import CreateShoeTaskFormModal from "../modals/CreateShoeTaskFormModal";
// import CreateTrimTaskFormModal from "../modals/CreateTrimTaskFormModal";
// import ConfirmNavigateDialog from "../modals/ConfirmNavigateModal";
// import {fetchApptAnimalByIds} from "../../data/get/fetchRecords";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


export default function EditApptFrom(props) {
    const [showLogs, setShowLogs] = useState(false);
    const [routerPaths, setRouterPaths] = useState({});
    const [ampUser, setAmpUser] = useState(null);
    const [apptStatusEnums, setApptStatusEnums] = useState([]);
    const [personName, setPersonName] = useState([]);

    const [origSelectedApptStatus, setOrigSelectedApptStatus] = useState('');
    const [editedSelectedApptStatus, setEditedSelectedApptStatus] = useState('');

    // TODO - FILL THIS IN BASED ON ORIG FORM VALS
    const [origApptDateTime, setOrigApptDateTime] = useState(null);
    const [editedApptDateTime, setEditedApptDateTime] = useState(null);

    const [clientAnimalRecs, setClientAnimalRecs] = useState([]);
    // const [animalRecsByClientId, setAnimalRecsByClientId] = useState(null);
    const [apptRec, setApptRec] = useState(null);
    const [apptClientRec, setApptClientRec] = useState(null);
    // these are animal recs for the appt, not the related model recs
    // const [origApptAnimals, setOrigApptAnimals] = useState([]);
    // const [editedApptAnimals, setEditedApptAnimals] = useState([]);

    // these are the AppointmentAnimal related recs
    const [origApptAnimalRecs, setOrigApptAnimalRecs] = useState([]);

    const [origSelectedShoeAnimalRecs, setOrigSelectedShoeAnimalRecs] = useState([]);
    const [editedSelectedShoeAnimalRecs, setEditedSelectedShoeAnimalRecs] = useState([]);
    const [origSelectedTrimAnimalRecs, setOrigSelectedTrimAnimalRecs] = useState([]);
    const [editedSelectedTrimAnimalRecs, setEditedSelectedTrimAnimalRecs] = useState([]);

    // TODO - PREVENT DUPE NAMES IN BOTH TASK TYPES
    const [origSelectedShoeAnimalNames, setOrigSelectedShoeAnimalNames] = useState([]);
    const [editedSelectedShoeAnimalNames, setEditedSelectedShoeAnimalNames] = useState([]);
    const [selectedShoeAnimalNamesChanged, setSelectedShoeAnimalNamesChanged] = useState(false);
    const [origSelectedTrimAnimalNames, setOrigSelectedTrimAnimalNames] = useState([]);
    const [editedSelectedTrimAnimalNames, setEditedSelectedTrimAnimalNames] = useState([]);
    const [selectedTrimAnimalNamesChanged, setSelectedTrimAnimalNamesChanged] = useState(false);

    const [shoeTaskModalOpen, setShoeTaskModalOpen] = useState(false);
    const [trimTaskModalOpen, setTrimTaskModalOpen] = useState(false);
    const [currentShoeTaskAnimalIndex, setCurrentShoeTaskAnimalIndex] = useState(0);
    const [currentTrimTaskAnimalIndex, setCurrentTrimTaskAnimalIndex] = useState(0);

    const [origShoeTaskFormVals, setOrigShoeTaskFormVals] = useState([]);
    const [editedShoeTaskFormVals, setEditedShoeTaskFormVals] = useState([]);
    const [origTrimTaskFormVals, setOrigTrimTaskFormVals] = useState([]);
    const [editedTrimTaskFormVals, setEditedTrimTaskFormVals] = useState([]);

    const [origShoeAnimalTaskRecs, setOrigShoeAnimalTaskRecs] = useState([]);
    // TODO - UPDATE TO MATCH TRIM TASKS, THEN REMOVE THIS
    const [editedShoeAnimalTaskRecs, setEditedShoeAnimalTaskRecs] = useState([]);
    const [origTrimAnimalTaskRecs, setOrigTrimAnimalTaskRecs] = useState([]);
    const [editedTrimAnimalTaskRecs, setEditedTrimAnimalTaskRecs] = useState([]);
    const [origTasksByAnimalId, setOrigTasksByAnimalId] = useState({});
    // TODO - ON TASK CHANGE, UPDATE THIS OBJ
    const [editedTasksByAnimalId, setEditedTasksByAnimalId] = useState({});

    // should be all tasks, with a taskName for each element
    const [origTasksForAnimals, setOrigTasksForAnimals] = useState([]);
    const [editedTasksForAnimals, setEditedTasksForAnimals] = useState([]);
    // TODO - ON APPT UPDATE, UPDATE THESE EDITED TASKS - ! BUT BASED ON TASK TYPE !
    const [updatedApptTasks, setUpdatedApptTasks] = useState([]);
    // TODO - ON UPDATE USE THESE TWO TO UPDATE TASKS IF NEEDED
    // const [updatedExistingTasks, setUpdatedExistingTasks] = useState({shoe: [], trim: []});
    const [newTaskForms, setNewTaskForms] = useState({shoe: [], trim: []});
    const [apptTasksValsChanged, setApptTasksValsChanged] = useState(false);
    // const [evalTaskFormValsChanged, setEvalTaskFormValsChanged] = useState(false);

    const [origTotalApptAmount, setOrigTotalApptAmount] = useState(0);
    const [editedTotalApptAmount, setEditedTotalApptAmount] = useState(0);
    const [origTotalApptDuration, setOrigTotalApptDuration] = useState(0);
    const [editedTotalApptDuration, setEditedTotalApptDuration] = useState(0);

    const [updateApptAmount, setUpdateApptAmount] = useState(false);
    // const [updatedApptAmount, setUpdatedApptAmount] = useState(0);
    const [apptAmountChanged, setApptAmountChanged] = useState(false);
    const [updateApptDuration, setUpdateApptDuration] = useState(false);
    // const [updatedApptDuration, setUpdatedApptDuration] = useState(0);
    const [apptDurationChanged, setApptDurationChanged] = useState(false);

    // these are the returned model recs, may not belong here as submit is done in the preview component
    // TODO - DO THESE NEED TO THEN UPDATE THE CORRESPONDING ORIG VALUES IN CASE OF FURTHER UPDATES?
    const [updatedApptRec, setUpdatedApptRec] = useState(null);
    // const [updatedApptAnimalRecs, setUpdatedApptAnimalRecs] = useState([]);

    const [editedApptTimeVals, setEditedApptTimeVals] = useState({});

    const [showDeleteApptModal, setShowDeleteApptModal] = useState(false);
    // const [showDeleteShoeTaskModal, setShowDeleteShoeTaskModal] = useState(false);
    // const [showDeleteTrimTaskModal, setShowDeleteTrimTaskModal] = useState(false);
    const [showDeleteApptNoteModal, setShowDeleteApptNoteModal] = useState(false);
    const [showDeleteApptAnimalModal, setShowDeleteApptAnimalModal] = useState(false);
    // TODO - TRY TO REPLACE THIS WITH MULTI MODEL UPDATE
    const [showUpdateApptModal, setShowUpdateApptModal] = useState(false);
    const [showUpdateApptNoteModal, setShowUpdateApptNoteModal] = useState(false);
    // TODO - UPDATE THIS BASED ON MULTI REC TYPE UPDATES
    const [showUpdateNoteTasksApptModal, setShowUpdateNoteTasksApptModal] = useState(false);
    // TODO - SHOW NAVIGATE MODAL AFTER UPDATE SUCCESS
    const [showNavigateModal, setShowNavigateModal] = useState(false);

    const [modelRecToDelete, setModelRecToDelete] = useState(null);
    // const [shoeTaskToDelete, setShoeTaskToDelete] = useState(null);
    // const [trimTaskToDelete, setTrimTaskToDelete] = useState(null);
    // const [taskToDeleteAnimalAllTasks, setTaskToDeleteAnimalAllTasks] = useState([]);
    const [apptNoteToDelete, setApptNoteToDelete] = useState(null);
    // const [apptAnimalRecToDelete, setApptAnimalRecToDelete] = useState(null);
    // const [animalToDelete, setAnimalToDelete] = useState(null);

    // const [apptShoeTasks, setApptShoeTasks] = useState([]);
    const [origApptShoeTasks, setOrigApptShoeTasks] = useState([]);
    const [editedApptShoeTasks, setEditedApptShoeTasks] = useState([]);
    // const [apptTrimTasks, setApptTrimTasks] = useState([]);
    const [origApptTrimTasks, setOrigApptTrimTasks] = useState([]);
    const [editedApptTrimTasks, setEditedApptTrimTasks] = useState([]);

    // const [shoeTasksByAnimal, setShoeTasksByAnimal] = useState({});
    // const [trimTasksByAnimal, setTrimTasksByAnimal] = useState({});
    const [animalByShoeTask, setAnimalByShoeTask] = useState({});
    const [animalByTrimTask, setAnimalByTrimTask] = useState({});

    const [editedApptFormValues, setEditedApptFormValues] = useState(editApptFormObj);
    const [origApptFormValues, setOrigApptFormValues] = useState(editApptFormObj);
    // each task type has: create, update, delete arrs, use those as checks for task updates on form submit
    const [apptFormValsDiff, setApptFormValsDiff] = useState({});
    const [taskFormsDiffObj, setTaskFormsDiffObj] = useState({shoe: {}, trim: {}});
    const [createdTrimTaskFormUniqueId, setCreatedTrimTaskFormUniqueId] = useState(null);
    const [createdShoeTaskFormUniqueId, setCreatedShoeTaskFormUniqueId] = useState(null);
    const [deletedTrimTaskFormUniqueId, setDeletedTrimTaskFormUniqueId] = useState(null);
    const [deletedShoeTaskFormUniqueId, setDeletedShoeTaskFormUniqueId] = useState(null);
    const [updatedTrimTaskFormUniqueId, setUpdatedTrimTaskFormUniqueId] = useState(null);
    const [updatedNewTrimTaskFormUniqueId, setUpdatedNewTrimTaskFormUniqueId] = useState(null);
    const [updatedShoeTaskFormUniqueId, setUpdatedShoeTaskFormUniqueId] = useState(null);
    const [updatedNewShoeTaskFormUniqueId, setUpdatedNewShoeTaskFormUniqueId] = useState(null);
    const [apptFormValsChanged, setApptFormValsChanged] = useState(false);
    const [apptFormDiffKeys, setApptFormDiffKeys] = useState([]);

    // const [editedApptNotes, setEditedApptNotes] = useState([]);
    const [editedApptNoteFormValues, setEditedApptNoteFormValues] = useState(apptNoteFormObj);
    // const [origApptNotes, setOrigApptNotes] = useState([]);
    // const [origApptNoteRec, setOrigApptNoteRec] = useState(null);
    // const [updatedApptNoteRec, setUpdatedApptNoteRec] = useState(null);
    const [origApptNoteFormValues, setOrigApptNoteFormValues] = useState(apptNoteFormObj);
    const [apptNoteFormValChanged, setApptNoteFormValChanged] = useState(false);

    // TODO - REPLACE WITH DEDICATED ERROR MSGS
    const [updateErrorMsg, setUpdateErrorMsg] = useState(null);
    const [updateApptErrorMsg, setUpdateApptErrorMsg] = useState(null);
    const [updateNoteErrorMsg, setUpdateNoteErrorMsg] = useState(null);
    const [updateTasksErrorMsg, setUpdateTasksErrorMsg] = useState(null);

    // TODO - REPLACE WITH DEDICATED SUCCESS MSGS
    const [updateSuccessMsg, setUpdateSuccessMsg] = useState(null);
    const [updateApptSuccessMsg, setUpdateApptSuccessMsg] = useState(null);
    const [updateNoteSuccessMsg, setUpdateNoteSuccessMsg] = useState(null);
    const [updateTasksSuccessMsg, setUpdateTasksSuccessMsg] = useState(null);

    const [updateRecApptSuccess, setUpdateRecApptSuccess] = useState(false);
    const [updateRecSuccess, setUpdateRecSuccess] = useState(false);
    const [updateNoteRecSuccess, setUpdateNoteRecSuccess] = useState(false);
    const [updateTaskRecsSuccess, setUpdateTaskRecsSuccess] = useState(false);
    const [deleteApptRes, setDeleteApptRes] = useState({});

    const [updateTaskRecsDone, setUpdateTaskRecsDone] = useState(true);

    const [updateMultiRecsSuccess, setUpdateMultiRecsSuccess] = useState(false);

    // TODO - ADD THIS WHERE NEEDED TO KEEP STATE UPDATES PAUSED WHILE FORM SUBMITS
    const [pauseForFormSubmit, setPauseForFormSubmit] = useState(false);
    const [resetStateVals, setResetStateVals] = useState(false);
    const [showUpdatingIndicator, setShowUpdatingIndicator] = useState(false);

    let navigate = useNavigate();
    const theme = useTheme();

    // TODO - !!! CLEAN THIS UP,
    //  TRY TO USE SHARED COMPONENTS WHERE POSSIBLE,
    //  CREATE NEW SHARED COMPONENTS IF NEEDED

    function handleSubmitStart() {
        setShowUpdatingIndicator(true);
        setPauseForFormSubmit(true);
        setUpdateTaskRecsDone(false);

        // if (props.setPauseDataForFormSubmit) {
        //     props.setPauseDataForFormSubmit(true);
        // }

        // setPauseForFormSubmit(true);
        // setUpdateTaskRecsDone(false);
    }

    // useEffect(() => {
    //     if (pauseForFormSubmit && !updateTaskRecsDone) {
    //         if (props.setPauseDataForFormSubmit) {
    //             props.setPauseDataForFormSubmit(true);
    //         }
    //     }
    // }, [pauseForFormSubmit, updateTaskRecsDone]);

    // useEffect(() => {
    //     if (pauseForFormSubmit) {
    //         if (props.setPauseDataForFormSubmit) {
    //             props.setPauseDataForFormSubmit(true);
    //         }
    //     }
    // }, [pauseForFormSubmit, updateTaskRecsDone]);

    function handleSubmitEnd() {
        // TODO - LATER UPDATE, ONLY RESET CHANGED FORM ATTRS,
        //  HOPE IS TO AVOID WEIRD RELOAD STATE EDGE CASES

        // TODO - SOON LATER UPDATE,
        //  DISPLAY ANY ERRORS, SUCCESS, CONFIRMATION MSGS,
        //  THEN RESET STATE VALS ONLY AFTER ACK

        // TODO - MAY WANT TO USE pauseForFormSubmit IN PARENT COMPONENT INSTEAD

        // do any state val resets here
        // resetFormValues();

        setPauseForFormSubmit(false);
        // unpause updates, populate state vals again
        // if (props.setPauseDataForFormSubmit) {
        //     props.setPauseDataForFormSubmit(false);
        // }

        // setPauseForFormSubmit(false);

        // console.warn('FORM SUBMIT DONE!');

        // setUpdateTaskRecsDone(true);
    }

    // useEffect(() => {
    //     // if (!pauseForFormSubmit) {
    //     if (!pauseForFormSubmit && !resetStateVals) {
    //         if (props.pauseDataForFormSubmit) {
    //             // if (props.setPauseDataForFormSubmit) {
    //                 props.setPauseDataForFormSubmit(false);
    //             // }
    //         }
    //     }
    //     else {
    //         console.warn('@@@ DID NOT props.setPauseDataForFormSubmit(false);', props.pauseDataForFormSubmit);
    //     }
    // }, [pauseForFormSubmit, updateTaskRecsDone, props.pauseDataForFormSubmit, resetStateVals]);

    useEffect(() => {
        if (resetStateVals) {
            resetFormValues();
        }
    }, [resetStateVals]);

    // useEffect( console.warn( resetStateVals, pauseForFormSubmit, props.pauseDataForFormSubmit, updateTaskRecsDone
    // useEffect(() => {
    //     if (showLogs) {
    //         console.warn('@@@ resetStateVals', resetStateVals);
    //         console.warn('@@@ pauseForFormSubmit', pauseForFormSubmit);
    //         console.warn('@@@ props.pauseDataForFormSubmit', props.pauseDataForFormSubmit);
    //         console.warn('@@@ updateTaskRecsDone', updateTaskRecsDone);
    //     }
    // }, [showLogs, resetStateVals, pauseForFormSubmit, props.pauseDataForFormSubmit, updateTaskRecsDone]);

    // useEffect( reset on clientAnimalRecs change
    function resetFormValues() {
        // TODO - CHECK FOR ANY OTHER STATE VALS TO RESET
        // TODO - MAKE SURE TO USE pauseForFormSubmit TO REPOPULATE THESE
        console.warn('!!! RESETTING FORM VALUES');
        setShowLogs(false);
        // setEditedApptFormValues(origApptFormValues);
        // setEditedApptNoteFormValues(origApptNoteFormValues);

        // setEditedTasksForAnimals(origTasksForAnimals);

        setOrigApptFormValues(editApptFormObj);
        setEditedApptFormValues(editApptFormObj);

        // TODO - UPDATE TO ALSO USE DEFAULT EMPTY FORM OBJ
        setEditedApptNoteFormValues(origApptNoteFormValues);

        setOrigTasksForAnimals([]);
        setEditedTasksForAnimals([]);

        setEditedTasksForAnimals([]);

        setOrigTrimTaskFormVals([]);
        setEditedTrimTaskFormVals([]);

        setApptFormValsDiff({});
        setTaskFormsDiffObj({shoe: {}, trim: {}});
        setApptNoteFormValChanged(false);
        setApptTasksValsChanged(false);
        setUpdateApptDuration(false);
        setUpdateApptAmount(false);
        setEditedSelectedShoeAnimalNames([]);
        setEditedSelectedTrimAnimalNames([]);
        setSelectedShoeAnimalNamesChanged(false);
        setSelectedTrimAnimalNamesChanged(false);
        setUpdatedTrimTaskFormUniqueId(null);
        setCreatedTrimTaskFormUniqueId(null);
        setDeletedTrimTaskFormUniqueId(null);

        // TODO - ADD ARRAY OF MODAL OPEN, CLOSE VALS, SET ALL TO MODALS CLOSED

        setResetStateVals(false);
        if (pauseForFormSubmit) {
            setPauseForFormSubmit(false);
        }
        // props.setPauseDataForFormSubmit(false);
    }

    // useEffect( log props console.log('props', props);
    useEffect(() => {
        if (showLogs) {
            console.log('props', props);
        }
    }, [showLogs, props]);

    // useEffect setAmpUser(props.ampUser)
    useEffect(() => {
        if (props.ampUser) {
            setAmpUser(props.ampUser);
        }
    }, [props.ampUser]);

    // useEffect( console.log(ampUser
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('ampUser', ampUser);
    //     }
    // }, [showLogs, ampUser]);

    // useEffect( setRouterPaths(props.routerPaths);
    useEffect(() => {
        if (props?.routerPaths) {
            setRouterPaths(props.routerPaths);
        }
    }, [props.routerPaths]);

    // useEffect( setShowLogs( props.showLogs
    useEffect(() => {
        if (props?.showLogs){
            setShowLogs(props.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [props.showLogs]);

    // useEffect( setAnimalStatusEnums(AnimalStatus);
    useEffect(() => {
        if (AppointmentStatus) {
            setApptStatusEnums(AppointmentStatus);
        }
    }, [AppointmentStatus]);

    // useEffect( setApptRec(props.apptRec); setOrigTotalApptAmount( setOrigTotalApptDuration
    useEffect(() => {
        // TODO - VERIFY THIS ACTUALLY HELPS
        if (!pauseForFormSubmit) {
            if (props?.apptRec) {
                setApptRec(props.apptRec);
                // if (!pauseForFormSubmit) {
                setOrigTotalApptAmount(props.apptRec.amount);
                setOrigTotalApptDuration(props.apptRec.apptDuration);
                // }
            }
        }
    }, [props.apptRec, pauseForFormSubmit]);

    // useEffect( setApptClientRec(props.apptClientRec);
    useEffect(() => {
        // TODO - ?DONT THINK NEED TO PAUSE THIS, VERIFY THAT
        if (!pauseForFormSubmit) {
            if (props?.apptClientRec) {
                setApptClientRec(props.apptClientRec);
            }
        }
    }, [props.apptClientRec, pauseForFormSubmit]);

    // useEffect( setClientAnimalRecs(props.apptClientAnimals);
    useEffect(() => {
        // TODO - ?DONT THINK NEED TO PAUSE THIS, VERIFY THAT
        if (!pauseForFormSubmit) {
            if (props?.apptClientAnimals) {
                setClientAnimalRecs(props.apptClientAnimals);
            }
        }
    }, [props.apptClientAnimals, pauseForFormSubmit]);

    // useEffect( setOrigApptFormValues(apptObj); setEditedApptFormValues(apptObj);
    useEffect(() => {
        if (!pauseForFormSubmit) {
            if (apptRec) {
                const apptObj = {};
                Object.keys(apptRec).forEach(key => {
                    // console.log('key', key);
                    // console.log('apptRec[key]', apptRec[key]);
                    if (key !== 'id') {
                        apptObj[key] = apptRec[key];
                    }
                    if (key === 'appointmentStatus') {
                        setOrigSelectedApptStatus(apptRec[key]);
                        setEditedSelectedApptStatus(apptRec[key]);
                    }
                    if (key === 'dateTime' && apptRec[key] !== null) {
                        const apptDateTime = convertAWSDateTimeToLocalDateTime(apptRec[key]);  // Parse the modified string as local time
                        // console.log('$$$ apptDateTime', apptDateTime);
                        setOrigApptDateTime(apptDateTime);
                        setEditedApptDateTime(apptDateTime);
                    }
                });
                setOrigApptFormValues(apptObj);
                setEditedApptFormValues(apptObj);
                setApptFormValsChanged(false);
                setApptFormValsDiff({});
            }
        }
    }, [apptRec, pauseForFormSubmit]);

    // TODO - NOTES NOW IMMUTABLE,
    //  NO NEED TO UPDATE, CREATE INSTEAD NOW, USE FORM OBJS TO SET INITIAL STATE

    // useEffect( setOrigApptNotes(, setEditedApptNotes(props.apptNotes);, setOrigApptNoteFormValues(, setEditedApptNoteFormValues(
    useEffect(() => {
        if (!pauseForFormSubmit) {
            if (props?.apptNotes) {
                // setOrigApptNotes(props.apptNotes);
                // setEditedApptNotes(props.apptNotes);
                // if (props.apptNotes.length > 0) {
                //     // setOrigApptNoteFormValues({...origApptNoteFormValues, note: props.apptNotes[0].note});
                //     setOrigApptNoteFormValues({note: props.apptNotes[0].note});
                //     setOrigApptNoteRec(props.apptNotes[0]);
                //     // setEditedApptNoteFormValues({...editedApptNoteFormValues, note: props.apptNotes[0].note});
                //     setEditedApptNoteFormValues({note: props.apptNotes[0].note});
                // }
            }
            setApptNoteFormValChanged(false);
        }
    }, [props.apptNotes, pauseForFormSubmit]);

    // useEffect( setOrigApptAnimalRecs(props.apptAnimalRecs);
    useEffect(() => {
        if (!pauseForFormSubmit) {
            if (props.apptAnimalRecs) {
                setOrigApptAnimalRecs(props.apptAnimalRecs);
            }
        }
    }, [pauseForFormSubmit, props.apptAnimalRecs]);

    // useEffect( console.warn('!!! origApptAnimalRecs', origApptAnimalRecs);
    useEffect(() => {
        if (showLogs) {
            console.warn('!!! origApptAnimalRecs', origApptAnimalRecs);
        }
    }, [showLogs, origApptAnimalRecs]);

    // useEffect( set shoeTasks, trimTasks, tasksByAnimalId based on props
    useEffect(() => {
        if (!pauseForFormSubmit && updateTaskRecsDone) {
            if (props?.apptShoeTasks) {
                // console.log('!!! UPDATING props.apptShoeTasks', props.apptShoeTasks);
                setOrigApptShoeTasks(props.apptShoeTasks);
                setEditedApptShoeTasks(props.apptShoeTasks);
            }
            if (props?.apptTrimTasks) {
                // console.log('!!! UPDATING props.apptTrimTasks', props.apptTrimTasks);
                setOrigApptTrimTasks(props.apptTrimTasks);
                setEditedApptTrimTasks(props.apptTrimTasks);
            }
            if (props?.tasksByAnimalId) {
                // console.log('!!! UPDATING props.tasksByAnimalId', props.tasksByAnimalId);
                setOrigTasksByAnimalId(props.tasksByAnimalId);
                setEditedTasksByAnimalId(props.tasksByAnimalId);
            }
        }
    }, [props.tasksByAnimalId, pauseForFormSubmit, updateTaskRecsDone]);

    // useEffect( setAnimalByShoeTask(props.animalByShoeTask); setAnimalByTrimTask(props.animalByTrimTask);
    useEffect(() => {
        if (!pauseForFormSubmit && updateTaskRecsDone) {
            if (props?.animalByShoeTask) {
                setAnimalByShoeTask(props.animalByShoeTask);
                const shoeAnimals = [];
                if (props.animalByShoeTask) {
                    Object.entries(props.animalByShoeTask).forEach(([key, value]) => {
                        // console.log('<!!!> props?.animalByShoeTask key', key);
                        // console.log('<!!!> props?.animalByShoeTask value', value);
                        shoeAnimals.push(value);
                    });
                }
                setOrigShoeAnimalTaskRecs(shoeAnimals);
                setEditedShoeAnimalTaskRecs(shoeAnimals);
            }
            if (props?.animalByTrimTask) {
                setAnimalByTrimTask(props.animalByTrimTask);
                const trimAnimals = [];
                if (props.animalByTrimTask) {
                    Object.entries(props.animalByTrimTask).forEach(([key, value]) => {
                        // console.log('<!!!> props?.animalByTrimTask key', key);
                        // console.log('<!!!> props?.animalByTrimTask value', value);
                        trimAnimals.push(value);
                    });
                }
                setOrigTrimAnimalTaskRecs(trimAnimals);
                setEditedTrimAnimalTaskRecs(trimAnimals);
            }
        }
    }, [props.animalByShoeTask, props.animalByTrimTask, pauseForFormSubmit, updateTaskRecsDone]);

    // useEffect( console.log( origTasksByAnimalId, editedTasksByAnimalId
    // useEffect( console.log( animalByShoeTask, animalByTrimTask, origTasksByAnimalId, editedTasksByAnimalId
    // useEffect(() => {
    //     if (showLogs) {
    //         // console.log('animalByShoeTask', animalByShoeTask);
    //         // console.log('animalByTrimTask', animalByTrimTask);
    //         console.log('origTasksByAnimalId', origTasksByAnimalId);
    //         console.log('$$$ editedTasksByAnimalId', editedTasksByAnimalId);
    //     }
    //     // }, [showLogs, animalByShoeTask, animalByTrimTask, origTasksByAnimalId, editedTasksByAnimalId]);
    // }, [showLogs, origTasksByAnimalId, editedTasksByAnimalId]);

    // useEffect( set task selected animal names based on orig tasks
    useEffect(() => {
        if (!pauseForFormSubmit && updateTaskRecsDone) {
            if (origTasksByAnimalId) {
                const shoeTaskAnimalRecs = origTasksByAnimalId.shoe;
                if (shoeTaskAnimalRecs && animalByShoeTask) {
                    const shoeTaskNames = [];
                    Object.entries(shoeTaskAnimalRecs).forEach(([animalId, taskArray]) => {
                        taskArray.forEach((taskObj) => {
                            const shoeTaskAnimal = animalByShoeTask[taskObj.id];
                            if (shoeTaskAnimal) {
                                shoeTaskNames.push(shoeTaskAnimal.name);
                            }
                        });
                    });
                    setOrigSelectedShoeAnimalNames(shoeTaskNames);
                    setEditedSelectedShoeAnimalNames(shoeTaskNames);
                }

                const trimTaskAnimalRecs = origTasksByAnimalId.trim;
                if (trimTaskAnimalRecs && animalByTrimTask) {
                    const trimTaskNames = [];
                    Object.entries(trimTaskAnimalRecs).forEach(([animalId, taskArray]) => {
                        taskArray.forEach((taskObj) => {
                            const trimTaskAnimal = animalByTrimTask[taskObj.id];
                            if (trimTaskAnimal) {
                                trimTaskNames.push(trimTaskAnimal.name);
                            }
                        });
                    });
                    setOrigSelectedTrimAnimalNames(trimTaskNames);
                    setEditedSelectedTrimAnimalNames(trimTaskNames);
                }
            }
        }
    }, [origTasksByAnimalId, animalByShoeTask, animalByTrimTask, pauseForFormSubmit, updateTaskRecsDone]);

    // useEffect( console.log( editedSelectedTrimAnimalRecs, editedSelectedShoeAnimalRecs
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('$$$ editedSelectedShoeAnimalRecs', editedSelectedShoeAnimalRecs);
    //         console.log('$$$ editedSelectedTrimAnimalRecs', editedSelectedTrimAnimalRecs);
    //     }
    // }, [showLogs, editedSelectedTrimAnimalRecs, editedSelectedShoeAnimalRecs]);

    // useEffect( addUpdatedFormToTaskFormsDiffObj('trim', updatedTrimTaskFormUniqueId);
    useEffect(() => {
        // if not null, then check for updated fields in the form
        if (updatedTrimTaskFormUniqueId) {
            addUpdatedTrimTaskFormToTaskFormsDiffObj(updatedTrimTaskFormUniqueId, taskFormsDiffObj, setTaskFormsDiffObj, editedTrimTaskFormVals, origTrimTaskFormVals, setUpdatedTrimTaskFormUniqueId, setUpdatedNewTrimTaskFormUniqueId);
        }
    }, [updatedTrimTaskFormUniqueId]);

    // useEffect( addUpdatedShoeTaskFormToTaskFormsDiffObj(
    useEffect(() => {
        // if not null, then check for updated fields in the form
        if (updatedShoeTaskFormUniqueId) {
            addUpdatedShoeTaskFormToTaskFormsDiffObj(updatedShoeTaskFormUniqueId, taskFormsDiffObj, setTaskFormsDiffObj, editedShoeTaskFormVals, origShoeTaskFormVals, setUpdatedShoeTaskFormUniqueId, setUpdatedNewShoeTaskFormUniqueId);
        }
    }, [updatedShoeTaskFormUniqueId]);

    // useEffect( addCreateTrimTaskFormToTaskFormsDiffObj(
    useEffect(() => {
        // if not null, then add the id to created trim tasks
        if (!pauseForFormSubmit) {
            if (createdTrimTaskFormUniqueId) {
                addCreateTrimTaskFormToTaskFormsDiffObj(createdTrimTaskFormUniqueId, taskFormsDiffObj, setTaskFormsDiffObj, editedTrimTaskFormVals, origTrimTaskFormVals, setCreatedTrimTaskFormUniqueId, showLogs);
            }
        }
    }, [createdTrimTaskFormUniqueId, pauseForFormSubmit]);

    // useEffect( addCreateShoeTaskFormToTaskFormsDiffObj(
    useEffect(() => {
        // if not null, then add the id to created trim tasks
        if (!pauseForFormSubmit) {
            if (createdShoeTaskFormUniqueId) {
                addCreateShoeTaskFormToTaskFormsDiffObj(createdShoeTaskFormUniqueId, taskFormsDiffObj, setTaskFormsDiffObj, editedShoeTaskFormVals, origShoeTaskFormVals, setCreatedShoeTaskFormUniqueId, showLogs);
            }
        }
    }, [createdShoeTaskFormUniqueId, pauseForFormSubmit]);

    // useEffect( handleUpdateNewTrimTaskForm(
    useEffect(() => {
        if (!pauseForFormSubmit) {
            if (updatedNewTrimTaskFormUniqueId) {
                handleUpdateNewTrimTaskForm(updatedNewTrimTaskFormUniqueId, createdTrimTaskFormUniqueId, taskFormsDiffObj, setTaskFormsDiffObj, editedTrimTaskFormVals, origTrimTaskFormVals, setCreatedTrimTaskFormUniqueId, setUpdatedNewTrimTaskFormUniqueId, showLogs);
                setUpdatedNewTrimTaskFormUniqueId(null);
            }
        }
    }, [updatedNewTrimTaskFormUniqueId, editedTrimTaskFormVals, pauseForFormSubmit]);

    // useEffect( handleUpdateNewShoeTaskForm(
    useEffect(() => {
        if (!pauseForFormSubmit) {
            if (updatedNewShoeTaskFormUniqueId) {
                handleUpdateNewShoeTaskForm(updatedNewShoeTaskFormUniqueId, createdShoeTaskFormUniqueId, taskFormsDiffObj, setTaskFormsDiffObj, editedShoeTaskFormVals, origShoeTaskFormVals, setCreatedShoeTaskFormUniqueId, setUpdatedNewShoeTaskFormUniqueId, showLogs);
                setUpdatedNewShoeTaskFormUniqueId(null);
            }
        }
    }, [updatedNewShoeTaskFormUniqueId, editedShoeTaskFormVals, pauseForFormSubmit]);

    // useEffect( check for trim tasks to delete and track form unique ids of orig trim tasks to delete
    useEffect(() => {
        if (!pauseForFormSubmit && updateTaskRecsDone) {
            // find the set of unique form ids of forms in the orig forms but not in edited forms
            const origTrimTaskFormIds = origTrimTaskFormVals.map(taskForm => taskForm.formUniqueId);
            const editedTrimTaskFormIds = editedTrimTaskFormVals.map(taskForm => taskForm.formUniqueId);
            const origTrimTaskFormIdsSet = new Set(origTrimTaskFormIds);
            const editedTrimTaskFormIdsSet = new Set(editedTrimTaskFormIds);
            const diffTrimTaskFormIds = new Set([...origTrimTaskFormIdsSet].filter(x => !editedTrimTaskFormIdsSet.has(x)));
            // console.log('<###> diffTrimTaskFormIds', diffTrimTaskFormIds);
            if (diffTrimTaskFormIds.size > 0) {
                const diffTrimTaskFormIdsArr = [...diffTrimTaskFormIds];
                for (const diffTaskFormId of diffTrimTaskFormIds) {
                    addDeleteTrimTaskUniqueIdToTaskFormsDiffObj(diffTaskFormId, taskFormsDiffObj, setTaskFormsDiffObj, editedTrimTaskFormVals, origTrimTaskFormVals, setDeletedTrimTaskFormUniqueId, showLogs);
                }
            }
        }
    }, [origTrimTaskFormVals, editedTrimTaskFormVals, pauseForFormSubmit, updateTaskRecsDone]);

    // useEffect( check for shoe tasks to delete and track form unique ids of orig shoe tasks to delete
    useEffect(() => {
        if (!pauseForFormSubmit && updateTaskRecsDone) {
            // find the set of unique form ids of forms in the orig forms but not in edited forms
            const origShoeTaskFormIds = origShoeTaskFormVals.map(taskForm => taskForm.formUniqueId);
            const editedShoeTaskFormIds = editedShoeTaskFormVals.map(taskForm => taskForm.formUniqueId);
            const origShoeTaskFormIdsSet = new Set(origShoeTaskFormIds);
            const editedShoeTaskFormIdsSet = new Set(editedShoeTaskFormIds);
            const diffShoeTaskFormIds = new Set([...origShoeTaskFormIdsSet].filter(x => !editedShoeTaskFormIdsSet.has(x)));
            // console.log('<###> diffShoeTaskFormIds', diffShoeTaskFormIds);
            if (diffShoeTaskFormIds.size > 0) {
                const diffShoeTaskFormIdsArr = [...diffShoeTaskFormIds];
                for (const diffTaskFormId of diffShoeTaskFormIds) {
                    addDeleteShoeTaskUniqueIdToTaskFormsDiffObj(diffTaskFormId, taskFormsDiffObj, setTaskFormsDiffObj, editedShoeTaskFormVals, origShoeTaskFormVals, setDeletedShoeTaskFormUniqueId, showLogs);
                }
            }
        }
    }, [origShoeTaskFormVals, editedShoeTaskFormVals, pauseForFormSubmit, updateTaskRecsDone]);

    // const handleUpdateApptModalClose = () => {
    //     setShowUpdateApptModal(false);
    //     setUpdateErrorMsg(null);
    //     setUpdateSuccessMsg(null);
    //     if (updateRecApptSuccess) {
    //         setUpdateRecApptSuccess(false);
    //         setApptFormValsChanged(false);
    //         setApptFormValsDiff({});
    //         // TODO - DO WE NEED TO SET THE ORIGNAL FORM VALUES TO THE UPDATED FORM VALUES? OR ADD USEEFFECT TO UPDATE WHEN UPDATEAPPTREC CHANGES
    //     }
    // }

    function handleUpdateApptSuccess() {
        setUpdateRecApptSuccess(false);
        setApptFormValsChanged(false);
        setApptFormValsDiff({});
        // TODO - DO WE NEED TO SET THE ORIGNAL FORM VALUES TO THE UPDATED FORM VALUES? OR ADD USEEFFECT TO UPDATE WHEN UPDATEAPPTREC CHANGES
    }

    function handleUpdateNoteSuccess() {
        // TODO - CHANGE TO DEDICATED UPDATE NOTE STATE VAL
        setUpdateRecSuccess(false);
        setApptNoteFormValChanged(false);
        setUpdateNoteRecSuccess(false);
        // TODO - DO WE NEED TO SET THE ORIGNAL FORM VALUES TO THE UPDATED FORM VALUES?
    }

    function handleUpdateTasksSuccess() {
        setUpdateTaskRecsSuccess(false);
        // setUpdateRecSuccess(false);
        // TODO - WAS BELOW A TYPO BEING HERE?
        // setApptNoteFormValChanged(false);
        // TODO - DO WE NEED TO SET THE ORIGNAL FORM VALUES TO THE UPDATED FORM VALUES?
    }

    // TODO - MOVE MODAL ACTIONS TO SHARED MODAL ACTIONS,
    //  !!! USE SHARED UPDATE NOTE DIALOG - WILL NEED TO ADD HANDLING FOR TASK NOTES TO UPDATE NOTES DIALOG

    const handleUpdateNoteTasksApptModalClose = () => {
        // TODO - HANDLE MULTI UPDATES IN SUBMIT, DON'T OPEN THESE DEDICATED MODALS
        setShowUpdateApptModal(false);
        setShowUpdateApptNoteModal(false);
        setShowUpdateNoteTasksApptModal(false);

        setUpdateErrorMsg(null);
        setUpdateApptErrorMsg(null);
        setUpdateNoteErrorMsg(null);
        setUpdateTasksErrorMsg(null);

        setUpdateSuccessMsg(null);
        setUpdateApptSuccessMsg(null);
        setUpdateNoteSuccessMsg(null);
        setUpdateTasksSuccessMsg(null);

        if (updateRecApptSuccess) {
            // TODO - DISPLAY UPDATE SUCCESS MSG
            handleUpdateApptSuccess();
            // TODO - DO WE NEED TO SET THE ORIGNAL FORM VALUES TO THE UPDATED FORM VALUES? OR ADD USEEFFECT TO UPDATE WHEN UPDATEAPPTREC CHANGES
        }
        if (updateNoteRecSuccess) {
            // TODO - DISPLAY UPDATE SUCCESS MSG
            handleUpdateNoteSuccess();
        }
        if (updateTaskRecsSuccess) {
            // TODO - DISPLAY UPDATE SUCCESS MSG
            handleUpdateTasksSuccess();
        }
        if (updateMultiRecsSuccess) {
            // TODO - DISPLAY UPDATE SUCCESS MSG
            setUpdateMultiRecsSuccess(false);
        }
    }

    const handleUpdateApptNoteModalClose = () => {
        setShowUpdateApptNoteModal(false);
        setUpdateErrorMsg(null);
        setUpdateSuccessMsg(null);
        if (updateRecSuccess) {
            handleUpdateNoteSuccess();
            // TODO - DO WE NEED TO SET THE ORIGNAL FORM VALUES TO THE UPDATED FORM VALUES?
        }
    }

    /**
     * Handles appt. update, for appt and all related model types
     * @param {string[]} updateRecTypes - names representing model types of updated recs.
     * @returns {null}
     */
    const handleUpdateNoteTasksApptConfirm = async (updateRecTypes) => {
        if ('appt' in updateRecTypes) {
            await handleUpdateApptConfirm(false);
        }
        // if ('note' in updateRecTypes) {
        //     await handleUpdateApptNoteConfirm(false);
        // }
        if ('tasks' in updateRecTypes) {
            await handleUpdateApptTasksConfirm(false);
        }
        // Update appt rec
        // let updateApptRes;
        // if (editedApptFormValues && apptFormValsChanged) {
        //     updateApptRes = await updateAppointment(apptRec.id, apptFormValsDiff, showLogs);
        // }
        // // Display a message on success or error
        // // On success, give option to navigate to /appointments
        // if (updateApptRes.success) {
        //     setUpdateSuccessMsg('Appointment updated successfully!');
        //     setUpdatedApptRec(updateApptRes.updatedAppt);
        //     setUpdateRecApptSuccess(true);
        //     // TODO - DO WE NEED TO SET THE ORIGNAL FORM VALUES TO THE UPDATED FORM VALUES? OR ADD USEEFFECT TO UPDATE WHEN UPDATEAPPTREC CHANGES
        //     // TODO - START HERE, UPDATE THE APPT NOTE, TASKS IF EITHER HAS CHANGED
        //     if (apptNoteFormValChanged) {
        //         // TODO - NEED TO SET ABOVE VAL BASED ON CHANGE
        //     }
        // }
        // // On error, display error message in modal
        // else {
        //     setUpdateErrorMsg(updateApptRes.error);
        // }
    }

    const handleUpdateApptConfirm = async (showAlert) => {
        // Update appt rec
        let showNoChangeAlert = showAlert;
        let updateApptRes;
        if (editedApptFormValues && apptFormValsChanged) {
            updateApptRes = await updateAppointment(apptRec.id, apptFormValsDiff, showLogs);
        } else {
            showNoChangeAlert = true;
        }
        // Display a message on success or error
        // On success, give option to navigate to /appointments
        if (updateApptRes.success) {
            setUpdateSuccessMsg('Appointment updated successfully!');
            setUpdateApptSuccessMsg('Appointment updated successfully!');
            setUpdatedApptRec(updateApptRes.updatedAppt);
            setUpdateRecApptSuccess(true);
            // TODO - DO WE NEED TO SET THE ORIGNAL FORM VALUES TO THE UPDATED FORM VALUES? OR ADD USEEFFECT TO UPDATE WHEN UPDATEAPPTREC CHANGES
            // TODO - START HERE, UPDATE THE APPT NOTE, TASKS IF EITHER HAS CHANGED
            if (apptNoteFormValChanged) {
                // TODO - NEED TO SET ABOVE VAL BASED ON CHANGE
            }
        }
        // On error, display error message in modal
        else {
            setUpdateErrorMsg(updateApptRes.error);
            setUpdateApptErrorMsg(updateApptRes.error);
        }
        if (showNoChangeAlert) {
            alert('No changes were made to the appointment record.');
        }
    }

    // TODO - ? ADD BUTTON TO UPDATE APPT NOTE ONLY?
    // TODO - NOTES NOW IMMUTABLE, REPLACE WITH CREATE A NEW NOTE

    // const handleUpdateApptNoteConfirm = async (showAlert) => {
    //     // Update appt note rec
    //     let showNoRecsAlert = showAlert;
    //     let updateApptNoteRes;
    //     if (origApptNoteRec) {
    //         if (editedApptNoteFormValues && apptNoteFormValChanged) {
    //             updateApptNoteRes = await updateAppointmentNote(origApptNoteRec.id, editedApptNoteFormValues.note, showLogs);
    //         } else {
    //             showNoRecsAlert = true;
    //         }
    //         // Display a message on success or error
    //         // TODO - On success for any rec update, give option to navigate to /appointments
    //         if (updateApptNoteRes.success) {
    //             setUpdateSuccessMsg('Appointment note updated successfully!');
    //             setUpdateNoteSuccessMsg('Appointment note updated successfully!');
    //             setUpdatedApptNoteRec(updateApptNoteRes.updatedApptNote);
    //             setUpdateRecSuccess(true);
    //         }
    //         // On error, display error message in modal
    //         else {
    //             setUpdateErrorMsg(updateApptNoteRes.error);
    //             setUpdateNoteErrorMsg(updateApptNoteRes.error);
    //         }
    //     }
    //     if (showNoRecsAlert) {
    //         // No existing appt note, create one
    //         // TODO - ADD CREATE APPT NOTE FUNCTIONALITY
    //         alert('No existing appt note to update! Create one!');
    //     }
    // }

    // TODO - FIT THIS TO UPDATE TASKS INSTEAD,
    //  !!! ?IS THIS STILL NEEDED?
    const handleUpdateApptTasksConfirm = async (showAlert) => {
        // Update appt task recs
        let showNoRecsAlert = showAlert;
        let updateApptTasksRes;
        if (origTasksForAnimals) {
            if (editedTasksForAnimals && apptTasksValsChanged) {
                updateApptTasksRes = await updateApptTasks(apptRec.id, editedTasksForAnimals, showLogs);
            } else {
                showNoRecsAlert = true;
            }
            // Display a message on success or error
            // TODO - On success for any rec update, give option to navigate to /appointments
            if (updateApptTasksRes.success) {
                setUpdateSuccessMsg('Appointment tasks updated successfully!');
                setUpdatedApptTasks(updateApptTasksRes.updatedApptTasks);
                setUpdateRecSuccess(true);
                setUpdateTaskRecsSuccess(true);
            }
            // On error, display error message in modal
            else {
                setUpdateErrorMsg(updateApptTasksRes.error);
                setUpdateTasksErrorMsg(updateApptTasksRes.error);
            }
        }
        if (showNoRecsAlert) {
            // No tasks changed
            alert('No existing appt tasks changed!');
        }
    }

    // useEffect( setOrigTasksForAnimals(combinedOrigTaskRecs)
    useEffect(() => {
        // TODO - DOES THIS NEED A STATE VAR TO PREVENT ORIG RECS UPDATING ON FORM SUBMIT,
        //  IF WE'RE NOT NAVIGATING AWAY AFTER?
        if (!pauseForFormSubmit && updateTaskRecsDone) {
            const combinedOrigTaskRecs = origShoeTaskFormVals.concat(origTrimTaskFormVals);
            setOrigTasksForAnimals(combinedOrigTaskRecs);
        }
    }, [origShoeTaskFormVals, origTrimTaskFormVals, pauseForFormSubmit, updateTaskRecsDone]);

    // useEffect( setEditedTasksForAnimals(combinedEditedTaskRecs);
    useEffect(() => {
        // TODO - DOES THIS NEED A STATE VAR TO PREVENT EDITED RECS UPDATING ON FORM SUBMIT,
        //  IF WE'RE NOT NAVIGATING AWAY AFTER?
        if (!pauseForFormSubmit && updateTaskRecsDone) {
            const combinedEditedTaskRecs = editedShoeTaskFormVals.concat(editedTrimTaskFormVals);
            setEditedTasksForAnimals(combinedEditedTaskRecs);
        }
    }, [editedShoeTaskFormVals, editedTrimTaskFormVals, pauseForFormSubmit, updateTaskRecsDone]);

    // TODO - VERIFY IF WE NEED THIS AFTER LOGIC MATCHES TRIM TASKS

    // useEffect( setEditedTasksForAnimals(combinedEditedTaskRecs);
    useEffect(() => {
        // check for diffs in orig vs. edited shoe task form vals
        if (!pauseForFormSubmit && updateTaskRecsDone) {
            if (origShoeTaskFormVals && editedShoeTaskFormVals) {
                const origShoeAnimalIds = origShoeTaskFormVals.map((taskObj, idx) => {
                    return taskObj.animalID;
                });
                const editedShoeAnimalIds = editedShoeTaskFormVals.map((taskObj, idx) => {
                    return taskObj.animalID;
                });
                const nonOrigShoeAnimalIds = editedShoeAnimalIds.filter(name => !origShoeAnimalIds.includes(name));
                const editedShoeTasksAnimalIds = editedTasksForAnimals.map((taskObj, idx) => {
                    if (taskObj.taskName === 'shoe') {
                        return taskObj.animalID;
                    }
                });
                nonOrigShoeAnimalIds.forEach((animalId) => {
                    if (!editedShoeTasksAnimalIds.includes(animalId)) {
                        const newShoeTask = editedShoeTaskFormVals.find((taskObj) => {
                            return taskObj.animalID === animalId;
                        });
                        if (newShoeTask) {
                            editedTasksForAnimals.push(newShoeTask);
                        }
                    }
                })
            }
        }
    }, [origShoeTaskFormVals, editedShoeTaskFormVals, origTrimTaskFormVals, editedTrimTaskFormVals, pauseForFormSubmit, updateTaskRecsDone]);

    // TODO - CREATE SHARED HOOKS FOR UPDATING APPT AMOUNTS, DURATION

    // useEffect( setEditedTotalApptAmount(sumAmount);
    useEffect(() => {
        if (!pauseForFormSubmit && updateTaskRecsDone) {
            const sumAmount = editedTasksForAnimals.reduce((accumulator, currentObj) => {
                return accumulator + currentObj.totalAmount;
            }, 0);
            setEditedTotalApptAmount(sumAmount);
        }
    }, [editedTasksForAnimals, pauseForFormSubmit, updateTaskRecsDone]);

    // useEffect( setEditedTotalApptDuration(sumDuration);
    useEffect(() => {
        if (!pauseForFormSubmit && updateTaskRecsDone) {
            const sumDuration = editedTasksForAnimals.reduce((accumulator, currentObj) => {
                return accumulator + currentObj.totalDuration;
            }, 0);
            setEditedTotalApptDuration(sumDuration);
        }
    }, [editedTasksForAnimals, pauseForFormSubmit, updateTaskRecsDone]);

    // useEffect( console.log( origShoeTaskFormVals, origTrimTaskFormVals, editedShoeTaskFormVals, editedTrimTaskFormVals, origTasksForAnimals, editedTasksForAnimals
    // useEffect( console.log( origSelectedShoeAnimalNames, editedSelectedShoeAnimalNames, editedSelectedTrimAnimalNames, origSelectedTrimAnimalNames, shoeTaskFormVals, trimTaskFormVals, origTasksForAnimals, editedTasksForAnimals
    useEffect(() => {
        if (showLogs) {
            // console.log('selectedAnimalNames', selectedAnimalNames);
            // console.log('editedSelectedShoeAnimalNames', editedSelectedShoeAnimalNames);
            // console.log('origSelectedShoeAnimalNames', origSelectedShoeAnimalNames);
            // console.log('editedSelectedTrimAnimalNames', editedSelectedTrimAnimalNames);
            // console.log('origSelectedTrimAnimalNames', origSelectedTrimAnimalNames);
            console.log('origShoeTaskFormVals', origShoeTaskFormVals);
            console.log('origTrimTaskFormVals', origTrimTaskFormVals);
            console.log('editedShoeTaskFormVals', editedShoeTaskFormVals);
            console.log('editedTrimTaskFormVals', editedTrimTaskFormVals);
            // console.log('origTasksForAnimals', origTasksForAnimals);
            // console.log('editedTasksForAnimals', editedTasksForAnimals);
        }
        // }, [showLogs, origSelectedShoeAnimalNames, editedSelectedShoeAnimalNames, editedSelectedTrimAnimalNames, origSelectedTrimAnimalNames, shoeTaskFormVals, trimTaskFormVals, origTasksForAnimals, editedTasksForAnimals]);
    // }, [showLogs, origShoeTaskFormVals, origTrimTaskFormVals, editedShoeTaskFormVals, editedTrimTaskFormVals, origTasksForAnimals, editedTasksForAnimals]);
    }, [showLogs, origShoeTaskFormVals, origTrimTaskFormVals, editedShoeTaskFormVals, editedTrimTaskFormVals]);

    // TODO - UPDATE TO MATCH CORRESPONDING ACTION FOR UPDATED TRIM TASKS

    // useEffect( setEditedShoeAnimalTaskRecs(, updateEditedShoeTaskForms(updatedTaskAnimalRecs);
    useEffect(() => {
        let updatedTaskAnimalRecs = [];
        if (editedSelectedShoeAnimalNames.length > 0) {
            const shoeAnimalRecs = filterAnimalRecsByAnimalNames(clientAnimalRecs, editedSelectedShoeAnimalNames);
            updatedTaskAnimalRecs = shoeAnimalRecs;
            setEditedSelectedShoeAnimalRecs(shoeAnimalRecs);
            const shoeAnimalRecsTasks = shoeAnimalRecs.map((animalRec, idx) => {
                return {
                    ...animalRec,
                    taskType: 'shoe',
                };
            });
            setEditedShoeAnimalTaskRecs(shoeAnimalRecsTasks);
            // TODO - USE NAMES FROM THESE TWO TO SET setApptTasksValsChanged IN DEDICATED USEEFFECT
        } else {
            setEditedShoeAnimalTaskRecs([]);
        }
        updateEditedShoeTaskForms(updatedTaskAnimalRecs);
    }, [editedSelectedShoeAnimalNames]);

    // TODO - START HERE, ADD HANDLING FOR UPDATING TASK VALS CHANGED BASED ON TASK FORM ATTRS

    // useEffect( setEditedTrimAnimalTaskRecs(, updateEditedTrimTaskForms(updatedTaskAnimalRecs);
    useEffect(() => {
        // TODO - ? NEED TO USE updateTaskRecsDone HERE ALSO?
        if (!pauseForFormSubmit) {
            let updatedTaskAnimalRecs = [];
            if (editedSelectedTrimAnimalNames.length > 0) {
                const trimAnimalRecs = filterAnimalRecsByAnimalNames(clientAnimalRecs, editedSelectedTrimAnimalNames);
                updatedTaskAnimalRecs = trimAnimalRecs;
                setEditedSelectedTrimAnimalRecs(trimAnimalRecs);
                const trimAnimalRecsTasks = trimAnimalRecs.map((animalRec, idx) => {
                    return {
                        ...animalRec,
                        taskType: 'trim',
                    };
                });
                setEditedTrimAnimalTaskRecs(trimAnimalRecsTasks);
                // TODO - USE NAMES FROM THESE TWO TO SET setApptTasksValsChanged IN DEDICATED USEEFFECT
            } else {
                setEditedTrimAnimalTaskRecs([]);
            }
            updateEditedTrimTaskForms(updatedTaskAnimalRecs);
        }
    }, [editedSelectedTrimAnimalNames, pauseForFormSubmit]);

    // useEffect( console.log( editedShoeAnimalTaskRecs, editedTrimAnimalTaskRecs
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('editedShoeAnimalTaskRecs', editedShoeAnimalTaskRecs);
    //         console.log('editedTrimAnimalTaskRecs', editedTrimAnimalTaskRecs);
    //     }
    // }, [showLogs, editedShoeAnimalTaskRecs, editedTrimAnimalTaskRecs]);

    // useEffect( setOrigShoeTaskFormVals(shoeTaskEmptyFormVals);
    useEffect(() => {
        if (!pauseForFormSubmit && updateTaskRecsDone) {
            if (apptRec && origApptShoeTasks && origApptShoeTasks.length > 0) {
                if (origShoeAnimalTaskRecs && origShoeAnimalTaskRecs.length > 0) {
                    const shoeTaskEmptyFormVals = origApptShoeTasks.map((taskRec, idx) => {
                        // console.log('>!> origApptShoeTasks taskRec', taskRec);
                        const recAnimalId = taskRec.animalID;
                        // const shoeTaskAnimalRec = clientAnimalRecs.find((animalRec) => {
                        const animalRec = clientAnimalRecs.find((animalRec) => {
                            return animalRec.id === recAnimalId;
                        });
                        // console.log('>!> animalRec', animalRec);
                        const defaultTaskAmt = ampUser.defaultShoeTaskAmount || shoeTaskFormObj.amount;
                        const defaultTaskDuration = ampUser.defaultShoeTaskDuration || shoeTaskFormObj.duration;
                        // const addedTaskAmt = animalRec.addedShoeTaskAmount || shoeTaskFormObj.addedCost;
                        const addedTaskAmt = Math.max(taskRec?.addedCost || 0,
                            animalRec?.addedShoeTaskAmount || 0,
                            shoeTaskFormObj?.addedCost || 0);
                        // const addedTaskDuration = animalRec.addedShoeTaskDuration || shoeTaskFormObj.addedTime;
                        const addedTaskDuration = Math.max(taskRec?.addedTime || 0,
                            animalRec?.addedShoeTaskDuration || 0,
                            shoeTaskFormObj?.addedTime || 0);
                        const thisTaskAmt = sumTaskAmount(defaultTaskAmt, addedTaskAmt);
                        // console.log('thisTaskAmt', thisTaskAmt);
                        const thisTaskDur = sumTaskDuration(defaultTaskDuration, addedTaskDuration);
                        // console.log('thisTaskDur', thisTaskDur);
                        const formId = generateUniqueKey(animalRec.id)
                        return {...shoeTaskFormObj,
                            taskRecID: taskRec.id,
                            animalID: animalRec.id,
                            appointmentID: apptRec.id,
                            name: animalRec?.name || 'missing name',
                            taskName: 'shoe',
                            duration: defaultTaskDuration,
                            amount: defaultTaskAmt,
                            addedCost: addedTaskAmt,
                            addedTime: addedTaskDuration,
                            totalAmount: thisTaskAmt,
                            totalDuration: thisTaskDur,
                            formUniqueId: formId,
                        };
                    })
                    // console.log('!!! CHANGED shoeTaskEmptyFormVals', shoeTaskEmptyFormVals);
                    setOrigShoeTaskFormVals(shoeTaskEmptyFormVals);
                    setEditedShoeTaskFormVals(shoeTaskEmptyFormVals);
                }
            }
        }
    }, [origApptShoeTasks, origShoeAnimalTaskRecs, apptRec, pauseForFormSubmit, updateTaskRecsDone]);

    // setEditedShoeTaskFormVals(newShoeTaskForms);
    function updateEditedShoeTaskForms(shoeAnimalRecs) {
        const existingShoeTaskForms = [...editedShoeTaskFormVals];
        const newShoeTaskForms = [];
        if (shoeAnimalRecs.length > 0) {
            shoeAnimalRecs.forEach((animalRec, idx) => {
                // check if animalID already exists in existingShoeTaskForms
                const existingFormObj = existingShoeTaskForms.find((taskObj) => {
                    return taskObj.animalID === animalRec.id;
                });
                // if exists, push onto newShoeTaskForms
                if (existingFormObj) {
                    newShoeTaskForms.push(existingFormObj);
                }
                else {
                    // check if task form obj already exists in origShoeTaskFormVals
                    const existingOrigShoeTaskForm = origShoeTaskFormVals.find((taskObj) => {
                        return taskObj.animalID === animalRec.id;
                    });
                    if (existingOrigShoeTaskForm) {
                        if (showLogs) {
                            console.log('@@@ <>@<> existingOrigShoeTaskForm', existingOrigShoeTaskForm);
                        }
                        newShoeTaskForms.push(existingOrigShoeTaskForm);
                    }
                    else {
                        // if not exists in either array, create new form obj
                        const formId = generateUniqueKey(animalRec.id)
                        console.warn('@@@ >>> <>!<> updateEditedShoeTaskForms formId', formId);
                        const taskBaseAmount = ampUser.defaultShoeTaskAmount || shoeTaskFormObj.amount;
                        const taskAddedAmount = animalRec.addedShoeTaskAmount || shoeTaskFormObj.addedCost;
                        const taskBaseDuration = ampUser.defaultShoeTaskDuration || shoeTaskFormObj.duration;
                        const taskAddedDuration = animalRec.addedShoeTaskDuration || shoeTaskFormObj.addedTime;
                        const newFormObj = {...shoeTaskFormObj,
                            animalID: animalRec.id,
                            appointmentID: apptRec?.id,
                            name: animalRec.name,
                            taskName: 'shoe',
                            duration: taskBaseDuration,
                            amount: taskBaseAmount,
                            addedCost: taskAddedAmount,
                            addedTime: taskAddedDuration,
                            totalAmount: sumTaskAmount(taskBaseAmount, taskAddedAmount),
                            totalDuration: sumTaskDuration(taskBaseDuration, taskAddedDuration),
                            formUniqueId: formId,
                            newTaskForm: true,
                        };
                        newShoeTaskForms.push(newFormObj);
                        if (showLogs) {
                            console.log('<<<!!!>>> new shoe task form newFormObj', newFormObj);
                        }
                        // TODO - MOVE TO USEEFFECT BASED ON editedShoeTaskFormVals
                        const existingNewShoeTaskForms = newTaskForms.shoe || [];
                        existingNewShoeTaskForms.push(newFormObj);
                        setNewTaskForms({...newTaskForms, shoe: existingNewShoeTaskForms});
                        setCreatedShoeTaskFormUniqueId(formId);
                    }
                }
            });
        }
        setEditedShoeTaskFormVals(newShoeTaskForms);
    }

    // useEffect( setOrigTrimTaskFormVals(trimTaskEmptyFormVals);
    useEffect(() => {
        if (!pauseForFormSubmit && updateTaskRecsDone) {
            if (apptRec && origApptTrimTasks && origApptTrimTasks.length > 0) {
                if (origTrimAnimalTaskRecs && origTrimAnimalTaskRecs.length > 0) {
                    const trimTaskEmptyFormVals = origApptTrimTasks.map((taskRec, idx) => {
                        // console.log('>!> origApptTrimTasks taskRec', taskRec);
                        const recAnimalId = taskRec.animalID;
                        // const trimTaskAnimalRec = clientAnimalRecs.find((animalRec) => {
                        const animalRec = clientAnimalRecs.find((animalRec) => {
                            return animalRec.id === recAnimalId;
                        });
                        // console.log('>!> animalRec', animalRec);
                        const defaultTaskAmt = ampUser.defaultTrimTaskAmount || trimTaskFormObj.amount;
                        const defaultTaskDuration = ampUser.defaultTrimTaskDuration || trimTaskFormObj.duration;
                        // const addedTaskAmt = animalRec.addedTrimTaskAmount || trimTaskFormObj.addedCost;
                        const addedTaskAmt = Math.max(taskRec?.addedCost || 0,
                            animalRec?.addedTrimTaskAmount || 0,
                            trimTaskFormObj?.addedCost || 0);
                        // const addedTaskDuration = animalRec.addedTrimTaskDuration || trimTaskFormObj.addedTime;
                        const addedTaskDuration = Math.max(taskRec?.addedTime || 0,
                            animalRec?.addedTrimTaskDuration || 0,
                            trimTaskFormObj?.addedTime || 0);
                        const thisTaskAmt = sumTaskAmount(defaultTaskAmt, addedTaskAmt);
                        // console.log('thisTaskAmt', thisTaskAmt);
                        const thisTaskDur = sumTaskDuration(defaultTaskDuration, addedTaskDuration);
                        // console.log('thisTaskDur', thisTaskDur);
                        const formId = generateUniqueKey(animalRec.id)
                        return {...trimTaskFormObj,
                            taskRecID: taskRec.id,
                            animalID: animalRec.id,
                            appointmentID: apptRec.id,
                            name: animalRec?.name || 'missing name',
                            taskName: 'trim',
                            duration: defaultTaskDuration,
                            amount: defaultTaskAmt,
                            addedCost: addedTaskAmt,
                            addedTime: addedTaskDuration,
                            totalAmount: thisTaskAmt,
                            totalDuration: thisTaskDur,
                            formUniqueId: formId,
                        };
                    })
                    // console.log('!!! CHANGED trimTaskEmptyFormVals', trimTaskEmptyFormVals);
                    setOrigTrimTaskFormVals(trimTaskEmptyFormVals);
                    setEditedTrimTaskFormVals(trimTaskEmptyFormVals);
                }
            }
        }
    }, [origApptTrimTasks, origTrimAnimalTaskRecs, apptRec, pauseForFormSubmit, updateTaskRecsDone]);

    // setEditedTrimTaskFormVals(newTrimTaskForms);
    function updateEditedTrimTaskForms(trimAnimalRecs) {
        const existingTrimTaskForms = [...editedTrimTaskFormVals];
        const newTrimTaskForms = [];
        if (trimAnimalRecs.length > 0) {
            trimAnimalRecs.forEach((animalRec, idx) => {
                // check if animalID already exists in existingTrimTaskForms
                const existingFormObj = existingTrimTaskForms.find((taskObj) => {
                    return taskObj.animalID === animalRec.id;
                });
                // if exists, push onto newTrimTaskForms
                if (existingFormObj) {
                    newTrimTaskForms.push(existingFormObj);
                }
                else {
                    // check if task form obj already exists in origTrimTaskFormVals
                    const existingOrigTrimTaskForm = origTrimTaskFormVals.find((taskObj) => {
                        return taskObj.animalID === animalRec.id;
                    });
                    if (existingOrigTrimTaskForm) {
                        if (showLogs) {
                            console.log('@@@ <>@<> existingOrigTrimTaskForm', existingOrigTrimTaskForm);
                        }
                        newTrimTaskForms.push(existingOrigTrimTaskForm);
                    }
                    else {
                        // if not exists in either array, create new form obj
                        const formId = generateUniqueKey(animalRec.id)
                        console.warn('@@@ >>> <>!<> updateEditedTrimTaskForms formId', formId);
                        const taskBaseAmount = ampUser.defaultTrimTaskAmount || trimTaskFormObj.amount;
                        const taskAddedAmount = animalRec.addedTrimTaskAmount || trimTaskFormObj.addedCost;
                        const taskBaseDuration = ampUser.defaultTrimTaskDuration || trimTaskFormObj.duration;
                        const taskAddedDuration = animalRec.addedTrimTaskDuration || trimTaskFormObj.addedTime;
                        const newFormObj = {...trimTaskFormObj,
                            animalID: animalRec.id,
                            appointmentID: apptRec?.id,
                            name: animalRec.name,
                            taskName: 'trim',
                            duration: taskBaseDuration,
                            amount: taskBaseAmount,
                            addedCost: taskAddedAmount,
                            addedTime: taskAddedDuration,
                            totalAmount: sumTaskAmount(taskBaseAmount, taskAddedAmount),
                            totalDuration: sumTaskDuration(taskBaseDuration, taskAddedDuration),
                            formUniqueId: formId,
                            newTaskForm: true,
                        };
                        newTrimTaskForms.push(newFormObj);
                        if (showLogs) {
                            console.log('<<<!!!>>> new trim task form newFormObj', newFormObj);
                        }
                        // TODO - MOVE TO USEEFFECT BASED ON editedTrimTaskFormVals
                        const existingNewTrimTaskForms = newTaskForms.trim || [];
                        existingNewTrimTaskForms.push(newFormObj);
                        setNewTaskForms({...newTaskForms, trim: existingNewTrimTaskForms});
                        setCreatedTrimTaskFormUniqueId(formId);
                    }
                }
            });
        }
        setEditedTrimTaskFormVals(newTrimTaskForms);
    }

    // setEditedApptFormValues({...editedApptFormValues, [updatedFormField]: updatedFieldValue});
    function updateEditedApptFormValues(updatedFormField, updatedFieldValue) {
        if (updatedFormField === 'amount' && updatedFieldValue !== 0) {
            const amountInt = convertToFullInteger(updatedFieldValue);
            setEditedApptFormValues({...editedApptFormValues, amount: amountInt});
        } else {
            setEditedApptFormValues({...editedApptFormValues, [updatedFormField]: updatedFieldValue});
        }

        if (origApptFormValues[updatedFormField] !== updatedFieldValue) {
            setApptFormValsDiff({...apptFormValsDiff, [updatedFormField]: updatedFieldValue});
        } else {
            // The form value went back to the orig value, remove the field from the diff object
            const updatedDiff = {...apptFormValsDiff};
            delete updatedDiff[updatedFormField];
            setApptFormValsDiff(updatedDiff);
        }
    }

    // useEffect( console.log(editedApptFormValues
    useEffect(() => {
        if (showLogs) {
            console.log('editedApptFormValues', editedApptFormValues);
            // console.log('editedApptNoteFormValues', editedApptNoteFormValues);
        }
    // }, [showLogs, editedApptFormValues, editedApptNoteFormValues]);
    }, [showLogs, editedApptFormValues]);

    // useEffect( console.log( apptFormValsDiff, apptFormValsChanged
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('apptFormValsDiff', apptFormValsDiff);
    //         console.log('apptFormValsChanged', apptFormValsChanged);
    //     }
    // }, [showLogs, apptFormValsDiff, apptFormValsChanged]);

    // useEffect( setApptFormValsChanged(
    useEffect(() => {
        if (!pauseForFormSubmit) {
            if (apptFormValsDiff) {
                if (Object.keys(apptFormValsDiff).length > 0) {
                    setApptFormValsChanged(true);
                } else {
                    setApptFormValsChanged(false);
                }
            }
        }
    }, [apptFormValsDiff, pauseForFormSubmit]);

    // setApptNoteFormValsDiff( setEditedApptNoteFormValues(
    function updateEditedApptNoteFormValue(event) {
        const updatedNoteFieldValue = event.target.value;
        if (origApptNoteFormValues.note !== updatedNoteFieldValue) {
            setApptNoteFormValChanged(true);
        } else {
            // The form value went back to the orig value
            setApptNoteFormValChanged(false);
        }
        setEditedApptNoteFormValues({note: updatedNoteFieldValue});
    }

    // useEffect( console.log( apptNoteFormValChanged
    useEffect(() => {
        if (showLogs) {
            console.log('$$$ apptNoteFormValChanged', apptNoteFormValChanged);
        }
    }, [showLogs, apptNoteFormValChanged]);

    // TODO - USE NAMES FROM THESE TWO TO SET setApptTasksValsChanged IN DEDICATED USEEFFECT
    // const handleSelectedAnimalNamesChange = (event)
    const handleSelectedShoeAnimalNamesChange = (event) => {
        // TODO - UPDATE TO MATCH CORRESPONDING ACTION FOR UPDATED TRIM TASKS
        // console.log('event', event);
        console.warn('handleSelectedShoeAnimalNamesChange');
        const { target: { value }, } = event;
        // console.log('value', value);
        // On autofill we get a stringified value.
        const shoeNames = typeof value === 'string' ? value.split(',') : value;
        setEditedSelectedShoeAnimalNames(shoeNames);
        setSelectedShoeAnimalNamesChanged(true);
        setUpdateApptAmount(true);
        setUpdateApptDuration(true);
    };

    // TODO - USE NAMES FROM THESE TWO TO SET setApptTasksValsChanged IN DEDICATED USEEFFECT
    const handleSelectedTrimAnimalNamesChange = (event) => {
        // console.log('event', event);
        console.warn('handleSelectedTrimAnimalNamesChange');
        const { target: { value }, } = event;
        // console.log('value', value);
        // On autofill we get a stringified value.
        const trimNames = typeof value === 'string' ? value.split(',') : value;
        setEditedSelectedTrimAnimalNames(trimNames);
        setSelectedTrimAnimalNamesChanged(true);
        setUpdateApptAmount(true);
        setUpdateApptDuration(true);
    };

    // TODO - MOVE THESE TO SHARED HOOKS OR FUNCTIONS IN NEW UTILS DIR

    function dateTimeFromDateTime(dateTimeVal) {
        const ret = {};
        const dateVal = dateTimeVal.format('YYYY-MM-DD');
        ret['dateVal'] = dateVal;
        const timeVal = dateTimeVal.format('HH:mm:ss');
        ret['timeVal'] = timeVal;
        const formattedDateTime = `${dateVal}T${timeVal}Z`;
        ret['formattedDateTime'] = formattedDateTime;
        return ret;
    }

    function handleApptDateTimeChange(newDateTime) {
        setEditedApptDateTime(newDateTime);
        const dateTimeValsObj = dateTimeFromDateTime(newDateTime);
        setEditedApptTimeVals(dateTimeValsObj);
        const updatedApptFormVals = {
            ...editedApptFormValues,
            apptDateTime: dateTimeValsObj.formattedDateTime,
            apptDate: dateTimeValsObj.dateVal,
            apptTime: dateTimeValsObj.timeVal,
        };
        setEditedApptFormValues(updatedApptFormVals);
    }

    // useEffect( console.log(apptDate, apptTime, origApptDateTime
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('apptDate', apptDate);
    //         console.log('apptTime', apptTime);
    //         console.log('origApptDateTime', origApptDateTime);
    //     }
    // }, [showLogs, apptDate, apptTime, origApptDateTime]);

    // TODO - THIS WILL REQUIRE A DELETE APPT BUTTON, WHICH DELETES THE APPT AND ALL RELATED TASKS,
    //  ALSO DELETE SPECIFICALLY M:N RELATED MODELS
    // const handleDeleteApptModalClose = () => {
    //     setShowDeleteApptModal(false);
    //     setModelRecToDelete(null);
    // };

    const onDeleteApptModalClose = () => {
        handleDeleteApptModalClose(setShowDeleteApptModal, setModelRecToDelete, showLogs);
    };

    const handleDeleteApptNoteModalClose = () => {
        setShowDeleteApptNoteModal(false);
        setApptNoteToDelete(null);
    }

    // TODO - MAKE SURE ALL RELATED RECS ARE DELETED ALSO
    // const handleDeleteApptConfirm = async () => {
    //     if (showLogs) {
    //         console.log('The Appt record has been deleted.', modelRecToDelete);
    //     }
    //     // TODO - MAKE SURE ALL RELATED RECS ARE DELETED ALSO
    //     await deleteAppointmentById(modelRecToDelete, showLogs);
    //     handleDeleteApptModalClose();
    //     navigate('/appointments');
    // };

    const onDeleteApptConfirm = async () => {
        await handleDeleteApptConfirm(modelRecToDelete, setDeleteApptRes, setShowDeleteApptModal, setModelRecToDelete, showLogs);
        // TODO - MAKE SURE ALL RELATED RECS ARE DELETED ALSO
        navigate('/appointments');
    };

    const onDeleteApptCancel = () => {
        handleDeleteApptCancel(modelRecToDelete, setShowDeleteApptModal, setModelRecToDelete, showLogs);
    };

    const handleDeleteApptNoteConfirm = async (modelRec) => {
        await deleteAppointmentNoteById(modelRec, showLogs);
        if (showLogs) {
            console.log('The Appointment Note record has been deleted.', modelRec);
        }
        handleDeleteApptNoteModalClose();
    }

    // useEffect( check tasks diff -> setApptTasksValsChanged(
    useEffect(() => {
        // TODO - VERIFY WE SHOULD USE BOTH OF THESE
        if (!pauseForFormSubmit && updateTaskRecsDone) {
            let tasksValsChanged = false;
            // TODO - THIS IS NOT UPDATED EXCEPT ON SUBMIT, USE IT OR REMOVE IT
            if (apptAmountChanged) {
                if (origTotalApptAmount !== editedTotalApptAmount) {
                    tasksValsChanged = true;
                }
            }
            if (apptDurationChanged) {
                // TODO - THIS IS NOT UPDATED EXCEPT ON SUBMIT, USE IT OR REMOVE IT
                if (origTotalApptDuration !== editedTotalApptDuration) {
                    tasksValsChanged = true;
                }
            }

            if (selectedShoeAnimalNamesChanged) {
                const origShoeNamesSet = new Set(origSelectedShoeAnimalNames);
                const editedShoeNamesSet = new Set(editedSelectedShoeAnimalNames);
                const shoeNamesDiff = new Set([...origShoeNamesSet].filter(x => !editedShoeNamesSet.has(x)));
                if (shoeNamesDiff.size > 0) {
                    console.warn('!!! shoeNamesDiff', shoeNamesDiff);
                    tasksValsChanged = true;
                }
                const origShoeTaskIdsSet = new Set(origSelectedShoeAnimalRecs.map(rec => rec.id));
                const editedShoeTaskIdsSet = new Set(editedSelectedShoeAnimalRecs.map(rec => rec.id));
                const shoeTaskIdsDiff = new Set([...origShoeTaskIdsSet].filter(x => !editedShoeTaskIdsSet.has(x)));
                if (shoeTaskIdsDiff.size > 0) {
                    tasksValsChanged = true;
                }
            }
            if (selectedTrimAnimalNamesChanged) {
                const origTrimNamesSet = new Set(origSelectedTrimAnimalNames);
                const editedTrimNamesSet = new Set(editedSelectedTrimAnimalNames);
                const trimNamesDiff = new Set([...origTrimNamesSet].filter(x => !editedTrimNamesSet.has(x)));
                if (trimNamesDiff.size > 0) {
                    // console.warn('!!! trimNamesDiff', trimNamesDiff);
                    tasksValsChanged = true;
                }
                const origTrimTaskIdsSet = new Set(origSelectedTrimAnimalRecs.map(rec => rec.id));
                const editedTrimTaskIdsSet = new Set(editedSelectedTrimAnimalRecs.map(rec => rec.id));
                const trimTaskIdsDiff = new Set([...origTrimTaskIdsSet].filter(x => !editedTrimTaskIdsSet.has(x)));
                if (trimTaskIdsDiff.size > 0) {
                    tasksValsChanged = true;
                }
            }
            // console.warn('!!! setApptTasksValsChanged(tasksValsChanged);', tasksValsChanged);
            setApptTasksValsChanged(tasksValsChanged);
        }
    // }, [apptAmountChanged, apptDurationChanged, selectedShoeAnimalNamesChanged, selectedTrimAnimalNamesChanged, pauseForFormSubmit]);
    }, [apptAmountChanged, apptDurationChanged, selectedShoeAnimalNamesChanged, selectedTrimAnimalNamesChanged, pauseForFormSubmit, updateTaskRecsDone]);

    // useEffect( console.log( taskFormsDiffObj, updatedTrimTaskFormUniqueId]
    useEffect(() => {
        if (showLogs) {
            console.log('$$$ taskFormsDiffObj', taskFormsDiffObj);
            console.log('$$$ updatedTrimTaskFormUniqueId', updatedTrimTaskFormUniqueId);
        }
    }, [showLogs, taskFormsDiffObj, updatedTrimTaskFormUniqueId]);

    // useEffect( set taskform action unique ids back to null
    useEffect(() => {
        // TODO - DO WE ALSO NEED TO USE pauseForFormSubmit
        if (updateTaskRecsDone) {
            // reset the diff obj, related vals
            setUpdatedTrimTaskFormUniqueId(null);
            setUpdatedShoeTaskFormUniqueId(null);
            setCreatedTrimTaskFormUniqueId(null);
            setCreatedShoeTaskFormUniqueId(null);
            setDeletedTrimTaskFormUniqueId(null);
            setDeletedShoeTaskFormUniqueId(null);
            setTaskFormsDiffObj({shoe: {}, trim: {}});
        }
        // setUpdateTaskRecsDone(false);
    // }, [updateTaskRecsDone]);
    }, [updateTaskRecsDone, pauseForFormSubmit]);

    // TODO - MOVE THESE TO SHARED HOOKS OR FUNCTIONS IN NEW UTILS DIR

    function calculateTotalApptAmount(copyOfTrimTaskForms, copyOfShoeTaskForms) {
        const allTaskForms = copyOfTrimTaskForms.concat(copyOfShoeTaskForms);
        const totalAmount = allTaskForms.reduce((acc, taskForm) => {
            return acc + taskForm.totalAmount;
        }, 0);
        return totalAmount;
    }

    function calculateTotalApptDuration(copyOfTrimTaskForms, copyOfShoeTaskForms) {
        const allTaskForms = copyOfTrimTaskForms.concat(copyOfShoeTaskForms);
        const totalDuration = allTaskForms.reduce((acc, taskForm) => {
            return acc + taskForm.totalDuration;
        }, 0);
        return totalDuration;
    }

    // TODO - TRY TO MOVE SOME OF THESE ACTIONS TO SHARED HOOKS OR FUNCTIONS

    async function onUpdateApptRec() {
        // TODO - HANDLE CASE IF MULTIPLE CHANGE MODALS ARE NEEDED
        props.setPauseDataForFormSubmit(true);
        const copyOfOrigTrimTaskFormVals = [...origTrimTaskFormVals];
        const copyOfEditedTrimTaskFormVals = [...editedTrimTaskFormVals];
        const copyOfOrigShoeTaskFormVals = [...origShoeTaskFormVals];
        const copyOfEditedShoeTaskFormVals = [...editedShoeTaskFormVals];
        const copyOfEditedApptFormAmt = editedApptFormValues.amount;
        const copyOfEditedApptFormDur = editedApptFormValues.duration;
        const copyOfEditedApptFormVals = {...editedApptFormValues};
        const copyOfApptFormValsDiffObj = {...apptFormValsDiff};
        const copyOfOrigApptAnimalRecs = [...origApptAnimalRecs];
        handleSubmitStart();

        // TODO - !!! START HERE FR, FR - VERIFY THESE ACTIONS,
        //  ESPECIALLY APPTANIMAL CREATE AND DELETE ON TASK ACTIONS
        //  SET SUCCESS, ERROR STATE VALS BASED ON RESULTS

        const deletedTasksAnimalIdsSet = new Set();

        const deleteTrimTaskModelRecIds = [];
        const deleteTrimTaskErrs = [];
        // first delete trim tasks
        const deletedTrimTaskFormVals = taskFormsDiffObj?.trim?.delete || [];
        if (deletedTrimTaskFormVals.length > 0) {
            for (const formUniqueId of deletedTrimTaskFormVals) {
                // make sure this form should be in this arr
                const deleteTaskFormToSkip = editedTrimTaskFormVals.find((taskForm) => {
                    return taskForm.formUniqueId === formUniqueId;
                });
                if (deleteTaskFormToSkip) {
                    console.error('TRIM TASK NOT DELETED, SHOULD NOT BE IN DELETE ARR - formUniqueId', formUniqueId);
                    continue;
                }
                const taskFormToDelete = origTrimTaskFormVals.find((taskForm) => {
                    return taskForm.formUniqueId === formUniqueId;
                });
                if (taskFormToDelete) {
                    try {
                        await deleteTrimTaskById(taskFormToDelete.taskRecID, showLogs);
                        console.warn('>>> DELETED TRIM TASK FORM taskFormToDelete', taskFormToDelete);
                        deleteTrimTaskModelRecIds.push(taskFormToDelete.taskRecID);
                        deletedTasksAnimalIdsSet.add(taskFormToDelete.animalID);

                    } catch (error) {
                        console.error('!!! Error deleting TRIM task form', error);
                        deleteTrimTaskErrs.push(error);
                    }
                } else {
                    console.error('!!! TRIM Task Form to delete not found', formUniqueId);
                }
            }
        }
        else {
            console.warn('NO TRIM RECS TO DELETE')
        }
        if (showLogs) {
            console.log('!!! deleteTrimTaskModelRecIds', deleteTrimTaskModelRecIds);
            console.log('!!! deleteTrimTaskErrs', deleteTrimTaskErrs);
        }

        const deleteShoeTaskModelRecIds = [];
        const deleteShoeTaskErrs = [];
        // first delete shoe tasks
        const deletedShoeTaskFormVals = taskFormsDiffObj?.shoe?.delete || [];
        if (deletedShoeTaskFormVals.length > 0) {
            for (const formUniqueId of deletedShoeTaskFormVals) {
                // make sure this form should be in this arr
                const deleteTaskFormToSkip = editedShoeTaskFormVals.find((taskForm) => {
                    return taskForm.formUniqueId === formUniqueId;
                });
                if (deleteTaskFormToSkip) {
                    console.error('SHOE TASK NOT DELETED, SHOULD NOT BE IN DELETE ARR - formUniqueId', formUniqueId);
                    continue;
                }
                const taskFormToDelete = origShoeTaskFormVals.find((taskForm) => {
                    return taskForm.formUniqueId === formUniqueId;
                });
                if (taskFormToDelete) {
                    try {
                        await deleteShoeTaskById(taskFormToDelete.taskRecID, showLogs);
                        console.warn('>>> DELETED SHOE TASK FORM taskFormToDelete', taskFormToDelete);
                        deleteShoeTaskModelRecIds.push(taskFormToDelete.taskRecID);
                        deletedTasksAnimalIdsSet.add(taskFormToDelete.animalID);
                    } catch (error) {
                        console.error('!!! Error deleting SHOE task form', error);
                        deleteShoeTaskErrs.push(error);
                    }
                } else {
                    console.error('!!! SHOE Task Form to delete not found', formUniqueId);
                }
            }
        }
        else {
            console.warn('NO SHOE RECS TO DELETE')
        }
        if (showLogs) {
            console.log('!!! deleteShoeTaskModelRecIds', deleteShoeTaskModelRecIds);
            console.log('!!! deleteShoeTaskErrs', deleteShoeTaskErrs);
        }

        const newTasksAnimalIdsSet = new Set();

        const newTrimTaskModelRecs = [];
        const newTrimTaskErrs = [];
        // next, create new shoe tasks
        const newTrimTaskFormVals = taskFormsDiffObj?.trim?.create || [];
        if (newTrimTaskFormVals.length > 0) {
            for (const newTrimTaskForm of newTrimTaskFormVals) {
                const taskFormToCreate = editedTrimTaskFormVals.find((taskForm) => {
                    return taskForm.formUniqueId === newTrimTaskForm.formUniqueId;
                });
                if (taskFormToCreate) {
                    console.warn('!!!-!!! taskFormToCreate', taskFormToCreate);
                    try {
                        const newTrimTaskRec = await createAppointmentTrimTask(taskFormToCreate.appointmentID, taskFormToCreate, showLogs);
                        console.warn('>>> CREATED NEW TRIM TASK REC - newTrimTaskRec', newTrimTaskRec);
                        newTrimTaskModelRecs.push(newTrimTaskRec);
                        newTasksAnimalIdsSet.add(taskFormToCreate.animalID);
                        if (deletedTasksAnimalIdsSet.has(taskFormToCreate.animalID)) {
                            deletedTasksAnimalIdsSet.delete(taskFormToCreate.animalID);
                        }
                    } catch (error) {
                        console.error('!!! Error creating trim task', error);
                        newTrimTaskErrs.push(error);
                    }
                } else {
                    // console.error('!!! Task Form to create not found', formUniqueId);
                    console.error('!!! Task Form to create not found', newTrimTaskForm);
                }
            }
        }
        else {
            console.warn('NO RECORDS TO CREATE');
        }
        if (showLogs) {
            console.log('!!! newTrimTaskModelRecs', newTrimTaskModelRecs);
            console.log('!!! newTrimTaskErrs', newTrimTaskErrs);
        }

        const newShoeTaskModelRecs = [];
        const newShoeTaskErrs = [];
        const newShoeTaskFormVals = taskFormsDiffObj?.shoe?.create || [];
        if (newShoeTaskFormVals.length > 0) {
            for (const newShoeTaskForm of newShoeTaskFormVals) {
                const taskFormToCreate = editedShoeTaskFormVals.find((taskForm) => {
                    return taskForm.formUniqueId === newShoeTaskForm.formUniqueId;
                });
                if (taskFormToCreate) {
                    console.warn('!!!-!!! SHOE taskFormToCreate', taskFormToCreate);
                    try {
                        const newShoeTaskRec = await createAppointmentShoeTask(taskFormToCreate.appointmentID, taskFormToCreate, showLogs);
                        console.warn('>>> CREATED NEW SHOE TASK REC - newShoeTaskRec', newShoeTaskRec);
                        newShoeTaskModelRecs.push(newShoeTaskRec);
                        newTasksAnimalIdsSet.add(taskFormToCreate.animalID);
                        if (deletedTasksAnimalIdsSet.has(taskFormToCreate.animalID)) {
                            deletedTasksAnimalIdsSet.delete(taskFormToCreate.animalID);
                        }
                    } catch (error) {
                        console.error('!!! Error creating shoe task', error);
                        newShoeTaskErrs.push(error);
                    }
                } else {
                    // console.error('!!! Task Form to create not found', formUniqueId);
                    console.error('!!! SHOE Task Form to create not found', newShoeTaskForm);
                }
            }
        }
        else {
            console.warn('NO SHOE RECORDS TO CREATE');
        }
        if (showLogs) {
            console.log('!!! newShoeTaskModelRecs', newShoeTaskModelRecs);
            console.log('!!! newShoeTaskErrs', newShoeTaskErrs);
        }

        if (newTasksAnimalIdsSet.size > 0) {
            // check if we need to create any new AppointmentAnimal recs, based on the set diff of existing and new animal ids
            const existingApptAnimalIdsSet = new Set(copyOfOrigApptAnimalRecs.map(rec => rec.animalId));
            console.warn('!!! existingApptAnimalIdsSet', existingApptAnimalIdsSet);
            const animalIdsSetDiff = new Set([...newTasksAnimalIdsSet].filter(x => !existingApptAnimalIdsSet.has(x)));
            console.warn('!!! animalIdsSetDiff', animalIdsSetDiff);
            if (animalIdsSetDiff.size > 0) {
                for (const animalId of animalIdsSetDiff) {
                    console.warn('>>>-!!! NEW APPT ANIMAL animalId', animalId);
                    const animalRec = clientAnimalRecs.find((rec) => {
                        return rec.id === animalId;
                    });
                    if (animalRec) {
                        console.warn('>>>-!!! matching client animalRec for NEW APPT ANIMAL', animalRec);
                        try {
                            const newApptAnimalRec = await createAppointmentAnimal(apptRec.id, animalRec.id, showLogs);
                            console.warn('>>> CREATED NEW APPT ANIMAL REC - newApptAnimalRec', newApptAnimalRec);
                        } catch (error) {
                            console.error('!!! Error creating appt animal rec for new task', error);
                        }
                    }
                    else {
                        console.error('!!! Animal rec to create new Appt Animal based on new task not found', animalId);
                    }
                }
            }
        }

        // TODO - !!! START HERE, FR, FR,
        //  VERIFY TASK ACTIONS ALL WORKING CORRECTLY, ? MOSTLY WORK AS EXPECTED

        // TODO - START HERE, FR FR,
        //  >>> DELETE DOES NOT TRACK ALL DELETED IDS CORRECTLY,
        //  MAKE SURE IT IS CHECKING ORIG RECS, NOT EDITED RECS
        //  >>> WE NEED TO RESET SOME STATE VALS OR UPDATE THEM SO AFTER FORM SUBMIT THERE IS NOT A DIFF IN TASK FORM UNIQUE IDS,
        //  NEED TO ADD HANDLING FOR SHOE TASKS NEXT,
        //  MAY NEED TO COLLECT NEW, UPDATED RECS AND SET PROVIDER STATE WITH THOSE,
        //  MAKE SURE AND REMOVE DELETED RECS FROM LOCAL STATE,
        //  >>> ADD TRY CATCHES TO UPDATE TASKS AND COLLECT ERRORS OR NEW MODEL RECS
        //
        //  ADD A CHECK IF ANY TRIM FORM VALS WERE ACTUALLY UPDATED, HANDLE THAT UPSTREAM,
        //  ? ADD UPDATES TO APPT DURATION, AMOUNT ON UPDATE SUCCESS,
        //  FIND PLACES TO DELETE OUTDATED UPDATE TASKS ACTIONS

        const updatedTasksTaskRecIdsSet = new Set();
        const updatedTasksAnimalIdsSet = new Set();

        const updatedTrimTaskModelRecs = [];
        const updatedTrimTaskErrs = [];
        // next update existing trim tasks
        const updatedTrimTaskFormVals = taskFormsDiffObj?.trim?.update || [];
        if (updatedTrimTaskFormVals.length > 0) {
            for (const updatedForm of updatedTrimTaskFormVals) {
                const updatedFormUniqueId = updatedForm.formUniqueId;
                if (updatedFormUniqueId) {
                    if (deletedTrimTaskFormVals.length > 0) {
                        const deletedTaskForm = deletedTrimTaskFormVals.find((taskForm) => {
                            return taskForm.formUniqueId === updatedFormUniqueId;
                        });
                        if (deletedTaskForm > -1) {
                            // if the form was edited but also deleted, skip updating the form
                            console.log('!!! Task Form was deleted, skipping update, task rec id', updatedForm.taskRecID);
                            continue;
                        }
                    }
                    if (newTrimTaskFormVals.length > 0) {
                        const newTaskForm = newTrimTaskFormVals.find((taskForm) => {
                            return taskForm.formUniqueId === updatedFormUniqueId;
                        });
                        if (newTaskForm) {
                            // if the form was a new task already created, but also updated, skip updating the form
                            console.log('!!! Task Form was already created, skipping update', newTaskForm.formUniqueId);
                            continue;
                        }
                    }
                    try {
                        const updatedModelRec = await updateAppointmentTrimTask(updatedForm.taskRecID, updatedForm, showLogs);
                        console.warn('>>> UPDATED TRIM TASK REC - updatedModelRec', updatedModelRec);
                        updatedTrimTaskModelRecs.push(updatedModelRec);
                        updatedTasksTaskRecIdsSet.add(updatedModelRec.id);
                        updatedTasksAnimalIdsSet.add(updatedForm.animalID);
                    } catch (error) {
                        console.error('!!! Error updating task form', error);
                        updatedTrimTaskErrs.push(error);
                    }
                } else {
                    console.error('!!! Task Form to update not found, form unique id', updatedForm.taskRecID);
                }
            }
        }
        else {
            console.warn('NO TRIM RECORDS TO UPDATE');
        }
        if (showLogs) {
            console.log('!!! updatedTrimTaskModelRecs', updatedTrimTaskModelRecs);
            console.log('!!! updatedTrimTaskErrs', updatedTrimTaskErrs);
        }

        const updatedShoeTaskModelRecs = [];
        const updatedShoeTaskErrs = [];
        // next update existing trim tasks
        const updatedShoeTaskFormVals = taskFormsDiffObj?.shoe?.update || [];
        if (updatedShoeTaskFormVals.length > 0) {
            for (const updatedForm of updatedShoeTaskFormVals) {
                const updatedFormUniqueId = updatedForm.formUniqueId;
                if (updatedFormUniqueId) {
                    if (deletedShoeTaskFormVals.length > 0) {
                        const deletedTaskForm = deletedShoeTaskFormVals.find((taskForm) => {
                            return taskForm.formUniqueId === updatedFormUniqueId;
                        });
                        if (deletedTaskForm > -1) {
                            // if the form was edited but also deleted, skip updating the form
                            console.log('!!! SHOE Task Form was deleted, skipping update, task rec id', updatedForm.taskRecID);
                            continue;
                        }
                    }
                    if (newShoeTaskFormVals.length > 0) {
                        const newTaskForm = newShoeTaskFormVals.find((taskForm) => {
                            return taskForm.formUniqueId === updatedFormUniqueId;
                        });
                        if (newTaskForm) {
                            // if the form was a new task already created, but also updated, skip updating the form
                            console.log('!!! SHOE Task Form was already created, skipping update', newTaskForm.formUniqueId);
                            continue;
                        }
                    }
                    try {
                        const updatedModelRec = await updateAppointmentShoeTask(updatedForm.taskRecID, updatedForm, showLogs);
                        console.warn('>>> UPDATED SHOE TASK REC - updatedModelRec', updatedModelRec);
                        updatedShoeTaskModelRecs.push(updatedModelRec);
                        updatedTasksTaskRecIdsSet.add(updatedModelRec.id);
                        updatedTasksAnimalIdsSet.add(updatedForm.animalID);
                    } catch (error) {
                        console.error('!!! Error updating SHOE task form', error);
                        updatedShoeTaskErrs.push(error);
                    }
                } else {
                    console.error('!!! SHOE Task Form to update not found, form unique id', updatedForm.taskRecID);
                }
            }
        }
        else {
            console.warn('NO SHOE RECORDS TO UPDATE');
        }
        if (showLogs) {
            console.log('!!! updatedShoeTaskModelRecs', updatedShoeTaskModelRecs);
            console.log('!!! updatedShoeTaskErrs', updatedShoeTaskErrs);
        }

        // TODO - START HERE, MAKE SURE APPTANIMALS THAT MOVE FROM ONE TASK FORM TO ANOTHER DON'T DELETE TE APPTANIMAL REC
        if (deleteTrimTaskModelRecIds.length > 0 || deleteShoeTaskModelRecIds.length > 0) {
            // const deletedFormIds = deleteTrimTaskModelRecIds.concat(deleteShoeTaskModelRecIds);
            // const combinedTaskActionAnimalIdsSet = newTasksAnimalIdsSet.add(updatedTasksAnimalIdsSet.values());
            const combinedTaskActionAnimalIdsSet = new Set([...newTasksAnimalIdsSet, ...updatedTasksAnimalIdsSet]);
            console.warn('!!! combinedTaskActionAnimalIdsSet', combinedTaskActionAnimalIdsSet);
            const deletedTaskRecIds = deleteTrimTaskModelRecIds.concat(deleteShoeTaskModelRecIds);
            const deletedTaskRecIdsSet = new Set(deletedTaskRecIds);
            const deletedTaskRecIdsArr = Array.from(deletedTaskRecIdsSet);
            // console.warn('@@@-!!! deletedFormIds', deletedFormIds);
            // console.warn('@@@-!!! deletedTaskRecIds', deletedTaskRecIds);
            // console.warn('@@@-!!! deletedTaskRecIdsSet', deletedTaskRecIdsSet);
            console.warn('@@@-!!! deletedTaskRecIdsArr', deletedTaskRecIdsArr);
            for (const taskRecId of deletedTaskRecIdsArr) {
                console.warn('@@@-!!! taskRecId', taskRecId);
                // Delete related AppointmentAnimal records
                const deleteApptAnimalShoeTaskForm = copyOfOrigShoeTaskFormVals.find((taskForm) => taskForm.taskRecID === taskRecId);
                console.warn('!!!-!!! deleteApptAnimalShoeTaskForm', deleteApptAnimalShoeTaskForm);
                const deleteApptAnimalTrimTaskForm = copyOfOrigTrimTaskFormVals.find((taskForm) => taskForm.taskRecID === taskRecId);
                console.warn('!!!-!!! deleteApptAnimalTrimTaskForm', deleteApptAnimalTrimTaskForm);

                // if (deleteApptAnimalShoeTaskForm && deleteApptAnimalShoeTaskForm?.animalID in updatedTasksAnimalIdsSet) {
                // if (deleteApptAnimalShoeTaskForm && deleteApptAnimalShoeTaskForm?.animalID in combinedTaskActionAnimalIdsSet) {
                if (deleteApptAnimalShoeTaskForm
                    && deleteApptAnimalShoeTaskForm?.animalID
                    && combinedTaskActionAnimalIdsSet.has(deleteApptAnimalShoeTaskForm.animalID)) {
                    // if the animal is in the updated tasks, skip deleting the appt animal rec
                    console.warn('>!!! Animal is in updated SHOE tasks, skipping delete appt animal rec - CONTINUE', deleteApptAnimalShoeTaskForm.animalID);
                    continue;
                }
                // if (deleteApptAnimalTrimTaskForm && deleteApptAnimalTrimTaskForm?.animalID in updatedTasksAnimalIdsSet) {
                // if (deleteApptAnimalTrimTaskForm && deleteApptAnimalTrimTaskForm?.animalID in combinedTaskActionAnimalIdsSet) {
                if (deleteApptAnimalTrimTaskForm
                    && deleteApptAnimalTrimTaskForm?.animalID
                    && combinedTaskActionAnimalIdsSet.has(deleteApptAnimalTrimTaskForm.animalID)) {
                    // if the animal is in the updated tasks, skip deleting the appt animal rec
                    console.warn('>!!! Animal is in updated TRIM tasks, skipping delete appt animal rec - CONTINUE', deleteApptAnimalTrimTaskForm.animalID);
                    continue;
                }

                if (deleteApptAnimalShoeTaskForm) {
                    // now get the apptAnimalRec from origApptAnimalRecs, filtering by appt id and animal id
                    const matchingApptAnimalRec = copyOfOrigApptAnimalRecs.find((apptAnimalRec) => {
                       return apptAnimalRec.appointmentId === deleteApptAnimalShoeTaskForm.appointmentID && apptAnimalRec.animalId === deleteApptAnimalShoeTaskForm.animalID;
                    });
                    console.warn('@@@>>>!!! deleteApptAnimalShoeTask matchingApptAnimalRec', matchingApptAnimalRec);
                    if (matchingApptAnimalRec) {
                        // proceed with the ApptAnimal delete
                        const deletedApptAnimalRecRes = deleteAppointmentAnimalRecById(matchingApptAnimalRec.id, showLogs);
                        if (showLogs) {
                            console.log('!!! SHOE TASK deletedApptAnimalRecRes', deletedApptAnimalRecRes);
                        }
                    }
                }
                else {
                    if (deleteApptAnimalTrimTaskForm) {
                        // now get the apptAnimalRec from origApptAnimalRecs, filtering by appt id and animal id
                        const matchingApptAnimalRec = copyOfOrigApptAnimalRecs.find((apptAnimalRec) => {
                            return apptAnimalRec.appointmentId === deleteApptAnimalTrimTaskForm.appointmentID && apptAnimalRec.animalId === deleteApptAnimalTrimTaskForm.animalID;
                        });
                        console.warn('@@@>>>!!! deleteApptAnimalTrimTask matchingApptAnimalRec', matchingApptAnimalRec);
                        if (matchingApptAnimalRec) {
                            // proceed with the ApptAnimal delete
                            const deletedApptAnimalRecRes = deleteAppointmentAnimalRecById(matchingApptAnimalRec.id, showLogs);
                            if (showLogs) {
                                console.log('!!! TRIM TASK deletedApptAnimalRecRes', deletedApptAnimalRecRes);
                            }
                        }
                    }
                    else {
                        console.warn('!!! APPTANIMAL to delete not found for task rec id', taskRecId);
                    }
                }
            }
        }
        else {
            console.warn('!!! NO APPT ANIMALS TO DELETE')
        }

        // if (apptFormValsChanged || apptNoteFormValChanged || apptTasksValsChanged) {
        //     // if (apptFormValsChanged) {
        //     //     setShowUpdateApptModal(true);
        //     // }
        //     setShowUpdateNoteTasksApptModal(true);
        // }

        // if (apptFormValsChanged || apptNoteFormValChanged) {
        //     // if (apptFormValsChanged) {
        //     //     setShowUpdateApptModal(true);
        //     // }
        //     setShowUpdateNoteTasksApptModal(true);
        // }
        //     // else if (apptNoteFormValChanged) {
        //     //     setShowUpdateApptNoteModal(true);
        // // }
        // else {
        //     alert('No changes were made to the appointment record.');
        // }

        // if (apptFormValsChanged) {
        //     setShowUpdateApptModal(true);
        // } else if (apptNoteFormValChanged) {
        //     setShowUpdateApptNoteModal(true);
        // }
        // else {
        //     alert('No changes were made to the appointment record.');
        // }

        // TODO - SUM APPT AMT, DURATION BASED ON SUCCESSFUL TASK UPDATES,
        //  UPDATE APPT REC IF CHANGED

        let saveUpdatedApptAmt = false;
        let saveUpdatedApptDur = false;
        if (deleteTrimTaskModelRecIds.length > 0
            || deleteShoeTaskModelRecIds.length > 0
            || newTrimTaskModelRecs.length > 0
            || newShoeTaskModelRecs.length > 0
            || updatedTrimTaskModelRecs.length > 0
            || updatedShoeTaskModelRecs.length > 0) {
            // TODO - HANDLE ERRS IN UPDATE ACTIONS, ACCOUNT FOR THOSE IN TOTALS
            const newApptTotalAmt = calculateTotalApptAmount(copyOfEditedTrimTaskFormVals, copyOfEditedShoeTaskFormVals);
            console.warn('!!! newApptTotalAmt', newApptTotalAmt);
            const newApptTotalDuration = calculateTotalApptDuration(copyOfEditedTrimTaskFormVals, copyOfEditedShoeTaskFormVals);
            console.warn('!!! newApptTotalDuration', newApptTotalDuration);
            if (newApptTotalAmt !== copyOfEditedApptFormAmt) {
                // setEditedApptFormValues({...editedApptFormValues, amount: newApptTotalAmt});
                // setApptAmountChanged(true);
                copyOfEditedApptFormVals.amount = newApptTotalAmt;
                copyOfApptFormValsDiffObj.amount = newApptTotalAmt;
                saveUpdatedApptAmt = true;
            }
            if (newApptTotalDuration !== copyOfEditedApptFormDur) {
                // setEditedApptFormValues({...editedApptFormValues, duration: newApptTotalDuration});
                // setApptDurationChanged(true);
                copyOfEditedApptFormVals.duration = newApptTotalDuration;
                copyOfApptFormValsDiffObj.duration = newApptTotalDuration;
                copyOfApptFormValsDiffObj.apptDuration = newApptTotalDuration;
                saveUpdatedApptDur = true;
            }
        }

        if (saveUpdatedApptAmt || saveUpdatedApptDur) {
            console.log('!!! saveUpdatedApptAmt', saveUpdatedApptAmt);
            console.log('!!! saveUpdatedApptDur', saveUpdatedApptDur);
            console.log('!!! copyOfApptFormValsDiffObj', copyOfApptFormValsDiffObj);
            // let showNoChangeAlert = showAlert;
            let updateApptRes;
            // if (editedApptFormValues && apptFormValsChanged) {
            // if (copyOfEditedApptFormVals && copyOfApptFormValsDiffObj.length > 0) {
            if (copyOfEditedApptFormVals && copyOfApptFormValsDiffObj) {
                // updateApptRes = await updateAppointment(apptRec.id, apptFormValsDiff, showLogs);
                try {
                    copyOfApptFormValsDiffObj.isEdited = true;
                    copyOfApptFormValsDiffObj.lastEditedAt = getNowAsAWSDateTime();
                    updateApptRes = await updateAppointment(apptRec.id, copyOfApptFormValsDiffObj, showLogs);
                    if (updateApptRes) {
                        console.log('!!! updateApptRes', updateApptRes);
                    }
                } catch (error) {
                    console.error('!!! Error updating appointment', error);
                }
            }
        }


        // resetFormValues();
        setResetStateVals(true);
        handleSubmitEnd();
        setUpdateTaskRecsDone(true);
        setTimeout(() => {
            console.warn('FORM SUBMIT DONE!');
            setShowUpdatingIndicator(false);
            navigate('/appointments');
        }, 1500);
        // navigate('/appointments');
    }

    // TODO - ? SHOULD APPT AMOUNT,
    //  DURATION BE UPDATED HERE OR ONLY IN THE UPDATE TASKS FUNCTION

    // TODO - !!! - UPDATE HANDLING FOR UPDATE APPT NOTE MODAL, WILL BREAK NOW

    // TODO - REPLACE WITH BOX COMPONENT FOR UNIFORMITY?,
    //  !!! -IMPROVE UI FOR SETTING DATE TIME,
    //  MAY WANT TO CREATE A DEDICATED COMPONENT FOR THAT,
    //  CLEAN UP THIS FORM

    return (
        <div style={{
            backgroundColor: 'lightgray',
            // backgroundColor: 'red',
            minHeight: '15vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 'calc(10px + 2vmin)',
            // color: 'white',
            color: 'black',
            // marginBottom: '5vh'
            marginTop: '0.5vh',
            // border: '1px solid black',
            borderTop: '1px solid black',
            borderBottom: '1px solid black',
            paddingTop: '1.5vh',
            paddingBottom: '1.5vh'
        }}>
            {showUpdatingIndicator ? <UpdatingIndicator message={"Updating Appointment"}/> : <div>
                <Divider />
                <div>
                    <FormControl sx={{m: 1, width: 300}}>
                        <InputLabel id={`multiple-chip-label-shoe-animal-names`}>Shoe Task Animals</InputLabel>
                        <Select
                            labelId={`multiple-chip-label-shoe-animal-names`}
                            id={`multiple-chip-shoe-animal-names`}
                            multiple
                            // disabled={selectedClientName === ''}
                            value={editedSelectedShoeAnimalNames || []}
                            onChange={handleSelectedShoeAnimalNamesChange}
                            input={<OutlinedInput id={`select-multiple-chip-shoe-animal-names`}
                                                  label={`Chip-animal-names`}/>}
                            renderValue={(selected) => (
                                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                    {selected.map((value) => (
                                        <Chip key={generateUniqueKey(value)} label={value}/>
                                    ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            {clientAnimalRecs.map((eachRec) => (
                                <MenuItem
                                    key={generateUniqueKey(eachRec.name)}
                                    value={eachRec.name}
                                    style={getStyles(eachRec.name, personName, theme)}
                                >
                                    {eachRec.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div>
                    <BaseStyledButton
                        disabled={editedSelectedShoeAnimalNames.length === 0}
                        // onClick={handleShoeTaskOpen}
                        onClick={() => handleShoeTaskOpen(editedSelectedShoeAnimalRecs, setShoeTaskModalOpen, setUpdatedShoeTaskFormUniqueId)}
                        label="Edit Shoe Tasks"
                    />
                </div>
                <Divider/>
                <div>
                    <FormControl sx={{m: 1, width: 300}}>
                        <InputLabel id={`multiple-chip-label-trim-animal-names`}>Trim Task Animals</InputLabel>
                        <Select
                            labelId={`multiple-chip-label-trim-animal-names`}
                            id={`multiple-chip-trim-animal-names`}
                            multiple
                            // disabled={selectedClientName === ''}
                            value={editedSelectedTrimAnimalNames || []}
                            onChange={handleSelectedTrimAnimalNamesChange}
                            input={<OutlinedInput id={`select-multiple-chip-trim-animal-names`}
                                                  label={`Chip-animal-names`}/>}
                            renderValue={(selected) => (
                                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                    {selected.map((value) => (
                                        <Chip key={generateUniqueKey(value)} label={value}/>
                                    ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            {clientAnimalRecs.map((eachRec) => (
                                <MenuItem
                                    key={generateUniqueKey(eachRec.name)}
                                    value={eachRec.name}
                                    style={getStyles(eachRec.name, personName, theme)}
                                >
                                    {eachRec.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div>
                    <BaseStyledButton
                        disabled={editedSelectedTrimAnimalNames.length === 0}
                        // onClick={handleTrimTaskOpen}
                        onClick={() => handleTrimTaskOpen(editedSelectedTrimAnimalRecs, setTrimTaskModalOpen, setUpdatedTrimTaskFormUniqueId)}
                        label="Edit Trim Tasks"
                    />
                </div>
                <Divider/>
                <div>
                    <FormControl sx={{m: 1, width: 300}}>
                        <InputLabel id={`edit-appt-appt-status-input-label`}>Appt. Status</InputLabel>
                        <Select
                            labelId={`edit-appt-appt-status-label-id`}
                            id={`edit-appt-appt-status-select`}
                            value={editedSelectedApptStatus || ''}
                            onChange={(event) => {
                                setEditedSelectedApptStatus(event.target.value);
                                setEditedApptFormValues({...editedApptFormValues, appointmentStatus: event.target.value})
                            }}
                            MenuProps={MenuProps}
                        >
                            {Object.entries(apptStatusEnums).map(([key, val]) => (
                                <MenuItem
                                    key={generateUniqueKey(key)}  // Use the key for generating a unique key
                                    value={val}  // Use the key as the value for the MenuItem
                                >
                                    {val}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <Divider/>
                <div>
                    <StyledTextField
                        id="street-address"
                        label="Street Address"
                        value={editedApptFormValues.streetAddress || ''}
                        onChange={(event) => updateEditedApptFormValues('streetAddress', event.target.value)}
                    />
                </div>
                <div>
                    <StyledTextField
                        id="city"
                        label="City"
                        value={editedApptFormValues.city || ''}
                        onChange={(event) => updateEditedApptFormValues('city', event.target.value)}
                    />
                </div>
                <div>
                    <StyledTextField
                        id="state"
                        label="State"
                        value={editedApptFormValues.state || ''}
                        onChange={(event) => updateEditedApptFormValues('state', event.target.value)}
                    />
                </div>
                <div>
                    <StyledTextField
                        id="zip-code"
                        label="Zip Code"
                        value={editedApptFormValues.zipCode || ''}
                        onChange={(event) => updateEditedApptFormValues('zipCode', event.target.value)}
                    />
                </div>
                <div>
                    <StyledTextField
                        id="amount"
                        label="Amount ($)"
                        type="number"
                        value={editedTotalApptAmount || 0}
                        onChange={(event) => {
                            const amountVal = textFieldValToInteger(event.target.value);
                            setEditedTotalApptAmount(amountVal);
                        }}
                        InputProps={{
                            readOnly: true,
                        }}
                        helperText="Based on total tasks amount."
                    />
                </div>
                <div>
                    <StyledTextField
                        id="duration"
                        label="Duration (mins.)"
                        type="number"
                        value={editedTotalApptDuration || 0}
                        onChange={(event) => {
                            const durationVal = textFieldValToInteger(event.target.value);
                            setEditedTotalApptDuration(durationVal);
                        }}
                        InputProps={{
                            readOnly: true,
                        }}
                        helperText="Based on total tasks duration."
                    />
                </div>
                <div>
                    <StyledTextField
                        id="gate-code"
                        label="Gate Code"
                        value={editedApptFormValues.gateCode || ''}
                        onChange={(event) => updateEditedApptFormValues('gateCode', event.target.value)}
                    />
                </div>
                <div>
                    <StyledNoteTextField
                        id="appt-note"
                        label="Appt. Note"
                        value={editedApptNoteFormValues.note || ''}
                        // onChange={updateEditedApptNoteFormValue}
                    />
                </div>
                <Divider>
                    <Chip label="Appt. Date"/>
                </Divider>
                <div>
                    <DateTimePicker
                        value={editedApptDateTime}
                        onChange={(newValue) => handleApptDateTimeChange(newValue)}
                    />
                </div>
                {editedApptDateTime ? (<div>
                    <Typography>
                        Appt. Date: {editedApptDateTime.format('YYYY-MM-DD')}
                    </Typography>
                </div>) : null}
                {editedApptDateTime ? (<div>
                    <Typography>
                        Appt. Time: {editedApptDateTime.format('HH:mm')}
                    </Typography>
                </div>) : null}
                <div>
                    <BaseStyledButton
                        label="Update Appointment"
                        onClick={onUpdateApptRec}
                    />
                </div>
                <div>
                    {shoeTaskModalOpen && (
                        <UpdateShoeTaskModal
                            open={shoeTaskModalOpen}
                            onClose={handleShoeTaskClose}
                            formData={editedShoeTaskFormVals[currentShoeTaskAnimalIndex]}
                            // handleFormChange={handleShoeTaskUpdate}
                            handleSaveAndUpdateNext={handleShoeSaveAndUpdateNext}
                            handleSaveAndUpdatePrev={handleShoeSaveAndUpdatePrev}
                            showLogs={showLogs}
                            formIndex={currentShoeTaskAnimalIndex}
                            editedShoeTaskFormVals={editedShoeTaskFormVals}
                            setEditedShoeTaskFormVals={setEditedShoeTaskFormVals}
                            setUpdatedShoeTaskFormUniqueId={setUpdatedShoeTaskFormUniqueId}
                            setShoeTaskModalOpen={setShoeTaskModalOpen}
                            currentShoeTaskAnimalIndex={currentShoeTaskAnimalIndex}
                            editedSelectedShoeAnimalRecs={editedSelectedShoeAnimalRecs}
                            setCurrentShoeTaskAnimalIndex={setCurrentShoeTaskAnimalIndex}
                        />
                    )}
                </div>
                <div>
                    {trimTaskModalOpen && (
                        <UpdateTrimTaskModal
                            open={trimTaskModalOpen}
                            onClose={handleTrimTaskClose}
                            // onClose={() => handleTrimTaskClose(currentTrimTaskAnimalIndex, )}
                            formData={editedTrimTaskFormVals[currentTrimTaskAnimalIndex]}
                            handleSaveAndUpdateNext={handleTrimSaveAndUpdateNext}
                            handleSaveAndUpdatePrev={handleTrimSaveAndUpdatePrev}
                            showLogs={showLogs}
                            formIndex={currentTrimTaskAnimalIndex}
                            editedTrimTaskFormVals={editedTrimTaskFormVals}
                            setEditedTrimTaskFormVals={setEditedTrimTaskFormVals}
                            setUpdatedTrimTaskFormUniqueId={setUpdatedTrimTaskFormUniqueId}
                            setTrimTaskModalOpen={setTrimTaskModalOpen}
                            currentTrimTaskAnimalIndex={currentTrimTaskAnimalIndex}
                            editedSelectedTrimAnimalRecs={editedSelectedTrimAnimalRecs}
                            setCurrentTrimTaskAnimalIndex={setCurrentTrimTaskAnimalIndex}
                        />
                    )}
                </div>
                <div>
                    {/*{apptRec && showUpdateApptModal && apptFormValsChanged ? (*/}
                    {/*    <UpdateApptConfirmationDialog*/}
                    {/*        open={showUpdateApptModal}*/}
                    {/*        handleClose={handleUpdateApptModalClose}*/}
                    {/*        handleConfirm={handleUpdateApptConfirm}*/}
                    {/*        updateSuccessMsg={updateSuccessMsg}*/}
                    {/*        updateErrorMsg={updateErrorMsg}*/}
                    {/*        editedApptFormValues={editedApptFormValues}*/}
                    {/*        editedApptNoteFormValues={editedApptNoteFormValues}*/}
                    {/*        editedTasksForAnimals={editedTasksForAnimals}*/}
                    {/*        apptFormValsDiff={apptFormValsDiff}*/}
                    {/*        apptNoteFormValChanged={apptNoteFormValChanged}*/}
                    {/*        apptTasksValsChanged={apptTasksValsChanged}*/}
                    {/*        updateRecApptSuccess={updateRecApptSuccess}*/}
                    {/*        showLogs={showLogs}*/}
                    {/*    />*/}
                    {/*) : null}*/}
                </div>
                {/*<div>*/}
                {/*    {apptRec && showUpdateNoteTasksApptModal ? (*/}
                {/*        <ConfirmUpdateNoteTasksApptDialog*/}
                {/*            open={showUpdateNoteTasksApptModal}*/}
                {/*            handleClose={handleUpdateNoteTasksApptModalClose}*/}
                {/*            handleConfirm={handleUpdateNoteTasksApptConfirm}*/}
                {/*            updateSuccessMsg={updateSuccessMsg}*/}
                {/*            updateErrorMsg={updateErrorMsg}*/}
                {/*            editedApptFormValues={editedApptFormValues}*/}
                {/*            editedApptNoteFormValues={editedApptNoteFormValues}*/}
                {/*            editedTasksForAnimals={editedTasksForAnimals}*/}
                {/*            apptFormValsDiff={apptFormValsDiff}*/}
                {/*            apptNoteFormValChanged={apptNoteFormValChanged}*/}
                {/*            apptTasksValsChanged={apptTasksValsChanged}*/}
                {/*            updateRecApptSuccess={updateRecApptSuccess}*/}
                {/*            showLogs={showLogs}*/}
                {/*        />*/}
                {/*    ) : null}*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    {apptRec && showUpdateApptNoteModal && apptNoteFormValChanged ? (*/}
                {/*        <UpdateApptNoteConfirmationDialog*/}
                {/*            open={showUpdateApptNoteModal}*/}
                {/*            handleClose={handleUpdateApptNoteModalClose}*/}
                {/*            handleConfirm={handleUpdateApptNoteConfirm}*/}
                {/*            updateSuccessMsg={updateSuccessMsg}*/}
                {/*            updateErrorMsg={updateErrorMsg}*/}
                {/*            editedApptNoteFormValues={editedApptNoteFormValues}*/}
                {/*            apptNoteFormValChanged={apptNoteFormValChanged}*/}
                {/*            updateRecSuccess={updateRecSuccess}*/}
                {/*            showLogs={showLogs}*/}
                {/*        />*/}
                {/*    ) : null}*/}
                {/*</div>*/}
                <div>
                    {apptRec ? (
                        <div>
                            {modelRecToDelete ? (
                                <div>
                                    <DeleteApptModal
                                        open={showDeleteApptModal}
                                        handleClose={onDeleteApptModalClose}
                                        handleConfirm={onDeleteApptConfirm}
                                        handleCancel={onDeleteApptCancel}
                                        modelRecToDelete={modelRecToDelete}
                                        showLogs={showLogs}
                                        clientRec={apptClientRec}
                                    />
                                </div>
                            ) : null}
                            {apptNoteToDelete ? (
                                <div>
                                    <DeleteApptNoteConfirmationDialog
                                        open={showDeleteApptNoteModal}
                                        handleClose={handleDeleteApptNoteModalClose}
                                        handleConfirm={handleDeleteApptNoteConfirm}
                                        modelRecToDelete={apptNoteToDelete}
                                        showLogs={showLogs}
                                        // animalRec={animalRec}
                                        apptRec={apptRec}
                                    />
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                </div>
            </div>}
        </div>
    )
}
