import React, {useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import {FormGroup} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { softDeleteAnimalById, softDeleteAnimalRelatedRecsByRecType } from "../../../data/delete/softDeleteRecs";
import CircularProgress from "@mui/material/CircularProgress";

// NEWER DELETE ANIMAL DIALOG, TRY TO REPLACE THE OLD ONE - DeleteAnimalConfirmationDialog

export default function ConfirmDeleteAnimalDialog({ openProp,
                                                      ampUser,
                                                      handleCloseProp,
                                                      modelRecToDelete,
                                                      showLogs, ...props}) {
    const [open, setOpen] = useState(false);
    const [confirmStep, setConfirmStep] = useState(1);
    const [pauseOnFormSubmit, setPauseOnFormSubmit] = useState(false);
    const [deleteAnimalResObj, setDeleteAnimalResObj] = useState(null);
    const [deleteRelatedRecsResObj, setDeleteRelatedRecsResObj] = useState(null);
    const [selectedTypes, setSelectedTypes] = useState({
        appointments: false,
        shoePackages: false,
        shoeings: false,
        trims: false,
        animalNotes: false,
    });
    const [isDeleting, setIsDeleting] = useState(false);
    const [deleteError, setDeleteError] = useState(null);

    useEffect(() => {
        if (showLogs) {
            console.log('### ConfirmDeleteAnimalDialog pauseOnFormSubmit', pauseOnFormSubmit);
            console.log('### ConfirmDeleteAnimalDialog deleteAnimalResObj', deleteAnimalResObj);
            console.log('### ConfirmDeleteAnimalDialog deleteRelatedRecsResObj', deleteRelatedRecsResObj);
        }
    }, [showLogs, deleteAnimalResObj, deleteRelatedRecsResObj, pauseOnFormSubmit]);

    // useEffect( if (openProp) { setOpen(true);
    useEffect(() => {
        if (openProp) {
            setOpen(true);
        }
    }, [openProp]);

    const handleClose = () => {
        if (showLogs) console.log('### ConfirmDeleteAnimalDialog handleClose');
        // setOpen(false);
        if (handleCloseProp) {
            if (!pauseOnFormSubmit) {
                handleCloseProp();
            }
            // props.handleCloseProp();
        }
    }

    const handleConfirmDelete = async (typesToDelete) => {
        if (showLogs) console.log('### ConfirmDeleteAnimalDialog handleConfirmDelete');
        if (showLogs) console.log('### ConfirmDeleteAnimalDialog handleConfirmDelete typesToDelete', typesToDelete);
        // TODO - Add delete animal logic here
        setPauseOnFormSubmit(true);
        const deleteAnimalRes = await softDeleteAnimalById(modelRecToDelete.id, showLogs);
        setDeleteAnimalResObj(deleteAnimalRes);
        if (typesToDelete.length > 0) {
            const deleteRelatedRecsRes = await softDeleteAnimalRelatedRecsByRecType(modelRecToDelete.id, typesToDelete, showLogs);
            setDeleteRelatedRecsResObj(deleteRelatedRecsRes);
        }
    }

    const handleTypeChange = (event) => {
        setSelectedTypes({
            ...selectedTypes,
            [event.target.name]: event.target.checked,
        });
    };

    const handleInitialConfirm = () => {
        setConfirmStep(2);
    };

    const handleSecondConfirm = () => {
        setConfirmStep(3);
    };

    const handleFinalConfirm = async () => {
        const typesToDelete = Object.keys(selectedTypes).filter(
            (type) => selectedTypes[type]
        );
        try {
            setIsDeleting(true);
            setDeleteError(null);
            // onConfirm(typesToDelete);
            await handleConfirmDelete(typesToDelete);
            setConfirmStep(4);
            // onClose();
            setPauseOnFormSubmit(false);
        }
        catch (error) {
            setDeleteError(error);
        }
        finally {
            setIsDeleting(false);
            // handleClose();
        }
        // handleClose();
    };

    const handleBack = () => {
        setConfirmStep(2);
    };

    const renderStep1 = () => (
        <>
            <DialogTitle>Confirm Delete Animal Record</DialogTitle>
            <DialogContent>
                <p>Are you sure you want to delete this animal record?</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleInitialConfirm} color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </>
    );

    const renderStep2 = () => (
        <>
            <DialogTitle>Select Related Records to Delete</DialogTitle>
            <DialogContent>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={selectedTypes.appointments} onChange={handleTypeChange} name="appointments" />}
                        label="Appointments"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={selectedTypes.shoePackages} onChange={handleTypeChange} name="shoePackages" />}
                        label="Shoe Packages"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={selectedTypes.shoeings} onChange={handleTypeChange} name="shoeings" />}
                        label="Shoeings"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={selectedTypes.trims} onChange={handleTypeChange} name="trims" />}
                        label="Trims"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={selectedTypes.animalNotes} onChange={handleTypeChange} name="animalNotes" />}
                        label="Animal Notes"
                    />
                </FormGroup>
            </DialogContent>
            <DialogActions>
                {/*<Button onClick={onClose}>Cancel</Button>*/}
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSecondConfirm} color="primary">
                    Delete Selected
                </Button>
            </DialogActions>
        </>
    );

    const renderStep3 = () => {
        const typesToDelete = Object.keys(selectedTypes).filter(
            (type) => selectedTypes[type]
        );

        return (
            <>
                <DialogTitle>Final Confirmation</DialogTitle>
                <DialogContent>
                    <p>You are about to delete the following:</p>
                    <List>
                        <ListItem>
                            <ListItemText primary={`Animal record for ${modelRecToDelete.name}`} />
                        </ListItem>
                        {typesToDelete.map((type) => (
                            <ListItem key={type}>
                                <ListItemText primary={type.charAt(0).toUpperCase() + type.slice(1)} />
                            </ListItem>
                        ))}
                    </List>
                    <p>Are you sure you want to proceed?</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleBack} color="secondary">
                        Back
                    </Button>
                    <Button onClick={async () => await handleFinalConfirm()} color="primary" disabled={isDeleting}>
                        {isDeleting ? <CircularProgress size={24} /> : 'Confirm Deletion'}
                    </Button>
                </DialogActions>
            </>
        );
    };

    const renderStep4 = () => (
        <>
            <DialogTitle>Deletion Confirmation</DialogTitle>
            <DialogContent>
                {deleteError ? (
                    <p>Error: {deleteError}</p>
                ) : (
                    <p>The animal record and selected related records have been successfully deleted.</p>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </>
    );

    return (
        <Dialog open={open} onClose={handleClose}>
            {confirmStep === 1 ? renderStep1() :
                confirmStep === 2 ? renderStep2() :
                    confirmStep === 3 ? renderStep3() :
                        renderStep4()}
        </Dialog>
    );
}
