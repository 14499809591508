import React, { useState, useEffect } from 'react';
import { textFieldValToIntegerHandleNaN } from "../../../utils/text";
import { generateUniqueKey } from "../../../utils/helpers";
import { convertToFullIntegerHandleNaN } from "../../../utils/numbers";
import { editClientFormObj, clientFormsTooltipTextsObj } from "../../../utils/formObjects";
import UpdatingIndicator from "../../progress/UpdatingIndicator";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import StyledTextField from "../../text/StyledTextField";
import BaseStyledButton from "../../buttons/BaseStyledButton";
import Divider from "@mui/material/Divider";
import { ClientStatus } from "../../../models";
import Typography from "@mui/material/Typography";
import StyledCardContentTypography from "../../text/StyledCardContentTypography";
import { useConsoleLog, useConsoleWarn } from "../../hooks/logging/console";
import AllClientApptsAccordions from "../../accordions/AllClientAppts";
import ClientAnimalsActionsAccordion from "../../accordions/ClientAnimalsActionsAccordion";
import ConfirmDeleteAnimalDialog from "../../modals/delete/ConfirmDeleteAnimalDialog";
import CreateAnimalDialog from "../../modals/create/CreateAnimalDialog";
import { useEditClientFormProvider } from "../../../data/providers/idb/EditClientFormProvider";
import UpdateClientDialog from "../../modals/update/ConfirmUpdateClientDialog";
import CreateClientNoteDialog from "../../modals/create/CreateClientNoteDialog";
import TooltipWrapper from "../../text/TooltipWrapper";

// import BaseNavigateButton from "../links/BaseNavigateButton";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

// TODO - START HERE, FR, FR, MAKE NEW BRANCH,
//  CLEAN THIS UP, 
//  UPDATE STYLING IN NEW UPDATE DIALOG,
//  ADD PROVIDER TO PERSIST CLIENT NOTE UPDATES TOO
//  ? - ADD BREADCRUMB ROUTES

export default function EditClientForm(props) {
    const [clientRec, setClientRec] = useState(null);
    const [showLogs, setShowLogs] = useState(false);
    const [ampUser, setAmpUser] = useState(null);
    const [routerPaths, setRouterPaths] = useState({});
    const [clientStatusEnums, setClientStatusEnums] = useState([]);

    // animal recs are client:n animals, there is no m:n related model for clients + animals
    const [origClientAnimalRecs, setOrigClientAnimalRecs] = useState([]);
    const [editedClientAnimalRecs, setEditedClientAnimalRecs] = useState([]);

    const [origClientApptRecs, setOrigClientApptRecs] = useState([]);
    const [editedClientApptRecs, setEditedClientApptRecs] = useState([]);

    const [animalRecToDelete, setAnimalRecToDelete] = useState(null);

    const [origSelectedClientStatus, setOrigSelectedClientStatus] = useState('');
    const [editedSelectedClientStatus, setEditedSelectedClientStatus] = useState('');

    const [origSelectedClientAnimalNames, setOrigSelectedClientAnimalNames] = useState([]);
    const [editedSelectedClientAnimalNames, setEditedSelectedClientAnimalNames] = useState([]);

    const [displayDeleteAnimalModal, setDisplayDeleteAnimalModal] = useState(false);

    const [displayUpdateModal, setDisplayUpdateModal] = useState(false);
    const [displayCreateClientNoteDialog, setDisplayCreateClientNoteDialog] = useState(false);

    const [origClientFormVals, setOrigClientFormVals] = useState(editClientFormObj);
    const [editedClientFormVals, setEditedClientFormVals] = useState(editClientFormObj);
    const [updatedClientRecRes, setUpdatedClientRecRes] = useState(null);

    const [createClientNoteRecRes, setCreateClientNoteRecRes] = useState(null);

    const [clientFormValsChanged, setClientFormValsChanged] = useState(false);
    const [clientFormValsDiff, setClientFormValsDiff] = useState({});

    const [pauseForFormSubmit, setPauseForFormSubmit] = useState(false);
    const [resetStateVals, setResetStateVals] = useState(false);
    const [resetStateValsDone, setResetStateValsDone] = useState(false);
    const [showUpdatingIndicator, setShowUpdatingIndicator] = useState(false);

    const [showCreateAnimalDialogBase, setShowCreateAnimalDialogBase] = useState(false);

    const { editClientFormValues,
        updateEditClientFormValues,
        resetFormValues: resetFormProviderValues,
        updateIDBFormValues,
        updateMultipleFormValues: updateMultipleProviderFormValues } = useEditClientFormProvider();

    useEffect(() => {
        if (editClientFormValues) {
            // Initialize local state with form data from the context provider only if it's different from the initial object
            if (Object.keys(editClientFormValues).length > 0) {
                const hasDifferentValues = Object.keys(editClientFormValues).some(
                    key => editClientFormValues[key] !== editClientFormObj[key]
                );
                if (hasDifferentValues) {
                    setEditedClientFormVals(editClientFormValues);
                }
            }
        }
    }, [editClientFormValues]);

    const handleBlur = (name, value) => {
        // Check if the input's value has changed, based on provider form data
        if (editClientFormValues[name] !== value) {
            // Example of custom logic that triggers a save
            updateIDBFormValues();
        }
    };

    useEffect(() => {
        if (updatedClientRecRes) {
            if (updatedClientRecRes?.success && updatedClientRecRes?.updatedClient) {
                updateMultipleProviderFormValues(updatedClientRecRes.updatedClient);
            }
        }
    }, [updatedClientRecRes]);

    useConsoleLog(showLogs, [{label: 'EditClientForm props', value: props}]);
    useConsoleLog(showLogs, [{label: 'EditClientForm provider editClientFormValues', value: editClientFormValues}]);
    // useConsoleLog(showLogs, [{label: 'EditClientForm resetStateVals', value: resetStateVals}]);
    // useConsoleLog(showLogs, [{label: 'EditClientForm resetStateValsDone', value: resetStateValsDone}]);
    useConsoleLog(showLogs, [{label: 'EditClientForm pauseForFormSubmit', value: pauseForFormSubmit}]);
    useConsoleWarn(showLogs, [{label: 'EditClientForm clientFormValsDiff', value: clientFormValsDiff}]);
    // useConsoleWarn(showLogs, [{label: 'EditClientForm updatedClientRecRes', value: updatedClientRecRes}]);
    // useConsoleWarn(showLogs, [{label: 'EditClientForm createClientNoteRecRes', value: createClientNoteRecRes}]);
    useConsoleLog(showLogs, [{label: 'EditClientForm origClientFormVals', value: origClientFormVals}]);
    useConsoleLog(showLogs, [{label: 'EditClientForm editedClientFormVals', value: editedClientFormVals}]);
    // useConsoleLog(showLogs, [{label: 'EditClientForm origClientAnimalRecs', value: origClientAnimalRecs}]);
    // useConsoleLog(showLogs, [{label: 'EditClientForm editedClientAnimalRecs', value: editedClientAnimalRecs}]);
    // useConsoleLog(showLogs, [{label: 'EditClientForm origClientApptRecs', value: origClientApptRecs}]);
    // useConsoleLog(showLogs, [{label: 'EditClientForm editedClientApptRecs', value: editedClientApptRecs}]);
    useConsoleLog(showLogs, [{label: 'EditClientForm origSelectedClientStatus', value: origSelectedClientStatus}]);
    useConsoleLog(showLogs, [{label: 'EditClientForm editedSelectedClientStatus', value: editedSelectedClientStatus}]);

    // function resetSelectStateVals() {
    //     if (resetStateVals && !resetStateValsDone) {
    //         // if (!pauseForFormSubmit) {
    //         //     setPauseForFormSubmit(true);
    //         // }
    //         // setOrigSelectedClientAnimalNames([]);
    //         // setEditedSelectedClientAnimalNames([]);
    //         // setSelectedClientAnimalNamesChanged(false);
    //         // setAnimalNamesToDelete([]);
    //         // setOrigAnimalRecsToDelete([]);
    //         // setUpdatedAnimalRecsToDelete([]);
    //         // setDeleteRelatedRecsObj({});
    //         setModelRecToDelete(null);
    //         // setDeleteAnimalsResponses([]);
    //         setClientFormValsDiff({});
    //         setClientFormValsChanged(false);
    //         // setClientNoteFormValChanged(false);
    //         // setCurrentDeleteAnimalIndex(0);
    //         // setUpdateClientAnimalNameOnModalClose(false);
    //         setResetStateValsDone(true);
    //     }
    // }

    // useEffect setAmpUser(props.ampUser)
    useEffect(() => {
        if (props.ampUser) {
            setAmpUser(props.ampUser);
        }
    }, [props.ampUser]);

    // useEffect( setRouterPaths(props.routerPaths);
    useEffect(() => {
        if (props?.routerPaths) {
            setRouterPaths(props.routerPaths);
        }
    }, [props.routerPaths]);

    // useEffect( setShowLogs( props.showLogs
    useEffect(() => {
        if (props?.showLogs){
            setShowLogs(props.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [props.showLogs]);

    // useEffect( setClientStatusEnums(ClientStatus);
    useEffect(() => {
        if (ClientStatus) {
            setClientStatusEnums(ClientStatus);
        }
    }, [ClientStatus]);

    // useEffect( setClientRec(props.clientRec);
    useEffect(() => {
        // TODO - VERIFY THIS ACTUALLY HELPS
        if (!pauseForFormSubmit) {
            if (props?.clientRec) {
                setClientRec(props.clientRec);
            }
        }
    }, [props.clientRec, pauseForFormSubmit]);

    // useEffect( setOrigClientFormVals(clientObj); setEditedClientFormVals(clientObj); setClientFormValsChanged(false); setClientFormValsDiff({});
    useEffect(() => {
        if (!pauseForFormSubmit) {
            if (clientRec) {
                const clientObj = {};
                Object.keys(clientRec).forEach(key => {
                    // console.log('key', key);
                    // console.log('clientRec[key]', clientRec[key]);
                    if (key !== 'id') {
                        clientObj[key] = clientRec[key];
                        updateEditClientFormValues(key, clientRec[key]);
                    }
                    if (key === 'clientStatus') {
                        setOrigSelectedClientStatus(clientRec[key]);
                        setEditedSelectedClientStatus(clientRec[key]);
                    }
                    // TODO - CLIENT STARTED NOT PRESENT? - EITHER CLIENT CREATED AT OR FIRST APPT
                    // TODO - IF CLIENT ENDED NOT PRESENT? - STATUS CHANGE DATE OR LAST APPT DATE
                });
                setOrigClientFormVals(clientObj);
                setEditedClientFormVals(clientObj);
                setClientFormValsChanged(false);
                setClientFormValsDiff({});
                updateIDBFormValues();
            }
        }
    }, [clientRec, pauseForFormSubmit]);

    // useEffect( setOrigClientAnimalRecs(props.clientAnimalRecs); setEditedClientAnimalRecs(props.clientAnimalRecs);
    useEffect(() => {
        if (!pauseForFormSubmit) {
            if (props?.clientAnimalRecs && props.clientAnimalRecs.length > 0) {
                setOrigClientAnimalRecs(props.clientAnimalRecs);
                setEditedClientAnimalRecs(props.clientAnimalRecs);
                const animalNames = props.clientAnimalRecs.map((eachRec) => eachRec.name);
                setOrigSelectedClientAnimalNames(animalNames);
                setEditedSelectedClientAnimalNames(animalNames);
            }
        }
    }, [pauseForFormSubmit, props.clientAnimalRecs]);

    // useEffect( setOrigClientApptRecs(props.clientApptRecs); setEditedClientApptRecs(props.clientApptRecs);
    useEffect(() => {
        if (!pauseForFormSubmit) {
            if (props.clientApptRecs) {
                setOrigClientApptRecs(props.clientApptRecs);
                setEditedClientApptRecs(props.clientApptRecs);
            }
        }
    }, [pauseForFormSubmit, props.clientApptRecs]);

    function displayClientAppts() {
        if (editedClientApptRecs && editedClientApptRecs.length > 0) {
            return (
                <AllClientApptsAccordions
                    clientAppts={editedClientApptRecs}
                    clientRec={clientRec}
                    showLogs={showLogs}
                />
            );
        } else {
            return (
                <div>
                    <Typography>No Appointments</Typography>
                </div>
            );
        }
    }

    const handleClientStatusChange = (event) => {
        const {target: {value}} = event;
        setEditedSelectedClientStatus(value);
        setEditedClientFormVals({
            ...editedClientFormVals,
            clientStatus: value
        });
        updateEditClientFormValues('clientStatus', value);
        // updateIDBFormValues();
        if (origSelectedClientStatus !== value) {
            setClientFormValsChanged(true);
            const updatedDiff = {...clientFormValsDiff};
            setClientFormValsDiff({...updatedDiff, clientStatus: value});
        } else {
            // TODO - MAKE SURE THIS IS BEING SET BASED ON ALL ATTRS. COMPARISON
            // setClientFormValsChanged(false);
            const updatedDiff = {...clientFormValsDiff};
            delete updatedDiff['clientStatus'];
            setClientFormValsDiff(updatedDiff);
        }
        updateIDBFormValues();
    }

    // TODO - START HERE, FR, FR, CLEAN THESE UP AND ORGANIZE BETTER,
    //  THEN USE UPDATE STATUS MODAL WHILE UPDATING,
    //  THEN SHOW SUCCESS OR ERRORS

    // setEditedClientFormVals({...editedClientFormVals, [updatedFormField]: updatedFieldValue});
    function updateEditedClientFormVals(updatedFormField, updatedFieldValue) {
        const numericFormFields = ['balance', 'addedApptAmount', 'addedApptDuration'];
        // if (updatedFormField === 'balance' && updatedFieldValue !== 0) {
        if (updatedFormField in numericFormFields && updatedFieldValue !== 0) {
            // TODO - HERE OR IN FORM FIELD?
            // TODO - NEED SAME HANDLING FOR ADDED APPT AMOUNT, DURATION?
            // const amountInt = convertToFullInteger(updatedFieldValue);
            const amountInt = convertToFullIntegerHandleNaN(updatedFieldValue);
            setEditedClientFormVals({...editedClientFormVals, [updatedFormField]: amountInt});
            updateEditClientFormValues(updatedFormField, amountInt);
        } else {
            setEditedClientFormVals({...editedClientFormVals, [updatedFormField]: updatedFieldValue});
            updateEditClientFormValues(updatedFormField, updatedFieldValue);
        }

        if (origClientFormVals[updatedFormField] !== updatedFieldValue) {
            setClientFormValsDiff({...clientFormValsDiff, [updatedFormField]: updatedFieldValue});
        } else {
            // The form value went back to the orig value, remove the field from the diff object
            const updatedDiff = {...clientFormValsDiff};
            delete updatedDiff[updatedFormField];
            setClientFormValsDiff(updatedDiff);
        }
    }

    const onCreateAnimalDialogBaseClose = () => {
        setShowCreateAnimalDialogBase(false);
    }

    const onDeleteAnimalDialogClose = () => {
        setDisplayDeleteAnimalModal(false);
        setAnimalRecToDelete(null);
    }

    const onUpdateClientDialogClose = () => {
        setDisplayUpdateModal(false);
    }

    const onCreateClientNoteDialogClose = () => {
        setDisplayCreateClientNoteDialog(false);
    }

    const onUpdateClientDone = (updateClientRes) => {
        if (updateClientRes) {
            setUpdatedClientRecRes(updateClientRes);
            if (updateClientRes?.success && updateClientRes?.updatedClient) {
                // setUpdatedClientRecRes(updateClientRes);
                setClientFormValsChanged(false);
                setClientFormValsDiff({});
            }
        }
        // TODO - check if we still need these
        setResetStateVals(false);
        setResetStateValsDone(true);
        setPauseForFormSubmit(false);
        setShowUpdatingIndicator(false);
    }

    const onCreateClientNoteDone = (createNoteRes) => {
        if (createNoteRes) {
            setCreateClientNoteRecRes(createNoteRes);
        }
        // TODO - check if we still need these
        // setResetStateVals(false);
        // setResetStateValsDone(true);
        setPauseForFormSubmit(false);
        setShowUpdatingIndicator(false);
    }

    async function onUpdateClientRec() {
        console.warn('onUpdateClientRec');
        setDisplayUpdateModal(true);
    }

    // TODO - ADD VALIDATION FOR PHONE, EMAIL
    // TODO - MAYBE REMOVE STREET ADDRESS, JUST EDIT EACH ADDRESS FIELD
    // TODO - ? NEED TO EDIT COUNTRY?
    // TODO - ? MAKE BALANCE READ ONLY, ADD BUTTON TO PAYMENT ACTIONS?
    // TODO - ADD BUTTON TO DELETE CLIENT
    // TODO - ADD BUTTON FOR CLIENT PAYMENT ACTIONS
    // TODO - ADD READONLY FOR NEXT APPT DATE TIME, LAST APPT DATE TIME
    // TODO - ADD ANIMALS, APPTS, NOTES
    // TODO - IF STATUS CHANGED FROM !ACTIVE TO ACTIVE, CLEAR CLIENT ENDED DATE ON SUBMIT

    // TODO - USE BOX COMPONENT FOR UNIFORMITY INSTEAD?, CLEAN UP FORM

    return (
        <div
            style={{
                backgroundColor: 'lightgray',
                // backgroundColor: 'red',
                minHeight: '15vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 'calc(10px + 2vmin)',
                // color: 'white',
                color: 'black',
                // marginBottom: '5vh'
                marginTop: '0.5vh',
                // border: '1px solid black',
                borderTop: '1px solid black',
                borderBottom: '1px solid black',
                paddingTop: '1.5vh',
                paddingBottom: '1.5vh'
            }}
        >
            {showUpdatingIndicator ? <UpdatingIndicator message={"Updating Client"}/> : <div>
                <Divider/>
                <Typography>
                    Name: {clientRec?.name}
                </Typography>
                <div>
                    <FormControl sx={{m: 1, width: 300}}>
                        <InputLabel id={`edit-client-client-status-input-label`}>Client Status</InputLabel>
                        <Select
                            labelId={`edit-client-client-status-label-id`}
                            id={`edit-client-client-status-select`}
                            name={'clientStatus'}
                            value={editedSelectedClientStatus || ''}
                            onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                            onChange={(event) => handleClientStatusChange(event)}
                            MenuProps={MenuProps}
                        >
                            {Object.entries(clientStatusEnums).map(([key, val]) => (
                                <MenuItem
                                    key={generateUniqueKey(key)}  // Use the key for generating a unique key
                                    value={val}  // Use the key as the value for the MenuItem
                                >
                                    {val}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div>
                    <ClientAnimalsActionsAccordion
                        clientRec={clientRec}
                        animals={origClientAnimalRecs}
                        headerText={`Animals (${origClientAnimalRecs ? origClientAnimalRecs.length : 0})`}
                        disabled={origClientAnimalRecs && origClientAnimalRecs?.length > 0}
                        customsx={{
                            marginBottom: '1rem',  // Space between rows
                            padding: '0.5rem 0',  // Center content with equal top and bottom padding
                        }}
                        showLogs={showLogs}
                        setDisplayDeleteAnimalModal={setDisplayDeleteAnimalModal}
                        setAnimalRecToDelete={setAnimalRecToDelete}
                    />
                </div>
                <div>
                    <BaseStyledButton
                        label={"Add Animal"}
                        onClick={() => {
                            setShowCreateAnimalDialogBase(true);
                        }}
                    />
                </div>
                <div>
                    <StyledTextField
                        id="phone"
                        name="phone"
                        label="Phone"
                        onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                        value={editedClientFormVals?.phone || ''}
                        onChange={(event) => updateEditedClientFormVals('phone', event.target.value)}
                    />
                </div>
                <div>
                    <StyledTextField
                        id="email"
                        name="email"
                        label="Email"
                        onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                        value={editedClientFormVals?.email || ''}
                        onChange={(event) => updateEditedClientFormVals('email', event.target.value)}
                    />
                </div>
                <div>
                    <TooltipWrapper tooltipText={clientFormsTooltipTextsObj.balance}>
                    <StyledTextField
                        id="balance"
                        name="balance"
                        label="Balance ($)"
                        type="number"
                        value={editedClientFormVals.balance}
                        onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                        onChange={(event) => {
                            const amountVal = textFieldValToIntegerHandleNaN(event.target.value);
                            updateEditedClientFormVals('balance', amountVal);
                        }}
                    />
                    </TooltipWrapper>
                </div>
                <div>
                    <TooltipWrapper tooltipText={clientFormsTooltipTextsObj.addedApptAmount}>
                    <StyledTextField
                        id="added-appt-amount"
                        name="addedApptAmount"
                        label="Added Appt. Amount ($)"
                        type="number"
                        value={editedClientFormVals?.addedApptAmount || ''}
                        onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                        onChange={(event) => {
                            const amountVal = textFieldValToIntegerHandleNaN(event.target.value);
                            updateEditedClientFormVals('addedApptAmount', amountVal);
                        }}
                    />
                    </TooltipWrapper>
                </div>
                <div>
                    <TooltipWrapper tooltipText={clientFormsTooltipTextsObj.addedApptDuration}>
                    <StyledTextField
                        id="added-appt-duration"
                        name="addedApptDuration"
                        label="Added Appt. Duration (mins.)"
                        type="number"
                        value={editedClientFormVals?.addedApptDuration || ''}
                        onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                        onChange={(event) => {
                            const amountVal = textFieldValToIntegerHandleNaN(event.target.value);
                            updateEditedClientFormVals('addedApptDuration', amountVal);
                        }}
                    />
                    </TooltipWrapper>
                </div>
                <div>
                    <TooltipWrapper tooltipText={clientFormsTooltipTextsObj.streetAddress}>
                    <StyledTextField
                        id="street-address"
                        name="streetAddress"
                        label="Street Address"
                        value={editedClientFormVals?.streetAddress || ''}
                        onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                        onChange={(event) => updateEditedClientFormVals('streetAddress', event.target.value)}
                    />
                    </TooltipWrapper>
                </div>
                <div>
                    <StyledTextField
                        id="city"
                        name="city"
                        label="City"
                        value={editedClientFormVals?.city || ''}
                        onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                        onChange={(event) => updateEditedClientFormVals('city', event.target.value)}
                    />
                </div>
                <div>
                    <StyledTextField
                        id="state"
                        name="state"
                        label="State"
                        value={editedClientFormVals?.state || ''}
                        onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                        onChange={(event) => updateEditedClientFormVals('state', event.target.value)}
                    />
                </div>
                <div>
                    <StyledTextField
                        id="zip-code"
                        name="zipCode"
                        label="Zip Code"
                        value={editedClientFormVals?.zipCode || ''}
                        onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                        onChange={(event) => updateEditedClientFormVals('zipCode', event.target.value)}
                    />
                </div>
                <div>
                    <StyledTextField
                        id="gate-code"
                        name="gateCode"
                        label="Gate Code"
                        value={editedClientFormVals?.gateCode || ''}
                        onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                        onChange={(event) => updateEditedClientFormVals('gateCode', event.target.value)}
                    />
                </div>
                <div>
                    <StyledTextField
                        id="last-payment-dateTime"
                        name="lastPaymentDateTime"
                        label="Last Payment Date/Time"
                        value={editedClientFormVals?.lastPaymentDateTime || '-'}
                        // onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                        // onChange={(event) => {
                        //     const amountVal = textFieldValToInteger(event.target.value);
                        //     updateEditedClientFormVals('balance', amountVal);
                        // }}
                        InputProps={{readOnly: true,}}
                        helperText="Last recorded client payment."
                    />
                </div>
                <div>
                    <StyledTextField
                        id="client-started-dateTime"
                        label="Client Started Date/Time"
                        value={editedClientFormVals?.clientStarted || '-'}
                        // onChange={(event) => {
                        //     const amountVal = textFieldValToInteger(event.target.value);
                        //     updateEditedClientFormVals('balance', amountVal);
                        // }}
                        InputProps={{readOnly: true,}}
                        helperText="Client Started."
                    />
                </div>
                {editedClientFormVals?.clientEnded ? (
                    <div>
                        <StyledTextField
                            id="client-ended-dateTime"
                            label="Client Ended Date/Time"
                            value={editedClientFormVals?.clientEnded || '-'}
                            // onChange={(event) => {
                            //     const amountVal = textFieldValToInteger(event.target.value);
                            //     updateEditedClientFormVals('balance', amountVal);
                            // }}
                            InputProps={{readOnly: true,}}
                            helperText="Client Ended."
                        />
                    </div>
                ) : null}
                <div>
                    <StyledCardContentTypography
                        text={`Appointments: (${editedClientApptRecs?.length || 0})`}
                    />
                </div>
                {displayClientAppts()}
                <div>
                    <BaseStyledButton
                        label="Update Client"
                        onClick={onUpdateClientRec}
                        disabled={Object.keys(clientFormValsDiff).length === 0}
                    />
                </div>
                <div>
                    <BaseStyledButton
                        label={"Add Client Note"}
                        onClick={() => {
                            setDisplayCreateClientNoteDialog(true);
                        }}
                    />
                </div>
            </div>}
            <div>
                {displayDeleteAnimalModal ? <ConfirmDeleteAnimalDialog
                    openProp={displayDeleteAnimalModal}
                    handleCloseProp={onDeleteAnimalDialogClose}
                    ampUser={ampUser}
                    modelRecToDelete={animalRecToDelete}
                    showLogs={showLogs}
                /> : null}
            </div>
            <div>
                {displayUpdateModal ? (
                    <UpdateClientDialog
                        openProp={displayUpdateModal}
                        ampUser={ampUser}
                        handleCloseProp={onUpdateClientDialogClose}
                        clientRec={clientRec}
                        clientFormValsDiff={clientFormValsDiff}
                        showLogs={showLogs}
                        handleUpdateDoneProp={onUpdateClientDone}
                        />
                ) : null}
            </div>
            <div>
                {showCreateAnimalDialogBase ? (<CreateAnimalDialog
                    ampUser={ampUser}
                    openProp={showCreateAnimalDialogBase}
                    handleCloseProp={onCreateAnimalDialogBaseClose}
                    clientRec={clientRec}
                    showLogs={showLogs}
                />) : null}
            </div>
            <div>
                {displayCreateClientNoteDialog ? (
                    <CreateClientNoteDialog
                        openProp={displayCreateClientNoteDialog}
                        ampUser={ampUser}
                        handleCloseProp={onCreateClientNoteDialogClose}
                        clientRec={clientRec}
                        handleCreateDoneProp={onCreateClientNoteDone}
                        showLogs={showLogs}
                        />
                ) : null}
            </div>
        </div>
    );
}
