import React, {useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import {FormGroup} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {
    softDeleteClientById,
    softDeleteClientRelatedRecsByRecType
} from "../../../data/delete/softDeleteRecs";
import CircularProgress from "@mui/material/CircularProgress";
import DialogContentText from "@mui/material/DialogContentText";
import {deleteClientDialogFinalStepSet} from "../../buttons/sets/clientButtonSets";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { camelCaseToTitleCase } from "../../../utils/text";

// TODO - DECIDE ON ALSO DELETING PUBLICCLIENT, PUBLICANIMAL RECS FOR THIS CLIENT AS WELL

export default function ConfirmDeleteClientDialog({
                                                      openProp,
                                                      ampUser,
                                                      handleCloseProp,
                                                      handleDeleteClientDoneProp,
                                                      handleDeleteRelatedRecsDoneProp,
                                                      modelRecToDelete,
                                                      showLogs,
                                                      ...props
                                                  }) {
    const [open, setOpen] = useState(false);
    const [clientRec, setClientRec] = useState(null);
    const [pauseOnFormSubmit, setPauseOnFormSubmit] = useState(false);
    const [deleteClientResObj, setDeleteClientResObj] = useState(null);
    const [deleteRelatedRecsResObj, setDeleteRelatedRecsResObj] = useState(null);
    const [deleteClientError, setDeleteClientError] = useState(null);
    const [deleteRelatedRecsError, setDeleteRelatedRecsError] = useState(null);
    const [confirmStep, setConfirmStep] = useState(0);
    const [finalStepButtonsObj, setFinalStepButtonsObj] = useState(null);

    // TODO - ADD CLIENT PAYMENTS ONCE THOSE ARE IMPLEMENTED
    const [selectedTypes, setSelectedTypes] = useState({
        appointments: false,
        animals: false,
        clientNotes: false,
    });
    const [isDeleting, setIsDeleting] = useState(false);
    const [deleteError, setDeleteError] = useState(null);

    useEffect(() => {
        if (showLogs) {
            console.log('### ConfirmDeleteClientDialog confirmStep', confirmStep);
            console.log('### ConfirmDeleteClientDialog isDeleting', isDeleting);
            console.log('### ConfirmDeleteClientDialog pauseOnFormSubmit', pauseOnFormSubmit);
            console.log('### ConfirmDeleteClientDialog deleteClientResObj', deleteClientResObj);
            console.log('### ConfirmDeleteClientDialog deleteRelatedRecsResObj', deleteRelatedRecsResObj);
            console.log('### ConfirmDeleteClientDialog finalStepButtonsObj', finalStepButtonsObj);
        }
    }, [showLogs, deleteClientResObj, deleteRelatedRecsResObj, pauseOnFormSubmit, confirmStep, isDeleting, finalStepButtonsObj]);

    // useEffect( if (openProp) { setOpen(true);
    useEffect(() => {
        if (openProp) {
            setOpen(true);
        }
    }, [openProp]);

    useEffect(() => {
        if (modelRecToDelete) {
            setClientRec(modelRecToDelete);
            setConfirmStep(1);
            setFinalStepButtonsObj(deleteClientDialogFinalStepSet());
        }
    }, [modelRecToDelete]);

    useEffect(() => {
        if (deleteClientResObj) {
            if (!deleteClientResObj?.success && deleteClientResObj?.error) {
                setDeleteClientError(deleteClientResObj.error);
            }
        }
    }, [deleteClientResObj]);

    useEffect(() => {
        if (deleteRelatedRecsResObj) {
            if (!deleteRelatedRecsResObj?.success && deleteRelatedRecsResObj?.error) {
                setDeleteRelatedRecsError(deleteRelatedRecsResObj.error);
            }
        }
    }, [deleteRelatedRecsResObj]);

    const handleClose = () => {
        if (showLogs) console.log('### ConfirmDeleteClientDialog handleClose');
        if (handleCloseProp) {
            // if (!pauseOnFormSubmit) {
            if (!pauseOnFormSubmit && !isDeleting) {
                if (handleDeleteClientDoneProp) {
                    handleDeleteClientDoneProp(deleteClientResObj);
                }
                if (handleDeleteRelatedRecsDoneProp) {
                    handleDeleteRelatedRecsDoneProp(deleteRelatedRecsResObj);
                }
                handleCloseProp();
            }
        }
    }

    const handleCancel = () => {
        setConfirmStep(0);
        handleClose();
    };

    const handleConfirmDelete = async (typesToDelete) => {
        if (showLogs) console.log('### ConfirmDeleteClientDialog handleConfirmDelete');
        if (showLogs) console.log('### ConfirmDeleteClientDialog handleConfirmDelete typesToDelete', typesToDelete);
        // Delete rec done in delete form, add any other actions here
        setPauseOnFormSubmit(true);
        const deleteClientRes = await softDeleteClientById(modelRecToDelete.id, showLogs);
        setDeleteClientResObj(deleteClientRes);
        if (typesToDelete.length > 0) {
            const deleteRelatedRecsRes = await softDeleteClientRelatedRecsByRecType(modelRecToDelete.id, typesToDelete, showLogs);
            setDeleteRelatedRecsResObj(deleteRelatedRecsRes);
        }
    }

    const handleTypeChange = (event) => {
        setSelectedTypes({
            ...selectedTypes,
            [event.target.name]: event.target.checked,
        });
    };

    const handleConfirmDeleteClientStep1 = () => {
        setConfirmStep(2);
    };

    const handleConfirmRelatedRecsStep2 = () => {
        setConfirmStep(3);
    };

    const handleConfirmDeleteRecsStep3 = async () => {
        const typesToDelete = Object.keys(selectedTypes).filter(
            (type) => selectedTypes[type]
        );
        try {
            setIsDeleting(true);
            setDeleteError(null);
            await handleConfirmDelete(typesToDelete);
            // setConfirmStep(4);
            // setPauseOnFormSubmit(false);
        } catch (error) {
            setDeleteError(error);
        } finally {
            setIsDeleting(false);
            setPauseOnFormSubmit(false);
            setConfirmStep(4);
        }
    };

    const handleBack = () => {
        setConfirmStep(2);
    };

    const renderConfirmDeleteClientStep1 = () => (
        <>
            <DialogTitle>Confirm Delete Client</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {modelRecToDelete.name} will be deleted.
                </DialogContentText>
                <DialogContentText>
                    Are you sure you want to delete this client record?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleConfirmDeleteClientStep1} color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </>
    );

    const renderSelectDeleteRelatedRecsStep2 = () => (
        <>
            <DialogTitle>Select Related Records to Delete</DialogTitle>
            <DialogContent>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox
                            checked={selectedTypes.appointments}
                            onChange={handleTypeChange}
                            name="appointments"
                        />}
                        label="Appointments"
                    />
                    {selectedTypes.appointments && (
                        <div>
                            <DialogContentText>
                                Warning! Deleting appointment records will also delete any related records.
                            </DialogContentText>
                            <DialogContentText>
                                Including: Appt. Notes, Shoeings, Trims, Shoe Packages.
                            </DialogContentText>
                        </div>
                    )}
                    <FormControlLabel
                        control={<Checkbox
                            checked={selectedTypes.animals}
                            onChange={handleTypeChange}
                            name="animals"
                        />}
                        label="Animals"
                    />
                    {selectedTypes.animals && (
                        <div>
                            <DialogContentText>
                                Warning! Deleting animal records will also delete any related records.
                            </DialogContentText>
                            <DialogContentText>
                                Including: Animal Notes, Shoeings, Trims, Shoe Packages.
                            </DialogContentText>
                        </div>
                    )}
                    <FormControlLabel
                        control={<Checkbox
                            checked={selectedTypes.clientNotes}
                            onChange={handleTypeChange}
                            name="clientNotes"
                        />}
                        label="Client Notes"
                    />
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleConfirmRelatedRecsStep2} color="primary">
                    Delete Selected
                </Button>
            </DialogActions>
        </>
    );

    const renderConfirmDeleteRecsStep3 = () => {
        const typesToDelete = Object.keys(selectedTypes).filter(
            (type) => selectedTypes[type]
        );

        return (
            <>
                <DialogTitle>Final Confirmation</DialogTitle>
                <DialogContent>
                    <p>You are about to delete the following:</p>
                    <List>
                        <ListItem>
                            <ListItemText primary={`Client record for ${modelRecToDelete.name}`}/>
                        </ListItem>
                        {typesToDelete.map((type) => (
                            <ListItem key={type}>
                                <ListItemText primary={`${modelRecToDelete.name}'s ${camelCaseToTitleCase(type)}`}/>
                            </ListItem>
                        ))}
                    </List>
                    <p>Are you sure you want to proceed?</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleBack} color="secondary">
                        Back
                    </Button>
                    <Button onClick={async () => await handleConfirmDeleteRecsStep3()} color="primary"
                            disabled={isDeleting}>
                        {isDeleting ? <CircularProgress size={24}/> : 'Confirm Delete'}
                    </Button>
                </DialogActions>
            </>
        );
    };

    const renderDeleteResultStep4 = () => (
        <>
            <DialogTitle>Delete Result</DialogTitle>
            {deleteError ? (
                <DialogContent>
                    <DialogContentText>
                        Error deleting the client:
                    </DialogContentText>
                    <p>{deleteError}</p>
                </DialogContent>
            ) : (
                <DialogContent>
                    {deleteClientResObj && deleteClientError ? (
                        <div>
                            <DialogContentText>
                                Error deleting the client:
                            </DialogContentText>
                            <p>{deleteClientError}</p>
                        </div>
                    ) : null}
                    {deleteClientResObj && deleteClientResObj?.success ? (
                        <DialogContentText>
                            Client deleted
                        </DialogContentText>
                    ) : null}
                    {deleteRelatedRecsResObj && deleteRelatedRecsError ? (
                        <div>
                            <DialogContentText>
                                Error deleting related records:
                            </DialogContentText>
                            <p>{deleteRelatedRecsError}</p>
                        </div>
                    ) : null}
                    {deleteRelatedRecsResObj && deleteRelatedRecsResObj?.success ? (
                        <DialogContentText>
                            Related records deleted
                        </DialogContentText>
                    ) : null}
                </DialogContent>
            )}
            <DialogActions>
                <Stack spacing={2} width="100%">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                            {/*{deleteClientDialogFinalStepSet.clients}*/}
                            {finalStepButtonsObj.clients}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {/*{deleteClientDialogFinalStepSet.createClient}*/}
                            {finalStepButtonsObj.createClient}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {/*{deleteClientDialogFinalStepSet.home}*/}
                            {finalStepButtonsObj.home}
                        </Grid>
                    </Grid>
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        color="primary"
                        fullWidth
                    >
                        Done
                    </Button>
                </Stack>
            </DialogActions>
        </>
    );

    const renderLoadingStep = () => (
        <>
            <DialogTitle>Loading...</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Loading Client Data
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                    disabled={isDeleting}
                >
                    Close
                </Button>
                <Button
                    onClick={handleCancel}
                    variant="contained"
                    color="primary"
                    disabled={isDeleting}
                >
                    Cancel
                </Button>
            </DialogActions>
        </>
    );

    return (
        <Dialog open={open} onClose={handleClose}>
            {confirmStep === 0 ? renderLoadingStep() :
                confirmStep === 1 ? renderConfirmDeleteClientStep1() :
                    confirmStep === 2 ? renderSelectDeleteRelatedRecsStep2() :
                        confirmStep === 3 ? renderConfirmDeleteRecsStep3() :
                            renderDeleteResultStep4()}
        </Dialog>
    );
}
