import React, {useState, useEffect, useContext} from 'react';
import {useResponsiveViewport} from "../../data/providers/ResponsiveViewportProvider";
import CreateApptForm from "../../components/forms/create/CreateApptForm";
import AmpUserClientsContext from "../../data/contexts/AmpUserClientsContext";
import {fetchAnimalRecsByClientId} from "../../data/get/fetchRecords";

export default function CreateApptScreen(props) {
    const [showLogs, setShowLogs] = useState(false);
    const [routerPaths, setRouterPaths] = useState({});
    const [ampUser, setAmpUser] = useState(null);
    const { isSmallScreen, isLargeScreen } = useResponsiveViewport();
    const [ampUserClients, setAmpUserClients] = useState([]);
    const [ampUserClientIds, setAmpUserClientIds] = useState([]);
    // const [clientAnimalsRecs, setClientAnimalsRecs] = useState([]);
    const [animalRecsByClientId, setAnimalRecsByClientId] = useState({});
    const [selectedAnimalRec, setSelectedAnimalRec] = useState(null);
    const [selectedClientRec, setSelectedClientRec] = useState(null);

    const { ampUserClientsRecs } = useContext(AmpUserClientsContext);
    // const {}

    // useEffect( setAmpUserClients(ampUserClientsRecs);
    useEffect(() => {
        if (ampUserClientsRecs) {
            setAmpUserClients(ampUserClientsRecs);
            const clientIds = ampUserClientsRecs.map((clientRec) => {
                return clientRec.id;
            });
            setAmpUserClientIds(clientIds);
        }
    }, [ampUserClientsRecs]);

    // useEffect( console.log( ampUserClientsRecs
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('$$$ CreateApptScreen ampUserClientsRecs', ampUserClientsRecs);
    //     }
    // }, [showLogs, ampUserClientsRecs]);

    // useEffect( setShowLogs(props.showLogs);
    useEffect(() => {
        if (props.showLogs) {
            setShowLogs(props.showLogs);
        }
    }, [props.showLogs]);

    // useEffect( setAmpUser(props.ampUser);
    useEffect(() => {
        if (props.ampUser) {
            setAmpUser(props.ampUser);
        }
    }, [props.ampUser]);

    // useEffect( setSelectedAnimalRec(props.animalRec);
    useEffect(() => {
        if (props?.animalRec) {
            setSelectedAnimalRec(props.animalRec);
        }
    }, [props]);

    // useEffect( setSelectedClientRec(props.clientRec);
    useEffect(() => {
        if (props?.clientRec) {
            setSelectedClientRec(props.clientRec);
        }
    }, [props]);

    // TODO - ON SELECTED CLIENT, GET LAST CLIENT'S APPT REC

    // useEffect( fetchAllClientAnimals => setAnimalRecsByClientId(recsById);
    useEffect(() => {
        const fetchAllClientAnimals = async () => {
            if (ampUserClientIds && ampUserClients.length > 0) {
                const fetchPromises = ampUserClientIds.map(async (clientId) => {
                    const animalRecs = await fetchAnimalRecsByClientId(clientId);
                    return { clientId, animalRecs };
                });

                const results = await Promise.all(fetchPromises);
                const recsById = results.reduce((acc, { clientId, animalRecs }) => {
                    // Assuming shoePackageRecs is an array of records
                    // Sort shoePackageRecs by createdAt in descending order to get the most recent record first
                    if (animalRecs && animalRecs.length > 0) {
                        animalRecs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                        // Assign only the most recent shoePackageRec for the animalId
                        acc[clientId] = animalRecs;
                    }
                    return acc;
                }, {});
                setAnimalRecsByClientId(recsById);
            }
        };
        fetchAllClientAnimals();
    }, [ampUserClientIds, ampUserClients]);

    // useEffect( console.log( ampUser, ampUserClients, animalRecsByClientId
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('CreateApptScreen ampUser', ampUser);
    //         console.log('CreateApptScreen ampUserClients', ampUserClients);
    //         console.log('CreateApptScreen animalRecsByClientId', animalRecsByClientId);
    //     }
    //     console.log('CreateApptScreen showLogs', showLogs);
    // }, [showLogs, ampUser, ampUserClients, animalRecsByClientId]);

    return (
        <div>
            <h2>Create Appointment</h2>
            <CreateApptForm
                showLogs={showLogs}
                ampUser={ampUser}
                routerPaths={routerPaths}
                ampUserClients={ampUserClients}
                animalRecsByClientId={animalRecsByClientId}
                selectedAnimalRec={selectedAnimalRec}
                selectedClientRec={selectedClientRec}
            />
        </div>
    );
}