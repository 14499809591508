import React, {useState, useEffect, useMemo} from 'react';
import './styles/App.css';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import {Amplify, DataStore, SortDirection} from "aws-amplify";
import {Authenticator, useAuthenticator} from '@aws-amplify/ui-react';
import {devStateNow} from "./utils/consts";
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {ResponsiveViewportProvider} from "./data/providers/ResponsiveViewportProvider";
import aws_exports from './aws-exports';
import {AmpUser, AmpUserSubscriber} from "./models";
import DevStateProvider from "./data/providers/DevStateProvider";
import MobileFooter from "./components/footer/MobileFooter";
import onAmpUserCreate from "./data/create/person/ampUser";
import AmpUserDataProvider from "./data/providers/people/AmpUserDataProvider";
import AmpUserSubscriberProvider from "./data/providers/people/subsPayments/AmpUserSubscriberProvider";
import Error404Screen from "./screens/400s/Error404Screen";
import RouterErrorBoundary from "./components/router/RouterErrorBoundary";
import { SnackbarProvider } from 'notistack';
import routes from "./routes";
import PromptAmpUserIntake from "./components/modals/PromptAmpUserIntake";
import WrappedHeaderBar from "./components/router/WrappedHeaderBar";
Amplify.configure(aws_exports);


function App() {
    const [ampUser, setAmpUser] = useState(null);
    const [ampUserSubscriber, setAmpUserSubscriber] = useState(null);
    const [ampUserIntakeFormCompleted, setAmpUserIntakeFormCompleted] = useState(true);
    const [isCreatingAmpUser, setIsCreatingAmpUser] = useState(false);
    const [newAmpUserCreated, setNewAmpUserCreated] = useState(false);
    const [displayUserIntakeDialog, setDisplayUserIntakeDialog] = useState(false);

    const [showLogs, setShowLogs] = useState(false);
    const [ampUserSubPaymentRecs, setAmpUserSubPaymentRecs] = useState([]);
    const [updateAmpUser, setUpdateAmpUser] = useState(null);
    const [currentUserSub, setCurrentUserSub] = useState(null);
    const [currentSubPayment, setCurrentSubPayment] = useState(null);
    const [routerPaths, setRouterPaths] = useState({});

    // TODO - !!! - SET THIS TO TRUE ON SIGNOUT, PASS TO ALL PROVIDERS AND RESET TO PREVENT PREV USER DATA PERSISTING IN PROVIDERS,
    //  RESET LOGIC EXISTS IN AMPUSERAPPTNOTES PROVIDER
    const [resetProvidersStates, setResetProvidersStates] = useState(false);

    const {user, signOut} = useAuthenticator((context) => [context.user]);
    const {authStatus} = useAuthenticator(context => [context.authStatus]);
    // const { setAmpUserRec } = useContext(AmpUserContext);

    // TODO - FIGURE OUT HOW TO CALL THIS ON SIGNOUT AND NOT WSOD FOR NEXT USER LOGIN
    // useEffect( DataStore.clear();
    // useEffect(() => {
    //     DataStore.clear();
    // }, []);

    // TODO - !!! BIG THING TO TEST,
    //  MAKE SURE THIS WORKS ON SIGNOUT AND ON NEW LOGIN

    // useEffect( if (authStatus === 'authenticated' && user) setResetProvidersStates(false);
    useEffect(() => {
        if (authStatus === 'authenticated' && user) {
            setResetProvidersStates(false);
        }
    }, [user, authStatus]);

    // useEffect( setShowLogs(
    useEffect(() => {
        if (devStateNow.stateNow === "prod") {
            setShowLogs(false);
        } else {
            setShowLogs(true);
        }
    }, [devStateNow]);

    // useEffect( console.log("App.js: user: ", user); console.log("App.js: authStatus: ", authStatus);
    useEffect(() => {
        if (showLogs) {
            console.log("App.js: user: ", user);
            console.log("App.js: authStatus: ", authStatus);
        }
    }, [user, authStatus, showLogs]);

    // useEffect( console.log("App.js: ampUser: ", ampUser);
    useEffect(() => {
        if (showLogs) {
            console.log("App.js: ampUser: ", ampUser);
        }
    }, [ampUser, showLogs]);

    // TODO - TRY TO REPLACE THIS WITH GET SET STATE VAL FROM AMP USER CONTEXT,
    //  AND PASS TO AMP USER PROVIDER
    // DataStore.observeQuery(AmpUser
    useEffect(() => {
        // if (user.attributes.verified) {
        if (authStatus === 'authenticated' && user) {
            if (resetProvidersStates) {
                // setUpdateAmpUser(true);
                setResetProvidersStates(false);
            }
            const subscription = DataStore.observeQuery(AmpUser,
                au => au.email.eq(user.attributes.email),
                {
                    sort: s => s.name(SortDirection.ASCENDING)
                }
            ).subscribe(snapshot => {
                const {items, isSynced} = snapshot;
                // if (showLogs) {
                // console.log(`AMPUSER [Snapshot] item count: ${items.length}, isSynced: ${isSynced}`);
                // console.log('AMPUSER [Snapshot] items', items);
                // }
                if (isSynced) {
                    if (items.length > 0) {
                        if (updateAmpUser) {
                            setAmpUser(items[0]);
                            setUpdateAmpUser(false);
                        } else if (ampUser !== items[0]) {
                            setAmpUser(items[0]);
                            setUpdateAmpUser(false);
                        }
                    } else {
                        // TODO - NEED BETTER TOGGLE LOGIC HERE, CREATES DUPE RECORDS,
                        //  IF A COGNITO USER EXISTS, ALWAYS CREATES AMP USER,
                        //  CORRECT AMP USER HAS EMAIL REC
                        if (authStatus === 'authenticated') {
                            setIsCreatingAmpUser(true);
                            onAmpUserCreate(user, true, showLogs).then(res => {
                                if (res.newAmpUserRec) {
                                    // setAmpUser(res.newAmpUserRec);
                                    setIsCreatingAmpUser(false);
                                    setNewAmpUserCreated(true);
                                }
                            }).catch(err => {
                                console.error('onAmpUserCreate error', err);
                            });
                        }
                    }
                }
            });
        }
    }, [user, authStatus, showLogs, updateAmpUser]);
    // }, [user, authStatus, showLogs]);

    // DataStore.observeQuery(AmpUserSubscriber
    useEffect(() => {
        if (ampUser) {
            const subscription = DataStore.observeQuery(AmpUserSubscriber,
                aus => aus.ampuserID.eq(ampUser.id),
                {
                    sort: s => s.createdAt(SortDirection.DESCENDING)
                }
            ).subscribe(msg => {
                const {items, isSynced} = msg;
                if (showLogs) {
                    // console.log(`AmpUserSubscriberProvider b [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                    // console.log('AmpUserSubscriberProvider b [Snapshot] items', items);
                }
                if (isSynced) {
                    if (items && items.length > 0) {
                        // console.log('AmpUserSubscriberProvider b [Snapshot] items', items);
                        const ampUserSubscriberRec = items[0];
                        // console.log('AmpUserSubscriberProvider ampUserSubscriberRec items[0]', ampUserSubscriberRec);
                        setAmpUserSubscriber(ampUserSubscriberRec);
                    }
                }
            });
            // return () => {
            //     subscription.unsubscribe();
            // };
        } else {
            // console.error('AmpUserSubscriberProvider no ampUserId');
            setAmpUserSubscriber(null);
        }
        // }, [ampUserSubscriberRec, ampUserId, showLogs]);
    }, [ampUser, showLogs]);

    // useEffect( if (newAmpUserCreated) setDisplayUserIntakeDialog(true);
    // useEffect(() => {
    //     if (newAmpUserCreated) {
    //         setDisplayUserIntakeDialog(true);
    //     }
    // }, [newAmpUserCreated]);

    // useEffect( if (ampUser?.intakeFormCompleted) setAmpUserIntakeFormCompleted(true);
    useEffect(() => {
        if (ampUser) {
            if (ampUser?.intakeFormCompleted) {
                setAmpUserIntakeFormCompleted(true);
            }
            else {
                setAmpUserIntakeFormCompleted(false);
            }
        }
    }, [ampUser]);

    // useEffect( if (newAmpUserCreated) setDisplayUserIntakeDialog(true);
    useEffect(() => {
        if (ampUser) {
            if (newAmpUserCreated) {
                setDisplayUserIntakeDialog(true);
            }
            else if (!ampUserIntakeFormCompleted) {
                setDisplayUserIntakeDialog(true);
            }
        }
    }, [ampUser, newAmpUserCreated, ampUserIntakeFormCompleted]);

    // TODO - DO WE NEED TO RESET PROVIDER VALUES ON SIGN OUT?
    function updateOnSignOut() {
        // if (authStatus !== 'authenticated') {
        setAmpUserSubPaymentRecs([]);
        setCurrentSubPayment(null);
        setCurrentUserSub(null);
        // setCreateUserSub(false);
        // setRegions([]);
        setAmpUser(null);
        setAmpUserSubscriber(null);
        setIsCreatingAmpUser(false);
        setNewAmpUserCreated(false);
        setDisplayUserIntakeDialog(false);
        // setAmpUser(null); // to the provider
        // // localStorage.clear();
        // TODO - TOGGLE BACK IN AFTER ADDING USER SUBS
        // resetOnSignOut(true);

        // TODO - VERIFY THIS DOESN'T CAUSE WSOD
        setResetProvidersStates(true);
        // }
    }

    function signOutAmpUser() {
        updateOnSignOut();
        signOut();
        // .then(data => console.log(data))
        // .catch(err => console.log(err));
        // window.location.reload();
    }

    // useEffect( signOutAmpUser();
    useEffect(() => {
        if (!user) {
            signOutAmpUser();
        }
        if (showLogs) {
            console.log('current user', user);
        }
    }, [user, showLogs]);

    function onUserIntakePromptNavConfirm() {
        // setAmpUserIntakeFormCompleted(true);
        setNewAmpUserCreated(false);
        setDisplayUserIntakeDialog(false);
    }

    function onUserIntakePromptClose() {
        setDisplayUserIntakeDialog(false);
        setNewAmpUserCreated(false);
    }

    const memoizedRoutes = useMemo(() =>
            routes(routerPaths, ampUser, ampUserSubscriber, showLogs, resetProvidersStates),
        [routerPaths, ampUser, ampUserSubscriber, showLogs, resetProvidersStates]
    );

    return (
        <div className="App">
            <BrowserRouter>
                <RouterErrorBoundary>
                    <AmpUserDataProvider showLogs={showLogs} ampUserProp={ampUser}>
                        <AmpUserSubscriberProvider showLogs={showLogs} ampUserSubscriberProp={ampUserSubscriber}>
                            <div className="app-container">
                                <WrappedHeaderBar
                                    ampUserProp={ampUser}
                                    routerPathsProp={routerPaths}
                                    routerPathNamesProp={routerPaths}
                                    authStatusProp={authStatus}
                                    signOutProp={signOutAmpUser}
                                    userSubProp={currentUserSub}
                                    subPaymentProp={currentSubPayment}
                                    showLogsProp={showLogs}
                                    resetProviderState={resetProvidersStates}
                                />
                                <div className="main-content">
                                    <Routes>
                                        {memoizedRoutes.map((route, index) => (
                                            <Route key={index} path={route.path} element={route.element} />
                                        ))}
                                        <Route path={"*"} element={<Error404Screen/>}/>
                                    </Routes>
                                </div>
                                {displayUserIntakeDialog && ampUser ? (
                                    <PromptAmpUserIntake
                                        openProp={displayUserIntakeDialog}
                                        ampUserProp={ampUser}
                                        handleCloseProp={onUserIntakePromptClose}
                                        handleConfirmNavProp={onUserIntakePromptNavConfirm}
                                        showLogs={showLogs}
                                    />
                                ) : null}
                                <DevStateProvider>
                                    <MobileFooter className="mobileFooter"/>
                                </DevStateProvider>
                            </div>
                        </AmpUserSubscriberProvider>
                    </AmpUserDataProvider>
                </RouterErrorBoundary>
            </BrowserRouter>
        </div>
    );
}

export default () => (
    <Authenticator.Provider>
        <SnackbarProvider maxSnack={3}>
            <ResponsiveViewportProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <App/>
                </LocalizationProvider>
            </ResponsiveViewportProvider>
        </SnackbarProvider>
    </Authenticator.Provider>
);
