import React, { useState } from 'react';
import { Grid, Snackbar, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';


// TODO - USE TO REPLACE OLDER StyledFooterButton

export default function StyledValidatorFooterButton({urlObj, ...props}) {
    const [isChecking, setIsChecking] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    // const [open, setOpen] = useState(false);

    const navigate = useNavigate();

    // const checkUrlStatus = async (url) => {
    //     try {
    //         setIsChecking(true);
    //         const response = await fetch(url, { method: 'HEAD' });
    //         setIsChecking(false);
    //         return response.ok; // Returns true if status is 2xx or 3xx
    //     } catch (error) {
    //         setIsChecking(false);
    //         return false; // If fetch fails, assume the URL is invalid
    //     }
    // };

    // const checkUrlStatus = async (url) => {
    //     try {
    //         setIsChecking(true);
    //         const response = await fetch(url, { method: 'HEAD' });
    //         setIsChecking(false);
    //         return response.ok; // Returns true if status is 2xx or 3xx
    //     } catch (error) {
    //         setIsChecking(false);
    //         return false; // If fetch fails, assume the URL is invalid
    //     }
    // };

    const isValidUrl = (url) => {
        try {
            // setIsChecking(true);
            if (!url || !url.isLive) {
                return false;
            }
            new URL(url.url);
            return true;
        } catch {
            return false;
        }
        // finally {
        //     setIsChecking(false);
        // }
    };

    const handleClick = () => {
        try {
            if (isValidUrl(urlObj)) {
                window.open(urlObj.url, '_blank');
            } else {
                enqueueSnackbar(`${props?.label || '-'} URL is not valid or not working.`, {
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            }
        }
        catch (e) {
            console.error(e);
            enqueueSnackbar(`Error opening ${props?.label || '-'} URL.`, {
                variant: 'error',
                autoHideDuration: 1000,
            });
        }
    };

    // const handleClick = async () => {
    //     const isValid = await checkUrlStatus(urlObj);
    //     if (isValid) {
    //         window.open(urlObj.url, '_blank');
    //     } else {
    //         enqueueSnackbar('This link is not available (404)', {
    //             variant: 'error',
    //             autoHideDuration: 1000,
    //         });
    //     }
    // };

    return (
        <Grid item xs={12} sm="auto">
            {urlObj?.isLive ? (
                <Button
                    onClick={handleClick}
                    // disabled={!isValidUrl(url)}
                    disabled={isChecking}
                    // variant="outlined"
                    size={props?.size || 'large'}
                    // color="inherit"
                    sx={{
                        // backgroundColor: '#5F9EA0',
                        color: 'white',
                        marginLeft: {
                            xs: '0.25rem',  // Assuming base font-size is 16px, this is 4px
                            sm: '0.5rem',   // 8px
                            md: '1rem',     // 16px
                            lg: '1.5rem',   // 24px
                            xl: '2rem'      // 32px
                        },
                        textTransform: 'none', // Maintain the original casing
                        // fontSize: ['3vw', '2.5vw', '2vw', '1.5vw'], // Responsive font sizes, smallest viewport to largest
                        fontSize: ['6vw', '4vw', '2.5vw', '2vw'],
                        '&:hover': {
                            backgroundColor: '#5F9EA0',  // A darker shade for hover
                        },
                    }}
                >
                    {/*{props.children}*/}
                    {props?.label || '-'}
                </Button>
            ) : null}
        </Grid>
    );
};
