import React, {useContext, useEffect} from 'react';
import styles from '../../styles/MobileFooter.css';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import StyledFooterButton from "../buttons/StyledFooterButton";
import DevStateContext from "../../data/contexts/DevStateContext";
import StyledValidatorFooterButton from "../buttons/StyledValidatorFooterButton";

export default function MobileFooter(props) {

    const [termsUrl, setTermsUrl] = React.useState('');
    const [contactUrl, setContactUrl] = React.useState('');
    const [aboutUrl, setAboutUrl] = React.useState('');
    const [worksUrl, setWorksUrl] = React.useState('');
    const { devStateObj, staticUrlsObj } = useContext(DevStateContext);

    useEffect(() => {
        console.log('MobileFooter devStateObj', devStateObj);
        console.log('MobileFooter staticUrlsObj', staticUrlsObj);
    }, [devStateObj, staticUrlsObj]);

    useEffect(() => {
        // if (devStateObj) {
        if (staticUrlsObj) {
            // if (devStateObj?.termsUrl?.isLive) setTermsUrl(devStateObj.termsUrl);
            if (staticUrlsObj?.termsUrl?.isLive) setTermsUrl(staticUrlsObj.termsUrl);
            // setTermsUrl(devStateObj.termsUrl);
            // if (devStateObj?.contactUrl?.isLive) setContactUrl(devStateObj.contactUrl);
            if (staticUrlsObj?.contactUrl?.isLive) setContactUrl(staticUrlsObj.contactUrl);
            // setContactUrl(devStateObj.contactUrl);
            // if (devStateObj?.aboutUrl?.isLive) setAboutUrl(devStateObj.aboutUrl);
            if (staticUrlsObj?.aboutUrl?.isLive) setAboutUrl(staticUrlsObj.aboutUrl);
            // setAboutUrl(devStateObj.aboutUrl);
            // if (devStateObj?.worksUrl?.isLive) setWorksUrl(devStateObj.worksUrl);
            if (staticUrlsObj?.worksUrl?.isLive) setWorksUrl(staticUrlsObj.worksUrl);
            // setWorksUrl(devStateObj.worksUrl);
        }
    // }, [devStateObj]);
    }, [staticUrlsObj]);

    // TODO - start here, make sure the linked pages have correct content, work
    return (
        <AppBar
            position="static"
        >
            <Toolbar sx={{
                backgroundColor: '#486B6C',
                color: 'white',
            }}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}
                      direction={{ xs: 'column', sm: 'row' }}>
                    {worksUrl && <StyledValidatorFooterButton urlObj={worksUrl} label={"How It Works"} />}
                    {aboutUrl && <StyledValidatorFooterButton urlObj={aboutUrl} label={"About Us"} />}
                    {contactUrl && <StyledValidatorFooterButton urlObj={contactUrl} label={"Contact Us"} />}
                    {termsUrl && <StyledValidatorFooterButton urlObj={termsUrl} label={"Terms of Service"} />}
                    <Grid item xs={12} sm="auto" className={styles.ngigsLogo}>
                        <StyledFooterButton>
                            By{' '}
                            <img
                                // src="/favicon.ico"
                                src="/frogLogo.png"
                                alt="Sole Frog logo"
                                width={40}
                                height={40}
                            />
                            Sole Frog
                        </StyledFooterButton>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm="auto"
                        sx={{
                            marginBottom: '0.5rem',
                            fontSize: ['6vw', '4vw', '2.5vw', '2vw'],
                    }}>
                        <div>
                            &copy; {new Date().getFullYear()} Sole Frog LLC. All rights reserved.
                        </div>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}
