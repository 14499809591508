import React, { useState, useEffect } from 'react';
import AmpUserContext from "../../contexts/AmpUserContext";

// import { DataStore } from '@aws-amplify/datastore';
// import { AmpUser } from '../../models';
// import {SortDirection} from "aws-amplify";
// import { SortDirection } from "aws-amplify";

const AmpUserDataProvider = ({ children, showLogs, ...props }) => {
    const [ampUserRec, setAmpUserRec] = useState(null);
    const [ampUserId, setAmpUserId] = useState(null);

    // useEffect( console.log( ampUserRec, ampUserId
    useEffect(() => {
        if (showLogs) {
            console.log('ampUserRec', ampUserRec);
            console.log('ampUserId', ampUserId);
        }
    }, [showLogs, ampUserRec, ampUserId]);

    // useEffect( console.log('AmpUserDataProvider ampUserRec', ampUserRec);
    // useEffect(() => {
    //     if (devStateNow.stateNow === 'dev') {
    //     console.log('AmpUserDataProvider ampUserRec', ampUserRec);
    //     }
    // }, [ampUserRec]);

    // useEffect( setAmpUserId(props.ampUserIdProp);
    useEffect(() => {
        if (props?.ampUserIdProp) {
            setAmpUserId(props.ampUserIdProp);
        }
    }, [props.ampUserIdProp]);

    // useEffect( setAmpUserRec(props.ampUserProp);
    useEffect(() => {
        if (props?.ampUserProp) {
            setAmpUserRec(props.ampUserProp);
        }
    }, [props.ampUserProp]);

    // useEffect( setAmpUserId(ampUserRec.id);
    useEffect(() => {
        if (ampUserRec) {
            if (ampUserRec.id !== ampUserId) {
                setAmpUserId(ampUserRec.id);
            }
        }
    }, [ampUserRec, ampUserId]);

    // TODO - TOGGLE THIS IN IF NEEDED,
    //  BUT MAY NOT NEED,
    //  MAY NEED TO CHECK IF IDS MATCH BEFORE FETCHING MODEL REC

    // useEffect(() => {
    //     if (ampUserId) {
    //         if (ampUserRec) {
    //             if (ampUserRec.id !== ampUserId) {
    //                 console.error('AmpUserDataProvider ampUserRec.id !== ampUserId', ampUserRec.id, ampUserId);
    //             }
    //         }
    //         const subscription = DataStore.observeQuery(AmpUser,
    //             au => au.id.eq(ampUserId),
    //             {
    //                     sort: s => s.name(SortDirection.ASCENDING)
    //                 }
    //             ).subscribe(msg => {
    //                 const { items, isSynced } = msg;
    //                 if (showLogs) {
    //                     console.log(`AmpUserDataProvider a [msg] item count: ${items.length}, isSynced: ${isSynced}`);
    //                     console.log('AmpUserDataProvider a [Snapshot] items', items);
    //                 }
    //                 if (isSynced) {
    //                     if (items && items.length > 0) {
    //                         console.log('AmpUserDataProvider a [Snapshot] items', items);
    //                         const ampUser = items[0];
    //                         console.log('AmpUserDataProvider ampUser items[0]', ampUser);
    //                         if (ampUserRec) {
    //                             if (ampUserRec.id !== ampUser.id) {
    //                                 console.error('AmpUserDataProvider ampUserRec.id !== ampUser.id', ampUserRec.id, ampUser.id);
    //                             }
    //                         }
    //                         // console.log('AmpUserDataProvider ampUser items[0]', ampUser);
    //                         setAmpUserRec(ampUser);
    //                     }
    //                     else {
    //                         console.error('AmpUserDataProvider no items for ampUserId', ampUserId);
    //                         setAmpUserRec(items);
    //                     }
    //                 }
    //             });
    //         return () => {
    //             subscription.unsubscribe();
    //         };
    //     }
    // }, [ampUserId, showLogs]);

    return (
        <AmpUserContext.Provider value={{ ampUserRec, setAmpUserRec }}>
            {children}
        </AmpUserContext.Provider>
    );
};

export default AmpUserDataProvider;
