import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
    Typography,
    Grid,
    Stack
} from '@mui/material';
import {formFieldsToIgnore} from "../../../utils/formObjects";
import {camelCaseToTitleCase, toTitleCase} from "../../../utils/text";
import { useSharedPubClientIDB } from "../../../data/providers/idb/SharedPubClientIDBProvider";
import WrappedWaitlistUserCreateForm from "../../forms/create/WrappedWaitlistUserCreateForm";
import CreateWaitlistUserForm from "../../forms/create/CreateWaitlistUserForm";
import {CheckBox} from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";

const updateApiUrl = process.env.REACT_APP_SHARED_PUBLIC_CLIENT_API;

export default function ConfirmUpdateSharedPublicClientDialog({ openProp,
                                                                  handleCloseProp,
                                                                  handleUpdateDoneProp,
                                                                  pubClientRecProp,
                                                                  pubClientFormValsDiffProp,
                                                                  newAnimalsArrProp,
                                                                  showLogs,
                                                                  ...props }) {
    const [open, setOpen] = useState(false);
    const [pubClient, setPubClient] = useState(null);
    const [updatedFields, setUpdatedFields] = useState(null);
    const [newAnimalsArr, setNewAnimalsArr] = useState(null);
    const [updatedPubClientDisplayFields, setUpdatedPubClientDisplayFields] = useState(null);
    const [updatedPubAnimalsDisplayFieldsArr, setUpdatedPubAnimalsDisplayFieldsArr] = useState(null);
    const [origPubClientEmail, setOrigPubClientEmail] = useState(null);
    const [joinWaitlistIsChecked, setJoinWaitlistIsChecked] = useState(false);
    const [isAddingWaitlistUser, setIsAddingWaitlistUser] = useState(false);
    const [createWaitlistUserRecObj, setCreateWaitlistUserRecObj] = useState(null);

    const [activeStep, setActiveStep] = useState(0);
    const [isUpdatingPubClient, setIsUpdatingPubClient] = useState(false);
    const [updatePubClientResObj, setUpdatePubClientResObj] = useState(null);
    const [updatePubClientError, setUpdatePubClientError] = useState(null);
    const [allUpdateSuccess, setAllUpdateSuccess] = useState(false);

    const [finalStepDialogTitleText, setFinalStepDialogTitleText] = useState(null);

    const { addUpdate: addUpdatedPubClientToIDB, getLatestUpdate: getLatestUpdatedPubClientFromIDB } = useSharedPubClientIDB();

    // useEffect( if (openProp) { setOpen(true);
    useEffect(() => {
        if (openProp) {
            setOpen(true);
        }
    }, [openProp]);

    // useEffect( setPubClient(pubClientRecProp);
    useEffect(() => {
        if (pubClientRecProp) {
            setPubClient(pubClientRecProp);
        }
    }, [pubClientRecProp]);

    // useEffect( setOrigPubClientEmail(props.origEmailProp);
    useEffect(() => {
        if (props.origEmailProp) {
            setOrigPubClientEmail(props.origEmailProp);
        }
    }, [props.origEmailProp]);

    // useEffect( console.log( props, origPubClientEmail, pubClient
    useEffect(() => {
        if (showLogs) {
            console.log('### ConfirmUpdateSharedPublicClientDialog props', props);
            console.log('### ConfirmUpdateSharedPublicClientDialog origPubClientEmail', origPubClientEmail);
            console.log('### ConfirmUpdateSharedPublicClientDialog pubClient', pubClient);
            console.log('### ConfirmUpdateSharedPublicClientDialog isAddingWaitlistUser', isAddingWaitlistUser);
            console.log('### ConfirmUpdateSharedPublicClientDialog createWaitlistUserRecObj', createWaitlistUserRecObj);
        }
    }, [props, showLogs, origPubClientEmail, pubClient, isAddingWaitlistUser, createWaitlistUserRecObj]);

    // useEffect( setNewAnimalsArr(newAnimalsArrProp);
    useEffect(() => {
        if (newAnimalsArrProp && newAnimalsArrProp.length > 0) {
            setNewAnimalsArr(newAnimalsArrProp);
            // setActiveStep(1);
        }
    }, [newAnimalsArrProp]);

    // useEffect(() => {
    //     if (pubClientFormValsDiffProp && Object.keys(pubClientFormValsDiffProp).length > 0) {
    //         setUpdatedFields(pubClientFormValsDiffProp);
    //         setActiveStep(1);
    //     }
    //     // else {
    //     //     setActiveStep(2);
    //     // }
    // }, [pubClientFormValsDiffProp]);

    // useEffect( if (pubClientFormValsDiffProp) { setUpdatedFields(pubClientFormValsDiffProp);
    useEffect(() => {
        if (pubClientFormValsDiffProp  && Object.keys(pubClientFormValsDiffProp).length > 0) {
            // setUpdatedFields(pubClientFormValsDiffProp);
            const updatedFieldsObj = {};
            Object.entries(pubClientFormValsDiffProp).forEach(([key, value]) => {
                if (!formFieldsToIgnore.includes(key)) {
                    updatedFieldsObj[key] = value;
                }
            });
            setUpdatedFields(updatedFieldsObj);
            setActiveStep(1);
        }
    }, [pubClientFormValsDiffProp]);

    // useEffect( setUpdatedPubClientDisplayFields(updatedDisplayFieldsObj);
    useEffect(() => {
        if (updatedFields && Object.keys(updatedFields).length > 0) {
            const updatedDisplayFieldsObj = {};
            Object.entries(updatedFields).forEach(([key, value]) => {
              if (!formFieldsToIgnore.includes(key)) {
                const titleCaseKey = camelCaseToTitleCase(key);
                  updatedDisplayFieldsObj[titleCaseKey] = value;
              }
            });
            setUpdatedPubClientDisplayFields(updatedDisplayFieldsObj);
        }
    }, [updatedFields]);

    // useEffect( if (newAnimalsArr && newAnimalsArr.length > 0) { setUpdatedPubAnimalsDisplayFieldsArr(updatedDisplayFieldsArr);
    useEffect(() => {
        if (newAnimalsArr && newAnimalsArr.length > 0) {
            const updatedDisplayFieldsArr = [];
            newAnimalsArr.forEach((animal, idx) => {
                const updatedDisplayFieldsObj = {};
                Object.entries(animal).forEach(([key, value]) => {
                    if (!formFieldsToIgnore.includes(key)) {
                        const titleCaseKey = camelCaseToTitleCase(key);
                        updatedDisplayFieldsObj[titleCaseKey] = value;
                    }
                });
                updatedDisplayFieldsArr.push(updatedDisplayFieldsObj);
            });
            setUpdatedPubAnimalsDisplayFieldsArr(updatedDisplayFieldsArr);
        }
    }, [newAnimalsArr]);

    // useEffect( if (updatePubClientResObj) { if (handleUpdateDoneProp) { handleUpdateDoneProp(updatePubClientResObj);
    useEffect(() => {
        if (updatePubClientResObj) {
            if (handleUpdateDoneProp) {
                handleUpdateDoneProp(updatePubClientResObj);
            }
            // try {
            //     if (updatePubClientResObj.addAnimalsSuccess
            //         && updatePubClientResObj.addPubAnimalsSuccess
            //         && updatePubClientResObj.updateClientSuccess
            //         && updatePubClientResObj.updatePubClientSuccess) {
            //         setAllUpdateSuccess(true);
            //         setFinalStepDialogTitleText('Update Done');
            //     }
            // }
            // catch (e) {
            //     console.error(e);
            // }
        }
    }, [updatePubClientResObj]);

    async function handleUpdatePubClient() {
        setIsUpdatingPubClient(true);
        const updateStartTime = Date.now();

        const response = await fetch(updateApiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': pubClient.formSubmitApiKey,  // Include the API key in the header
            },
            body: JSON.stringify({...updatedFields, id: pubClient.id, animals: newAnimalsArr}),
            // body: {...pubClientFormValsDiffObj, id: publicClientRec.id.toString()},
        });

        if (!response.ok) {
            // Handle non-2xx responses here (e.g., 404, 500)
            // throw new Error(`HTTP error! status: ${response.status}`);
            setUpdatePubClientError(`HTTP error! status: ${response.status}`);
        }
        else {
            const resData = await response.json();
            if (showLogs) console.log('!!! >>> resData', resData);
            setUpdatePubClientResObj(resData);
            try {
                if (resData.addAnimalsSuccess
                    && resData.addPubAnimalsSuccess
                    && resData.updateClientSuccess
                    && resData.updatePubClientSuccess) {
                    setAllUpdateSuccess(true);
                    // setFinalStepDialogTitleText('Update Done');
                }
            }
            catch (e) {
                console.error(e);
            }
        }

        // const resData = await response.json();
        // if (showLogs) console.log('!!! >>> resData', resData);

        // setUpdatePubClientError(null);
        // setUpdatePubClientResObj(null);

        // const updateRes = await updateClientRec(ampUser, clientRec, updatedFields);
        // setUpdateClientResObj(updateRes);
        setIsUpdatingPubClient(false);
        setActiveStep(4);
    }

    const handleClose = () => {
        if (showLogs) console.log('### ConfirmUpdateSharedPublicClientDialog handleClose');
        if (handleCloseProp) {
            if (!isUpdatingPubClient) {
                // if (handleUpdateDoneProp) {
                //     handleUpdateDoneProp(updatePubClientResObj);
                // }
                handleCloseProp();
            }
            else {
                alert('Please wait for the update to complete before closing the dialog.');
            }
        }
    }

    const handleCancel = () => {
        setActiveStep(0);
        handleClose();
    };

    const renderConfirmUpdatedPubClientDataStep1 = () => (
        <>
            <DialogTitle>Updating Your Info and Animals</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <h3>Fields to Update</h3>
                    {updatedPubClientDisplayFields ? Object.entries(updatedPubClientDisplayFields).map(([key, value], idx) => {
                        return (
                            <DialogContentText key={`${idx}-${key}`}>
                                {`${key}: ${value}`}
                            </DialogContentText>
                        )
                    }) : null}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                    disabled={isUpdatingPubClient}
                >
                    Close
                </Button>
                <Button
                    onClick={() => setActiveStep(2)}
                    variant="contained"
                    color="primary"
                    disabled={isUpdatingPubClient}
                >
                    Next
                </Button>
            </DialogActions>
        </>
    );

    const renderConfirmNewAnimalsDataStep2 = () => (
        <>
            <DialogTitle>Updating Your Info and Animals</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <h3>Animals to Add</h3>
                    {updatedPubAnimalsDisplayFieldsArr ? updatedPubAnimalsDisplayFieldsArr.map((animal, idx) => {
                        return (
                            <Grid container key={idx} spacing={2}>
                                {Object.entries(animal).map(([key, value], idx) => {
                                    return (
                                        <Grid item key={`${idx}-${key}`}>
                                            <Typography variant="body1">
                                                {`${key}: ${value}`}
                                            </Typography>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        )}) : null}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    variant="contained"
                    color="error"
                    disabled={isUpdatingPubClient}
                >
                    Close
                </Button>
                <Button
                    onClick={() => setActiveStep(1)}
                    variant="contained"
                    color="primary"
                    disabled={isUpdatingPubClient}
                >
                    Back
                </Button>
                <Button
                    // onClick={handleUpdatePubClient}
                    onClick={() => setActiveStep(3)}
                    variant="contained"
                    color="primary"
                    disabled={isUpdatingPubClient}
                >
                    Next
                </Button>
            </DialogActions>
        </>
    );

    // TODO - START HERE, FR, FR, MAKE SURE ADD WAITLIST USER WORKS
    const renderJoinWaitlistStep3 = () => (
        <>
            <DialogTitle>Join SoleFrog's Owner App Waitlist</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Enter your email to join SoleFrog's Owner App wait list.
                </DialogContentText>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox
                            checked={joinWaitlistIsChecked}
                            onChange={(e) => setJoinWaitlistIsChecked(e.target.checked)}
                            name="joinWaitlist"
                            color="primary"
                        />}
                        label="Join Waitlist"
                    />
                </FormGroup>
                {/*<CreateWaitlistUserForm*/}
                {/*    // origEmail={pubClient.email}*/}
                {/*    origEmail={origPubClientEmail}*/}
                {/*    showLogs={showLogs}*/}
                {/*    joinWaitlistIsChecked={joinWaitlistIsChecked}*/}
                {/*    setAddingWaitlistUser={setIsAddingWaitlistUser}*/}
                {/*/>*/}
                {joinWaitlistIsChecked && !createWaitlistUserRecObj ? (
                    <CreateWaitlistUserForm
                        // origEmail={pubClient.email}
                        origEmail={origPubClientEmail}
                        showLogs={showLogs}
                        setAddingWaitlistUserProp={setIsAddingWaitlistUser}
                        setCreateWaitlistUserRecObjProp={setCreateWaitlistUserRecObj}
                    />
                ) : null}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    variant="contained"
                    color="warning"
                    disabled={isUpdatingPubClient || isAddingWaitlistUser}
                >
                    Close
                </Button>
                <Button
                    // onClick={handleClose}
                    onClick={handleUpdatePubClient}
                    variant="contained"
                    color="primary"
                    disabled={isUpdatingPubClient || isAddingWaitlistUser}
                >
                    Next
                </Button>
            </DialogActions>
        </>
    )

    const renderFinalStep = () => (
        <>
            <DialogTitle>{finalStepDialogTitleText || 'Update Done'}</DialogTitle>
            <DialogContent>
                {updatePubClientResObj && updatePubClientError ? (
                    <div>
                        <DialogContentText>
                            Error updating your info and animals
                        </DialogContentText>
                        <p>{updatePubClientError}</p>
                    </div>
                ): null}
                {updatePubClientResObj && allUpdateSuccess ? (
                    <div>
                        <DialogContentText>
                            Your info was updated and animals added!
                        </DialogContentText>
                        {/*<DialogContentText>*/}
                        {/*    Enter your email to join SoleFrog's Owner App wait list.*/}
                        {/*</DialogContentText>*/}
                        {/*<CreateWaitlistUserForm*/}
                        {/*    // origEmail={pubClient.email}*/}
                        {/*    origEmail={origPubClientEmail}*/}
                        {/*    showLogs={showLogs}*/}
                        {/*/>*/}
                    </div>
                ): null}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                    disabled={isUpdatingPubClient}
                >
                    Close
                </Button>
            </DialogActions>
        </>
    );

    const renderLoadingStep = () => (
        <>
            <DialogTitle>Loading...</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Loading Client Update Data
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                    disabled={isUpdatingPubClient}
                >
                    Close
                </Button>
                <Button
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                    disabled={isUpdatingPubClient}
                >
                    Cancel
                </Button>
            </DialogActions>
        </>
    );

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            {activeStep === 0 ? renderLoadingStep() :
                activeStep === 1 ? renderConfirmUpdatedPubClientDataStep1() :
                    activeStep === 2 ? renderConfirmNewAnimalsDataStep2() :
                        activeStep === 3 ? renderJoinWaitlistStep3() :
                        renderFinalStep()}
        </Dialog>
    );
}