import {generateUniqueKey} from "./helpers";
import {monthsToMilliseconds} from "./datesTimes";
import { SubscriptionType, ClientStatus } from "../models";

const defaultName = 'name'

export const shoeTaskFormObj = {
    taskRecID: '',
    duration: 0,
    amount: 0,
    addedCost: 0,
    addedTime: 0,
    animalID: '',
    appointmentID: '',
    totalAmount: 0,
    totalDuration: 0,
    formUniqueId: '',
    newTaskForm: false,
}

export const trimTaskFormObj = {
    taskRecID: '',
    duration: 0,
    amount: 0,
    addedCost: 0,
    addedTime: 0,
    animalID: '',
    appointmentID: '',
    totalAmount: 0,
    totalDuration: 0,
    formUniqueId: '',
    newTaskForm: false,
}

export const createApptFormObj = {
    appointmentStatus: '',
    amount: 0,
    apptAmount: 0,
    address: '',
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
    apptDate: null,
    apptTime: null,
    apptDateTime: '',
    duration: 0,
    apptDuration: 0,
    apptNotes: '',
    lastApptNotes: null,
    animalIds: [],
    clientId: null,
    clientName: '',
    gateCode: '',
}

export const editApptFormObj = {
    appointmentStatus: '',
    amount: 0,
    apptAmount: 0,
    address: '',
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
    apptDate: null,
    apptTime: null,
    origApptDateTime: '',
    duration: 0,
    apptDuration: 0,
    apptNotes: '',
    lastApptNotes: null,
    animalIds: [],
    clientId: null,
    clientName: '',
    gateCode: '',
}

export const apptNoteFormObj = {
    note: '',
}

// TODO - UPDATE TEMPERAMENT TO BE A NUMBER
export const editAnimalFormObj = {
    name: '',
    animalStatus: '',
    animalType: '',
    age: 0,
    temperament: 0,
    stallNumber: '',
    breed: '',
    color: '',
    discipline: '',
    isMiniature: false,
    addedShoeTaskAmount: 0,
    addedTrimTaskAmount: 0,
    addedShoeTaskDuration: 0,
    addedTrimTaskDuration: 0,
    shoeTaskCyclePeriodWeeks: 0,
    trimTaskCyclePeriodWeeks: 0,
    birthday: null,
}

export const createAnimalFormObj = {
    name: '',
    animalStatus: '',
    animalType: '',
    age: 0,
    temperament: 0,
    stallNumber: '',
    breed: '',
    color: '',
    discipline: '',
    isMiniature: false,
    addedShoeTaskAmount: 0,
    addedTrimTaskAmount: 0,
    addedShoeTaskDuration: 0,
    addedTrimTaskDuration: 0,
    shoeTaskCyclePeriodWeeks: 0,
    trimTaskCyclePeriodWeeks: 0,
    birthday: null,
}

export const animalFormsTooltipTextsObj = {
    temperament: 'Enter the temperament as a number, 1-10, with 1 being the calmest and 10 being the most aggressive.',
    addedShoeTaskAmount: 'If this animal has any additional cost to shoe, enter that here, in dollars.',
    addedTrimTaskAmount: 'If this animal has any additional cost to trim, enter that here, in dollars.',
    addedShoeTaskDuration: 'If this animal has any additional time to shoe, enter that here, in minutes.',
    addedTrimTaskDuration: 'If this animal has any additional time to trim, enter that here, in minutes.',
    shoeTaskCyclePeriodWeeks: 'Enter the number of weeks between shoeing appointments.',
    trimTaskCyclePeriodWeeks: 'Enter the number of weeks between trimming appointments.',
    shoePackageFrontLeft: 'Enter the shoe type for the front left foot.',
    shoePackageFrontRight: 'Enter the shoe type for the front right foot.',
    shoePackageRearLeft: 'Enter the shoe type for the rear left foot.',
    shoePackageRearRight: 'Enter the shoe type for the rear right foot.',
}

export const createAnimalSubsetAttrs = [
    'breed',
    'name',
    'age',
    'color',
    'discipline',
    'animalStatus',
    'animalType',
]

export const createShoePackageValuesFormObj = {
    frontLeft: '',
    frontRight: '',
    rearLeft: '',
    rearRight: '',
}

export const editShoePackageValuesFormObj = {
    frontLeft: '',
    frontRight: '',
    rearLeft: '',
    rearRight: '',
}

export const animalNoteFormObj = {
    note: '',
}

export const clientFormsTooltipTextsObj = {
    balance: "Enter the client's current, outstanding balance, in dollars.",
    addedApptAmount: "Enter any additional cost for each of this client's appointment, in dollars.",
    addedApptDuration: "Enter any additional time for each of this client's appointment, in minutes.",
    streetAddress: "Enter the client's street address, for example: 123 Oak St.",
}

export const ampUserFormsTooltipTextsObj = {
    defaultShoeTaskAmount: "Enter the default cost for shoeings, in dollars.",
    defaultTrimTaskAmount: "Enter the default cost for trims, in dollars.",
    defaultShoeTaskDuration: "Enter the default duration for shoeings, in minutes.",
    defaultTrimTaskDuration: "Enter the default duration for trims, in minutes.",
}

export const createClientFormObj = {
    name: '',
    phone: '',
    phoneE164: '',
    email: '',
    balance: 0,
    clientStatus: "",
    streetAddress: '',
    ampuserID: '',
    city: '',
    state: '',
    zipCode: '',
    gateCode: '',
    addedApptAmount: 0,
    addedApptDuration: 0,
    checked: false,
    clientStarted: '',
    alternateContactName: '',
    alternateContactPhone: '',
    alternateContactPhoneE164: '',
    publicClientEditingEnabled: false,
}

export const editClientFormObj = {
    name: '',
    phone: '',
    phoneE164: '',
    email: '',
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    clientStatus: '',
    balance: 0,
    checked: false,
    gateCode: '',
    addedApptAmount: 0,
    addedApptDuration: 0,
    lastApptId: '',
    lastPaymentDateTime: '',
    nextApptId: '',
    clientStarted: '',
    clientEnded: '',
    publicClientEditingEnabled: false,
    alternateContactName: '',
    alternateContactPhone: '',
    alternateContactPhoneE164: '',
}

export const clientNoteFormObj = {
    note: '',
}

export const editAmpUserFormObj = {
    name: '',
    phone: '',
    phoneE164: '',
    email: '',
    city: '',
    state: '',
    zipCode: '',
    // country: '',
    streetAddress: '',
    defaultShoeTaskAmount: 0,
    defaultTrimTaskAmount: 0,
    defaultShoeTaskDuration: 0,
    defaultTrimTaskDuration: 0,
    ampUserTypes: [],
    intakeFormCompleted: null,
}

export const endAdornedFormFields = {
    addedShoeTaskDuration: 'mins.',
    addedTrimTaskDuration: 'mins.',
    shoeTaskCyclePeriodWeeks: 'weeks',
    trimTaskCyclePeriodWeeks: 'weeks',
    addedApptDuration: 'mins.',
    defaultShoeTaskDuration: 'mins.',
    defaultTrimTaskDuration: 'mins.',
    age: 'years',
}

export const numericFormFields = [
    'age',
    'temperament',
    'addedShoeTaskAmount',
    'addedTrimTaskAmount',
    'addedShoeTaskDuration',
    'addedTrimTaskDuration',
    'shoeTaskCyclePeriodWeeks',
    'trimTaskCyclePeriodWeeks',
    'balance',
    'addedApptAmount',
    'addedApptDuration',
    'defaultShoeTaskAmount',
    'defaultTrimTaskAmount',
    'defaultShoeTaskDuration',
    'defaultTrimTaskDuration',
];

export const formFieldsToIgnore = [
    'id',
    'createdAt',
    'updatedAt',
    'phoneE164',
    'alternateContactPhoneE164',
    ];

export const ampUserTypesToDisplay = [
    'FARRIER',
    'OWNER',
    'CLIENT',
];

export const ampUserAttrsDisplayTextObj = {
    defaultShoeTaskAmount: 'Shoeings Amount ($)',
    defaultTrimTaskAmount: 'Trims Amount ($)',
    defaultShoeTaskDuration: 'Shoeings Time (mins.)',
    defaultTrimTaskDuration: 'Trims Time (mins.)',
    ampUserTypes: 'User Types',
}

export const defaultMissingPersonAttrs = {
    name: 'Missing Name',
    phone: 'Missing Phone',
    email: 'Missing Email',
    streetAddress: 'Missing Address',
    city: 'Missing City',
    state: 'Missing State',
    zipCode: 'Missing Zip Code',
    country: 'Missing Country',
    notes: 'No Notes',
    clientStatus: 'Missing Client Status',
    balance: 0,
    checked: false,
    id: generateUniqueKey(defaultName)
}

export const userSubDataObj = {
    Pending: {
        subText: 'Pending',
        subPrice: process.env.REACT_APP_STRIPE_MONTH_PENDING,
        subTypeEnum: SubscriptionType.PENDING,
        periodMs: monthsToMilliseconds(0.25),
        descriptionText: 'Pending Payment',
        subLengthMonths: 0.25
    },
    Month: {
        subText: 'Month',
        subPrice: process.env.REACT_APP_STRIPE_MONTH_PRICE,
        subTypeEnum: SubscriptionType.MONTH,
        periodMs: monthsToMilliseconds(1),
        descriptionText: '1 month',
        subLengthMonths: 1
    },
    Quarter: {
        subText: 'Quarter',
        // subPrice: 33,
        subPrice: process.env.REACT_APP_STRIPE_QUARTER_PRICE,
        subTypeEnum: SubscriptionType.QUARTER,
        periodMs: monthsToMilliseconds(3),
        descriptionText: '3 months',
        subLengthMonths: 3
    },
    Year: {
        subText: 'Year',
        // subPrice: 100,
        subPrice: process.env.REACT_APP_STRIPE_YEAR_PRICE,
        userSubEnum: SubscriptionType.YEAR,
        periodMs: monthsToMilliseconds(12),
        descriptionText: '1 year',
        subLengthMonths: 12
    }
}

export const createPublicClientFormObj = {
    name: '',
    phone: '',
    phoneE164: '',
};

export const editPublicClientFormObj = {
    name: '',
    phone: '',
    phoneE164: '',
    email: '',
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
    publicUpdatingEnabled: false,
    publicClientStatus: '',
    sharedPublicClientStatus: '',
    sharedPublicClientUpdateStatus: '',
    alternateContactName: '',
    alternateContactPhone: '',
    alternateContactPhoneE164: '',
}

export const ampUserEditPublicClientAttrs = [
    'name',
    'phone',
    'phoneE164',
    'email',
    'streetAddress',
    'city',
    'state',
    'zipCode',
    'publicUpdatingEnabled',
    'publicClientStatus',
    'sharedPublicClientStatus',
    'sharedPublicClientUpdateStatus',
    'alternateContactName',
    'alternateContactPhone',
    'alternateContactPhoneE164',
];

export const publicSharedEditPublicClientAttrs = [
    'name',
    'email',
    'phone',
    'phoneE164',
    'streetAddress',
    'city',
    'state',
    'zipCode',
    'alternateContactName',
    'alternateContactPhone',
    'alternateContactPhoneE164',
];

export const createPubAnimalFormObj = {
    age: 0,
    animalType: '',
    birthday: null,
    breed: '',
    color: '',
    discipline: '',
    isMiniature: false,
    name: '',
    stallNumber: '',
}

export const publicSharedPubAnimalAttrs = [
    'age',
    'animalType',
    'birthday',
    'breed',
    'color',
    'discipline',
    'isMiniature',
    'name',
    'stallNumber',
];

