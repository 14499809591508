import React, {useState, useEffect} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import BaseStyledButton from "../../buttons/BaseStyledButton";
import { createAnimalRec, createAnimalNoteRec, createShoePackageRec } from "../../../data/create/animal/createAnimalRecs";
import CircularProgress from "@mui/material/CircularProgress";
import { camelCaseToTitleCase } from "../../../utils/text";
import {useNavigate} from "react-router-dom";


// TODO - START HERE, FR, FR, SORT THE CONFIRM STEPS FOR CREATING NEW RECS, ADD TO CREATE ANIMAL FORM

export default function ConfirmCreateAnimalFullDialog({
                                                          ampUser,
                                                          openProp,
                                                          handleCloseProp,
                                                          handleCreateAnimalDoneProp,
                                                          handleCreateNoteDoneProp,
                                                          handleCreateShoePackageDoneProp,
                                                          animalFormVals,
                                                          animalFormValsDiffObj,
                                                          animalNoteFormVals,
                                                          shoePackageFormVals,
                                                          showLogs,
                                                          ...props
                                                      }) {
    const [open, setOpen] = useState(false);
    const [confirmStep, setConfirmStep] = useState(0);
    const [finalStepButtonsObj, setFinalStepButtonsObj] = useState(null);

    const [pauseOnFormSubmit, setPauseOnFormSubmit] = useState(false);
    const [isCreatingAnimal, setIsCreatingAnimal] = useState(false);
    const [isCreatingNote, setIsCreatingNote] = useState(false);
    const [isCreatingShoePackage, setIsCreatingShoePackage] = useState(false);

    const [createErrorMsg, setCreateErrorMsg] = useState(null);
    const [finalErrorMsg, setFinalErrorMsg] = useState(null);
    const [createSuccessMsg, setCreateSuccessMsg] = useState(null);
    const [finalSuccessMsg, setFinalSuccessMsg] = useState(null);

    const [createAnimalResObj, setCreateAnimalResObj] = useState(null);
    const [createNoteResObj, setCreateNoteResObj] = useState(null);
    const [createShoePackageResObj, setCreateShoePackageResObj] = useState(null);

    const [newAnimalFormDataObj, setNewAnimalFormDataObj] = useState(null);
    const [newAnimalFormDataDiffObj, setNewAnimalFormDataDiffObj] = useState(null);
    const [newNoteFormDataObj, setNewNoteFormDataObj] = useState(null);
    const [newShoePackageFormDataObj, setNewShoePackageFormDataObj] = useState(null);

    const navigate = useNavigate();

    // useEffect( console.log( confirmStep
    useEffect(() => {
        if (showLogs) console.log('### ConfirmCreateAnimalFullDialog confirmStep', confirmStep);
    }, [showLogs, confirmStep]);

    // useEffect( if (openProp) { setOpen(true);
    useEffect(() => {
        if (openProp) {
            setOpen(true);
        }
    }, [openProp]);

    // useEffect( if (animalFormVals) { setNewAnimalFormDataObj(animalFormVals);
    useEffect(() => {
        if (animalFormVals) {
            setNewAnimalFormDataObj(animalFormVals);
            setConfirmStep(1);
        }
    }, [animalFormVals]);

    // useEffect if (animalFormValsDiffObj) { setNewAnimalFormDataDiffObj(animalFormValsDiffObj);
    useEffect(() => {
        if (animalFormValsDiffObj) {
            setNewAnimalFormDataDiffObj(animalFormValsDiffObj);
        }
    }, [animalFormValsDiffObj]);

    // useEffect( if (animalNoteFormVals) { setNewNoteFormDataObj(animalNoteFormVals);
    useEffect(() => {
        if (animalNoteFormVals) {
            setNewNoteFormDataObj(animalNoteFormVals);
        }
    }, [animalNoteFormVals]);

    // useEffect( if (shoePackageFormVals) { setNewShoePackageFormDataObj(shoePackageFormVals);
    useEffect(() => {
        if (shoePackageFormVals) {
            setNewShoePackageFormDataObj(shoePackageFormVals);
        }
    }, [shoePackageFormVals]);

    // useEffect(() => {
    //     if (showLogs) console.log('### ConfirmCreateAnimalFullDialog newShoePackageFormDataObj', newShoePackageFormDataObj);
    // }, [showLogs, newShoePackageFormDataObj]);

    // useEffect( if (createAnimalResObj) { handleCreateAnimalDoneProp(createAnimalResObj);
    useEffect(() => {
        if (createAnimalResObj) {
            handleCreateAnimalDoneProp(createAnimalResObj);
        }
    }, [createAnimalResObj]);

    // useEffect( if (createNoteResObj) { handleCreateNoteDoneProp(createNoteResObj);
    useEffect(() => {
        if (createNoteResObj) {
            handleCreateNoteDoneProp(createNoteResObj);
        }
    }, [createNoteResObj]);

    // useEffect( if (createShoePackageResObj) { handleCreateShoePackageDoneProp(createShoePackageResObj);
    useEffect(() => {
        if (createShoePackageResObj) {
            handleCreateShoePackageDoneProp(createShoePackageResObj);
        }
    }, [createShoePackageResObj]);

    function resetState() {
        setNewAnimalFormDataObj(null);
        setNewNoteFormDataObj(null);
        setNewShoePackageFormDataObj(null);
        setConfirmStep(0);
        // setCreateAnimalResObj(null);
        // setCreateNoteResObj(null);
        // setCreateShoePackageResObj(null);
        setIsCreatingAnimal(false);
        setIsCreatingNote(false);
        setIsCreatingShoePackage(false);
        setCreateErrorMsg(null);
        setCreateSuccessMsg(null);
        setPauseOnFormSubmit(false);
    }

    const handleClose = () => {
        if (showLogs) console.log('### ConfirmCreateAnimalFullDialog handleClose');
        if (handleCloseProp) {
            // if (!pauseOnFormSubmit) {
            if (!pauseOnFormSubmit && !isCreatingAnimal && !isCreatingNote && !isCreatingShoePackage) {
                resetState();
                handleCloseProp();
            }
            else {
                alert('Creating Record in progress...');
            }
        }
    };

    function handleFinalDone() {
        if (showLogs) console.log('### ConfirmCreateAnimalFullDialog handleFinalDone');
        resetState();
        // handleClose();
        navigate('/animals');
    }

    const handleConfirmCreateAnimal = async () => {
        if (showLogs) console.log('### ConfirmCreateAnimalFullDialog handleConfirmCreate');
        setPauseOnFormSubmit(true);
        setIsCreatingAnimal(true);
        try {
            const createAnimalRes = await createAnimalRec(newAnimalFormDataObj, ampUser.id, showLogs);
            setCreateAnimalResObj(createAnimalRes);
            if (createAnimalRes?.success) {
                if (showLogs) console.log('### ConfirmCreateAnimalFullDialog handleConfirmCreate createAnimalRes', createAnimalRes);
                setCreateSuccessMsg('New Animal record created!');
                setFinalSuccessMsg('New record created successfully!');
                setIsCreatingAnimal(false);
                setConfirmStep(2);
            } else {
                setCreateErrorMsg(createAnimalRes?.error || 'Error creating animal record');
                setFinalErrorMsg('Error creating animal record');
                setIsCreatingAnimal(false);
                setConfirmStep(7);
            }
        }
        catch (error) {
            setCreateErrorMsg(error);
            setFinalErrorMsg('Error creating animal record');
            setIsCreatingAnimal(false);
            setConfirmStep(7);
        }
        setPauseOnFormSubmit(false);
    };

    const handleConfirmCreateNote = async () => {
        if (showLogs) console.log('### ConfirmCreateAnimalFullDialog handleConfirmCreateNote');
        setPauseOnFormSubmit(true);
        setIsCreatingNote(true);
        try {
            const createNoteRes = await createAnimalNoteRec(newNoteFormDataObj.note, createAnimalResObj?.newAnimalRec.id, ampUser.id, showLogs);
            setCreateNoteResObj(createNoteRes);
            if (createNoteRes?.success) {
                if (showLogs) console.log('### ConfirmCreateAnimalFullDialog handleConfirmCreateNote createNoteRes', createNoteRes);
                setCreateSuccessMsg('New Note record created!')
                setFinalSuccessMsg('New records created successfully!')
                setIsCreatingNote(false);
                setConfirmStep(4);
            } else {
                setCreateErrorMsg(createNoteRes?.error || 'Error creating note record');
                setFinalErrorMsg('Error creating note record');
                setIsCreatingNote(false);
                setConfirmStep(7);
            }
        }
        catch (error) {
            setCreateErrorMsg(error);
            setFinalErrorMsg('Error creating note record');
            setIsCreatingNote(false);
            setConfirmStep(7);
        }
        setPauseOnFormSubmit(false);
    }

    const handleConfirmCreateShoePackage = async () => {
        if (showLogs) console.log('### ConfirmCreateAnimalFullDialog handleConfirmCreateShoePackage');
        setPauseOnFormSubmit(true);
        setIsCreatingShoePackage(true);
        try {
            const createShoePackageRes = await createShoePackageRec(newShoePackageFormDataObj, createAnimalResObj?.newAnimalRec.id, ampUser.id, showLogs);
            setCreateShoePackageResObj(createShoePackageRes);
            if (createShoePackageRes?.success) {
                if (showLogs) console.log('### ConfirmCreateAnimalFullDialog handleConfirmCreateShoePackage createShoePackageRes', createShoePackageRes);
                setCreateSuccessMsg('New Shoe Package record created!');
                setFinalSuccessMsg('New records created successfully!')
                setIsCreatingShoePackage(false);
                setConfirmStep(6);
            } else {
                setCreateErrorMsg(createShoePackageRes?.error || 'Error creating shoe package record');
                setFinalErrorMsg('Error creating shoe package record');
                setIsCreatingShoePackage(false);
                setConfirmStep(7);
            }
        }
        catch (error) {
            setCreateErrorMsg(error);
            setFinalErrorMsg('Error creating shoe package record');
            setIsCreatingShoePackage(false);
            setConfirmStep(7);
        }
        setPauseOnFormSubmit(false);
    }

    const handleCreateAnimalResultNextStep = () => {
        try {
            if (newNoteFormDataObj && newNoteFormDataObj?.note && newNoteFormDataObj?.note?.length > 0) {
                setConfirmStep(3);
            }
            else if (newShoePackageFormDataObj) {
                if (Object.keys(newShoePackageFormDataObj).length > 0) {
                    setConfirmStep(5);
                }
            }
            else {
                setConfirmStep(7);
            }
        }
        catch (e) {
            if (showLogs) console.error('handleCreateAnimalResultNextStep error', e);
            setConfirmStep(7);
        }
    }

    const handleCreateNoteResultNextStep = () => {
        try {
            if (newShoePackageFormDataObj ) {
                if (Object.keys(newShoePackageFormDataObj).length > 0) {
                    setConfirmStep(5);
                }
            }
            else {
                setConfirmStep(7);
            }
        }
        catch (e) {
            if (showLogs) console.error('handleCreateNoteResultNextStep error', e);
            setConfirmStep(7);
        }
    }

    const renderConfirmCreateAnimalStep1 = () => {
        return (
            <>
                <DialogTitle>
                    Confirm Create Animal
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Confirm you want to create this animal
                    </DialogContentText>
                    {newAnimalFormDataObj ? (
                        Object.entries(newAnimalFormDataDiffObj).map(([key, value]) => {
                            if (!['clientId'].includes(key)) {
                                return (
                                    <DialogContentText key={key}>
                                        {camelCaseToTitleCase(key)}: {value || '-'}
                                    </DialogContentText>
                                )
                            }
                        }
                    )) : null}
                </DialogContent>
                <DialogActions>
                    <BaseStyledButton
                        label={isCreatingAnimal ? <CircularProgress size={24} /> : 'Confirm'}
                        // onClick={handleConfirmCreateAnimalStep1}
                        onClick={async () => await handleConfirmCreateAnimal()}
                        color={'primary'}
                        disabled={isCreatingAnimal || isCreatingNote || isCreatingShoePackage}
                    />
                    <BaseStyledButton
                        disabled={isCreatingAnimal || isCreatingNote || isCreatingShoePackage}
                        label={'Cancel'}
                        onClick={handleClose}
                    />
                </DialogActions>
            </>
        )
    }

    const renderCreateAnimalResultStep2 = () => {
        return (
            <>
                <DialogTitle>
                    Create Animal Result
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {createSuccessMsg || createErrorMsg}
                    </DialogContentText>
                    {createAnimalResObj ? (
                        <DialogContentText>
                            {createAnimalResObj?.success ? 'Animal created successfully' : createAnimalResObj?.error}
                        </DialogContentText>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    <BaseStyledButton
                        disabled={isCreatingAnimal || isCreatingNote || isCreatingShoePackage}
                        label={'Next'}
                        onClick={handleCreateAnimalResultNextStep}
                    />
                </DialogActions>
            </>
        )
    }

    const renderConfirmCreateNoteStep3 = () => {
        return (
            <>
                <DialogTitle>
                    Confirm Create Note
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Confirm you want to create this note
                    </DialogContentText>
                    {newNoteFormDataObj && newNoteFormDataObj?.note && newNoteFormDataObj?.note?.length > 0 ? (
                        <DialogContentText>
                            Note: {newNoteFormDataObj?.note}
                        </DialogContentText>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    <BaseStyledButton
                        disabled={isCreatingAnimal || isCreatingNote || isCreatingShoePackage}
                        // label={'Confirm'}
                        label={isCreatingNote ? <CircularProgress size={24} /> : 'Confirm'}
                        // onClick={handleConfirmCreateNoteStep3}
                        color={'primary'}
                        onClick={async () => await handleConfirmCreateNote()}
                    />
                    <BaseStyledButton
                        disabled={isCreatingAnimal || isCreatingNote || isCreatingShoePackage}
                        label={'Cancel'}
                        onClick={handleClose}
                    />
                </DialogActions>
            </>
        )
    }

    const renderCreateNoteResultStep4 = () => {
        return (
            <>
                <DialogTitle>
                    Create Note Result
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {createSuccessMsg || createErrorMsg}
                    </DialogContentText>
                    {/*{createNoteResObj ? (*/}
                    {/*    <DialogContentText>*/}
                    {/*        {createNoteResObj?.message}*/}
                    {/*    </DialogContentText>*/}
                    {/*) : null}*/}
                </DialogContent>
                <DialogActions>
                    <BaseStyledButton
                        disabled={isCreatingAnimal || isCreatingNote || isCreatingShoePackage}
                        label={'Next'}
                        // onClick={handleShowCreateNoteResultStep4}
                        onClick={handleCreateNoteResultNextStep}
                    />
                    {/*<BaseStyledButton*/}
                    {/*    disabled={isCreatingAnimal || isCreatingNote || isCreatingShoePackage}*/}
                    {/*    label={'Cancel'}*/}
                    {/*    onClick={handleClose}*/}
                    {/*/>*/}
                </DialogActions>
            </>
        )
    }

    const renderConfirmCreateShoePackageStep5 = () => {
        return (
            <>
                <DialogTitle>
                    Confirm Create Shoe Package
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Confirm you want to create this shoe package
                    </DialogContentText>
                    {newShoePackageFormDataObj ? (
                        Object.entries(newShoePackageFormDataObj).map(([key, value]) => {
                            return (
                                <DialogContentText key={key}>
                                    {key}: {value || '-'}
                                </DialogContentText>
                            )
                        }
                    )) : null}
                </DialogContent>
                <DialogActions>
                    <BaseStyledButton
                        disabled={isCreatingAnimal || isCreatingNote || isCreatingShoePackage}
                        // label={'Confirm'}
                        color={'primary'}
                        label={isCreatingShoePackage ? <CircularProgress size={24} /> : 'Confirm'}
                        // onClick={handleConfirmCreateShoePackageStep5}
                        onClick={async () => await handleConfirmCreateShoePackage()}
                    />
                    <BaseStyledButton
                        disabled={isCreatingAnimal || isCreatingNote || isCreatingShoePackage}
                        label={'Cancel'}
                        onClick={handleClose}
                    />
                </DialogActions>
            </>
        )
    }

    const renderCreateShoePackageResultStep6 = () => {
        return (
            <>
                <DialogTitle>
                    Create Shoe Package Result
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {createSuccessMsg || createErrorMsg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <BaseStyledButton
                        disabled={isCreatingAnimal || isCreatingNote || isCreatingShoePackage}
                        label={'Next'}
                        // onClick={handleShowCreateShoePackageResultStep6}
                        onClick={() => setConfirmStep(7)}
                    />
                    {/*<BaseStyledButton*/}
                    {/*    disabled={isCreatingAnimal || isCreatingNote || isCreatingShoePackage}*/}
                    {/*    label={'Cancel'}*/}
                    {/*    onClick={handleClose}*/}
                    {/*/>*/}
                </DialogActions>
            </>
        )
    }

    const renderFinalStep7 = () => {
        return (
            <>
                <DialogTitle>
                    Create Confirmation
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {finalSuccessMsg || finalErrorMsg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <BaseStyledButton
                        disabled={isCreatingAnimal || isCreatingNote || isCreatingShoePackage}
                        label={'Done'}
                        onClick={handleFinalDone}
                    />
                </DialogActions>
            </>
        )
    }

    const renderLoadingStep = () => {
        return (
            <>
                <DialogTitle>
                    Loading
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Loading...
                    </DialogContentText>
                </DialogContent>
            </>
        )
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="confirm-create-animal-full-dialog-title"
            aria-describedby="confirm-create-animal-full-dialog-description"
        >
            {confirmStep === 0 ? renderLoadingStep() :
                confirmStep === 1 ? renderConfirmCreateAnimalStep1() :
                    confirmStep === 2 ? renderCreateAnimalResultStep2() :
                        confirmStep === 3 ? renderConfirmCreateNoteStep3() :
                            confirmStep === 4 ? renderCreateNoteResultStep4() :
                                confirmStep === 5 ? renderConfirmCreateShoePackageStep5() :
                                    confirmStep === 6 ? renderCreateShoePackageResultStep6() :
                                        confirmStep === 7 ? renderFinalStep7() :
                                            renderLoadingStep()
            }
        </Dialog>
    );
}
