import React, { useState, useEffect, useContext } from 'react';
import { DataStore, SortDirection } from "aws-amplify";
import { Client, PublicClient, SharedPublicClientUpdateStatus } from '../../../models';

const FarrierClientAlertsContext = React.createContext();


export function FarrierClientAlertsProvider({ children, ampUserIdProp, showLogsProp, resetProviderState, ...props }) {
    const [ampUserId, setAmpUserId] = useState(null);
    const [localShowLogs, setLocalShowLogs] = useState(false);
    const [farrierClientIds, setFarrierClientIds] = useState([]);
    const [farrierPublicClientIds, setFarrierPublicClientIds] = useState([]);
    const [clientUpdatedSharedPublicClientRecs, setClientUpdatedSharedPublicClientRecs] = useState([]);

    const [error, setError] = useState(null);

    const resetContext = () => {
        setAmpUserId(null);
        setFarrierClientIds([]);
        setFarrierPublicClientIds([]);
        setClientUpdatedSharedPublicClientRecs([]);
    }

    // useEffect( reset state values when resetProviderState is true
    useEffect(() => {
        if (resetProviderState) {
            resetContext();
        }
    }, [resetProviderState]);

    // useEffect( set localShowLogs when showLogs changes
    useEffect(() => {
        if (showLogsProp) {
            setLocalShowLogs(true);
        }
        else {
            setLocalShowLogs(false);
        }
    }, [showLogsProp]);

    // useEffect( setAmpUserId(ampUserIdProp);
    useEffect(() => {
        if (ampUserIdProp) {
            setAmpUserId(ampUserIdProp);
        }
    }, [ampUserIdProp]);

    // useEffect( console.log( farrierPublicClientIds, farrierClientIds
    useEffect(() => {
        if (localShowLogs) {
            console.log('FarrierClientAlertsProvider farrierClientIds', farrierClientIds);
            console.log('FarrierClientAlertsProvider farrierPublicClientIds', farrierPublicClientIds);
        }
    }, [localShowLogs, farrierPublicClientIds, farrierClientIds]);

    // useEffect( console.log( clientUpdatedSharedPublicClientRecs
    useEffect(() => {
        if (localShowLogs) {
            console.log('FarrierClientAlertsProvider clientUpdatedSharedPublicClientRecs', clientUpdatedSharedPublicClientRecs);
        }
    }, [localShowLogs, clientUpdatedSharedPublicClientRecs]);

    // useEffect( console.log( ampUserId
    useEffect(() => {
        if (localShowLogs) {
            console.log('FarrierClientAlertsProvider ampUserId', ampUserId);
        }
    }, [localShowLogs, ampUserId]);


    // useEffect DataStore.observeQuery for PublicClient records, where ampuserID equals ampUserId, where id is in farrierPublicClientIds, and where sharedPublicClientUpdateStatus is CLIENT_UPDATED, and where isDeleted is not true
    useEffect(() => {
        // if (!resetProviderState && ampUserId && farrierPublicClientIds.length > 0) {
        if (!resetProviderState && ampUserId) {
            const subscription = DataStore.observeQuery(PublicClient,
                (pc) => pc.and( pc => [
                    pc.ampuserID.eq(ampUserId),
                    pc.sharedPublicClientUpdateStatus.eq(SharedPublicClientUpdateStatus.CLIENT_UPDATED),
                    pc.isDeleted.ne(true)
            ]),
                {
                sort: s => s.updatedAt(SortDirection.DESCENDING)
            })
            .subscribe(async msg => {
                const {items, isSynced} = msg;
                if (isSynced) {
                    if (localShowLogs) {
                        console.log('FarrierClientAlertsProvider PublicClient.sharedPublicClientUpdateStatus = CLIENT_UPDATED items', items);
                    }
                    // if (items && items?.length > 0) {
                    if (items) {
                        // if (clientUpdatedSharedPublicClientRecs.length === 0) {
                        //     setClientUpdatedSharedPublicClientRecs(items);
                        // }
                        // else {
                        //     const updatedClientUpdatedSharedPublicClientRecs = [];
                        //     items.forEach(item => {
                        //         const index = clientUpdatedSharedPublicClientRecs.findIndex(rec => rec.id === item.id);
                        //         if (index >= 0) {
                        //             updatedClientUpdatedSharedPublicClientRecs.push(item);
                        //         }
                        //     })
                        //     setClientUpdatedSharedPublicClientRecs(updatedClientUpdatedSharedPublicClientRecs);
                        // }
                        setClientUpdatedSharedPublicClientRecs(items);
                    }
                }
            });

            return () => subscription.unsubscribe();
        }
    // }, [ampUserId, farrierPublicClientIds, localShowLogs, resetProviderState]);
    }, [ampUserId, localShowLogs, resetProviderState]);


    return (
        <FarrierClientAlertsContext.Provider value={{
            error,
            farrierClientIds,
            setFarrierClientIds,
            farrierPublicClientIds,
            setFarrierPublicClientIds,
            clientUpdatedSharedPublicClientRecs
        }}>
            {children}
        </FarrierClientAlertsContext.Provider>
    );
}

export const useFarrierClientAlertsProvider = () => {
    return useContext(FarrierClientAlertsContext);
}
