import React, { useState, useEffect } from 'react';
import StyledListCard from "../cards/StyledListCard";
import StyledCardContentTypography from "../text/StyledCardContentTypography";
import BaseStyledButton from "../buttons/BaseStyledButton";
import CardContent from "@mui/material/CardContent";
import { CardActions } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFarrierAlertsIDBProvider } from "../../data/providers/alerts/FarrierAlertsIDBProvider";
import { farrierAlertsDB } from "../../data/providers/idb/alerts/FarrierAlertsDB";
import { useConsoleLog } from "../hooks/logging/console";
import FarrierAlertViewButton from "../buttons/FarrierAlertViewButton";
import Box from "@mui/material/Box";
import AlertNotifCardIconButton from "../buttons/AlertNotifCardIconButton";
import PersonIcon from '@mui/icons-material/Person';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PaidIcon from '@mui/icons-material/Paid';


export default function FarrierPinnedNotif(props) {
    const [ampUserRec, setAmpUserRec] = useState(null);
    const [alertIDBRec, setAlertIDBRec] = useState(null);
    const [alertStoreName, setAlertStoreName] = useState(null);
    const [showLogs, setShowLogs] = useState(false);
    const [cardIcon, setCardIcon] = useState(null);

    // TODO - start here, add unpin actions
    const { setIsUpdatingPinnedAlerts, unPinAlert } = useFarrierAlertsIDBProvider();

    const navigate = useNavigate();

    useConsoleLog(showLogs, [{label: 'FarrierPinnedNotif alertIDBRec', value: alertIDBRec}]);
    useConsoleLog(showLogs, [{label: 'FarrierPinnedNotif alertStoreName', value: alertStoreName}]);
    useConsoleLog(showLogs, [{label: 'FarrierPinnedNotif ampUserRec', value: ampUserRec}]);

    // useEffect( set showLogs from props.showLogs
    useEffect(() => {
        if (props?.showLogs) {
            setShowLogs(props.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [props.showLogs]);

    // useEffect( set alertIDBRec from props.alertIDBRec
    useEffect(() => {
        if (props?.alertIDBRec) {
            setAlertIDBRec(props.alertIDBRec);
        }
        // }, [props.alertIDBRec]);
    }, [props]);

    // useEffect( set ampUserRec from props.ampUserRec
    useEffect(() => {
        if (props?.ampUserRec) {
            setAmpUserRec(props.ampUserRec);
        }
    }, [props.ampUserRec]);

    // useEffect( set alertStoreName from alertIDBRec.alertType
    useEffect(() => {
        if (alertIDBRec?.alertType) {
            const pinnedNotifStoreName = returnStoreNameByAlertType(alertIDBRec.alertType);
            if (pinnedNotifStoreName) {
                setAlertStoreName(pinnedNotifStoreName);
            }
        }
    }, [alertIDBRec]);

    function returnStoreNameByAlertType(alertType) {
        switch (alertType) {
            case "clientUpdatedSharedPublicClient":
                return farrierAlertsDB.stores.farrierPublicClientAlertsStoreName;
            default:
                return;
        }
    }

    async function handleUnpinPinnedNotif() {
        if (!alertIDBRec) {
            if (showLogs) {
                console.log('handleUnpinPinnedNotif alertIDBRec is null');
            }
            return;
        }
        if (!alertStoreName) {
            if (showLogs) {
                console.log('handleUnpinPinnedNotif alertStoreName is null');
            }
            return;
        }
        setIsUpdatingPinnedAlerts(true);
        await unPinAlert(alertIDBRec.alertId, alertStoreName);
        setIsUpdatingPinnedAlerts(false);
    }

    function setCardStateValsByAlertType(alertType) {
        // console.log('Setting ackAlertHandlerFunc for alert type:', alertType);
        switch (alertType) {
            case "clientUpdatedSharedPublicClient":
                setCardIcon(<PersonIcon/>);
                // const storeName = farrierAlertsDB.stores.farrierPublicClientAlertsStoreName;

                // setAlertStoreName(farrierAlertsDB.stores.farrierPublicClientAlertsStoreName);

                // setAlertStoreName(storeName);
                // console.log("handleAckFarrierAlertNotif type:", typeof handleAckFarrierAlertNotif); // Should output "function"
                break;
            case "farrierAlert":
                setCardIcon(<PriorityHighIcon/>);
                break;
            case "farrierPaymentAlert":
                // setCardIcon(<AttachMoneyIcon />);
                setCardIcon(<PaidIcon/>);
                break;
            case "farrierNotificationAlert":
                setCardIcon(<NotificationsIcon/>);
                break;
            default:
                setCardIcon(<NotificationsIcon/>);
                return;
        }
    }

    useEffect(() => {
        if (ampUserRec && alertIDBRec) {
            // if (handleAckAlertProp && updateAckedAlertHashesProp) {
            setCardStateValsByAlertType(alertIDBRec.alertType);
        }
        // }, [ampUserRec, alertIDBRec, showLogs, handleAckAlertProp, updateAckedAlertHashesProp]);
    }, [ampUserRec, alertIDBRec, showLogs]);



    return (
        <div>
            <StyledListCard>
                <CardContent>
                    <Box
                        display="flex"
                        alignItems="center"
                        // alignItems="flex-start"
                    >
                        <AlertNotifCardIconButton
                            buttonIconProp={cardIcon}
                            displayBadgeProp={false}
                            badgeValProp={0}
                            showLogsProp={showLogs}
                            notificoncolor="black"
                        />
                        <StyledCardContentTypography
                            text={`Msg: ${alertIDBRec?.alertMsg || ''}`}
                            fontSize={{
                                xs: '1.5rem',
                                sm: '1.5rem',
                                md: '1.5rem',
                                lg: '2rem',
                                xl: '2.5rem',
                            }}
                            // sx={{ marginLeft: '0.5rem' }} // Adjusts spacing between icon and text
                            style={{ marginLeft: '0.5rem', textAlign: 'left' }} // Adjusts spacing between icon and text
                        />
                    </Box>
                </CardContent>
                <CardActions>
                    <FarrierAlertViewButton
                        label={'View'}
                        alertIDBRecProp={alertIDBRec}
                        alertIDBStoreNameProp={alertStoreName}
                        showLogsProp={showLogs}
                        fontSize={{
                            xs: '1.5rem',
                            sm: '1.5rem',
                            md: '1.5rem',
                            lg: '2rem',
                            xl: '2.5rem',
                        }}
                    />
                    <BaseStyledButton
                        label={'Unpin'}
                        onClick={async () => {
                            if (alertIDBRec) {
                                await handleUnpinPinnedNotif();
                            }
                            else {
                                alert('Remove pin button clicked, no unpin handler set for this alert');
                            }
                        }}
                        fontSize={{
                            xs: '1.5rem',
                            sm: '1.5rem',
                            md: '1.5rem',
                            lg: '2rem',
                            xl: '2.5rem',
                        }}
                    />
                </CardActions>
            </StyledListCard>
        </div>
    );
}

