import React, {useState, useEffect} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from "@mui/material/Divider";
import {dateFromAWSDateTime} from "../../../utils/datesTimes";

export default function DeleteShoePackageConfirmationDialog({
                                                                open,
                                                                handleClose,
                                                                handleConfirm,
                                                                modelRecToDelete,
                                                                showLogs,
                                                                animalRec
                                                            }) {
    const [modelRec, setModelRec] = useState(null);
    const [relatedAnimalRec, setRelatedAnimalRec] = useState(null);

    // useEffect( setModelRec(modelRecToDelete); setRelatedAnimalRec(animalRec);
    useEffect(() => {
        if (modelRecToDelete) {
            setModelRec(modelRecToDelete);
        }
        if (animalRec) {
            setRelatedAnimalRec(animalRec);
        }
    }, [modelRecToDelete, animalRec]);

    // useEffect( console.log( modelRec, relatedAnimalRec
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('DeleteShoePackageConfirmationDialog shoePackageRec', modelRec);
    //         console.log('DeleteShoePackageConfirmationDialog relatedAnimalRec', relatedAnimalRec);
    //     }
    // }, [showLogs, modelRec, relatedAnimalRec]);

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{"Confirm Delete Action"}</DialogTitle>
            <DialogContent>
                {modelRec && relatedAnimalRec ? (
                    <div>
                        <Typography>
                            Animal Name: {relatedAnimalRec.name}
                        </Typography>
                        <Typography>
                            Created At: {dateFromAWSDateTime(modelRec.createdAt)}
                        </Typography>
                        <Typography>
                            Front Left: {modelRec.frontLeft}
                        </Typography>
                        <Typography>
                            Front Right: {modelRec.frontRight}
                        </Typography>
                        <Typography>
                            Rear Left: {modelRec.rearLeft}
                        </Typography>
                        <Typography>
                            Rear Right: {modelRec.rearRight}
                        </Typography>
                        <Divider/>
                        <div>
                            <DialogContentText>
                                Are you sure you want to delete this record?
                            </DialogContentText>
                        </div>
                    </div>
                ) : (
                    <Typography>
                        No record found
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                {modelRec && relatedAnimalRec ? (
                    <Button onClick={() => {
                        handleConfirm(modelRec.id)
                    }} autoFocus>
                        Confirm
                    </Button>
                ) : null}
            </DialogActions>
        </Dialog>
    );
}

// export default DeleteShoePackageConfirmationDialog;
