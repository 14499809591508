import React, {useState, useEffect} from 'react';
import UserSubsContext from "../../../contexts/UserSubsContext";
import { DataStore } from '@aws-amplify/datastore';
import { UserSub } from '../../../../models';
import {SortDirection} from "aws-amplify";

// TODO - !!! START HERE, FR FR, FINISH THIS BY IMPORTING WHERE NEEDED,
//  ADD CONTEXT AND PROVIDER FOR SUBPAYMENTVIEW

// const UserSubsProvider = ({ children, ampUserProp, ampUserSubscriberProp, showLogs, ...props }) => {
const UserSubsProvider = ({ children, showLogs, ...props }) => {
    const [userSubRecs, setUserSubRecs] = useState(null);
    const [latestUserSubRec, setLatestUserSubRec] = useState(null);
    const [ampUserRec, setAmpUserRec] = useState(null);
    const [ampUserSubscriberRec, setAmpUserSubscriberRec] = useState(null);

    // useEffect( setAmpUserRec(props.ampUserProp);
    useEffect(() => {
        if (props?.ampUserProp) {
            setAmpUserRec(props.ampUserProp);
        }
    }, [props.ampUserProp]);

    // useEffect( setAmpUserSubscriberRec(props.ampUserSubscriberProp);
    useEffect(() => {
        if (props?.ampUserSubscriberProp) {
            setAmpUserSubscriberRec(props.ampUserSubscriberProp);
        }
    }, [props.ampUserSubscriberProp]);

    // useEffect( console.log( ampUserRec, ampUserSubscriberRec
    useEffect(() => {
        if (showLogs) {
            console.log('ampUserRec', ampUserRec);
            console.log('ampUserSubscriberRec', ampUserSubscriberRec);
        }
    }, [ampUserRec, ampUserSubscriberRec, showLogs]);

    useEffect(() => {
        if (ampUserRec) {
            const subscription = DataStore.observeQuery(UserSub,
                (a => a.ampuserID.eq(ampUserRec.id)),
                {
                    sort: s => s.createdAt(SortDirection.DESCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    if (showLogs) {
                        console.log(`UserSubsProvider a [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                        console.log('UserSubsProvider a [Snapshot] items', items);
                    }
                    if (isSynced) {
                        setUserSubRecs(items);
                        if (items.length > 0) {
                            setLatestUserSubRec(items[0]);
                        }
                    }
                });
            return () => subscription.unsubscribe();
        }
        else if (ampUserSubscriberRec) {
            const subscription = DataStore.observeQuery(UserSub,
                (a => a.ampusersubscriberID.eq(ampUserSubscriberRec.id)),
                {
                    sort: s => s.createdAt(SortDirection.DESCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    if (showLogs) {
                        console.log(`UserSubsProvider b [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                        console.log('UserSubsProvider b [Snapshot] items', items);
                    }
                    if (isSynced) {
                        setUserSubRecs(items);
                        if (items.length > 0) {
                            setLatestUserSubRec(items[0]);
                        }
                    }
                });
            return () => subscription.unsubscribe();
        }
    }, [ampUserRec, ampUserSubscriberRec, showLogs]);

    return (
        <UserSubsContext.Provider value={{
            userSubRecs,
            latestUserSubRec,
            setAmpUserRec,
            setAmpUserSubscriberRec
        }}>
            {children}
        </UserSubsContext.Provider>
    );
}

export default UserSubsProvider;
