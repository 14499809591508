import React, {useState, useEffect} from "react";
import StyledListCard from "./StyledListCard";
import BaseNavigateButton from "../links/BaseNavigateButton";
import StyledCardContentTypography from "../text/StyledCardContentTypography";
import {toTitleCase} from "../../utils/text";
import BaseStyledButton from "../buttons/BaseStyledButton";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

// import {useConsoleLog, useConsoleWarn, useConsoleError} from "../hooks/logging/console";
// import StyledListItemText from "../text/StyledListItemText";

// Based on AnimalInfoCard
// Used as accordion content in StyledAccordionList, on UpdateSharedPublicClientForm
export default function SharedPubAnimalInfoCard({ animalData, showLogsProp, ...props }) {
    const [animalRec, setAnimalRec] = useState(null);
    const [showLogs, setShowLogs] = useState(false);

    // const [showAllAttrs, setShowAllAttrs] = useState(true);

    // const [cardActionButtonStack, setCardActionButtonStack] = useState(null);

    // useEffect( setShowLogs(props.showLogs);
    // useEffect(() => {
    //     if (props?.showLogs) {
    //         setShowLogs(props.showLogs);
    //     }
    // }, [props.showLogs]);

    // useEffect( setShowLogs(showLogs);
    useEffect(() => {
        if (showLogsProp) {
            setShowLogs(showLogsProp);
        }
        else {
            setShowLogs(false);
        }
    }, [showLogsProp]);

    // useEffect( setAnimalRec(props.animalRec);
    // useEffect(() => {
    //     if (props?.animalRec) {
    //         setAnimalRec(props.animalRec);
    //     }
    // }, [props.animalRec]);

    // useEffect( setAnimalRec(animalData);
    useEffect(() => {
        if (animalData) {
            setAnimalRec(animalData);
        }
    }, [animalData]);

    // useEffect( setShowAllAttrs(props.showAllAttrs);
    // useEffect(() => {
    //     if (props?.showAllAttrs) {
    //         if (props.showAllAttrs === 'false') {
    //             setShowAllAttrs(false);
    //         } else {
    //             setShowAllAttrs(true);
    //         }
    //     }
    // }, [props.showAllAttrs]);

    // useEffect( setCardActionButtonStack(props.cardActionButtonStack);
    // useEffect(() => {
    //     if (props?.cardActionButtonStack) {
    //         setCardActionButtonStack(props.cardActionButtonStack);
    //     }
    // }, [props.cardActionButtonStack]);

    // function handleShowAllAttrs() {
    //     setShowAllAttrs(!showAllAttrs);
    // }

    // useEffect( console.log( animalRec
    useEffect(() => {
        if (showLogs) {
            console.log('AnimalInfoCard animalRec', animalRec);
        }
    }, [animalRec, showLogs]);


    return (
        // <div style={{
        //     display: 'flex',
        //     flexDirection: 'column',
        //     alignItems: 'center',
        //     justifyContent: 'center',
        //     margin: '0 auto',
        // }}>
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            margin="0 auto"
        >
            <StyledListCard
                margin={['1rem', '1rem', '1rem', '1rem']}
            >
                <StyledCardContentTypography
                    text={`Animal: ${animalRec?.name || '-'}`}
                />
                <StyledCardContentTypography
                    text={`Animal Type: ${animalRec?.animalType ? toTitleCase(animalRec.animalType) : '-'}`}
                />
                <StyledCardContentTypography
                    text={`Breed: ${animalRec?.breed ? toTitleCase(animalRec.breed) : '-'}`}
                />
                <StyledCardContentTypography
                    text={`Age: ${animalRec?.age || '-'}`}
                />
                <StyledCardContentTypography
                    text={`Color: ${animalRec?.color ? toTitleCase(animalRec.color) : '-'}`}
                />
                <StyledCardContentTypography
                    text={`Discipline: ${animalRec?.discipline ? toTitleCase(animalRec.discipline) : '-'}`}
                />
                <StyledCardContentTypography
                    text={`Stall Number: ${animalRec?.stallNumber || '-'}`}
                />
                {/*<StyledCardContentTypography*/}
                {/*    text={`Is Miniature: ${animalRec?.isMiniature ? toTitleCase(animalRec.isMiniature.toString()) : '-'}`}*/}
                {/*/>*/}
                {/*<StyledCardContentTypography*/}
                {/*    text={`Is Miniature: ${animalRec?.isMiniature ? toTitleCase(animalRec.isMiniature.toString()) : '-'}`}*/}
                {/*/>*/}
                <StyledCardContentTypography
                    text={`Is Miniature: ${animalRec?.isMiniature ? toTitleCase(animalRec.isMiniature.toString()) : 'False'}`}
                />
                <StyledCardContentTypography
                    text={`Birthday: ${animalRec?.birthday || '-'}`}
                />
            </StyledListCard>
        </Box>
    );
}
