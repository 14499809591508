import React, {useState, useEffect, useContext} from 'react';
import DevStateContext from "../data/contexts/DevStateContext";
import StyledListCard from "../components/cards/StyledListCard";
import Box from "@mui/material/Box";
import BaseNavigateButton from "../components/links/BaseNavigateButton";
import StyledListBox from "../components/boxes/StyledListBox";
import {useResponsiveViewport} from "../data/providers/ResponsiveViewportProvider";
import RouterBreadcrumbs from "../components/router/RouterBreadcrumbs";
import {useConsoleLog} from "../components/hooks/logging/console";
import StyledCardContentTypography from "../components/text/StyledCardContentTypography";
import {useAmpUserPublicClientsProvider} from "../data/providers/people/AmpUserPublicClientsProvider";
import AmpUserClientsContext from "../data/contexts/AmpUserClientsContext";
import {breadcrumbRoutesPublicClientsScreen} from "../utils/breadcrumbsRoutes";
import CreatePublicClientDialog from "../components/modals/create/CreatePubClientDialog";
import BaseStyledButton from "../components/buttons/BaseStyledButton";
import CardContent from "@mui/material/CardContent";
import SharedPubAnimalsAccordion from "../components/accordions/SharedPubAnimalsAccordion";
import {toTitleCase} from "../utils/text";
import Divider from "@mui/material/Divider";

// import {useNavigate} from "react-router-dom";
// import CardActionArea from "@mui/material/CardActionArea";
// import {fetchPublicClientById} from "../data/get/fetchRecords";


export default function AmpUserPublicClientsScreen(props) {
    const [ampUser, setAmpUser] = useState(null);
    const [showLogs, setShowLogs] = useState(false);
    const [breadcrumbRoutes, setBreadcrumbRoutes] = useState([]); // [{path: '', name: ''}, ...
    const [clientRecs, setClientRecs] = useState([]);
    const [publicAnimalRecs, setPublicAnimalRecs] = useState([]);
    const [publicClientRecs, setPublicClientRecs] = useState([]);
    const [publicAnimalsRecsByPublicClientId, setPublicAnimalsRecsByPublicClientId] = useState({});
    const [displayCreatePubClientDialog, setDisplayCreatePubClientDialog] = useState(false);
    const [newPublicClientRec, setNewPublicClientRec] = useState(null);
    const [newPublicClientId, setNewPublicClientId] = useState(null);
    const [newPublicClientResObj, setNewPublicClientResObj] = useState(null);
    const [addButtonTopMargin, setAddButtonTopMargin] = useState(0);

    const {isSmallScreen, isLargeScreen} = useResponsiveViewport();
    const {devStateObj} = useContext(DevStateContext);
    const {ampUserClientsRecs} = useContext(AmpUserClientsContext);
    const {
        ampUserPublicClients,
        ampUserPublicAnimals,
        publicAnimalsByPublicClientId
    } = useAmpUserPublicClientsProvider();

    useConsoleLog(showLogs, [{label: 'AmpUserPublicClientsScreen clientRecs', value: clientRecs}]);
    useConsoleLog(showLogs, [{label: 'AmpUserPublicClientsScreen publicAnimalRecs', value: publicAnimalRecs}]);
    useConsoleLog(showLogs, [{label: '$$$ !!! AmpUserPublicClientsScreen publicClientRecs', value: publicClientRecs}]);
    useConsoleLog(showLogs, [{
        label: 'AmpUserPublicClientsScreen publicAnimalsRecsByPublicClientId',
        value: publicAnimalsRecsByPublicClientId
    }]);
    useConsoleLog(showLogs, [{label: 'AmpUserPublicClientsScreen isLargeScreen', value: isLargeScreen}]);

    // const navigate = useNavigate();

    // setBreadcrumbRoutes
    useEffect(() => {
        setBreadcrumbRoutes(breadcrumbRoutesPublicClientsScreen() || [])
        // console.error('breadcrumbRoutes', breadcrumbRoutes)
    }, [props]);

    // useEffect( setShowLogs(devStateObj.showLogs);
    useEffect(() => {
        if (devStateObj?.showLogs) {
            setShowLogs(devStateObj.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [devStateObj]);

    // useEffect( setAmpUser(props.ampUser)
    useEffect(() => {
        if (props?.ampUser) {
            setAmpUser(props.ampUser);
        }
    }, [props.ampUser]);

    // useEffect( setClientRecs(ampUserClientsRecs);
    useEffect(() => {
        if (ampUserClientsRecs) {
            setClientRecs(ampUserClientsRecs);
        }
    }, [ampUserClientsRecs]);

    // useEffect( setPublicAnimalRecs(ampUserPublicAnimals);
    useEffect(() => {
        if (ampUserPublicAnimals) {
            setPublicAnimalRecs(ampUserPublicAnimals);
        }
    }, [ampUserPublicAnimals]);

    // useEffect( setPublicClientRecs(ampUserPublicClients);
    useEffect(() => {
        if (ampUserPublicClients) {
            setPublicClientRecs(ampUserPublicClients);
        }
    }, [ampUserPublicClients]);

    // useEffect( setPublicAnimalsRecsByPublicClientId(publicAnimalsByPublicClientId);
    useEffect(() => {
        if (publicAnimalsByPublicClientId) {
            setPublicAnimalsRecsByPublicClientId(publicAnimalsByPublicClientId);
        }
    }, [publicAnimalsByPublicClientId]);

    // useEffect( setAddButtonTopMargin(dynamicMarginTop);
    useEffect(() => {
        const updateTopMargin = () => {
            const viewportHeight = window.innerHeight;
            const dynamicMarginTop = viewportHeight * 0.02; // 10% of viewport height
            setAddButtonTopMargin(dynamicMarginTop);
        };

        updateTopMargin();

        window.addEventListener('resize', updateTopMargin);

        return () => window.removeEventListener('resize', updateTopMargin);
    }, []);

    // function displayPublicClientRecs() {
    //     return publicClientRecs.map((eachRec, index) => {
    //         return (
    //             <Box
    //                 key={`amp-user-clients-${index}-${eachRec.id}`}
    //                 sx={{
    //                     marginBottom: '1.5vh',
    //                     // backgroundColor: 'white',
    //                     backgroundColor: 'whitesmoke',
    //                     // backgroundColor: 'red',
    //                     // width: '100%', // Ensure the item takes the full width of the container
    //                     // width: '100%', // Ensure the item takes the full width of the container
    //                     display: 'flex',
    //                     justifyContent: 'center', // Center the content
    //                 }}
    //             >
    //                 <StyledListCard
    //                     key={eachRec.id}
    //                     sx={{
    //                         width: '100%',
    //                         maxWidth: {
    //                             xs: 360,  // max-width for xs breakpoint and up
    //                             sm: 480,  // max-width for sm breakpoint and up
    //                             md: 600,  // max-width for md breakpoint and up
    //                             lg: 800,  // max-width for lg breakpoint and up
    //                             xl: 1000, // max-width for xl breakpoint and up
    //                         },
    //                         borderTop: '1px solid black',
    //                         borderBottom: '1px solid black',
    //                     }}
    //                 >
    //                     <CardContent>
    //                         <StyledCardContentTypography
    //                             text={eachRec.name}
    //                         />
    //                         <StyledCardContentTypography
    //                             text={eachRec?.publicClientStatus ? toTitleCase(eachRec.publicClientStatus) : '-'}
    //                         />
    //                         <div>
    //                             <SharedPubAnimalsAccordion
    //                                 animals={publicAnimalRecs.filter((animalRec) => animalRec.publicclientID === eachRec.id)}
    //                                 headerText="Your Animals:"
    //                                 showLogs={showLogs}
    //                             />
    //                         </div>
    //                         <div>
    //                             <BaseNavigateButton
    //                                 label="View"
    //                                 navTarget={`/shared-public-client/${eachRec.sharedRouterPath}`}
    //                                 fontSize={['5vw', '3vw', '2.5vw', '2vw']}
    //                             />
    //                         </div>
    //                     </CardContent>
    //                 </StyledListCard>
    //             </Box>
    //         );
    //     });
    // }

    function displayPublicClientRecs() {
        return (
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    // alignItems: 'center',
                    backgroundColor: 'whitesmoke',
                    // borderBottom: '1px solid black',
                }}
            >
                {publicClientRecs.map((eachRec, index) => {
            return (
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        // alignItems: 'center',
                        backgroundColor: 'whitesmoke',
                    }}
                    key={`amp-user-pub-clients-${index}-${eachRec.id}`}
                >
                    <StyledListCard
                        key={eachRec.id}
                        sx={{
                            // width: '100%',
                            width: '90%',
                            // maxWidth: {
                            //     xs: 360,  // max-width for xs breakpoint and up
                            //     sm: 480,  // max-width for sm breakpoint and up
                            //     md: 600,  // max-width for md breakpoint and up
                            //     lg: 800,  // max-width for lg breakpoint and up
                            //     xl: 1000, // max-width for xl breakpoint and up
                            // },
                            borderTop: '1px solid black',
                            borderBottom: '1px solid black',
                            // display: 'flex',
                            // justifyContent: 'center',
                            marginBottom: '1.5vh',
                        }}
                    >
                        <CardContent>
                            <StyledCardContentTypography
                                text={eachRec.name}
                                fontSize={{
                                    xs: '1.5rem', // Similar to small screens
                                    sm: '2rem',   // Adjust for tablets and small desktops
                                    md: '2.5rem', // Standard desktops
                                    lg: '3rem',   // Larger desktops
                                    xl: '3.5rem'  // Ultra-wide or larger screens
                                }}
                            />
                            <StyledCardContentTypography
                                text={eachRec?.publicClientStatus ? toTitleCase(eachRec.publicClientStatus) : '-'}
                                fontSize={{
                                    xs: '1.5rem', // Similar to small screens
                                    sm: '2rem',   // Adjust for tablets and small desktops
                                    md: '2.5rem', // Standard desktops
                                    lg: '3rem',   // Larger desktops
                                    xl: '3.5rem'  // Ultra-wide or larger screens
                                }}
                            />
                            <div>
                                <SharedPubAnimalsAccordion
                                    animals={publicAnimalRecs.filter((animalRec) => animalRec.publicclientID === eachRec.id)}
                                    headerText="Your Animals:"
                                    showLogs={showLogs}
                                />
                            </div>
                            <div>
                                <BaseNavigateButton
                                    label="View"
                                    navTarget={`/shared-public-client/${eachRec.sharedRouterPath}`}
                                    // fontSize={['5vw', '3vw', '2.5vw', '2vw']}
                                    fontSize={{
                                        xs: '1.5rem', // Similar to small screens
                                        sm: '2rem',   // Adjust for tablets and small desktops
                                        md: '2.5rem', // Standard desktops
                                        lg: '3rem',   // Larger desktops
                                        xl: '3.5rem'  // Ultra-wide or larger screens
                                    }}
                                />
                            </div>
                        </CardContent>
                    </StyledListCard>
                </div>
            );
        })
                }
    </div>
    );
    }


    function onCloseCreatePubClientDialog() {
        setDisplayCreatePubClientDialog(false);
    }

    function onCreatePubClientDone(createPubClientResObj) {
        if (createPubClientResObj) {
            if (showLogs) {
                console.log('AmpUserPublicClientsScreen onCreatePubClientDialogDone createPubClientResObj', createPubClientResObj);
            }
            if (createPubClientResObj?.success && createPubClientResObj?.newPublicClientRec) {
                // setNewPublicClientRec(createPubClientResObj.newPublicClientRec);
                setNewPublicClientId(createPubClientResObj.newPublicClientRec.id);
                setNewPublicClientResObj(createPubClientResObj);

                // fetchPublicClientById(createPubClientResObj.newPublicClientRec.id)
                //     .then((res) => {
                //         if (showLogs) {
                //             console.log('!!! >>> AmpUserPublicClientsScreen onCreatePubClientDialogDone fetchPublicClientById res', res);
                //         }
                //         if (res?.success) {
                //             setNewPublicClientRec(res.publicClientRec);
                //         }
                //     }).catch((error) => {
                //         console.error('AmpUserPublicClientsScreen onCreatePubClientDialogDone fetchPublicClientById error', error);
                //     });
                // get new public client rec, from provider, by id
                // const newRec = publicClientRecs.find((rec) => rec.id === createPubClientResObj.newPublicClientRec.id);

                const newRec = ampUserPublicClients.find((rec) => rec.id === createPubClientResObj.newPublicClientRec.id);
                if (showLogs) {
                    console.log('AmpUserPublicClientsScreen onCreatePubClientDialogDone newRec', newRec);
                }
            }
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center', // Center children horizontally
                justifyContent: 'center', // Center children vertically if needed
                margin: '0 auto',
            }}
        >
            <div>
                <StyledCardContentTypography
                    text="Public Clients"
                    sx={{
                        fontSize: {
                            xs: '1.5rem', // Similar to small screens
                            sm: '2rem',   // Adjust for tablets and small desktops
                            md: '2.5rem', // Standard desktops
                            lg: '3rem',   // Larger desktops
                            xl: '3.5rem'  // Ultra-wide or larger screens
                        }
                    }}
                />
            </div>
            <RouterBreadcrumbs breadcrumbsArr={breadcrumbRoutes}/>
            <StyledListBox
                // width={isLargeScreen ? '90%' : '100%'}
                // width={isLargeScreen ? '90%' : '100%'}
                style={{
                    color: 'black',
                    backgroundColor: 'white',
                    // backgroundColor: 'red',
                    // borderTop: '1px solid black',
                    // borderBottom: '1px solid red',
                    marginTop: '0.5rem',
                    // marginBottom: '1rem',
                    // maxWidth: '100vw', // Ensure it doesn’t exceed viewport width
                    // maxWidth: '90vw', // Ensure it doesn’t exceed viewport width
                    height: '60vh', // Adjust height based on viewport height
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    // justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {displayPublicClientRecs()}
            </StyledListBox>
            <div
                // style={{
                //     display: 'flex',
                //     flexDirection: 'column',
                //     alignItems: 'center',
                //     justifyContent: 'center',
                //     margin: '0 auto',
                //     width: '50%',
                //     borderTop: '1px solid black',
                //     // borderBottom: '1px solid red',
                // }}
            >
                <BaseStyledButton
                    label="Create Public Client"
                    onClick={() => setDisplayCreatePubClientDialog(true)}
                    style={{
                        marginTop: `${addButtonTopMargin}px`,
                        // borderTop: '1px solid black',
                        // paddingTop: '1rem',
                    }} // Passing dynamic margin via style prop
                    // fontSize={['5vw', '3vw', '2.5vw', '2vw']}
                    fontSize={{
                        xs: '1.5rem', // Similar to small screens
                        sm: '2rem',   // Adjust for tablets and small desktops
                        md: '2.5rem', // Standard desktops
                        lg: '3rem',   // Larger desktops
                        xl: '3.5rem'  // Ultra-wide or larger screens
                    }}
                />
            </div>
            {displayCreatePubClientDialog ? (
                <CreatePublicClientDialog
                    openProp={displayCreatePubClientDialog}
                    ampUser={ampUser}
                    showLogs={showLogs}
                    handleCloseProp={onCloseCreatePubClientDialog}
                    handleCreateDoneProp={onCreatePubClientDone}
                />
            ) : null}
        </Box>
    );
}

