import React, {useState, useEffect} from "react";
import DevStateProvider from "../../../data/providers/DevStateProvider";
import {RequireAuth} from "../../auth/RequireAuth";
import AmpUserApptsScreen from "../../../screens/appts/AmpUserApptsScreen";
import AmpUserApptsProvider from "../../../data/providers/appts/AmpUserApptsProvider";
import AmpUserApptAnimalsProvider from "../../../data/providers/appts/AmpUserApptAnimalsProvider";
import AmpUserApptNotesProvider from "../../../data/providers/notes/AmpUserApptNotesProvider";
import {RequireSub} from "../../auth/RequireSub";

export default function WrappedAmpUserAppts({routerPaths, ampUser, showLogs, resetProvidersStates}) {
    const [ampUserRec, setAmpUserRec] = useState(null);

    // useEffect( setAmpUserRec(ampUser);
    useEffect(() => {
        if (ampUser) {
            setAmpUserRec(ampUser);
        }
    }, [ampUser]);

    // useEffect( console.log('ampUserRec', ampUserRec);
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('WrappedAmpUserAppts ampUserRec', ampUserRec);
    //     }
    // }, [ampUserRec, showLogs]);

    return (
        <RequireAuth>
                <DevStateProvider>
                    {ampUserRec ? (
                        <RequireSub ampUser={ampUserRec}>
                        <AmpUserApptNotesProvider showLogs={showLogs} ampUserId={ampUserRec.id} resetProviderState={resetProvidersStates}>
                            <AmpUserApptAnimalsProvider showLogs={showLogs} ampUser={ampUserRec} resetProviderState={resetProvidersStates}>
                                <AmpUserApptsProvider ampUserId={ampUserRec.id} showLogs={showLogs} resetProviderState={resetProvidersStates}>
                                    <AmpUserApptsScreen routerPaths={routerPaths} showLogs={showLogs}/>
                                </AmpUserApptsProvider>
                            </AmpUserApptAnimalsProvider>
                        </AmpUserApptNotesProvider>
                    </RequireSub>
                ) : (
                    <h2>Loading Your User and Appts. Data...</h2>
                )}
            </DevStateProvider>
        </RequireAuth>
    )
}
