import React, {useEffect, useState} from 'react';
import SharedPublicClientScreen from "../../../screens/people/SharedPublicClientScreen";
import {useLocation, useParams} from "react-router-dom";
import {fetchPublicClientBySharedRouterPath, fetchPublicAnimalsByPublicClientId} from "../../../data/get/fetchRecords";
import {SharedPubClientIDBProvider} from "../../../data/providers/idb/SharedPubClientIDBProvider";
import {TempSharedPubClientFormDataProvider} from "../../../data/providers/idb/SharedPubClientFormIDBProvider";
import {SharedPubAnimalsIDBProvider} from "../../../data/providers/idb/SharedPubAnimalsIDBProvider";
import {SharedPubAnimalsProvider} from "../../../data/providers/animals/SharedPubAnimalsProvider";

export default function WrappedSharedPublicClient({routerPaths, showLogs, resetProvidersStates}) {
    const [localShowLogs, setLocalShowLogs] = useState(false);
    const [publicClient, setPublicClient] = useState(null);
    const [publicClientPubAnimals, setPublicClientPubAnimals] = useState(null);
    const [ampUserSharedRouterPathParam, setAmpUserSharedRouterPathParam] = useState(null);
    const [publicClientSharedRouterPathParam, setPublicClientSharedRouterPathParam] = useState(null);

    const location = useLocation();
    const params = useParams();
    // get the ampUserSharedRouterPath from the router
    // const ampUserSharedRouterPath = params.ampUserSharedRouterPath;
    // get the publicClientSharedRouterPath from the router
    const publicClientSharedRouterPath = params.publicClientSharedRouterPath;

    useEffect(() => {
        if (showLogs !== null) {
            setLocalShowLogs(showLogs);
        }
    }, [showLogs]);

    useEffect(() => {
        if (publicClientSharedRouterPath !== null) {
            setPublicClientSharedRouterPathParam(publicClientSharedRouterPath);
        }
    }, [publicClientSharedRouterPath]);

    // useEffect(() => {
    //     if (ampUserSharedRouterPath !== null) {
    //         setAmpUserSharedRouterPathParam(ampUserSharedRouterPath);
    //     }
    // }, [ampUserSharedRouterPath]);

    // useEffect( fetch public client by shared router path
    useEffect(() => {
        if (publicClientSharedRouterPathParam !== null) {
            fetchPublicClientBySharedRouterPath(publicClientSharedRouterPathParam)
                .then((res) => {
                    if (res && res?.length > 0) {
                        res = res[0];
                    }
                    setPublicClient(res);
                }).catch((err) => {
                console.error('Error fetching public client by shared router path', err);
            });
        }
    }, [publicClientSharedRouterPathParam]);

    useEffect(() => {
        if (publicClient) {
            fetchPublicAnimalsByPublicClientId(publicClient.id)
                .then((res) => {
                    setPublicClientPubAnimals(res);
                }).catch((err) => {
                console.error('Error fetching public client animals by public client id', err);
            });
        }
    }, [publicClient]);

    useEffect(() => {
        if (localShowLogs) {
            console.log('WrappedSharedPublicClient location', location);
            console.log('WrappedSharedPublicClient params', params);
            // console.log('WrappedSharedPublicClient ampUserSharedRouterPath', ampUserSharedRouterPath);
            console.log('WrappedSharedPublicClient publicClientSharedRouterPath', publicClientSharedRouterPath);
            console.log('WrappedSharedPublicClient publicClient', publicClient);
            console.log('WrappedSharedPublicClient publicClientPubAnimals', publicClientPubAnimals);
        }
        // }, [localShowLogs, location, params, ampUserSharedRouterPath, publicClientSharedRouterPath, publicClient]);
    }, [localShowLogs, location, params, publicClientSharedRouterPath, publicClient, publicClientPubAnimals]);

    return (
        <div>
            {publicClient ? (
                <SharedPubAnimalsProvider
                    sharedPubClientIdProp={publicClient.id}
                    showLogs={showLogs}
                    resetProviderState={resetProvidersStates}
                >
                    <SharedPubClientIDBProvider>
                        <SharedPubAnimalsIDBProvider>
                            <TempSharedPubClientFormDataProvider>
                                <SharedPublicClientScreen
                                    publicClient={publicClient}
                                    showLogs={localShowLogs}
                                    publicClientPubAnimals={publicClientPubAnimals}
                                />
                            </TempSharedPubClientFormDataProvider>
                        </SharedPubAnimalsIDBProvider>
                    </SharedPubClientIDBProvider>
                </SharedPubAnimalsProvider>
            ) : (
                <h2>Loading Public Client Data...</h2>
            )}
        </div>
    );
}
