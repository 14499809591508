import React from 'react';
import HomeScreen from "./screens/HomeScreen";
import WrappedAmpUserInfo from "./components/router/people/WrappedAmpUserInfo";
import { Login } from "./components/auth/Login";
import WrappedAmpUserAppts from "./components/router/appts/WrappedAmpUserAppts";
import WrappedCreateAppt from "./components/router/appts/WrappedCreateAppt";
import WrappedCreateApptPreview from "./components/router/appts/WrappedCreateApptPreview";
import WrappedApptInfo from "./components/router/appts/WrappedApptInfo";
import WrappedEditAppt from "./components/router/appts/WrappedEditAppt";
import WrappedAmpUserClients from "./components/router/people/WrappedAmpUserClients";
import WrappedCreateClient from "./components/router/people/WrappedCreateClient";
import CreateClientPreviewScreen from "./screens/people/CreateClientPreviewScreen";
import DevStateProvider from "./data/providers/DevStateProvider";
import WrappedClientInfo from "./components/router/people/WrappedClientInfo";
import WrappedClientAnimals from "./components/router/animals/WrappedClientAnimals";
import WrappedClientAppts from "./components/router/appts/WrappedClientAppts";
import WrappedCreateClientNote from "./components/router/people/WrappedCreateClientNote";
import WrappedEditClient from "./components/router/people/WrappedEditClient";
import WrappedCreateAnimal from "./components/router/animals/WrappedCreateAnimal";
import WrappedEditAnimal from "./components/router/animals/WrappedEditAnimal";
import WrappedAmpUserAnimals from "./components/router/animals/WrappedAmpUserAnimals";
import WrappedAnimalInfo from "./components/router/animals/WrappedAnimalInfo";
import WrappedCreateClientAnimal from "./components/router/animals/WrappedCreateClientAnimal";
import WrappedApplyForUserSub from "./components/router/people/subsPayments/WrappedApplyForUserSub";
import WrappedCreateUserSub from "./components/router/people/subsPayments/WrappedCreateUserSub";
import WrappedConfirmCreateUserSub from "./components/router/people/subsPayments/WrappedConfirmUserSub";
import WrappedSubscriptionCancel from "./components/router/people/subsPayments/WrappedSubscriptionCancel";
import WrappedSubscriptionSuccess from "./components/router/people/subsPayments/WrappedSubsccriptionSuccess";
import WrappedAmpUserIntake from "./components/router/people/WrappedAmpUserIntake";
import WrappedAmpUserPublicClients from "./components/router/people/WrappedAmpUserPublicClients";
import WrappedSharedPublicClient from "./components/router/people/WrappedSharedPublicClient";

// import Error404Screen from "./screens/400s/Error404Screen";


const routes = (routerPaths, ampUser, ampUserSubscriber, showLogs, resetProvidersStates) => [
    {
        path: "/",
        element: <HomeScreen />,
    },
    {
        path: "/home",
        element: <HomeScreen />,
    },
    {
        path: "/login",
        element: <Login />,
    },
    {
        path: "/appointments",
        element: (<WrappedAmpUserAppts routerPaths={routerPaths}
                                      ampUser={ampUser}
                                      showLogs={showLogs}
                                      resetProvidersStates={resetProvidersStates}/>
        ),
    },
    {
        path: "/create-appt",
        element: (<WrappedCreateAppt routerPaths={routerPaths}
                               ampUser={ampUser}
                               showLogs={showLogs}
                               resetProvidersStates={resetProvidersStates}/>
        ),
    },
    {
        path: "/create-appt-preview",
        element: (<WrappedCreateApptPreview
                routerPaths={routerPaths}
                ampUser={ampUser}
                showLogs={showLogs}
                resetProvidersStates={resetProvidersStates}/>
        ),
    },
    {
        path: "/appt-info/:apptRouterPath?",
        element: (<WrappedApptInfo routerPaths={routerPaths}
                             ampUser={ampUser}
                             ampUserSubscriber={ampUserSubscriber}
                             showLogs={showLogs}
                             resetProvidersStates={resetProvidersStates}/>
        ),
    },
    {
        path: "/edit-appt/:apptRouterPath?",
        element: (<WrappedEditAppt routerPaths={routerPaths}
                                   ampUser={ampUser}
                                   showLogs={showLogs}
                                   resetProvidersStates={resetProvidersStates}/>
        ),
    },
    {
        path: "/clients",
        element: (<WrappedAmpUserClients routerPaths={routerPaths}
                                         ampUser={ampUser}
                                         showLogs={showLogs}
                                         resetProvidersStates={resetProvidersStates}/>
        ),
    },
    {
        path: "/create-client",
        element: (<WrappedCreateClient routerPaths={routerPaths}
                                       ampUser={ampUser}
                                       showLogs={showLogs}
                                       resetProvidersStates={resetProvidersStates}/>
        ),
    },
    {
        path: "/create-client-preview",
        element: (<DevStateProvider>
            <CreateClientPreviewScreen routerPaths={routerPaths}
                                       ampUser={ampUser}
                                       showLogs={showLogs}
                                       resetProvidersStates={resetProvidersStates}/>
        </DevStateProvider>
        ),
    },
    {
        path: "/client-info/:clientRouterPath",
        element: (<WrappedClientInfo routerPaths={routerPaths}
                                     ampUser={ampUser}
                                     showLogs={showLogs}
                                     resetProvidersStates={resetProvidersStates}/>
        ),
    },
    {
        path: "/client-animals/:clientRouterPath?",
        element: (<WrappedClientAnimals routerPaths={routerPaths}
                                        ampUser={ampUser}
                                        showLogs={showLogs}
                                        resetProvidersStates={resetProvidersStates}/>
        ),
    },
    {
        path: "/client-appts/:clientRouterPath?",
        element: (<WrappedClientAppts routerPaths={routerPaths}
                                      ampUser={ampUser}
                                      showLogs={showLogs}
                                      resetProvidersStates={resetProvidersStates}/>
        ),
    },
    {
        path: "/create-client-note/:clientRouterPath?",
        element: (<WrappedCreateClientNote routerPaths={routerPaths}
                                           ampUser={ampUser}
                                           showLogs={showLogs}
                                           resetProvidersStates={resetProvidersStates}/>
        ),
    },
    {
        path: "/edit-client/:clientRouterPath?",
        element: (<WrappedEditClient routerPaths={routerPaths}
                                     ampUser={ampUser}
                                     showLogs={showLogs}
                                     resetProvidersStates={resetProvidersStates}/>
        ),
    },
    {
        path: "/public-clients",
        element: (<WrappedAmpUserPublicClients routerPaths={routerPaths}
                                               ampUser={ampUser}
                                               showLogs={showLogs}
                                               resetProvidersStates={resetProvidersStates}/>
        ),
    },
    // {
    //     path: "/shared-public-client/:ampUserSharedRouterPath/:publicClientSharedRouterPath",
    //     element: (<WrappedSharedPublicClient routerPaths={routerPaths}
    //                                            showLogs={showLogs}
    //                                            resetProvidersStates={resetProvidersStates}/>
    //     ),
    // },
    {
        path: "/shared-public-client/:publicClientSharedRouterPath",
        element: (<WrappedSharedPublicClient routerPaths={routerPaths}
                                             showLogs={showLogs}
                                             resetProvidersStates={resetProvidersStates}/>
        ),
    },
    {
        path: "/animals",
        element: (<WrappedAmpUserAnimals routerPaths={routerPaths}
                                        ampUser={ampUser}
                                        showLogs={showLogs}
                                        resetProvidersStates={resetProvidersStates}/>
        ),
    },
    {
        path: "/animal-info/:animalRouterPath",
        element: (<WrappedAnimalInfo routerPaths={routerPaths}
                                     ampUser={ampUser}
                                     showLogs={showLogs}
                                     resetProvidersStates={resetProvidersStates}/>
        ),
    },
    {
        path: "/create-animal",
        element: (<WrappedCreateAnimal routerPaths={routerPaths}
                                       ampUser={ampUser}
                                       showLogs={showLogs}
                                       resetProvidersStates={resetProvidersStates}/>
        ),
    },
    {
        path: "/create-client-animal",
        element: (<WrappedCreateClientAnimal routerPaths={routerPaths}
                                             ampUser={ampUser}
                                             showLogs={showLogs}
                                             resetProvidersStates={resetProvidersStates}/>
        ),
    },
    {
        path: "/edit-animal/:animalRouterPath?",
        element: (<WrappedEditAnimal routerPaths={routerPaths}
                                     ampUser={ampUser}
                                     showLogs={showLogs}
                                     resetProvidersStates={resetProvidersStates}/>
        ),
    },
    {
        path: "/user-info",
        element: (
            <WrappedAmpUserInfo
                routerPaths={routerPaths}
                ampUser={ampUser}
                ampUserSubscriberProp={ampUserSubscriber}
                showLogs={showLogs}
                resetProvidersStates={resetProvidersStates}
            />
        ),
    },
    {
        path: "/user-intake",
        element: (
            <WrappedAmpUserIntake
                routerPaths={routerPaths}
                ampUser={ampUser}
                ampUserSubscriberProp={ampUserSubscriber}
                showLogs={showLogs}
                resetProvidersStates={resetProvidersStates}
            />
        ),
    },
    {
        path: "/apply-for-subscription",
        element: (<WrappedApplyForUserSub routerPaths={routerPaths}
                                          ampUser={ampUser}
                                          showLogs={showLogs}
                                          resetProvidersStates={resetProvidersStates}/>
        ),
    },
    {
        path: "/subscribe",
        element: (<WrappedCreateUserSub routerPaths={routerPaths}
                                        ampUser={ampUser}
                                        ampUserSubscriber={ampUserSubscriber}
                                        showLogs={showLogs}
                                        resetProvidersStates={resetProvidersStates}/>
        ),
    },
    {
        path: "/confirm-user-sub",
        element: (<WrappedConfirmCreateUserSub routerPaths={routerPaths}
                                               ampUser={ampUser}
                                               ampUserSubscriber={ampUserSubscriber}
                                               showLogs={showLogs}
                                               resetProvidersStates={resetProvidersStates}/>
        ),
    },
    {
        path: "/subscription-cancel",
        element: (<WrappedSubscriptionCancel routerPaths={routerPaths}
                                             ampUser={ampUser}
                                             ampUserSubscriber={ampUserSubscriber}
                                             showLogs={showLogs}
                                             resetProvidersStates={resetProvidersStates}/>
        ),
    },
    {
        path: "/subscription-success",
        element: (<WrappedSubscriptionSuccess routerPaths={routerPaths}
                                              ampUser={ampUser}
                                              ampUserSubscriber={ampUserSubscriber}
                                              showLogs={showLogs}
                                              resetProvidersStates={resetProvidersStates}/>
        ),
    },
    // Catch-all route for 404
    // {
    //     path: "*",
    //     element: <Error404Screen />,
    // },
];

export default routes;
