import { DataStore } from "aws-amplify";
import { AmpUser, AmpUserSubscriber, AmpUserType } from "../../../models";
import { getNowAsAWSDateTime } from "../../../utils/datesTimes";
import { validatePhoneNumber, convertToE164 } from "../../../utils/validation";
import { generateSaltedRouterPath } from "../../../utils/breadcrumbsRoutes";
import { v4 as uuidv4 } from 'uuid';


export async function updateAmpUserUserAttrs(ampUserRec, userAttrsDiffsObj, showLogs) {
    if (showLogs) {
        console.log('updateAmpUserUserAttrs ampUserRec', ampUserRec);
        console.log('updateAmpUserUserAttrs userAttrsDiffsObj', userAttrsDiffsObj);
    }
    const resObj = {};
    try {
        const original = await DataStore.query(AmpUser, ampUserRec.id);
        // Check if the user exists
        if (!original) {
            console.error("User not found");
            // return {success: false, error: "User not found"};
            resObj.success = false;
            resObj.error = "User not found";
            return resObj;
        }
        if (!original.routerPath) {
            const routerPathUUID = uuidv4();
            const ampUserRouterPath = generateSaltedRouterPath(`${ampUserRec.id}-${routerPathUUID}`);
            userAttrsDiffsObj.routerPath = ampUserRouterPath;
        }
        if (!original.sharedRouterPath) {
            const sharedRouterPathUUID = uuidv4();
            const ampUserSharedRouterPath = generateSaltedRouterPath(`${ampUserRec.id}-${sharedRouterPathUUID}`);
            userAttrsDiffsObj.sharedRouterPath = ampUserSharedRouterPath;
        }
        if (userAttrsDiffsObj.phone) {
            const phone = userAttrsDiffsObj.phone;
            if (!validatePhoneNumber(phone)) {
                // return {success: false, error: "Invalid phone number"};
                resObj.success = false;
                resObj.phoneError = `Invalid phone number: ${phone}`;
                resObj.error = `Invalid phone number: ${phone}`;
                return resObj;
            }
            else {
                userAttrsDiffsObj.phoneE164 = convertToE164(phone);
            }
            // userAttrsDiffsObj.phoneE164 = convertToE164(phone);
        }
        const updatedUser = await DataStore.save(
            AmpUser.copyOf(original, updated => {
                Object.keys(userAttrsDiffsObj).forEach(key => {
                    // Update the corresponding field in the User model
                    updated[key] = userAttrsDiffsObj[key];
                });
            })
        );
        // return {updatedUser: updatedUser, success: true};
        resObj.success = true;
        resObj.updatedRec = updatedUser;
        return resObj;
    }
    catch (error) {
        console.error('updateAmpUserUserAttrs error', error);
        return {success: false, error};
    }
}

// TODO - ADD ATTR HERE OR TO AUS TO TRACK TEMP SUBS TO AVOID MALICIOUS UNPAID SUBS
export async function updateAmpUserUserStatus(ampUser, userStatus, showLogs) {
    if (showLogs) {
        console.warn('updateAmpUserUserStatus ampUser', ampUser)
        console.warn('updateAmpUserUserStatus userStatus', userStatus)
    }
    const updateResObj = {};
    try {
        const updateRes = await DataStore.save(
            AmpUser.copyOf(ampUser, updated => {
                updated.userStatus = userStatus;
            })
        );
        updateResObj.success = true;
        updateResObj.updatedRec = updateRes;
        return updateResObj;
        // window.location.reload();
    }
    catch (error) {
        console.error('updateAmpUserUserStatus error', error);
        updateResObj.success = false;
        updateResObj.error = error;
        return updateResObj;
    }
}

export async function updateAmpUserLastPaymentHash(ampUser, lastPaymentHash) {
    console.warn('updateAmpUserLastPaymentHash(ampUser, userStatus)', ampUser, lastPaymentHash)
    await DataStore.save(
        AmpUser.copyOf(ampUser, updated => {
            updated.lastPaymentHash = lastPaymentHash;
        })
    );
    // window.location.reload();
}

export async function updateAmpUserSubscriberById(ampUserSubscriberId, updatedAttrsObj, showLogs) {
    if (showLogs) {
        console.log('updateAmpUserSubscriber ampUserSubscriberId', ampUserSubscriberId);
        console.log('updateAmpUserSubscriber updatedAttrsObj', updatedAttrsObj);
    }
    const updateAUSRes = {};
    try {
        const originalAUSRec = await DataStore.query(AmpUserSubscriber, ampUserSubscriberId);
        // Check if the AUS rec exists
        if (!originalAUSRec) {
            console.error("AmpUserSubscriber not found");
            updateAUSRes.success = false;
            updateAUSRes.error = "AmpUserSubscriber not found";
            return updateAUSRes;
        }

        // if (!original.routerPath) {
        //     const clientRouterPath = modelRouterPathAttrFactory(`${ampUserRec.name}-${clientId}`);
        //     userAttrs.routerPath = clientRouterPath;
        // }
        updatedAttrsObj.lastEditedDateTime = getNowAsAWSDateTime();
        updatedAttrsObj.isEdited = true;

        const updatedAmpUserSubscriber = await DataStore.save(
            AmpUserSubscriber.copyOf(originalAUSRec, updated => {
                Object.keys(updatedAttrsObj).forEach(key => {
                    // Update the corresponding field in the User model
                    updated[key] = updatedAttrsObj[key];
                });
            })
        );
        updateAUSRes.success = true;
        updateAUSRes.updatedRec = updatedAmpUserSubscriber;
        return updateAUSRes;
    }
    catch (error) {
        console.error('updateAmpUserSubscriber error', error);
        updateAUSRes.success = false;
        updateAUSRes.error = error;
        return updateAUSRes;
    }
}

