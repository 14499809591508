import React, {useState, useEffect} from 'react';
import StyledCardContentTypography from "../text/StyledCardContentTypography";
import StyledListCard from "./StyledListCard";
import BaseNavigateButton from "../links/BaseNavigateButton";

export default function ApptInfoCard(props) {
    const [showLogs, setShowLogs] = useState(false);
    const [apptRec, setApptRec] = useState(null);
    const [clientRec, setClientRec] = useState(null);

    // useEffect( setShowLogs( props.showLogs
    useEffect(() => {
        if (props.showLogs) {
            setShowLogs(props.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [props.showLogs]);

    // useEffect( setApptRec(props.apptRec);
    useEffect(() => {
        if (props?.apptRec) {
            setApptRec(props.apptRec);
        }
    }, [props.apptRec]);

    // useEffect( setClientRec(props.clientRec);
    useEffect(() => {
        if (props?.clientRec) {
            setClientRec(props.clientRec);
        }
    }, [props.clientRec]);

    // useEffect( console.log(apptRec, clientRec
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('ApptInfoCard apptRec', apptRec);
    //         console.log('ApptInfoCard clientRec', clientRec);
    //     }
    // }, [apptRec, clientRec, showLogs]);

    // TODO - ADD REST OF APPT. INFO

    return (
        <StyledListCard>
            {apptRec && clientRec ? (
                <>
                    <StyledCardContentTypography
                        text={`Client: ${clientRec?.name || '-'}`}
                    />
                    <StyledCardContentTypography
                        text={`Date: ${apptRec?.apptDate || '-'}`}
                    />
                    <StyledCardContentTypography
                        text={`Time: ${apptRec?.apptTime || '-'}`}
                    />
                    <StyledCardContentTypography
                        text={`Duration: ${apptRec?.apptDuration || '-'}`}
                    />
                    <StyledCardContentTypography
                        text={`Status: ${apptRec?.appointmentStatus || '-'}`}
                    />
                    <StyledCardContentTypography
                        text={`Notes: ${apptRec?.notes || '-'}`}
                    />
                    {props?.hideEditButton ? null : (
                        <BaseNavigateButton
                            label={`Edit Appt.`}
                            navTarget={`/edit-appt/${apptRec.routerPath}`}
                            navState={{apptRecId: apptRec.id, apptClientId: clientRec.id}}
                        />
                    )}
                </>
            ) : (
                <StyledCardContentTypography
                    text={`No Appt. Rec`}
                />
            )}
        </StyledListCard>
    )
}
