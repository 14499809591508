import React, { createContext, useContext, useEffect, useState } from 'react';
import { openDB } from 'idb';

const SharedPubAnimalsIDBContext = createContext(null);

export function SharedPubAnimalsIDBProvider({ children }) {
    const [db, setDb] = useState(null);

    // useEffect(() => {
    //     async function initDB() {
    //         try {
    //             const dbInstance = await openDB('SharedPublicAnimalDB', 1, {
    //                 upgrade(db) {
    //                     if (!db.objectStoreNames.contains('sharedPubAnimalUpdates')) {
    //                         db.createObjectStore('sharedPubAnimalUpdates', { keyPath: 'timestamp' });
    //                     }
    //                 }
    //             });
    //             setDb(dbInstance);
    //         } catch (error) {
    //             console.error('Failed to open IndexedDB:', error);
    //         }
    //     }
    //
    //     initDB();
    // }, []);

    useEffect(() => {
        async function initDB() {
            try {
                const dbInstance = await openDB('SharedPublicAnimalDB', 2, {
                    upgrade(db, oldVersion, newVersion, transaction) {
                        if (oldVersion < 1) {
                            db.createObjectStore('sharedPubAnimalUpdates', { keyPath: 'id', autoIncrement: true });
                        }
                        if (oldVersion < 2) {
                            const store = transaction.objectStore('sharedPubAnimalUpdates');
                            store.createIndex('pubClientId', 'pubClientId');
                            store.createIndex('timestamp', 'timestamp');
                        }
                    }
                });
                setDb(dbInstance);
            } catch (error) {
                console.error('Failed to open IndexedDB SharedPublicAnimalDB:', error);
            }
        }

        initDB();
    }, []);

    const storeSharedPubAnimalsData = async (formData, pubClientId) => {
        if (!db) return;

        try {
            const tx = db.transaction('sharedPubAnimalUpdates', 'readwrite');
            const store = tx.objectStore('sharedPubAnimalUpdates');
            await store.add({
                timestamp: Date.now(),
                pubClientId: pubClientId,
                data: formData // Store as an array to match the desired format
            });
            await tx.done;
        } catch (error) {
            console.error('Error storing Shared PublicAnimals data:', error);
        }
    };

    const getLatestPubAnimalsData = async (pubClientId) => {
        if (!db) return null;

        try {

            if (pubClientId == null) {
                console.warn('Invalid pubClientId provided to getLatestPubAnimalsData');
                return null;
            }

            const tx = db.transaction('sharedPubAnimalUpdates', 'readonly');
            const store = tx.objectStore('sharedPubAnimalUpdates');
            const index = store.index('pubClientId');

            // let cursor = await store.openCursor(null, 'prev');
            // if (cursor) {
            //     return cursor.value;
            // }
            // Use a cursor to find the latest entry for the given pubClientId

            let cursor = await index.openCursor(IDBKeyRange.only(pubClientId), 'prev');

            if (cursor) {
                return {
                    timestamp: cursor.value.timestamp,
                    pubClientId: cursor.value.pubClientId,
                    data: cursor.value.data
                };
            }
            return null;
        } catch (error) {
            console.error('Error fetching latest Shared PublicAnimals data:', error);
            return null;
        }
    };

    return (
        <SharedPubAnimalsIDBContext.Provider value={{ storeSharedPubAnimalsData, getLatestPubAnimalsData }}>
            {children}
        </SharedPubAnimalsIDBContext.Provider>
    );
}

export function useSharedPubAnimalsIDB() {
    return useContext(SharedPubAnimalsIDBContext);
}