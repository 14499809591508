import React, { useState, useEffect, useContext } from 'react';
import AmpUserApptsContext from "../../data/contexts/AmpUserApptsContext";
import Card from "@mui/material/Card";
// import {Divider} from "@aws-amplify/ui-react";
import Divider from "@mui/material/Divider";
import AmpUserApptAnimalsContext from "../../data/contexts/AmpUserApptAnimalsContext";
import DevStateContext from "../../data/contexts/DevStateContext";
import StyledCardContentTypography from "../../components/text/StyledCardContentTypography";
import BaseNavigateButton from "../../components/links/BaseNavigateButton";
import BaseStyledDeleteButton from "../../components/buttons/BaseStyledDeleteButton";
import DeleteApptModal from "../../components/modals/delete/DeleteApptModal";
import {DataStore} from "aws-amplify";
import {ShoeTask, TrimTask} from "../../models";
import RouterBreadcrumbs from "../../components/router/RouterBreadcrumbs";
import {breadcrumbRoutesApptsScreen} from "../../utils/breadcrumbsRoutes";
import StyledCardContent from "../../components/cards/StyledCardContent";
import StyledListBox from "../../components/boxes/StyledListBox";
import {useResponsiveViewport} from "../../data/providers/ResponsiveViewportProvider";
import {useConsoleLog, useConsoleWarn} from "../../components/hooks/logging/console";
import {
    handleDeleteApptCancel,
    handleDeleteApptConfirm,
    handleDeleteApptModalClose
} from "../../components/modals/helpers";
import {useNavigate} from "react-router-dom";
import AmpUserApptNotesContext from "../../data/contexts/AmpUserApptNotesContext";
import NotesAccordion from "../../components/accordions/NotesAccordion";
import AnimalsSummaryAccordion from "../../components/accordions/AnimalsSummaryAccordion";
import StyledListCard from "../../components/cards/StyledListCard";
import Box from "@mui/material/Box";

// TODO - ADD CREATE APPT NOTE

export default function AmpUserApptsScreen(props) {
    const [ampUser, setAmpUser] = useState(null);
    const [apptRecs, setApptRecs] = useState(null);
    const [apptRecIds, setApptRecIds] = useState([]);
    const [apptAnimalRecs, setApptAnimalRecs] = useState([]);
    const [animalsByApptId, setAnimalsByApptId] = useState({}); // [apptId: [animalId, animalId, ...]
    const [clientsByApptId, setClientsByApptId] = useState({}); // [apptId: clientId]
    const [tasksByApptId, setTasksByApptId] = useState({}); // [apptId: [task, task, ...
    const [taskCountsByApptId, setTaskCountsByApptId] = useState({}); // [apptId: [task, task, ...
    const [animalCountsByApptId, setAnimalCountsByApptId] = useState({}); // [apptId: [task, task, ...

    const [ampUserApptNotes, setAmpUserApptNotes] = useState(null);
    const [apptNotesByApptId, setApptNotesByApptId] = useState({}); // [apptId: [note, note, ...]
    const [ampUserApptAnimals, setAmpUserApptAnimals] = useState(null);
    const [showLogs, setShowLogs] = useState(false);
    const [showDeleteApptModal, setShowDeleteApptModal] = useState(false);
    const [modelRecToDelete, setModelRecToDelete] = useState(null);
    const [breadcrumbRoutes, setBreadcrumbRoutes] = useState([]); // [{path: '', name: ''}, ...
    const { isSmallScreen, isLargeScreen } = useResponsiveViewport();
    const [deleteApptRes, setDeleteApptRes] = useState({});

    const [apptClientRec, setApptClientRec] = useState(null);

    const { devStateObj } = useContext(DevStateContext);
    const { ampUserApptsRecs, clientRecsByApptId, apptRecsByClientId } = useContext(AmpUserApptsContext);
    const { ampUserApptAnimalsRecs, animalRecs, setLocalAnimalIds, setLocalApptIds, animalsRecsByApptId } = useContext(AmpUserApptAnimalsContext);
    const { ampUserApptNotesRecs, apptNoteRecsByApptId, setAmpUserApptIds } = useContext(AmpUserApptNotesContext);

    let navigate = useNavigate();

    // const listSubheaderSX = {
    //     padding: '0 0.5vw',
    //     margin: '1vh 0.5vw',
    //     color: 'black',
    //     fontSize: ['1.75rem', '2rem', '1.5rem', '1rem']
    // }

    // setBreadcrumbRoutes
    useEffect(() => {
        setBreadcrumbRoutes(breadcrumbRoutesApptsScreen() || [])
        // console.error('breadcrumbRoutes', breadcrumbRoutes)
    }, [props]);

    // useEffect( setShowLogs(devStateObj.showLogs);
    useEffect(() => {
        if (devStateObj?.showLogs){
            setShowLogs(devStateObj.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [devStateObj]);

    // useEffect( setShowLogs(devStateObj.showLogs);
    // useEffect(() => {
    //     if (devStateObj?.showLogs){
    //         setShowLogs(devStateObj.showLogs);
    //     } else {
    //         setShowLogs(false);
    //     }
    // }, [devStateObj]);

    // TODO - ?VERIFY THE COUNT OF ANIMALS AND TASKS ARE BOTH CORRECT FOR EACH APPT,?
    //  ?ANIMAL COUNTS LOOK INCORRECT?
    useEffect(() => {
        if (ampUserApptsRecs) {
            const fetchAllApptTasks = async () => {
                const taskRecsByApptId = {};
                const taskCountsByApptId = {};
                for (const appt of ampUserApptsRecs) {
                    const shoeTasks = await DataStore.query(ShoeTask, c => c.appointmentID.eq(appt.id));
                    const trimTasks = await DataStore.query(TrimTask, c => c.appointmentID.eq(appt.id));

                    // console.log('!!! shoeTasks', shoeTasks);
                    // console.log('!!! shoeTasks.length', shoeTasks.length);
                    // console.log('!!! trimTasks', trimTasks);
                    // console.log('!!! trimTasks.length', trimTasks.length);

                    const apptTasks = { shoe: shoeTasks, trim: trimTasks };
                    const apptTasksTotal = shoeTasks.length + trimTasks.length;

                    // console.log('!!! apptTasksTotal', apptTasksTotal);

                    taskRecsByApptId[appt.id] = apptTasks;
                    taskCountsByApptId[appt.id] = apptTasksTotal;
                }
                setTasksByApptId(taskRecsByApptId);
                setTaskCountsByApptId(taskCountsByApptId);
            };
            fetchAllApptTasks();
        }
    }, [ampUserApptsRecs]);

    // const handleDeleteApptModalClose = () => {
    //     setShowDeleteApptModal(false);
    //     setModelRecToDelete(null);
    // };

    const onDeleteApptModalClose = () => {
        handleDeleteApptModalClose(setShowDeleteApptModal, setModelRecToDelete, showLogs);
    };

    // const handleDeleteApptConfirm = async () => {
    //     if (showLogs) {
    //         console.log('The Appt record has been deleted.', modelRecToDelete);
    //     }
    //     await deleteAppointmentById(modelRecToDelete, showLogs);
    //     handleDeleteApptModalClose();
    // };

    const onDeleteApptConfirm = async () => {
        await handleDeleteApptConfirm(modelRecToDelete, setDeleteApptRes, setShowDeleteApptModal, setModelRecToDelete, showLogs);
        // TODO - MAKE SURE ALL RELATED RECS ARE DELETED ALSO
        navigate('/appointments');
    };

    const onDeleteApptCancel = () => {
        handleDeleteApptCancel(modelRecToDelete, setShowDeleteApptModal, setModelRecToDelete, showLogs);
    };

    // useEffect( setApptRecs(ampUserApptsRecs); setApptRecIds(apptIds);
    useEffect(() => {
        if (ampUserApptsRecs) {
            setApptRecs(ampUserApptsRecs);
            const apptIds = ampUserApptsRecs.map((appt) => appt.id);
            setApptRecIds(apptIds);
        }
        if (clientRecsByApptId) {
            setClientsByApptId(clientRecsByApptId);
        }
    }, [ampUserApptsRecs, clientRecsByApptId]);

    // useEffect( setAmpUserApptAnimals( setApptAnimalRecs( setAnimalsByApptId(
    useEffect(() => {
        if (ampUserApptAnimalsRecs) {
            setAmpUserApptAnimals(ampUserApptAnimalsRecs);
            setApptAnimalRecs(animalRecs);
            setAnimalsByApptId(animalsRecsByApptId);
        }
    }, [ampUserApptAnimalsRecs, animalRecs, animalsRecsByApptId]);

    useEffect(() => {
        if (ampUserApptNotesRecs) {
            setAmpUserApptNotes(ampUserApptNotesRecs);
        }
        if (apptNoteRecsByApptId) {
            setApptNotesByApptId(apptNoteRecsByApptId);
        }
    }, [ampUserApptNotesRecs, apptNoteRecsByApptId]);

    // useEffect( setLocalApptIds(apptRecIds);, setAmpUserApptIds(apptRecIds);
    useEffect(() => {
        if (apptRecIds && apptRecIds.length > 0) {
            // passed from ApptAnimals provider
            setLocalApptIds(apptRecIds);
            // passed from ApptNotes provider
            setAmpUserApptIds(apptRecIds);
        }
    }, [apptRecIds]);

    useConsoleLog(showLogs, [{ label: 'AmpUserApptsScreen apptRecIds', value: apptRecIds }]);

    useConsoleLog(showLogs, [{ label: 'AmpUserApptsScreen ampUserApptNotes', value: ampUserApptNotes }]);

    useConsoleLog(showLogs, [{ label: 'AmpUserApptsScreen apptNotesByApptId', value: apptNotesByApptId }]);

    useConsoleLog(showLogs, [{ label: 'AmpUserApptsScreen apptRecs', value: apptRecs }]);

    useConsoleLog(showLogs, [{ label: 'AmpUserApptsScreen ampUserApptAnimals', value: ampUserApptAnimals }]);

    useConsoleLog(showLogs, [{ label: 'AmpUserApptsScreen apptAnimalRecs', value: apptAnimalRecs }]);

    useConsoleLog(showLogs, [{ label: 'AmpUserApptsScreen animalsByApptId', value: animalsByApptId }]);

    useConsoleLog(showLogs, [{ label: 'AmpUserApptsScreen tasksByApptId', value: tasksByApptId }]);

    useConsoleWarn(showLogs, [{ label: 'AmpUserApptsScreen clientsByApptId', value: clientsByApptId }]);

    // TODO - START HERE, ADD APPT ATTRS AND RELATED MODELS ATTRS,
    //  ADD BUTTONS TO SINGLE APPTS, EDIT APPT,
    //  ADD APPT NOTE, ADD ANIMALAPPTS RECS

    function displayAmpUserAppts() {
        if (apptRecs && apptRecs.length > 0) {
            return apptRecs.map((appt, index) => {
                const clientRecByThisAppt = clientsByApptId[appt.id];
                if (!clientRecByThisAppt) {
                    return;
                }
                // const clientRec = clientsByApptId[appt.id][0];
                const clientRec = clientRecByThisAppt[0];
                const apptNotes = apptNotesByApptId[appt.id] || [];
                return (
                    <Box
                        key={`amp-user-appts-${index}`}
                        sx={{
                            marginBottom: '1.5vh',
                            backgroundColor: 'whitesmoke',
                            width: '100%', // Ensure the item takes the full width of the container
                            display: 'flex',
                            justifyContent: 'center', // Center the content
                        }}
                    >
                        <Divider />
                        <StyledListCard
                            key={`ampUserClientRec-${index}`}
                            sx={{
                                width: '100%',
                                maxWidth: {
                                    xs: 360,  // max-width for xs breakpoint and up
                                    sm: 480,  // max-width for sm breakpoint and up
                                    md: 600,  // max-width for md breakpoint and up
                                    lg: 800,  // max-width for lg breakpoint and up
                                    xl: 1000, // max-width for xl breakpoint and up
                                },
                            }}
                        >
                                    <StyledCardContentTypography
                                        text={`Client: ${clientRec?.name || '-'}`}
                                    />
                                    <StyledCardContentTypography
                                        text={appt.apptDate}
                                    />
                                    <StyledCardContentTypography
                                        text={appt.apptTime}
                                    />
                                    <StyledCardContentTypography
                                        text={`Appt. Status: ${appt?.appointmentStatus}`}
                                    />
                                    <StyledCardContentTypography
                                        text={`Shoe Tasks: ${tasksByApptId[appt.id]?.shoe?.length || 0}`}
                                    />
                                    <StyledCardContentTypography
                                        text={`Trim Tasks: ${tasksByApptId[appt.id]?.trim?.length || 0}`}
                                    />
                                    <StyledCardContentTypography
                                        text={`Amount: $${appt?.amount || 0}`}
                                    />
                                    <StyledCardContentTypography
                                        text={`Duration: ${appt?.apptDuration || 0}mins.`}
                                    />
                                    {apptNotes.length > 0 ? (
                                        <NotesAccordion
                                            notes={apptNotes}
                                            headerText={`Appt Notes: (${apptNotes.length > 0 ? apptNotes.length : 0})`}
                                            showLogs={showLogs}
                                            apptRecProp={appt}
                                            noteType="appt"
                                            clientId={appt.clientID}
                                        />
                                    ) : null}
                                    {animalsByApptId[appt.id]?.length && animalsByApptId[appt.id]?.length > 0 ? (
                                        <AnimalsSummaryAccordion
                                            animals={animalsByApptId[appt.id]}
                                            headerText={`Animals: (${animalsByApptId[appt.id]?.length || 0})`}
                                            showLogs={showLogs}
                                        />
                                    ) : null}
                                <BaseNavigateButton
                                    navTarget={`/appt-info/${appt?.routerPath || ""}`}
                                    label="View Appt. Info"
                                    navState={{
                                        apptRec: appt,
                                        animalRecs: animalsByApptId[appt.id] || [],
                                        clientRec: clientsByApptId[appt.id] || {}
                                    }}
                                />
                                <BaseNavigateButton
                                    label={"Update Appointment"}
                                    navTarget={`/edit-appt/${appt.routerPath}`}
                                    navState={{apptRecId: appt.id, apptClientId: appt.clientID}}
                                />
                                <BaseStyledDeleteButton
                                    label="Delete Appt."
                                    onClick={() => {
                                        setModelRecToDelete(appt);
                                        setShowDeleteApptModal(true);
                                        setApptClientRec(clientRec);
                                    }}
                                />
                        </StyledListCard>
                        <Divider />
                    </Box>
                );
            });
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center', // Center children horizontally
                justifyContent: 'center', // Center children vertically if needed
                margin: '0 auto',
            }}
        >
            <h2>Appointments</h2>
            <RouterBreadcrumbs breadcrumbsArr={breadcrumbRoutes} />
            <StyledListBox
                width={isLargeScreen ? '90%' : '100%'}
                style={{
                    color: 'white',
                    backgroundColor: 'whitesmoke',
                    borderTop: '1px solid black',
                    borderBottom: '1px solid black',
                    marginTop: '0.5vh',
                    maxWidth: '100vw', // Ensure it doesn’t exceed viewport width
                    height: '60vh', // Adjust height based on viewport height
                    overflowY: 'auto',
                    overflowX: 'hidden',
                }}
            >
            {displayAmpUserAppts()}
            </StyledListBox>
            <div>
                {showDeleteApptModal ? (
                    <DeleteApptModal
                        open={showDeleteApptModal}
                        handleClose={onDeleteApptModalClose}
                        handleConfirm={onDeleteApptConfirm}
                        handleCancel={onDeleteApptCancel}
                        modelRecToDelete={modelRecToDelete}
                        showLogs={showLogs}
                        clientRec={apptClientRec}
                    />
                ) : null}
            </div>
        </Box>
    );
}
