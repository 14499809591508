import React, {useState, useEffect} from 'react';
import StyledListCard from "../cards/StyledListCard";
import StyledCardContentTypography from "../text/StyledCardContentTypography";
import BaseStyledButton from "../buttons/BaseStyledButton";
import CardContent from "@mui/material/CardContent";
import {CardActions} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useFarrierAlertsIDBProvider} from "../../data/providers/alerts/FarrierAlertsIDBProvider";
import {farrierAlertsDB} from "../../data/providers/idb/alerts/FarrierAlertsDB";
import {useConsoleLog} from "../hooks/logging/console";
import FarrierAlertViewButton from "../buttons/FarrierAlertViewButton";
import PersonIcon from '@mui/icons-material/Person';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PaidIcon from '@mui/icons-material/Paid';
import AlertNotifCardIconButton from "../buttons/AlertNotifCardIconButton";
import {SharedPublicClientUpdateStatus} from "../../models";
import {ampUserUpdatePublicClientRec} from "../../data/update/client/updateClient";
import Box from "@mui/material/Box";

// export default function FarrierAlertNotif(props) {
export default function FarrierAlertNotif({
                                              alertIDBRecProp,
                                              ampUserRecProp,
                                              showLogsProp,
                                              // handleAckAlertProp,
                                              updateAckedAlertHashesProp,
                                              ...props
                                          }) {
    const [ampUserRec, setAmpUserRec] = useState(null);
    const [alertIDBRec, setAlertIDBRec] = useState(null);
    const [alertIsAcked, setAlertIsAcked] = useState(true);
    const [alertStoreName, setAlertStoreName] = useState(null);

    const [cardIcon, setCardIcon] = useState(null);
    const [showLogs, setShowLogs] = useState(false);

    const [ackButtonIsDisabled, setAckButtonIsDisabled] = useState(false);
    const [pinButtonIsDisabled, setPinButtonIsDisabled] = useState(false);
    const [unPinButtonIsDisabled, setUnPinButtonIsDisabled] = useState(false);

    const [ackAlertHandlerFunc, setAckAlertHandlerFunc] = useState(null);
    const [ackAlertHandlerFuncArgsArr, setAckAlertHandlerFuncArgsArr] = useState([]);
    const [ackAlertUpdateRelatedRecFuncArgsArr, setAckAlertUpdateRelatedRecFuncArgsArr] = useState([]);

    const [pinAlertHandlerFuncArgsArr, setPinAlertHandlerFuncArgsArr] = useState([]);
    const [unPinAlertHandlerFuncArgsArr, setUnPinAlertHandlerFuncArgsArr] = useState([]);


    const {
        acknowledgeAlert,
        pinAlert,
        setIsUpdatingUnAckedAlerts,
        isUpdatingUnAckedAlerts,
        unPinAlert,
        setIsUpdatingPinnedAlerts,
        isUpdatingPinnedAlerts
    } = useFarrierAlertsIDBProvider();

    useConsoleLog(showLogs, [{label: 'FarrierAlertNotif alertIDBRec', value: alertIDBRec}]);
    useConsoleLog(showLogs, [{label: 'FarrierAlertNotif alertStoreName', value: alertStoreName}]);
    useConsoleLog(showLogs, [{label: 'FarrierAlertNotif ampUserRec', value: ampUserRec}]);
    // useConsoleLog(showLogs, [{label: 'FarrierAlertNotif alertIsAcked', value: alertIsAcked}]);
    // useConsoleLog(showLogs, [{label: 'FarrierAlertNotif ackAlertHandlerFunc', value: ackAlertHandlerFunc}]);
    useConsoleLog(showLogs, [{label: 'FarrierAlertNotif ackAlertHandlerFuncArgsArr', value: ackAlertHandlerFuncArgsArr}]);

    // useEffect( set showLogs from showLogsProp
    useEffect(() => {
        if (showLogsProp) {
            setShowLogs(showLogsProp);
        } else {
            setShowLogs(false);
        }
    }, [showLogsProp]);

    // useEffect( set alertIDBRec from alertIDBRecProp
    useEffect(() => {
        if (alertIDBRecProp) {
            setAlertIDBRec(alertIDBRecProp);
        }
    }, [alertIDBRecProp]);

    // useEffect( set alertIsAcked from alertIDBRec
    useEffect(() => {
        if (alertIDBRec) {
            setAlertIsAcked(alertIDBRec.isAcknowledged);
            setAckButtonIsDisabled(alertIDBRec.isAcknowledged);
            setPinButtonIsDisabled(alertIDBRec.isPinned);
            setUnPinButtonIsDisabled(!alertIDBRec.isPinned);
        }
    }, [alertIDBRec]);

    // useEffect( set ampUserRec from ampUserRecProp
    useEffect(() => {
        if (ampUserRecProp) {
            setAmpUserRec(ampUserRecProp);
        }
    }, [ampUserRecProp]);

    // async function handleAckFarrierAlertNotif() {
    //     if (alertIDBRec) {
    //         if (showLogs) {
    //             console.log('FarrierAlertNotif: handleAckFarrierAlertNotif alertIDBRec:', alertIDBRec);
    //         }
    //         setAckButtonIsDisabled(true);
    //         await acknowledgeAlert(alertIDBRec, ampUserRec);
    //         if (updateAckedAlertHashesProp) {
    //             updateAckedAlertHashesProp(alertIDBRec.alertId);
    //         }
    //         setAckButtonIsDisabled(false);
    //     }
    // }

    // async function handleAckFarrierAlertNotif(ampUserRecParam,
    //                                           alertIDBRecParam,
    //                                           storeNameParam,
    //                                           setAckButtonIsDisabledParam,
    //                                           setIsUpdatingUnAckedAlertsParam,
    //                                           updateRelatedRecFuncParam,
    //                                           acknowledgeAlertParam,
    //                                           updateAckedAlertHashesPropParam,
    //                                           showLogsParam) {
    //     if (alertIDBRecParam) {
    //         if (showLogsParam) {
    //             console.log('FarrierAlertNotif: handleAckFarrierAlertNotif alertIDBRecParam:', alertIDBRecParam);
    //         }
    //         // const recDiffObj = {sharedPublicClientUpdateStatus: SharedPublicClientUpdateStatus.CLIENT_UPDATE_ACKNOWLEDGED};
    //         // const pubClientId = alertIDBRec.publicClientId;
    //
    //         setAckButtonIsDisabledParam(true);
    //         setIsUpdatingUnAckedAlertsParam(true);
    //         if (updateRelatedRecFuncParam) {
    //             await updateRelatedRecFuncParam(ampUserRecParam, alertIDBRecParam, recDiffObj);
    //         }
    //         await acknowledgeAlertParam(alertIDBRecParam.alertId, storeNameParam);
    //         if (updateAckedAlertHashesPropParam) {
    //             updateAckedAlertHashesPropParam(alertIDBRecParam.ackHash);
    //         }
    //         setAckButtonIsDisabledParam(false);
    //         setIsUpdatingUnAckedAlertsParam(false);
    //     }
    // }


    async function handleAckFarrierAlertNotif(ampUserRecParam,
                                              alertIDBRecParam,
                                              storeNameParam,
                                              setAckButtonIsDisabledParam,
                                              setIsUpdatingUnAckedAlertsParam,
                                              acknowledgeAlertParam,
                                              updateAckedAlertHashesPropParam,
                                              showLogsParam,
                                              updateRelatedRecFuncParam = null,
                                              updateRelatedRecFuncArgsParam = []) {
        if (alertIDBRecParam) {
            if (showLogsParam) {
                console.log('FarrierAlertNotif: handleAckFarrierAlertNotif alertIDBRecParam:', alertIDBRecParam);
            }
            // const recDiffObj = {sharedPublicClientUpdateStatus: SharedPublicClientUpdateStatus.CLIENT_UPDATE_ACKNOWLEDGED};
            // const pubClientId = alertIDBRec.publicClientId;

            setAckButtonIsDisabledParam(true);
            setIsUpdatingUnAckedAlertsParam(true);
            if (updateRelatedRecFuncParam) {
                if (typeof updateRelatedRecFuncParam === 'function') {
                    await updateRelatedRecFuncParam(...updateRelatedRecFuncArgsParam);
                }
                // await updateRelatedRecFuncParam(ampUserRecParam, alertIDBRecParam, recDiffObj);
            }
            await acknowledgeAlertParam(alertIDBRecParam.alertId, storeNameParam);
            if (updateAckedAlertHashesPropParam) {
                updateAckedAlertHashesPropParam(alertIDBRecParam.ackHash);
            }
            setAckButtonIsDisabledParam(false);
            setIsUpdatingUnAckedAlertsParam(false);
        }
    }


    async function handlePinFarrierAlertNotif(alertIDBRecParam,
                                              storeNameParam,
                                              setPinButtonIsDisabledParam,
                                              setUnPinButtonIsDisabledParam,
                                              setIsUpdatingPinnedAlertsParam,
                                              pinAlertParam,
                                              handleAckFarrierAlertNotifParam,
                                              ackAlertHandlerFuncArgsArrParam,
                                              showLogsParam) {
        if (alertIDBRecParam) {
            if (showLogsParam) {
                console.log('FarrierAlertNotif: handlePinFarrierAlertNotif alertIDBRecParam:', alertIDBRecParam);
            }

            setIsUpdatingPinnedAlertsParam(true);
            setPinButtonIsDisabledParam(true);
            await pinAlertParam(alertIDBRecParam.alertId, storeNameParam);

            const alertAcked = alertIDBRecParam.isAcknowledged;

            if (!alertAcked) {
                // await handleAckFarrierAlertNotif(...ackAlertHandlerFuncArgsArr);
                await handleAckFarrierAlertNotifParam(...ackAlertHandlerFuncArgsArrParam);
            }
            setIsUpdatingPinnedAlertsParam(false);
            setUnPinButtonIsDisabledParam(false);
            setPinButtonIsDisabledParam(true);
        }
    }


    async function handleUnPinFarrierAlertNotif(alertIDBRecParam,
                                              storeNameParam,
                                              setPinButtonIsDisabledParam,
                                              setUnPinButtonIsDisabledParam,
                                              setIsUpdatingPinnedAlertsParam,
                                              unPinAlertParam,
                                              showLogsParam) {
        if (alertIDBRecParam) {
            if (showLogsParam) {
                console.log('FarrierAlertNotif: handleUnPinFarrierAlertNotif alertIDBRecParam:', alertIDBRecParam);
            }

            setIsUpdatingPinnedAlertsParam(true);
            setUnPinButtonIsDisabledParam(true);
            await unPinAlertParam(alertIDBRecParam.alertId, storeNameParam);

            setIsUpdatingPinnedAlertsParam(false);
            setUnPinButtonIsDisabled(true);
            setPinButtonIsDisabledParam(false);
        }
    }


    function setCardStateValsByAlertType(alertType) {
        // console.log('Setting ackAlertHandlerFunc for alert type:', alertType);
        switch (alertType) {
            case "clientUpdatedSharedPublicClient":
                setCardIcon(<PersonIcon/>);
                const storeName = farrierAlertsDB.stores.farrierPublicClientAlertsStoreName;
                // setAlertStoreName(farrierAlertsDB.stores.farrierPublicClientAlertsStoreName);
                setAlertStoreName(storeName);
                // console.log("handleAckFarrierAlertNotif type:", typeof handleAckFarrierAlertNotif); // Should output "function"

                // const ackHandlerFunc = handleAckFarrierAlertNotif;
                // setAckAlertHandlerFunc(ackHandlerFunc);
                // setAckAlertHandlerFunc(handleAckFarrierAlertNotif);

                const ackAlertHandlerFuncArgs = [ampUserRec, alertIDBRec, storeName, setAckButtonIsDisabled, setIsUpdatingUnAckedAlerts, acknowledgeAlert, updateAckedAlertHashesProp, showLogs];
                const recDiffObj = {sharedPublicClientUpdateStatus: SharedPublicClientUpdateStatus.CLIENT_UPDATE_ACKNOWLEDGED};
                const pubClientId = alertIDBRec.publicClientId;
                const updateRelatedRecFunc = ampUserUpdatePublicClientRec;
                const updateRelatedRecFuncArgs = [pubClientId, recDiffObj, ampUserRec, showLogs];
                // setAckAlertHandlerFunc(ackHandlerFunc);
                ackAlertHandlerFuncArgs.push(updateRelatedRecFunc);
                ackAlertHandlerFuncArgs.push(updateRelatedRecFuncArgs);
                setAckAlertHandlerFuncArgsArr(ackAlertHandlerFuncArgs);

                const pinAlertHandlerFuncArgs = [alertIDBRec, storeName, setPinButtonIsDisabled, setUnPinButtonIsDisabled, setIsUpdatingPinnedAlerts, pinAlert, handleAckFarrierAlertNotif, ackAlertHandlerFuncArgs, showLogs];
                setPinAlertHandlerFuncArgsArr(pinAlertHandlerFuncArgs);

                const unPinAlertHandlerFuncArgs = [alertIDBRec, storeName, setPinButtonIsDisabled, setUnPinButtonIsDisabled, setIsUpdatingPinnedAlerts, unPinAlert, showLogs];
                setUnPinAlertHandlerFuncArgsArr(unPinAlertHandlerFuncArgs);
                break;
            case "farrierAlert":
                setCardIcon(<PriorityHighIcon/>);
                break;
            case "farrierPaymentAlert":
                // setCardIcon(<AttachMoneyIcon />);
                setCardIcon(<PaidIcon/>);
                break;
            case "farrierNotificationAlert":
                setCardIcon(<NotificationsIcon/>);
                break;
            default:
                setCardIcon(<NotificationsIcon/>);
                return;
        }
    }

    useEffect(() => {
        if (ampUserRec && alertIDBRec) {
            // if (handleAckAlertProp && updateAckedAlertHashesProp) {
            if (updateAckedAlertHashesProp) {
                // setCardStateValsByAlertType(alertIDBRec.alertType);
                setCardStateValsByAlertType(alertIDBRec.alertType);
            }
            // setCardStateValsByAlertType(alertIDBRec.alertType);
        }
        // }, [ampUserRec, alertIDBRec, showLogs, handleAckAlertProp, updateAckedAlertHashesProp]);
    }, [ampUserRec, alertIDBRec, showLogs, updateAckedAlertHashesProp]);

    // TODO - START HERE, FR, FR, UPDATE STYLING, LAYOUT FOR CARD

    function handleAck() {
        console.log('Acknowledge button clicked, calling ackAlertHandlerFunc');
        console.log('ackAlertHandlerFunc', ackAlertHandlerFunc);
        console.log('ackAlertHandlerFuncArgsArr', ackAlertHandlerFuncArgsArr);
        // ackAlertHandlerFunc(...ackAlertHandlerFuncArgsArr);
        handleAckFarrierAlertNotif(...ackAlertHandlerFuncArgsArr);
    }

    function handlePin() {
        console.log('Pin button clicked, calling pinAlertHandlerFunc');
        console.log('pinAlertHandlerFuncArgsArr', pinAlertHandlerFuncArgsArr);
        // pinAlertHandlerFunc(...pinAlertHandlerFuncArgsArr);
        handlePinFarrierAlertNotif(...pinAlertHandlerFuncArgsArr);
    }

    function handleUnPin() {
        console.log('Unpin button clicked, calling unpinAlertHandlerFunc');
        console.log('unPinAlertHandlerFuncArgsArr', unPinAlertHandlerFuncArgsArr);
        // unPinAlertHandlerFunc(...unPinAlertHandlerFuncArgsArr);
        handleUnPinFarrierAlertNotif(...unPinAlertHandlerFuncArgsArr);
    }

    return (
        <div>
            <StyledListCard>
                <CardContent>
                    <Box
                        display="flex"
                        alignItems="center"
                        // alignItems="flex-start"
                    >
                        <AlertNotifCardIconButton
                            buttonIconProp={cardIcon}
                            displayBadgeProp={!alertIsAcked}
                            badgeValProp={0}
                            showLogsProp={showLogs}
                            notificoncolor="black"
                        />
                        <StyledCardContentTypography
                            text={`Msg: ${alertIDBRec?.alertMsg || ''}`}
                            fontSize={{
                                xs: '1.5rem',
                                sm: '1.5rem',
                                md: '1.5rem',
                                lg: '2rem',
                                xl: '2.5rem',
                            }}
                            // sx={{ marginLeft: '0.5rem' }} // Adjusts spacing between icon and text
                            style={{ marginLeft: '0.5rem', textAlign: 'left' }} // Adjusts spacing between icon and text
                        />
                    </Box>
                </CardContent>
                <CardActions>
                    <FarrierAlertViewButton
                        label={'View'}
                        alertIDBRecProp={alertIDBRec}
                        alertIDBStoreNameProp={alertStoreName}
                        showLogsProp={showLogs}
                        fontSize={{
                            xs: '1.5rem',
                            sm: '1.5rem',
                            md: '1.5rem',
                            lg: '2rem',
                            xl: '2.5rem',
                        }}
                        // style={{ margin: '0rem', padding: '0rem' }}
                    />
                    {!ackButtonIsDisabled ? (
                        <BaseStyledButton
                            label={'Acknowledge'}
                            // onClick={async () => {
                            // onClick={async () => {
                            //     // if (alertIDBRec && ackAlertHandlerFunc && ackAlertHandlerFuncArgsArr && ackAlertHandlerFuncArgsArr.length > 0) {
                            //     //     console.log('FarrierAlertNotif: Acknowledge button clicked, calling ackAlertHandlerFunc');
                            //     //     console.log('FarrierAlertNotif:ackAlertHandlerFunc', ackAlertHandlerFunc);
                            //     //     await ackAlertHandlerFunc(...ackAlertHandlerFuncArgsArr);
                            //     // }
                            //     // else {
                            //     //     alert('Acknowledge button clicked, no ack handler set for this alert');
                            //     // }
                            //     console.log('FarrierAlertNotif: Acknowledge button clicked, calling ackAlertHandlerFunc');
                            //     console.log('FarrierAlertNotif:ackAlertHandlerFunc', ackAlertHandlerFunc);
                            //     await ackAlertHandlerFunc(...ackAlertHandlerFuncArgsArr);
                            //
                            // }}
                            // onClick={() => ackAlertHandlerFunc(...ackAlertHandlerFuncArgsArr)}
                            onClick={handleAck}
                            disabled={ackButtonIsDisabled}
                            fontSize={{
                                xs: '1.5rem',
                                sm: '1.5rem',
                                md: '1.5rem',
                                lg: '2rem',
                                xl: '2.5rem',
                            }}
                        />
                    ) : null}
                    {!pinButtonIsDisabled ? (
                        <BaseStyledButton
                            label={'Pin'}
                            // onClick={async () => {
                            //     if (alertIDBRec) {
                            //         await handlePinFarrierAlertNotif();
                            //     } else {
                            //         alert('Pin button clicked, no pin handler set for this alert');
                            //     }
                            // }}
                            onClick={handlePin}
                            disabled={pinButtonIsDisabled}
                            fontSize={{
                                xs: '1.5rem',
                                sm: '1.5rem',
                                md: '1.5rem',
                                lg: '2rem',
                                xl: '2.5rem',
                            }}
                        />
                    ) : null}
                    {!unPinButtonIsDisabled ? (
                        <BaseStyledButton
                            label={'Unpin'}
                            // onClick={async () => {
                            //     if (alertIDBRec) {
                            //         await handleUnpinFarrierAlertNotif();
                            //     }
                            //     else {
                            //         alert('Remove pin button clicked, no unpin handler set for this alert');
                            //     }
                            // }}
                            onClick={handleUnPin}
                            disabled={unPinButtonIsDisabled}
                            fontSize={{
                                xs: '1.5rem',
                                sm: '1.5rem',
                                md: '1.5rem',
                                lg: '2rem',
                                xl: '2.5rem',
                            }}
                        />
                    ) : null}
                </CardActions>
            </StyledListCard>
        </div>
    );
}

