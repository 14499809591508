import React, {useState, useEffect} from 'react';
import { DataStore, SortDirection } from "aws-amplify";
import { Client } from '../../../models';
import AmpUserClientsContext from "../../contexts/AmpUserClientsContext";

const AmpUserClientsDataProvider = ({ children, ampUserId, showLogs, resetProviderState }) => {
    const [ampUserClientsRecs, setAmpUserClientsRecs] = useState([]);
    const [ampUserClientsShowLogs, setAmpUserClientsShowLogs] = useState(false);

    const resetContext = () => {
        setAmpUserClientsRecs([]);
    }

    // useEffect( reset context if resetProviderState is true
    useEffect(() => {
        if (resetProviderState) {
            resetContext();
        }
    }, [resetProviderState]);

    // useEffect( set showLogs from props.showLogs
    useEffect(() => {
        if (showLogs) {
            setAmpUserClientsShowLogs(true);
        } else {
            setAmpUserClientsShowLogs(false);
        }
    }, [showLogs]);

    // useEffect( console.log( ampUserClientsRecs
    useEffect(() => {
        if (ampUserClientsShowLogs) {
            console.log('AmpUserClientsDataProvider ampUserClientsRecs', ampUserClientsRecs);
        }
    }, [ampUserClientsRecs, ampUserClientsShowLogs]);

    useEffect(() => {
        if (!resetProviderState && ampUserId) {
            const subscription = DataStore.observeQuery(Client,
                (c) => c.and(c => [
                    c.ampuserID.eq(ampUserId),
                    c.isDeleted.ne(true)  // Filter out records where isDeleted is true
                ]),
                {
                    sort: s => s.createdAt(SortDirection.DESCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    // console.log(`AmpUserClientsDataProvider c [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                    // console.log('AmpUserClientsDataProvider c [Snapshot] items', items);
                    if (isSynced) {
                        setAmpUserClientsRecs(items);
                    }
                });
            // Remember to unsubscribe when the component unmounts
            return () => subscription.unsubscribe();
        }
    }, [ampUserId, ampUserClientsShowLogs, resetProviderState]); // Dependency array now includes regionId

    return (
        <AmpUserClientsContext.Provider value={{ ampUserClientsRecs }}>
            {children}
        </AmpUserClientsContext.Provider>
    );
}

export default AmpUserClientsDataProvider;
