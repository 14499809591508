import React, {useState, useEffect, useContext} from 'react';
import Divider from '@mui/material/Divider';
import { CardActionArea, CardActions } from '@mui/material';
import BaseNavigateButton from "../../../components/links/BaseNavigateButton";
import {userSubDataObj} from "../../../utils/formObjects"
import AmpUserContext from "../../../data/contexts/AmpUserContext";
import StyledListBox from "../../../components/boxes/StyledListBox";
import StyledListCard from "../../../components/cards/StyledListCard";
import StyledCardContent from "../../../components/cards/StyledCardContent";
import {useConsoleError, useConsoleLog, useConsoleWarn} from "../../../components/hooks/logging/console";
import {useResponsiveViewport} from "../../../data/providers/ResponsiveViewportProvider";
import StyledCardContentTypography from "../../../components/text/StyledCardContentTypography";
import {breadcrumbRoutesApplyForUserSubScreen} from "../../../utils/breadcrumbsRoutes";
import RouterBreadcrumbs from "../../../components/router/RouterBreadcrumbs";
import Box from "@mui/material/Box";

// import StyledListItemText from "../components/text/StyledListItemText";
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import Typography from '@mui/material/Typography';

// TODO - !!! START HERE, FR, FR, MAKE SURE A 1 MONTH SUB WORKS

export default function ApplyForUserSubScreen(props) {
    const [ampUser, setAmpUser] = useState(null);
    const [userSubData, setUserSubData] = useState(userSubDataObj);
    const [showLogs, setShowLogs] = useState(false);
    const [breadcrumbRoutes, setBreadcrumbRoutes] = useState([]); // [{path: '', name: ''}, ...

    const {ampUserRec} = useContext(AmpUserContext);
    const { isSmallScreen, isLargeScreen } = useResponsiveViewport();

    // setBreadcrumbRoutes
    useEffect(() => {
        setBreadcrumbRoutes(breadcrumbRoutesApplyForUserSubScreen() || [])
        // console.error('breadcrumbRoutes', breadcrumbRoutes)
    }, [props]);

    // useEffect( setShowLogs(
    useEffect(() => {
        if (props?.showLogs){
            setShowLogs(props.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [props]);

    // useEffect( setAmpUser(props.ampUser);
    useEffect(() => {
        if (ampUserRec) {
            setAmpUser(ampUserRec);
        }
    }, []);

    useConsoleLog(showLogs, [{label: 'ApplyForUserSubScreen userSubData', value: userSubData}]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto',
            }}
        >
            <h2>
                Subscribe to Sole Frog!
            </h2>
            <RouterBreadcrumbs breadcrumbsArr={breadcrumbRoutes}/>
            {userSubData ? (
                <div>
                    <StyledListBox
                        backgroundColor={'transparent'}
                        style={{
                            marginTop: '0.5rem',
                        }}
                    >
                        <StyledListCard
                            width={isLargeScreen ? '90%' : '100%'}
                            sx={{
                                marginBottom: '0.5rem',
                            }}
                        >
                            <StyledCardContentTypography
                                text={`$${userSubData.Month.subPrice} for ${userSubData.Month.descriptionText}`}
                            />
                            <StyledCardContent>
                                <StyledCardContentTypography
                                    text={"Month to Month"}
                                />
                                <BaseNavigateButton
                                    label="Subscribe"
                                    navTarget="/subscribe"
                                    navState={{subLength: userSubData.Month.subText, subPrice: userSubData.Month.subPrice}}
                                />
                            </StyledCardContent>
                        </StyledListCard>
                        <Divider />
                        <StyledListCard
                            width={isLargeScreen ? '90%' : '100%'}
                            sx={{
                                marginBottom: '0.5rem',
                            }}
                        >
                            <StyledCardContentTypography
                                text={`$${userSubData.Quarter.subPrice} for ${userSubData.Quarter.descriptionText}`}
                            />
                            <StyledCardContent>
                                <StyledCardContentTypography
                                    text={"3 Months, with a discount"}
                                />
                                <BaseNavigateButton
                                    label="Subscribe"
                                    navTarget="/subscribe"
                                    navState={{subLength: userSubData.Quarter.subText, subPrice: userSubData.Quarter.subPrice}}
                                />
                            </StyledCardContent>
                        </StyledListCard>
                        <Divider />
                        <StyledListCard
                            width={isLargeScreen ? '90%' : '100%'}
                        >
                            <StyledCardContentTypography
                                text={`$${userSubData.Year.subPrice} for ${userSubData.Year.descriptionText}`}
                            />
                            <StyledCardContent>
                                <StyledCardContentTypography
                                    text={"1 Year, with a discount"}
                                />
                                <BaseNavigateButton
                                    label="Subscribe"
                                    navTarget="/subscribe"
                                    navState={{subLength: userSubData.Year.subText, subPrice: userSubData.Year.subPrice}}
                                />
                            </StyledCardContent>
                        </StyledListCard>
                    </StyledListBox>
                </div>
            ) : null}
        </Box>
    );
}
