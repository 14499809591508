import React, {useEffect, useState} from 'react';
import {RequireAuth} from '../../auth/RequireAuth';
import DevStateProvider from "../../../data/providers/DevStateProvider";
import AmpUserClientsScreen from "../../../screens/people/AmpUserClientsScreen";
import AmpUserClientsDataProvider from "../../../data/providers/people/AmpUserClientsDataProvider";
import AmpUserClientNotesProvider from "../../../data/providers/notes/AmpUserClientNotesProvider";
import ApptsByClientsDataProvider from "../../../data/providers/appts/ApptsByClientsProvider";
import {RequireSub} from "../../auth/RequireSub";

export default function WrappedAmpUserClients({routerPaths, ampUser, showLogs, resetProvidersStates}) {
    const [ampUserRec, setAmpUserRec] = useState(null);
    const [showLogsVal, setShowLogsVal] = useState(false);

    // useEffect( setAmpUserRec(ampUser);
    useEffect(() => {
        if (ampUserRec === null && ampUser !== null) {
            setAmpUserRec(ampUser);
        }
    }, [ampUser]);

    // useEffect( setShowLogsVal(showLogs);
    useEffect(() => {
        if (showLogs !== null) {
            setShowLogsVal(showLogs);
        }
    }, [showLogs]);

    // useEffect( console.log( ampUserRec
    useEffect(() => {
        if (showLogsVal) {
            console.log('WrappedAmpUserClients ampUserRec', ampUserRec);
        }
    }, [ampUserRec, showLogsVal]);

    return (
        <DevStateProvider>
            <RequireAuth>
                {ampUser ? (
                    <RequireSub ampUser={ampUser}>
                        <ApptsByClientsDataProvider ampUserId={ampUser.id} showLogs={showLogs} resetProviderState={resetProvidersStates}>
                            <AmpUserClientNotesProvider ampUserId={ampUser.id} showLogs={showLogs} resetProviderState={resetProvidersStates}>
                                <AmpUserClientsDataProvider ampUserId={ampUser.id} showLogs={showLogs} resetProviderState={resetProvidersStates}>
                                    <AmpUserClientsScreen
                                        routerPaths={routerPaths}
                                        ampUser={ampUser}
                                    />
                                </AmpUserClientsDataProvider>
                            </AmpUserClientNotesProvider>
                        </ApptsByClientsDataProvider>
                      </RequireSub>
                    ) : (
                        <div><h2>Loading Client Data...</h2></div>
                    )}
            </RequireAuth>
        </DevStateProvider>
    );
}
