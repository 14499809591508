import {
    homeNavButtonFactory,
    clientAnimalsNavButtonFactory,
    clientApptsNavButtonFactory,
    clientInfoNavButtonFactory,
    clientsNavButtonFactory,
    createClientNavButtonFactory } from "../shared";

export const updateClientDialogFinalStepSet = (clientRec) => {

    return {
        home: homeNavButtonFactory(),
        clientAppts: clientApptsNavButtonFactory(clientRec),
        clientAnimals: clientAnimalsNavButtonFactory(clientRec),
        clientInfo: clientInfoNavButtonFactory(clientRec),
    }
}

export const deleteClientDialogFinalStepSet = () => {

    return {
        home: homeNavButtonFactory(),
        clients: clientsNavButtonFactory(),
        createClient: createClientNavButtonFactory(),
    }
}