import { deleteAnimalById } from "../../../data/delete/deleteRecords";

export function handleDeleteAnimalModalClose(setDisplayDeleteClientAnimalModal, setModelRecToDelete, showLogs) {
    setDisplayDeleteClientAnimalModal(false);
    setModelRecToDelete(null);
}

export function handleDeleteAnimalCancel(modelRecToDelete, setDisplayDeleteClientAnimalModal, setModelRecToDelete, showLogs) {
    if (showLogs) {
        console.log('The Animal record has NOT been deleted.', modelRecToDelete);
    }
    handleDeleteAnimalModalClose(setDisplayDeleteClientAnimalModal, setModelRecToDelete, showLogs);
}

export async function handleDeleteAnimalConfirm(modelRecToDelete, setDeleteClientAnimalRes, setDisplayDeleteClientAnimalModal, setModelRecToDelete, showLogs) {
    if (showLogs) {
        console.log('The Animal record has been deleted. modelRecToDelete', modelRecToDelete);
    }
    const deleteResObj = await deleteAnimalById(modelRecToDelete.id, showLogs);
    setDeleteClientAnimalRes(deleteResObj);
    handleDeleteAnimalModalClose(setDisplayDeleteClientAnimalModal, setModelRecToDelete, showLogs);
}
