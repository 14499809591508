import React, {useEffect, useState} from 'react';
import {Modal, Box, TextField, Button, Typography} from '@mui/material';
import {textFieldValToInteger} from "../../../utils/text";
import {sumTaskAmount, sumTaskDuration} from "../../../utils/numbers";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

// TODO - ADD DEFAULT VALUES FOR TASK TO ANIMAL REC
export default function CreateShoeTaskFormModal({
                                                    open,
                                                    onClose,
                                                    formData,
                                                    handleFormChange,
                                                    handleSaveAndUpdateNext,
                                                    handleSaveAndUpdatePrev,
                                                    showLogs
                                                }) {
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalDuration, setTotalDuration] = useState(0);
    const [localShowLogs, setLocalShowLogs] = useState(false);

    // useEffect( setLocalShowLogs(showLogs);
    useEffect(() => {
        if (showLogs) {
            setLocalShowLogs(showLogs);
        } else {
            setLocalShowLogs(false);
        }
    }, [showLogs]);

    // useEffect( setTotalAmount(sumTaskAmount(formData.amount, formData.addedCost));
    useEffect(() => {
        setTotalAmount(sumTaskAmount(formData.amount, formData.addedCost));
    }, [formData.addedCost, formData.amount]);

    // useEffect( handleFormChange('totalAmount', totalAmount);
    useEffect(() => {
        handleFormChange('totalAmount', totalAmount);
    }, [totalAmount]);

    // useEffect( setTotalDuration(sumTaskDuration(formData.duration, formData.addedTime));
    useEffect(() => {
        setTotalDuration(sumTaskDuration(formData.duration, formData.addedTime));
    }, [formData.addedTime, formData.duration]);

    // useEffect( handleFormChange('totalDuration', totalDuration);
    useEffect(() => {
        handleFormChange('totalDuration', totalDuration);
    }, [totalDuration]);

    // useEffect( console.log( totalAmount, totalDuration
    // useEffect(() => {
    //     if (localShowLogs) {
    //         console.log('CreateShoeTaskFormModal totalAmount', totalAmount);
    //         console.log('CreateShoeTaskFormModal totalDuration', totalDuration);
    //     }
    // }, [localShowLogs, totalAmount, totalDuration]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <h2>Animal: {formData.name}</h2>
                <form>
                    <div>
                        <Button onClick={onClose} sx={{mt: 2, mr: 1}}>
                            Close
                        </Button>
                    </div>
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Duration (mins)"
                        name="duration"
                        type="number"
                        value={formData.duration}
                        onChange={(event) => {
                            const duration = textFieldValToInteger(event.target.value);
                            handleFormChange('duration', duration);
                        }}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Amount"
                        name="amount"
                        type="number"
                        value={formData.amount}
                        onChange={(event) => {
                            const amount = textFieldValToInteger(event.target.value);
                            handleFormChange('amount', amount);
                        }}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Added Cost"
                        name="added-cost"
                        type="number"
                        value={formData.addedCost}
                        onChange={(event) => {
                            const addedCost = textFieldValToInteger(event.target.value);
                            handleFormChange('addedCost', addedCost);
                        }}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Added Time (mins)"
                        name="added-time"
                        type="number"
                        value={formData.addedTime}
                        onChange={(event) => {
                            const addedTime = textFieldValToInteger(event.target.value);
                            handleFormChange('addedTime', addedTime);
                        }}
                    />
                    <Typography>
                        Total Amount ($): {formData.totalAmount}
                    </Typography>
                    <Typography>
                        Total Time (mins.): {formData.totalDuration}
                    </Typography>
                    <div>
                        <Button onClick={handleSaveAndUpdateNext} sx={{mt: 2}}>
                            Next
                        </Button>
                    </div>
                    <div>
                        <Button onClick={handleSaveAndUpdatePrev} sx={{mt: 2}}>
                            Prev
                        </Button>
                    </div>
                </form>
            </Box>
        </Modal>
    );
}

// export default CreateShoeTaskFormModal;
