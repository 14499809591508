import React, {useEffect, useState} from 'react';
import DevStateProvider from "../../data/providers/DevStateProvider";
import PrimarySearchAppBar from "../header/PrimarySearchAppBar";
import { FarrierClientAlertsProvider } from "../../data/providers/alerts/FarrierClientAlertsProvider";
import { FarrierAlertsIDBProvider } from "../../data/providers/alerts/FarrierAlertsIDBProvider";


export default function WrappedHeaderBar({
                                             ampUserProp,
                                             routerPathsProp,
                                             routerPathNamesProp,
                                             authStatusProp,
                                             signOutProp,
                                             userSubProp,
                                             subPaymentProp,
                                             showLogsProp,
                                             resetProviderState,
                                             ...props
                                         }) {
    const [ampUser, setAmpUser] = useState(null);
    const [ampUserIdVal, setAmpUserIdVal] = useState(null);
    const [showLogsVal, setShowLogsVal] = useState(false);

    // useEffect( setAmpUser(ampUserProp);
    useEffect(() => {
        if (ampUser === null && ampUserProp !== null) {
            setAmpUser(ampUserProp);
        }
    }, [ampUserProp]);

    // useEffect( setShowLogsVal(showLogsProp);
    useEffect(() => {
        if (showLogsProp !== null) {
            setShowLogsVal(showLogsProp);
        }
    }, [showLogsProp]);

    // useEffect( console.log( ampUser
    useEffect(() => {
        if (showLogsVal) {
            // console.log('WrappedHeaderBar ampUserIdVal', ampUserIdVal);
            console.log('WrappedHeaderBar ampUser', ampUser);
        }
    // }, [ampUserIdVal, showLogsVal]);
    }, [ampUser, showLogsVal]);

    return (
        <div>
            <DevStateProvider>
                <FarrierClientAlertsProvider
                    // ampUserIdProp={ampUserProp.id}
                    ampUserIdProp={ampUserProp ? ampUserProp.id : null}
                    showLogsProp={showLogsProp}
                    resetProviderState={resetProviderState}
                >
                    <FarrierAlertsIDBProvider
                        ampUserIdProp={ampUserProp ? ampUserProp.id : null}
                        showLogsProp={showLogsProp}
                        resetProviderState={resetProviderState}
                    >
                    <PrimarySearchAppBar
                        ampUser={ampUserProp}
                        routerPaths={routerPathsProp}
                        routerPathNames={routerPathNamesProp}
                        authStatus={authStatusProp}
                        signOut={signOutProp}
                        userSub={userSubProp}
                        subPayment={subPaymentProp}
                    />
                    </FarrierAlertsIDBProvider>
                </FarrierClientAlertsProvider>
            </DevStateProvider>
        </div>
    );

    // return (
    //     <div>
    //         {ampUserProp ? (
    //             <DevStateProvider>
    //                 <FarrierClientAlertsProvider
    //                     ampUserIdProp={ampUserProp.id}
    //                     showLogsProp={showLogsProp}
    //                     resetProviderState={resetProviderState}
    //                 >
    //                     <PrimarySearchAppBar
    //                         ampUser={ampUserProp}
    //                         routerPaths={routerPathsProp}
    //                         routerPathNames={routerPathNamesProp}
    //                         authStatus={authStatusProp}
    //                         signOut={signOutProp}
    //                         userSub={userSubProp}
    //                         subPayment={subPaymentProp}
    //                     />
    //                 </FarrierClientAlertsProvider>
    //             </DevStateProvider>
    //         ) : (
    //             <DevStateProvider>
    //                 <PrimarySearchAppBar
    //                     ampUser={ampUserProp}
    //                     routerPaths={routerPathsProp}
    //                     routerPathNames={routerPathNamesProp}
    //                     authStatus={authStatusProp}
    //                     signOut={signOutProp}
    //                     userSub={userSubProp}
    //                     subPayment={subPaymentProp}
    //                 />
    //             </DevStateProvider>
    //         )}
    //     </div>
    // );
}
