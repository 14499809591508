import {deleteAppointmentById} from "../../../data/delete/deleteRecords";

export function handleDeleteApptModalClose(setShowDeleteApptModal, setModelRecToDelete, showLogs) {
    setShowDeleteApptModal(false);
    setModelRecToDelete(null);
}

export function handleDeleteApptCancel(modelRecToDelete, setShowDeleteApptModal, setModelRecToDelete, showLogs){
    if (showLogs) {
        console.log('The Appt record has NOT been deleted.', modelRecToDelete);
    }
    handleDeleteApptModalClose(setShowDeleteApptModal, setModelRecToDelete, showLogs);
}

export async function handleDeleteApptConfirm(modelRecToDelete, setDeleteApptRes, setDisplayDeleteApptModal, setModelRecToDelete, showLogs){
    if (showLogs) {
        console.log('The Appt record has been deleted.', modelRecToDelete);
    }
    const deleteResObj = await deleteAppointmentById(modelRecToDelete, showLogs);
    setDeleteApptRes(deleteResObj);
    handleDeleteApptModalClose(setDisplayDeleteApptModal, setModelRecToDelete, showLogs);
}
