import React, {useState, useEffect} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';
import StyledCardContentTypography from "../text/StyledCardContentTypography";
import { CardActionArea, CardActions } from '@mui/material';
import StyledListItemText from "../text/StyledListItemText";

// import defaultPic from "../../static/images/cards/halibut-circle-hook-outline-1.svg";
// import PostUserContactInfoList from "../lists/PostUserContactInfoList";
// import {defaultMissingPersonAttrs} from "../../utils/formObjects";


export default function ApptPreviewCard(props) {
    const [ampUser, setAmpUser] = useState({});
    const [showLogs, setShowLogs] = useState(false);
    const [apptFormValues, setApptFormValues] = useState({});
    const [apptNoteFormValues, setApptNoteFormValues] = useState({});
    const [tasksForAnimals, setTasksForAnimals] = useState([]);

    const typographySX = {
        color: 'black',
        fontSize: ['1.5rem', '1.75rem', '1.5rem', '1rem']
    }
    const listSubheaderSX = {
        padding: '0 0.5vw',
        margin: '1vh 0.5vw',
        color: 'black',
        fontSize: ['1.75rem', '2rem', '1.5rem', '1rem']
    }

    // useEffect( setShowLogs(
    useEffect(() => {
        if (props?.showLogs) {
            setShowLogs(props.showLogs);
        }
    }, [props.showLogs]);

    // useEffect( setApptFormValues( setApptNoteFormValues( setTasksForAnimals(
    useEffect(() => {
        if (props?.apptFormValues) {
            setApptFormValues(props.apptFormValues);
        }
        if (props?.apptNoteFormValues) {
            setApptNoteFormValues(props.apptNoteFormValues);
        }
        if (props?.tasksForAnimals) {
            setTasksForAnimals(props.tasksForAnimals);
        }
    },[props]);

    // useEffect( console.log(apptFormValues, apptNoteFormValues, tasksForAnimals
    useEffect(() => {
        if (showLogs) {
            console.log('apptFormValues', apptFormValues);
            console.log('apptNoteFormValues', apptNoteFormValues);
            console.log('tasksForAnimals', tasksForAnimals);
        }
    }, [apptFormValues, apptNoteFormValues, tasksForAnimals, showLogs]);

    // useEffect( setAmpUser(props.ampUser);
    useEffect(() => {
        if (props?.ampUser) {
            setAmpUser(props.ampUser);
        }
    },[props]);

    // TODO: Update justify, size for StyledCardContentTypography, StyledListItemText,
    //  verify Edit button works on nav back to create form
    return (
        <Box sx={{
            width: {
                xs: '90%',    // 0px Full width on extra-small and small devices
                sm: '90%',     // 600px Full width on extra-small and small devices
                md: 420,       // 900px Fixed width on medium devices and up
                // md: '100%',       // Fixed width on medium devices and up
                lg: 600,        // 1200px Fixed width on large devices and up
            },
            maxWidth: 600,
            minWidth: 300,
            bgcolor: 'background.paper',
            color: 'black',
            position: 'relative',
            overflowX: 'hidden',
            overflowY: 'auto',
            '& ul': { padding: 0 },
            '& > :not(style)': { m: 1 }
        }}
        >
        <div>
            <h2>Appt Preview Card</h2>
            <Card>
                <CardActionArea>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Client Name: {apptFormValues?.clientName}
                        </Typography>
                        <div>
                            <StyledCardContentTypography
                                text={`Appt. Status: ${apptFormValues?.appointmentStatus}`}
                            />
                            <StyledCardContentTypography
                                text={`Address: ${apptFormValues?.address}`}
                            />
                            <StyledCardContentTypography
                                text={`State: ${apptFormValues?.state}`}
                            />
                            <StyledCardContentTypography
                                text={`City: ${apptFormValues?.city}`}
                            />
                            <StyledCardContentTypography
                                text={`Zip Code: ${apptFormValues?.zipCode}`}
                            />
                            <StyledCardContentTypography
                                text={`Amount: ${apptFormValues?.amount}`}
                            />
                            <StyledCardContentTypography
                                text={`Duration: ${apptFormValues?.duration}`}
                            />
                            <StyledCardContentTypography
                                text={`Gate Code: ${apptFormValues?.gateCode}`}
                            />
                            <StyledCardContentTypography
                                text={`Appt Date Time: ${apptFormValues?.apptDateTime}`}
                            />
                            <StyledCardContentTypography
                                text={`Appt Date: ${apptFormValues?.apptDate}`}
                            />
                            <StyledCardContentTypography
                                text={`Appt Time: ${apptFormValues?.apptTime}`}
                            />
                            <StyledCardContentTypography
                                text={`Appt Note: ${apptNoteFormValues?.apptNote}`}
                            />
                            <StyledCardContentTypography
                                text={`Appt Note Rec: ${apptNoteFormValues?.note}`}
                            />
                        </div>
                        <List
                            sx={{
                                width: '100%',
                                bgcolor: 'background.paper',
                                position: 'relative',
                                overflow: 'auto',
                                maxHeight: 200,
                                '& ul': { padding: 0 },
                                '& > :not(style)': { m: 1 }
                            }}
                            subheader={<li />}
                        >
                            <li>
                                <ul>
                                    <ListSubheader sx={listSubheaderSX}>Tasks</ListSubheader>
                                    {tasksForAnimals.map((task, index) => {
                                        return (
                                            <div key={index}>
                                                <Divider />
                                                <StyledListItemText
                                                    text={`Client Name: ${task.name}`}
                                                    secondaryText={`Task Name: ${task.taskName}`}
                                                />
                                                <StyledListItemText
                                                    text={`Total Duration: ${task.totalDuration}`}
                                                    secondaryText={`Duration: ${task.duration}, Added Time: ${task.addedTime}`}
                                                />
                                                <StyledListItemText
                                                    text={`Total Amount: ${task.totalAmount}`}
                                                    secondaryText={`Amount: ${task.amount}, Added Cost: ${task.addedCost}`}
                                                />
                                                <StyledListItemText
                                                    text={`Task Note: ${task.taskNote}`}
                                                />
                                                <Divider />
                                            </div>
                                        )
                                    })}
                                </ul>
                            </li>
                        </List>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    {/*<BaseNavigateButton*/}
                    {/*    navTarget={"/create-appt"}*/}
                    {/*    label="Edit"*/}
                    {/*    navState={{*/}
                    {/*        apptFormValues: apptFormValues,*/}
                    {/*        apptNoteFormValues: apptNoteFormValues,*/}
                    {/*        tasksForAnimals: tasksForAnimals,*/}
                    {/*    }}*/}
                    {/*/>*/}
                </CardActions>
            </Card>
        </div>
        </Box>
    )
}