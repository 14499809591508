import React, {useEffect, useState} from 'react';
import {useParams, useLocation} from "react-router-dom";
import {RequireAuth} from '../../auth/RequireAuth';
import DevStateProvider from "../../../data/providers/DevStateProvider";
import CreateClientNoteScreen from "../../../screens/CreateClientNoteScreen";
import AmpUserClientsDataProvider from "../../../data/providers/people/AmpUserClientsDataProvider";
import {RequireSub} from "../../auth/RequireSub";

export default function WrappedCreateClientNote(props) {
    const [ampUser, setAmpUser] = useState(null);
    const [clientRec, setClientRec] = useState(null);

    const { clientRouterPath } = useParams();
    const location = useLocation();

    const stateClientRec = location.state?.clientRec;

    // useEffect(() => {
    //     console.log('location', location);
    // }, [location]);

    useEffect(() => {
        if (props?.ampUser) {
            setAmpUser(props.ampUser);
            // setAmpUserId(props.ampUser.id);
        }
    }, [props]);

    useEffect(() => {
        if (stateClientRec) {
            setClientRec(stateClientRec);
        }
    }, [location]);

    useEffect(() => {
        console.log('ampUser', ampUser);
        console.log('clientRouterPath', clientRouterPath);
    }, [clientRouterPath]);

    return (
        <DevStateProvider>
            <RequireAuth>
                {ampUser ? (
                    <RequireSub ampUser={ampUser}>
                        <AmpUserClientsDataProvider ampUserId={ampUser.id} showLogs={props.showLogs} resetProviderState={props.resetProvidersStates}>
                            <CreateClientNoteScreen
                                routerPaths={props.routerPaths}
                                ampUser={ampUser}
                                clientRouterPath={clientRouterPath}
                                showLogs={props.showLogs}
                                clientRec={clientRec}
                            />
                        </AmpUserClientsDataProvider>
                    </RequireSub>
                ) : (
                    <div><h2>Loading Client Data...</h2></div>
                )}
            </RequireAuth>
        </DevStateProvider>
    )
}
