import {Button} from "@mui/material";

// TODO - USED THIS STYLING TO UPDATE STYLING FOR BASE NAVIGATE BUTTON

export default function BaseStyledDeleteButton(props) {
    return (
        <Button
            variant={props?.variant || "contained"}
            size={props?.size || "small"}
            sx={{
                textTransform: 'none',
                fontSize: props.fontSize || ['1rem', '1.25rem', '1rem', '1rem'],
                backgroundColor: 'red',
                color: 'white',
                padding: '0.25rem 1.5rem 0.25rem 1.5rem',
                margin: '0.25rem 1rem 0.5rem 1rem',
                minWidth: props?.minWidth || 'auto',
                ...props.customsx,
                ...props.style
            }}
            {...props}
        >
            {props?.label || "Delete"}
        </Button>
    )
}
