import React, { useState, useEffect } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { SortDirection } from "aws-amplify";
import AmpUserApptNotesContext from "../../contexts/AmpUserApptNotesContext";
import { AppointmentNote, Appointment } from '../../../models';
// import {useConsoleLog, useConsoleWarn, useConsoleError} from "../../components/hooks/logging/console";

// const AmpUserApptNotesProvider = ({ children, ampUserId, apptId, showLogs }) => {
const AmpUserApptNotesProvider = ({ children, ampUserId, showLogs, resetProviderState }) => {
    const [ampUserApptIds, setAmpUserApptIds] = useState([]);
    const [ampUserApptNotesRecs, setAmpUserApptNotesRecs] = useState([]);
    const [localShowLogs, setLocalShowLogs] = useState(false);
    const [apptNoteRecsByApptId, setApptNoteRecsByApptId] = useState({});

    const resetContext = () => {
        setAmpUserApptIds([]);
        setAmpUserApptNotesRecs([]);
        setApptNoteRecsByApptId({});
        // console.warn('!!! AmpUserApptNotesProvider resetContext() - RESET');
    };

    useEffect(() => {
        if (resetProviderState) {
            resetContext();
        }
    }, [resetProviderState]);

    // useEffect( setLocalShowLogs(showLogs);
    useEffect(() => {
        if (showLogs) {
            setLocalShowLogs(showLogs);
        }
        else {
            setLocalShowLogs(false);
        }
    }, [showLogs]);

    // useEffect( console.log( ampUserApptNotesRecs
    useEffect(() => {
        if (localShowLogs) {
            console.log('AmpUserApptNotesProvider ampUserApptNotesRecs', ampUserApptNotesRecs);
            console.log('AmpUserApptNotesProvider apptNoteRecsByApptId', apptNoteRecsByApptId);
            console.log('AmpUserApptNotesProvider ampUserApptIds', ampUserApptIds);
            console.log('AmpUserApptNotesProvider resetProviderState', resetProviderState);
        }
    }, [localShowLogs, ampUserApptNotesRecs, ampUserApptIds, resetProviderState]);

    useEffect(() => {
        // if (ampUserId && apptId) {
        // if (ampUserId && ampUserApptIds) { << prev
        if (!resetProviderState && ampUserId && ampUserApptIds && ampUserApptIds.length > 0) {
            // const subscription = DataStore.observeQuery(AppointmentNote,
            //     (an) => an.appointmentID.eq(apptId),
            //     {
            //         sort: s => s.createdAt(SortDirection.ASCENDING)
            //     })
            const subscription = DataStore.observeQuery(AppointmentNote,
                (an) => an.or((an) =>
                    ampUserApptIds.map(apptId => an.appointmentID.eq(apptId))
                ),
                {
                    sort: s => s.createdAt(SortDirection.ASCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    if (localShowLogs) {
                        // console.log(`AmpUserApptNotesProvider c [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                        // useConsoleLog(localShowLogs, [{label: `AmpUserApptNotesProvider c [msg] item count:`, value: items.length}]);
                        // useConsoleLog(localShowLogs, [{label: `AmpUserApptNotesProvider c [msg] isSynced:`, value: isSynced}]);
                        // console.log('AmpUserApptNotesProvider c [Snapshot] items', items);
                        // useConsoleLog(localShowLogs, [{label: 'AmpUserApptNotesProvider c [Snapshot] items', value: items}]);
                    }
                    if (isSynced) {
                        const notesByApptId = {};
                        for (const note of items) {
                            if (Object.hasOwn(notesByApptId, note.appointmentID)) {
                                notesByApptId[note.appointmentID].push(note);
                            } else {
                                notesByApptId[note.appointmentID] = [note];
                            }
                        }
                        setAmpUserApptNotesRecs(items);
                        setApptNoteRecsByApptId(notesByApptId);
                    }
                });
            // Remember to unsubscribe when the component unmounts
            // return () => subscription.unsubscribe();
            return () => {
                subscription.unsubscribe();
                // resetContext();
            };
        }
    // }, [ampUserId, apptId, localShowLogs]);
    }, [localShowLogs, ampUserId, ampUserApptIds, resetProviderState]);

    return (
        <AmpUserApptNotesContext.Provider value={{ampUserApptNotesRecs, apptNoteRecsByApptId, setAmpUserApptIds}}>
            {children}
        </AmpUserApptNotesContext.Provider>
    );
}

export default AmpUserApptNotesProvider;
