import React, { useState, useContext, useEffect } from "react";
import DevStateContext from "../../data/contexts/DevStateContext";
import ClientApptsContext from "../../data/contexts/ClientApptsContext";
import ApptInfoCard from "../../components/cards/ApptInfoCard";
import StyledCardContentTypography from "../../components/text/StyledCardContentTypography";
import StyledListBox from "../../components/boxes/StyledListBox";
import {breadcrumbRoutesApptsScreen} from "../../utils/breadcrumbsRoutes";
import {useResponsiveViewport} from "../../data/providers/ResponsiveViewportProvider";
import RouterBreadcrumbs from "../../components/router/RouterBreadcrumbs";


export default function AmpUserClientApptsScreen(props) {
    const [showLogs, setShowLogs] = useState(false);
    const [ampUser, setAmpUser] = useState(null);
    const [clientRec, setClientRec] = useState(null);
    const [clientApptRecs, setClientApptRecs] = useState([]);
    const [breadcrumbRoutes, setBreadcrumbRoutes] = useState([]); // [{path: '', name: ''}, ...
    const { isSmallScreen, isLargeScreen } = useResponsiveViewport();

    const { devStateObj } = useContext(DevStateContext);
    const {clientApptsRecs} = useContext(ClientApptsContext);

    // setBreadcrumbRoutes
    useEffect(() => {
        setBreadcrumbRoutes(breadcrumbRoutesApptsScreen() || [])
        // console.error('breadcrumbRoutes', breadcrumbRoutes)
    }, [props]);

    // useEffect( setShowLogs(devStateObj.showLogs);
    useEffect(() => {
        if (devStateObj?.showLogs) {
            setShowLogs(devStateObj.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [devStateObj]);

    // useEffect( setClientRec(props.clientRec);
    useEffect(() => {
        if (props.clientRec) {
            setClientRec(props.clientRec);
        }
    }, [props.clientRec]);

    // useEffect( setClientApptRecs(clientApptsRecs);
    useEffect(() => {
        if (clientApptsRecs) {
            setClientApptRecs(clientApptsRecs);
        }
    }, [clientApptsRecs]);

    useEffect(() => {
        if (showLogs) {
            console.log('AmpUserClientApptsScreen clientRec', clientRec);
            console.log('AmpUserClientApptsScreen clientApptRecs', clientApptRecs);
            console.warn('CONTEXT clientApptsRecs', clientApptsRecs);
        }
    }, [showLogs, clientRec, clientApptRecs, clientApptsRecs]);

    function displayClientAppts() {
        if (clientRec && clientApptRecs && clientApptRecs.length > 0) {
        // if (clientRec && clientApptRecs) {
            return clientApptRecs.map((clientApptRec, index) => {
                console.log('!!! displayClientAppts clientApptRec', clientApptRec);
                return (
                    <ApptInfoCard
                        key={`clientApptRec-${index}`}
                        apptRec={clientApptRec}
                        showLogs={showLogs}
                        clientRec={clientRec}
                    />
                )
            })
        }
        else {
            return (
                <StyledCardContentTypography
                    text={`No Appts`}
                />
            )
        }
    }

    return (
        <div style={{
            backgroundColor: 'lightgray',
            minHeight: '15vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 'calc(10px + 2vmin)',
            // color: 'white',
            color: 'black',
            // marginBottom: '0.5vh'
        }}>
            <h2>Client Appts</h2>
            <RouterBreadcrumbs breadcrumbsArr={breadcrumbRoutes} />
            <StyledListBox
                width={isLargeScreen ? '90%' : '100%'}
                style={{
                    color: 'white',
                    backgroundColor: 'lightgray',
                    borderTop: '1px solid black',
                    borderBottom: '1px solid black',
                    marginTop: '0.5vh',
                }}
            >
                {displayClientAppts()}
            </StyledListBox>
        </div>
    )
}
