import React, {useState, useEffect} from 'react';
import {useLocation, useParams} from "react-router-dom";
import DevStateProvider from "../../../data/providers/DevStateProvider";
import {RequireAuth} from '../../auth/RequireAuth';
import ApptShoeTasksProvider from "../../../data/providers/appts/ApptShoeTasksProvider";
import ApptTrimTasksProvider from "../../../data/providers/appts/ApptTrimTasksProvider";
import ApptNotesProvider from "../../../data/providers/notes/ApptNotesProvider";
import ApptInfoScreen from "../../../screens/appts/ApptInfoScreen";
import AmpUserApptAnimalsProvider from "../../../data/providers/appts/AmpUserApptAnimalsProvider";
import AmpUserApptsProvider from "../../../data/providers/appts/AmpUserApptsProvider";
import {RequireSub} from "../../auth/RequireSub";

// import AnimalShoePackagesProvider from "../../data/providers/AnimalShoePackagesProvider";

// TODO - ADD PROVIDERS FOR APPT INFO, BUILD OUT INFO SCREEN,
//  ADD BUTTON TO NAVIGATE HERE TO THE INFO SCREEN
export default function WrappedApptInfo({routerPaths, ampUser, showLogs, resetProvidersStates}) {
    const [apptInfoApptRec, setApptInfoApptRec] = useState(null);
    const [apptInfoAnimalRecs, setApptInfoAnimalRecs] = useState(null);
    const [clientRec, setClientRec] = useState(null);
    // const [shoePackageRecs, setShoePackageRecs] = useState(null);
    // const [shoeTaskRecs, setShoeTaskRecs] = useState(null);
    // const [trimTaskRecs, setTrimTaskRecs] = useState(null);
    const [localShowLogs, setLocalShowLogs] = useState(false);
    const [routerPathParam, setRouterPathParam] = useState(null);
    const [fetchApptInfo, setFetchApptInfo] = useState(true);
    const [pauseForFetch, setPauseForFetch] = useState(true);

    const {apptRouterPath} = useParams();
    const location = useLocation();

    const stateApptRec = location.state?.apptRec;
    const stateAnimalRecs = location.state?.animalRecs;
    const stateClientRec = location.state?.clientRec;

    // useEffect( setLocalShowLogs(showLogs);
    useEffect(() => {
        if (showLogs) {
            setLocalShowLogs(showLogs);
        } else {
            setLocalShowLogs(false);
        }
    }, [showLogs]);

    // useEffect( setRouterPathParam(apptRouterPath);
    useEffect(() => {
        if (apptRouterPath) {
            setRouterPathParam(apptRouterPath);
        }
    }, [apptRouterPath]);

    // useEffect( console.log( routerPathParam
    useEffect(() => {
        if (localShowLogs) {
            console.log('routerPathParam', routerPathParam);
        }
    }, [localShowLogs, routerPathParam]);

    // useEffect( console.log('location', location);
    useEffect(() => {
        if (localShowLogs) {
            console.log('location', location);
        }
    }, [localShowLogs, location]);

    // useEffect( setApptRec(stateApptRec);
    useEffect(() => {
        let fetchApptInfoByRouterPath = false;
        if (stateApptRec) {
            setApptInfoApptRec(stateApptRec);
        } else {
            fetchApptInfoByRouterPath = true;
        }
        if (stateAnimalRecs) {
            setApptInfoAnimalRecs(stateAnimalRecs);
        } else {
            fetchApptInfoByRouterPath = true;
        }
        if (stateClientRec) {
            setClientRec(stateClientRec);
        } else {
            fetchApptInfoByRouterPath = true;
        }
        // TODO - ? NEED TO ADD FETCH FOR APPT CLIENT OR ANIMALS?,
        //  OR ADD LOGIC TO SCREEN TO USE PROVIDERS OVER PROPS?
        // TODO - LOOKS LIKE INFO SCREEN HANDLES FETCHING ANIMALS AT LEAST
        // if (fetchApptInfoByRouterPath) {
        //     setFetchApptInfo(true);
        //     setPauseForFetch(true);
        // }
        // else {
        //     setFetchApptInfo(false);
        //     setPauseForFetch(false);
        // }
    }, [location]);

    // useEffect( setAnimalRecs(stateAnimalRecs);
    // useEffect(() => {
    //     if (stateAnimalRecs) {
    //         setApptInfoAnimalRecs(stateAnimalRecs);
    //     }
    // }, [location]);

    return (
        <DevStateProvider>
            <RequireAuth>
                {apptInfoApptRec && ampUser ? (
                    // {apptInfoApptRec && !fetchApptInfo && !pauseForFetch ? (
                    <RequireSub ampUser={ampUser}>
                        <AmpUserApptsProvider apptId={apptInfoApptRec.id} showLogs={showLogs}
                                              resetProviderState={resetProvidersStates}>
                            <AmpUserApptAnimalsProvider apptId={apptInfoApptRec.id} showLogs={showLogs}
                                                        ampUser={ampUser} resetProviderState={resetProvidersStates}>
                                <ApptNotesProvider apptId={apptInfoApptRec.id} showLogs={showLogs}
                                                   resetProviderState={resetProvidersStates}>
                                    <ApptTrimTasksProvider apptId={apptInfoApptRec.id} showLogs={showLogs}
                                                           resetProviderState={resetProvidersStates}>
                                        <ApptShoeTasksProvider apptId={apptInfoApptRec.id} showLogs={showLogs}
                                                               resetProviderState={resetProvidersStates}>
                                            <ApptInfoScreen
                                                routerPaths={routerPaths}
                                                ampUser={ampUser}
                                                apptRec={apptInfoApptRec}
                                                animalRecs={apptInfoAnimalRecs}
                                                clientRec={clientRec}
                                                showLogs={showLogs}/>
                                        </ApptShoeTasksProvider>
                                    </ApptTrimTasksProvider>
                                </ApptNotesProvider>
                            </AmpUserApptAnimalsProvider>
                        </AmpUserApptsProvider>
                    </RequireSub>
                ) : (
                    <h2>Loading Appt Rec...</h2>
                )}
            </RequireAuth>
        </DevStateProvider>
    )
}
