import {deleteClientNoteById} from "../../../data/delete/deleteRecords";

export function handleDeleteClientNoteModalClose(setDisplayDeleteClientNoteModal, setModelRecToDelete, showLogs) {
    setDisplayDeleteClientNoteModal(false);
    setModelRecToDelete(null);
}

export function handleDeleteClientNoteCancel(modelRecToDelete, setDisplayDeleteClientNoteModal, setModelRecToDelete, showLogs){
    if (showLogs) {
        console.log('The Client Note record has NOT been deleted.', modelRecToDelete);
    }
    handleDeleteClientNoteModalClose(setDisplayDeleteClientNoteModal, setModelRecToDelete, showLogs);
}

export async function handleDeleteClientNoteConfirm(modelRecToDelete, setDeleteClientNoteRes, setDisplayDeleteClientNoteModal, setModelRecToDelete, showLogs){
    if (showLogs) {
        console.log('The Client Note record has been deleted.', modelRecToDelete);
    }
    const deleteResObj= await deleteClientNoteById(modelRecToDelete.id, showLogs);
    setDeleteClientNoteRes(deleteResObj);
    handleDeleteClientNoteModalClose(setDisplayDeleteClientNoteModal, setModelRecToDelete, showLogs);
}
