import { Client,
    ClientNote,
    PublicClient,
    PublicClientStatus,
    SharedPublicClientStatus,
    SharedPublicClientUpdateStatus } from "../../../models";
import { DataStore } from "aws-amplify";
import { generateSaltedRouterPath, generateSaltedApiKey } from "../../../utils/breadcrumbsRoutes";
import { convertToFullInteger } from "../../../utils/numbers";
import { v4 as uuidv4 } from 'uuid';
import { getNowAsAWSDateTime } from "../../../utils/datesTimes";

export async function createClientRec(clientFormValues, clientNoteFormValues, ampUserRec, showLogs) {
    // const clientRouterPath = md5(`${ampUserRec.name}-${clientFormValues.name}-${clientFormValues.phone}-${Date.now()}`);
    const resObj = {};
    const routerPathUUID = uuidv4();
    // const clientRouterPath = modelRouterPathAttrFactory(`${ampUserRec.name}-${clientFormValues.name}-${routerPathUUID}`);
    const clientRouterPath = generateSaltedRouterPath(`${ampUserRec.id}-${routerPathUUID}`);
    // const clientRecObj = {...clientRec, id: clientRecId};
    const clientStartedAt = getNowAsAWSDateTime();
    try {
        const newClientRec = await DataStore.save(new Client(
            {
                name: clientFormValues.name,
                email: clientFormValues?.email || null,
                phone: clientFormValues.phone,
                phoneE164: clientFormValues.phoneE164,
                balance: clientFormValues.balance ? convertToFullInteger(clientFormValues.balance) : 0,
                streetAddress: clientFormValues.streetAddress,
                city: clientFormValues.city,
                state: clientFormValues.state,
                zipCode: clientFormValues.zipCode,
                clientStatus: clientFormValues.clientStatus,
                ampuserID: ampUserRec.id,
                routerPath: clientRouterPath,
                gateCode: clientFormValues.gateCode,
                isEdited: false,
                isDeleted: false,
                clientStarted: clientStartedAt,
                alternateContactName: clientFormValues.alternateContactName,
                alternateContactPhone: clientFormValues.alternateContactPhone,
                alternateContactPhoneE164: clientFormValues.alternateContactPhoneE164,
                publicClientEditingEnabled: clientFormValues.publicClientEditingEnabled || false,
                addedApptAmount: clientFormValues.addedApptAmount ? convertToFullInteger(clientFormValues.addedApptAmount) : 0,
                addedApptDuration: clientFormValues.addedApptDuration ? convertToFullInteger(clientFormValues.addedApptDuration) : 0,
            }
        ));
        if (showLogs) {
            console.log('createClient newClientRec', newClientRec);
        }
        resObj.success = true;
        resObj.newClientRec = newClientRec;
        if (newClientRec && clientNoteFormValues?.note) {
            // const newClientNoteRec = await createClientNote(newClientRec, clientFormValues.clientNote);
            const newClientNoteRec = await createClientNote(newClientRec, clientNoteFormValues.note);
            if (showLogs) {
                console.log('createClient newClientNoteRec', newClientNoteRec);
            }
            resObj.newClientNoteRec = newClientNoteRec;
        }
        return resObj;
    }
    catch (error) {
        console.error('createClientRec error', error);
        resObj.success = false;
        resObj.error = error;
        return resObj;
    }
}

// TODO - START HERE, ADD DB STREAM TO ADD APIKEY TO USAGE PLAN

export async function createPublicClientRec(ampUserRec, clientId, publicClientFormVals, showLogs) {
    const resObj = {};
    const routerPathUUID = uuidv4();
    // const publicClientRouterPath = modelRouterPathAttrFactory(`${ampUserRec.id}-${clientId}-${routerPathUUID}`);
    const publicClientRouterPath = generateSaltedRouterPath(`${ampUserRec.id}-${clientId}-${routerPathUUID}`);
    const sharedRouterPathUUID = uuidv4();
    const publicClientSharedRouterPath = generateSaltedRouterPath(`${sharedRouterPathUUID}-${ampUserRec.id}-${clientId}`);
    const apiKeyUUID = uuidv4();
    const publicClientApiKey = generateSaltedApiKey(`${apiKeyUUID}-${ampUserRec.id}-${clientId}`);
    try {
        publicClientFormVals.ampuserID = ampUserRec.id;
        publicClientFormVals.clientID = clientId;
        publicClientFormVals.routerPath = publicClientRouterPath;
        publicClientFormVals.sharedRouterPath = publicClientSharedRouterPath;
        publicClientFormVals.publicClientStatus = PublicClientStatus.NEW;
        publicClientFormVals.sharedPublicClientStatus = SharedPublicClientStatus.PENDING;
        publicClientFormVals.sharedPublicClientUpdateStatus = SharedPublicClientUpdateStatus.AWAITING_CLIENT_UPDATE;
        publicClientFormVals.publicUpdatingEnabled = true;
        publicClientFormVals.formSubmitApiKey = publicClientApiKey;
        publicClientFormVals.ampUserIdField = ampUserRec.id;
        publicClientFormVals.isDeleted = false;

        if (showLogs) {
            console.log('createPublicClientRec publicClientFormVals', publicClientFormVals);
        }

        const newPublicClientRec = await DataStore.save(new PublicClient(
            publicClientFormVals
        ));
        if (showLogs) {
            console.log('createPublicClientRec newPublicClientRec', newPublicClientRec);
        }
        resObj.success = true;
        resObj.newPublicClientRec = newPublicClientRec;
    }
    catch (error) {
        console.error('createPublicClientRec error', error);
        resObj.success = false;
        resObj.error = error;
        return resObj;
    }
    return resObj;
}

export async function createClientNote(newClientRec, clientNote, showLogs) {
    const routerPathUUID = uuidv4();
    const modelRouterPath = generateSaltedRouterPath(`${newClientRec.id}-${routerPathUUID}`);
    const newClientNoteRec = await DataStore.save(new ClientNote(
        {
            clientID: newClientRec.id,
            note: clientNote,
            isEdited: false,
            routerPath: modelRouterPath,
        }
    ));
    return newClientNoteRec;
}