import React, { useMemo } from "react";
import {useResponsiveViewport} from "../../data/providers/ResponsiveViewportProvider";
import {useNavigate} from "react-router-dom";
import {CardActionArea, CardActions} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import defaultPic from "../../static/images/default-running-horse.jpg";
import StyledCardContent from "./StyledCardContent";
import StyledCardContentTypography from "../text/StyledCardContentTypography";
import {deployState} from "../../utils/consts";
import BaseLinkButton from "../links/BaseLinkButton";
import StyledListCard from "./StyledListCard";

// export default function HomeScreenAnimalsCard(props) {
function HomeScreenAnimalsCard(props) {
    const { isSmallScreen, isLargeScreen } = useResponsiveViewport();

    let navigate = useNavigate();

    // Memoize the card media and actions
    const cardMedia = useMemo(() => (
        <CardMedia
            component="img"
            height="100%"
            image={defaultPic}
            alt={defaultPic}
        />
    ), []);

    const cardActions = useMemo(() => (
        <CardActions
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                alignItems: 'flex-start'
            }}
        >
            <div>
                {deployState === 'signup' ? null : (
                    <BaseLinkButton
                        linkTarget="/create-animal"
                        buttonText="Add Animal"
                        style={{ width: '100%' }}
                        // fontSize={['6vw', '3.5vw', '3vw', '2.5vw']}
                        fontSize={{
                            xs: '1.5rem', // Similar to small screens
                            sm: '2rem',   // Adjust for tablets and small desktops
                            md: '2.5rem', // Standard desktops
                            lg: '3rem',   // Larger desktops
                            xl: '3.5rem'  // Ultra-wide or larger screens
                        }}
                    />
                )}
            </div>
        </CardActions>
    ), []);


    return (
        <StyledListCard
            width={isLargeScreen ? '90%' : '100%'}
        >
            <CardActionArea
                onClick={() => navigate('/animals')}
            >
                {cardMedia}
                <StyledCardContent>
                    <StyledCardContentTypography
                        margin="1rem 0 0 0"
                        text="Animals"
                        fontSize={{
                            xs: '1.5rem', // Similar to small screens
                            sm: '2rem',   // Adjust for tablets and small desktops
                            md: '2.5rem', // Standard desktops
                            lg: '3rem',   // Larger desktops
                            xl: '3.5rem'  // Ultra-wide or larger screens
                        }}
                    />
                </StyledCardContent>
            </CardActionArea>
            {cardActions}
        </StyledListCard>
    )
}

export default React.memo(HomeScreenAnimalsCard);
