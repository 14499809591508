import {DataStore} from "aws-amplify";
import {Appointment,
    AppointmentNote,
    AppointmentAnimal,
    ShoeTask,
    TrimTask} from "../../../models";
import { generateSaltedRouterPath } from "../../../utils/breadcrumbsRoutes";
import {fetchLastApptByClientId, fetchApptNotes} from "../../get/fetchRecords";
import { v4 as uuidv4 } from 'uuid';

// TODO - START HERE, GET DATE AND TIME VALS WORKING
export async function createAppointment(ampUserId, createApptFormValues, lastApptIdParam, showLogs) {
    if (showLogs) {
        console.log('createAppointment(createApptFormValues)', createApptFormValues);
    }
    // TODO - USE LAST APPT AS PARAM FROM SCREEN, FROM PROVIDER
    const resObj = {}
    // TODO - create address from addr elements
    // TODO - get last appt - last appt with apptStatus "Done"
    let lastAppt;
    let lastApptId = lastApptIdParam;
    if (!lastApptId) {
        try {
            lastAppt = await fetchLastApptByClientId(createApptFormValues.clientID);
            if (lastAppt) {
                lastApptId = lastAppt.id;
            }
        }
        catch (error) {
            console.error('fetchLastApptByClientId error', error);
            resObj.success = false;
            resObj.error = error;
            return resObj;
        }
    }
    // else {
    //
    // }
    // let lastAppt;

    // get last appt notes
    let lastApptNotes = '';
    // if (lastAppt) {
    //     const lastApptNotes = await fetchApptNotes(lastAppt.id);
    // }

    // TODO - come back and verify this after testing new lastApptId param
    // if (lastApptId) {
    //     lastApptNotes = await fetchApptNotes(lastApptId);
    // }

    // let lastApptId = '';
    // if (lastAppt) {
    //     lastApptId = lastAppt.id;
    // }
    // TODO - get last appt pics
    // TODO - get last appt videos

    const routerPathUUID = uuidv4();
    const apptRouterPath = generateSaltedRouterPath(`Appointment-${createApptFormValues.clientId}-${routerPathUUID}`);
    try {
        const newApptRec = await DataStore.save(new Appointment({
            clientID: createApptFormValues.clientId,
            ampuserID: ampUserId,
            appointmentStatus: createApptFormValues.appointmentStatus,
            amount: createApptFormValues.amount,
            streetAddress: createApptFormValues.streetAddress,
            city: createApptFormValues.city,
            state: createApptFormValues.state,
            zipCode: createApptFormValues.zipCode,
            dateTime: createApptFormValues.apptDateTime,
            apptDate: createApptFormValues.apptDate,
            apptTime: createApptFormValues.apptTime,
            apptDuration: createApptFormValues.apptDuration,
            gateCode: createApptFormValues.gateCode,
            notes: createApptFormValues.notes,
            lastApptNotes: createApptFormValues.lastApptNotes,
            lastApptPicLinks: createApptFormValues.lastApptPicLinks,
            lastApptVideoLinks: createApptFormValues.lastApptVideoLinks,
            thisApptPicLinks: createApptFormValues.thisApptPicLinks,
            thisApptVideoLinks: createApptFormValues.thisApptVideoLinks,
            lastApptID: lastApptId,
            isEdited: false,
            routerPath: apptRouterPath,
        }))
        if (showLogs) {
            console.log('newApptRec', newApptRec);
        }
        resObj.success = true;
        resObj.newApptRec = newApptRec;
        return resObj;
    }
    catch (error) {
        console.error('createAppointment error', error);
        resObj.success = false;
        resObj.error = error;
        return resObj;
    }
    // return newApptRec;
}

// TODO - UPDATE EDIT APPT FORM TO USE NEW RETURN OBJ
export async function createAppointmentAnimal(apptId, animalId, showLogs) {
    // if (showLogs) {
    //     console.log('createAppointmentAnimal(apptId, animalId)', apptId, animalId);
    // }
    // create AppointmentAnimal
    const resObj = {};
    try {
        const newApptAnimalRec = await DataStore.save(new AppointmentAnimal({
            appointmentID: apptId,
            appointmentId: apptId,
            animalID: animalId,
            animalId: animalId,
        }))
        if (showLogs) {
            console.log('newApptAnimalRec', newApptAnimalRec);
        }
        resObj.success = true;
        resObj.newApptAnimalRec = newApptAnimalRec;
        return resObj;
    }
    catch (error) {
        console.error('createAppointmentAnimal error', error);
        resObj.success = false;
        resObj.error = error;
        return resObj;
    }
    // return newApptAnimalRec;
}

// TODO - UPDATE EDIT APPT FORM,
//  ADD APPT NOTE FORM TO USE NEW RETURN OBJ
export async function createAppointmentNote(apptId, note, showLogs) {
    if (showLogs) {
        console.log('createAppointmentNote', apptId, note);
    }
    // create AppointmentNote
    const resObj = {};
    const routerPathUUID = uuidv4();
    const apptNoteRouterPath = generateSaltedRouterPath(`Appointment-${apptId}-${note}-${routerPathUUID}`);
    try {
        const newApptNote = await DataStore.save(new AppointmentNote({
            appointmentID: apptId,
            note: note,
            routerPath: apptNoteRouterPath,
        }))
        if (showLogs) {
            console.log('newApptNote', newApptNote);
        }
        resObj.success = true;
        resObj.newApptNote = newApptNote;
        return resObj;
    }
    catch (error) {
        console.error('createAppointmentNote error', error);
        resObj.success = false;
        resObj.error = error;
        return resObj;
    }
    // return newApptNote;
}

// TODO - UPDATE EDIT APPT FORM,
//  ADD APPT TASK FORM TO USE NEW RETURN OBJ
export async function createAppointmentShoeTask(apptId, animalShoeTaskFormVal, showLogs) {
    // if (showLogs) {
    //     console.log('createAppointmentShoeTask', apptId, animalShoeTaskFormVal);
    // }
    const routerPathUUID = uuidv4();
    // const recRouterPath = modelRouterPathAttrFactory(`ShoeTask-${apptId}-${animalShoeTaskFormVal.animalId}`);
    const recRouterPath = generateSaltedRouterPath(`ShoeTask-${apptId}-${animalShoeTaskFormVal.animalId}-${routerPathUUID}`);
    // create AppointmentTasks - ShoeTasks
    const resObj = {};
    try {
        const newShoeTaskRec = await DataStore.save(new ShoeTask({
            appointmentID: apptId,
            animalID: animalShoeTaskFormVal.animalID,
            duration: animalShoeTaskFormVal.duration,
            amount: animalShoeTaskFormVal.amount,
            addedTime: animalShoeTaskFormVal.addedTime,
            addedCost: animalShoeTaskFormVal.addedCost,
            // taskNote: animalShoeTaskFormVal.taskNote,
            routerPath: recRouterPath,
        }))
        if (showLogs) {
            console.log('newShoeTaskRec', newShoeTaskRec);
        }
        resObj.success = true;
        resObj.newShoeTaskRec = newShoeTaskRec;
        return resObj;
    }
    catch (error) {
        console.error('createAppointmentShoeTask error', error);
        resObj.success = false;
        resObj.error = error;
        return resObj;
    }
    // return newShoeTaskRec;
}

// TODO - UPDATE EDIT APPT FORM,
//  ADD APPT TASK FORM TO USE NEW RETURN OBJ
export async function createAppointmentTrimTask(apptId, animalTrimTaskFormVal, showLogs) {
    // if (showLogs) {
    //     console.log('createAppointmentTrimTask', apptId, animalTrimTaskFormVal);
    // }
    const routerPathUUID = uuidv4();
    // const recRouterPath = modelRouterPathAttrFactory(`TrimTask-${apptId}-${animalTrimTaskFormVal.animalId}`);
    const recRouterPath = generateSaltedRouterPath(`TrimTask-${apptId}-${animalTrimTaskFormVal.animalId}-${routerPathUUID}`);
    // create AppointmentTasks - TrimTasks
    const resObj = {};
    try {
    const newTrimTaskRec = await DataStore.save(new TrimTask({
        appointmentID: apptId,
        animalID: animalTrimTaskFormVal.animalID,
        duration: animalTrimTaskFormVal.duration,
        amount: animalTrimTaskFormVal.amount,
        addedTime: animalTrimTaskFormVal.addedTime,
        addedCost: animalTrimTaskFormVal.addedCost,
        // taskNote: animalTrimTaskFormVal.taskNote,
        routerPath: recRouterPath,
    }))
        if (showLogs) {
            console.log('newTrimTaskRec', newTrimTaskRec);
        }
        resObj.success = true;
        resObj.newTrimTaskRec = newTrimTaskRec;
        return resObj;
    }
    catch (error) {
        console.error('createAppointmentTrimTask error', error);
        resObj.success = false;
        resObj.error = error;
        return resObj;
    }
    // return newTrimTaskRec;
}

// TODO - UPDATE EDIT APPT FORM,
//  ADD APPT TASK FORM TO USE NEW RETURN OBJ
export async function createApptTasks(apptId, tasksForAnimals, showLogs) {
    // if (showLogs) {
    //     console.log('createApptTasks', apptId, tasksForAnimals);
    // }
    const newTaskRecs = {
        shoeTasks: [],
        trimTasks: [],
    };
    // create AppointmentTasks - ShoeTasks, TrimTasks
    const resObj = {
        apptId,
        shoeTasksResObjs: [],
        trimTasksResObjs: [],
    };
    for (const animalTasksValue of tasksForAnimals) {
        const eachResObj = {
            apptId,
            animalId: animalTasksValue.animalId,
        };
        // try {
            if (animalTasksValue.taskName === 'shoe') {
                eachResObj.taskName = 'shoe';
                try {
                    const newShoeTaskRecRes = await createAppointmentShoeTask(apptId, animalTasksValue);
                    // if (showLogs) {
                    //     console.log('newShoeTaskRec', newShoeTaskRec);
                    // }
                    newTaskRecs.shoeTasks.push(newShoeTaskRecRes?.newShoeTaskRec);
                    eachResObj.success = newShoeTaskRecRes?.success;
                    eachResObj.newShoeTaskRec = newShoeTaskRecRes?.newShoeTaskRec;
                    resObj.shoeTasksResObjs.push(eachResObj);
                }
                catch (error) {
                    console.error('createApptTasks CREATE SHOE TASK error', error);
                    eachResObj.success = false;
                    eachResObj.error = error;
                    // return resObj;
                    resObj.shoeTasksResObjs.push(eachResObj);
                }
            // }
            } else if (animalTasksValue.taskName === 'trim') {
                eachResObj.taskName = 'trim';
                try {
                    const newTrimTaskRecRes = await createAppointmentTrimTask(apptId, animalTasksValue);
                    // if (showLogs) {
                    //     console.log('newTrimTaskRec', newTrimTaskRec);
                    // }
                    newTaskRecs.trimTasks.push(newTrimTaskRecRes?.newTrimTaskRec);
                    eachResObj.success = newTrimTaskRecRes?.success;
                    eachResObj.newTrimTaskRec = newTrimTaskRecRes?.newTrimTaskRec;
                    resObj.trimTasksResObjs.push(eachResObj);
                }
                catch (error) {
                    console.error('createApptTasks CREATE TRIM TASK error', error);
                    eachResObj.success = false;
                    eachResObj.error = error;
                    // return resObj;
                    resObj.trimTasksResObjs.push(eachResObj);
                }
            }
    }
    resObj.newTaskRecs = newTaskRecs;
    return resObj;
    // return newTaskRecs;
}
