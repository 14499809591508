import React, {useState, useEffect} from 'react';
import AnimalNotesContext from "../../contexts/AnimalNotesContext";
import { DataStore, SortDirection } from "aws-amplify";
import {AnimalNote} from '../../../models';

const AnimalNotesProvider = ({ children, animalId, showLogs, resetProviderState }) => {
    const [animalNotesRecs, setAnimalNotesRecs] = useState([]);
    const [animalNotesShowLogs, setAnimalNotesShowLogs] = useState(false);
    const [animalNotesAnimalRecId, setAnimalNotesAnimalRecId] = useState(null);

    // useEffect( reset data, based on resetProviderState
    useEffect(() => {
        if (resetProviderState) {
            setAnimalNotesRecs([]);
            setAnimalNotesAnimalRecId(null);
        }
    }, [resetProviderState]);

    // useEffect( setAnimalNotesShowLogs(showLogs);
    useEffect(() => {
        if (showLogs) {
            setAnimalNotesShowLogs(showLogs);
        } else {
            setAnimalNotesShowLogs(false);
        }
    }, [showLogs]);

    // useEffect( console.log animalId, animalNotesRecs, animalNotesAnimalRecId
    // useEffect(() => {
    //     if (animalNotesShowLogs) {
    //         console.log('AnimalNotesProvider animalNotesAnimalRecId:', animalNotesAnimalRecId);
    //         console.log('AnimalNotesProvider animalId:', animalId);
    //         console.log('AnimalNotesProvider animalNotesRecs:', animalNotesRecs);
    //     }
    // }, [animalNotesShowLogs, animalNotesAnimalRecId, animalId, animalNotesRecs]);

    // useEffect(() => {
    //     if (animalId) {
    //         const subscription = DataStore.observeQuery(AnimalNote,
    //             (c) => c.animalID.eq(animalId),
    //             {
    //                 sort: s => s.createdAt(SortDirection.ASCENDING)
    //             })
    //             .subscribe(async msg => {
    //                 const {items, isSynced} = msg;
    //                 if (animalNotesShowLogs) {
    //                     // console.log(`AnimalNotesProvider c [msg] item count: ${items.length}, isSynced: ${isSynced}`);
    //                     // console.log('AnimalNotesProvider c [Snapshot] items', items);
    //                 }
    //                 if (isSynced) {
    //                     setAnimalNotesRecs(items);
    //                 }
    //             });
    //         // Remember to unsubscribe when the component unmounts
    //         return () => subscription.unsubscribe();
    //     }
    // }, [animalId, animalNotesShowLogs]); // Dependency array now includes regionId

    useEffect(() => {
        if (animalId) {
            const subscription = DataStore.observeQuery(AnimalNote,
                (c) => c.and(c => [
                    c.animalID.eq(animalId),
                    c.isDeleted.ne(true)  // Filter out records where isDeleted is true
                ]),
                {
                    sort: s => s.createdAt(SortDirection.ASCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    if (animalNotesShowLogs) {
                        // console.log(`AnimalNotesProvider c [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                        // console.log('AnimalNotesProvider c [Snapshot] items', items);
                    }
                    if (isSynced) {
                        setAnimalNotesRecs(items);
                    }
                });
            // Remember to unsubscribe when the component unmounts
            return () => subscription.unsubscribe();
        }
    }, [animalId, animalNotesShowLogs]); // Dependency array now includes regionId

    // useEffect(() => {
    //     if (animalNotesAnimalRecId) {
    //         const subscription = DataStore.observeQuery(AnimalNote,
    //             (an) => an.animalID.eq(animalNotesAnimalRecId),
    //             {
    //                 sort: s => s.createdAt(SortDirection.ASCENDING)
    //             })
    //             .subscribe(async msg => {
    //                 const {items, isSynced} = msg;
    //                 if (animalNotesShowLogs) {
    //                     console.log(`AnimalNotesProvider c [msg] item count: ${items.length}, isSynced: ${isSynced}`);
    //                     console.log('AnimalNotesProvider c [Snapshot] items', items);
    //                 }
    //                 if (isSynced) {
    //                     setAnimalNotesRecs(items);
    //                 }
    //             });
    //         // Remember to unsubscribe when the component unmounts
    //         return () => subscription.unsubscribe();
    //     }
    // }, [animalNotesAnimalRecId, animalNotesShowLogs]);

    useEffect(() => {
        if (animalNotesAnimalRecId) {
            const subscription = DataStore.observeQuery(AnimalNote,
                (an) => an.and(an => [
                    an.animalID.eq(animalNotesAnimalRecId),
                    an.isDeleted.ne(true)
                ]),
                {
                    sort: s => s.createdAt(SortDirection.ASCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    if (animalNotesShowLogs) {
                        console.log(`AnimalNotesProvider c [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                        console.log('AnimalNotesProvider c [Snapshot] items', items);
                    }
                    if (isSynced) {
                        setAnimalNotesRecs(items);
                    }
                });
            // Remember to unsubscribe when the component unmounts
            return () => subscription.unsubscribe();
        }
    }, [animalNotesAnimalRecId, animalNotesShowLogs]);

    return (
        <AnimalNotesContext.Provider value={{ animalNotesRecs, setAnimalNotesAnimalRecId }}>
            {children}
        </AnimalNotesContext.Provider>
    );
}

export default AnimalNotesProvider;

