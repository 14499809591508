import React, {useState, useEffect} from "react";
import DevStateProvider from "../../../data/providers/DevStateProvider";
import {RequireAuth} from "../../auth/RequireAuth";
import AmpUserAnimalsScreen from "../../../screens/animals/AmpUserAnimalsScreen";
import AmpUserAnimalsProvider from "../../../data/providers/animals/AmpUserAnimalsProvider";
import AmpUserClientsDataProvider from "../../../data/providers/people/AmpUserClientsDataProvider";
import AnimalNotesProvider from "../../../data/providers/notes/AnimalNotesProvider";
import {RequireSub} from "../../auth/RequireSub";
import AnimalShoePackagesProvider from "../../../data/providers/animals/AnimalShoePackagesProvider";
import AnimalShoeTasksProvider from "../../../data/providers/animals/AnimalShoeTasksProvider";
import {ApptByTaskProvider} from "../../../data/providers/appts/ApptByTaskProvider";
import AnimalTrimTasksProvider from "../../../data/providers/animals/AnimalTrimTasksProvider";

export default function WrappedAmpUserAnimals({routerPaths, ampUser, showLogs, resetProvidersStates}) {
    const [ampUserRec, setAmpUserRec] = useState(null);

    // useEffect( setAmpUserRec(ampUser);
    useEffect(() => {
        if (ampUser) {
            setAmpUserRec(ampUser);
        }
    }, [ampUser]);

    // useEffect( console.log( ampUserRec
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('ampUserRec', ampUserRec);
    //     }
    // }, [ampUserRec, showLogs]);

    return (
        <RequireAuth>
            <DevStateProvider>
                {ampUserRec ? (
                    <RequireSub ampUser={ampUserRec}>
                        <ApptByTaskProvider showLogs={showLogs} resetProviderState={resetProvidersStates}>
                            <AnimalShoeTasksProvider animalId={null} showLogs={showLogs}
                                                     resetProviderState={resetProvidersStates}>
                                <AnimalShoePackagesProvider animalId={null} showLogs={showLogs}
                                                            resetProviderState={resetProvidersStates}>
                                    <AnimalTrimTasksProvider animalId={null} showLogs={showLogs}
                                                             resetProviderState={resetProvidersStates}>
                                        <AnimalNotesProvider animalId={null} showLogs={showLogs}
                                                             resetProviderState={resetProvidersStates}>
                                            <AmpUserClientsDataProvider ampUserId={ampUserRec.id} showLogs={showLogs}
                                                                        resetProviderState={resetProvidersStates}>
                                                <AmpUserAnimalsProvider ampUserId={ampUserRec.id} showLogs={showLogs}
                                                                        resetProviderState={resetProvidersStates}>
                                                    <AmpUserAnimalsScreen
                                                        routerPaths={routerPaths}
                                                        showLogs={showLogs}
                                                        ampUser={ampUserRec}
                                                    />
                                                </AmpUserAnimalsProvider>
                                            </AmpUserClientsDataProvider>
                                        </AnimalNotesProvider>
                                    </AnimalTrimTasksProvider>
                                </AnimalShoePackagesProvider>
                            </AnimalShoeTasksProvider>
                        </ApptByTaskProvider>
                    </RequireSub>
                ) : (
                    <div><h2>Loading Animal Data...</h2></div>
                )}
            </DevStateProvider>
        </RequireAuth>
    )
}
