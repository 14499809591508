import React, {useEffect, useState, useContext} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AnimalInfoCard from "../cards/AnimalInfoCard";
import BaseNavigateButton from "../links/BaseNavigateButton";
import Button from "@mui/material/Button";
import {animalNoteFormObj} from "../../utils/formObjects";
import StyledTextField from "../text/StyledTextField";
import CircularProgress from "@mui/material/CircularProgress";
import {createAnimalNoteRec} from "../../data/create/animal/createAnimalRecs";
import AnimalShoePackagesContext from "../../data/contexts/AnimalShoePackagesContext";
import ShoePackagesAccordion from "../accordions/ShoePackagesAccordion";
import AnimalNotesContext from "../../data/contexts/AnimalNotesContext";
import NotesAccordion from "../accordions/NotesAccordion";
import AnimalShoeTasksContext from "../../data/contexts/AnimalShoeTasksContext";
import ShoeTasksAccordion from "../accordions/ShoeTasksAccordion";
import AnimalTrimTasksContext from "../../data/contexts/AnimalTrimTasksContext";
import TrimTasksAccordion from "../accordions/TrimTasksAccordion";


export default function AnimalDetailsDialog({
                                                animalRecProp,
                                                clientRecProp,
                                                openProp,
                                                handleCloseProp,
                                                ampUserProp,
                                                showLogs,
                                                ...props
                                            }) {
    const [open, setOpen] = useState(false);
    const [ampUser, setAmpUser] = useState(null);
    const [animalRec, setAnimalRec] = useState(null);
    const [clientRec, setClientRec] = useState(null);
    const [activeStep, setActiveStep] = useState(0);

    const [animalNotes, setAnimalNotes] = useState(null);
    const [animalNotesLoading, setAnimalNotesLoading] = useState(false);
    const [animalNotesError, setAnimalNotesError] = useState(null);

    const [animalCurrentShoePackage, setAnimalCurrentShoePackage] = useState(null);
    const [animalShoePackages, setAnimalShoePackages] = useState(null);
    const [animalShoePackagesLoading, setAnimalShoePackagesLoading] = useState(false);
    const [animalShoePackagesError, setAnimalShoePackagesError] = useState(null);

    const [animalShoeTasks, setAnimalShoeTasks] = useState(null);
    const [animalShoeTasksLoading, setAnimalShoeTasksLoading] = useState(false);
    const [animalShoeTasksError, setAnimalShoeTasksError] = useState(null);

    const [animalTrimTasks, setAnimalTrimTasks] = useState(null);
    const [animalTrimTasksLoading, setAnimalTrimTasksLoading] = useState(false);
    const [animalTrimTasksError, setAnimalTrimTasksError] = useState(null);

    const [animalAppts, setAnimalAppts] = useState(null);
    const [animalApptsLoading, setAnimalApptsLoading] = useState(false);
    const [animalApptsError, setAnimalApptsError] = useState(null);

    const [animalNextAppt, setAnimalNextAppt] = useState(null);
    const [animalNextApptLoading, setAnimalNextApptLoading] = useState(false);
    const [animalNextApptError, setAnimalNextApptError] = useState(null);

    const [isCreatingNote, setIsCreatingNote] = useState(false);
    const [createError, setCreateError] = useState(null);
    const [animalNoteFormValues, setAnimalNoteFormValues] = useState(animalNoteFormObj);
    const [createAnimalNoteResObj, setCreateAnimalNoteResObj] = useState(null);

    const {animalShoePackagesRecs, setAnimalDetailsAnimalId: setAnimalIdShoePackagesContext } = useContext(AnimalShoePackagesContext);
    const {animalNotesRecs, setAnimalNotesAnimalRecId } = useContext(AnimalNotesContext);
    const {animalShoeTasksRecs, setShoeTasksAnimalRecId } = useContext(AnimalShoeTasksContext);
    const {animalTrimTasksRecs, setTrimTasksAnimalRecId } = useContext(AnimalTrimTasksContext);

    // useEffect( if (openProp) { setOpen(true);
    useEffect(() => {
        if (openProp) {
            setOpen(true);
        }
    }, [openProp]);

    // useEffect( if (ampUserProp) { setAmpUser(ampUserProp);
    useEffect(() => {
        if (ampUserProp) {
            setAmpUser(ampUserProp);
        }
    }, [ampUserProp]);

    // useEffect( setAnimalRec(animalRecProp);
    useEffect(() => {
        if (animalRecProp) {
            setAnimalRec(animalRecProp);
            setAnimalShoePackagesLoading(true);
            setAnimalNotesLoading(true);
            setAnimalShoeTasksLoading(true);
            setAnimalTrimTasksLoading(true);
            setAnimalIdShoePackagesContext(animalRecProp.id);
            setAnimalNotesAnimalRecId(animalRecProp.id);
            setShoeTasksAnimalRecId(animalRecProp.id);
            setTrimTasksAnimalRecId(animalRecProp.id);
        }
    }, [animalRecProp]);

    useEffect(() => {
        if (animalShoePackagesRecs) {
            setAnimalShoePackages(animalShoePackagesRecs);
            setAnimalShoePackagesLoading(false);
        }
    }, [animalShoePackagesRecs]);

    useEffect(() => {
        if (animalNotesRecs) {
            setAnimalNotes(animalNotesRecs);
            setAnimalNotesLoading(false);
        }
    }, [animalNotesRecs]);

    useEffect(() => {
        if (animalShoeTasksRecs) {
            setAnimalShoeTasks(animalShoeTasksRecs);
            setAnimalShoeTasksLoading(false);
        }
    }, [animalShoeTasksRecs]);

    useEffect(() => {
        if (animalTrimTasksRecs) {
            setAnimalTrimTasks(animalTrimTasksRecs);
            setAnimalTrimTasksLoading(false);
        }
    }, [animalTrimTasksRecs]);

    // useEffect( if (animalRec && activeStep === 0) => setActiveStep(1);
    useEffect(() => {
        if (animalRec && activeStep === 0) {
            setActiveStep(1);
        }
    }, [animalRec, activeStep]);

    // useEffect( setClientRec(clientRecProp);
    useEffect(() => {
        if (clientRecProp) {
            setClientRec(clientRecProp);
        }
    }, [clientRecProp]);

    const handleClose = () => {
        if (showLogs) console.log('### AnimalDetailsDialog handleClose');
        if (handleCloseProp) {
            setAnimalIdShoePackagesContext(null);
            setAnimalNotesAnimalRecId(null);
            setShoeTasksAnimalRecId(null);
            handleCloseProp();
        }
    }

    const handleConfirmCreateAnimalNote = async () => {
        setIsCreatingNote(true);
        const createAnimalNoteRes = await createAnimalNoteRec(animalNoteFormValues.note, animalRec.id, ampUser.id, showLogs);
        setCreateAnimalNoteResObj(createAnimalNoteRes);
        if (createAnimalNoteRes && !createAnimalNoteRes.success) {
            if (createAnimalNoteRes?.error) {
                setCreateError(createAnimalNoteRes.error);
            } else {
                setCreateError('Error Creating Note');
            }
        }
        setIsCreatingNote(false);
        setActiveStep(4);
    }

    // useEffect( console.log( createAnimalNoteResObj
    useEffect(() => {
        if (showLogs) {
            console.log('### AnimalDetailsDialog createAnimalNoteResObj', createAnimalNoteResObj);
        }
    }, [createAnimalNoteResObj]);

    const handleNoteTextFieldChange = (event) => {
        setAnimalNoteFormValues({...animalNoteFormValues, note: event.target.value})
    };

    const handleShowCreateNoteForm = () => {
        setActiveStep(2);
    };

    const handleShowCreateNoteConfirm = () => {
        setActiveStep(3);
    };

    const handleCreateNoteBack = () => {
        setActiveStep(2);
    };

    const handleCreateNoteCancel = () => {
        setActiveStep(1);
    };

    const handleCreateNoteDone = () => {
        setActiveStep(1);
    };

    const renderLoadingStep = () => (
        <>
            <DialogTitle id="animal-details-dialog-title">Loading...</DialogTitle>
            <DialogContent>
                <DialogContentText id="animal-details-dialog-description">
                    Loading Animal Details...
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </>
    );

    const renderDisplayAnimalDetails = () => (
        <>
            <DialogTitle id="animal-details-dialog-title">Animal Details</DialogTitle>
            <DialogContent>
                    <AnimalInfoCard
                        animalRec={animalRec}
                        clientRec={clientRec}
                        showLogs={showLogs}
                        showAllAttrs={'false'}
                        hideEditButton={true}
                    />
                {!animalShoePackagesLoading && animalShoePackages ? (
                    <ShoePackagesAccordion
                        shoePackages={animalShoePackages}
                        headerText={`Shoe Packages: ${animalShoePackages?.length || 0}`}
                    />
                ) : null}
                {!animalShoeTasksLoading && animalShoeTasks ? (
                    <ShoeTasksAccordion
                        shoeTasks={animalShoeTasks}
                        headerText={`Shoeings: ${animalShoeTasks?.length || 0}`}
                        showLogs={showLogs}
                    />
                ) : null}
                {!animalTrimTasksLoading && animalTrimTasks ? (
                    <TrimTasksAccordion
                        trimTasks={animalTrimTasks}
                        headerText={`Trims: ${animalTrimTasks?.length || 0}`}
                        showLogs={showLogs}
                    />
                ) : null}
                {!animalNotesLoading && animalNotes ? (
                    <NotesAccordion
                        notes={animalNotes}
                        headerText={`Notes: ${animalNotes?.length || 0}`}
                        noteType={'animal'}
                        apptRecProp={null}
                        showLogs={showLogs}
                        animalRecProp={animalRec}
                        animalId={animalRec.id}
                    />
                ) : null}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleShowCreateNoteForm}>Create Note</Button>
                <Button onClick={handleClose}>Close</Button>
                <BaseNavigateButton
                    label="Edit Animal"
                    navTarget={`/edit-animal/${animalRec?.routerPath}`}
                    navState={{animalRec: animalRec, clientRec: clientRec}}
                />
            </DialogActions>
        </>
    )

    const renderCreateAnimalNote = () => (
        <>
            <DialogTitle id="animal-details-dialog-title-create-animal-note">Create Animal Note</DialogTitle>
            <DialogContent>
                <DialogContentText id="animal-details-dialog-description-create-animal-notee">
                    Animal Note Form
                </DialogContentText>
                <div>
                    <StyledTextField
                        id="create-animal-note-note-text-field"
                        label="Note"
                        value={animalNoteFormValues?.note || ''}
                        onChange={handleNoteTextFieldChange}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleShowCreateNoteConfirm}
                    disabled={!animalNoteFormValues?.note || animalNoteFormValues?.note === ''}
                >
                    Create Note
                </Button>
                <Button onClick={handleCreateNoteCancel}>Cancel Note</Button>
                <BaseNavigateButton
                    label="Edit Animal"
                    navTarget={`/edit-animal/${animalRec?.routerPath}`}
                    navState={{animalRec: animalRec, clientRec: clientRec}}
                />
            </DialogActions>
        </>
    )

    const renderConfirmCreateAnimalNote = () => (
        <>
            <DialogTitle id="animal-details-dialog-title-confirm-create-animal-note">Confirm Create Animal
                Note</DialogTitle>
            <DialogContent>
                <DialogContentText id="animal-details-dialog-description-confirm-create-animal-note">
                    Confirm Create Animal Note
                </DialogContentText>
                <div>
                    <StyledTextField
                        id="confirm-create-animal-note-note-text-field"
                        label="Note"
                        readOnly
                        value={animalNoteFormValues?.note || ''}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={async () => await handleConfirmCreateAnimalNote()} color="primary"
                        disabled={isCreatingNote}>
                    {isCreatingNote ? <CircularProgress size={24}/> : 'Create Note'}
                </Button>
                <Button
                    onClick={handleCreateNoteBack}
                >
                    Back
                </Button>
                <Button
                    onClick={handleCreateNoteCancel}
                >
                    Cancel Note
                </Button>
            </DialogActions>
        </>
    );

    const renderCreateNoteDone = () => (
        <>
            <DialogTitle id="animal-details-dialog-title-create-note-done">Note Created</DialogTitle>
            <DialogContent>
                {createError ? (
                    <DialogContentText id="animal-details-dialog-description-create-note-done-error">
                        {createError || 'Error Creating Note'}
                    </DialogContentText>
                ) : null}
                {createAnimalNoteResObj && createAnimalNoteResObj.success ? (
                    <DialogContentText id="animal-details-dialog-description-create-note-done">
                        Note Created
                    </DialogContentText>
                ) : null}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCreateNoteDone}>Done</Button>
            </DialogActions>
        </>
    );

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="animal-details-dialog-title"
            aria-describedby="animal-details-dialog-description"
        >
            {activeStep === 0 ? renderLoadingStep() :
                activeStep === 1 ? renderDisplayAnimalDetails() :
                    activeStep === 2 ? renderCreateAnimalNote() :
                        activeStep === 3 ? renderConfirmCreateAnimalNote() :
                            activeStep === 4 ? renderCreateNoteDone() :
                                renderLoadingStep()
            }
        </Dialog>
    );
}
