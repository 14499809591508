import React, { useEffect, useState, useContext } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import BaseNavigateButton from "../../links/BaseNavigateButton";
import "../../../styles/CreateSub.css";
import { userSubDataObj } from "../../../utils/formObjects";
import AmpUserContext from "../../../data/contexts/AmpUserContext";
import StyledCardContentTypography from "../../text/StyledCardContentTypography";
import StyledListBox from "../../boxes/StyledListBox";
import StyledListCard from "../../cards/StyledListCard";
import { useResponsiveViewport } from "../../../data/providers/ResponsiveViewportProvider";
import { useConsoleLog } from "../../hooks/logging/console";

import { Typography } from "@mui/material";

export default function CreateUserSubForm(props) {
    const [ampUser, setAmpUser] = useState({});
    const [ampUserSubscriber, setAmpUserSubscriber] = useState(null);
    const [showLogs, setShowLogs] = useState(false);
    const [routerPaths, setRouterPaths] = useState(null);

    const [subLength, setSubLength] = useState('Quarter');
    const [locationState, setLocationState] = useState(null);
    const [userSubData, setUserSubData] = useState(userSubDataObj);

    const {ampUserRec} = useContext(AmpUserContext);
    const { isSmallScreen, isLargeScreen } = useResponsiveViewport();

    // let navigate = useNavigate();

    // useEffect( setShowLogs(
    useEffect(() => {
        if (props?.showLogs){
            setShowLogs(props.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [props]);

    // useEffect setAmpUser(ampUserRec); setAmpUser(props.ampUser);
    useEffect(() => {
        if (ampUserRec) {
            setAmpUser(ampUserRec);
        }
        else if (props?.ampUser) {
            setAmpUser(props.ampUser);
        }
    }, [ampUserRec, props.ampUser]);

    // useEffect setAmpUserSubscriber(props.ampUserSubscriber);
    useEffect(() => {
        if (props?.ampUserSubscriber) {
            setAmpUserSubscriber(props.ampUserSubscriber);
        }
    }, [props.ampUserSubscriber]);

    // useEffect setRouterPaths(props.routerPaths);
    useEffect(() => {
        if (props?.routerPaths) {
            setRouterPaths(props.routerPaths);
        }
    }, [props.routerPaths]);

    // useEffect( setLocationState(props.locationState); setSubLength(props.locationState.subLength);
    useEffect(() => {
        if (props?.locationState) {
            setLocationState(props.locationState);
            if (props.locationState?.subLength) {
                setSubLength(props.locationState.subLength);
            }
        }
    }, [props.locationState]);

    useConsoleLog(showLogs, [{label: 'CreateUserSubForm locationState', value: locationState}]);

    useConsoleLog(showLogs, [{label: 'CreateUserSubForm subLength', value: subLength}]);

    // handleSelectChange setSubLength(event.target.value);
    const handleSelectChange = (event) => {
        setSubLength(event.target.value);
    };

    // TODO - START HERE, MAKE SURE A 1 MONTH SUB WORKS
    return (
        <div>
            <StyledListBox
                backgroundColor={'transparent'}
                style={{
                    marginTop: '0.5rem',
                }}
            >
            <StyledCardContentTypography
                text={'Subscription Length'}
                />
            <StyledListCard
                width={isLargeScreen ? '90%' : '100%'}
                sx={{
                    marginBottom: '0.5rem',
                }}
            >
                <StyledCardContentTypography
                    text={`${userSubData.Year.subLengthMonths} months, beginning today`}
                    />
                <StyledCardContentTypography
                    text={`$${userSubData.Year.subPrice}`}
                />
            </StyledListCard>
            <StyledListCard
                width={isLargeScreen ? '90%' : '100%'}
                sx={{
                    marginBottom: '0.5rem',
                }}
            >
                <StyledCardContentTypography
                    text={`${userSubData.Quarter.subLengthMonths} months, beginning today`}
                />
                <StyledCardContentTypography
                    text={`$${userSubData.Quarter.subPrice}`}
                />
            </StyledListCard>
            <StyledListCard
                width={isLargeScreen ? '90%' : '100%'}
                sx={{
                    marginBottom: '1.5rem',
                }}
            >
                <StyledCardContentTypography
                    text={`${userSubData.Month.subLengthMonths} months, beginning today`}
                />
                <StyledCardContentTypography
                    text={`$${userSubData.Month.subPrice}`}
                />
            </StyledListCard>
            <div style={{
                backgroundColor: 'white',
                paddingTop: '1rem',
                paddingBottom: '1rem',
            }}
            >
                <FormControl
                    row="true"
                    component="fieldset"
                    sx={{
                        fontSize: {
                            xs: '1.2rem',   // For extra-small screens
                            sm: '1.5rem', // For small screens
                            md: '1.7rem', // For medium screens
                            lg: '1.8rem', // For large screens
                            xl: '2rem', // For extra-large screens
                        },
                        width: {
                            xs: 200, // For extra-small screens
                            sm: 220, // For small screens
                            md: 280, // For medium screens
                            lg: 360, // For large screens
                            xl: 400, // For extra-large screens
                        },
                        // backgroundColor: 'transparent',
                    }}
                >
                    <InputLabel
                        id="demo-simple-select-label"
                        sx={{
                            fontSize: {
                                xs: '1.2rem',   // 0px+
                                sm: '1.4rem', // 600px+
                                md: '1.5rem', // 900+
                                lg: '1.6rem', // 1200+
                                xl: '1.8rem', // 1536+
                            }
                        }}
                    >
                        Subscription Length
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={subLength}
                        label="Subscription Length"
                        onChange={handleSelectChange}
                        sx={{
                            fontSize: {
                                xs: '1.2rem',   // 0px+
                                sm: '1.4rem', // 600px+
                                md: '1.5rem', // 900+
                                lg: '1.6rem', // 1200+
                                xl: '1.8rem', // 1536+
                            }
                        }}
                    >
                        <MenuItem
                            value={userSubData.Month.subText}
                            // sx={{
                            //     fontSize: {
                            //         xs: '1.2rem',   // 0px+
                            //         sm: '1.4rem', // 600px+
                            //         md: '2rem', // 900+
                            //         lg: '1.6rem', // 1200+
                            //         xl: '1.8rem', // 1536+
                            //     }
                            // }}
                        >
                            {`${userSubData.Month.descriptionText} - $${userSubData.Month.subPrice}`}
                        </MenuItem>
                        <MenuItem
                            value={userSubData.Quarter.subText}
                            // sx={{
                            //     fontSize: {
                            //         xs: '1.2rem',   // 0px+
                            //         sm: '1.4rem', // 600px+
                            //         md: '1.5rem', // 900+
                            //         lg: '1.6rem', // 1200+
                            //         xl: '1.8rem', // 1536+
                            //     }
                            // }}
                        >
                            {`${userSubData.Quarter.descriptionText} - $${userSubData.Quarter.subPrice}`}
                        </MenuItem>
                        <MenuItem
                            value={userSubData.Year.subText}
                            // sx={{
                            //     fontSize: {
                            //         xs: '1.2rem',   // 0px+
                            //         sm: '1.4rem', // 600px+
                            //         md: '1.5rem', // 900+
                            //         lg: '1.6rem', // 1200+
                            //         xl: '1.8rem', // 1536+
                            //     }
                            // }}
                        >
                            {`${userSubData.Year.descriptionText} - $${userSubData.Year.subPrice}`}
                        </MenuItem>
                    </Select>
                </FormControl>
            </div>
            <Divider/>
            <BaseNavigateButton
                label="Confirm Subscription"
                navState={{
                    ampUser,
                    subLength
                }}
                navTarget={'/confirm-user-sub'}
            >
            </BaseNavigateButton>
            <Divider/>
            </StyledListBox>
        </div>
    )
}
