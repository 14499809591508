import React from 'react';
import Card from '@mui/material/Card';

const StyledListCard = ({ children, ...props }) => {
    return (
        <Card
            sx={{
                width: props?.width || '100%',
                maxWidth: {
                    xs: 360,  // 0px max-width for xs breakpoint and up
                    sm: 480,  // 600px max-width for sm breakpoint and up
                    md: 600,  // 900px max-width for md breakpoint and up
                    lg: 960,  // 1200px max-width for lg breakpoint and up
                    xl: 1280, // 1536px max-width for xl breakpoint and up
                },
                // Add default styles specific to the Card component
                // For example, setting the background color, padding, etc.
                bgcolor: 'background.paper', // Example background color
                // boxShadow: 3, // Example shadow
                // borderRadius: 2, // Example border radius
                // Add more default styles if necessary
                margin: props?.margin || ['0', '1rem', '1rem', '1rem'],
                // Overwrite with props.sx to allow for custom styles
                ...props.sx,
            }}
            {...props} // Spread the rest of the props to the Card component
        >
            {children}
        </Card>
    );
};

export default StyledListCard;
