
export function checkForTaskFormAttrsDiff(origObj, editedObj) {
    const updatedFormTasksObj = {};

    // Iterate through the keys of the original object
    Object.keys(origObj).forEach(key => {
        // Compare values; for simplicity, this does a shallow comparison
        // For arrays or objects, you might need a deep comparison depending on your requirements
        if (origObj[key] !== editedObj[key]) {
            try {
                updatedFormTasksObj[key] = editedObj[key];
                console.log('!!! checkForTaskFormAttrsDiff updated key, val', key, editedObj[key]);
            }
            catch (error) {
                console.error('!!! checkForTaskFormAttrsDiff error', error);
            }
        }
    });
    return updatedFormTasksObj;
}

// export function addUpdatedTrimTaskFormToTaskFormsDiffObj(taskType, formUniqueId, taskFormsDiffObj, setTaskFormsDiffObj, editedTrimTaskFormVals, origTrimTaskFormVals, setUpdatedTrimTaskFormUniqueId, setUpdatedNewTrimTaskFormUniqueId) {
export function addUpdatedTrimTaskFormToTaskFormsDiffObj(formUniqueId, taskFormsDiffObj, setTaskFormsDiffObj, editedTrimTaskFormVals, origTrimTaskFormVals, setUpdatedTrimTaskFormUniqueId, setUpdatedNewTrimTaskFormUniqueId) {
    // TODO - VERIFY WE'RE REMOVING UPDATE IDS ON EVEN FOR UPDATED FORMS WHEN NAMES ARE REMOVED
    // if (taskType === 'trim') {
        // TODO - CHECK IF THE UPDATED FORM IS A NEW ONE IN CREATE ARR,
        //  UPDATE IT IN THAT ARR AND DON'T ADD IT TO UPDATE ARR
        const currentDiffObjCreateTrimForms = taskFormsDiffObj?.trim?.create || [];
        // const currentCreateTrimFormsCopy = [...currentCreateTrimForms];
        const copyOfDiffObjCurrentCreateTrimForms = [...currentDiffObjCreateTrimForms];
        const indexOfDiffObjCreateTrimForm = copyOfDiffObjCurrentCreateTrimForms.findIndex((form) => form.formUniqueId === formUniqueId);
        if (indexOfDiffObjCreateTrimForm > -1) {
            // TODO - !!! FR FR START HERE,
            //  MAY NEED A NEW FUNC TO UPDATE FORMS IN CREATE ARR, OR CAN DO IT HERE,
            //  UPDATE addCreateUniqueIdToTaskFormsDiffOb TO USE FORMS NOT JUST UNIQUE FORM IDS
            // handleUpdateNewTaskForm(taskType, formUniqueId);
            setUpdatedNewTrimTaskFormUniqueId(formUniqueId);
            return;
        }

        const currentDeleteTrimForms = taskFormsDiffObj?.trim?.delete || [];
        const currentDeleteTrimFormsCopy = [...currentDeleteTrimForms];

        // const currentUpdateTrimForms = taskFormsDiffObj?.trim?.update || [];
        const currentDiffObjUpdateTrimForms = taskFormsDiffObj?.trim?.update || [];

        // const currentUpdateTrimFormsCopy = [...currentUpdateTrimForms];
        let copyOfCurrentDiffObjUpdateTrimForms = [...currentDiffObjUpdateTrimForms];

        // find the index of the matching form to update, by uniqueFormId
        // const updatedFormTrimFormValIndex = editedTrimTaskFormVals.findIndex((form) => form.formUniqueId === formUniqueId);
        const indexOfExistingEditedTrimTaskForm = editedTrimTaskFormVals.findIndex((form) => form.formUniqueId === formUniqueId);
        const indexOfMatchingOrigTrimTaskForm = origTrimTaskFormVals.findIndex((form) => form.formUniqueId === formUniqueId);

        // use this to check for form attrs diff
        const existingEditedTrimTaskForm = editedTrimTaskFormVals[indexOfExistingEditedTrimTaskForm];
        const matchingOrigTrimTaskForm = origTrimTaskFormVals[indexOfMatchingOrigTrimTaskForm];

        // make a copy of the updated form
        // const updatedFormTrimFormCopy = {...editedTrimTaskFormVals[indexOfExistingEditedTrimTaskForm]};
        const copyOfExistingEditedTrimTaskForm = {...existingEditedTrimTaskForm};

        // find the index of the updated form, if it is already in currentUpdateTrimFormsCopy, based on uniqueFormId
        // const updatedFormTrimFormIndex = currentUpdateTrimFormsCopy.findIndex((form) => form.formUniqueId === formUniqueId);
        const indexOfDiffObjUpdatedTrimForm = copyOfCurrentDiffObjUpdateTrimForms.findIndex((form) => form.formUniqueId === formUniqueId);

        // check for differences in the form, based on attrs
        const formsDiffObj = checkForTaskFormAttrsDiff(matchingOrigTrimTaskForm, existingEditedTrimTaskForm);
        // log the diff obj
        // console.warn('@@@ addUpdatedFormToTaskFormsDiffObj formsDiffObj', formsDiffObj);
        // if no fields are changed, do not add to array of updated form objs
        let formMatchesOrig = false;
        if (Object.keys(formsDiffObj).length === 0) {
            // console.warn('FORM WAS NOT UPDATED, OR IT WAS REVERTED TO THE ORIGINAL STATE');
            // if the form exists in currentUpdateTrimFormsCopy, remove it
            formMatchesOrig = true;
        }

        // if the form exists in currentUpdateTrimFormsCopy, replace it by index
        if (indexOfDiffObjUpdatedTrimForm > -1) {
            // if formMatchesOrig, remove it from copyOfCurrentDiffObjUpdateTrimForms,
            // as it is no longer updated, it has been reverted
            if (formMatchesOrig) {
                copyOfCurrentDiffObjUpdateTrimForms.splice(indexOfDiffObjUpdatedTrimForm, 1);
            }
            else {
                // the form exists and does not match the orig form
                // currentUpdateTrimFormsCopy[updatedFormTrimFormIndex] = updatedFormTrimFormCopy;
                copyOfCurrentDiffObjUpdateTrimForms[indexOfDiffObjUpdatedTrimForm] = copyOfExistingEditedTrimTaskForm;
            }
        } // else, still check if form was even updated
        else {
            // if the form matches the orig form and is NOT in the array of updated forms, skip updating, as it is not updated
            if (formMatchesOrig) {
                setUpdatedTrimTaskFormUniqueId(null);
                return;
            }
            else {
                // push the new updated form to currentUpdateTrimFormsCopy
                copyOfCurrentDiffObjUpdateTrimForms.push(copyOfExistingEditedTrimTaskForm);
            }
        }

        // replace the form in the editedTrimTaskFormVals array
        // const updatedTrimTaskFormValsCopy = [...editedTrimTaskFormVals];
        // updatedTrimTaskFormValsCopy[updatedFormTrimFormValIndex] = updatedFormTrimForm;

        // if (currentUpdateTrimFormUniqueIdsCopy.includes(formUniqueId)) {
        //     console.warn('taskFormsDiffObj UPDATE includes formUniqueId', formUniqueId);
        //     console.warn('taskFormsDiffObj', taskFormsDiffObj);
        //     console.warn('return');
        //     setUpdatedTrimTaskFormUniqueId(null);
        //     return;
        // }
        // else {
        //     currentUpdateTrimFormUniqueIdsCopy.push(formUniqueId);
        //     console.warn('taskFormsDiffObj UPDATE NOT includes formUniqueId', formUniqueId);
        //     console.warn('taskFormsDiffObj', taskFormsDiffObj);
        //     console.warn('pushing unique id');
        // }

        // const updatedFormsCopy = [...copyOfCurrentDiffObjUpdateTrimForms];
        // setUpdatedTrimTaskFormUniqueId(null);
        const updatedTrimTaskFormsDiffObj = {
            ...taskFormsDiffObj,
            trim: {
                create: copyOfDiffObjCurrentCreateTrimForms,
                delete: currentDeleteTrimFormsCopy,
                update: copyOfCurrentDiffObjUpdateTrimForms,
                // update: updatedFormsCopy,
            }
        };
        setTaskFormsDiffObj(updatedTrimTaskFormsDiffObj);
        setUpdatedTrimTaskFormUniqueId(null);
    // }
}

export function addUpdatedShoeTaskFormToTaskFormsDiffObj(formUniqueId, taskFormsDiffObj, setTaskFormsDiffObj, editedShoeTaskFormVals, origShoeTaskFormVals, setUpdatedShoeTaskFormUniqueId, setUpdatedNewShoeTaskFormUniqueId) {
    // TODO - VERIFY WE'RE REMOVING UPDATE IDS ON EVEN FOR UPDATED FORMS WHEN NAMES ARE REMOVED
    // TODO - CHECK IF THE UPDATED FORM IS A NEW ONE IN CREATE ARR,
    //  UPDATE IT IN THAT ARR AND DON'T ADD IT TO UPDATE ARR
    const currentDiffObjCreateShoeForms = taskFormsDiffObj?.shoe?.create || [];
    const copyOfDiffObjCurrentCreateShoeForms = [...currentDiffObjCreateShoeForms];
    const indexOfDiffObjCreateShoeForm = copyOfDiffObjCurrentCreateShoeForms.findIndex((form) => form.formUniqueId === formUniqueId);
    if (indexOfDiffObjCreateShoeForm > -1) {
        setUpdatedNewShoeTaskFormUniqueId(formUniqueId);
        return;
    }

    const currentDeleteShoeForms = taskFormsDiffObj?.shoe?.delete || [];
    const currentDeleteShoeFormsCopy = [...currentDeleteShoeForms];

    const currentDiffObjUpdateShoeForms = taskFormsDiffObj?.shoe?.update || [];

    let copyOfCurrentDiffObjUpdateShoeForms = [...currentDiffObjUpdateShoeForms];

    // find the index of the matching form to update, by uniqueFormId
    const indexOfExistingEditedShoeTaskForm = editedShoeTaskFormVals.findIndex((form) => form.formUniqueId === formUniqueId);
    const indexOfMatchingOrigShoeTaskForm = origShoeTaskFormVals.findIndex((form) => form.formUniqueId === formUniqueId);

    // use this to check for form attrs diff
    const existingEditedShoeTaskForm = editedShoeTaskFormVals[indexOfExistingEditedShoeTaskForm];
    const matchingOrigShoeTaskForm = origShoeTaskFormVals[indexOfMatchingOrigShoeTaskForm];

    // make a copy of the updated form
    const copyOfExistingEditedShoeTaskForm = {...existingEditedShoeTaskForm};

    // find the index of the updated form, if it is already in currentUpdateTrimFormsCopy, based on uniqueFormId
    const indexOfDiffObjUpdatedShoeForm = copyOfCurrentDiffObjUpdateShoeForms.findIndex((form) => form.formUniqueId === formUniqueId);

    // check for differences in the form, based on attrs
    const formsDiffObj = checkForTaskFormAttrsDiff(matchingOrigShoeTaskForm, existingEditedShoeTaskForm);
    // log the diff obj
    // console.warn('@@@ addUpdatedFormToTaskFormsDiffObj formsDiffObj', formsDiffObj);
    // if no fields are changed, do not add to array of updated form objs
    let formMatchesOrig = false;
    if (Object.keys(formsDiffObj).length === 0) {
        // console.warn('FORM WAS NOT UPDATED, OR IT WAS REVERTED TO THE ORIGINAL STATE');
        // if the form exists in currentUpdateTrimFormsCopy, remove it
        formMatchesOrig = true;
    }

    // if the form exists in currentUpdateTrimFormsCopy, replace it by index
    if (indexOfDiffObjUpdatedShoeForm > -1) {
        // if formMatchesOrig, remove it from copyOfCurrentDiffObjUpdateTrimForms,
        // as it is no longer updated, it has been reverted
        if (formMatchesOrig) {
            copyOfCurrentDiffObjUpdateShoeForms.splice(indexOfDiffObjUpdatedShoeForm, 1);
        }
        else {
            // the form exists and does not match the orig form
            // currentUpdateTrimFormsCopy[updatedFormTrimFormIndex] = updatedFormTrimFormCopy;
            copyOfCurrentDiffObjUpdateShoeForms[indexOfDiffObjUpdatedShoeForm] = copyOfExistingEditedShoeTaskForm;
        }
    } // else, still check if form was even updated
    else {
        // if the form matches the orig form and is NOT in the array of updated forms, skip updating, as it is not updated
        if (formMatchesOrig) {
            setUpdatedShoeTaskFormUniqueId(null);
            return;
        }
        else {
            // push the new updated form to currentUpdateTrimFormsCopy
            copyOfCurrentDiffObjUpdateShoeForms.push(copyOfExistingEditedShoeTaskForm);
        }
    }
    // const updatedFormsCopy = [...copyOfCurrentDiffObjUpdateTrimForms];
    const updatedShoeTaskFormsDiffObj = {
        ...taskFormsDiffObj,
        shoe: {
            create: copyOfDiffObjCurrentCreateShoeForms,
            delete: currentDeleteShoeFormsCopy,
            update: copyOfCurrentDiffObjUpdateShoeForms,
        }
    };
    setTaskFormsDiffObj(updatedShoeTaskFormsDiffObj);
    setUpdatedShoeTaskFormUniqueId(null);
}


// export function handleUpdateNewTrimTaskTaskForm(taskType, formUniqueId, createdTrimTaskFormUniqueId, taskFormsDiffObj, setTaskFormsDiffObj, editedTrimTaskFormVals, origTrimTaskFormVals, setCreatedTrimTaskFormUniqueId, setUpdatedNewTrimTaskFormUniqueId, showLogs) {
export function handleUpdateNewTrimTaskForm(formUniqueId, createdTrimTaskFormUniqueId, taskFormsDiffObj, setTaskFormsDiffObj, editedTrimTaskFormVals, origTrimTaskFormVals, setCreatedTrimTaskFormUniqueId, setUpdatedNewTrimTaskFormUniqueId, showLogs) {
        const currentDeleteTrimForms = taskFormsDiffObj?.trim?.delete || [];
        const currentDeleteTrimFormsCopy = [...currentDeleteTrimForms];
        const currentDiffObjUpdateTrimForms = taskFormsDiffObj?.trim?.update || [];
        const copyOfCurrentDiffObjUpdateTrimForms = [...currentDiffObjUpdateTrimForms];

        const currentDiffObjCreateTrimForms = taskFormsDiffObj?.trim?.create || [];
        const copyOfCurrentCreateDiffObjTrimForms = [...currentDiffObjCreateTrimForms];

        // find the index of the new form, if it is already in copyOfCurrentCreateDiffObjTrimForms, based on uniqueFormId
        const indexOfDiffObjCreateTrimForm = copyOfCurrentCreateDiffObjTrimForms.findIndex((form) => form.formUniqueId === formUniqueId);

        if (indexOfDiffObjCreateTrimForm === -1) {
            // is a new form, TODO - debug why we got here
            console.warn('??? handleUpdateNewTaskForm formUniqueId is new, should never reach here - DEBUG WHY', formUniqueId);
            setCreatedTrimTaskFormUniqueId(null);
            setUpdatedNewTrimTaskFormUniqueId(null);

            // addCreateTaskFormToTaskFormsDiffObj(taskType, formUniqueId);
            // addCreateTaskFormToTaskFormsDiffObj('trim', createdTrimTaskFormUniqueId, taskFormsDiffObj, setTaskFormsDiffObj, editedTrimTaskFormVals, origTrimTaskFormVals, setCreatedTrimTaskFormUniqueId, showLogs);
            addCreateTrimTaskFormToTaskFormsDiffObj(createdTrimTaskFormUniqueId, taskFormsDiffObj, setTaskFormsDiffObj, editedTrimTaskFormVals, origTrimTaskFormVals, setCreatedTrimTaskFormUniqueId, showLogs);

            // setCreatedTrimTaskFormUniqueId(null);
            // setUpdatedNewTrimTaskFormUniqueId(null);

            return;
        }
        else {
            //first check for form diff, if a diff, update the form in the existing create forms arr
            const existingDiffObjCreateTrimForm = copyOfCurrentCreateDiffObjTrimForms[indexOfDiffObjCreateTrimForm];
            const existingEditedTrimTaskForm = editedTrimTaskFormVals.find((form) => form.formUniqueId === formUniqueId);
            // check for differences in the form, based on attrs
            const formsDiffObj = checkForTaskFormAttrsDiff(existingDiffObjCreateTrimForm, existingEditedTrimTaskForm);
            // log the diff obj
            // console.warn('@@@ handleUpdateNewTaskForm formsDiffObj', formsDiffObj);
            // if no fields are changed, do not update array of create form objs
            let formChanged = true;
            if (Object.keys(formsDiffObj).length === 0) {
                // console.warn('CREATE TASK FORM WAS NOT UPDATED, OR IT WAS REVERTED TO THE ORIGINAL DIFF OBJ STATE');
                // the edited form matches form in copyOfCurrentCreateDiffObjTrimForms, nothing to update, return
                formChanged = false;
            }
            if (formChanged) {
                console.warn('### CREATE TRIM TASK FORM IN DIFF OBJ WAS UPDATED');
                copyOfCurrentCreateDiffObjTrimForms[indexOfDiffObjCreateTrimForm] = existingEditedTrimTaskForm;
            }
            else {
                // if (showLogs) {
                //     console.warn('### CREATE TASK FORM WAS NOT UPDATED, OR IT WAS REVERTED TO THE ORIGINAL STATE');
                // }
                // TODO - make sure return here is correct
                return;
            }
        }
        const updatedTrimTaskFormsDiffObj = {
            ...taskFormsDiffObj,
            trim: {
                create: copyOfCurrentCreateDiffObjTrimForms,
                delete: currentDeleteTrimFormsCopy,
                update: copyOfCurrentDiffObjUpdateTrimForms,
            }
        };
        setTaskFormsDiffObj(updatedTrimTaskFormsDiffObj);
        setCreatedTrimTaskFormUniqueId(null);
        setUpdatedNewTrimTaskFormUniqueId(null);
}

export function handleUpdateNewShoeTaskForm(formUniqueId, createdShoeTaskFormUniqueId, taskFormsDiffObj, setTaskFormsDiffObj, editedShoeTaskFormVals, origShoeTaskFormVals, setCreatedShoeTaskFormUniqueId, setUpdatedNewShoeTaskFormUniqueId, showLogs) {
    const currentDeleteShoeForms = taskFormsDiffObj?.shoe?.delete || [];
    const currentDeleteShoeFormsCopy = [...currentDeleteShoeForms];
    const currentDiffObjUpdateShoeForms = taskFormsDiffObj?.shoe?.update || [];
    const copyOfCurrentDiffObjUpdateShoeForms = [...currentDiffObjUpdateShoeForms];

    const currentDiffObjCreateShoeForms = taskFormsDiffObj?.shoe?.create || [];
    const copyOfCurrentCreateDiffObjShoeForms = [...currentDiffObjCreateShoeForms];

    // find the index of the new form, if it is already in copyOfCurrentCreateDiffObjTrimForms, based on uniqueFormId
    const indexOfDiffObjCreateShoeForm = copyOfCurrentCreateDiffObjShoeForms.findIndex((form) => form.formUniqueId === formUniqueId);

    if (indexOfDiffObjCreateShoeForm === -1) {
        // is a new form, TODO - debug why we got here
        console.warn('??? handleUpdateNewTaskForm formUniqueId is new, should never reach here - DEBUG WHY', formUniqueId);
        setCreatedShoeTaskFormUniqueId(null);
        setUpdatedNewShoeTaskFormUniqueId(null);

        // addCreateTaskFormToTaskFormsDiffObj(taskType, formUniqueId);
        // addCreateTaskFormToTaskFormsDiffObj('trim', createdShoeTaskFormUniqueId, taskFormsDiffObj, setTaskFormsDiffObj, editedShoeTaskFormVals, origShoeTaskFormVals, setCreatedShoeTaskFormUniqueId, showLogs);
        addCreateShoeTaskFormToTaskFormsDiffObj(createdShoeTaskFormUniqueId, taskFormsDiffObj, setTaskFormsDiffObj, editedShoeTaskFormVals, origShoeTaskFormVals, setCreatedShoeTaskFormUniqueId, showLogs);

        // setCreatedTrimTaskFormUniqueId(null);
        // setUpdatedNewTrimTaskFormUniqueId(null);

        return;
    }
    else {
        //first check for form diff, if a diff, update the form in the existing create forms arr
        const existingDiffObjCreateShoeForm = copyOfCurrentCreateDiffObjShoeForms[indexOfDiffObjCreateShoeForm];
        const existingEditedShoeTaskForm = editedShoeTaskFormVals.find((form) => form.formUniqueId === formUniqueId);
        // check for differences in the form, based on attrs
        const formsDiffObj = checkForTaskFormAttrsDiff(existingDiffObjCreateShoeForm, existingEditedShoeTaskForm);
        // log the diff obj
        // console.warn('@@@ handleUpdateNewTaskForm formsDiffObj', formsDiffObj);
        // if no fields are changed, do not update array of create form objs
        let formChanged = true;
        if (Object.keys(formsDiffObj).length === 0) {
            // console.warn('CREATE TASK FORM WAS NOT UPDATED, OR IT WAS REVERTED TO THE ORIGINAL DIFF OBJ STATE');
            // the edited form matches form in copyOfCurrentCreateDiffObjTrimForms, nothing to update, return
            formChanged = false;
        }
        if (formChanged) {
            console.warn('### CREATE SHOE TASK FORM IN DIFF OBJ WAS UPDATED');
            copyOfCurrentCreateDiffObjShoeForms[indexOfDiffObjCreateShoeForm] = existingEditedShoeTaskForm;
        }
        else {
            // if (showLogs) {
            //     console.warn('### CREATE TASK FORM WAS NOT UPDATED, OR IT WAS REVERTED TO THE ORIGINAL STATE');
            // }
            // TODO - make sure return here is correct
            return;
        }
    }
    const updatedShoeTaskFormsDiffObj = {
        ...taskFormsDiffObj,
        shoe: {
            create: copyOfCurrentCreateDiffObjShoeForms,
            delete: currentDeleteShoeFormsCopy,
            update: copyOfCurrentDiffObjUpdateShoeForms,
        }
    };
    setTaskFormsDiffObj(updatedShoeTaskFormsDiffObj);
    setCreatedShoeTaskFormUniqueId(null);
    setUpdatedNewShoeTaskFormUniqueId(null);
}

// TODO - START HERE, UPDATE THESE AND IN PARENT FUNCTION TO BE SINGLE TASK TYPE
// export function addCreateTrimTaskFormToTaskFormsDiffObj(taskType, formUniqueId, taskFormsDiffObj, setTaskFormsDiffObj, editedTrimTaskFormVals, origTrimTaskFormVals, setCreatedTrimTaskFormUniqueId, showLogs) {
export function addCreateTrimTaskFormToTaskFormsDiffObj(formUniqueId, taskFormsDiffObj, setTaskFormsDiffObj, editedTrimTaskFormVals, origTrimTaskFormVals, setCreatedTrimTaskFormUniqueId, showLogs) {
    // TODO - !!! FR FR -  START HERE, FIND OUT WHY DIFF OBJ HAS TOO MANY RECS,
    //  LOSES EXISTING EDITED FORMS WITH MODEL REC IDS
        const currentDeleteTrimForms = taskFormsDiffObj?.trim?.delete || [];
        const currentDeleteTrimFormsCopy = [...currentDeleteTrimForms];

        const currentUpdateTrimForms = taskFormsDiffObj?.trim?.update || [];
        const currentUpdateTrimFormsCopy = [...currentUpdateTrimForms];

        const currentDiffObjCreateTrimForms = taskFormsDiffObj?.trim?.create || [];
        const copyOfCurrentCreateDiffObjTrimForms = [...currentDiffObjCreateTrimForms];

        // check if the form was in orig forms, by uniqueFormId
        const indexOfMatchingOrigTrimTaskForm = origTrimTaskFormVals.findIndex((form) => form.formUniqueId === formUniqueId);
        if (indexOfMatchingOrigTrimTaskForm === -1) {
            // formUniqueId is not in orig trim task form vals, it is a new form
            console.warn('@@@ !!! addCreateTaskFormToTaskFormsDiffObj formUniqueId is new, add it if not already in create arr - formUniqueId:', formUniqueId);
        }
        else {
            // not a new task form, should never reach here
            console.warn('!!! addCreateTaskFormToTaskFormsDiffObj formUniqueId is not new, should never reach here - DEBUG WHY', formUniqueId);
            console.warn('!!! addCreateTaskFormToTaskFormsDiffObj indexOfMatchingOrigTrimTaskForm', indexOfMatchingOrigTrimTaskForm);
            setCreatedTrimTaskFormUniqueId(null);
            return;
        }

        // find the index of the new form, if it is already in copyOfCurrentCreateDiffObjTrimForms, based on uniqueFormId
        const indexOfDiffObjCreateTrimForm = copyOfCurrentCreateDiffObjTrimForms.findIndex((form) => form.formUniqueId === formUniqueId);

        if (indexOfDiffObjCreateTrimForm === -1) {
            // if the form does not exist in currentUpdateTrimFormsCopy, get the form from editedTrimTaskFormVals by uniqueFormId, then add it
            const newForm = editedTrimTaskFormVals.find((form) => form.formUniqueId === formUniqueId);
            if (newForm === undefined) {
                console.warn('!!! addCreateTaskFormToTaskFormsDiffObj newForm is undefined, should never reach here - DEBUG WHY', formUniqueId);
                setCreatedTrimTaskFormUniqueId(null);
                return;
            }
            copyOfCurrentCreateDiffObjTrimForms.push(newForm);
        }
        else {
            // the new form already exists, no need to add it again
            if (showLogs) {
                console.warn('### addCreateTaskFormToTaskFormsDiffObj formUniqueId already exists', formUniqueId);
            }
            setCreatedTrimTaskFormUniqueId(null);
            return;
        }

        if (showLogs) {
            console.warn('### addCreateTaskFormToTaskFormsDiffObj copyOfCurrentCreateDiffObjTrimForms', copyOfCurrentCreateDiffObjTrimForms);
        }
        const updatedTrimTaskFormsDiffObj = {
            ...taskFormsDiffObj,
            trim: {
                create: copyOfCurrentCreateDiffObjTrimForms,
                delete: currentDeleteTrimFormsCopy,
                update: currentUpdateTrimFormsCopy,
            }
        };
        setTaskFormsDiffObj(updatedTrimTaskFormsDiffObj);
    setCreatedTrimTaskFormUniqueId(null);
}

export function addCreateShoeTaskFormToTaskFormsDiffObj(formUniqueId, taskFormsDiffObj, setTaskFormsDiffObj, editedShoeTaskFormVals, origShoeTaskFormVals, setCreatedShoeTaskFormUniqueId, showLogs) {
    // TODO - !!! FR FR -  START HERE, FIND OUT WHY DIFF OBJ HAS TOO MANY RECS,
    //  LOSES EXISTING EDITED FORMS WITH MODEL REC IDS
        const currentDeleteShoeForms = taskFormsDiffObj?.shoe?.delete || [];
        const currentDeleteShoeFormsCopy = [...currentDeleteShoeForms];

        const currentUpdateShoeForms = taskFormsDiffObj?.shoe?.update || [];
        const currentUpdateShoeFormsCopy = [...currentUpdateShoeForms];

        const currentDiffObjCreateShoeForms = taskFormsDiffObj?.shoe?.create || [];
        const copyOfCurrentCreateDiffObjShoeForms = [...currentDiffObjCreateShoeForms];

        // check if the form was in orig forms, by uniqueFormId
        const indexOfMatchingOrigShoeTaskForm = origShoeTaskFormVals.findIndex((form) => form.formUniqueId === formUniqueId);
        if (indexOfMatchingOrigShoeTaskForm === -1) {
            // formUniqueId is not in orig trim task form vals, it is a new form
            console.warn('@@@ !!! addCreateTaskFormToTaskFormsDiffObj formUniqueId is new, add it if not already in create arr - formUniqueId:', formUniqueId);
        }
        else {
            // not a new task form, should never reach here
            console.warn('!!! addCreateTaskFormToTaskFormsDiffObj formUniqueId is not new, should never reach here - DEBUG WHY', formUniqueId);
            console.warn('!!! addCreateTaskFormToTaskFormsDiffObj indexOfMatchingOrigShoeTaskForm', indexOfMatchingOrigShoeTaskForm);
            setCreatedShoeTaskFormUniqueId(null);
            return;
        }

        // find the index of the new form, if it is already in copyOfCurrentCreateDiffObjTrimForms, based on uniqueFormId
        const indexOfDiffObjCreateShoeForm = copyOfCurrentCreateDiffObjShoeForms.findIndex((form) => form.formUniqueId === formUniqueId);

        if (indexOfDiffObjCreateShoeForm === -1) {
            // if the form does not exist in currentUpdateTrimFormsCopy, get the form from editedTrimTaskFormVals by uniqueFormId, then add it
            const newForm = editedShoeTaskFormVals.find((form) => form.formUniqueId === formUniqueId);
            if (newForm === undefined) {
                console.warn('!!! addCreateTaskFormToTaskFormsDiffObj newForm is undefined, should never reach here - DEBUG WHY', formUniqueId);
                setCreatedShoeTaskFormUniqueId(null);
                return;
            }
            copyOfCurrentCreateDiffObjShoeForms.push(newForm);
        }
        else {
            // the new form already exists, no need to add it again
            if (showLogs) {
                console.warn('### addCreateTaskFormToTaskFormsDiffObj formUniqueId already exists', formUniqueId);
            }
            setCreatedShoeTaskFormUniqueId(null);
            return;
        }
        if (showLogs) {
            console.warn('### addCreateTaskFormToTaskFormsDiffObj copyOfCurrentCreateDiffObjShoeForms', copyOfCurrentCreateDiffObjShoeForms);
        }
        const updatedShoeTaskFormsDiffObj = {
            ...taskFormsDiffObj,
            shoe: {
                create: copyOfCurrentCreateDiffObjShoeForms,
                delete: currentDeleteShoeFormsCopy,
                update: currentUpdateShoeFormsCopy,
            }
        };
        setTaskFormsDiffObj(updatedShoeTaskFormsDiffObj);
    setCreatedShoeTaskFormUniqueId(null);
}


// TODO - ?NEED TO UPDATE TO USE TASK FORMS? OR ARE UNIQUE IDS OK?
// export function addDeleteUniqueIdToTaskFormsDiffObj(taskType, formUniqueId, taskFormsDiffObj, setTaskFormsDiffObj, editedTrimTaskFormVals, origTrimTaskFormVals, setDeletedTrimTaskFormUniqueId, showLogs) {
export function addDeleteTrimTaskUniqueIdToTaskFormsDiffObj(formUniqueId, taskFormsDiffObj, setTaskFormsDiffObj, editedTrimTaskFormVals, origTrimTaskFormVals, setDeletedTrimTaskFormUniqueId, showLogs) {
    // TODO - VERIFY WE'RE REMOVING UPDATE IDS ON EVEN FOR UPDATED FORMS WHEN NAMES ARE REMOVED
        const currentCreateTrimFormUniqueIds = taskFormsDiffObj?.trim?.create || [];
        const currentCreateTrimFormUniqueIdsCopy = [...currentCreateTrimFormUniqueIds];
        const currentDeleteTrimFormUniqueIds = taskFormsDiffObj?.trim?.delete || [];
        const currentDeleteTrimFormUniqueIdsCopy = [...currentDeleteTrimFormUniqueIds];
        const currentUpdateTrimFormUniqueIds = taskFormsDiffObj?.trim?.update || [];
        const currentUpdateTrimFormUniqueIdsCopy = [...currentUpdateTrimFormUniqueIds];
        if (currentDeleteTrimFormUniqueIdsCopy.includes(formUniqueId)) {
            // console.warn('taskFormsDiffObj DELETE includes formUniqueId', formUniqueId);
            // console.warn('taskFormsDiffObj', taskFormsDiffObj);
            // console.warn('return');
            return;
        }
        else {
            currentDeleteTrimFormUniqueIdsCopy.push(formUniqueId);
            // console.warn('taskFormsDiffObj DELETE NOT includes formUniqueId', formUniqueId);
            // console.warn('taskFormsDiffObj', taskFormsDiffObj);
            // console.warn('pushing unique id');
        }
        const updatedTrimTaskFormsDiffObj = {
            ...taskFormsDiffObj,
            trim: {
                create: currentCreateTrimFormUniqueIdsCopy,
                delete: currentDeleteTrimFormUniqueIdsCopy,
                update: currentUpdateTrimFormUniqueIdsCopy,
            }
        };
        setTaskFormsDiffObj(updatedTrimTaskFormsDiffObj);
    setDeletedTrimTaskFormUniqueId(null);
}

export function addDeleteShoeTaskUniqueIdToTaskFormsDiffObj(formUniqueId, taskFormsDiffObj, setTaskFormsDiffObj, editedShoeTaskFormVals, origShoeTaskFormVals, setDeletedShoeTaskFormUniqueId, showLogs) {
    // TODO - VERIFY WE'RE REMOVING UPDATE IDS ON EVEN FOR UPDATED FORMS WHEN NAMES ARE REMOVED
        const currentCreateShoeFormUniqueIds = taskFormsDiffObj?.shoe?.create || [];
        const currentCreateShoeFormUniqueIdsCopy = [...currentCreateShoeFormUniqueIds];
        const currentDeleteShoeFormUniqueIds = taskFormsDiffObj?.shoe?.delete || [];
        const currentDeleteShoeFormUniqueIdsCopy = [...currentDeleteShoeFormUniqueIds];
        const currentUpdateShoeFormUniqueIds = taskFormsDiffObj?.shoe?.update || [];
        const currentUpdateShoeFormUniqueIdsCopy = [...currentUpdateShoeFormUniqueIds];
        if (currentDeleteShoeFormUniqueIdsCopy.includes(formUniqueId)) {
            // console.warn('taskFormsDiffObj DELETE includes formUniqueId', formUniqueId);
            // console.warn('taskFormsDiffObj', taskFormsDiffObj);
            // console.warn('return');
            return;
        }
        else {
            currentDeleteShoeFormUniqueIdsCopy.push(formUniqueId);
            // console.warn('taskFormsDiffObj DELETE NOT includes formUniqueId', formUniqueId);
            // console.warn('taskFormsDiffObj', taskFormsDiffObj);
            // console.warn('pushing unique id');
        }
        const updatedShoeTaskFormsDiffObj = {
            ...taskFormsDiffObj,
            shoe: {
                create: currentCreateShoeFormUniqueIdsCopy,
                delete: currentDeleteShoeFormUniqueIdsCopy,
                update: currentUpdateShoeFormUniqueIdsCopy,
            }
        };
        setTaskFormsDiffObj(updatedShoeTaskFormsDiffObj);
    setDeletedShoeTaskFormUniqueId(null);
}


