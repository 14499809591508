import React, {useState, useEffect} from 'react';
import AmpUserClientNotesContext from "../../contexts/AmpUserClientNotesContext";
import { DataStore, SortDirection } from "aws-amplify";
import {ClientNote} from '../../../models';

const AmpUserClientNotesProvider = ({ children, clientId, ampUserId, showLogs, resetProviderState }) => {
    const [ampUserClientNotesRecs, setAmpUserClientNotesRecs] = useState([]);
    const [ampUserClientNotesShowLogs, setAmpUserClientNotesShowLogs] = useState(false);
    const [clientIdsArr, setClientIdsArr] = useState([]);
    const [clientNoteRecsByClientId, setClientNoteRecsByClientId] = useState({});

    const resetContext = () => {
        setAmpUserClientNotesRecs([]);
        setClientIdsArr([]);
        setClientNoteRecsByClientId({});
        // console.warn('!!! AmpUserClientNotesProvider resetContext() - RESET');
    }

    // useEffect( reset state values when resetProviderState is true
    useEffect(() => {
        if (resetProviderState) {
            resetContext();
        }
    }, [resetProviderState]);

    // useEffect( console.log( ampUserClientNotesRecs
    useEffect(() => {
        if (ampUserClientNotesShowLogs) {
            console.log('AmpUserClientNotesProvider ampUserClientNotesRecs', ampUserClientNotesRecs);
        }
    }, [ampUserClientNotesRecs, ampUserClientNotesShowLogs]);

    useEffect(() => {
        if (!resetProviderState && clientId) {
            const subscription = DataStore.observeQuery(ClientNote,
                (c) => c.and(c => [
                    c.clientID.eq(clientId),
                    c.isDeleted.ne(true)  // Filter out records where isDeleted is true
                ]),
                {
                    sort: s => s.createdAt(SortDirection.DESCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    if (ampUserClientNotesShowLogs) {
                        // console.log(`AmpUserClientNotesProvide c [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                        // console.log('AmpUserClientNotesProvide c [Snapshot] items', items);
                    }
                    if (isSynced) {
                        setAmpUserClientNotesRecs(items);
                        if (items && items.length > 0) {
                            const clientNoteRecsByClientIdObj = {};
                            items.forEach((clientNoteRec) => {
                                if (clientNoteRec.clientID) {
                                    if (clientNoteRecsByClientIdObj[clientNoteRec.clientID]) {
                                        clientNoteRecsByClientIdObj[clientNoteRec.clientID].push(clientNoteRec);
                                    } else {
                                        clientNoteRecsByClientIdObj[clientNoteRec.clientID] = [clientNoteRec];
                                    }
                                }
                            });
                            setClientNoteRecsByClientId(clientNoteRecsByClientIdObj);
                        }
                    }
                });
            // Remember to unsubscribe when the component unmounts
            return () => subscription.unsubscribe();
        }
        else if (!resetProviderState && clientIdsArr && clientIdsArr.length > 0) {
            const subscription = DataStore.observeQuery(ClientNote,
                (cn) => cn.and(cn => [
                    cn.or(cn => clientIdsArr.map(clientId => cn.clientID.eq(clientId))),
                    cn.isDeleted.ne(true)  // Filter out records where isDeleted is true
                ]),
                {
                    sort: s => s.createdAt(SortDirection.DESCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    if (ampUserClientNotesShowLogs) {
                        // console.log(`AmpUserClientNotesProvider c [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                        // console.log('AmpUserClientNotesProvider c [Snapshot] items', items);
                    }
                    if (isSynced) {
                        setAmpUserClientNotesRecs(items);
                        if (items && items.length > 0) {
                            const clientNoteRecsByClientIdObj = {};
                            items.forEach((clientNoteRec) => {
                                if (clientNoteRec.clientID) {
                                    if (clientNoteRecsByClientIdObj[clientNoteRec.clientID]) {
                                        clientNoteRecsByClientIdObj[clientNoteRec.clientID].push(clientNoteRec);
                                    } else {
                                        clientNoteRecsByClientIdObj[clientNoteRec.clientID] = [clientNoteRec];
                                    }
                                }
                            });
                            setClientNoteRecsByClientId(clientNoteRecsByClientIdObj);
                        }
                    }
                });
            // Remember to unsubscribe when the component unmounts
            return () => subscription.unsubscribe();
        }
        else {
            if (ampUserClientNotesShowLogs) {
                console.error('AmpUserClientNotesProvider notes for clientId not found', clientId);
                console.error('AmpUserClientNotesProvider notes for clientIdsArr not found', clientIdsArr);
            }
        }
    }, [clientId, ampUserClientNotesShowLogs, clientIdsArr, resetProviderState]);

    return (
        <AmpUserClientNotesContext.Provider value={{ ampUserClientNotesRecs, setClientIdsArr, clientNoteRecsByClientId }}>
            {children}
        </AmpUserClientNotesContext.Provider>
    );
}

export default AmpUserClientNotesProvider;
