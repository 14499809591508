import React, {useState, useEffect} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import BaseStyledButton from "../../buttons/BaseStyledButton";
import CreateAnimalSubsetForm from "../../forms/create/CreateAnimaSubsetForm";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CircularProgress from "@mui/material/CircularProgress";
import {createClientRec, createPublicClientRec} from "../../../data/create/person/Client";
import CreatePublicClientForm from "../../forms/public/CreatePublicClientForm";
import {ClientStatus} from "../../../models";
import StyledListCard from "../../cards/StyledListCard";
import StyledListItemText from "../../text/StyledListItemText";

export default function CreatePublicClientDialog({
                                                     openProp,
                                                     ampUser,
                                                     handleCloseProp,
                                                     handleCreateDoneProp,
                                                     showLogs,
                                                     ...props
                                                 }) {
    const [open, setOpen] = useState(false);
    const [pauseOnFormSubmit, setPauseOnFormSubmit] = useState(false);
    const [confirmStep, setConfirmStep] = useState(1);
    const [isCreating, setIsCreating] = useState(false);
    const [createErrorMsg, setCreateErrorMsg] = useState(null);
    const [createSuccessMsg, setCreateSuccessMsg] = useState(null);
    const [newClientFormDataObj, setNewClientFormDataObj] = useState(null);
    const [clientRec, setClientRec] = useState(null);
    const [createClientRecResObj, setCreateClientRecResObj] = useState(null);
    const [createPubClientRecResObj, setCreatePubClientRecResObj] = useState(null);

    // useEffect( if (openProp) { setOpen(true);
    useEffect(() => {
        if (openProp) {
            setOpen(true);
        }
    }, [openProp]);

    const handleClose = () => {
        if (showLogs) console.log('### CreatePublicClientDialog handleClose');
        if (handleCloseProp) {
            if (!isCreating) {
                if (handleCreateDoneProp) {
                    handleCreateDoneProp(createPubClientRecResObj);
                }
                handleCloseProp();
            }
        }
    }

    const handleBack = () => {
        setConfirmStep(1);
    };

    function onFormEntriesDone(formDataValsObj) {
        if (showLogs) console.log('### CreatePublicClientDialog onFormEntriesDone', formDataValsObj);
        setNewClientFormDataObj(formDataValsObj);
        setConfirmStep(2);
    }

    async function handleConfirmCreate() {
        if (showLogs) console.log('### CreatePublicClientDialog handleConfirmCreate');
        setIsCreating(true);
        setCreateErrorMsg(null);
        setCreateSuccessMsg(null);
        try {
            newClientFormDataObj.clientStatus = ClientStatus.ACTIVE;
            // const createClientRes = await createPublicClientRec(ampUser, newClientFormDataObj);
            const createClientRes = await createClientRec(newClientFormDataObj, '', ampUser, showLogs);
            if (showLogs) console.log('### CreatePublicClientDialog createClientRes', createClientRes);
            setCreateClientRecResObj(createClientRes);
            if (createClientRes?.success) {
                setClientRec(createClientRes.newClientRec);
                const createPublicClientRes = await createPublicClientRec(ampUser, createClientRes.newClientRec.id, newClientFormDataObj, showLogs);
                setCreatePubClientRecResObj(createPublicClientRes);
                if (showLogs) console.log('### CreatePublicClientDialog createPublicClientRes', createPublicClientRes);
                if (createPublicClientRes.success) {
                    setCreateSuccessMsg('New Client Created Successfully');
                }
            }
        } catch (error) {
            console.error('### CreatePublicClientDialog error', error);
            setCreateErrorMsg('Error Creating Client');
        }
        setIsCreating(false);
        setConfirmStep(3);
    }

    const renderCreateNewClientStep1 = () => (
        <>
            <DialogTitle
                sx={{
                    fontSize: {
                        xs: '1.5rem', // Similar to small screens
                        sm: '2rem',   // Adjust for tablets and small desktops
                        md: '2.5rem', // Standard desktops
                        lg: '3rem',   // Larger desktops
                        xl: '3.5rem'  // Ultra-wide or larger screens
                    },
                    textAlign: 'center'
                }}
            >
                Create New Client
            </DialogTitle>
            <DialogContent>
                <CreatePublicClientForm
                    ampUser={ampUser}
                    showLogs={showLogs}
                    handleFormDoneProp={onFormEntriesDone}
                />
            </DialogContent>
            <DialogActions>
                <BaseStyledButton
                    label="Cancel"
                    color="warning"
                    onClick={handleClose}
                    sx={{
                        fontSize: {
                            xs: '1rem', // Similar to small screens
                            sm: '2rem',   // Adjust for tablets and small desktops
                            md: '2.5rem', // Standard desktops
                            lg: '2.5rem',   // Larger desktops
                            xl: '2.5rem'  // Ultra-wide or larger screens
                        }
                    }}
                />
            </DialogActions>
        </>
    )

    const renderConfirmCreateClientStep2 = () => (
        <>
            <DialogTitle
                sx={{
                    fontSize: {
                        xs: '1.5rem', // Similar to small screens
                        sm: '2rem',   // Adjust for tablets and small desktops
                        md: '2.5rem', // Standard desktops
                        lg: '3rem',   // Larger desktops
                        xl: '3.5rem'  // Ultra-wide or larger screens
                    },
                    textAlign: 'center'
                }}
            >
                Confirm New Client
            </DialogTitle>
            <DialogContent>
                <List>
                    {/*<ListItem>*/}
                    {/*    <ListItemText*/}
                    {/*        primary="Name"*/}
                    {/*        secondary={newClientFormDataObj?.name || ''}*/}
                    {/*    />*/}
                    {/*</ListItem>*/}
                    <ListItem>
                        <StyledListItemText
                            text="Name"
                            secondaryText={newClientFormDataObj?.name || ''}
                            propsFontSize={{fontSize: {
                                    xs: '1.5rem', // Similar to small screens
                                    sm: '2rem',   // Adjust for tablets and small desktops
                                    md: '2.5rem', // Standard desktops
                                    lg: '2.5rem',   // Larger desktops
                                    xl: '2.5rem'  // Ultra-wide or larger screens
                                }}}
                            secondaryFontSize={{fontSize: {
                                    xs: '1.5rem', // Similar to small screens
                                    sm: '2rem',   // Adjust for tablets and small desktops
                                    md: '2.5rem', // Standard desktops
                                    lg: '2.5rem',   // Larger desktops
                                    xl: '2.5rem'  // Ultra-wide or larger screens
                                }}}
                            />
                    </ListItem>
                    {/*<ListItem>*/}
                    {/*    <ListItemText*/}
                    {/*        primary="Phone"*/}
                    {/*        secondary={newClientFormDataObj?.phone || ''}*/}
                    {/*    />*/}
                    {/*</ListItem>*/}
                    <ListItem>
                        <StyledListItemText
                            text="Phone"
                            secondaryText={newClientFormDataObj?.phone || ''}
                            propsFontSize={{fontSize: {
                                    xs: '1.5rem', // Similar to small screens
                                    sm: '2rem',   // Adjust for tablets and small desktops
                                    md: '2.5rem', // Standard desktops
                                    lg: '2.5rem',   // Larger desktops
                                    xl: '2.5rem'  // Ultra-wide or larger screens
                                }}}
                            secondaryFontSize={{fontSize: {
                                    xs: '1.5rem', // Similar to small screens
                                    sm: '2rem',   // Adjust for tablets and small desktops
                                    md: '2.5rem', // Standard desktops
                                    lg: '2.5rem',   // Larger desktops
                                    xl: '2.5rem'  // Ultra-wide or larger screens
                                }}}
                        />
                    </ListItem>
                </List>
            </DialogContent>
            <DialogActions
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between', // Or 'space-evenly' for equal spacing
                    width: '100%',
                    padding: '16px', // Optional: Adjust padding as needed
                }}
            >
                <BaseStyledButton
                    label="Back"
                    onClick={handleBack}
                    disabled={isCreating}
                    sx={{
                        fontSize: {
                            xs: '1rem', // Similar to small screens
                            sm: '2rem',   // Adjust for tablets and small desktops
                            md: '2.5rem', // Standard desktops
                            lg: '2.5rem',   // Larger desktops
                            xl: '2.5rem'  // Ultra-wide or larger screens
                        }
                    }}
                />
                <BaseStyledButton
                    label="Cancel"
                    onClick={handleClose}
                    disabled={isCreating}
                    color="warning"
                    sx={{
                        fontSize: {
                            xs: '1rem', // Similar to small screens
                            sm: '2rem',   // Adjust for tablets and small desktops
                            md: '2.5rem', // Standard desktops
                            lg: '2.5rem',   // Larger desktops
                            xl: '2.5rem'  // Ultra-wide or larger screens
                        }
                    }}
                />
                <BaseStyledButton
                    label="Confirm"
                    onClick={handleConfirmCreate}
                    disabled={isCreating}
                    sx={{
                        fontSize: {
                            xs: '1rem', // Similar to small screens
                            sm: '2rem',   // Adjust for tablets and small desktops
                            md: '2.5rem', // Standard desktops
                            lg: '2.5rem',   // Larger desktops
                            xl: '2.5rem'  // Ultra-wide or larger screens
                        },
                        backgroundColor: 'green',
                    }}
                />
            </DialogActions>
        </>
    )

    const renderCreateClientResultStep3 = () => (
        <>
            <DialogTitle
                sx={{
                    fontSize: {
                        xs: '1.5rem', // Similar to small screens
                        sm: '2rem',   // Adjust for tablets and small desktops
                        md: '2.5rem', // Standard desktops
                        lg: '3rem',   // Larger desktops
                        xl: '3.5rem'  // Ultra-wide or larger screens
                    },
                    textAlign: 'center'
                }}
            >
                Create Client Result
            </DialogTitle>
            <DialogContent>
                {createErrorMsg && (
                    // <p>{createErrorMsg}</p>
                    <StyledListItemText
                        text={createErrorMsg}
                        propsFontSize={{fontSize: {
                                xs: '1.5rem', // Similar to small screens
                                sm: '2rem',   // Adjust for tablets and small desktops
                                md: '2.5rem', // Standard desktops
                                lg: '2.5rem',   // Larger desktops
                                xl: '2.5rem'  // Ultra-wide or larger screens
                            }}}
                    />
                )}
                {createSuccessMsg && (
                    // <p>{createSuccessMsg}</p>
                    <StyledListItemText
                        text={createSuccessMsg}
                        propsFontSize={{fontSize: {
                                xs: '1.5rem', // Similar to small screens
                                sm: '2rem',   // Adjust for tablets and small desktops
                                md: '2.5rem', // Standard desktops
                                lg: '2.5rem',   // Larger desktops
                                xl: '2.5rem'  // Ultra-wide or larger screens
                            }}}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <BaseStyledButton
                    label="Close"
                    onClick={handleClose}
                    color="warning"
                    sx={{
                        fontSize: {
                            xs: '1rem', // Similar to small screens
                            sm: '2rem',   // Adjust for tablets and small desktops
                            md: '2.5rem', // Standard desktops
                            lg: '2.5rem',   // Larger desktops
                            xl: '2.5rem'  // Ultra-wide or larger screens
                        }
                    }}
                />
            </DialogActions>
        </>
    )

    const renderLoadingStep = () => (
        <>
            <DialogTitle>Loading...</DialogTitle>
            <DialogContent>
                <CircularProgress/>
            </DialogContent>
            <DialogActions>
                <BaseStyledButton
                    label="Cancel"
                    onClick={handleClose}
                />
            </DialogActions>
        </>
    )

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="md"
        >
            {confirmStep === 1 ? renderCreateNewClientStep1() :
                confirmStep === 2 ? renderConfirmCreateClientStep2() :
                    confirmStep === 3 ? renderCreateClientResultStep3() :
                        renderLoadingStep()}
        </Dialog>
    );
}