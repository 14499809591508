import React, { useState, useEffect, useContext } from "react";
import RouterBreadcrumbs from "../../components/router/RouterBreadcrumbs";
import {breadcrumbRoutesUserAttributesScreen} from "../../utils/breadcrumbsRoutes";
import AmpUserContext from "../../data/contexts/AmpUserContext";
import { useConsoleLog } from "../../components/hooks/logging/console";
import Box from "@mui/material/Box";
import EditAmpUserForm from "../../components/forms/edit/EditAmpUserForm";
import UserSubsContext from "../../data/contexts/UserSubsContext";
import SubPaymentViewContext from "../../data/contexts/SubPaymentViewContext";
import AmpUserSubscriberContext from "../../data/contexts/AmpUserSubscriberContext";
import SubsPaymentsDataAccordion from "../../components/accordions/SubsPaymentsDataAccordion";
import AmpUserSubscriberInfoCard from "../../components/cards/AmpUserSubscriberInfoCard";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function AmpUserInfoScreen(props) {
    const [showLogs, setShowLogs] = useState(false);
    const [breadcrumbRoutes, setBreadcrumbRoutes] = useState([]); // [{path: '', name: ''}, ...
    const [routerPaths, setRouterPaths] = useState({});

    const [ampUser, setAmpUser] = useState(null);
    const [ampUserSubscriber, setAmpUserSubscriber] = useState(null);
    const [userSubs, setUserSubs] = useState([]);
    const [latestUserSub, setLatestUserSub] = useState(null);
    const [subPaymentViews, setSubPaymentViews] = useState([]);
    const [latestSubPaymentView, setLatestSubPaymentView] = useState(null);

    const [displaySubsPaymentsAccordion, setDisplaySubsPaymentsAccordion] = useState(false);

    // const [origClientFormVals, setOrigClientFormVals] = useState(clientNoteFormObj);
    // const [editedClientFormVals, setEditedClientFormVals] = useState(clientNoteFormObj);
    // const [origSelectedClientStatus, setOrigSelectedClientStatus] = useState('');
    // const [editedSelectedClientStatus, setEditedSelectedClientStatus] = useState('');
    // const [personName, setPersonName] = useState([]);
    //
    // const [ampUserFormValsChanged, setAmpUserFormValsChanged] = useState(false);
    // const [clientFormValsDiff, setClientFormValsDiff] = useState({});
    //
    // const [displayUpdateModal, setDisplayUpdateModal] = useState(false);
    // const [updateClientOnModalClose, setUpdateClientOnModalClose] = useState(false);
    //
    // const [lastSyncTimestamp, setLastSyncTimestamp] = useState(Date.now());

    // const [showUpdatingIndicator, setShowUpdatingIndicator] = useState(false);
    // const [pauseForFormSubmit, setPauseForFormSubmit] = useState(false);

    const { ampUserRec } = useContext(AmpUserContext);
    const {userSubRecs, latestUserSubRec, setAmpUserRec, setAmpUserSubscriberRec: setUserSubAmpUserSubscriberRec } = useContext(UserSubsContext);
    const {subPaymentViewRecs, latestSubPaymentViewRec, setAmpUserSubscriberRec: setSPVAmpUserSubscriberRec, setUserSubIdsArr, setUserSubRecId } = useContext(SubPaymentViewContext);
    const { ampUserSubscriberRec, setAmpUserSubscriberRec } = useContext(AmpUserSubscriberContext);

    // setBreadcrumbRoutes
    useEffect(() => {
        setBreadcrumbRoutes(breadcrumbRoutesUserAttributesScreen() || [])
        // console.error('breadcrumbRoutes', breadcrumbRoutes)
    }, [props]);

    // let location = useLocation();
    // let navigate = useNavigate();
    // const theme = useTheme();

    // useEffect( setShowLogs(props.showLogs);
    useEffect(() => {
        if (props?.showLogs) {
            setShowLogs(props.showLogs);
        }
        else {
            setShowLogs(false);
        }
    }, [props.showLogs]);

    // useEffect( setAmpUser(ampUserRec); setAmpUser(props.ampUser);
    useEffect(() => {
        if (ampUserRec) {
            setAmpUser(ampUserRec);
        }
        else if (props?.ampUser) {
            // TODO - MAY NEED A CHECK TO SEE IF AMPUSER IS NULL FIRST
            setAmpUser(props.ampUser);
        }
    }, [ampUserRec, props.ampUser]);

    // useEffect( setAmpUserSubscriber(ampUserSubscriberRec); setAmpUserSubscriber(props.ampUserSubscriber);
    useEffect(() => {
        if (ampUserSubscriberRec) {
            setAmpUserSubscriber(ampUserSubscriberRec);
        }
        else if (props?.ampUserSubscriber) {
            // TODO - MAY NEED A CHECK TO SEE IF AMPUSER IS NULL FIRST
            setAmpUserSubscriber(props.ampUserSubscriber);
        }
    }, [ampUserSubscriberRec, props.ampUserSubscriber]);

    useConsoleLog(showLogs, [{label: "AmpUserInfoScreen ampUser", value: ampUser}]);
    useConsoleLog(showLogs, [{label: "AmpUserInfoScreen ampUserSubscriber", value: ampUserSubscriber}]);

    // useEffect( setUserSubs(userSubRecs); setLatestUserSub(latestUserSubRec);
    useEffect(() => {
        if (userSubRecs) {
            setUserSubs(userSubRecs);
        }
        if (latestUserSubRec) {
            setLatestUserSub(latestUserSubRec);
        }
    }, [userSubRecs, latestUserSubRec]);

    useConsoleLog(showLogs, [{label: "AmpUserInfoScreen userSubs", value: userSubs}]);
    useConsoleLog(showLogs, [{label: "AmpUserInfoScreen latestUserSub", value: latestUserSub}]);

    // useEffect( setSubPaymentViews(subPaymentViewRecs); setLatestSubPaymentView(latestSubPaymentViewRec);
    useEffect(() => {
        if (subPaymentViewRecs) {
            setSubPaymentViews(subPaymentViewRecs);
        }
        if (latestSubPaymentViewRec) {
            setLatestSubPaymentView(latestSubPaymentViewRec);
        }
    }, [subPaymentViewRecs, latestSubPaymentViewRec]);

    useConsoleLog(showLogs, [{label: "AmpUserInfoScreen subPaymentViews", value: subPaymentViews}]);
    useConsoleLog(showLogs, [{label: "AmpUserInfoScreen latestSubPaymentView", value: latestSubPaymentView}]);

    // useEffect( setDisplaySubsPaymentsAccordion(showAccordion);
    useEffect(() => {
        let showAccordion = false;
        if (userSubs && userSubs.length > 0) {
            showAccordion = true;
        }
        if (subPaymentViews && subPaymentViews.length > 0) {
            showAccordion = true;
        }
        setDisplaySubsPaymentsAccordion(showAccordion);
    }, [userSubs, subPaymentViews]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto',
            }}
        >
            <RouterBreadcrumbs breadcrumbsArr={breadcrumbRoutes}/>
            <EditAmpUserForm
                ampUser={ampUser}
                showLogs={showLogs}
            />
            {ampUserSubscriber ? (
                <AmpUserSubscriberInfoCard
                    ampUser={ampUser}
                    ampUserSubscriber={ampUserSubscriber}
                    showLogs={showLogs}
                />
            ) : null}
            {displaySubsPaymentsAccordion ? (
                <SubsPaymentsDataAccordion
                    userSubs={userSubs}
                    subPaymentViews={subPaymentViews}
                    showLogs={showLogs}
                    headerText={'Subscriptions'}
                    ampUserSubscriber={ampUserSubscriber}
                />
            ) : null}
        </Box>
    );
}
