import React, { useState, useEffect } from 'react';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import StyledCardContentTypography from "../text/StyledCardContentTypography";
import StyledListCard from "../cards/StyledListCard";
import { useNavigate } from "react-router-dom";
import StyledAccordion from "./StyledAccordion";
import Divider from "@mui/material/Divider";
import {toTitleCase} from "../../utils/text";

export default function SharedPubAnimalsAccordion (props) {
    const [pubAnimals, setPubAnimals] = useState([]);
    const [accordionHeaderText, setAccordionHeaderText] = useState('');
    const [accExpanded, setAccExpanded] = useState(false);

    let navigate = useNavigate();

    // useEffect( setPubAnimals(props.animals);
    useEffect(() => {
        if (props?.animals) {
            setPubAnimals(props.animals);
        }
    }, [props.animals]);

    // useEffect( setAccordionHeaderText(headerText);
    useEffect(() => {
        if (props?.headerText) {
            setAccordionHeaderText(props.headerText);
        }
    }, [props.headerText]);

    useEffect(() => {
        if (props?.showLogs) {
            console.log('SharedPubAnimalsAccordion pubAnimals', pubAnimals);
        }
    }, [props.showLogs, pubAnimals]);

    return (
        <StyledAccordion
            expanded={accExpanded}
            onChange={() => {
                // console.log('Accordion clicked');
                setAccExpanded(!accExpanded);
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <StyledCardContentTypography
                    text={`${accordionHeaderText || 'Animals:'} ${pubAnimals.length}`}
                    fontSize={{
                        xs: '1.5rem', // Similar to small screens
                        sm: '2rem',   // Adjust for tablets and small desktops
                        md: '2.5rem', // Standard desktops
                        lg: '3rem',   // Larger desktops
                        xl: '3.5rem'  // Ultra-wide or larger screens
                    }}
                />
            </AccordionSummary>
            <AccordionDetails>
                <div>
                    {pubAnimals.map((animalRec, index) => (
                        <div key={`${accordionHeaderText}-${animalRec?.name}-${index}`}>
                            <StyledListCard
                                // onClick={() => {
                                //     navigate(`/animal-info/${animalRec.routerPath}`, {state: {animalRec}});
                                // }}
                            >
                                <StyledCardContentTypography text={`Name: ${animalRec?.name ? toTitleCase(animalRec.name) : '-'}`} />
                                <StyledCardContentTypography text={`Type: ${animalRec?.animalType ? toTitleCase(animalRec.animalType) : '-'}`} />
                                <StyledCardContentTypography text={`Age: ${animalRec?.age ? animalRec.age : '-'}`} />
                                <StyledCardContentTypography text={`Breed: ${animalRec?.breed ? toTitleCase(animalRec.breed) : '-'}`} />
                                <StyledCardContentTypography text={`Color: ${animalRec?.color ? toTitleCase(animalRec.color) : '-'}`} />
                                <StyledCardContentTypography text={`Stall Number: ${animalRec?.stallNumber ? animalRec.stallNumber : '-'}`} />
                                <StyledCardContentTypography text={`Discipline: ${animalRec?.discipline ? toTitleCase(animalRec.discipline) : '-'}`} />
                                <StyledCardContentTypography text={`Is Miniature: ${animalRec?.isMiniature ? toTitleCase(animalRec.isMiniature.toString()) : '-'}`} />
                                <StyledCardContentTypography text={`Birthday: ${animalRec?.birthday ? animalRec.birthday : '-'}`} />
                            </StyledListCard>
                            <Divider />
                        </div>
                    ))}
                </div>
            </AccordionDetails>
        </StyledAccordion>
    );
}
