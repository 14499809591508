import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

// OLDER DELETE ANIMAL DIALOG, TRY TO REPLACE WITH NEW ONE - ConfirmDeleteAnimalDialog

export default function DeleteAnimalConfirmationDialog({
                                            open,
                                            handleClose,
                                            handleConfirm,
                                            handleCancel,
                                            modelRecToDelete,
                                            setClientAnimalToDelete,
                                            setDisplayDeleteClientAnimalModal,
                                            setDeleteClientAnimalRes,
                                            clientRec,
                                            showLogs
                                        }) {
    // if (showLogs) console.log("DeleteAnimalConfirmationDialog modelRecToDelete: ", modelRecToDelete);

    return (
        <Dialog
            open={open}
            onClose={() => handleClose(setDisplayDeleteClientAnimalModal, setClientAnimalToDelete, showLogs)}
        >
            <DialogTitle>{"Confirm Delete Animal"}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this animal from the system?
                </DialogContentText>
                <DialogContentText>
                    Client: {clientRec?.name}
                </DialogContentText>
                <DialogContentText>
                    Animal: {modelRecToDelete?.name}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => handleCancel(modelRecToDelete, setDisplayDeleteClientAnimalModal, setClientAnimalToDelete, showLogs)}
                    color="primary"
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => handleConfirm(modelRecToDelete, setDeleteClientAnimalRes, setDisplayDeleteClientAnimalModal, setClientAnimalToDelete, showLogs)}
                    color="warning"
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}

// export default DeleteAnimalConfirmationDialog;
