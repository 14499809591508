import {Button} from "@mui/material";

export default function BaseStyledButton(props) {
    return (
            <Button
                size={props?.size || "small"}
                color={props?.color || "primary"}
                variant={props?.variant || "contained"}
                sx={{
                    textTransform: 'none',
                    fontSize: props.fontSize || ['5vw', '2.5vw', '1.5vw', '1vw'],
                    // fontSize: props.fontSize || ['5vw', '3vw', '2.5vw', '2vw'],
                    backgroundColor: props.backgroundColor || '#5F9EA0',
                    ...props.style
                }}
                {...props}
            >
                {props.label}
            </Button>
    )
}
