import React, { useState, useEffect } from 'react';
import {
    AccordionSummary,
    AccordionDetails,
    Typography,
    Stack,
    Box
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StyledAccordion from "./StyledAccordion";

export default function StyledAccordionList({ accordionDataProp, baseHeaderTextFormatFunc, CustomAccordionDetails, renderButtonsRowProp, showLogsProp, ...props }) {
    const [accordionData, setAccordionData] = useState([{}]);
    const [showLogs, setShowLogs] = useState(false);
    // const [expanded, setExpanded] = useState(false);
    const [expanded, setExpanded] = useState('');

    // useEffect( setAccordionData(accordionDataProp);
    useEffect(() => {
        if (accordionDataProp) {
            setAccordionData(accordionDataProp);
        }
    }, [accordionDataProp]);

    // useEffect( setShowLogs(showLogsProp);
    useEffect(() => {
        if (showLogsProp) {
            setShowLogs(showLogsProp);
        }
        else {
            setShowLogs(false);
        }
    }, [showLogsProp]);

    // useEffect( console.log( accordionData
    useEffect(() => {
        if (showLogs) {
            console.log('StyledAccordionList accordionData', accordionData);
            console.log('StyledAccordionList expanded', expanded);
        }
    }, [accordionData, expanded, showLogs]);

    const handleExpandedOnChange = (panel) => (event, isExpanded) => {
        console.log('handleExpandedOnChange panel', panel);
        console.log('handleExpandedOnChange event', event);
        console.log('handleExpandedOnChange isExpanded', isExpanded);

        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Box>
            {accordionData && accordionData.length > 0 ? (  <Stack spacing={2}>
                {accordionData.map((eachAccordionItem, index) => (
                        <StyledAccordion
                            key={index}
                            expanded={expanded === `panel${index}`}
                            onChange={handleExpandedOnChange(`panel${index}`)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${index}-content`}
                                id={`panel${index}-header`}
                            >
                                <Typography>{baseHeaderTextFormatFunc(eachAccordionItem)}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <CustomAccordionDetails accordionDetailsItem={eachAccordionItem} showLogs={showLogs} />
                                <Box mt={2}>
                                    {renderButtonsRowProp(eachAccordionItem, index)}
                                </Box>
                            </AccordionDetails>
                        </StyledAccordion>
                        ))}
                </Stack>
            ) : null}
        </Box>
    );
};

