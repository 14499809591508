import React, {useState, useEffect} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import {defaultMissingPersonAttrs} from "../../utils/formObjects";
import StyledCardContentTypography from "../text/StyledCardContentTypography";
import { CardActionArea, CardActions } from '@mui/material';
import BaseStyledButton from "../buttons/BaseStyledButton";
import Chip from "@mui/material/Chip";
import { useConsoleLog } from "../hooks/logging/console";


export default function ClientPreviewCard(props) {
    // const [cardImage, setCardImage] = useState(defaultPic)
    const [formValues, setFormValues] = useState({});
    const [ampUser, setAmpUser] = useState({});
    const [showLogs, setShowLogs] = useState(false);
    const [updatedClientFormVals, setUpdatedClientFormVals] = useState({});
    const [updatedClientNoteFormVals, setUpdatedClientNoteFormVals] = useState({});

    // const postAttrsFontSizes = ['5vw', '3vw', '2vw', '1.5vw']
    // const listItemTextSX = {
    //     color: 'black',
    //     fontSize: ['5rem', '4rem', '3rem', '2rem'],
    // }

    const typographySX = {
        color: 'black',
        fontSize: ['1.5rem', '1.75rem', '1.5rem', '1rem']
    }

    const listSubheaderSX = {
        padding: '0 0.5vw',
        margin: '1vh 0.5vw',
        color: 'black',
        fontSize: ['1.75rem', '2rem', '1.5rem', '1rem']
    }

    // useEffect( setShowLogs(
    useEffect(() => {
        if (props?.showLogs) {
            setShowLogs(props.showLogs);
        }
        else {
            setShowLogs(false);
        }
    }, [props.showLogs]);

    // useEffect( set all Form Values
    useEffect(() => {
        if (props?.formValues) {
            setFormValues(props.formValues);
        }
        if (props?.updatedClientFormVals) {
            setUpdatedClientFormVals(props.updatedClientFormVals);
        }
        if (props?.updatedClientNoteFormVals) {
            setUpdatedClientNoteFormVals(props.updatedClientNoteFormVals);
        }
    },[props]);

    // useEffect( console.log('formValues', formValues);
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('formValues', formValues);
    //     }
    // }, [props.formValues, showLogs]);

    useConsoleLog(showLogs, [{label: 'ClientPreviewCard formValues', value: formValues}]);
    useConsoleLog(showLogs, [{label: 'ClientPreviewCard updatedClientFormVals', value: updatedClientFormVals}]);
    useConsoleLog(showLogs, [{label: 'ClientPreviewCard updatedClientNoteFormVals', value: updatedClientNoteFormVals}]);

    // useEffect( setAmpUser(props.ampUser);
    useEffect(() => {
        if (props?.ampUser) {
            setAmpUser(props.ampUser);
        }
    },[props]);

    return (
        <Box sx={{
            width: {
                xs: '90%',    // 0px Full width on extra-small and small devices
                sm: '90%',     // 600px Full width on extra-small and small devices
                md: 420,       // 900px Fixed width on medium devices and up
                // md: '100%',       // Fixed width on medium devices and up
                lg: 600,        // 1200px Fixed width on large devices and up
            },
            maxWidth: 600,
            minWidth: 300,
            bgcolor: 'background.paper',
            color: 'black',
            position: 'relative',
            overflowX: 'hidden',
            overflowY: 'auto',
            '& ul': { padding: 0 },
            '& > :not(style)': { m: 1 }
        }}
        >
            <Card sx={{ maxWidth: 420 }}>
                <CardActionArea>
                    <CardContent>
                        {/*<Typography gutterBottom variant="h5" component="div">*/}
                        {/*    {formValues.name || defaultMissingPersonAttrs.name}*/}
                        {/*</Typography>*/}
                        <Divider>
                            <Chip label="Name"/>
                        </Divider>
                        <StyledCardContentTypography
                            // text={formValues.name || defaultMissingPersonAttrs.name}
                            text={updatedClientFormVals?.name || defaultMissingPersonAttrs.name}
                            sx={typographySX}
                        />
                        <Divider>
                            <Chip label="Email"/>
                        </Divider>
                        <StyledCardContentTypography
                            // text={formValues?.email || defaultMissingPersonAttrs.email}
                            text={updatedClientFormVals?.email || defaultMissingPersonAttrs.email}
                            sx={typographySX}
                        />
                        <Divider>
                            <Chip label="Phone"/>
                        </Divider>
                        <StyledCardContentTypography
                            // text={formValues?.phone || defaultMissingPersonAttrs.phone}
                            text={updatedClientFormVals?.phone || defaultMissingPersonAttrs.phone}
                            sx={typographySX}
                        />
                        <Divider>
                            <Chip label="Balance"/>
                        </Divider>
                        <StyledCardContentTypography
                            // text={formValues?.balance || defaultMissingPersonAttrs.balance}
                            text={updatedClientFormVals?.balance || defaultMissingPersonAttrs.balance}
                            sx={typographySX}
                        />
                        <Divider>
                            <Chip label="Street Address"/>
                        </Divider>
                        <StyledCardContentTypography
                            // text={formValues?.streetAddress || defaultMissingPersonAttrs.streetAddress}
                            text={updatedClientFormVals?.streetAddress || defaultMissingPersonAttrs.streetAddress}
                            sx={typographySX}
                        />
                        <Divider>
                            <Chip label="City, State, Zip"/>
                        </Divider>
                        <StyledCardContentTypography
                            // text={`${formValues?.city || defaultMissingPersonAttrs.city}, ${formValues?.state || defaultMissingPersonAttrs.state} ${formValues?.zipCode || defaultMissingPersonAttrs.zipCode}`}
                            text={`${updatedClientFormVals?.city || defaultMissingPersonAttrs.city}, ${updatedClientFormVals?.state || defaultMissingPersonAttrs.state} ${updatedClientFormVals?.zipCode || defaultMissingPersonAttrs.zipCode}`}
                            sx={typographySX}
                        />
                        <Divider>
                            <Chip label="Client Status"/>
                        </Divider>
                        <StyledCardContentTypography
                            // text={formValues?.clientStatus || defaultMissingPersonAttrs.clientStatus}
                            text={updatedClientFormVals?.clientStatus || defaultMissingPersonAttrs.clientStatus}
                            sx={typographySX}
                        />
                        <Divider>
                            <Chip label="Gate Code"/>
                        </Divider>
                        <StyledCardContentTypography
                            // text={formValues?.clientStatus || defaultMissingPersonAttrs.clientStatus}
                            text={updatedClientFormVals?.gateCode || '-'}
                            sx={typographySX}
                        />
                        <Divider>
                            <Chip label="Alt. Contact Name"/>
                        </Divider>
                        <StyledCardContentTypography
                            // text={formValues?.clientStatus || defaultMissingPersonAttrs.clientStatus}
                            text={updatedClientFormVals?.alternateContactName || '-'}
                            sx={typographySX}
                        />
                        <Divider>
                            <Chip label="Alt. Contact Phone"/>
                        </Divider>
                        <StyledCardContentTypography
                            // text={formValues?.clientStatus || defaultMissingPersonAttrs.clientStatus}
                            text={updatedClientFormVals?.alternateContactPhone || '-'}
                            sx={typographySX}
                        />
                        <Divider>
                            <Chip label="Client Notes"/>
                        </Divider>
                        <StyledCardContentTypography
                            // text={formValues?.clientNote || defaultMissingPersonAttrs.notes}
                            text={updatedClientNoteFormVals?.note || defaultMissingPersonAttrs.notes}
                            sx={typographySX}
                        />
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <BaseStyledButton
                        size={'large'}
                        label={'Create Client'}
                        onClick={() => props.createClient()}
                        fontSize={['6vw', '3.5vw', '2.5vw', '2vw']}
                        style={{
                            margin: '0 1vw 0 1vw',
                        }}
                    />
                </CardActions>
            </Card>
        </Box>
    )
}
