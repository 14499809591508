import React, { useState, useContext, useEffect } from "react";
import DevStateContext from "../../data/contexts/DevStateContext";
import AmpUserClientsContext from "../../data/contexts/AmpUserClientsContext";
import CreateAnimalForm from "../../components/forms/create/CreateAnimalForm";
import { useConsoleLog } from "../../components/hooks/logging/console";

// TODO - !!! START HERE, FR FR,
//  USE SELECTED CLIENT IN CREATE FORM, FIGURE OUT WHY CLIENTREC  IS NOT COMING THROUGH,
//  SELECTEDCLIENT IS NOT BEING SET BY IT

export default function CreateAnimalScreen(props) {
    const [ampUser, setAmpUser] = useState(null);
    const [showLogs, setShowLogs] = useState(false);
    const [ampUserClients, setAmpUserClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);

    const { devStateObj } = useContext(DevStateContext);
    const { ampUserClientsRecs } = useContext(AmpUserClientsContext);

    // useEffect( setShowLogs(
    useEffect(() => {
        if (devStateObj?.showLogs){
            setShowLogs(devStateObj.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [devStateObj]);

    // useEffect( setShowLogs(props.showLogs);
    // useEffect(() => {
    //     if (props?.showLogs){
    //         setShowLogs(props.showLogs);
    //     } else {
    //         setShowLogs(false);
    //     }
    // }, [props.showLogs]);

    // useEffect( setAmpUser(props.ampUser);
    useEffect(() => {
        if (props?.ampUser) {
            setAmpUser(props.ampUser);
        }
    }, [props.ampUser]);

    // useEffect( setAmpUserClients(ampUserClientRecs);
    useEffect(() => {
        if (ampUserClientsRecs) {
            setAmpUserClients(ampUserClientsRecs);
        }
    }, [ampUserClientsRecs]);

    // useEffect( setSelectedClient(props.clientRec);
    useEffect(() => {
        if (props?.clientRec) {
            setSelectedClient(props.clientRec);
        }
    }, [props.clientRec]);

    // useEffect( console.log('CreateAnimalScreen ampUser', ampUser);
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('CreateAnimalScreen ampUser', ampUser);
    //     }
    // }, [showLogs, ampUser]);

    // useEffect( console.log('CreateAnimalScreen ampUserClients', ampUserClients);
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('CreateAnimalScreen ampUserClients', ampUserClients);
    //     }
    // }, [showLogs, ampUserClients]);

    useConsoleLog(showLogs, [{label: 'CreateAnimalScreen props', value: props}]);
    useConsoleLog(showLogs, [{label: 'CreateAnimalScreen ampUser', value: ampUser}]);
    useConsoleLog(showLogs, [{label: 'CreateAnimalScreen selectedClient', value: selectedClient}]);
    useConsoleLog(showLogs, [{label: 'CreateAnimalScreen ampUserClients', value: ampUserClients}]);

    return (
        <div>
            <h2>Create Animal</h2>
            <CreateAnimalForm
                ampUser={ampUser}
                showLogs={showLogs}
                ampUserClients={ampUserClients}
                selectedClientProp={selectedClient}
            />
        </div>
    )
}
