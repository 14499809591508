import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import DevStateContext from "../../data/contexts/DevStateContext";
import StyledListCard from "../../components/cards/StyledListCard";
import Box from "@mui/material/Box";
import BaseNavigateButton from "../../components/links/BaseNavigateButton";
import StyledListBox from "../../components/boxes/StyledListBox";
import { useResponsiveViewport } from "../../data/providers/ResponsiveViewportProvider";
import RouterBreadcrumbs from "../../components/router/RouterBreadcrumbs";
import { useConsoleLog } from "../../components/hooks/logging/console";
import StyledCardContentTypography from "../../components/text/StyledCardContentTypography";
import BaseStyledButton from "../../components/buttons/BaseStyledButton";
import {editPublicClientFormObj} from "../../utils/formObjects";
import {useSnackbar} from "notistack";
import UpdateSharedPublicClientForm from "../../components/forms/public/UpdateSharedPublicClientForm";

export default function SharedPublicClientScreen(props) {
    const [showLogs, setShowLogs] = useState(false);
    const [publicClient, setPublicClient] = useState(null);

    // useEffect( setShowLogs(props.showLogs);
    useEffect(() => {
        if (props?.showLogs) {
            setShowLogs(props.showLogs);
        }
        else {
            setShowLogs(false);
        }
    }, [props.showLogs]);

    // useEffect( setPublicClient(props.publicClient);
    useEffect(() => {
        if (props?.publicClient) {
            setPublicClient(props.publicClient);
        }
    }, [props.publicClient]);

    // useEffect( console.log( publicClient
    useEffect(() => {
        if (showLogs) {
            console.log('SharedPublicClientScreen publicClient', publicClient);
        }
    }, [showLogs, publicClient]);

    return (
        <div>
        <h2>Shared Public Client Screen</h2>
        <UpdateSharedPublicClientForm
            publicClient={publicClient}
            showLogs={showLogs}
            publicClientPubAnimals={props.publicClientPubAnimals}
        />
        </div>
    );
}

