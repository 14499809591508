// LoadingIndicator.js
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const defaultSXObj = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh'
};

const UpdatingIndicator = ({customSXObj, message}) => {
    return (
        <Box
            sx={{...defaultSXObj, ...customSXObj}}
        >
            <CircularProgress />
            <Typography variant="h6" sx={{ marginTop: 2 }}>
                {message}
            </Typography>
        </Box>
    );
};

export default UpdatingIndicator;
